import React from 'react';

import CardMedia from '@material-ui/core/CardMedia';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import cx from 'classnames';
import { ReactComponent as AssignedIcon } from 'images/assigned_small.svg';

import { setImgixFallbackUrl, setImgixParams } from 'utils/files';

import ArrowIcon from './assets/arrow-with-border.svg';
import { ReactComponent as MultiSelectCheckboxChecked } from './assets/multiselect_checkbox_checked.svg';
import { ReactComponent as MultiSelectCheckboxUnchecked } from './assets/multiselect_checkbox_unchecked.svg';
import styles from './styles.module.scss';

const WorkCardImage = ({
  isEmptyWork,
  onClick,
  activeWork,
  activeWorkIndex,
  activeTaskIndex,
  assignmentTasks,
  activeTaskWork,
  onBottomArrowClick,
  onTopArrowClick,
  onLeftArrowClick,
  onRightArrowClick,
  multiSelectMode = false,
  selected = false
}) => {
  const showLeftArrow = activeTaskIndex > 0 && assignmentTasks.length > 1;
  const showTopArrow = activeWorkIndex > 0 && activeTaskWork.length > 1;

  const showRightArrow = activeTaskIndex < assignmentTasks.length - 1;
  const showBottomArrow = activeWorkIndex < activeTaskWork?.length - 1;

  return (
    <div className={styles.container}>
      {isEmptyWork ? (
        <div
          onClick={onClick}
          className={cx(styles.emptyWorkPlaceholder, styles.image)}
        >
          <SvgIcon component={AssignedIcon} />
        </div>
      ) : (
        <CardMedia
          height={200}
          component="img"
          alt="Student work"
          onClick={onClick}
          className={styles.image}
          image={setImgixParams(activeWork.work_url)}
          onError={setImgixFallbackUrl(activeWork.s3_work_url)}
        />
      )}

      {multiSelectMode && (
        <Checkbox
          checked={selected}
          icon={<MultiSelectCheckboxUnchecked />}
          checkedIcon={<MultiSelectCheckboxChecked />}
          className={styles.cardSelectCheckbox}
        />
      )}

      {showLeftArrow && (
        <IconButton className={styles.leftArrow} onClick={onLeftArrowClick}>
          <img src={ArrowIcon} alt="arrow icon" />
        </IconButton>
      )}
      {showTopArrow && (
        <IconButton className={styles.topArrow} onClick={onTopArrowClick}>
          <img src={ArrowIcon} alt="arrow icon" />
        </IconButton>
      )}
      {showRightArrow && (
        <IconButton className={styles.rightArrow} onClick={onRightArrowClick}>
          <img src={ArrowIcon} alt="arrow icon" />
        </IconButton>
      )}
      {showBottomArrow && (
        <IconButton className={styles.bottomArrow} onClick={onBottomArrowClick}>
          <img src={ArrowIcon} alt="arrow icon" />
        </IconButton>
      )}
    </div>
  );
};

export default WorkCardImage;
