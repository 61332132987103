import { BASE_URL } from 'config/urls';

import { get, post, requestSdk } from 'utils/sdk';

export const fetchCustomer = async () =>
  await requestSdk(() => get(`${BASE_URL}/v1/subscriptions/customer/`));

export const customerAddCard = async (data) => {
  return await requestSdk(() =>
    post(`${BASE_URL}/v1/subscriptions/customer/add-card/`, data)
  );
};

export const fetchProducts = async () =>
  await requestSdk(() => get(`${BASE_URL}/v1/subscriptions/templates/`));

export const createSubscription = async (data) => {
  return await requestSdk(() =>
    post(`${BASE_URL}/v1/subscriptions/create/`, data)
  );
};

export const getSubscription = async (id) => {
  return await requestSdk(() => get(`${BASE_URL}/v1/subscriptions/${id}/`));
};
