import { BASE_URL } from 'config/urls';

import { get, post, requestSdk } from 'utils/sdk';

export const login = async (data) =>
  await requestSdk(() => post(`${BASE_URL}/v1/auth/login/`, data));

export const getMeetingInvitationDetails = ({ invitationIdentifier }) => {
  return requestSdk(() =>
    get(
      `${BASE_URL}/v1/meetings/${invitationIdentifier}/meeting-invitation-details/`
    )
  );
};
