import {
  STUDENT_PORTFOLIO_STANDARD_TABS,
  SUBMITTED_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION
} from 'utils/constants';

export const STANDARD_TAB_TO_READABLE_VALUE_MAPPING = {
  [STUDENT_PORTFOLIO_STANDARD_TABS.MATH]: 'MATH',
  [STUDENT_PORTFOLIO_STANDARD_TABS.ELA]: 'ELA',
  [STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS]: 'NO STANDARDS'
};

export const INITIAL_FILTERS = {
  status: SUBMITTED_STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTION.value,
  standard: STUDENT_PORTFOLIO_STANDARD_TABS.MATH,
  no_standards: false
};
