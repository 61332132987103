import React, { useEffect, useMemo, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import { useMeetingInvitationSchools } from 'sdk';

import { Typography } from 'pages/Walkthrough/components';
import { colors } from 'theme/palette';

import Button from 'components/Button';
import InputField from 'components/InputField';
import SelectFilter from 'components/SelectFilter';

import { INITIAL_VALUES, VALIDATION_SCHEMA } from './constants';
import styles from './styles.module.scss';

const MeetingInvitationSelectSchool = ({
  invitationIdentifier,
  isCompletingProfile,
  onJoinDifferentSchool,
  onNextClick
}) => {
  const [selectedSchoolId, setSelectedSchoolId] = useState();

  const { data: meetingSchools } = useMeetingInvitationSchools({
    invitationIdentifier
  });

  useEffect(() => {
    if (_.isNil(meetingSchools)) {
      return;
    }
    setSelectedSchoolId(meetingSchools[0].id);
  }, [meetingSchools]);

  const selectOptions = useMemo(
    () =>
      meetingSchools?.map((school) => ({
        key: school.id,
        value: school.id,
        label: school.name
      })),
    [meetingSchools]
  );

  return (
    <Formik initialValues={INITIAL_VALUES} validationSchema={VALIDATION_SCHEMA}>
      {({ dirty, errors, values }) => {
        return (
          <>
            <div className={styles.inputContainer}>
              <Typography
                variant="H-TEXT-3"
                color={colors.blue1}
                className={styles.inputLabel}
              >
                Students call me
              </Typography>
              <Field
                fullWidth
                type="text"
                name="preferredUserName"
                placeholder="Mr. T"
                className={styles.textField}
                component={InputField}
                variant="underlined"
                error={_.has(errors, 'preferredUserName')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled className={styles.icon}>
                        {(errors.preferredUserName || !dirty) && (
                          <CheckCircleRoundedIcon fontSize="small" />
                        )}
                        {!(errors.preferredUserName || !dirty) && (
                          <CheckCircleRoundedIcon
                            fontSize="small"
                            style={{ color: colors.green3 }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>

            <div className={styles.inputContainer}>
              <Typography
                variant="H-TEXT-3"
                color={colors.blue1}
                className={styles.inputLabel}
              >
                Select school to join
              </Typography>
              <Field
                type="text"
                name="schoolId"
                selectOnFocus
                clearOnBlur
                disableListWrap
                component={SelectFilter}
                options={selectOptions || []}
                value={selectedSchoolId}
                onChange={(event, school) => {
                  setSelectedSchoolId(event.target.value);
                }}
              />
            </div>

            <Button
              fullWidth
              onClick={() =>
                onJoinDifferentSchool({
                  preferredName: values.preferredUserName
                })
              }
              className={styles.differentSchoolButton}
            >
              Join a different school
            </Button>

            <Button
              fullWidth
              color="pink"
              onClick={() =>
                onNextClick({
                  schoolId: selectedSchoolId,
                  preferredUserName: values.preferredUserName
                })
              }
              disabled={!_.isEmpty(errors) || !dirty || isCompletingProfile}
              className={styles.submitButton}
            >
              Next
            </Button>
          </>
        );
      }}
    </Formik>
  );
};

export default MeetingInvitationSelectSchool;
