import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import celebrateIllustration from './assets/celebrate.png';
import styles from './styles.module.scss';

const CelebratoryDialog = ({
  onClose,
  nextAssignment,
  goToNextAssignment,
  goToAssignmentResults,
  assignmentName,
  open
}) => (
  <Dialog
    onClose={onClose}
    aria-labelledby="celebrate-dialog-title"
    PaperProps={{
      className: styles.dialogPaper
    }}
    open={open}
  >
    <DialogTitle disableTypography id="celebrate-dialog-title" align="center">
      <img
        src={celebrateIllustration}
        alt="celebrate"
        className={styles.illustration}
      />
      <Typography variant="H-TEXT-3" color={colors.blue1}>
        ALL SUBMISSIONS SCORED FOR:
      </Typography>
      <Typography
        variant="H-TEXT-2"
        color={colors.blue4}
        className={styles.assignmentName}
      >
        {assignmentName}
      </Typography>
    </DialogTitle>
    <DialogActions className={styles.dialogActions}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        {_.get(nextAssignment, 'tracker') && (
          <Grid item>
            <Button
              color="blue"
              onClick={goToNextAssignment}
              className={styles.dialogButton}
            >
              Next assignment
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            color="blue"
            className={styles.dialogButton}
            onClick={goToAssignmentResults}
          >
            View Results
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
);

export default CelebratoryDialog;
