import React from 'react';
import { withRouter } from 'react-router';

import {
  LOGIN_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL,
  TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL,
  TEACHERS_PERMISSION_DENIED_URL,
  TEACHER_STRATEGIES_OVERVIEW_URL
} from 'config/urls';
import _ from 'lodash';
import { me } from 'sdk';

import { stringifyParams } from 'utils/urls';

import UserContext from 'components/UserContext';

const FamilyMemberRequired = (WrappedComponent) => {
  class HOC extends React.Component {
    fetchMe = async () => {
      const { data: user } = await me();
      return user;
    };

    redirectToLogin = () => {
      const { pathname, search } = this.props.location;

      this.props.history.push({
        pathname: LOGIN_URL,
        search: stringifyParams({ next: pathname + search })
      });
    };

    redirectToStudentApp = () =>
      this.props.history &&
      this.props.history.push(STUDENTS_ASSIGNMENTS_LIST_URL);

    redirectToTeacherApp = () =>
      this.props.history &&
      this.props.history.push(
        process.env.REACT_APP_SHOW_INSIGHTS_V2 === 'true'
          ? TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL
          : TEACHER_STRATEGIES_OVERVIEW_URL
      );

    redirectToPermissionDenied = () =>
      this.props.history &&
      this.props.history.push(TEACHERS_PERMISSION_DENIED_URL);

    ensureUser = ({ user, setUser }) => {
      if (!user) {
        this.fetchMe().then((me) => {
          !_.isEmpty(me) ? setUser(me) : this.redirectToLogin();
        });

        return null;
      }

      if (user && !user.is_family_member && user.is_teacher) {
        this.redirectToTeacherApp();
        return null;
      }

      if (user && !user.is_family_member && user.student_id) {
        this.redirectToStudentApp();
        return null;
      }

      if (user && !user.is_family_member) {
        this.redirectToPermissionDenied();
      }

      return user;
    };

    render() {
      return (
        <UserContext.Consumer>
          {({ user, setUser }) => {
            const ensuredUser = this.ensureUser({ user, setUser });

            if (ensuredUser) {
              return <WrappedComponent user={ensuredUser} {...this.props} />;
            }
          }}
        </UserContext.Consumer>
      );
    }
  }

  return withRouter(HOC);
};

export default FamilyMemberRequired;
