import React, { useEffect } from 'react';

import { Link } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import LaunchIcon from '@material-ui/icons/Launch';

import BottomNavigation from 'pages/Pathways/components/BottomNavigation';
import { withNavbar } from 'pages/Teachers/shared';

import Button from 'components/Button';
import Typography from 'components/Typography';

import AskEmberButton from '../AskEmberButton/index';
import styles from './styles.module.scss';

const PathwaysCommunity = ({ navigation }) => {
  useEffect(() => {
    navigation.setLeftSubheader(
      <Typography noWrap variant="B-Text-1" className={styles.navigationTitle}>
        Community
      </Typography>
    );
  }, [navigation]);

  return (
    <>
      <div className={styles.container}>
        <Typography variant="H-TEXT-1" className={styles.title}>
          Connect with Fellow Educators!
        </Typography>

        <Typography variant="B-Text-1" className={styles.communityContent}>
          Join our Pathways Facebook community to connect with other teachers,
          share insights, ask questions and stay engaged with the EdLight team
          and your peers.
        </Typography>

        <Typography variant="B-Text-1" className={styles.communityContent}>
          Together, we can support each other on our professional growth
          journeys. Click below to join the conversation!
        </Typography>
        <div className={styles.bottomButton}>
          <Link
            href="https://www.facebook.com/share/g/mKBMkc8h17Dy3DLf/"
            target="_blank"
          >
            <Button className={styles.submitButton} endIcon={<LaunchIcon />}>
              Visit the pathways community
            </Button>
          </Link>
        </div>
        <AskEmberButton />
      </div>
      <BottomNavigation />
    </>
  );
};

export default withWidth()(withNavbar(PathwaysCommunity, { sticky: true }));
