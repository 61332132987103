import React from 'react';

import _ from 'lodash';

import StudentWorkCard from 'components/StudentWorkCard';
import TeacherWorkCard from 'components/TeacherWorkCard';

import { useDraggable } from './hooks';
import styles from './styles.module.scss';

const ScrollableAssignmentsList = ({
  assignments,
  student,
  standardId,
  forStudents,
  postHeightChangeAction
}) => {
  const ref = React.useRef();
  const { onMouseDown } = useDraggable({ ref });

  return (
    <div
      className={styles.scrollableAssignmentsContainer}
      onMouseDown={onMouseDown}
      ref={ref}
    >
      {_.map(assignments, (assignment) => {
        const filteredTasks = _.filter(assignment.tasks, (task) =>
          _.includes(task.standard_ids, standardId)
        );

        const assignmentWithTasksForStandard = {
          ...assignment,
          // This is legacy logic - we count on the BE order of the tasks
          tasks: _.map(filteredTasks, (task, index) => ({
            ...task,
            index: index
          }))
        };

        return forStudents ? (
          <div className={styles.assignmentBox} key={assignment.id}>
            <StudentWorkCard
              assignment={assignmentWithTasksForStandard}
              postHeightChangeAction={postHeightChangeAction}
            />
          </div>
        ) : (
          <div className={styles.assignmentBox} key={assignment.id}>
            <TeacherWorkCard
              assignment={assignmentWithTasksForStandard}
              student={student}
              postHeightChangeAction={postHeightChangeAction}
            />
          </div>
        );
      })}
    </div>
  );
};
export default ScrollableAssignmentsList;
