import { useCallback, useState } from 'react';

import { notifyError } from 'utils/notifications';

/**
 * Params:
 * `onAudioRecorded`: [function]
 *
 * Returns:
 * new Promise((resolve) => resolve(audioBlob))
 */
const startRecording = (onAudioRecorded) => {
  return window.navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((stream) => {
      const recorder = new MediaRecorder(stream);

      recorder.addEventListener('dataavailable', (e) => {
        // Event fires at the end of the recording
        onAudioRecorded(e.data);
      });

      recorder.start();

      return new Promise((resolve) => resolve(recorder));
    })
    .catch(notifyError);
};

/**
 * Params:
 * `recorder`: [function]
 */
const stopRecording = (recorder) => {
  if (recorder) {
    recorder.stop();
    // Remove the "recording" icon from the browser tab.
    recorder.stream.getTracks().forEach((track) => track.stop());
  }
};

/**
 * Encapsulates the audio recorder state logic
 *
 * Params:
 * `onAudioRecorded`: [function]
 *
 * Returns:
 * {
 *   `isVisible`: bool, - for showing/hiding the button
 *   `isActive`: bool, - true if audio is being recorded
 *   `toggleVisible`: [function]
 *   `startRecording`: [function]
 *   `stopRecording`: [function]
 * }
 */
export const useAudioRecorder = ({ onAudioRecorded }) => {
  const [showRecordAudioButton, setShowRecordAudioButton] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [recorder, setRecorder] = useState();
  const [isLoading, setIsLoading] = useState(false); // uploading

  const handleAudioRecorded = useCallback(
    (data) => {
      setIsLoading(true);
      onAudioRecorded(data).then((result) => {
        setIsLoading(false);
        return result;
      });
    },
    [onAudioRecorded]
  );

  return {
    isVisible: showRecordAudioButton,
    isActive,
    isLoading,
    toggleVisible: () => setShowRecordAudioButton(!showRecordAudioButton),
    setVisible: setShowRecordAudioButton,
    startRecording: () =>
      startRecording(handleAudioRecorded)
        .then(setRecorder)
        .then(setIsActive(true)),
    stopRecording: () => {
      if (recorder) {
        stopRecording(recorder);
      }
      setIsActive(false);
      setRecorder(undefined);
    }
  };
};
