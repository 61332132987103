import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  TEACHERS_ASSIGNMENTS_RESULTS_URL,
  TEACHERS_MEETINGS_LIST_URL,
  TEACHERS_MEETING_FEED_URL,
  TEACHERS_PERMISSION_DENIED_URL
} from 'config/urls';
import _ from 'lodash';
import { useMeetingBoards, useTeamMeetings } from 'sdk';
import { acceptMeetingInvitation } from 'sdk';

import MeetingSortsSkeleton from 'pages/Teachers/MeetingFeed/components/MeetingInfoBoxSkeleton';
import { AssignmentPaper } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { buildFilters } from 'utils/filters';
import { useDialog, useInfiniteScroll } from 'utils/hooks';
import { formatDate } from 'utils/moment';
import { parseParams, reverse, stringifyParams } from 'utils/urls';

import StudentWorkDialog from 'components/StudentWorkDialog';
import Typography from 'components/Typography';

import {
  MeetingInfoBox,
  MeetingInfoBoxSkeleton,
  MeetingShareSkeleton,
  MeetingSorts,
  RandomMeetingShareItemSkeleton,
  SortWorkDialog,
  StudentWorkCard
} from './components';
import { useMeetingInfo, useMeetingShares, useNavigation } from './hooks';
import {
  postMeetingComment,
  postMeetingShareComment,
  updateMeetingTopic
} from './sdk';
import styles from './styles.module.scss';

const AssignmentPaperHeader = ({ share }) => (
  <Typography
    variant="H-TEXT-3"
    color={colors.blue2}
    component={Link}
    to={{
      pathname: reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
        trackerId: share.assignment.tracker_id
      }),
      search: stringifyParams({
        section: share.assignment.id
      })
    }}
    className={styles.link}
  >
    {share.assignment.name} - {share.assignment.section?.name}
  </Typography>
);

const MeetingFeed = () => {
  const { invitationIdentifier } = useParams();
  const history = useHistory();

  const queryParams = parseParams(history.location.search);

  const backTo = _.get(queryParams, 'backTo', null);

  const [
    meetingInfoLoading,
    meetingInfoError,
    meetingInfo,
    setMeetingInfo,
    refetchMeetingInfo
  ] = useMeetingInfo(invitationIdentifier);

  const { data: meetings } = useTeamMeetings({
    teamId: meetingInfo?.team?.id
  });

  const selectMeeting = useCallback(
    (invitationIdentifier) => {
      history.push({
        pathname: reverse(TEACHERS_MEETING_FEED_URL, { invitationIdentifier }),
        search: history.location.search
      });
    },
    [history]
  );

  const accepInvitation = useCallback(async () => {
    const { success } = await acceptMeetingInvitation({
      invitationIdentifier
    });

    if (success) {
      refetchMeetingInfo();
    } else {
      history.push(TEACHERS_PERMISSION_DENIED_URL);
    }
  }, [history, invitationIdentifier, refetchMeetingInfo]);

  useEffect(() => {
    // teacher does not have permission to see the meeting
    if (!_.isNil(meetingInfoError)) {
      accepInvitation();
    }
  }, [meetingInfoError, accepInvitation]);

  const {
    data: meetingBoards,
    refetch: refetchMeetingBoards,
    isLoading: meetingBoardsLoading
  } = useMeetingBoards({
    selectedMeetingId: meetingInfo?.id
  });

  const filters = useMemo(
    () => meetingInfo && { meetingId: meetingInfo.id },
    [meetingInfo]
  );

  const [{ data, loading: meetingSharesLoading }, loadNextMeetingShareItems] =
    useInfiniteScroll(useMeetingShares, filters, 5);

  const [visibleMeetingShares, setVisibleMeetingShares] = useState([]);

  useEffect(() => {
    if (!meetingSharesLoading && !_.isEmpty(data.results)) {
      setVisibleMeetingShares(data.results);
    }
  }, [data.results, meetingSharesLoading]);

  const onBackButtonClick = useCallback(() => {
    if (!_.isNil(backTo)) {
      history.push(backTo);
    } else {
      history.push(TEACHERS_MEETINGS_LIST_URL);
    }
  }, [history, backTo]);

  useNavigation({
    teamName: meetingInfo?.team?.name,
    meetings,
    selectedMeeting: meetingInfo,
    onMeetingSelect: selectMeeting,
    onBackButtonClick
  });

  const {
    isOpened: isSortWorkDialogOpened,
    openDialog: openSortWorkDialog,
    closeDialog: closeSortWorkDialog
  } = useDialog();

  const handleAddingMeetingShareComment = useCallback(
    ({ meetingShareId, parentId, text }) => {
      if (text === '') return;

      postMeetingShareComment({ meetingShareId, text, parent: parentId }).then(
        (data) => {
          setVisibleMeetingShares((prevMeetingFeed) => {
            let updatedFeedData = _.cloneDeep(prevMeetingFeed);
            const updatedFeedItemIndex = _.findIndex(updatedFeedData, {
              id: meetingShareId
            });

            if (_.isNil(parentId)) {
              // This is a top-level comment
              updatedFeedData[updatedFeedItemIndex].comments = [
                ..._.get(updatedFeedData[updatedFeedItemIndex], 'comments', []),
                data
              ];
            } else {
              // This is a reply comment
              const parentCommentIndex = _.findIndex(
                updatedFeedData[updatedFeedItemIndex].comments,
                {
                  id: parentId
                }
              );
              updatedFeedData[updatedFeedItemIndex].comments[
                parentCommentIndex
              ].replies = [
                ..._.get(
                  updatedFeedData[updatedFeedItemIndex],
                  `comments[${parentCommentIndex}].replies`,
                  []
                ),
                data
              ];
            }
            return updatedFeedData;
          });
        }
      );
    },
    [setVisibleMeetingShares]
  );

  const handleAddMeetingComment = useCallback(
    ({ text, parentId }) => {
      if (text === '') return;

      const requestData = buildFilters({
        meetingId: meetingInfo.id,
        parent: parentId,
        text
      });

      postMeetingComment(requestData).then((data) => {
        setMeetingInfo((prevMeetingInfo) => {
          let updatedComments = _.cloneDeep(prevMeetingInfo.comments);
          if (_.isNil(parentId)) {
            // This is a top-level comment. TODO: Use this when clicking the "Add note" button in future
            const newMeetingComment = {
              ...data,
              attachments: [],
              replies: []
            };
            updatedComments = [newMeetingComment, ...updatedComments];
          } else {
            const updatedCommentItemIndex = _.findIndex(updatedComments, {
              id: parentId
            });
            updatedComments[updatedCommentItemIndex].replies.push(data);
          }
          return { ...prevMeetingInfo, comments: updatedComments };
        });
      });
    },
    [setMeetingInfo, meetingInfo?.id]
  );

  const onTopicUpdate = useCallback(
    (newTopic) => {
      setMeetingInfo((prev) => ({ ...prev, topic: newTopic }));
      updateMeetingTopic({ meetingId: meetingInfo.id, newTopic });
    },
    [setMeetingInfo, meetingInfo?.id]
  );

  const {
    isOpened: isStudentWorkDialogOpened,
    closeDialog: closeStudentWorkDialog,
    dialogData: studentWorkDialogData
  } = useDialog();

  const meetingSharesWithStudentWorks = useMemo(() => {
    const meetingAssignments = _.get(meetingInfo, 'assignments', []);

    return meetingAssignments.filter((assignment) => !assignment.is_empty);
  }, [meetingInfo]);

  return (
    <>
      <div className={styles.container}>
        {meetingInfoLoading && <MeetingInfoBoxSkeleton />}
        {!meetingInfoLoading && !_.isEmpty(meetingInfo) && (
          <>
            <MeetingInfoBox
              meetingInfo={meetingInfo}
              handleAddMeetingComment={handleAddMeetingComment}
              onTopicUpdate={onTopicUpdate}
              sortWorkDialogButtonDisabled={_.isEmpty(visibleMeetingShares)}
              onAddAssignmentSuccess={refetchMeetingInfo}
              onShareMeetingUpdateSuccess={refetchMeetingInfo}
            />
            {meetingBoardsLoading && meetingInfo.id && <MeetingSortsSkeleton />}
            {!meetingBoardsLoading && meetingInfo.id && (
              <MeetingSorts
                meetingShares={meetingSharesWithStudentWorks}
                sorts={meetingBoards}
                meetingId={meetingInfo?.id}
                invitationIdentifier={invitationIdentifier}
                onCreateMeetingSort={openSortWorkDialog}
                onDeleteSortSuccess={refetchMeetingBoards}
              />
            )}
          </>
        )}
        {meetingSharesLoading && <MeetingShareSkeleton />}
        <InfiniteScroll
          className={styles.feedContainer}
          dataLength={visibleMeetingShares.length}
          next={loadNextMeetingShareItems}
          hasMore={_.get(data, 'next')}
          loader={
            <div className={styles.loadingPlaceholder}>
              <RandomMeetingShareItemSkeleton />
            </div>
          }
        >
          {_.map(visibleMeetingShares, (meetingShareItem) => (
            <div key={meetingShareItem.id}>
              <Typography
                variant="S-TEXT-1"
                color={colors.grey3}
                className={styles.shareInfoTitle}
              >{`${meetingShareItem.created_by?.name} shared on ${formatDate(
                meetingShareItem.created_at
              )}`}</Typography>
              <AssignmentPaper
                assignment={meetingShareItem.assignment}
                assignmentTasksCount={meetingShareItem.student_work.length}
                headerComponent={
                  <AssignmentPaperHeader share={meetingShareItem} />
                }
                comments={meetingShareItem.comments}
                addComment={(_event, text) =>
                  handleAddingMeetingShareComment({
                    meetingShareId: meetingShareItem.id,
                    parentId: _.get(_.last(meetingShareItem.comments), 'id'),
                    text
                  })
                }
              />
              <div className={styles.tasksContainer}>
                {_.map(meetingShareItem.student_work, (sw) => (
                  <StudentWorkCard
                    key={sw.id}
                    assignmentId={meetingShareItem.assignment.id}
                    trackerId={meetingShareItem.assignment.tracker_id}
                    studentData={sw.student}
                    initalComments={sw.comments}
                    tasksData={sw.tasks}
                  />
                ))}
              </div>
            </div>
          ))}
        </InfiniteScroll>
        {isStudentWorkDialogOpened && (
          <StudentWorkDialog
            {...studentWorkDialogData}
            onClose={closeStudentWorkDialog}
          />
        )}
        {isSortWorkDialogOpened && (
          <SortWorkDialog
            invitationIdentifier={invitationIdentifier}
            meetingShares={meetingSharesWithStudentWorks}
            onClose={closeSortWorkDialog}
          />
        )}
      </div>
    </>
  );
};

export default MeetingFeed;
