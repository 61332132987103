import React from 'react';

import Button from '@material-ui/core/Button';
import cx from 'classnames';

import CameraImg from './camera.png';
import styles from './styles.module.scss';

const WebcamError = () => (
  <div className={styles.container}>
    <h2 className={cx(styles.header, styles.pink)}>Uh-oh!</h2>
    <img
      className={styles.cameraImg}
      src={CameraImg}
      alt="Camera placeholder"
    />
    <h2 className={cx(styles.header, styles.blue)}>Can't access camera</h2>
    <span className={styles.info}>
      Try turning off your camera on Zoom/Video Calls
    </span>
    <Button
      color="secondary"
      variant="contained"
      fullWidth
      onClick={() => window.location.reload(false)}
    >
      Try Again
    </Button>
  </div>
);

export default WebcamError;
