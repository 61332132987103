import { useEffect } from 'react';
import { matchPath } from 'react-router';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import { reverse } from 'utils/urls';

import { BulkUploadStatusToUrl } from './constants';

export const useBulkUploadStatusRedirect = ({
  bulkUpload,
  isLoading = false
}) => {
  const history = useHistory();
  const location = history.location;

  useEffect(() => {
    if (_.isNil(bulkUpload) || isLoading) {
      return;
    }

    const expectedUrl = BulkUploadStatusToUrl[bulkUpload.status];

    if (
      _.isNull(
        matchPath(location.pathname, {
          path: expectedUrl,
          exact: false
        })
      )
    ) {
      history.push(
        reverse(expectedUrl, {
          bulkUploadId: bulkUpload.id
        })
      );
    }
  }, [bulkUpload, history, location.pathname, isLoading]);
};
