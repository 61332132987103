export const MuiButton = (palette) => ({
  contained: {
    borderRadius: 25,
    minWidth: 140,
    minHeight: 40,
    fontSize: 14
  },
  sizeLarge: {
    height: 50,
    fontSize: 'initial'
  },
  containedSecondary: {
    backgroundColor: palette.common.colors.blue
  }
});
