import React, { useCallback, useContext, useMemo, useState } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import cx from 'classnames';
import { ReactComponent as RightArrow } from 'images/arrow-right.svg';
import { ReactComponent as MinusIcon } from 'images/minusicon.svg';
import { ReactComponent as PlusIcon } from 'images/plusicon.svg';
import _ from 'lodash';
import { useAcceleratedCurriculums, useCurriculums } from 'sdk';

import { AssignmentDialogModes } from 'pages/Teachers/shared/AssignmentDialog';

import AssignmentCreateDialogContext from 'components/AssignmentCreateDialogContext';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const CurriculumNode = ({ isAcceleratedCurriculum, parentId = null }) => {
  const useCurriculumsHook = useMemo(() => {
    if (isAcceleratedCurriculum) {
      return useAcceleratedCurriculums;
    }
    return useCurriculums;
  }, [isAcceleratedCurriculum]);

  const [openCurriculums, setOpenCurriculums] = useState([]);
  const [renderedNodes, setRenderedNodes] = useState({});

  const measuredRef = useCallback((node, curriculum) => {
    if (!_.isNil(node)) {
      setRenderedNodes((prev) => {
        if (!_.hasIn(prev, curriculum.id)) {
          return {
            ...prev,
            [curriculum.id]: node.getBoundingClientRect().height
          };
        }

        return prev;
      });
    }
  }, []);

  const { open: openCreateAssignmentDialog } = useContext(
    AssignmentCreateDialogContext
  );
  const { data: curriculums, isLoading } = useCurriculumsHook({ parentId });

  const handleClick = ({ curriculum }) => {
    if (curriculum.has_children) {
      toggleCurriculum({ curriculumId: curriculum.id });
    } else if (!_.isNil(curriculum.assignment_template)) {
      openCreateAssignmentDialog({
        mode: AssignmentDialogModes.CREATE_FROM_TEMPLATE,
        assignmentTemplateId: curriculum.assignment_template.id,
        isAcceleratedCurriculum: isAcceleratedCurriculum
      });
    }
  };

  const toggleCurriculum = ({ curriculumId }) =>
    setOpenCurriculums((prevValue) => _.xor(prevValue, [curriculumId]));

  return isLoading || _.isNil(curriculums)
    ? null
    : curriculums.results.map((curriculum, index) => {
        const isOpen = openCurriculums.includes(curriculum.id);

        const hasChildren = curriculum.has_children;
        const isAssignmentTemplate = // We do not want to allow parent curriculum objects to be assignment templates
          !hasChildren && !_.isNil(curriculum.assignment_template);

        const isClickable = curriculum.has_children || isAssignmentTemplate;

        // A node has a parent level line if it's open, has a parent and is not the last child in the chain.
        const isLastChild = index === curriculums.results.length - 1;
        const isRootCurriculum = _.isNil(parentId);
        const hasParentLevelLine = isOpen && !isRootCurriculum && !isLastChild;

        const node = renderedNodes[curriculum.id];

        const nodeHeight = _.isNil(node) ? 0 : node;

        const beforeHeight = isLastChild ? nodeHeight / 2 : nodeHeight;

        return (
          <div
            className={styles.wrapper}
            key={curriculum.id}
            data-intercom-target={
              index === 0 && parentId === null
                ? 'Assignment Create Dialog First Curriculum Node'
                : undefined
            }
          >
            <div
              className={cx(styles.container, {
                [styles.withoutConnectorLine]: isRootCurriculum
              })}
              ref={(node) => measuredRef(node, curriculum)}
              style={{
                '--connector-line-height': `${beforeHeight}px`
              }}
            >
              <div className={styles.iconContainer}>
                {hasChildren && (
                  <div
                    onClick={() =>
                      toggleCurriculum({ curriculumId: curriculum.id })
                    }
                    className={styles.icon}
                  >
                    {isOpen ? <MinusIcon /> : <PlusIcon />}
                  </div>
                )}
              </div>

              <div
                className={cx(styles.content, {
                  [styles.clickable]: isClickable
                })}
                onClick={() => handleClick({ curriculum })}
              >
                <Typography variant="S-TEXT-1" className={styles.title}>
                  {curriculum.title}
                </Typography>
                <Typography variant="B-TEXT-1" className={styles.description}>
                  {curriculum.description}
                </Typography>
                {isAssignmentTemplate && <SvgIcon component={RightArrow} />}
              </div>
            </div>
            {isOpen && (
              <div
                className={cx(styles.indented, {
                  [styles.withParentLevelLine]: hasParentLevelLine
                })}
              >
                <CurriculumNode
                  isAcceleratedCurriculum={isAcceleratedCurriculum}
                  parentId={curriculum.id}
                />
              </div>
            )}
          </div>
        );
      });
};

export default CurriculumNode;
