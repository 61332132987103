import _ from 'lodash';

import { ImgixDimensions } from 'utils/constants';

const ImgixDimensionsValues = {
  [ImgixDimensions.SMALL]: { width: 140, height: 140 },
  [ImgixDimensions.MEDIUM]: { width: 660, height: 400 },
  [ImgixDimensions.LARGE]: { width: 1520, height: '' }
};

export const setImgixParams = (imgUrl, dimensions = ImgixDimensions.MEDIUM) => {
  if (_.isEmpty(imgUrl)) {
    return '';
  }

  if (!_.startsWith(imgUrl, 'https')) {
    return imgUrl;
  }

  const { width, height } = _.get(
    ImgixDimensionsValues,
    dimensions,
    ImgixDimensions.MEDIUM
  );

  return `${imgUrl}?auto=compress&w=${width}&h=${height}`;
};

export const setImgixFallbackUrl = (fallbackUrl) => (event) => {
  if (event.target.src === fallbackUrl) {
    event.target = null; // prevents infinite loops
  } else {
    event.target.src = fallbackUrl;
  }
};
