import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import classnames from 'classnames';
import _ from 'lodash';

import Flex from 'components/Flex';

import styles from './styles.module.scss';

export const SkeletonRow = ({ lgScreen, numSections = 3 }) => {
  const width = lgScreen ? 130 : 80;

  return (
    <div className={styles.assignmentRow}>
      <div className={classnames({ [styles.titleRowTablet]: !lgScreen })}>
        <Flex
          column={lgScreen}
          alignCenter={!lgScreen}
          spaceBetween={!lgScreen}
        >
          <Skeleton variant="text" width={150} />
          <Skeleton variant="text" width={70} />
        </Flex>
      </div>
      <div className={styles.headerRow}>
        <Skeleton variant="text" width={width} />
        <Skeleton variant="text" width={width} />
        <Skeleton variant="text" width={width} />
        <Skeleton variant="text" width={width} />
        <Skeleton variant="text" width={width} />
      </div>
      {_.map(_.range(numSections), (idx) => (
        <div key={idx} className={styles.sectionRow}>
          <Skeleton variant="text" width={width} />
          <Skeleton variant="text" width={width} />
          <Skeleton variant="text" width={width} />
          <Skeleton variant="text" width={width} />
          <Skeleton variant="text" width={width} />
          <Skeleton variant="text" width={width} />
        </div>
      ))}
    </div>
  );
};
