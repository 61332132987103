import React, { useCallback, useMemo, useState } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import DescriptionIcon from '@material-ui/icons/Description';
import _ from 'lodash';
import {
  meetingSharesCreate,
  useAssignmentsNamesList,
  useRecentlyCreatedAssignments
} from 'sdk';

import { colors } from 'theme/palette';
import { notifyErrors } from 'utils/notifications';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import SearchFilter from 'components/SearchFilter';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const AddAssignmentDialog = ({
  onClose,
  meetingId,
  onAddAssignmentSuccess
}) => {
  const [selectedAssignment, setSelectedAssignment] = useState(null);

  const { data: assignmentsNames } = useAssignmentsNamesList({
    data: { include_prior_years: true }
  });

  const { data: recentlyCreatedAssignmentsData } =
    useRecentlyCreatedAssignments({ params: { limit: 5 } });

  const recentlyCreatedAssignments = useMemo(
    () => _.get(recentlyCreatedAssignmentsData, 'results', []),
    [recentlyCreatedAssignmentsData]
  );

  const handelAddingAssignmentToMeeting = useCallback(
    async ({ assignmentId }) => {
      const { success, errors } = await meetingSharesCreate({
        meetingId,
        data: { assignment: assignmentId }
      });

      if (success) {
        onClose();
        onAddAssignmentSuccess();
      } else {
        notifyErrors(errors);
      }
    },
    [onClose, meetingId, onAddAssignmentSuccess]
  );

  return (
    <Dialog
      open
      alignTop
      maxWidth="md"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <div className={styles.header}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          Add assignment
        </Typography>

        <Button
          color="pink"
          disabled={_.isNil(selectedAssignment)}
          onClick={() =>
            handelAddingAssignmentToMeeting({
              assignmentId: selectedAssignment.tracker_id
            })
          }
          className={styles.button}
        >
          Add
        </Button>
      </div>
      <div className={styles.dialogContent}>
        <SearchFilter
          autoComplete
          getOptionLabel={(assignment) => assignment.name}
          value={selectedAssignment}
          options={assignmentsNames || []}
          onChange={(event, value) => setSelectedAssignment(value)}
          placeholder="Find Assignment"
        />

        {!_.isEmpty(recentlyCreatedAssignments) && (
          <div className={styles.recentAssignments}>
            <Typography variant="S-TEXT-2" color={colors.grey2}>
              Recent Assignments
            </Typography>
            {recentlyCreatedAssignments.map((assignment) => (
              <div
                key={assignment.id}
                className={styles.assignmentItem}
                onClick={() =>
                  handelAddingAssignmentToMeeting({
                    assignmentId: assignment.id
                  })
                }
              >
                <SvgIcon
                  component={DescriptionIcon}
                  color="primary"
                  className={styles.buttonIcon}
                />
                <div className={styles.assignmentName}>
                  <Typography variant="B-TEXT-3" color={colors.blue3}>
                    {assignment.name}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default AddAssignmentDialog;
