import React, { useMemo, useState } from 'react';

import _ from 'lodash';

import { base64UrlSafeEncodeString, stringifyParams } from 'utils/urls';

const CLEVER_AUTH_URL = 'https://clever.com/oauth/authorize';

export const openCleverLoginPage = (redirectUri, state) => {
  const params = {
    response_type: 'code',
    client_id: process.env.REACT_APP_CLEVER_CLIENT_ID,
    redirect_uri: `${process.env.REACT_APP_BASE_BACKEND_URL}/${redirectUri}`,
    state: base64UrlSafeEncodeString(JSON.stringify(state))
  };

  window.location = `${CLEVER_AUTH_URL}?${stringifyParams(params)}`;
};

export const CleverSyncStatuses = {
  INITIATED: {
    value: 'initiated',
    message: 'Syncing with Clever...⏳'
  },
  FAILED: {
    value: 'failed',
    message: 'Clever sync incomplete. Please contact support@edlight.com'
  },
  READY: {
    value: 'ready',
    successMessage: () => (
      <>Your Clever sections & students were synced successfully!</>
    ),
    getWarningMessage: (unsynced_students_count) =>
      `Clever sync complete. We were unable to create accounts for ${unsynced_students_count} students. Please contact support@edlight.com`
  }
};

export const CleverSyncOrigins = {
  DISTRICT: {
    value: 'district',
    label: 'District'
  },
  LIBRARY: {
    value: 'library',
    label: 'Clever'
  }
};

export const useCleverSync = () => {
  const [sync, setSync] = useState(null);

  const isInitiated = useMemo(
    () => _.get(sync, 'status') === CleverSyncStatuses.INITIATED.value,
    [sync]
  );

  const isFailed = useMemo(
    () => _.get(sync, 'status') === CleverSyncStatuses.FAILED.value,
    [sync]
  );

  return useMemo(
    () => ({ sync, isInitiated, isFailed, setSync }),
    [sync, isInitiated, isFailed]
  );
};
