import React, { useMemo, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { useOrganizationsSchools } from 'sdk';

import AccordionContainer from './components/AccordionContainer';
import FiltersBar from './components/FiltersBar/index';
import OrganizationSelectDialog from './components/OrganizationSelectDialog';

import AssignmentSelector from '../AssignmentSelector';
import styles from './styles.module.scss';

const Insights = ({
  selectedSchool,
  setSelectedSchool,
  selectedOrganization,
  setSelectedOrganization
}) => {
  const { data: organizations, isLoading } = useOrganizationsSchools();

  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);

  const assignmentSelected =
    selectedSection && selectedUnit && selectedLesson && selectedGrade;

  const savedFilterSearch = localStorage.getItem(`strategiesFilterSearch`);
  const intialFilterSortParams = useMemo(() => {
    if (savedFilterSearch) {
      return JSON.parse(savedFilterSearch);
    } else {
      return {
        ascending: true,
        sort_by: 'name_first'
      };
    }
  }, [savedFilterSearch]);

  const [filterSortParams, setFilterSortParams] = useState(
    intialFilterSortParams
  );

  const onAssignmentSelected = ({
    selectedSection,
    selectedGrade,
    selectedUnit,
    selectedLesson
  }) => {
    setSelectedSection(selectedSection);
    setSelectedGrade(selectedGrade);
    setSelectedUnit(selectedUnit);
    setSelectedLesson(selectedLesson);
  };

  if (isLoading) {
    return (
      <div className={styles.progress}>
        <CircularProgress size={60} />
      </div>
    );
  }
  return (
    <>
      {selectedSchool && (
        <AssignmentSelector
          selectedSchool={selectedSchool}
          onChange={onAssignmentSelected}
        />
      )}
      {assignmentSelected && (
        <FiltersBar
          selectedSection={selectedSection}
          selectedGrade={selectedGrade}
          selectedUnit={selectedUnit}
          selectedLesson={selectedLesson}
          filterSortParams={filterSortParams}
          setFilterSortParams={setFilterSortParams}
        />
      )}
      {assignmentSelected && (
        <AccordionContainer
          selectedSection={selectedSection}
          selectedUnit={selectedUnit}
          selectedLesson={selectedLesson}
          selectedGrade={selectedGrade}
          filterSortParams={filterSortParams}
        />
      )}
      {(!selectedSchool || !selectedOrganization) && (
        <OrganizationSelectDialog
          organizations={organizations}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          selectedSchool={selectedSchool}
          setSelectedSchool={setSelectedSchool}
        />
      )}
    </>
  );
};

export default Insights;
