import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import MomentUtils from '@date-io/moment';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import classnames from 'classnames';
import _ from 'lodash';
import { getItemFromLocalStorage } from 'utils';

import { AssignmentCreateDialogContextProvider } from 'components/AssignmentCreateDialogContext';
import { AssignmentDialogContextProvider } from 'components/AssignmentDialogContext';

import { SideNav, TopBar } from './components';
import styles from './styles.module.scss';

const TeacherLayoutContext = React.createContext();

const SIDE_NAVIGATION_IS_OPENED = 'sideNavigationIsOpened';

const TeacherPageLayout = ({ children, width }) => {
  const lgScreen = isWidthUp('lg', width);

  const sideNavIsOpened = getItemFromLocalStorage({
    item: SIDE_NAVIGATION_IS_OPENED
  });

  const [sideNavOpen, setSideNavOpen] = useState(
    _.isNil(sideNavIsOpened) ? true : sideNavIsOpened
  );
  const toggleSideNav = useCallback(() => {
    setSideNavOpen((prev) => !prev);
  }, []);

  const [title, setTitle] = useState();
  const [onTitleClick, setOnTitleClick] = useState();
  const [onBackButtonClick, setOnBackButtonClick] = useState();
  const [LeftSubheader, setLeftSubheader] = useState();
  const [RightSubheader, setRightSubheader] = useState();
  const [sticky, setSticky] = useState(false);

  const [noTopNavigation, setNoTopNavigation] = useState(false);
  const topNavigationProps = {
    sideNavOpen,
    title,
    onTitleClick,
    onBackButtonClick,
    lgScreen,
    toggleSideNav,
    LeftSubheader,
    RightSubheader,
    sticky
  };

  useEffect(() => {
    localStorage.setItem(SIDE_NAVIGATION_IS_OPENED, sideNavOpen);
  }, [sideNavOpen]);

  useEffect(() => {
    setSideNavOpen((prevOpenValue) => {
      if (prevOpenValue && !lgScreen) {
        return false;
      }
      return prevOpenValue;
    });
  }, [lgScreen]);

  const navigation = useMemo(
    () => ({
      setTitle,
      setOnTitleClick,
      setOnBackButtonClick,
      setNoTopNavigation,
      setLeftSubheader,
      setRightSubheader,
      setSticky
    }),
    []
  );
  const sideNav = useMemo(
    () => ({
      toggle: toggleSideNav,
      open: sideNavOpen
    }),
    [toggleSideNav, sideNavOpen]
  );

  return (
    <TeacherLayoutContext.Provider
      value={{
        navigation: navigation,
        sideNav: sideNav
      }}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AssignmentDialogContextProvider>
          <AssignmentCreateDialogContextProvider>
            <div
              className={classnames(styles.root, {
                [styles.noTopNavigation]: noTopNavigation
              })}
            >
              {!noTopNavigation && <TopBar {...topNavigationProps} />}
              <SideNav
                open={sideNavOpen}
                toggle={toggleSideNav}
                lgScreen={lgScreen}
              />
              {/* wrap this inside a "should component update" to prevent infinite loops */}
              <main
                className={classnames(styles.content, {
                  [styles.small]: lgScreen && sideNavOpen,
                  [styles.fullWidth]: !lgScreen
                })}
              >
                {children}
              </main>
            </div>
          </AssignmentCreateDialogContextProvider>
        </AssignmentDialogContextProvider>
      </MuiPickersUtilsProvider>
    </TeacherLayoutContext.Provider>
  );
};

const withNavbar = (
  Component,
  {
    title,
    onTitleClick,
    onBackButtonClick,
    LeftSubheader,
    RightSubheader,
    sticky
  } = {}
) => {
  class Wrapper extends React.Component {
    componentDidMount() {
      title && this.navigation.setTitle(title);
      onTitleClick && this.navigation.setOnTitleClick(onTitleClick);
      onBackButtonClick &&
        this.navigation.setOnBackButtonClick(onBackButtonClick);
      LeftSubheader && this.navigation.setLeftSubheader(LeftSubheader);
      RightSubheader && this.navigation.setRightSubheader(RightSubheader);
      sticky && this.navigation.setSticky(sticky);
    }

    componentWillUnmount() {
      if (this.navigation) {
        this.navigation.setTitle(null);
        this.navigation.setOnTitleClick(null);
        this.navigation.setOnBackButtonClick(null);
        this.navigation.setLeftSubheader(null);
        this.navigation.setRightSubheader(null);
        this.navigation.setSticky(false);
      }
    }

    render() {
      return (
        <TeacherLayoutContext.Consumer>
          {(hocProps) => {
            // For proper unmounting
            if (!this.navigation) {
              this.navigation = hocProps.navigation;
            }
            return <Component {...this.props} {...hocProps} />;
          }}
        </TeacherLayoutContext.Consumer>
      );
    }
  }
  return Wrapper;
};

export const useNavbar = ({
  title,
  onTitleClick,
  onBackButtonClick,
  LeftSubheader,
  RightSubheader,
  sticky
}) => {
  const navbar = useContext(TeacherLayoutContext);

  useEffect(() => {
    return () => {
      navbar.navigation.setTitle(null);
      navbar.navigation.setOnTitleClick(null);
      navbar.navigation.setOnBackButtonClick(null);
      navbar.navigation.setLeftSubheader(null);
      navbar.navigation.setRightSubheader(null);
      navbar.navigation.setSticky(false);
    };
  }, [navbar.navigation]);

  useEffect(() => {
    if (title) {
      navbar.navigation.setTitle(title);
    }
  }, [title, navbar.navigation]);

  useEffect(() => {
    if (onTitleClick) {
      navbar.navigation.setOnTitleClick(() => onTitleClick);
    }
  }, [onTitleClick, navbar.navigation]);

  useEffect(() => {
    if (onBackButtonClick) {
      navbar.navigation.setOnBackButtonClick(() => onBackButtonClick);
    }
  }, [onBackButtonClick, navbar.navigation]);

  useEffect(() => {
    if (LeftSubheader) {
      navbar.navigation.setLeftSubheader(LeftSubheader);
    }
  }, [LeftSubheader, navbar.navigation]);

  useEffect(() => {
    if (RightSubheader) {
      navbar.navigation.setRightSubheader(RightSubheader);
    }
  }, [RightSubheader, navbar.navigation]);

  useEffect(() => {
    if (sticky) {
      navbar.navigation.setSticky(sticky);
    }
  }, [sticky, navbar.navigation]);

  return navbar;
};

export { TopBarSelect } from './components';
export { withNavbar };

export default withWidth()(TeacherPageLayout);
