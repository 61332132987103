import * as Sentry from '@sentry/react';

import adaptiveThreshold from 'adaptive-threshold';
import { fabric } from 'fabric';
import getPixels from 'get-pixels';
import moment from 'moment';
import savePixels from 'save-pixels';

import palette from 'theme/palette';
import { notifyError } from 'utils/notifications';

export const resizeCanvas = ({ width, height }, canvas) => {
  canvas.setWidth(width);
  canvas.setHeight(height);
  canvas.calcOffset();
  return canvas;
};

const setInitialImage = (imageObject, canvas) => {
  const fabricImage = new fabric.Image(imageObject, {
    left: 0,
    top: 0,
    originX: 'left',
    originY: 'top',
    selectable: false
  });
  fabricImage.isInitialImage = true;

  canvas.add(fabricImage).requestRenderAll();

  return fabricImage;
};

const setInitialImageFromUrl = (imageUrl, canvas, currentEventTimestamp) => {
  return new Promise((resolve, reject) => {
    //https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
    fabric.Image.fromURL(
      `${imageUrl}?dont-cache=true`,
      (image) => {
        image.set({
          left: 0,
          top: 0,
          originX: 'left',
          originY: 'top',
          selectable: false
        });
        image.isInitialImage = true;

        if (currentEventTimestamp === canvas.latestImageTimestamp) {
          canvas.add(image).requestRenderAll();
          resolve(image);
        } else {
          reject();
        }
      },
      {
        crossOrigin: 'Anonymous'
      }
    );
  });
};

const resizeAndScaleCanvas = (image, canvas) => {
  const imageContainer = document.getElementById('image-container');
  if (!imageContainer) {
    return;
  }

  const containerWidth = imageContainer.getBoundingClientRect().width;
  const newHeight = containerWidth * (image.height / image.width);

  // First resize to get the relative height to add a scrollbar on the Window if needed
  resizeCanvas({ width: containerWidth, height: newHeight }, canvas);

  // Second resize to take into account the eventual scrollbar on the window (that shortens the window width on Linux/Windows)
  fitImageAndCanvasToContainer(canvas);
  canvas.setZoom(canvas.getWidth() / image.width);
  canvas.initialZoom = canvas.getWidth() / image.width;
};

export const drawInitialImageObject = (canvas, imageObject) => {
  canvas.clear();

  const fabricImage = setInitialImage(imageObject, canvas);

  resizeAndScaleCanvas(fabricImage, canvas);
  // used when resetting crop
  canvas.initialWidth = canvas.getWidth();
  canvas.initialHeight = canvas.getHeight();
};

export const drawInitialImage = (canvas, src) => {
  // Make sure there are no old objects in the canvas
  canvas.clear();
  // TODO: add loading animation here

  const drawImageCallTimestamp = moment();

  // This is mutable, so it holds the proper time in every instance of setInitialImageFromUrl;
  if (
    !canvas.latestImageTimestamp ||
    canvas.latestImageTimestamp < drawImageCallTimestamp
  ) {
    canvas.latestImageTimestamp = drawImageCallTimestamp;
  }

  return setInitialImageFromUrl(src, canvas, drawImageCallTimestamp)
    .then((image) => {
      resizeAndScaleCanvas(image, canvas);
      // used when resetting crop
      canvas.initialWidth = canvas.getWidth();
      canvas.initialHeight = canvas.getHeight();
    })
    .catch(() => {
      // The image was not loaded because another image request was started after it and loaded faster.
      // No harm done, we just need to avoid showing the image and resizing the canvas
    });
};

// As of commit 1b330051 it supports rotating only +90 and -90 degrees
export const rotateCanvas = (
  canvas,
  angle = 90,
  shouldFitImageAndCanvasToContainer = true
) => {
  canvas = resizeCanvas(
    { width: canvas.getHeight(), height: canvas.getWidth() },
    canvas
  );

  canvas.getObjects().forEach((object) => {
    let newLeft = object.top;
    let newTop = canvas.getHeight() / canvas.getZoom() - object.left;

    if (angle === 90) {
      newLeft = canvas.getWidth() / canvas.getZoom() - object.top;
      newTop = object.left;
    }

    object.set({
      left: newLeft,
      top: newTop,
      angle: object.angle + angle
    });
    object.setCoords();
  });
  canvas.requestRenderAll();

  if (shouldFitImageAndCanvasToContainer) {
    fitImageAndCanvasToContainer(canvas);
  }
};

const scaleByCanvasZoom = (value, zoom) => value / zoom;

export const addSticker = (canvas, sticker) => {
  const canvasZoom = canvas.getZoom();

  fabric.loadSVGFromURL(sticker, (objects, options) => {
    const stickerSVG = fabric.util.groupSVGElements(objects, options);
    const tempZoom = 6;
    stickerSVG.set({
      left: scaleByCanvasZoom(canvas.width / 2, canvasZoom),
      top: scaleByCanvasZoom(canvas.height / 2, canvasZoom),
      originX: 'center',
      originY: 'center',
      scaleY: scaleByCanvasZoom(tempZoom, canvasZoom),
      scaleX: scaleByCanvasZoom(tempZoom, canvasZoom)
    });
    canvas.add(stickerSVG).setActiveObject(stickerSVG).requestRenderAll();
  });
};

export const getInitialImage = (canvas) => {
  return canvas.getObjects().filter((object) => object.isInitialImage)[0];
};

export const fitImageAndCanvasToContainer = (canvas) => {
  const container = document.getElementById('image-container');
  const containerWidth = container
    ? container.getBoundingClientRect().width
    : 0;
  const initialImage = getInitialImage(canvas);
  if (initialImage) {
    const canvasRatio =
      canvas.cropRatio || initialImage.height / initialImage.width;
    const newWidth = containerWidth;
    const newHeight = containerWidth * canvasRatio;

    // That's just multiplication by the matrix of rotation and then taking the ABS
    let multipliedWidth = Math.abs(
      newWidth * Math.round(Math.cos(Math.PI * (-initialImage.angle / 180))) -
        newHeight * Math.round(Math.sin(Math.PI * (-initialImage.angle / 180)))
    );

    let multipliedHeight = Math.abs(
      newWidth * Math.round(Math.sin(Math.PI * (-initialImage.angle / 180))) +
        newHeight * Math.round(Math.cos(Math.PI * (-initialImage.angle / 180)))
    );

    // If the image was portrait mode, contain it within the viewport
    if (multipliedWidth > containerWidth) {
      // Note: the order here matters
      multipliedHeight = multipliedHeight * (containerWidth / multipliedWidth);
      multipliedWidth = containerWidth;
    }

    resizeCanvas(
      {
        width: multipliedWidth,
        height: multipliedHeight
      },
      canvas
    );

    let zoom = canvas.getHeight() / initialImage.width;

    if (Math.round(initialImage.angle % 180) === 0) {
      zoom = canvas.getWidth() / initialImage.width;
    }

    canvas.setZoom(zoom);
    canvas.initialZoom = zoom;
  }
};

export const groupCanvasUp = (canvas) => {
  const group = new fabric.Group(canvas.getObjects());

  canvas.getObjects().forEach((object) => canvas.remove(object));
  canvas.add(group);

  return group;
};

export const ungroupCanvas = (canvas) => {
  if (canvas.getObjects().length > 1) {
    console.error('More than 1 objects in canvas');
    return;
  }
  if (canvas.getObjects()[0].type === 'group') {
    const group = canvas.getObjects()[0];
    group.toActiveSelection();
    canvas.discardActiveObject();
    canvas.renderAll();
  }
};

export const cropImage = (canvas, { x, y, width, height }, setCropArea) => {
  const initialImage = getInitialImage(canvas);
  const zoom = canvas.getZoom();

  const timesRotated = parseInt((Math.round(initialImage.angle) / 90) % 4);

  // this is => if (timesImageHasBeenRotated === 0) {
  let _x = x;
  let _y = y;
  let _width = width;
  let _height = height;

  if (timesRotated === 1) {
    _x = y;
    _y = canvas.getWidth() - (width + x);
    _width = height;
    _height = width;
  }

  if (timesRotated === 2) {
    _x = canvas.getWidth() - (width + x);
    _y = canvas.getHeight() - (height + y);
    _width = width;
    _height = height;
  }

  if (timesRotated === 3) {
    _x = canvas.getHeight() - (height + y);
    _y = x;
    _width = height;
    _height = width;
  }

  for (let i = 0; i < timesRotated; i++) {
    rotateCanvas(canvas, -90, false);
  }

  const scale = canvas.getWidth() / _width;

  setCropArea({ x: _x, y: _y, width: _width, height: _height });

  // Used when resizing the window/canvas/image
  canvas.cropRatio = _height / _width;
  fitImageAndCanvasToContainer(canvas);

  const group = groupCanvasUp(canvas);
  group.set({
    left: (-_x * scale) / zoom,
    top: (-_y * scale) / zoom,
    scaleX: scale,
    scaleY: scale
  });
  ungroupCanvas(canvas);

  for (let i = 0; i < timesRotated; i++) {
    rotateCanvas(canvas, 90, false);
  }
};

export const addTextBox = (canvas) => {
  const canvasZoom = canvas.getZoom();

  const textBox = new fabric.Textbox('Add Note', {
    width: 375,
    fontSize: 50,
    lineHeight: 0.75,
    left: scaleByCanvasZoom(canvas.width / 2, canvasZoom),
    top: scaleByCanvasZoom(canvas.height / 4, canvasZoom),
    originX: 'center',
    originY: 'center',
    scaleX: scaleByCanvasZoom(1, canvasZoom),
    scaleY: scaleByCanvasZoom(1, canvasZoom),
    fill: palette.primary.main,
    fontFamily: 'Nunito'
  });

  canvas.add(textBox);
  canvas.setActiveObject(textBox);
  canvas.requestRenderAll();
  textBox.enterEditing();
  textBox.selectAll();

  // Select everything in the text box when it's clicked
  // textBox.on('mousedown', event => event.target.selectAll());
};

export const addElement = (canvas, { element }) => {
  canvas.add(element);
};

export const deleteElement = (canvas, { element }) => {
  canvas.remove(element);
};

export const turnOffDrawingMode = (canvas) => {
  canvas.isDrawingMode = false;
};

export const toggleDrawingMode = (canvas) => {
  canvas.isDrawingMode = !canvas.isDrawingMode;
  if (canvas.freeDrawingBrush) {
    canvas.freeDrawingBrush.width = 4;
    canvas.freeDrawingBrush.color = palette.primary.main;
  }
};

export const applyFilter = (canvas, { imageSrc, threshold }) => {
  getPixels(imageSrc, async (err, pixels) => {
    try {
      const filteredImagePixels = adaptiveThreshold(pixels, {
        size: 11,
        compensation: threshold
      });

      const initialImage = canvas
        .getObjects()
        .filter((object) => object.isInitialImage)[0];
      const filteredImage = new Image();

      filteredImage.onerror = notifyError;

      filteredImage.onload = () => {
        // Remove previous filtered images
        canvas
          .getObjects()
          .filter((object) => object.isFilteredImage)
          .forEach((object) => canvas.remove(object));

        const fabricFilteredImage = new fabric.Image(filteredImage, {
          left: initialImage.left,
          top: initialImage.top,
          originX: 'left',
          originY: 'top',
          scaleX: initialImage.scaleX,
          scaleY: initialImage.scaleY,
          angle: initialImage.angle,
          selectable: false
        });

        fabricFilteredImage.isFilteredImage = true;
        canvas
          .add(fabricFilteredImage)
          .sendToBack(fabricFilteredImage) // Send the filtered image behind everything else
          .sendToBack(initialImage); // Send the initial image behind everything else and the filtered image
      };
      filteredImage.src = savePixels(filteredImagePixels, 'canvas').toDataURL(
        'image/jpeg',
        1
      );
    } catch (error) {
      console.error(error);
      notifyError('Could not add filter to this image.');
      Sentry.captureException(error, { extra: { imageSrc } });
    }
  });
};

export const removeFilter = (canvas) => {
  canvas
    .getObjects()
    .filter((object) => object.isFilteredImage)
    .forEach((object) => canvas.remove(object));
  canvas.requestRenderAll();
};

export const loadImageFromSrc = (src) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.src = src;
  });
};
