import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PATHWAYS_RESULTS_URL } from 'config/urls';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const GatheringResults = () => {
  const [progress, setProgress] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 90) {
          clearInterval(timer);
          return 100;
        }

        return prevProgress + 18;
      });
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const redirectToResultsPage = () => {
    history.push(PATHWAYS_RESULTS_URL);
  };

  return (
    <div className={styles.container}>
      <div className={styles.scrollableContainer}>
        <div className={styles.progressBar}>
          <LinearProgress
            className={styles.progress}
            value={progress}
            variant="determinate"
          />
          <div className={styles.percentage}>{progress}%</div>
        </div>
        <Typography variant="H-TEXT-1" className={styles.title}>
          That’s it, great job finishing up!
        </Typography>
        <Typography variant="B-Text-1" className={styles.subtitle}>
          We’re just adding the finishing touches to your Pathways experience!
        </Typography>

        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="determinate"
            value={progress}
            size={250}
            thickness={4}
            className={styles.progress}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            className={styles.progressContentBox}
          >
            <Typography
              variant="H-TEXT-1"
              className={styles.progressContent}
            >{`${Math.round(progress)}%`}</Typography>
          </Box>
        </Box>

        <div className={styles.contentContainer}>
          <div className={styles.innerContentContainer}>
            {progress >= 18 && (
              <div className={styles.content}>
                <CheckCircleIcon htmlColor="#61a460" />
                <Typography variant="H-TEXT-3" className={styles.text}>
                  Analyzing your responses
                </Typography>
              </div>
            )}
            {progress >= 36 && (
              <div className={styles.content}>
                <CheckCircleIcon htmlColor="#61a460" />
                <Typography variant="H-TEXT-3" className={styles.text}>
                  Setting your goals
                </Typography>
              </div>
            )}
            {progress >= 54 && (
              <div className={styles.content}>
                <CheckCircleIcon htmlColor="#61a460" />
                <Typography variant="H-TEXT-3" className={styles.text}>
                  Tailoring your learning plan
                </Typography>
              </div>
            )}
            {progress >= 72 && (
              <div className={styles.content}>
                <CheckCircleIcon htmlColor="#61a460" />
                <Typography variant="H-TEXT-3" className={styles.text}>
                  Preparing resources
                </Typography>
              </div>
            )}
          </div>
        </div>

        <div className={styles.bottomButton}>
          <Button
            disabled={progress < 100}
            variant="textAndIcon"
            className={styles.startButton}
            endIcon={<ArrowForwardIosOutlinedIcon className={styles.icon} />}
            onClick={redirectToResultsPage}
          >
            Go To Results
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GatheringResults;
