import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import { DateTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import classnames from 'classnames';

import InputField from 'components/InputField';

const useStyles = makeStyles(() => ({
  field: {
    '& .MuiInputBase-root > input': {
      paddingRight: 0
    },
    '& .MuiInputBase-root > .MuiInputAdornment-root button': { padding: 6 }
  }
}));

// pickerVariant = 'default' | 'keyboard'
const DateTimeField = ({ pickerVariant = 'default', ...props }) => {
  const styles = useStyles();

  const defaultProps = {
    variant: 'dialog',
    disablePast: true,
    format: 'ddd M/DD h:mm A',
    inputVariant: 'underlined', // "standard" | "outlined" | "filled"
    TextFieldComponent: InputField
  };

  let PickerComponent = DateTimePicker;
  if (pickerVariant === 'keyboard') {
    PickerComponent = KeyboardDateTimePicker;
  }

  return (
    <PickerComponent
      {...defaultProps}
      {...props}
      className={classnames(styles.field, props.className)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <TodayRoundedIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default DateTimeField;
