import React from 'react';

import SelectFilter from 'components/SelectFilter';

import styles from './styles.module.scss';

export const getLabelForWork = (work) => {
  if (work.is_feedback) return 'Feedback';
  if (work.is_revision) return 'Revision';
  return 'Original';
};

const StudentWorkSelectInput = ({
  options,
  value,
  onChange,
  disabled,
  onMouseDown = () => {}
}) => {
  return (
    <div className={styles.selectContainer}>
      <SelectFilter
        className={styles.selectFilter}
        classes={{ root: styles.root }}
        options={options}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
        onMouseDown={onMouseDown}
      />
    </div>
  );
};

export default StudentWorkSelectInput;
