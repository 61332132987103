import React from 'react';

import { find, round } from 'lodash';

import ScoreExplanationItem from './components/ScoreExplanationItem';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const ScoreExplanationList = ({ results }) => {
  const teacherKnowledgeAndSkillResult = find(
    results,
    (result) => result.subject === 'Teacher Knowledge & Skill'
  ).result;

  const teacherKnowledgeAndSkillMaxResult = find(
    results,
    (result) => result.subject === 'Teacher Knowledge & Skill'
  ).maxResult;

  const teacherKnowledgeAndSkillPercentage =
    round(
      teacherKnowledgeAndSkillResult / teacherKnowledgeAndSkillMaxResult,
      2
    ) * 100;

  const studentEngagementResult = find(
    results,
    (result) => result.subject === 'Student Engagement'
  ).result;

  const studentEngagementMaxResult = find(
    results,
    (result) => result.subject === 'Student Engagement'
  ).maxResult;

  const studentEngagementPercentage =
    round(studentEngagementResult / studentEngagementMaxResult, 2) * 100;

  const contentRigorAndExcellenceResult = find(
    results,
    (result) => result.subject === 'Content Rigor & Relevance'
  ).result;

  const contentRigorAndExcellenceMaxResult = find(
    results,
    (result) => result.subject === 'Content Rigor & Relevance'
  ).maxResult;

  const contentRigorAndExcellencePercentage =
    round(
      contentRigorAndExcellenceResult / contentRigorAndExcellenceMaxResult,
      2
    ) * 100;

  return (
    <div className={styles.scoreExplanationList}>
      <Typography variant="B-Text-4" className={styles.description}>
        Our AI evaluates your answers and assigns different weights to each
        response to create your personalized learner profile. This way, you
        receive customized support and resources designed just for you and your
        teaching journey.
      </Typography>

      <ScoreExplanationItem
        title="Teacher Knowledge & Skill"
        percentage={parseFloat(teacherKnowledgeAndSkillPercentage.toFixed(2))}
      />
      <ScoreExplanationItem
        title="Student Engagement"
        percentage={parseFloat(studentEngagementPercentage.toFixed(2))}
      />
      <ScoreExplanationItem
        title="Content Rigor & Relevance"
        percentage={parseFloat(contentRigorAndExcellencePercentage.toFixed(2))}
      />
    </div>
  );
};

export default ScoreExplanationList;
