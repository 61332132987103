import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { useSectionGrades } from 'sdk';

import { TopBarSelect } from 'pages/Teachers/shared';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { parseParams, stringifyParams } from 'utils/urls';

import UserContext from 'components/UserContext';

const GradeSelectDropdown = ({
  selectedGrade,
  selectedSection,
  setSelectedGrade,
  setSelectedUnit,
  setSelectedLesson,
  className
}) => {
  const { user } = useContext(UserContext);

  const { data: grades, isLoading } = useSectionGrades({
    sectionId: selectedSection.id
  });

  const history = useHistory();
  const urlParams = parseParams(history.location.search);
  const gradeNumber = _.get(urlParams, 'grade');

  useEffect(() => {
    if (!isLoading) {
      if (!_.isEmpty(grades)) {
        const grade = grades.find((grade) => grade.name === gradeNumber);
        if (grade) {
          setSelectedGrade(grade);
        } else {
          const defaultElement = grades.find((element) => element.is_default);
          setSelectedGrade(defaultElement);

          urlParams.grade = defaultElement.name;
          history.replace({ search: stringifyParams(urlParams) });
        }
      } else {
        setSelectedGrade(null);

        delete urlParams.grade;
        delete urlParams.unit;
        delete urlParams.lesson;
        history.replace({ search: stringifyParams(urlParams) });
      }
    }
  }, [grades, setSelectedGrade, gradeNumber, history, urlParams, isLoading]);

  const selectGrade = (newGrade) => {
    setSelectedLesson(null);
    setSelectedUnit(null);
    setSelectedGrade(newGrade);

    urlParams.grade = newGrade.name;
    history.replace({ search: stringifyParams(urlParams) });

    trackMixpanelEvent(user.id, '[StrategiesOverview] Teacher changed grade.', {
      grade: newGrade.name
    });
  };

  if (_.isEmpty(grades)) return <></>;
  return (
    <TopBarSelect
      value={selectedGrade}
      renderValue={(grade) => `Grade ${_.get(grade, 'name')}`}
      onChange={selectGrade}
      options={grades.map((grade) => ({
        value: grade,
        label: grade.name
      }))}
      className={className}
    />
  );
};

export default GradeSelectDropdown;
