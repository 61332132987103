import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import classnames from 'classnames';
import _ from 'lodash';

import { withNavbar } from 'pages/Teachers/shared';
import {
  default as AssignmentDialog,
  AssignmentDialogModes
} from 'pages/Teachers/shared/AssignmentDialog';
import { colors } from 'theme/palette';
import { calendarDate } from 'utils/moment';
import { notifyError, notifyErrors, notifySuccess } from 'utils/notifications';
import { openInNewTab } from 'utils/urls';

import Button from 'components/Button';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as TrashIcon } from './assets/trash.svg';
import {
  AddTeacherConfirmDialog,
  AssignmentDetailSkeleton,
  AssignmentEditDialog,
  AssignmentListByInstance,
  TasksList
} from './components';
import {
  addTeacherToSections,
  assignmentDetail,
  bulkSoftDeleteAssignments,
  canPreviewAsStudent,
  restoreAssignment,
  softDeleteAssignment,
  updateAssignmentDueDate,
  updateAssignmentOpenDate
} from './sdk';
import styles from './styles.module.scss';

class TeacherAssignmentDetail extends React.Component {
  static contextType = UserContext;

  state = {
    assignmentDetails: null,
    dialogs: {
      assign: {
        open: false
      },
      edit: {
        open: false
      },
      confirm: {
        open: false
      }
    },
    loading: {
      detail: true,
      studentPreview: true
    },
    canPreviewAsStudent: null
  };

  trackerId = this.props.match.params.trackerId;

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    await Promise.all([this.fetchAssignmentDetail()]);
  }

  fetchAssignmentDetail = async () => {
    const { success, data, errors } = await assignmentDetail(this.trackerId);

    if (success) {
      this.setState(
        {
          assignmentDetails: data,
          loading: {
            ...this.state.loading,
            detail: false
          }
        },
        this.checkCanPreviewAsStudent
      );
    } else {
      notifyErrors(errors);
    }
  };

  checkCanPreviewAsStudent = async () => {
    const canPreviewResponses = await Promise.all(
      _.map(this.state.assignmentDetails.instances, ({ id }) =>
        canPreviewAsStudent(id)
      )
    );

    this.setState((prevState) => ({
      canPreviewAsStudent: _.every(
        canPreviewResponses,
        'data.can_preview_as_student'
      ),
      loading: { ...prevState.loading, studentPreview: false }
    }));
  };

  updateDueDate = async (instanceId, assignmentId, dueDate) => {
    const { success, errors } = await updateAssignmentDueDate(
      assignmentId,
      dueDate
    );

    if (success) {
      notifySuccess('Assignment due date successfully updated.');

      const instances = _.cloneDeep(this.state.assignmentDetails.instances);
      const instance = _.find(instances, { id: instanceId });

      const assignmentIndex = _.findIndex(instance.assignments, {
        id: assignmentId
      });

      instance.assignments[assignmentIndex].due_at = dueDate;

      this.setState({
        assignmentDetails: {
          ...this.state.assignmentDetails,
          instances
        }
      });
    } else {
      notifyErrors(errors);
    }
  };

  updateOpenDate = async (instanceId, assignmentId, openDate) => {
    const { success, errors } = await updateAssignmentOpenDate(
      assignmentId,
      openDate
    );

    if (success) {
      notifySuccess('Assignment open date successfully updated.');

      const instances = _.cloneDeep(this.state.assignmentDetails.instances);
      const instance = _.find(instances, { id: instanceId });

      const assignmentIndex = _.findIndex(instance.assignments, {
        id: assignmentId
      });

      instance.assignments[assignmentIndex].open_at = openDate;

      this.setState({
        assignmentDetails: {
          ...this.state.assignmentDetails,
          instances
        }
      });
    } else {
      notifyErrors(errors);
    }
  };

  softDelete = async (instanceId, assignmentId) => {
    const { success } = await softDeleteAssignment(assignmentId);

    if (success) {
      notifySuccess('Assignment deleted.');

      const instances = _.cloneDeep(this.state.assignmentDetails.instances);
      const instance = _.find(instances, { id: instanceId });

      const assignmentIndex = _.findIndex(instance.assignments, {
        id: assignmentId
      });

      instance.assignments[assignmentIndex].deleted = true;

      this.setState({
        assignmentDetails: {
          ...this.state.assignmentDetails,
          instances
        }
      });
    } else {
      notifyError('Failed to delete assignment.');
    }
  };

  softDeleteAll = async () => {
    const {
      assignmentDetails: { instances }
    } = this.state;

    const assignments = _.flatMap(instances, 'assignments');
    const activeAssignments = _.filter(assignments, (x) => !x.deleted);
    const activeAssignmentsIds = _.map(activeAssignments, 'id');

    const { success, errors } = await bulkSoftDeleteAssignments(
      activeAssignmentsIds
    );

    if (success) {
      notifySuccess(
        `Successfully archived ${activeAssignmentsIds.length} assignments.`
      );

      const updatedInstances = _.cloneDeep(instances);
      _.forEach(updatedInstances, (instance) =>
        _.forEach(
          instance.assignments,
          (assignment) => (assignment.deleted = true)
        )
      );

      this.setState({
        assignmentDetails: {
          ...this.state.assignmentDetails,
          instances: updatedInstances
        }
      });
    } else {
      notifyErrors(errors);
    }
  };

  restore = async (instanceId, assignmentId) => {
    const { success } = await restoreAssignment(assignmentId);

    if (success) {
      notifySuccess('Assignment restored.');

      const instances = _.cloneDeep(this.state.assignmentDetails.instances);
      const instance = _.find(instances, { id: instanceId });

      const assignmentIndex = _.findIndex(instance.assignments, {
        id: assignmentId
      });

      instance.assignments[assignmentIndex].deleted = false;

      this.setState({
        assignmentDetails: {
          ...this.state.assignmentDetails,
          instances
        }
      });
    } else {
      notifyError('Failed to restore assignment.');
    }
  };

  openAssignAssignmentDialog = () =>
    this.setState((prevState) => ({
      dialogs: { ...prevState.dialogs, assign: { open: true, defaultStep: 1 } }
    }));

  closeAssignAssignmentDialog = () =>
    this.setState(
      (prevState) => ({
        dialogs: { ...prevState.dialogs, assign: { open: false } }
      }),
      this.fetch
    );

  openAssignmentEditDialog = () =>
    this.setState((prevState) => ({
      dialogs: { ...prevState.dialogs, edit: { open: true } }
    }));

  closeAssignmentEditDialog = () =>
    this.setState(
      (prevState) => ({
        dialogs: { ...prevState.dialogs, edit: { open: false } }
      }),
      this.fetch
    );

  openConfirmDialog = () =>
    this.setState((prevState) => ({
      dialogs: { ...prevState.dialogs, confirm: { open: true } }
    }));
  closeConfirmDialog = () =>
    this.setState((prevState) => ({
      dialogs: { ...prevState.dialogs, confirm: { open: false } }
    }));

  handleStudentPreview = () => {
    if (_.isNull(this.state.canPreviewAsStudent)) {
      return;
    }

    if (!this.state.canPreviewAsStudent) {
      this.openConfirmDialog();
    } else {
      const deeplink = _.get(
        this.state.assignmentDetails,
        'instances[0].student_deeplink'
      );
      openInNewTab(deeplink);
    }
  };

  ensureTeacherInSections = async () => {
    this.setState((prevState) => ({
      ...prevState.loading,
      studentPreview: true
    }));

    // Make sure the current user is teacher in the assignment sections.
    // He could be an admin for example which means that the demo student won't be part from the sections.
    // This makes sure that the teacher can preview the assignment from all assigned sections.
    const sectionIds = _(this.state.assignmentDetails.instances)
      .map('assignments')
      .flatten()
      .map('section_id')
      .value();
    await addTeacherToSections(sectionIds);

    this.setState(
      (prevState) => ({
        canPreviewAsStudent: true,
        loading: { ...prevState.loading, studentPreview: false },
        dialogs: { ...prevState.dialogs, confirm: { open: false } }
      }),
      this.handleStudentPreview
    );
  };

  render() {
    const { assignmentDetails, dialogs, loading } = this.state;

    const lgScreen = isWidthUp('lg', this.props.width);
    const sideNavOpen = this.props.sideNav.open;

    const paperClassName = classnames({
      [styles.noMarginPaper]: !lgScreen,
      [styles.smallMarginPaper]: lgScreen && sideNavOpen,
      [styles.bigMarginPaper]: lgScreen && !sideNavOpen
    });

    const author = loading.detail ? null : assignmentDetails.created_by.name;

    const instances = _.get(assignmentDetails, 'instances', []);
    const assignments = _.flatMap(instances, 'assignments');
    const allAssignmentsAreArchived = _.every(assignments, 'deleted');

    return (
      <>
        <Paper className={paperClassName} square={!lgScreen} variant="outlined">
          {loading.detail && <AssignmentDetailSkeleton />}
          {!loading.detail && (
            <React.Fragment>
              <Grid
                container
                alignItems="center"
                className={styles.paperHeading}
              >
                <Grid container item xs direction="column" spacing={1}>
                  <Grid item>
                    <Typography variant="H-TEXT-2" color={colors.blueDark}>
                      {assignmentDetails.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="B-Text-3" color={colors.grey1}>
                      {author} created{' '}
                      {calendarDate(assignmentDetails.created_at)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <Button
                      color="lightGrey"
                      disabled={loading.studentPreview}
                      onClick={this.handleStudentPreview}
                    >
                      Add Exemplar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <div className={styles.paperSection}>
                <div className={styles.assignedSection}>
                  <Typography variant="H-TEXT-3" color={colors.blueDark}>
                    Assigned
                  </Typography>
                  <div>
                    <Button
                      color="lightGrey"
                      variant="small"
                      startIcon={<TrashIcon className={styles.trashIcon} />}
                      className={styles.archiveAllButton}
                      disabled={allAssignmentsAreArchived}
                      onClick={this.softDeleteAll}
                    >
                      Archive all
                    </Button>
                    <Button
                      color="pink"
                      variant="small"
                      onClick={this.openAssignAssignmentDialog}
                    >
                      Assign
                    </Button>
                  </div>
                </div>
                <AssignmentListByInstance
                  assignmentDetails={assignmentDetails}
                  updateOpenDate={this.updateOpenDate}
                  updateDueDate={this.updateDueDate}
                  softDelete={this.softDelete}
                  restore={this.restore}
                />
              </div>
              <Divider />
              <div className={styles.paperSection}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="H-TEXT-3" color={colors.blueDark}>
                      Tasks
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      color="pink"
                      variant="small"
                      onClick={this.openAssignmentEditDialog}
                    >
                      Edit
                    </Button>
                  </Grid>
                </Grid>

                <TasksList tasks={assignmentDetails.tasks} />
              </div>
              <Divider />
            </React.Fragment>
          )}
        </Paper>
        {dialogs.assign.open && (
          <AssignmentDialog
            defaultStep={dialogs.assign.defaultStep}
            defaultMode={AssignmentDialogModes.UPDATE}
            defaultTrackerId={assignmentDetails.id}
            isAssigned={!_.isEmpty('assignmentDetails', 'assignments')}
            onClose={this.closeAssignAssignmentDialog}
          />
        )}
        {dialogs.edit.open && (
          <AssignmentEditDialog
            assignment={assignmentDetails}
            onClose={this.closeAssignmentEditDialog}
          />
        )}
        {dialogs.confirm.open && (
          <AddTeacherConfirmDialog
            onClose={this.closeConfirmDialog}
            onConfirm={this.ensureTeacherInSections}
          />
        )}
      </>
    );
  }
}

export default withWidth()(
  withNavbar(TeacherAssignmentDetail, { title: 'Manage assignment' })
);
