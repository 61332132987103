import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import classnames from 'classnames';
import { TEACHERS_MEETING_FEED_URL } from 'config/urls';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';
import { formatDate } from 'utils/moment';
import { notifyErrors } from 'utils/notifications';
import { reverse } from 'utils/urls';

import AttachmentsUpload from 'components/AttachmentsUpload';
import Button from 'components/Button';
import CreateTeacherTeamDialog from 'components/CreateTeacherTeamDialog';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import ScheduleMeetingDialog from 'components/ScheduleMeetingDialog';
import SearchFilter from 'components/SearchFilter';
import SelectFilter from 'components/SelectFilter';
import Typography from 'components/Typography';

import {
  useAttachments,
  useMeetings,
  useStudentNotes,
  useTeams
} from './hooks';
import { createMeetingShare } from './sdk';
import styles from './styles.module.scss';

const ShareToMeetingDialog = ({
  assignment,
  data: { students, trackerScoreResponseIds },
  onClose
}) => {
  const history = useHistory();

  const [teamSearchValue, setTeamSearchValue] = useState('');
  const { teams, setTeams, selectedTeam, setSelectedTeam } =
    useTeams(teamSearchValue);

  const {
    isOpened: isCreateTeacherTeamDialogOpened,
    openDialog: openCreateTeacherTeamDialog,
    closeDialog: closeCreateTeacherTeamDialog
  } = useDialog();

  const { meetings, setMeetings, selectedMeeting, setSelectedMeeting } =
    useMeetings(selectedTeam);
  const {
    isOpened: isScheduleMeetingDialogOpened,
    openDialog: openScheduleMeetingDialog,
    closeDialog: closeScheduleMeetingDialog
  } = useDialog();

  const selectTeam = useCallback(
    (team) => {
      const teamName = _.get(team, 'name', '');

      setSelectedTeam(team);
      setTeamSearchValue(teamName);
      setSelectedMeeting(null);
    },
    [setSelectedTeam, setSelectedMeeting]
  );

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(
      !isCreateTeacherTeamDialogOpened && !isScheduleMeetingDialogOpened
    );
  }, [isCreateTeacherTeamDialogOpened, isScheduleMeetingDialogOpened]);

  const [notes, setNotes] = useState('');

  const { studentsNotes, setStudentNotes } = useStudentNotes(students);

  const { attachments, setAttachments, areAttachmentsUploading } =
    useAttachments();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const disableSubmit = useMemo(
    () =>
      isSubmitting ||
      _.isNull(selectedTeam) ||
      _.isNull(selectedMeeting) ||
      areAttachmentsUploading,
    [isSubmitting, selectedTeam, selectedMeeting, areAttachmentsUploading]
  );

  const onCreateTeacherTeamDialogSuccess = useCallback(
    (newTeam) => {
      setTeams([newTeam, ...teams]);
      selectTeam(newTeam);
    },
    [teams, setTeams, selectTeam]
  );

  const onTeamChange = useCallback((e, opt) => selectTeam(opt), [selectTeam]);
  const onTeamInputChange = useCallback(
    (e, val) => setTeamSearchValue(val),
    []
  );

  const onScheduleMeetingDialogSuccess = useCallback(
    (newMeetings) => {
      const firstScheduled = _.head(newMeetings);
      if (!_.find(teams, { id: firstScheduled.team.id })) {
        // Add the new meeting "optimistically" to the options.
        setTeams([firstScheduled.team, ...teams]);
      }
      selectTeam(firstScheduled.team);

      setMeetings([...newMeetings, ...meetings]);
      setSelectedMeeting(firstScheduled);
    },
    [teams, setTeams, selectTeam, meetings, setMeetings, setSelectedMeeting]
  );

  const getMeetingLabel = (meeting) => {
    const meetingTopic = _.get(meeting, 'topic');

    const formattedMeetingSchedule = formatDate(
      _.get(meeting, 'scheduled_for'),
      'ddd MM/D [at] h:mm a'
    );
    if (_.isEmpty(meetingTopic)) {
      return formattedMeetingSchedule;
    }
    return `${formattedMeetingSchedule} - ${meetingTopic}`;
  };

  const meetingsOptions = useMemo(
    () =>
      _.map(meetings, (meeting) => {
        const label = getMeetingLabel(meeting);
        return {
          key: meeting.id,
          value: meeting.id,
          label
        };
      }),
    [meetings]
  );
  const isMeetingSelectDisabled = useMemo(
    () => _.isNull(selectedTeam) || _.isEmpty(meetings),
    [selectedTeam, meetings]
  );
  const onMeetingChange = useCallback(
    (event) => setSelectedMeeting(_.find(meetings, { id: event.target.value })),
    [meetings, setSelectedMeeting]
  );

  const handleMeetingFeedRedirect = useCallback(
    (invitationIdentifier) =>
      history.push({
        pathname: reverse(TEACHERS_MEETING_FEED_URL, { invitationIdentifier })
      }),
    [history]
  );

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);

    const nonEmptyStudentsNotes = _.omitBy(studentsNotes, _.isEmpty);

    const meetingShareData = {
      meeting: selectedMeeting.id,
      assignment: assignment.id,
      tracker_score_responses: trackerScoreResponseIds,
      comments_data: nonEmptyStudentsNotes
    };

    if (!_.isEmpty(notes)) {
      meetingShareData.notes_for_team = notes;
    }
    if (!_.isEmpty(attachments)) {
      meetingShareData.comment_attachment_files = _.map(attachments, 'id');
    }

    const { success, errors, data } = await createMeetingShare(
      meetingShareData
    );

    setIsSubmitting(false);

    if (success) {
      handleMeetingFeedRedirect(data.meeting_invitation_identifier);
    } else {
      notifyErrors(errors);
    }
  }, [
    trackerScoreResponseIds,
    studentsNotes,
    selectedMeeting,
    assignment,
    notes,
    attachments,
    handleMeetingFeedRedirect
  ]);

  return (
    <Dialog
      open
      alignTop
      fullWidth
      maxWidth="md"
      onClose={onClose}
      hideBackdrop={!visible}
      classes={{
        paper: classnames(styles.dialog, { [styles.hidden]: !visible })
      }}
    >
      <>
        <div className={styles.header}>
          <Typography variant="H-TEXT-2" color={colors.blue1}>
            Share to meeting
          </Typography>
          <Button
            color="pink"
            variant="textAndIcon"
            endIcon={<ChevronRightRoundedIcon />}
            onClick={onSubmit}
            disabled={disableSubmit}
            className={styles.shareButton}
          >
            Share
          </Button>
        </div>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Team
        </Typography>
        <div className={styles.selectOrCreateContainer}>
          <SearchFilter
            fullWidth
            placeholder="Select Team"
            disabled={_.isEmpty(teams) && _.isEmpty(teamSearchValue)}
            options={teams || []}
            value={selectedTeam || ''}
            getOptionLabel={(team) => _.get(team, 'name', '')}
            getOptionSelected={(team, value) => team.id === value.id}
            onChange={onTeamChange}
            onInputChange={onTeamInputChange}
          />
          <Typography variant="B-Text-3" align="center" color={colors.grey3}>
            - or -
          </Typography>
          <Button
            color="lightGrey"
            variant="contained"
            disabled={isSubmitting}
            onClick={openCreateTeacherTeamDialog}
            className={styles.createNewButton}
          >
            Create team
          </Button>
        </div>

        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Meeting
        </Typography>
        <div className={styles.selectOrCreateContainer}>
          <SelectFilter
            fullWidth
            disabled={isMeetingSelectDisabled}
            options={meetingsOptions}
            value={_.get(selectedMeeting, 'id', null)}
            onChange={onMeetingChange}
            placeholder="Select Meeting"
            MenuProps={{ classes: { paper: styles.meetingSelectPaper } }}
          />
          <Typography variant="B-Text-3" align="center" color={colors.grey3}>
            - or -
          </Typography>
          <Button
            color="lightGrey"
            variant="contained"
            disabled={isSubmitting}
            onClick={openScheduleMeetingDialog}
            className={styles.createNewButton}
          >
            Add meeting
          </Button>
        </div>

        <InputField
          fullWidth
          multiline
          rows={3}
          variant="underlined"
          placeholder="Notes for Team"
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
          classes={{ root: styles.notes }}
          InputProps={{
            classes: { multiline: styles.textarea }
          }}
        />

        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Student work
        </Typography>
        <div className={styles.studentCommentsContainer}>
          {_.map(students, (student) => (
            <div key={student.id} className={styles.studentCommentRow}>
              <Typography variant="B-Text-2" color={colors.blue1}>
                {student.name}
              </Typography>
              <InputField
                fullWidth
                variant="underlined"
                placeholder="Write Here"
                onChange={(event) =>
                  setStudentNotes(student.id, event.target.value)
                }
              />
            </div>
          ))}
        </div>

        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Assignment
        </Typography>
        <Typography variant="B-Text-2" color={colors.blue1}>
          {assignment.tracker_name}
        </Typography>

        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Attachments
        </Typography>
        <AttachmentsUpload
          attachments={attachments}
          setAttachments={setAttachments}
        />

        {isSubmitting && <LinearProgress className={styles.linearProgress} />}
      </>

      {isCreateTeacherTeamDialogOpened && (
        <CreateTeacherTeamDialog
          onClose={closeCreateTeacherTeamDialog}
          onSuccess={onCreateTeacherTeamDialogSuccess}
        />
      )}
      {isScheduleMeetingDialogOpened && (
        <ScheduleMeetingDialog
          initialTeamId={selectedTeam.id}
          onClose={closeScheduleMeetingDialog}
          onSuccess={onScheduleMeetingDialogSuccess}
        />
      )}
    </Dialog>
  );
};

export default React.memo(ShareToMeetingDialog);
