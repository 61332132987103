import React, { useMemo, useState } from 'react';

import _ from 'lodash';

import { notifySuccess } from 'utils/notifications';

export const GoogleClassroomSyncStatuses = {
  INITIATED: {
    value: 'initiated',
    message: 'Syncing with Google Classroom...⏳'
  },
  FAILED: {
    value: 'failed',
    message: 'Google Classroom incomplete. Please contact support@edlight.com'
  },
  READY: {
    value: 'ready',
    successMessage: () => (
      <>Your Google Classroom courses & rosters were synced successfully!</>
    ),
    getWarningMessage: (unsynced_students_count) =>
      `Google Classroom sync complete. We were unable to create accounts for ${unsynced_students_count} students. Please contact support@edlight.com`,
    customNotificationOptions: {
      autoClose: 3000
    }
  }
};

export const buildGoogleClassroomWidgetId = (id) =>
  `google-classroom-widget-${id}`;

const MAX_GOOGLE_CLASSROOM_URL_LENGTH = 1100;
const ensureParams = ({ url, itemtype, title, body }) => {
  // The Google API return 400 if our params are too long
  // This forces the Google Classroom share button stop appearing
  const urlLength = url.length;
  const itemtypeLength = itemtype.length;
  const titleLength = title.length;
  const bodyLength = body.length;

  const allParamsLength = urlLength + itemtypeLength + titleLength + bodyLength;

  if (allParamsLength <= MAX_GOOGLE_CLASSROOM_URL_LENGTH) {
    return;
  }

  // We cut the body in order to make sure we don't go over the Google URL limit
  const bodySlice =
    MAX_GOOGLE_CLASSROOM_URL_LENGTH - (allParamsLength - bodyLength);

  return bodySlice;
};

export const buildGoogleClassroomOptions = (assignmentData, url) => {
  const buildBody = () => {
    const { description, attachments } = assignmentData;

    if (_.isEmpty(attachments)) {
      return description;
    }

    const attachmentsHeader = 'Attachments:';
    const descriptionHeader = '\nDescription:';

    const bodyParts = [
      attachmentsHeader,
      ..._.map(attachments, 'file.url'),
      descriptionHeader,
      description
    ];

    return _.join(bodyParts, '\n');
  };

  let body = buildBody();

  const bodySlice = ensureParams({
    url,
    itemtype: 'assignment',
    title: assignmentData.name,
    body: buildBody()
  });

  if (bodySlice) {
    body = body.slice(0, bodySlice + 3); // add 3 to the slice for '...'
    body = body + '...';
  }

  // Reference for those values: https://developers.google.com/classroom/guides/sharebutton#share_tag_attributes
  return {
    url,
    itemtype: 'assignment',
    title: assignmentData.name,
    body: body,
    onsharecomplete: () =>
      notifySuccess('Assignment successfully shared to Google Classroom.')
  };
};

export const renderGoogleClassroomWidget = (id, params = {}) => {
  const gapi = window.gapi;
  const googleLoaded = gapi && gapi.sharetoclassroom;

  // Check whether the Google script is fully loaded 200 milliseconds.
  // If it is loaded -> render the Classroom widget and stop the polling process.
  const loaded = setInterval(() => {
    if (googleLoaded) {
      clearInterval(loaded);
      gapi.sharetoclassroom.render(id, params);
    }
  }, 200);
};

export const useGoogleClassroomSync = () => {
  const [sync, setSync] = useState(null);

  const isInitiated = useMemo(
    () => _.get(sync, 'status') === GoogleClassroomSyncStatuses.INITIATED.value,
    [sync]
  );

  const isFailed = useMemo(
    () => _.get(sync, 'status') === GoogleClassroomSyncStatuses.FAILED.value,
    [sync]
  );

  return useMemo(
    () => ({ sync, isInitiated, isFailed, setSync }),
    [sync, isInitiated, isFailed]
  );
};

export const shareAssignmentToGoogleClassroom = ({ assignment }) =>
  window.open(
    `https://classroom.google.com/u/0/share?url=${assignment.student_deeplink}&title=${assignment.name}&itemtype=assignment`,
    'popup',
    'width=600,height=600'
  );
