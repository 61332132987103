import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import {
  STUDENTS_ASSIGNMENTS_LIST_URL,
  STUDENTS_ASSIGNMENTS_OVERVIEW_URL
} from 'config/urls';
import _ from 'lodash';
import AudioPlayer from 'material-ui-audio-player';

import { LightTaskInfo, Typography, withNavbar } from 'pages/Students/shared';
import { colors } from 'theme/palette';
import { AssignmentStatusOptions } from 'utils/constants';
import { uploadImage } from 'utils/files';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { notifyErrors } from 'utils/notifications';
import { getBlob } from 'utils/sdk';
import { parseParams, reverse, stringifyParams } from 'utils/urls';

import Button from 'components/Button';
import Swipeable from 'components/Swipeable';
import UserContext from 'components/UserContext';

import {
  AllTasksSubmittedDialog,
  Comments,
  LoadingPlaceholder,
  NotDoneDialog,
  TaskWork,
  WebcamCapture
} from './components';
import {
  addAssignmentComment,
  assignmentDetail,
  uploadStudentWorkV2
} from './sdk';
import styles from './styles.module.scss';

const StyledLoading = withStyles({
  root: {
    color: 'var(--white)'
  }
})(CircularProgress);

const useAudioStyles = makeStyles({
  root: {
    backgroundColor: colors.grey7,
    margin: 0,
    '& div:last-child': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  replayIcon: {
    width: 24,
    height: 24
  },
  pauseIcon: {
    width: 24,
    height: 24
  },
  playIcon: {
    width: 24,
    height: 24
  },
  volumeIcon: {
    width: 24,
    height: 24,
    '& + div': {
      // Selects the first div after the icon component which is the container of the slider
      // and fixes a z-index problem. NOTE: We cannot use the `volumeSlider` class to fix this.
      zIndex: 1
    }
  },
  mainSlider: {
    // Fixes a bug with the time alignment
    paddingBottom: 9
  }
});

class StudentAssignmentsDetail extends React.Component {
  static contextType = UserContext;

  state = {
    activeTask: null,
    activeWork: null,
    loading: true,
    assignment: null,
    webcamView: false,
    uploading: false,
    doneDialogOpen: false,
    notDoneDialogOpen: false,
    showImageUploadButtons: true
  };

  webcamObj = null;
  audioRef = null;
  assignmentId = this.props.match.params.assignmentId;

  componentDidMount() {
    this.fetchAssignment();
  }

  initializeTasks = () => {
    const { task: taskId } = parseParams(
      _.get(this.props, 'history.location.search', '')
    );

    if (this.state.assignment) {
      const initialTask =
        _.find(this.state.assignment.tasks, { id: _.toNumber(taskId) }) ||
        _.first(this.state.assignment.tasks);

      this.changeActiveTask(initialTask);
    }
  };

  setButtonActions = () => {
    const { camera: webcamView, backUrl } = parseParams(
      _.get(this.props, 'history.location.search', ''),
      { parseBooleans: true }
    );

    if (webcamView && !this.state.webcamView) {
      this.setState({ webcamView: true });
      this.props.navigation.setBackButtonAction(() => {
        const done = this.isAssignmentDone();
        if (done) {
          this.toggleCameraView();
        } else {
          this.toggleNotDoneDialog();
        }
      });
      this.props.navigation.setDoneButtonAction(
        this.uploadAllCapturedStudentWork
      );
      this.props.navigation.setBottomNavigationVisible(false);
    } else {
      this.props.navigation.setDoneButtonAction(null);
      this.props.navigation.setBottomNavigationVisible(true);

      if (!_.isEmpty(backUrl)) {
        this.props.navigation.setBackButtonAction(() =>
          this.props.history.push(backUrl)
        );
      } else {
        this.props.navigation.setBackButtonAction(this.goToAssignmentOverview);
      }
    }
  };

  fetchAssignment = async () => {
    const { student_id: studentId } = this.context.user;

    const {
      data: assignment,
      success,
      errors
    } = await assignmentDetail({ studentId, assignmentId: this.assignmentId });

    if (success) {
      this.setState({ assignment, loading: false }, () => {
        this.setButtonActions();
        this.initializeTasks();
      });
      this.props.navigation.setTitle(assignment.name);
    } else {
      this.setState({ assignment: null, loading: false }, () =>
        notifyErrors(errors)
      );
      this.props.navigation.setTitle(null);
    }
  };

  changeActiveTask = (task) =>
    new Promise((resolve, reject) => {
      this.setState(
        {
          activeTask: task,
          activeWork: _.first(_.get(task, 'work'))
        },
        () => {
          this.updateGetQuery();
        }
      );

      return resolve(task);
    });

  changeActiveWork = (work) =>
    this.setState({ activeWork: work }, () => {
      if (work.is_feedback) {
        const { assignment, activeTask } = this.state;

        trackMixpanelEvent(
          this.context.user.id,
          'Student accessed feedback for assignment.',
          {
            assignmentName: assignment.name,
            taskName: activeTask.name
          }
        );
      }
    });

  updateGetQuery = () =>
    this.props.history.replace({
      search: stringifyParams({
        task: _.get(this.state.activeTask, 'id'),
        camera: this.state.webcamView
      })
    });

  isAssignmentDone = () =>
    _.every(
      _.map(
        _.get(this.state, 'assignment.tasks', []),
        (task) =>
          task.status === AssignmentStatusOptions.SUBMITTED ||
          task.status === AssignmentStatusOptions.COMPLETE
      )
    );

  goToAssignmentsList = () =>
    this.props.history.push(STUDENTS_ASSIGNMENTS_LIST_URL);

  goToAssignmentOverview = () =>
    this.props.history.push(
      reverse(STUDENTS_ASSIGNMENTS_OVERVIEW_URL, {
        assignmentId: this.assignmentId
      })
    );

  getTaskName = () => {
    const { activeTask } = this.state;
    const index = this.getActiveTaskIndex() + 1;

    return `${index}. ${activeTask.name}`;
  };

  getActiveTaskIndex = () => {
    const { assignment, activeTask } = this.state;

    if (assignment && activeTask) {
      return _.findIndex(assignment.tasks, {
        id: activeTask.id
      });
    }

    return 0;
  };

  swipeActiveTask = (step) => {
    const { assignment } = this.state;

    if (assignment) {
      const current = this.getActiveTaskIndex();
      const next = current + step;

      let nextActiveTask = assignment.tasks[next];
      if (!nextActiveTask) {
        // Handle swiping of the last and the first task
        nextActiveTask =
          step === 1 ? assignment.tasks[0] : _.last(assignment.tasks);
      }

      return this.changeActiveTask(nextActiveTask);
    }
  };

  addComment = async (values, { resetForm }) => {
    const { id: assignmentId } = this.state.activeTask;
    const { student_id: studentId } = this.context.user;

    const {
      success,
      data: newComment,
      errors
    } = await addAssignmentComment({ studentId, assignmentId, data: values });

    if (success) {
      const updatedTask = {
        ...this.state.activeTask,
        comments: [...this.state.activeTask.comments, newComment]
      };
      // We need to update the task reference in the assignment as well.
      // NOTE: We don't need another heavy fetch call here since the API returns all of the new info we need.
      const updatedAssignment = {
        ...this.state.assignment,
        tasks: _.map(this.state.assignment.tasks, (t) =>
          t.id === updatedTask.id ? updatedTask : t
        )
      };

      this.setState(
        { activeTask: updatedTask, assignment: updatedAssignment },
        resetForm
      );
    } else {
      notifyErrors(errors);
    }
  };

  toggleCameraView = () => {
    this.setState({ webcamView: !this.state.webcamView }, () => {
      this.updateGetQuery();

      if (this.state.webcamView) {
        this.props.navigation.setBackButtonAction(this.toggleCameraView);
        this.props.navigation.setDoneButtonAction(
          this.uploadAllCapturedStudentWork
        );
        this.props.navigation.setBottomNavigationVisible(false);
      } else {
        this.props.navigation.setBackButtonAction(this.goToAssignmentOverview);
        this.props.navigation.setDoneButtonAction(null);
        this.props.navigation.setBottomNavigationVisible(true);
      }
    });
  };

  uploadStudentWork = async (capturedImg) => {
    const { activeTask } = this.state;
    const studentId = this.context.user.student_id;

    const { data: blob } = await getBlob(capturedImg);

    const workId = await uploadImage({ blob, name: 'work' });

    if (workId) {
      const formData = new FormData();
      formData.append('work_file_ids', workId);
      formData.append('tracker_score_response_ids', activeTask.id);

      const { success, errors, data } = await uploadStudentWorkV2(
        studentId,
        formData
      );

      if (success) {
        await this.fetchAssignment();
        this.swipeActiveTask(1);
        const status = data.student_status;
        if (
          status === AssignmentStatusOptions.SUBMITTED ||
          status === AssignmentStatusOptions.COMPLETE
        ) {
          this.toggleDoneDialog();
        }
      } else {
        notifyErrors(errors);
      }
    }
  };

  uploadAllCapturedStudentWork = async () => {
    if (this.webcamObj) {
      const { capturedImagesData } = this.webcamObj.state;

      const taskImages = _.reduce(
        capturedImagesData,
        (res, imageData, taskId) => {
          const imageUrl = _.get(imageData, 'image');
          if (imageUrl) {
            res = [...res, { id: taskId, imageUrl }];
          }
          return res;
        },
        []
      );

      if (!_.isEmpty(taskImages)) {
        this.setState({ uploading: true });
        const studentId = this.context.user.student_id;

        const formData = new FormData();

        for (let taskImageData of taskImages) {
          const { id, imageUrl } = taskImageData;

          const { data: blob } = await getBlob(imageUrl);

          const workId = await uploadImage({ blob, name: 'work' });

          if (workId) {
            formData.append('work_file_ids', workId);
            formData.append('tracker_score_response_ids', id);
          }
        }

        const { success, errors, data } = await uploadStudentWorkV2(
          studentId,
          formData
        );
        if (success) {
          this.setState({ uploading: false });

          await this.fetchAssignment();

          const status = data.student_status;
          if (
            status === AssignmentStatusOptions.SUBMITTED ||
            status === AssignmentStatusOptions.COMPLETE
          ) {
            this.toggleDoneDialog();
          } else {
            this.toggleNotDoneDialog();
          }
        } else {
          notifyErrors(errors);
        }
      } else {
        const done = this.isAssignmentDone();

        !done && this.toggleNotDoneDialog();
        done && this.goToAssignmentOverview();
      }
    }
  };

  handleSwipe =
    (step) =>
    ({ event }) => {
      if (this.audioRef && this.audioRef.contains(event.target)) {
        // The swipe action is triggered by the Audio Player.
        // We prevent it.
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      this.swipeActiveTask(step);
    };

  toggleDoneDialog = () => {
    this.setState((prevState) => ({
      doneDialogOpen: !prevState.doneDialogOpen
    }));
  };

  toggleNotDoneDialog = () => {
    this.setState((prevState) => ({
      notDoneDialogOpen: !prevState.notDoneDialogOpen
    }));
  };

  getPendingTasks = () => {
    const {
      assignment: { tasks }
    } = this.state;

    return _.filter(
      tasks,
      ({ status }) =>
        status === AssignmentStatusOptions.REVISE ||
        status === AssignmentStatusOptions.ASSIGNED
    );
  };

  goToNextPendingTask = () => {
    const nextTask = _.first(this.getPendingTasks());
    return this.changeActiveTask(nextTask);
  };

  render() {
    const {
      assignment,
      loading,
      uploading,
      activeTask,
      activeWork,
      webcamView,
      doneDialogOpen,
      notDoneDialogOpen,
      showImageUploadButtons
    } = this.state;

    if (loading) {
      return <LoadingPlaceholder />;
    }

    if (uploading) {
      return (
        <Backdrop open style={{ zIndex: 1 }}>
          <Box textAlign="center">
            <StyledLoading size={52} />
            <Box color="white" fontSize="1.4rem" marginTop={2}>
              Uploading...
            </Box>
          </Box>
        </Backdrop>
      );
    }

    if (!assignment) {
      return <h3>Assignment does not exist.</h3>;
    }

    const showMainView = !webcamView;
    const lgScreen = isWidthUp('sm', this.props.width);

    return (
      <>
        {showMainView && activeTask && (
          <Swipeable
            preventScrollOnSwipe
            trackTouch
            trackMouse
            onSwipedLeft={this.handleSwipe(1)}
            onSwipedRight={this.handleSwipe(-1)}
          >
            <Grid container className={styles.taskSwitch} alignItems="center">
              <Grid item>
                {assignment.tasks.length > 1 && (
                  <IconButton
                    size="small"
                    onClick={() => this.swipeActiveTask(-1)}
                  >
                    <KeyboardArrowLeftRoundedIcon fontSize="large" />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs container justifyContent="center">
                <Typography
                  variant={lgScreen ? 'H-TEXT-3' : 'S-TEXT-1'}
                  color={colors.blue1}
                >
                  {this.getTaskName()}
                </Typography>
              </Grid>
              <Grid item>
                {assignment.tasks.length > 1 && (
                  <IconButton
                    size="small"
                    onClick={() => this.swipeActiveTask(1)}
                  >
                    <KeyboardArrowRightRoundedIcon fontSize="large" />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            <LightTaskInfo
              task={{ ...activeTask, name: this.getTaskName() }}
              lgScreen={lgScreen}
            />

            {activeTask.audio_files &&
              activeTask.audio_files.map((file, index) => {
                const audioSource = _.get(file, 'file.file_url');
                return (
                  <Grid
                    container
                    className={styles.audioContainer}
                    alignItems="center"
                    ref={(x) => (this[`audioRef${index}`] = x)}
                    key={index}
                  >
                    <Grid item xs={3} className={styles.audioContainerIcon}>
                      <VolumeUpRoundedIcon
                        fontSize="large"
                        htmlColor={colors.grey2}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <AudioPlayer
                        src={audioSource}
                        elevation={0}
                        spacing={2}
                        width={lgScreen ? '70%' : '90%'}
                        variation="secondary"
                        useStyles={useAudioStyles}
                        loop={false}
                        debug={false}
                      />
                    </Grid>
                  </Grid>
                );
              })}

            {showImageUploadButtons && (
              <div className={styles.buttonContainer}>
                <Button
                  color="lightGreen"
                  onClick={() => this.toggleCameraView()}
                  startIcon={<AddAPhotoRoundedIcon />}
                >
                  ADD PICTURE
                </Button>
              </div>
            )}
            <TaskWork
              showImageUploadButtons={showImageUploadButtons}
              work={activeTask.work}
              active={activeWork}
              changeImage={this.changeActiveWork}
              openCameraView={this.toggleCameraView}
            />
            <Comments
              comments={activeTask.comments}
              addComment={this.addComment}
            />
          </Swipeable>
        )}

        {doneDialogOpen && (
          <AllTasksSubmittedDialog
            toggleDoneDialog={this.toggleDoneDialog}
            goToAssignmentsList={this.goToAssignmentsList}
          />
        )}
        {notDoneDialogOpen && (
          <NotDoneDialog
            toggleNotDoneDialog={this.toggleNotDoneDialog}
            goToAssignmentsList={this.goToAssignmentsList}
            goToNextPendingTask={this.goToNextPendingTask}
            getTasksCount={() => _.size(this.getPendingTasks())}
          />
        )}

        {webcamView && activeTask && (
          <WebcamCapture
            ref={(el) => (this.webcamObj = el)}
            activeTask={{ ...activeTask, name: this.getTaskName() }}
            tasks={assignment.tasks}
            changeTask={this.swipeActiveTask}
            uploadStudentWork={this.uploadStudentWork}
          />
        )}
      </>
    );
  }
}

export default withWidth()(withNavbar(StudentAssignmentsDetail));
