import { useMemo } from 'react';

import moment from 'moment';
import { useCurrentSchoolYear } from 'sdk';

import { formatDate } from 'utils/moment';

export const useMyPortfolioInitialFilters = ({ defaultValues }) => {
  const { data: currentSchoolYear, isLoading: currentSchoolYearLoading } =
    useCurrentSchoolYear();

  const filters = useMemo(() => {
    if (!currentSchoolYear || currentSchoolYearLoading) {
      return {};
    }

    return {
      ...defaultValues,
      startDate: formatDate(
        moment(currentSchoolYear.naive_start_date, 'YYYY-MM-DD')
      ),
      endDate: formatDate(
        moment(currentSchoolYear.naive_end_date, 'YYYY-MM-DD')
      )
    };
  }, [currentSchoolYear, currentSchoolYearLoading, defaultValues]);

  return filters;
};

export const parseAndPrepareStudentPortfolioDateFilters = ({ filters }) => {
  const dateObjects = {
    startDate: moment(filters.startDate, 'MM/DD/YYYY'),
    endDate: moment(filters.endDate, 'MM/DD/YYYY')
  };

  return {
    start_date: formatDate(dateObjects.startDate, 'YYYY-MM-DD'),
    end_date: formatDate(dateObjects.endDate, 'YYYY-MM-DD')
  };
};
