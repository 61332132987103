import React, { useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as AssignedSmall } from 'images/assigned_small.svg';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { AssignmentStatusOptions } from 'utils/constants';

import Typography from 'components/Typography';

import { ReactComponent as CompleteSmall } from './assets/complete_small.svg';
import { ReactComponent as ReviseSmall } from './assets/revise_small.svg';
import { ReactComponent as SubmittedSmall } from './assets/submitted_small.svg';
import styles from './styles.module.scss';

export const STATUS_ICONS = {
  assigned: AssignedSmall,
  submitted: SubmittedSmall,
  revise: ReviseSmall,
  complete: CompleteSmall
};

export const STATUS_COLORS = {
  assigned: colors.yellow3,
  submitted: colors.blue3,
  revise: colors.pink1,
  complete: colors.green1
};

const TooltipContent = ({ changeStatus, currentStatus, closeTooltip }) => (
  <div>
    {_.map(
      _.omit(STATUS_ICONS, [
        AssignmentStatusOptions.ASSIGNED,
        AssignmentStatusOptions.SUBMITTED
      ]),
      (statusIcon, status) => (
        <div
          key={status}
          className={styles.statusWrapper}
          onClick={() => {
            if (status !== currentStatus) {
              changeStatus(status);
            }
            closeTooltip();
          }}
        >
          <SvgIcon
            component={statusIcon}
            fontSize="small"
            className={styles.dropdownIcon}
          />
          <Typography
            variant="B-Text-3"
            color={STATUS_COLORS[status]}
            display="inline"
            className={styles.statusText}
          >
            {status}
          </Typography>
        </div>
      )
    )}
  </div>
);

const StatusBoxIcon = ({
  disabled,
  currentStatus,
  changeStatus,
  iconButtonClasses = {}
}) => {
  const [open, setOpen] = useState(false);

  if (disabled) {
    return (
      <IconButton classes={iconButtonClasses} disabled>
        <SvgIcon fontSize="small" component={STATUS_ICONS[currentStatus]} />
      </IconButton>
    );
  }
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Tooltip
          arrow
          open={open}
          onClose={() => setOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          classes={{
            tooltip: styles.statusTooltip,
            arrow: styles.tooltipArrow
          }}
          title={
            <TooltipContent
              changeStatus={changeStatus}
              currentStatus={currentStatus}
              closeTooltip={() => setOpen(false)}
            />
          }
        >
          <IconButton
            onClick={() => setOpen((prevValue) => !prevValue)}
            classes={iconButtonClasses}
          >
            <SvgIcon fontSize="small" component={STATUS_ICONS[currentStatus]} />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default StatusBoxIcon;
