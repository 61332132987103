import { useHistory } from 'react-router-dom';

export const useCurrentPageName = () => {
  const history = useHistory();
  const urlArray = history.location.pathname.split('/');
  return urlArray.at(-1);
};

export const firstTimeLogin = ({ students }) => {
  return !students.some((student) => student.confirmed);
};
