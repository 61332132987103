import React from 'react';

import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

import checkIcon from './assets/check-icon.svg';
import toggleIcon from './assets/toggle-icon.svg';

const SwitchToggle = withStyles((theme) => ({
  root: {
    width: 45,
    height: 24,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(21px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#5A608D',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff'
    }
  },
  thumb: ({ checked }) => ({
    width: 22,
    height: 22,
    backgroundImage: `url(${checked ? checkIcon : toggleIcon})`,
    backgroundPosition: 'center',
    backgroundSize: '85%'
  }),
  track: {
    borderRadius: 13,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#33385C',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export default SwitchToggle;
