import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { TEACHERS_MEETING_SORT_URL } from 'config/urls';

import SortUpdateDialog from 'pages/Teachers/MeetingFeed/components/SortUpdateDialog';
import { colors } from 'theme/palette';
import { setImgixFallbackUrl, setImgixParams } from 'utils/files';
import { useDialog } from 'utils/hooks';
import { reverse, stringifyParams } from 'utils/urls';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const MeetingSortCard = ({
  sort,
  invitationIdentifier,
  meetingShares,
  onDeleteSortSuccess
}) => {
  const history = useHistory();

  const {
    isOpened: isSortUpdateDialogOpened,
    openDialog: openSortUpdateDialog,
    closeDialog: closeSortUpdateDialog
  } = useDialog();

  const goToMeetingSortDetail = useCallback(
    () =>
      history.push({
        pathname: reverse(TEACHERS_MEETING_SORT_URL, {
          invitationIdentifier
        }),
        search: stringifyParams({ sortId: sort.id })
      }),
    [history, invitationIdentifier, sort.id]
  );

  return (
    <>
      <Card variant="outlined" className={styles.sortCardContainer}>
        <CardActionArea component="div">
          <div onClick={goToMeetingSortDetail}>
            <CardMedia
              height={200}
              component="img"
              alt="Meeting Sort Student work"
              image={setImgixParams(sort.preview_work_url)}
              onError={setImgixFallbackUrl(sort.preview_s3_work_url)}
            />
            <div className={styles.cardFooter}>
              <Typography variant="H-TEXT-3" color={colors.white}>
                {sort.name}
              </Typography>
              <IconButton
                className={styles.editIcon}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  openSortUpdateDialog();
                }}
              >
                <BorderColorIcon style={{ color: colors.white }} />
              </IconButton>
            </div>
          </div>
        </CardActionArea>
      </Card>
      {isSortUpdateDialogOpened && (
        <SortUpdateDialog
          invitationIdentifier={invitationIdentifier}
          sortName={sort.name}
          sortId={sort.id}
          meetingShares={meetingShares}
          onClose={closeSortUpdateDialog}
          onDelete={onDeleteSortSuccess}
        />
      )}
    </>
  );
};

export default MeetingSortCard;
