import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import { ReactComponent as Bullet } from './assets/bullet.svg';
import styles from './styles.module.scss';

const ScoreExplanationItem = ({ title, percentage }) => {
  return (
    <>
      <div className={styles.scoreExplanationItem}>
        <div className={styles.textContainer}>
          <Bullet />
          <div className={styles.title}>{title}</div>
        </div>
      </div>
      <div className={styles.progressBar}>
        <LinearProgress
          className={styles.progress}
          value={percentage}
          variant="determinate"
        />

        <div className={styles.percentage}>{percentage}%</div>
      </div>
    </>
  );
};

export default ScoreExplanationItem;
