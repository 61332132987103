import React, { useCallback } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';
import { noopPromise } from 'utils';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import { ReactComponent as CancelIcon } from './assets/cancel.svg';
import { ReactComponent as CheckboxIcon } from './assets/checkbox.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';
import styles from './styles.module.scss';

const ShareTooltipContent = React.forwardRef(
  (
    {
      disabled,
      onShareTooltipClick,
      toggleSelectAllVisibleStudentWork,
      openShareToMeetingDialog
    },
    ref
  ) => {
    const onSelectAllSubmittedClick = useCallback(
      () => toggleSelectAllVisibleStudentWork({ includeEmpty: false }),
      [toggleSelectAllVisibleStudentWork]
    );

    const openShareToMeetingDialogCallback = useCallback(() => {
      onShareTooltipClick();
      openShareToMeetingDialog();
    }, [onShareTooltipClick, openShareToMeetingDialog]);

    return (
      <div className={styles.content} ref={ref}>
        <div className={styles.tab} onClick={onSelectAllSubmittedClick}>
          <CheckboxIcon />
          <Typography variant="H-TEXT-3">Select All Submitted</Typography>
        </div>
        <div className={styles.tab} onClick={onShareTooltipClick}>
          <CancelIcon />
          <Typography variant="H-TEXT-3">Cancel</Typography>
        </div>
        <div
          className={classnames(styles.tab, styles.purple, {
            [styles.disabled]: disabled
          })}
          onClick={disabled ? noopPromise : openShareToMeetingDialogCallback}
        >
          <ShareIcon fill={disabled ? colors.grey3 : colors.white} />
          <Typography variant="H-TEXT-3">Share</Typography>
        </div>
      </div>
    );
  }
);

ShareTooltipContent.displayName = 'ShareTooltipContent';

const ShareTooltip = ({
  onShareTooltipClick,
  toggleSelectAllVisibleStudentWork,
  open,
  canAssign = true,
  openShareToMeetingDialog,
  children
}) => {
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true
      }}
      classes={{
        tooltip: styles.tooltip,
        popper: styles.popper
      }}
      onClose={onShareTooltipClick}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={
        <ShareTooltipContent
          disabled={!canAssign}
          toggleSelectAllVisibleStudentWork={toggleSelectAllVisibleStudentWork}
          onShareTooltipClick={onShareTooltipClick}
          openShareToMeetingDialog={openShareToMeetingDialog}
        />
      }
    >
      {children}
    </Tooltip>
  );
};

export default ShareTooltip;
