import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { TEACHERS_MEETINGS_LIST_URL } from 'config/urls';
import _ from 'lodash';
import { useTeamMeetings } from 'sdk';
import {
  useTeamMeetingsPerUser,
  useTeamPastMeetings,
  useTeamUpcomingMeetings
} from 'sdk';
import { useTeam } from 'sdk';

import MeetingTabs from 'pages/Teachers/TeamDetail/components/MeetingTabs';
import MeetingsTable from 'pages/Teachers/TeamDetail/components/MeetingsTable';
import { useNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';

import AddButton from 'components/AddButton';
import ScheduleMeetingDialog from 'components/ScheduleMeetingDialog';
import SearchFilter from 'components/SearchFilter';
import Typography from 'components/Typography';

import { MEETINGS } from './constants';
import { usePaginatedTeamMeetings } from './hooks';
import styles from './styles.module.scss';

const TeamDetail = ({ history }) => {
  const { teamId } = useParams();

  const [activeTab, setActiveTab] = useState(MEETINGS.UPCOMING);
  const [search, setSearch] = useState('');

  const { data: meetingOptions, refetch: refetchMeetingOptions } =
    useTeamMeetings({ teamId });

  const { data: team } = useTeam({ teamId });

  const [
    upcomingMeetings,
    isLoadingUpcomingMeetings,
    currentUpcomingMeetingsPage,
    setUpcomingMeetingsPage,
    upcomingMeetingsPageCount,
    refetchUpcomingMeetings
  ] = usePaginatedTeamMeetings({ action: useTeamUpcomingMeetings, teamId });

  const [
    pastMeetings,
    isLoadingPastMeetings,
    currentPastMeetingsPage,
    setPastMeetingsPage,
    pastMeetingsPageCount,
    refetchPastMeetings
  ] = usePaginatedTeamMeetings({ action: useTeamPastMeetings, teamId });

  const [
    meetings,
    isLoadingMeetings,
    currentMeetingsPage,
    setMeetingsPage,
    meetingsPageCount,
    refetchMeetings
  ] = usePaginatedTeamMeetings({
    action: useTeamMeetingsPerUser,
    teamId,
    search
  });

  const meetingsWithTopic = meetingOptions?.filter(
    (meeting) => !_.isEmpty(meeting.topic)
  );

  const {
    isOpened: isScheduleMeetingDialogOpened,
    openDialog: openScheduleMeetingDialog,
    closeDialog: closeScheduleMeetingDialog
  } = useDialog();

  const onBackButtonClick = useCallback(() => {
    history.push(TEACHERS_MEETINGS_LIST_URL);
  }, [history]);

  const handleMeetingCreateSuccess = () => {
    refetchUpcomingMeetings();
    refetchPastMeetings();
    refetchMeetingOptions();

    if (!_.isEmpty(search)) {
      refetchMeetings();
    }
  };

  useNavbar({
    title: team?.name,
    sticky: true,
    onBackButtonClick
  });

  const showTabs = _.isEmpty(search);

  return (
    <div className={styles.container}>
      <div className={styles.headerLimiter}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          className={styles.labelContainer}
        >
          <Grid item xs>
            <Typography
              display="inline"
              variant="S-TEXT-1"
              color={colors.grey3}
            >
              Meetings
            </Typography>
          </Grid>
          <Grid item xs>
            <SearchFilter
              freeSolo
              autoComplete
              disabled={_.isEmpty(meetingsWithTopic)}
              value={search}
              options={_.map(meetingsWithTopic, 'topic')}
              onChange={(event, value) => setSearch(value)}
              placeholder="Search meetings"
            />
          </Grid>
          <Grid item xs>
            <AddButton
              className={styles.addButton}
              onClick={openScheduleMeetingDialog}
              color="blue"
              noTypography
            >
              <Typography variant="S-TEXT-1">Meeting</Typography>
            </AddButton>
          </Grid>
        </Grid>
        {showTabs ? (
          <>
            <MeetingTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            {activeTab === MEETINGS.UPCOMING && (
              <MeetingsTable
                meetings={upcomingMeetings}
                loading={isLoadingUpcomingMeetings}
                currentPage={currentUpcomingMeetingsPage}
                setPage={setUpcomingMeetingsPage}
                pageCount={upcomingMeetingsPageCount}
                emptyTableMessage="No upcoming meetings"
              />
            )}
            {activeTab === MEETINGS.PAST && (
              <MeetingsTable
                meetings={pastMeetings}
                loading={isLoadingPastMeetings}
                currentPage={currentPastMeetingsPage}
                setPage={setPastMeetingsPage}
                pageCount={pastMeetingsPageCount}
                emptyTableMessage="No past meetings"
              />
            )}
          </>
        ) : (
          <MeetingsTable
            meetings={meetings}
            loading={isLoadingMeetings}
            currentPage={currentMeetingsPage}
            setPage={setMeetingsPage}
            pageCount={meetingsPageCount}
            emptyTableMessage="No meetings"
          />
        )}
      </div>

      {isScheduleMeetingDialogOpened && (
        <ScheduleMeetingDialog
          onClose={closeScheduleMeetingDialog}
          onSuccess={() => handleMeetingCreateSuccess()}
          initialTeamId={teamId}
          disableTeamSelect={true}
        />
      )}
    </div>
  );
};

export default TeamDetail;
