import React from 'react';

import { get } from 'lodash';

import DailyProgressPieChart from './components/DailyProgressPieChart';

import { ReactComponent as LeftArrow } from './assets/left-arrow.svg';
import { ReactComponent as RightArrow } from './assets/right-arrow.svg';
import styles from './styles.module.scss';

const WeeklyProgressBar = ({
  selectedDayOfTheWeek,
  setSelectedDayOfTheWeek,
  days
}) => {
  const nextDayIsAvailable = get(
    days.find((day) => day.value === parseInt(selectedDayOfTheWeek) + 1),
    'isAvailable',
    false
  );

  const handleRightArrowClick = () => {
    if (selectedDayOfTheWeek === 5) {
      return;
    }

    if (!nextDayIsAvailable) {
      return;
    }

    setSelectedDayOfTheWeek(parseInt(selectedDayOfTheWeek) + 1);
  };

  const previousDayIsAvailable = get(
    days.find((day) => day.value === parseInt(selectedDayOfTheWeek) - 1),
    'isAvailable',
    false
  );

  const handleLeftArrowClick = () => {
    if (selectedDayOfTheWeek === 1) {
      return;
    }

    if (!previousDayIsAvailable) {
      return;
    }

    setSelectedDayOfTheWeek(parseInt(selectedDayOfTheWeek) - 1);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.arrow}
        onClick={handleLeftArrowClick}
        style={{
          opacity: previousDayIsAvailable ? 1 : 0.4,
          cursor: previousDayIsAvailable ? 'pointer' : 'default'
        }}
      >
        <LeftArrow />
      </div>
      {days.map((day) => (
        <div
          key={day.value}
          className={styles.dailyChart}
          onClick={() => day.isAvailable && setSelectedDayOfTheWeek(day.value)}
        >
          <DailyProgressPieChart day={day} />
        </div>
      ))}
      <div
        className={styles.arrow}
        onClick={handleRightArrowClick}
        style={{
          opacity: nextDayIsAvailable ? 1 : 0.4,
          cursor: nextDayIsAvailable ? 'pointer' : 'default'
        }}
      >
        <RightArrow />
      </div>
    </div>
  );
};

export default WeeklyProgressBar;
