import React from 'react';

import classnames from 'classnames';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const AttendeeChip = ({ attendee }) => (
  <div className={classnames(styles.chip, styles.attendeeChip)}>
    <Typography
      variant="S-TEXT-2"
      color={colors.blue3}
      className={styles.attendeeChipText}
    >
      {attendee.name}
    </Typography>
  </div>
);

export default AttendeeChip;
