import React, { useEffect } from 'react';

import { get } from 'lodash';

import SelectFilter from 'components/SelectFilter';

const FILTER_OPTIONS = [
  { label: 'Full Year', value: 0 },
  { label: 'Last Month', value: 1 },
  { label: 'Last 3 Months', value: 3 },
  { label: 'Last 6 Months', value: 6 }
];

const MonthsBackFilter = ({ selectedMonthsBack, setSelectedMonthsBack }) => {
  const handleChange = (event) => {
    const newMonthsBackValue = event.target.value;

    setSelectedMonthsBack(
      FILTER_OPTIONS.find((item) => item.value === newMonthsBackValue)
    );
  };

  useEffect(() => {
    if (!selectedMonthsBack) {
      setSelectedMonthsBack(FILTER_OPTIONS[0]);
    }
  }, [selectedMonthsBack, setSelectedMonthsBack]);

  return (
    <SelectFilter
      value={get(selectedMonthsBack, 'value', '')}
      options={FILTER_OPTIONS}
      onChange={handleChange}
      placeholder="Months Back"
    />
  );
};

export default MonthsBackFilter;
