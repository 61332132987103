import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import _ from 'lodash';

import { AssignmentDialogModes } from 'pages/Teachers/shared/AssignmentDialog';
import { uploadFile } from 'utils/files';
import { notifyError } from 'utils/notifications';
import { getBlob } from 'utils/sdk';

const MAX_ATTACHMENT_FILE_SIZE_IN_BYTES = 20 * 1000 * 1000; // 20MB
const MAX_ATTACHMENT_FILE_SIZE_IN_MEGABYTES_STRING = '20MB';

const AttachmentDropzone = ({
  children,
  mode,
  initialAttachments,
  setFieldValue
}) => {
  const [attachments, setAttachments] = useState([]);
  const [initialAttachmentsUploaded, setInitialAttachmentsUploaded] =
    useState(false);

  const setAttachmentsAndFieldValue = useCallback(
    (attachments) => {
      setAttachments(attachments);
      setFieldValue('attachments', attachments);
    },
    [setFieldValue]
  );

  const reuploadInitialAttachments = useCallback(
    async (initialAttachments) => {
      const preparedAttachments = _.map(initialAttachments, (attachment) => ({
        ...attachment,
        id: null,
        uploaded: false
      }));

      setAttachments(preparedAttachments);

      const uploadedFileIds = await Promise.all(
        _.map(preparedAttachments, async ({ file }) => {
          const { data: blob } = await getBlob(file.url);

          return uploadFile({
            blob,
            name: file.name,
            maxSize: {
              sizeInBytes: MAX_ATTACHMENT_FILE_SIZE_IN_BYTES,
              sizeAsString: MAX_ATTACHMENT_FILE_SIZE_IN_MEGABYTES_STRING
            }
          });
        })
      );

      const uploadedFiles = _.map(initialAttachments, (attachment, index) => ({
        ...attachment,
        id: uploadedFileIds[index],
        uploaded: true
      }));

      setAttachmentsAndFieldValue(uploadedFiles);
    },
    [setAttachmentsAndFieldValue]
  );

  const prepareInitialAttachments = useCallback(
    (initialAttachments) => {
      const preparedAttachments = _.map(initialAttachments, (attachment) => ({
        ...attachment,
        id: attachment.file.id,
        uploaded: true
      }));

      setAttachmentsAndFieldValue(preparedAttachments);
    },
    [setAttachmentsAndFieldValue]
  );

  useEffect(() => {
    async function prepareAttachments() {
      if (
        mode === AssignmentDialogModes.COPY ||
        mode === AssignmentDialogModes.CREATE_FROM_TEMPLATE
      ) {
        await reuploadInitialAttachments(initialAttachments);
      } else if (mode === AssignmentDialogModes.UPDATE) {
        prepareInitialAttachments(initialAttachments);
      }

      setInitialAttachmentsUploaded(true);
    }

    if (_.isEmpty(initialAttachments) || initialAttachmentsUploaded) {
      return;
    }

    prepareAttachments();
  }, [
    mode,
    initialAttachments,
    initialAttachmentsUploaded,
    reuploadInitialAttachments,
    prepareInitialAttachments
  ]);

  const onDrop = async (files) => {
    const attachmentsBeforeDrop = _.cloneDeep(attachments);

    const preparedFiles = _.map(files, (file) => ({
      file,
      id: null,
      uploaded: false
    }));

    const preparedAttachments = [...attachmentsBeforeDrop, ...preparedFiles];
    setAttachmentsAndFieldValue(preparedAttachments);

    const uploadedFileIds = await Promise.all(
      _.map(preparedFiles, ({ file }) =>
        uploadFile({
          blob: file,
          name: file.name,
          maxSize: {
            sizeInBytes: MAX_ATTACHMENT_FILE_SIZE_IN_BYTES,
            sizeAsString: MAX_ATTACHMENT_FILE_SIZE_IN_MEGABYTES_STRING
          }
        })
      )
    );

    const uploadedFiles = _.map(files, (file, index) => ({
      file,
      id: uploadedFileIds[index],
      uploaded: true
    }));

    const uploadedAttachments = [...attachmentsBeforeDrop, ...uploadedFiles];
    setAttachmentsAndFieldValue(uploadedAttachments);
  };

  const onRemove = (indexToRemove) => {
    const newAttachments = _.filter(
      attachments,
      (value, index) => index !== indexToRemove
    );
    setAttachmentsAndFieldValue(newAttachments);
  };

  const onDropRejected = (files) =>
    _.forEach(files, ({ name }) =>
      notifyError(
        `${name} exceeds the size limit of ${MAX_ATTACHMENT_FILE_SIZE_IN_MEGABYTES_STRING}.`
      )
    );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxSize: MAX_ATTACHMENT_FILE_SIZE_IN_BYTES,
    disableClick: true,
    onDropRejected,
    noKeyboard: true
  });

  return (
    <div
      {...getRootProps({
        onClick: (event) => event.stopPropagation()
      })}
    >
      <input {...getInputProps()} />
      {children({ attachments, onRemove, isDragActive, openFileDialog: open })}
    </div>
  );
};

export default AttachmentDropzone;
