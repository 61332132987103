import React, { useRef } from 'react';

import _ from 'lodash';

import StudentWorkCard from 'pages/Teachers/Assignments/Results/components/StudentWorkCard';
import { useTouchSideScroll } from 'utils/touchScroll';

import styles from './styles.module.scss';

// This is a separate component only because useTouchSideScroll depends on having the "unmount"
// lifecycle event being triggered whenever the touchScrollContainerRef is not present.
const ScrollableStudentWorkList = ({
  studentWorkList,
  showScores,
  addComment,
  visibleTaskIds,
  multiSelectMode,
  buildAssignmentFeedbackLinkObject,
  toggleSelectStudentWorkItem,
  storeActiveTaskId,
  removeActiveTaskId,
  activeTasksIds,
  setSelectedWorksSelectedChildren,
  refetchStudentWork
}) => {
  const touchScrollContainerRef = useRef();

  const stopTouchScroll = useTouchSideScroll(touchScrollContainerRef, []);

  return (
    <div
      className={styles.studentsTasksContainer}
      ref={touchScrollContainerRef}
    >
      {_.map(studentWorkList, ({ student, work }) => (
        <div className={styles.studentRow} key={student.id}>
          {_.map(work, (workItem, index) => (
            <>
              <StudentWorkCard
                key={`${student.id}_${index}`}
                student={student}
                selected={workItem.selected}
                tasksData={[workItem]}
                showScores={showScores}
                addComment={addComment}
                visibleTaskIds={visibleTaskIds}
                multiSelectMode={multiSelectMode}
                toggleSelectStudentWorkItem={toggleSelectStudentWorkItem}
                buildAssignmentFeedbackLinkObject={
                  buildAssignmentFeedbackLinkObject
                }
                activeTasksIds={activeTasksIds}
                storeActiveTaskId={storeActiveTaskId}
                removeActiveTaskId={removeActiveTaskId}
                stopTouchScroll={stopTouchScroll}
                setSelectedWorksSelectedChildren={
                  setSelectedWorksSelectedChildren
                }
                refetchStudentWork={refetchStudentWork}
              />
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ScrollableStudentWorkList;
