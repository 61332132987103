import React, { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import StudentWorkCard from 'pages/Teachers/MeetingSort/components/StudentWorkCard';
import { buildItemId } from 'pages/Teachers/MeetingSort/utils';

import styles from './styles.module.scss';

const DraggableItemCard = ({ id, index, item }) => (
  <Draggable draggableId={buildItemId(id)} index={index}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        className={styles.draggableItemCard}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <StudentWorkCard key={item.student.id} studentWork={item} />
      </div>
    )}
  </Draggable>
);

export default memo(DraggableItemCard);
