import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import _ from 'lodash';

import { useMixpanelPageOpenTrack } from 'utils/integrations/mixpanel';

import MeetingSortContext from 'components/MeetingSortContext';

import { AddColumnButton, Column, MeetingSortSkeleton } from './components';
import {
  useBoardColumns,
  useMeetingDetail,
  useNavigation,
  usePusherManager,
  useSortInfo,
  useSorts
} from './hooks';
import { updateBoardColumnsOrder, updateItemPosition } from './sdk';
import styles from './styles.module.scss';
import { getNonExemplarItemsCount, parseColumnId, parseItemId } from './utils';

const MeetingSort = () => {
  useMixpanelPageOpenTrack('Teacher viewed sort');

  const { invitationIdentifier } = useParams();

  const meeting = useMeetingDetail(invitationIdentifier);

  const {
    sorts,
    refetchSorts,
    loading: loadingSorts,
    selectedSort,
    selectSort
  } = useSorts({
    meetingId: meeting?.id
  });

  const selectedSortId = useMemo(
    () => _.get(selectedSort, 'id'),
    [selectedSort]
  );

  const [sortInfoIsLoading, sortInfo] = useSortInfo(selectedSortId);

  const [boardId, setBoardId] = useState();
  const {
    columns,
    setColumns,
    columnsOrder,
    setColumnsOrder,
    refetchBoardColumns,
    addNewColumn,
    removeColumn,
    updateColumnName
  } = useBoardColumns(boardId);

  const [items, setItems] = useState(null);
  const [workIndexes, setWorkIndexes] = useState({});
  const [taskIndexes, setTaskIndexes] = useState({});
  const [paginatedTasksStartIndexes, setPaginatedTasksStartIndexes] = useState(
    {}
  );

  const onSortSelect = useCallback(
    (sortId) => {
      setColumnsOrder([]);
      setColumns(null);
      selectSort(sortId);
    },
    [setColumnsOrder, setColumns, selectSort]
  );

  useNavigation({
    meeting,
    sorts,
    loadingSorts,
    selectedSortId,
    onSortSelect,
    onSelectOpen: refetchSorts
  });

  usePusherManager(boardId, refetchBoardColumns);

  useEffect(() => {
    if (!sortInfoIsLoading && !_.isNil(sortInfo)) {
      const initialItems = _.keyBy(sortInfo.meeting_board_items, 'item_id');

      setBoardId(sortInfo.board_id);
      refetchBoardColumns();
      setItems(initialItems);
    }
  }, [sortInfoIsLoading, sortInfo, refetchBoardColumns]);

  const handleItemMovement = useCallback(
    ({ destination, source, draggableId }) => {
      const startColumn = columns[parseColumnId(source.droppableId)];
      const endColumn = columns[parseColumnId(destination.droppableId)];
      const itemId = parseItemId(draggableId);

      if (startColumn === endColumn) {
        // Moving within the same column

        const newItemsOrder = Array.from(startColumn.items_order);
        newItemsOrder.splice(source.index, 1);
        newItemsOrder.splice(destination.index, 0, itemId);

        const newColumn = { ...startColumn, items_order: newItemsOrder };
        setColumns((prevColumns) => ({
          ...prevColumns,
          [newColumn.id]: newColumn
        }));
      } else {
        // Moving within different columns

        const newStartItemsOrder = Array.from(startColumn.items_order);
        newStartItemsOrder.splice(source.index, 1);
        const newStartColumn = {
          ...startColumn,
          items_order: newStartItemsOrder
        };

        const newEndItemsOrder = Array.from(endColumn.items_order);
        newEndItemsOrder.splice(destination.index, 0, itemId);
        const newEndColumn = {
          ...endColumn,
          items_order: newEndItemsOrder
        };

        setColumns((prevColumns) => ({
          ...prevColumns,
          [newStartColumn.id]: newStartColumn,
          [newEndColumn.id]: newEndColumn
        }));
      }

      updateItemPosition(itemId, {
        source_column: startColumn.id,
        destination_column: endColumn.id,
        position: destination.index
      });
    },
    [columns, setColumns]
  );

  const handleColumnMovement = useCallback(
    ({ destination, source, draggableId }) => {
      const newColumnsOrder = Array.from(columnsOrder);
      newColumnsOrder.splice(source.index, 1);
      newColumnsOrder.splice(destination.index, 0, parseColumnId(draggableId));

      setColumnsOrder(newColumnsOrder);

      // We do not need to wait for the response of the update API.
      // We perform an optimistic re-render and then send the request to the BE.
      // This allows the front-end to be a bit more snappy.
      const updateData = { columns_order: newColumnsOrder };
      updateBoardColumnsOrder(boardId, updateData);
    },
    [columnsOrder, setColumnsOrder, boardId]
  );

  const onDragEnd = useCallback(
    (result) => {
      const { destination, source, type } = result;

      if (!destination) {
        return;
      }

      // User dropped the item back in the original position
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      if (type === 'item') {
        handleItemMovement(result);
      } else if (type === 'column') {
        handleColumnMovement(result);
      }
    },
    [handleItemMovement, handleColumnMovement]
  );

  const isLoadingSortData = useMemo(
    () => sortInfoIsLoading || _.isEmpty(columnsOrder) || _.isNil(columns),
    [sortInfoIsLoading, columnsOrder, columns]
  );

  if (isLoadingSortData) {
    return <MeetingSortSkeleton />;
  }

  return (
    <MeetingSortContext.Provider
      value={{
        columns,
        workIndexes,
        setWorkIndexes,
        taskIndexes,
        setTaskIndexes,
        handleItemMovement,
        paginatedTasksStartIndexes,
        setPaginatedTasksStartIndexes
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="columns" direction="horizontal" type="column">
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={styles.container}
              {...provided.droppableProps}
            >
              {_.map(columnsOrder, (columnId, index) => {
                const column = columns[columnId];

                // There can be a racing condition between setting `columnsOrder` & `columns`.
                if (!column) {
                  return;
                }

                const itemsList = _.map(
                  column.items_order,
                  (itemId) => items[itemId]
                );

                return (
                  <Column
                    key={columnId}
                    index={index}
                    items={itemsList}
                    nonExemplarItemsCount={getNonExemplarItemsCount(columns)}
                    column={column}
                    onColumnNameChange={updateColumnName}
                    removeColumn={removeColumn}
                  />
                );
              })}
              {provided.placeholder}
              <AddColumnButton addNewColumn={addNewColumn} />
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </MeetingSortContext.Provider>
  );
};

export default MeetingSort;
