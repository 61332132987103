import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import { get, isEmpty } from 'lodash';
import { useYears } from 'sdk';

import ConceptMastery from './components/ConceptMastery';
import LearningTrends from './components/LearningTrends';
import MonthsBackFilter from './components/MonthsBackFilter';
import StrengthsAndWhereToGrow from './components/StrengthsAndWhereToGrow';
import YearFilter from './components/YearFilter';
import ExportButton from 'components/ExportButton';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StudentJourney = ({ student }) => {
  const { data: yearsData, isLoading: isLoadingYears } = useYears();

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonthsBack, setSelectedMonthsBack] = useState(null);
  const [exportData, setExportData] = useState(null);

  const studentId = useMemo(() => get(student, 'id', null), [student]);
  const studentName = useMemo(() => get(student, 'name', null), [student]);

  const studentJourneyContainerRef = useRef(null);
  const [studentJourneyContainerWidth, setStudentJourneyContainerWidth] =
    useState(0);

  const updateWidth = () => {
    if (studentJourneyContainerRef.current) {
      setStudentJourneyContainerWidth(
        studentJourneyContainerRef.current.offsetWidth
      );
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const handleExportClick = useCallback(() => {
    if (!exportData) return;

    const captureElement = document.getElementById('export-target');

    const width = window.innerWidth;
    const height = captureElement.clientHeight;

    html2canvas(captureElement, {
      scale: 1.5,
      width: width,
      height: height,
      windowWidth: width,
      windowHeight: height
    }).then((canvas) => {
      // setup screenshot
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('l', 'mm', 'a4');

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 1, 1, pdfWidth, pdfHeight);

      // setup custom data table
      const tableStartY = pdfHeight + 10;
      const tableHeaders = [
        { title: 'Month', dataKey: 'month' },
        { title: 'Total Concepts', dataKey: 'total_concepts' },
        {
          title: 'Student Concepts Mastered',
          dataKey: 'student_concepts_mastered'
        },
        {
          title: 'Class Average Concepts Mastered',
          dataKey: 'class_average_concepts_mastered'
        }
      ];
      const tableRows = exportData.map((item) => ({
        month: item.month,
        total_concepts: item.total_concepts,
        student_concepts_mastered: item.student_concepts_mastered,
        class_average_concepts_mastered: item.class_average_concepts_mastered
      }));

      autoTable(pdf, {
        startY: tableStartY,
        head: [tableHeaders.map((header) => header.title)],
        body: tableRows.map((row) => Object.values(row)),
        headStyles: { fillColor: [0, 51, 102] }
      });

      pdf.save('student-journey-export.pdf');
    });
  }, [exportData]);

  return (
    <Paper
      className={styles.studentJourneyContainer}
      variant="outlined"
      ref={studentJourneyContainerRef}
      id="export-target"
    >
      <div>
        <div className={styles.header}>
          <Typography variant="H-TEXT-1" className={styles.title}>
            Academic Journey
          </Typography>
          <ExportButton handleClick={handleExportClick} />
        </div>
        {!isEmpty(yearsData) && !isLoadingYears && (
          <Grid container spacing={3}>
            <Grid item xs={4} md={2}>
              <YearFilter
                years={yearsData}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <MonthsBackFilter
                selectedMonthsBack={selectedMonthsBack}
                setSelectedMonthsBack={setSelectedMonthsBack}
              />
            </Grid>
          </Grid>
        )}
      </div>
      <div className={styles.innerContainer}>
        <Paper className={styles.leftContainer} variant="outlined">
          <ConceptMastery
            width={studentJourneyContainerWidth - 580}
            studentId={studentId}
            studentName={studentName}
            selectedYear={selectedYear}
            selectedMonthsBack={selectedMonthsBack}
            setExportData={setExportData}
          />
        </Paper>
        <div className={styles.rightContainer}>
          <LearningTrends
            studentId={studentId}
            selectedYear={selectedYear}
            selectedMonthsBack={selectedMonthsBack}
          />
          <StrengthsAndWhereToGrow
            studentId={studentId}
            selectedYear={selectedYear}
            selectedMonthsBack={selectedMonthsBack}
          />
        </div>
      </div>
    </Paper>
  );
};

export default StudentJourney;
