import { BASE_URL } from 'config/urls';

import { notifyError } from 'utils/notifications';
import { get, post, requestSdk } from 'utils/sdk';

export const getAllNotifications = (params) =>
  new Promise((resolve) =>
    get(`${BASE_URL}/v1/trackers/notifications/student/`, params)
      .then(resolve)
      .catch(notifyError)
  );

export const markAllUnseenNotificationsAsSeen = async () =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/trackers/notifications/student/mark-unseen-as-seen/`)
  );

export const archiveNotifications = async () =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/trackers/notifications/student/archive/`)
  );
