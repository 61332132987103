import React from 'react';

import styles from './styles.module.scss';

const DiagramLegend = ({ studentName }) => {
  return (
    <div>
      <div className={styles.diagramLegend}>
        <span>{studentName}</span>
        <svg width={20} height={20}>
          <rect width={20} height={20} fill={'#CC2364'} />
        </svg>
      </div>
      <div className={styles.diagramLegend}>
        <span>Average</span>
        <svg width={20} height={20}>
          <rect width={20} height={20} fill={'#333966'} />
        </svg>
      </div>
    </div>
  );
};

export default DiagramLegend;
