import React from 'react';

import PersonalGoals from './components/PersonalGoals';

import styles from './styles.module.scss';

const PathwaysProfilePersonalGoals = () => {
  return (
    <div className={styles.pathwaysProfilePersonalGoals}>
      <PersonalGoals />
    </div>
  );
};

export default PathwaysProfilePersonalGoals;
