import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import { useFetch } from 'utils/sdk';
import { parseParams, stringifyParams } from 'utils/urls';

import { ORDERING_OPTIONS, SHOW_FILTER_OPTIONS } from './constants';
import { tasksList } from './sdk';

const mapTasksResponse = (response) =>
  _.map(response, (task, index) => ({
    ...task,
    label: `${index + 1}. ${task.name}`
  }));

export const useTasksOptions = (selectedSectionId) => {
  const { data, loading } = useFetch(
    tasksList,
    { data: [], loading: true },
    selectedSectionId,
    mapTasksResponse
  );

  return [data, loading];
};

export const useDefaultOrdering = (ordering, setOrdering) => {
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (_.isEmpty(ordering.value)) {
      const params = parseParams(history.location.search, {
        parseNumbers: true,
        arrayFormat: 'comma'
      });
      const orderingParam = _.get(params, 'ordering');
      const orderingValue =
        _.find(ORDERING_OPTIONS, { value: orderingParam }) ||
        ORDERING_OPTIONS[0];

      setOrdering(orderingValue);
      setInitialized(true);
    }
  }, [history, ordering, setOrdering]);

  return initialized;
};

const areFiltersEmpty = (filters) => {
  return (
    _.isEmpty(filters.tasks) &&
    _.isEmpty(filters.show.value) &&
    _.isEmpty(filters.students)
  );
};

export const useDefaultFilters = (
  filters,
  setFilters,
  studentsOptions,
  tasksOptions,
  loadingTasksOptions
) => {
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (areFiltersEmpty(filters) && !loadingTasksOptions) {
      const params = parseParams(history.location.search, {
        parseNumbers: true,
        arrayFormat: 'comma'
      });

      let tasksParam = _.get(params, 'tasks', []);
      // Ensure tasks param value is always an array.
      tasksParam = _.isArray(tasksParam) ? tasksParam : [tasksParam];

      const tasks = _.filter(tasksOptions, (task) =>
        _.includes(tasksParam, task.id)
      );

      const showParam = _.get(params, 'show');
      const show =
        _.find(SHOW_FILTER_OPTIONS, { value: showParam }) ||
        SHOW_FILTER_OPTIONS[0];

      let studentsParam = _.get(params, 'students', []);
      // Ensure tasks param value is always an array.
      studentsParam = _.isArray(studentsParam)
        ? studentsParam
        : [studentsParam];

      const students = _.filter(studentsOptions, (student) =>
        _.includes(studentsParam, student.id)
      );

      setFilters({ show, tasks, students });
      setInitialized(true);
    }
  }, [
    history,
    filters,
    setFilters,
    studentsOptions,
    tasksOptions,
    loadingTasksOptions
  ]);

  return initialized;
};

export const useHistorySearch = (
  selectedSectionId,
  filters,
  filtersInitialized,
  ordering,
  orderingInitialized
) => {
  const history = useHistory();

  useEffect(() => {
    if (selectedSectionId && filtersInitialized && orderingInitialized) {
      const params = {
        section: selectedSectionId,
        tasks: _.map(filters.tasks, 'id'),
        students: _.map(filters.students, 'id'),
        show: filters.show.value,
        ordering: ordering.value
      };

      history.replace({
        search: stringifyParams(params, { arrayFormat: 'comma' })
      });
    }
  }, [
    history,
    filters,
    ordering,
    selectedSectionId,
    filtersInitialized,
    orderingInitialized
  ]);
};
