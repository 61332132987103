import { BASE_URL } from 'config/urls';

import { callUrl, post } from 'utils/sdk';

export const updateTaskStatus = ({ trackerScoreResponseId, data }) =>
  callUrl(
    post,
    `${BASE_URL}/v1/trackers/assignments/task/${trackerScoreResponseId}/update-status/`,
    data
  );

export const postAssignmentComment = ({ trackerScoreResponseId, data }) =>
  callUrl(
    post,
    `${BASE_URL}/v1/trackers/assignments/${trackerScoreResponseId}/add-comment/`,
    data
  );

export const uploadAssignmentFeedback = (data) =>
  callUrl(
    post,
    `${BASE_URL}/v2/trackers/assignments/task/upload-feedback/`,
    data
  );
