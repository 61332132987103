import React, { useEffect, useState } from 'react';

import familyLogo from 'pages/Family/assets/family-logo.svg';

import ConfirmStudents from '../ConfirmStudents';
import UpdateProfile from '../UpdateProfile';
import styles from './styles.module.scss';

const ManageStudents = ({ students, onSubmit, firstTimeLogin = false }) => {
  const [selectedStudents, setSelectedStudents] = useState({});
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);

  useEffect(() => {
    const initialSelected = students.reduce((acc, student) => {
      if (student.confirmed) {
        acc[student.id] = true;
      }
      return acc;
    }, {});
    setSelectedStudents(initialSelected);
  }, [students]);

  const handleStudentToggle = (studentId) => {
    setSelectedStudents((prev) => ({
      ...prev,
      [studentId]: !prev[studentId]
    }));
  };

  const handleContinue = () => {
    setShowConfirmDialog(true);
  };

  const handleCancel = () => {
    setShowConfirmDialog(false);
  };

  const handleConfirm = async () => {
    setShowConfirmDialog(false);
    setShowUpdateProfile(true);
  };

  const selectedStudentNames = students
    .filter((student) => selectedStudents[student.id])
    .map((student) => `${student.first_name} ${student.last_name}`);

  const selectedStudentData = students
    .filter((student) => selectedStudents[student.id])
    .reduce((acc, student) => {
      acc[student.id] = student;
      return acc;
    }, {});

  if (showUpdateProfile) {
    return (
      <UpdateProfile
        students={selectedStudentData}
        firstTimeLogin={firstTimeLogin}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img
          className={styles.familyLogo}
          src={familyLogo}
          alt="EdLight Logo"
        />
      </div>

      <div className={styles.iconContainer}>
        <span className={styles.icon}>👤👤</span>
      </div>

      <p className={styles.instruction}>
        Choose all your student(s) to connect to their EdLight account.
      </p>

      <div className={styles.studentList}>
        {students.map((student) => (
          <div
            key={student.id}
            className={`${styles.studentItem} ${
              selectedStudents[student.id] ? styles.selected : ''
            }`}
            onClick={() => handleStudentToggle(student.id)}
          >
            <span className={styles.studentName}>
              {`${student.first_name} ${student.last_name}`}
            </span>
          </div>
        ))}
      </div>

      <p className={styles.helperText}>
        Don't see one or more of your students?
        <br />
        Reach out to their teacher to have them invite you to the app.
      </p>

      <button className={styles.continueButton} onClick={handleContinue}>
        Continue
      </button>

      {showConfirmDialog && (
        <ConfirmStudents
          selectedStudentNames={selectedStudentNames}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default ManageStudents;
