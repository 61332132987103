import React from 'react';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Skeleton from '@material-ui/lab/Skeleton';
import cx from 'classnames';
import _ from 'lodash';

import { STANDARD_MSA } from 'utils/constants';

import Typography from 'components/Typography';

import { MSA_LABEL_MAPPING } from './constants';
import styles from './styles.module.scss';

const Placeholder = ({ count = 1 }) =>
  _.range(count).map((index) => (
    <div key={index} className={styles.container}>
      <div className={styles.topRow}>
        <Skeleton width={30} />
        <div className={styles.group}>
          <Typography
            variant="B-TEXT-2"
            className={styles.standardChildrenCount}
          >
            <Skeleton width={15} />
          </Typography>

          <KeyboardArrowRightIcon className={styles.icon} />
        </div>
      </div>
      <Typography variant="B-TEXT-2">
        <Skeleton count={2} />
      </Typography>
    </div>
  ));

const StandardBox = ({ standard, onClick }) => (
  <div onClick={onClick} className={styles.container}>
    <div className={styles.topRow}>
      <Typography variant="S-TEXT-2" className={styles.code}>
        {standard.code}
      </Typography>
      <div className={styles.group}>
        {standard.msa && (
          <div
            className={cx(styles.standardMsaWrapper, {
              [styles.major]: standard.msa === STANDARD_MSA.MAJOR,
              [styles.supporting]: standard.msa === STANDARD_MSA.SUPPORTING,
              [styles.additional]: standard.msa === STANDARD_MSA.ADDITIONAL
            })}
          >
            <Typography variant="S-TEXT-1">
              {MSA_LABEL_MAPPING[standard.msa]}
            </Typography>
          </div>
        )}

        <Typography variant="B-TEXT-2" className={styles.standardChildrenCount}>
          {standard.nested_assignments_count}
        </Typography>

        <KeyboardArrowRightIcon className={styles.icon} />
      </div>
    </div>
    <Typography variant="B-TEXT-2">{standard.short_description}</Typography>
  </div>
);

StandardBox.Placeholder = Placeholder;

export default StandardBox;
