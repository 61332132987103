import React from 'react';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const ExcludeImageConfirmationDialog = ({ onCancel, onConfirm }) => (
  <div className={styles.container}>
    <Typography variant="H-TEXT-2" className={styles.header}>
      Image will be permanently lost
    </Typography>
    <Button fullWidth className={styles.cancelButton} onClick={onCancel}>
      Cancel
    </Button>
    <Button
      fullWidth
      color="pink"
      className={styles.confirmButton}
      onClick={onConfirm}
    >
      Confirm
    </Button>
  </div>
);

export default ExcludeImageConfirmationDialog;
