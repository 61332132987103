import React, { useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import MuiTab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import palette from 'theme/palette';

import Emojis from 'components/Emojis';
import Image from 'components/Image';

import styles from './styles.module.scss';

const Tab = withStyles({
  root: {
    minWidth: 75,
    width: 75,
    opacity: 1
  }
})(MuiTab);

const StickerSelector = ({ onStickerSelected, close }) => {
  const [activeSticker, setActiveSticker] = useState(0);
  const [stickerUsed, setStickedUsed] = useState(false);

  const handleClickAway = () => {
    if (stickerUsed) {
      close();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid style={{ backgroundColor: palette.common.colors.greyWhite }}>
        <Tabs
          value={activeSticker}
          onChange={(e, stickerNumber) => {
            setActiveSticker(stickerNumber);
            onStickerSelected(Emojis[stickerNumber]);
            setStickedUsed(true);
          }}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
        >
          {_.map(Emojis, (emoji, index) => (
            <Tab
              key={index}
              icon={<Image className={styles.stickerIcon} src={emoji} />}
            />
          ))}
        </Tabs>
      </Grid>
    </ClickAwayListener>
  );
};
export default StickerSelector;
