import React from 'react';

import ExplanationChart from './components/ExplanationChart';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const ResultExplanationItem = ({ title, description, results }) => {
  return (
    <div className={styles.resultsExplanationItem}>
      <div className={styles.imageContainer}>
        <ExplanationChart results={results} title={title} />
      </div>
      <div className={styles.textContainer}>
        <Typography variant="S-TEXT-1" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="B-Text-4" className={styles.description}>
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default ResultExplanationItem;
