import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import _ from 'lodash';

export const getSchoolOptionLabel = ({ school }) => {
  // School selected with enter, right from the input
  if (typeof school === 'string') {
    return school;
  }

  // Add "xxx" option created dynamically
  if (school.inputValue) {
    return school.inputValue;
  }

  // Regular option - school name (school city)
  return _.isEmpty(school.city)
    ? school.name
    : `${school.name} (${school.city})`;
};

export const getSchoolFilterOptions = ({ options, params }) => {
  const filter = createFilterOptions();

  const filtered = filter(options, params);

  const newSchoolLabel =
    params.inputValue !== '' ? `Add "${params.inputValue}"` : 'Add new school';

  // Suggest the creation of a new value
  filtered.push({
    id: null,
    inputValue: params.inputValue,
    name: newSchoolLabel,
    newSchool: true
  });

  return filtered.slice(0, 20);
};
