import React from 'react';

import Typography from 'components/Typography';

import { ReactComponent as LoaderImage } from './assets/loader.svg';
import styles from './styles.module.scss';

const LoadingContainer = ({ text }) => {
  return (
    <div className={`${styles.loaderContainer} ${styles.bounceAnimation}`}>
      <div className={styles.imageContainer}>
        <LoaderImage />
        <div
          className={`${styles.dotsContainer} ${styles.loading} ${styles.bounceAnimation}`}
        >
          <div className={`${styles.dot} forward-animation`}></div>
          <div className={`${styles.dot} forward-animation`}></div>
          <div className={`${styles.dot} forward-animation`}></div>
        </div>
      </div>
      <Typography variant="H-TEXT-1" className={styles.text}>
        {text}
      </Typography>
    </div>
  );
};

export default LoadingContainer;
