import React from 'react';

import { notifyErrors } from 'utils/notifications';

import { magicLogin } from './sdk';

const MagicLogin = (props) => {
  const { token } = props.match.params;

  React.useEffect(() => {
    magicLogin(token)
      .then(({ data, success, errors }) => {
        if (success) {
          const { redirect_url } = data;
          window.location = redirect_url;
        } else {
          notifyErrors(errors);
        }
      })
      .catch(notifyErrors);
  }, [token]);

  return null;
};

export default MagicLogin;
