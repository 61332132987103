import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import { Link } from 'react-router-dom';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import cx from 'classnames';
import {
  MOBILE_APP_URL,
  TEACHERS_BULK_UPLOAD_WORK_UPLOAD_URL
} from 'config/urls';
import scanImage from 'images/scan.png';

import { useNavbar } from 'pages/Teachers/shared/TeacherPageLayout';
import { colors } from 'theme/palette';
import { useMixpanelPageOpenTrack } from 'utils/integrations/mixpanel';

import Image from 'components/Image';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Capture = () => {
  useNavbar({ title: 'CAPTURE WORK' });

  useMixpanelPageOpenTrack('Teacher opened Capture Page');

  return (
    <div className={styles.wrapper}>
      <Link
        className={cx(styles.container, styles.hoverable)}
        to={TEACHERS_BULK_UPLOAD_WORK_UPLOAD_URL}
      >
        <div className={styles.topRow}>
          <Typography variant="H-TEXT-1" color={colors.blue3}>
            Upload PDF
          </Typography>
          <ArrowForwardIosIcon
            htmlColor={colors.blue3}
            fontSize="small"
            className={styles.icon}
          />
        </div>
        <Image src={scanImage} />

        <Typography
          variant="B-Text-3"
          className={styles.description}
          color={colors.blue1}
        >
          Bulk Scan Student Work and EdLight’s AI Matches Students to Pages
          Automatically
        </Typography>
      </Link>

      <div className={styles.container}>
        <Typography variant="H-TEXT-1" color={colors.blue3}>
          use scanning app
        </Typography>
        <QRCodeSVG
          width={187}
          height={187}
          value={`${window.location.origin}${MOBILE_APP_URL}`}
        />

        <Typography
          variant="B-Text-3"
          className={styles.description}
          color={colors.blue1}
        >
          Get EdLight’s Phone / Tablet Scanning App to Easily Capture Student
          Work
        </Typography>
      </div>
    </div>
  );
};

export default Capture;
