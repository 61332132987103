import React from 'react';

import _ from 'lodash';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

const TitledList = ({ elements, title, onElementClick, getOptionLabel }) => {
  if (_.isEmpty(elements)) {
    return null;
  }

  return (
    <div>
      <Typography variant="S-TEXT-1" color={colors.blue4}>
        {title}
      </Typography>
      <ul>
        {_.map(elements, (el) => (
          <li key={el.id}>
            <Typography
              variant="B-Text-2"
              color={colors.blue3}
              className="pointer"
              component="span"
              onClick={() => onElementClick(el)}
            >
              {getOptionLabel(el)}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(TitledList);
