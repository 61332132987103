import { BASE_URL } from 'config/urls';

import { get, requestSdk } from 'utils/sdk';

export const findAssignment = async (studentId, trackerInstanceId, task) =>
  await requestSdk(() =>
    get(
      `${BASE_URL}/v1/students/${studentId}/assignments/find/${trackerInstanceId}`,
      { task }
    )
  );
