import { BASE_URL } from 'config/urls';

import { get, post, requestSdk } from 'utils/sdk';

export const canPreviewAsStudent = async (trackerInstanceId) => {
  const url = `${BASE_URL}/v1/trackers/assignments/${trackerInstanceId}/can-preview-as-student/`;
  return await requestSdk(() => get(url));
};

export const addTeacherToSections = async (sectionIds) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/sections/add-teacher/`, { sections: sectionIds })
  );
