import React, { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { useMeetingInfo as useMeetingInfoAction } from 'sdk';
import { noopPromise } from 'utils';

import { TopBarSelect, useNavbar } from 'pages/Teachers/shared';
import { useFetch } from 'utils/sdk';

import { getMeetingSharesList, getTeamDetail } from './sdk';
import styles from './styles.module.scss';
import { buildMeetingsOptions, getMeetingLabel } from './utils';

export const useTeam = (teamId) => {
  const { data: team } = useFetch(getTeamDetail, { data: null }, teamId);
  return team;
};

export const useNavigation = ({
  teamName,
  meetings,
  selectedMeeting,
  onMeetingSelect,
  ...rest
}) => {
  const { navigation } = useNavbar({ title: teamName, sticky: true, ...rest });

  const renderValue = useCallback(
    (invitation_identifier) => {
      const meeting = _.find(meetings, { invitation_identifier });
      return getMeetingLabel(meeting);
    },
    [meetings]
  );

  useEffect(() => {
    if (!_.isEmpty(meetings) && selectedMeeting) {
      navigation.setLeftSubheader(
        <TopBarSelect
          className={styles.meetingSelectFormControl}
          MenuProps={{ classes: { paper: styles.meetingSelectPaper } }}
          value={selectedMeeting?.invitation_identifier}
          onChange={onMeetingSelect}
          renderValue={renderValue}
          options={buildMeetingsOptions(meetings)}
        />
      );
    }
  }, [navigation, meetings, selectedMeeting, onMeetingSelect, renderValue]);
};

export const useMeetingShares = (requestData, triggerFetch) => {
  const sdk = useMemo(
    () => (triggerFetch ? getMeetingSharesList : noopPromise),
    [triggerFetch]
  );
  const result = useFetch(
    sdk,
    {
      data: [],
      loading: true
    },
    requestData
  );

  const data = useMemo(() => {
    // If the page is not ready to perform the request we need to make sure that it's still in a loading state
    if (!triggerFetch || result.data === undefined) {
      return { ...result, loading: true };
    }
    return result;
  }, [result, triggerFetch]);

  return data;
};

export const useMeetingInfo = (invitationIdentifier) => {
  const [meetingInfo, setMeetingInfo] = useState(null);

  const params = useMemo(() => ({}), []);

  const errorHandlers = useMemo(
    () => ({
      403: (error) => {
        throw error;
      }
    }),
    []
  );

  const { data, error, isLoading, refetch } = useMeetingInfoAction({
    invitationIdentifier,
    params,
    errorHandlers
  });

  useEffect(() => {
    if (!isLoading) {
      setMeetingInfo(data);
    }
  }, [data, isLoading]);

  return [isLoading, error, meetingInfo, setMeetingInfo, refetch];
};
