import React from 'react';

import logoSmall from 'images/logo-small.svg';

import styles from './styles.module.scss';

const UnderConstruction = () => {
  return (
    <div className={styles.contentWrapper}>
      <img src={logoSmall} alt="Logo" />
      <h2>Coming Soon...</h2>
      <p>
        <i>Bright Ideas Are Just Around the Corner!</i>
      </p>
    </div>
  );
};

export default UnderConstruction;
