import { useCallback, useEffect, useRef } from 'react';

const TIMING = (1 / 120) * 1000; // (120fps)
export const useDraggable = ({ ref }) => {
  // Inspired by https://www.npmjs.com/package/react-use-draggable-scroll.

  const internalState = useRef({
    isMouseDown: false,
    isDraggingX: false,
    initialMouseX: 0,
    lastMouseX: 0,
    scrollSpeedX: 0,
    lastScrollX: 0
  });

  const runScroll = useCallback(() => {
    const dx = internalState.current.scrollSpeedX * TIMING;
    const offsetX = ref.current.scrollLeft + dx;

    ref.current.scrollLeft = offsetX;
    internalState.current.lastScrollX = offsetX;
  }, [ref]);

  const preventClick = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    // e.stopPropagation();
  };

  const onMouseDown = (e) => {
    const isLeftMouseButton = e.buttons === 1;
    if (!isLeftMouseButton) {
      return;
    }

    internalState.current.isMouseDown = true;
    internalState.current.lastMouseX = e.clientX;
    internalState.current.initialMouseX = e.clientX;
  };

  const onMouseUp = useCallback(
    (e) => {
      const isDragging = internalState.current.isDraggingX;

      const dx = internalState.current.initialMouseX - e.clientX;

      const isMotionIntentional = Math.abs(dx) > 10;

      const isDraggingConfirmed = isDragging && isMotionIntentional;

      if (isDraggingConfirmed) {
        ref.current.childNodes.forEach((child) => {
          child.addEventListener('click', preventClick);
        });
      } else {
        ref.current.childNodes.forEach((child) => {
          child.removeEventListener('click', preventClick);
        });
      }

      internalState.current.isMouseDown = false;
      internalState.current.lastMouseX = 0;
    },
    [ref]
  );

  const onMouseMove = useCallback(
    (e) => {
      if (!internalState.current.isMouseDown) {
        return;
      }

      e.preventDefault();

      const dx = internalState.current.lastMouseX - e.clientX;
      internalState.current.lastMouseX = e.clientX;

      internalState.current.scrollSpeedX = dx / TIMING;
      internalState.current.isDraggingX = true;

      runScroll();
    },
    [runScroll]
  );

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseUp, onMouseMove]);

  return {
    onMouseDown
  };
};
