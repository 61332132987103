export const MuiInputBase = () => ({
  input: {
    // `Nunito` font requires more height than the default `1.1876em` (19px -> 20px)
    // For reference: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/InputBase/InputBase.js#L43
    // Also, our "1em" is 14px, compared to the default for MaterialUI - 16px
    height: '1.4285em'
  },
  root: {
    // `Nunito` font requires more height than the default `1.1876em` (19px -> 20px)
    // For reference: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/InputBase/InputBase.js#L43
    // Also, our "1em" is 14px, compared to the default for MaterialUI - 16px
    lineHeight: '1.4285em'
  }
});
