import _ from 'lodash';
import { randomNumber } from 'utils';

import { addStickerToImage } from 'pages/Teachers/shared/StickerAddingTool/utils';
import { AssignmentStatusOptions } from 'utils/constants';
import { uploadImage } from 'utils/files';
import { buildFilters } from 'utils/filters';
import { formatDate } from 'utils/moment';
import { notifyErrors, notifyInfo } from 'utils/notifications';

import {
  addStudentComment,
  bulkAddStudentComment,
  bulkUpdateTasksScores,
  bulkUpdateTasksStatuses,
  getStudentWorkList,
  updateTaskStatus,
  uploadAssignmentFeedback
} from '../sdk';

const emptyWork = ({ taskId, responseId, objectiveOrder }) => ({
  emptyWork: true,

  tracker_instance_objective_id: taskId,
  tracker_score_response_id: responseId,
  objective_order: objectiveOrder,
  status: 'assigned',
  id: randomNumber(),
  created_at: null,
  is_feedback: false,
  is_revision: false,
  original_filename: null,
  original_id: null,
  work_url: null,
  children: [],
  standards: []
});

export const fillEmptyStudentWork = (studentWorkList) => {
  // Adds work object representation of the tasks where there is no uploaded work

  const fullStudentWork = _.reduce(
    studentWorkList,
    (result, studentWork) => {
      const { student, work } = studentWork;
      const pages = _.filter(student.score_responses, ({ objective }) =>
        _.isNil(objective.parent)
      );

      const fullWork = _.map(_.orderBy(pages, 'objective.order'), (task) => {
        const existingWork = _.find(work, {
          tracker_score_response_id: task.id
        });

        if (!existingWork) {
          // There is no uploaded work for Task X
          return emptyWork({
            taskId: task.objective.id,
            responseId: task.id,
            objectiveOrder: task.objective.order
          });
        }

        return existingWork;
      });

      // Make sure the work order follows the task one
      return [
        ...result,
        { ...studentWork, work: _.orderBy(fullWork, 'objective_order') }
      ];
    },
    []
  );

  return fullStudentWork;
};

export const removeEmptyStudentWork = (studentWorkList) =>
  _.reduce(
    studentWorkList,
    (result, studentWork) => {
      const uploadedWork = _.filter(studentWork.work, (x) => !x.emptyWork);

      return [...result, { ...studentWork, work: uploadedWork }];
    },
    []
  );

export const filterStudentWorkByTaskIds = (studentWorkList, taskIds) =>
  _.reduce(
    studentWorkList,
    (result, studentWork) => {
      const filteredWork = _.filter(
        studentWork.work,
        ({ tracker_instance_objective_id }) =>
          _.includes(taskIds, tracker_instance_objective_id)
      );

      return [...result, { ...studentWork, work: filteredWork }];
    },
    []
  );

const orderStudentWorkByDate = (studentWorkList) => {
  // _.orderBy returns the null values at the top of the list when the order is descending.
  // We want to apply the ordering only to the non-null objects and return the null ones at the end.

  // _.remove mutates the given array. The removed elements are the return value of the function
  const studentWorkListCopy = _.cloneDeep(studentWorkList);
  const emptyWorkList = _.remove(studentWorkListCopy, ({ student }) =>
    _.isNull(student.last_submission_date)
  );

  const orderedWorkList = _.orderBy(
    studentWorkListCopy,
    ['student.is_teacher_demo_student', 'student.last_submission_date'],
    ['desc', 'desc']
  );

  return [...orderedWorkList, ...emptyWorkList];
};

export const getOrderedStudentWorkList = (studentWorkList, orderingValue) => {
  if (orderingValue === 'student.first_name') {
    return _.orderBy(
      studentWorkList,
      ['student.is_teacher_demo_student', 'student.first_name'],
      ['desc', 'asc']
    );
  }

  if (orderingValue === 'student.last_name') {
    return _.orderBy(
      studentWorkList,
      ['student.is_teacher_demo_student', 'student.last_name'],
      ['desc', 'asc']
    );
  }

  if (orderingValue === 'student.last_submission_date') {
    return orderStudentWorkByDate(studentWorkList);
  }

  return studentWorkList;
};

export const filterDemoStudentsWithEmptyWork = (studentWorkList) =>
  _.filter(studentWorkList, ({ student, work }) => {
    if (student.is_teacher_demo_student) {
      return _.some(work, (x) => !x.emptyWork);
    }
    return true;
  });

export const filterStudentWorkByStudentIds = (studentWorkList, studentIds) => {
  if (_.isEmpty(studentIds)) {
    return studentWorkList;
  }
  return _.filter(studentWorkList, (studentWork) =>
    _.includes(studentIds, studentWork.student.id)
  );
};

export const buildStudentsNamesList = (studentWorkList) =>
  _.orderBy(
    _.map(
      _.filter(
        studentWorkList,
        (studentWork) => !studentWork.student.is_teacher_demo_student
      ),
      (studentWork) => ({
        id: studentWork.student.id,
        name: studentWork.student.name
      })
    ),
    'name'
  );

export const buildSectionsOptions = (sections) =>
  _.map(sections, (section) => {
    const createdAtFormatted = formatDate(section.created_at);

    return {
      value: section.id,
      label: `${section.section_name} ${createdAtFormatted}`
    };
  });

export const addComment = ({ studentId, taskId, data }, { studentWork }) => {
  return addStudentComment(taskId, data).then(({ data: newComment }) => {
    const studentWorkCopy = _.cloneDeep(studentWork);

    // Find the student data in the studentWork array
    const studentIndex = _.findIndex(_.map(studentWorkCopy, 'student'), {
      id: studentId
    });
    const studentData = studentWorkCopy[studentIndex];

    // Find the current task in the student data
    const taskIndex = _.findIndex(studentData.student.score_responses, {
      id: taskId
    });
    const task = studentData.student.score_responses[taskIndex];

    // Append the new comment in the task comments
    task.comments = [...task.comments, newComment];
    // Mutate the arrays using the new objects
    studentData.student.score_responses[taskIndex] = task;
    studentWorkCopy[studentIndex] = studentData;

    return studentWorkCopy;
  });
};

export const addCommentBulk = (
  { text },
  { user, selectedResponses, studentWork }
) => {
  bulkAddStudentComment({
    tracker_score_responses: selectedResponses,
    text
  }).then(({ success, errors }) => {
    if (!success) {
      notifyErrors(errors);
    }
  });

  const newComment = () => ({
    text: text,
    id: randomNumber(),
    user: {
      id: user.id,
      name: user.name,
      is_student: false
    }
  });

  const updatedStudentWork = _.map(studentWork, (studentWork) => {
    studentWork.student.score_responses = _.map(
      studentWork.student.score_responses,
      (resp) => {
        if (_.includes(selectedResponses, resp.id)) {
          resp.comments = [...resp.comments, newComment()];
        }
        return resp;
      }
    );

    return studentWork;
  });

  return updatedStudentWork;
};

const uploadImageBlob = async (blob, activeWork) => {
  const feedbackFileId = await uploadImage({
    blob,
    name: 'feedback'
  });

  const data = await uploadAssignmentFeedback({
    feedback_file_id: feedbackFileId,
    original_work: activeWork.original_id || activeWork.id
  });

  return data;
};

export const addStickersBulk = (
  { stickerUrl },
  {
    selectedResponses,
    studentWork,
    updateWorkItemsInState,
    selectedWorksSelectedChildren
  }
) => {
  const selectedWorks = _.flatten(
    studentWork.map(({ work }) => {
      return work
        .map((workItem) => {
          if (
            _.indexOf(selectedResponses, workItem.tracker_score_response_id) !==
            -1
          ) {
            const activeChildIndex =
              selectedWorksSelectedChildren[workItem.tracker_score_response_id];
            if (
              !_.isEmpty(workItem.children) &&
              activeChildIndex <= workItem.children.length - 1
            ) {
              return workItem.children[activeChildIndex];
            }
            return workItem;
          }
          return undefined;
        })
        .filter((x) => !_.isNil(x));
    })
  ).filter((work) => !_.isEmpty(work) && work.work_url);

  updateWorkItemsInState(selectedWorks.map((x) => ({ ...x, isLoading: true })));

  Promise.all(
    selectedWorks.map((work) => {
      // Work_url can be empty if the selected box is empty
      if (work.work_url) {
        return addStickerToImage(work.work_url, stickerUrl).then((blob) => {
          return uploadImageBlob(blob, work);
        });
      }
      return undefined;
    })
  ).then((newWorkList) => {
    updateWorkItemsInState(newWorkList.filter((x) => !_.isNil(x)));
  });
};

export const updateWorkItems = ({ newWorkList }, { studentWork }) => {
  const newStudentWork = newWorkList.reduce((result, newWork) => {
    return result.map((studentWorkItem) => {
      const work = studentWorkItem.work.map((workItem) => {
        const newStatus = _.get(
          workItem,
          'children[0].status',
          AssignmentStatusOptions.ASSIGNED
        );
        if (workItem.id === newWork.original_id) {
          return {
            ...workItem,
            children: [{ ...newWork, status: newStatus }, ...workItem.children]
          };
        }
        if (_.isNil(newWork.original_id) && newWork.id === workItem.id) {
          return {
            ...workItem,
            children: [{ ...newWork, status: newStatus }, ...workItem.children]
          };
        }
        return workItem;
      });

      return { ...studentWorkItem, work };
    });
  }, _.cloneDeep(studentWork));

  return newStudentWork;
};

export const handleNewStudentWorkWebhookCallback = (
  { newStudentWorkIds },
  { studentWork }
) => {
  const getStudentWorkData = buildFilters({
    'student_work[]': newStudentWorkIds
  });

  return getStudentWorkList(getStudentWorkData).then(
    ({ data: studentWorkData, success }) => {
      if (!success) {
        return;
      }
      const studentWorkCopy = _.cloneDeep(studentWork);

      _.map(studentWorkData, ({ student, work }) => {
        // Find the student data in the studentWork array
        const studentIndex = _.findIndex(_.map(studentWorkCopy, 'student'), {
          id: student.id
        });

        if (studentIndex === -1) {
          return;
        }

        const studentData = studentWorkCopy[studentIndex];

        _.forEach(work, (workItem) => {
          const workOriginal = _.find(studentData.work, {
            tracker_instance_objective_id:
              workItem.tracker_instance_objective_id
          });

          if (_.isNil(workOriginal)) {
            return; // continue loop
          }

          if (workOriginal.emptyWork) {
            const emptyWorkIndex = _.findIndex(studentData.work, {
              id: workOriginal.id
            });

            workItem.children = [];
            studentData.work[emptyWorkIndex] = workItem;
          } else {
            workOriginal.status = workItem.status;
            workOriginal.children = [workItem, ...workOriginal.children];
          }
        });

        studentData.student.last_submission_date = student.last_submission_date;
        studentData.work = _.orderBy(studentData.work, 'objective_order');

        const objectiveNumbers = _.join(
          _.map(work, (sw) => sw.objective_order + 1),
          ', '
        );

        notifyInfo(`${student.name} submitted task ${objectiveNumbers}.`);
      });
      return studentWorkCopy;
    }
  );
};

export const changeTaskStatus = (
  { trackerScoreResponseId, studentId, status },
  { studentWork }
) => {
  return updateTaskStatus({
    trackerScoreResponseId,
    data: { status }
  }).then(({ success, errors }) => {
    if (success) {
      const studentWorkCopy = _.cloneDeep(studentWork);

      // Update status locally instead of refetching the data
      const studentWorkIndex = _.findIndex(_.map(studentWorkCopy, 'student'), {
        id: studentId
      });
      const activeTaskIndex = _.findIndex(
        _.get(studentWorkCopy, `[${studentWorkIndex}].work`),
        { tracker_score_response_id: trackerScoreResponseId }
      );

      const taskData = _.get(
        studentWorkCopy,
        `[${studentWorkIndex}].work[${activeTaskIndex}]`
      );
      if (taskData) {
        const children = _.map(taskData.children, (childWork) => ({
          ...childWork,
          status
        }));
        studentWorkCopy[studentWorkIndex].work[activeTaskIndex] = {
          ...taskData,
          status,
          children
        };

        return studentWorkCopy;
      }
    } else {
      notifyErrors(errors);
    }
  });
};

export const addSingleNewWork = (
  { studentId, activeTaskId, newWork },
  { studentWork }
) => {
  const studentWorkCopy = _.cloneDeep(studentWork);

  // Update status locally instead of refetching the data
  const studentWorkIndex = _.findIndex(_.map(studentWorkCopy, 'student'), {
    id: studentId
  });

  // studentWork[X].work is actually a list of tasks
  const taskDataIndex = _.findIndex(studentWorkCopy[studentWorkIndex].work, {
    id: activeTaskId
  });
  const taskData = studentWorkCopy[studentWorkIndex].work[taskDataIndex];

  const children = [newWork, ...taskData.children];

  studentWorkCopy[studentWorkIndex].work[taskDataIndex] = {
    ...taskData,
    children
  };

  return studentWorkCopy;
};

export const changeTasksStatusesBulk = (
  { status },
  { selectedResponses, selectedSectionId, studentWork }
) => {
  return bulkUpdateTasksStatuses(selectedSectionId, {
    tracker_score_responses: selectedResponses,
    status
  }).then(({ success, errors }) => {
    if (!success) {
      notifyErrors(errors);
      return;
    }

    const newStudentWork = _.map(studentWork, (studentWorkObj) => {
      studentWorkObj.work = _.map(studentWorkObj.work, (work) => {
        if (_.includes(selectedResponses, work.tracker_score_response_id)) {
          work.status = status;
          work.children = _.map(work.children, (childWork) => {
            childWork.status = status;
            return childWork;
          });
        }
        return work;
      });
      return studentWorkObj;
    });

    return newStudentWork;
  });
};

export const changeTasksScoresBulk = (
  { scoreValue },
  { selectedResponses, studentWork, trackerScoreResponses }
) => {
  const updateResponseValue = (resp) => {
    if (_.includes(selectedResponses, resp.id)) {
      resp.value = scoreValue;
      resp.raw_value = scoreValue;
    }
    return resp;
  };

  return bulkUpdateTasksScores({
    tracker_score_responses: selectedResponses,
    raw_value: scoreValue
  }).then(({ success, errors }) => {
    if (!success) {
      notifyErrors(errors);
      return;
    }

    const newStudentWork = _.map(studentWork, (sw) => {
      sw.student.score_responses = _.map(
        sw.student.score_responses,
        updateResponseValue
      );

      return sw;
    });

    const newTrackerScoreResponses = _.map(
      trackerScoreResponses,
      updateResponseValue
    );

    return { newStudentWork, newTrackerScoreResponses };
  });
};

const getFilteredStudentWorkList = (studentWork, filters) => {
  const visibleTaskIds = _.map(filters.tasks, 'id');
  const showAll = filters.show.value === 'all_students';
  const showAllStudentsAllTasks =
    filters.show.value === 'all_students_all_tasks';
  const showAllSubmittedAllTasks =
    filters.show.value === 'all_submitted_all_tasks';

  if ((showAll || showAllStudentsAllTasks) && _.isEmpty(visibleTaskIds)) {
    // Show all tasks for all students. Exclude the demo students with no submitted work
    return filterDemoStudentsWithEmptyWork(studentWork);
  }
  if ((showAll || showAllStudentsAllTasks) && !_.isEmpty(visibleTaskIds)) {
    // Show only the filtered tasks for all students,  Exclude the demo students with no submitted work
    const filteredWork = filterStudentWorkByTaskIds(
      studentWork,
      visibleTaskIds
    );
    return filterDemoStudentsWithEmptyWork(filteredWork);
  }

  const studentUploadedWork = removeEmptyStudentWork(studentWork);

  if ((!showAll || showAllSubmittedAllTasks) && _.isEmpty(visibleTaskIds)) {
    // Show all tasks for all uploaded student work (submissions)
    return studentUploadedWork;
  }
  if ((!showAll || showAllSubmittedAllTasks) && !_.isEmpty(visibleTaskIds)) {
    // Show only the filtered tasks for all uploaded student work (submissions)
    return filterStudentWorkByTaskIds(studentUploadedWork, visibleTaskIds);
  }
};

export const getVisibleStudentWorkList = ({
  studentWork,
  filters,
  ordering
}) => {
  const orderedStudentWork = getOrderedStudentWorkList(
    studentWork,
    ordering.value
  );
  const filteredStudentWork = getFilteredStudentWorkList(
    orderedStudentWork,
    filters
  );

  const visibleStudentIds = _.map(filters.students, 'id');
  return filterStudentWorkByStudentIds(
    _.filter(filteredStudentWork, ({ work }) => !_.isEmpty(work)),
    visibleStudentIds
  );
};

export const toggleStudentWorkItemSelect = ({
  studentWorkList,
  studentId,
  trackerScoreResponseId,
  selected
}) => {
  const studentWorkItemIndex = _.findIndex(
    studentWorkList,
    (x) => x.student.id === studentId
  );
  if (studentWorkItemIndex > -1) {
    studentWorkList[studentWorkItemIndex].selected = selected;

    if (trackerScoreResponseId) {
      const workItemIndex = _.findIndex(
        studentWorkList[studentWorkItemIndex].work,
        (x) => x.tracker_score_response_id === trackerScoreResponseId
      );
      if (workItemIndex > -1) {
        studentWorkList[studentWorkItemIndex].work[workItemIndex].selected =
          selected;
      }
    }
  }

  // TODO: Think how to remove the deep copy??
  return _.cloneDeep(studentWorkList);
};

export const toggleAllStudentWorkSelectValue = ({
  studentWorkList,
  selected
}) => {
  return _.map(studentWorkList, (swItem) => ({
    ...swItem,
    selected,
    work: _.map(swItem.work, (workItem) => ({
      ...workItem,
      selected
    }))
  }));
};

export const toggleAllStudentWorkSelect = ({ studentWorkList, showValue }) => {
  const selectedStudents = _.filter(studentWorkList, 'selected');

  if (_.size(selectedStudents) === _.size(studentWorkList)) {
    if (
      showValue === 'all_students_all_tasks' ||
      showValue === 'all_submitted_all_tasks'
    ) {
      // We want to make sure that all cards are selected in all modes
      const allWorkSelected = _.every(
        _.flatten(_.map(studentWorkList, 'work')),
        'selected'
      );
      return toggleAllStudentWorkSelectValue({
        studentWorkList: studentWorkList,
        selected: !allWorkSelected
      });
    }

    return toggleAllStudentWorkSelectValue({
      studentWorkList: studentWorkList,
      selected: false
    });
  }
  return toggleAllStudentWorkSelectValue({
    studentWorkList: studentWorkList,
    selected: true
  });
};

export const ensureStudentWorkSelect = ({
  studentWorkList,
  newStudentWorkList
}) => {
  return _.map(newStudentWorkList, (swItem) => {
    const currentSwItem = _.find(
      studentWorkList,
      (x) => x.student.id === swItem.student.id
    );
    swItem.selected = _.get(currentSwItem, 'selected', false);

    swItem.work = _.map(swItem.work, (workItem) => {
      const currentWorkItem = _.find(_.get(currentSwItem, 'work', []), {
        id: workItem.id
      });
      workItem.selected = _.get(currentWorkItem, 'selected', false);
      return workItem;
    });
    return swItem;
  });
};

export const setVisibleStudentWorkSelected = (
  swList,
  activeTasksIds,
  showFilter,
  includeEmpty
) => {
  // Removes all work items that are not visible to the user right now
  const studentWorkListWithOnlyActiveWork = _.map(swList, (swItem) => ({
    ...swItem,
    work: _.filter(swItem.work, (x) =>
      _.includes(activeTasksIds, x.tracker_score_response_id)
    )
  }));

  // Removes all empty work items
  const studentWorkListWithOnlyNonEmptyWork = includeEmpty
    ? studentWorkListWithOnlyActiveWork
    : _.filter(
        _.map(studentWorkListWithOnlyActiveWork, (swItem) => ({
          ...swItem,
          work: _.filter(swItem.work, (x) => !x.emptyWork)
        })),
        (sw) => !_.isEmpty(sw.work)
      );

  const selectedStudentWork = toggleAllStudentWorkSelect({
    studentWorkList: studentWorkListWithOnlyNonEmptyWork,
    showValue: showFilter
  });

  const currentStudentWorkIndex = _.reduce(
    swList,
    (result, swItem) => ({ ...result, [swItem.student.id]: swItem.work }),
    {}
  );

  const activeSelectedWorks = _.map(selectedStudentWork, (selectedSwItem) => {
    const currentSwItemWork =
      currentStudentWorkIndex[selectedSwItem.student.id];

    // Brings back the removed student work items from
    // `studentWorkListWithOnlyActiveWork` and `studentWorkListWithOnlyNonEmptyWork`
    const selectedSwItemWork = _.map(currentSwItemWork, (workItem) => {
      if (
        !_.includes(activeTasksIds, workItem.tracker_score_response_id) ||
        (!includeEmpty && workItem.emptyWork)
      ) {
        return workItem;
      }
      return _.find(selectedSwItem.work, {
        tracker_score_response_id: workItem.tracker_score_response_id
      });
    });
    return { ...selectedSwItem, work: selectedSwItemWork };
  });

  const selectedSwGroupedByStudent = _.groupBy(
    activeSelectedWorks,
    (sw) => sw.student.id
  );

  // Brings back empty work
  return _.map(swList, (swItem) => {
    return _.get(
      _.get(selectedSwGroupedByStudent, swItem.student.id),
      [0],
      swItem
    );
  });
};

export const getPercentageLabel = (results, value) => {
  // results is array of Objective entries e.g. key-value pair
  const total = _.sumBy(results, (result) => result[1]);
  const percentage = ((value / total) * 100).toFixed(0);

  return `${percentage}% (${value})`;
};

export const getScoreResponsesForStudents = (scoreResult, groupedStudents) =>
  _.chain(scoreResult.tracker_score_responses)
    .filter((scoreResponse) => _.get(groupedStudents, scoreResponse.student_id))
    .filter((scoreResponse) => scoreResponse.raw_value !== null)
    .value();

export const getScoreResponsesHistogram = (scoreResponses) =>
  _.chain(scoreResponses).groupBy('raw_value').mapValues('length').value();

export const getPresentStudents = (scoreResult, visibleStudentIds = []) => {
  const trackerScoreStudentIndex = _.keyBy(
    scoreResult.tracker_score_students,
    'student_id'
  );

  // If the student is not part from the index => there is no submitted student work
  const presentStudents = _.filter(
    scoreResult.students,
    (student) =>
      !_.get(trackerScoreStudentIndex[student.id], 'is_excused', true)
  );

  return _.isEmpty(visibleStudentIds)
    ? presentStudents
    : _.filter(presentStudents, (student) =>
        _.includes(visibleStudentIds, student.id)
      );
};
