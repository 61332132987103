import React, { useEffect, useState } from 'react';

import { STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL } from 'config/urls';
import _ from 'lodash';

import FiltersDrawer from 'pages/Students/FiltersDrawer';
import ViewSwitcher from 'pages/Students/ViewSwitcher';
import { withNavbar } from 'pages/Students/shared';
import AssignmentsDateView from 'pages/Students/shared/AssignmentsDateView';
import { useMyPortfolioInitialFilters } from 'pages/Students/utils';
import { colors } from 'theme/palette';
import { useBreakpoints } from 'utils/resizing';

import { INITIAL_FILTERS } from './constants';

const MyPortfolioDateView = ({
  history,
  navigation: {
    filterIconClicked,
    setTitle,
    setFilterIconColor,
    setFilterIconClicked,
    setBackButtonAction
  }
}) => {
  const [isDesktop, isTablet, isMobile] = useBreakpoints({
    tablet: 992,
    mobile: 767
  });

  const initialFilters = useMyPortfolioInitialFilters({
    defaultValues: INITIAL_FILTERS
  });

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  useEffect(() => {
    if (isMobile) {
      setBackButtonAction(() =>
        history.push(STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL)
      );
    } else {
      setBackButtonAction(null);
    }
  }, [history, setBackButtonAction, isMobile]);

  useEffect(() => {
    if (isTablet || isDesktop) {
      setTitle('My portfolio');
    } else {
      setTitle('By date');
    }
  }, [setTitle, isTablet, isDesktop]);

  useEffect(() => {
    if (
      _.isEmpty(filters) ||
      _.isEmpty(initialFilters) ||
      filters === initialFilters
    ) {
      setFilterIconColor(colors.white);
    } else {
      setFilterIconColor(colors.pink);
    }
  }, [filters, initialFilters, setFilterIconColor]);

  return (
    <>
      <FiltersDrawer
        filters={filters}
        setFilters={setFilters}
        open={filterIconClicked}
        resetFilters={() => setFilters(initialFilters)}
        onClose={() => setFilterIconClicked(false)}
        showStandardsFilter={false}
      />

      <AssignmentsDateView filters={filters} />
    </>
  );
};

export default withNavbar(MyPortfolioDateView, {
  title: 'My portfolio',
  filterIconVisible: true,
  viewSwitcher: ViewSwitcher
});
