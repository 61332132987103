import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  TEACHERS_ASSIGNMENTS_DETAIL_URL,
  TEACHERS_ASSIGNMENTS_FEEDBACK_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL,
  TEACHERS_ASSIGNMENTS_RESULTS_URL,
  TEACHERS_ASSIGNMENTS_STUDENTS_STATUS_URL
} from 'config/urls';

import Detail from './Detail';
import Feedback from './Feedback';
import List from './List';
import Results from './Results';
import StudentsStatus from './StudentsStatus';

const TeacherAssignments = () => {
  return (
    <Switch>
      <Route exact path={TEACHERS_ASSIGNMENTS_DETAIL_URL} component={Detail} />
      <Route
        exact
        path={TEACHERS_ASSIGNMENTS_FEEDBACK_URL}
        component={Feedback}
      />
      <Route exact path={TEACHERS_ASSIGNMENTS_LIST_URL} component={List} />
      <Route
        exact
        path={TEACHERS_ASSIGNMENTS_RESULTS_URL}
        component={Results}
      />
      <Route
        exact
        path={TEACHERS_ASSIGNMENTS_STUDENTS_STATUS_URL}
        component={StudentsStatus}
      />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default TeacherAssignments;
