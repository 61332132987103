export const MuiSvgIcon = (palette) => ({
  colorPrimary: {
    color: palette.secondary.main
  },
  colorSecondary: {
    color: palette.common.colors.white
  },
  colorAction: {
    color: palette.primary.main
  }
});
