import React from 'react';

import { get, isEmpty, isNil } from 'lodash';

import { getRedirectPath } from 'pages/Login/utils';
import { useIsMobile } from 'utils/resizing';
import { parseParams } from 'utils/urls';

import { useMe } from './hooks';

const AnonymousRequired = (WrappedComponent) => {
  const HOC = (props) => {
    const { data: user, error, isLoading } = useMe();
    const isMobile = useIsMobile();

    const statusCode = get(error, 'status');
    if (statusCode === 401 && !isLoading) {
      return <WrappedComponent {...props} />;
    }

    if (isNil(error) & !isEmpty(user) && !isLoading) {
      const queryParams = parseParams(props.location.search);

      const nextPath = get(queryParams, 'next');

      const redirectPath = getRedirectPath({ user, nextPath, isMobile });

      props.history.push(redirectPath);
    }
  };

  return HOC;
};

export default AnonymousRequired;
