import React from 'react';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import cx from 'classnames';
import { ReactComponent as AddRoundedIcon } from 'images/add-white.svg';

import { SearchField } from 'pages/Teachers/shared';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const AssignAssignment = ({
  selectedAssignment,
  assignmentsNames,
  handleAssignmentSelect,
  handleCreateClick
}) => {
  return (
    <div className={styles.selectAssignmentContainer}>
      <Typography variant="H-TEXT-1" className={styles.title}>
        What Assignment is this?
      </Typography>
      <div className={styles.selectionRowContainer}>
        <SearchField
          value={selectedAssignment}
          className={styles.search}
          options={assignmentsNames || []}
          getOptionLabel={(assignment) => assignment.name}
          onChange={(event, value) => handleAssignmentSelect(value)}
          nooptionstext="No Assignments"
          placeholder="Select Assignment"
          hideSearchIcon
        />
        <Typography variant="B-TEXT-3" className={styles.divider}>
          --- or ---
        </Typography>

        <ButtonGroup
          className={styles.buttonGroup}
          disableElevation
          onClick={handleCreateClick}
        >
          <Button className={styles.addIconButton} noTypography>
            <AddRoundedIcon />
          </Button>
          <Button className={cx(styles.createButton, styles.label)}>
            Create assignment
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default AssignAssignment;
