import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import BaseTypography from 'components/Typography';

export const useStyles = makeStyles({
  'H-TEXT-1': {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 700,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'H-TEXT-2': {
    fontSize: '17px',
    lineHeight: 'initial',
    fontWeight: 600,
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'H-TEXT-3': {
    fontSize: '15px',
    lineHeight: '17.5px',
    fontWeight: 700,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'H-TEXT-4': {
    fontSize: '32px',
    lineHeight: '43.65px',
    fontWeight: 700,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'B-Text-1': {
    fontSize: '17px',
    lineHeight: '20px',
    fontWeight: 400,
    letterSpacing: '0px',
    fontFamily: 'Nunito'
  },
  'B-Text-2': {
    fontSize: '15px',
    lineHeight: 'initial',
    fontWeight: 400,
    letterSpacing: '0px',
    fontFamily: 'Nunito'
  },
  'B-Text-3': {
    fontSize: '13px',
    lineHeight: 'initial',
    fontWeight: 600,
    letterSpacing: '0px',
    fontFamily: 'Nunito'
  },
  'B-Text-4': {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 600,
    letterSpacing: '0.15px',
    fontFamily: 'Nunito'
  },
  'B-Text-5': {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: 'normal',
    fontFamily: 'Nunito'
  },
  'S-TEXT-1': {
    fontSize: '13px',
    lineHeight: 'initial',
    fontWeight: 600,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'S-TEXT-2': {
    fontSize: '12px',
    lineHeight: 'initial',
    fontWeight: 700,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'S-TEXT-3': {
    fontSize: '11px',
    lineHeight: 'initial',
    fontWeight: 600,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'S-TEXT-4': {
    fontSize: '10px',
    lineHeight: 'initial',
    fontWeight: 600,
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'Header-2': {
    fontSize: '17px',
    lineHeight: 'initial',
    fontWeight: 700,
    letterSpacing: 'normal',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  default: {}
});

const Typography = ({
  variant,
  color = colors.greyDarker,
  children,
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <BaseTypography
      className={classnames(className, classes[variant])}
      style={{ color }}
      {..._.omit(props, 'variant')}
    >
      {children}
    </BaseTypography>
  );
};

export default React.memo(Typography);
