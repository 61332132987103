import React from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const HeaderCell = ({ children }) => (
  <TableCell className={styles.headerCell}>
    <Typography variant="H-TEXT-3" color={colors.blue1}>
      {children}
    </Typography>
  </TableCell>
);

export const LoadingListViewRowSkeleton = () => (
  <TableRow>
    <TableCell width="23%">
      <Skeleton variant="text" animation="wave" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" animation="wave" />
    </TableCell>
    <TableCell>
      <Skeleton
        variant="circle"
        width={24}
        height={24}
        style={{ margin: 'auto' }}
      />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" animation="wave" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" animation="wave" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" animation="wave" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" animation="wave" />
    </TableCell>
  </TableRow>
);

const LoadingListViewSkeleton = () => (
  <TableContainer component={Paper} variant="outlined">
    <Table className={styles.table}>
      <TableHead>
        <TableRow>
          <HeaderCell>Name</HeaderCell>
          <HeaderCell>Due</HeaderCell>
          <HeaderCell>Overall Status</HeaderCell>
          <HeaderCell>Tasks Submitted</HeaderCell>
          <HeaderCell>Tasks Complete</HeaderCell>
          <HeaderCell>Tasks Submitted</HeaderCell>
          <HeaderCell>Student Link</HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {_.range(20).map((x) => (
          <LoadingListViewRowSkeleton key={x} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default LoadingListViewSkeleton;
