import React from 'react';
import { useHistory } from 'react-router-dom';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import _ from 'lodash';

import { STUDENT_PORTFOLIO_VIEW_OPTIONS } from 'pages/Students/constants';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Option = ({ option }) =>
  option ? (
    <div className={styles.optionContainer}>
      <option.icon />
      <Typography variant="H-TEXT-3" noWrap>
        {option.label}
      </Typography>
    </div>
  ) : null;

const ViewSwitcher = () => {
  const history = useHistory();

  const value = history.location.pathname;

  return (
    <Select
      disableUnderline
      value={value}
      renderValue={(selectedValue) => (
        <Option
          option={_.find(
            STUDENT_PORTFOLIO_VIEW_OPTIONS,
            (option) => option.value === selectedValue
          )}
        />
      )}
      IconComponent={KeyboardArrowDownIcon}
      onChange={(event) => history.push(event.target.value)}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        getContentAnchorEl: null,
        classes: {
          list: styles.menuList
        }
      }}
      classes={{ icon: styles.arrowDownIcon, root: styles.container }}
    >
      {_.map(STUDENT_PORTFOLIO_VIEW_OPTIONS, (option, index) => (
        <MenuItem
          key={index}
          value={option.value}
          classes={{ root: styles.item, selected: styles.itemSelected }}
        >
          <Option option={option} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default ViewSwitcher;
