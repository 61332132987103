import React, { useCallback, useContext, useEffect, useState } from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import Dialog from 'components/Dialog';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const SchoolSelectContainer = ({ organization, selectSchool }) => (
  <>
    {organization.schools.map((school, index) => (
      <div
        key={`school-${index}`}
        className={styles.selectBox}
        onClick={() => selectSchool(school)}
      >
        {school.name}
      </div>
    ))}
  </>
);

const OrganizationSelectContainer = ({ organizations, selectOrganization }) => (
  <>
    {organizations.map((org, index) => (
      <div
        key={`organization-${index}`}
        className={styles.selectBox}
        onClick={() => selectOrganization(org)}
      >
        {org.name}
      </div>
    ))}
  </>
);

const OrganizationSelectDialog = ({
  organizations,
  selectedOrganization,
  setSelectedOrganization,
  setSelectedSchool,
  selectOnlyOrganization
}) => {
  const { user } = useContext(UserContext);
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const handleBackClick = () => {
    setSelectedOrganization(null);
  };

  const selectSchool = useCallback(
    (school) => {
      setSelectedSchool(school);
      setIsDialogOpen(false);

      trackMixpanelEvent(
        user.id,
        '[PathwaysInsights] Teacher selected School.',
        {
          school: school.name
        }
      );
    },
    [setSelectedSchool, user]
  );

  const selectOrganization = useCallback(
    (organization) => {
      setSelectedOrganization(organization);

      trackMixpanelEvent(
        user.id,
        '[PathwaysInsights] Teacher selected Organization.',
        {
          organization: organization.name
        }
      );
    },
    [setSelectedOrganization, user]
  );

  useEffect(() => {
    if (organizations.length === 1) {
      selectOrganization(organizations[0]);
    }
    if (selectedOrganization && selectedOrganization.schools.length === 1) {
      selectSchool(selectedOrganization.schools[0]);
    }
  }, [selectedOrganization, organizations, selectSchool, selectOrganization]);

  return (
    <Dialog open={isDialogOpen} classes={{ paper: styles.dialog }}>
      <div className={styles.dialogTitle}>
        {selectedOrganization && (
          <div className={styles.arrowIconContainer}>
            <ArrowBackIcon className={styles.arrow} onClick={handleBackClick} />
          </div>
        )}
        <div>
          <Typography
            className={styles.title}
            variant="B-Text-3"
            component="h5"
          >
            PLEASE SELECT WHICH ORGANIZATION OR SCHOOL ACCOUNT YOU WOULD LOGIN
            INTO
          </Typography>
        </div>
      </div>
      <div className={styles.selectContainer}>
        <div className={styles.innterSelectContainer}>
          {!selectedOrganization && (
            <OrganizationSelectContainer
              organizations={organizations}
              selectOrganization={selectOrganization}
            />
          )}
          {selectedOrganization && !selectOnlyOrganization && (
            <SchoolSelectContainer
              organization={selectedOrganization}
              selectSchool={selectSchool}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default OrganizationSelectDialog;
