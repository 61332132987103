import React from 'react';

import _ from 'lodash';

import { TopBarSelect } from 'pages/Teachers/shared';

const renderValue = (section) => _.get(section, 'section_name');
const buildOptions = (sections) =>
  _.map(sections, (section) => ({
    value: section,
    label: section.section_name
  }));

const SectionSelect = ({ sections, selectedSection, setSelectedSection }) => {
  if (!selectedSection) {
    return null;
  }

  return (
    <TopBarSelect
      value={selectedSection}
      renderValue={renderValue}
      onChange={setSelectedSection}
      options={buildOptions(sections)}
    />
  );
};

export default React.memo(SectionSelect);
