import { useEffect, useState } from 'react';

import axios from 'axios';
import { BASE_URL } from 'config/urls';

import { getConfig } from 'utils/sdk';

const apiUrl = `${BASE_URL}/v1/auth/me/`;

// We do not want to use the default handleSdk401 handler. That's why here we're not using useFetch/ get
export const useMe = () => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(apiUrl, { ...getConfig() });
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error.response);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  return { data, error, isLoading };
};
