import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  STUDENTS_ASSIGNMENTS_URL,
  STUDENTS_MY_PORTFOLIO_DATE_VIEW_URL,
  STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL,
  STUDENTS_MY_PORTFOLIO_INCOMPLETE_ASSIGNMENTS_VIEW_URL,
  STUDENTS_MY_PORTFOLIO_STANDARDS_VIEW_URL,
  STUDENTS_NOTIFICATIONS_URL
} from 'config/urls';

import { StudentPageLayout, StudentRequired } from 'pages/Students/shared';

import Assignments from './Assignments';
import MyPortfolioDateView from './MyPortfolioDateView';
import MyPortfolioGroupingChoice from './MyPortfolioGroupingChoice';
import MyPortfolioIncompleteAssignmentsView from './MyPortfolioIncompleteAssignmentsView';
import MyPortfolioStandardsView from './MyPortfolioStandardsView';
import Notifications from './Notifications';
import { URLS_WHOSE_CONTENT_IS_NOT_LIMITED_TO_THE_CENTER_PORTION_OF_THE_SCREEN } from './constants';

const Students = () => {
  const limitContentToCenterPortionOfTheScreen =
    !URLS_WHOSE_CONTENT_IS_NOT_LIMITED_TO_THE_CENTER_PORTION_OF_THE_SCREEN.includes(
      window.location.pathname.replace(/\/$/, '')
    );

  return (
    <StudentPageLayout
      limitContentToCenterPortionOfTheScreen={
        limitContentToCenterPortionOfTheScreen
      }
    >
      <Switch>
        <Route path={STUDENTS_ASSIGNMENTS_URL} component={Assignments} />
        <Route path={STUDENTS_NOTIFICATIONS_URL} component={Notifications} />
        <Route
          exact
          path={STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL}
          component={MyPortfolioGroupingChoice}
        />
        <Route
          exact
          path={STUDENTS_MY_PORTFOLIO_STANDARDS_VIEW_URL}
          component={MyPortfolioStandardsView}
        />
        <Route
          exact
          path={STUDENTS_MY_PORTFOLIO_DATE_VIEW_URL}
          component={MyPortfolioDateView}
        />
        <Route
          exact
          path={STUDENTS_MY_PORTFOLIO_INCOMPLETE_ASSIGNMENTS_VIEW_URL}
          component={MyPortfolioIncompleteAssignmentsView}
        />

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </StudentPageLayout>
  );
};

export default StudentRequired(Students);
