import React from 'react';

import cx from 'classnames';

import { ReactComponent as ELAIcon } from 'pages/Students/MyPortfolioStandardsView/assets/ela.svg';
import { ReactComponent as MATHIcon } from 'pages/Students/MyPortfolioStandardsView/assets/math.svg';
import { colors } from 'theme/palette';
import { STUDENT_PORTFOLIO_STANDARD_TABS } from 'utils/constants';

import styles from './styles.module.scss';

const StandardTabs = ({ activeTab, setActiveTab }) => (
  <div className={styles.tabsWrapper}>
    <div
      onClick={() => setActiveTab(STUDENT_PORTFOLIO_STANDARD_TABS.MATH)}
      className={cx(styles.tab, {
        [styles.activeTab]: activeTab === STUDENT_PORTFOLIO_STANDARD_TABS.MATH
      })}
    >
      <MATHIcon
        fill={
          activeTab === STUDENT_PORTFOLIO_STANDARD_TABS.MATH
            ? colors.white
            : colors.blue5
        }
      />
      MATH
    </div>
    <div
      onClick={() => setActiveTab(STUDENT_PORTFOLIO_STANDARD_TABS.ELA)}
      className={cx(styles.tab, {
        [styles.activeTab]: activeTab === STUDENT_PORTFOLIO_STANDARD_TABS.ELA
      })}
    >
      <ELAIcon
        fill={
          activeTab === STUDENT_PORTFOLIO_STANDARD_TABS.ELA
            ? colors.white
            : colors.blue5
        }
      />
      ELA
    </div>
    <div
      onClick={() => setActiveTab(STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS)}
      className={cx(styles.tab, {
        [styles.activeTab]:
          activeTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS
      })}
    >
      NO STANDARDS
    </div>
  </div>
);

export default StandardTabs;
