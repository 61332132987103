import React from 'react';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const DiagramTooltip = ({ active, payload, studentName }) => {
  if (!active || !payload || payload.length === 0) return null;

  const data = payload[0].payload;

  return (
    <div className={styles.tooltipContainer}>
      <Typography>Concepts mastered:</Typography>
      <Typography>
        {studentName}: {data.student_concepts_mastered}/{data.total_concepts}
      </Typography>
      <Typography>
        Class average: {data.class_average_concepts_mastered}/
        {data.total_concepts}
      </Typography>
    </div>
  );
};

export default DiagramTooltip;
