import React, { memo, useCallback, useEffect } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SvgIcon from '@material-ui/core/SvgIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as AddCommentIcon } from 'images/add-comment.svg';
import { ReactComponent as DefaultAddCommentIcon } from 'images/default-add-comment.svg';
import { ReactComponent as HideCommentsIcon } from 'images/hide-comments.svg';
import _ from 'lodash';
import { addAssignmentComment } from 'sdk';

import { AssignmentStatusIcon } from 'pages/Students/shared/AssignmentStatus';
import { colors } from 'theme/palette';
import { useDialog, useWorkCard } from 'utils/hooks';

import Comments from './components/Comments';
import StudentWorkDialog from './components/StudentWorkDialog';
import AssignmentTaskValueTypeIcon from 'components/AssignmentTaskValueTypeIcon';
import PaginatedStudentWorkTasksButtons from 'components/PaginatedStudentWorkTasksButtons';
import StudentWorkTasksButtons from 'components/StudentWorkTasksButtons';
import Typography from 'components/Typography';
import WorkCardImage from 'components/WorkCardImage';

import styles from './styles.module.scss';

const Placeholder = ({ count = 1 }) =>
  _.range(count).map((index) => (
    <div className={styles.container} key={index}>
      <Skeleton height={200} variant="rect" />

      <div className={styles.actionRow}>
        <Grid container alignItems="center">
          <Grid item>
            <Skeleton width={83} height={24} />
          </Grid>
        </Grid>

        <div />

        <div className={styles.taskButtons} />
      </div>

      <Typography variant="B-Text-1" className={styles.name}>
        <Skeleton width={200} />
      </Typography>

      <div className={styles.taskInfoContainer}>
        <Grid container className={styles.spaceBetween}>
          <Grid item>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              <Skeleton width={150} />
            </Typography>
          </Grid>
        </Grid>
        {_.range(3).map((index) => (
          <div key={index} className={styles.criteria}>
            <Typography variant="B-Text-3" color={colors.grey2}>
              <Skeleton width={50} />
            </Typography>
            <Typography
              variant="S-TEXT-2"
              color={colors.blue4}
              className={styles.criteriaStatus}
            >
              <Skeleton width={30} />
            </Typography>
          </div>
        ))}
      </div>
    </div>
  ));

const TeacherWorkCard = ({
  assignment: initialAssignmentData,
  student,
  postHeightChangeAction = () => {}
}) => {
  const {
    isOpened: isStudentWorkDialogOpened,
    openDialog: openStudentWorkDialog,
    closeDialog: closeStudentWorkDialog
  } = useDialog();

  const {
    tasksButtonsElements,
    hasComments,
    isEmptyWork,
    assignmentTasks,
    activeTaskWork,
    activeTask,
    activeWork,
    activeTaskIndex,
    setActiveTaskIndex,
    activeWorkIndex,
    setActiveWorkIndex,
    paginatedTasksStart,
    handleRightArrowClick,
    handleLeftArrowClick,
    showActiveWorksDropdown,
    showPaginatedTaskButtons,
    showAssignmentTasksButtons,
    showComments,
    setShowComments,
    assignment,
    setAssignment
  } = useWorkCard({ assignment: initialAssignmentData });

  const handleCommentAdd = useCallback(
    ({ value }) => {
      if (value === '') {
        return;
      }

      addAssignmentComment({
        studentId: student.id,
        assignmentId: activeTask.id,
        data: { text: value }
      }).then(({ data }) => {
        setAssignment((prevData) =>
          _.update(
            _.clone(prevData),
            `tasks.${activeTaskIndex}.comments`,
            (oldValue) => [...oldValue, data]
          )
        );
      });
    },
    [student.id, activeTask.id, activeTaskIndex, setAssignment]
  );

  useEffect(() => {
    postHeightChangeAction();
  }, [showComments, activeTaskIndex, activeWorkIndex, postHeightChangeAction]);

  return (
    <>
      <div className={styles.container}>
        <WorkCardImage
          isEmptyWork={isEmptyWork}
          onClick={openStudentWorkDialog}
          activeWork={activeWork}
          activeWorkIndex={activeWorkIndex}
          activeTaskIndex={activeTaskIndex}
          assignmentTasks={assignmentTasks}
          activeTaskWork={activeTaskWork}
          onLeftArrowClick={handleLeftArrowClick}
          onTopArrowClick={() =>
            setActiveWorkIndex((prevIndex) => prevIndex - 1)
          }
          onBottomArrowClick={() =>
            setActiveWorkIndex((prevIndex) => prevIndex + 1)
          }
          onRightArrowClick={handleRightArrowClick}
        />
        <div className={styles.actionRow}>
          <Grid container alignItems="center" className={styles.icons}>
            <Grid item>
              <AssignmentStatusIcon status={assignment.overall_status} />
            </Grid>

            {showComments ? (
              <Grid item>
                <SvgIcon
                  component={HideCommentsIcon}
                  className={styles.messageIcon}
                  onClick={() => setShowComments(false)}
                />
              </Grid>
            ) : (
              <Grid item>
                <SvgIcon
                  component={
                    hasComments ? AddCommentIcon : DefaultAddCommentIcon
                  }
                  className={styles.messageIcon}
                  onClick={() => setShowComments(true)}
                />
              </Grid>
            )}
          </Grid>

          <div className={styles.taskButtons}>
            {showPaginatedTaskButtons && (
              <PaginatedStudentWorkTasksButtons
                elements={tasksButtonsElements}
                onChange={(element) => {
                  setActiveTaskIndex(element.value);
                  setActiveWorkIndex(0);
                }}
                onLeftArrowClick={handleLeftArrowClick}
                onRightArrowClick={handleRightArrowClick}
                firstElementIndex={paginatedTasksStart}
                elementsCount={assignmentTasks.length}
              />
            )}
            {showAssignmentTasksButtons && (
              <StudentWorkTasksButtons
                elements={tasksButtonsElements}
                onChange={(element) => {
                  setActiveTaskIndex(element.value);
                  setActiveWorkIndex(0);
                }}
              />
            )}
          </div>

          <div className={styles.selectWork}>
            {showActiveWorksDropdown && (
              <FormControl>
                <Select
                  className={styles.selectField}
                  classes={{ root: styles.selectFieldRoot }}
                  value={activeWorkIndex}
                  onChange={(e, element) => {
                    setActiveWorkIndex(element.props.value);
                  }}
                >
                  {activeTask.work.map((workItem, index) => {
                    if (workItem.is_feedback) {
                      return (
                        <MenuItem key={index} value={index}>
                          Feedback
                        </MenuItem>
                      );
                    }

                    if (workItem.is_revision) {
                      return (
                        <MenuItem key={index} value={index}>
                          Revision
                        </MenuItem>
                      );
                    }

                    return (
                      <MenuItem key={index} value={index}>
                        Original
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </div>
        </div>
        <Typography
          variant="B-Text-1"
          className={styles.name}
          onClick={openStudentWorkDialog}
        >
          {assignment.name}
        </Typography>
        <div className={styles.taskInfoContainer}>
          <Grid container className={styles.spaceBetween}>
            <Grid item>
              <Typography variant="S-TEXT-1" color={colors.grey1}>
                {activeTask.task_index + 1}. {activeTask.name}
              </Typography>
            </Grid>

            {!_.isNull(activeTask.value) && (
              <Grid item>
                <div className={styles.taskScore}>
                  <AssignmentTaskValueTypeIcon
                    task={activeTask}
                    color={colors.blue4}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          {_.get(activeTask, 'criteria', []).map((criteria, index) => (
            <div key={index} className={styles.criteria}>
              <Typography variant="B-Text-3" color={colors.grey2}>
                {criteria.name}
              </Typography>
              <Typography
                variant="S-TEXT-2"
                color={colors.blue4}
                className={styles.criteriaStatus}
              >
                {criteria.value ? 'Yes' : 'No'}
              </Typography>
            </div>
          ))}
        </div>
        {showComments && (
          <Comments
            comments={activeTask.comments}
            onSubmit={({ value }) =>
              handleCommentAdd({
                value
              })
            }
          />
        )}
      </div>
      {isStudentWorkDialogOpened && (
        <StudentWorkDialog
          initialTaskIndex={activeTaskIndex}
          initialWorkIndex={activeWorkIndex}
          studentWork={{
            tasks: assignment.tasks,
            student
          }}
          assignmentId={assignment.id}
          trackerId={assignment.tracker_id}
          onClose={closeStudentWorkDialog}
        />
      )}
    </>
  );
};

const MemoizedTeacherWorkCard = memo(
  TeacherWorkCard,
  (prevProps, nextProps) => prevProps.assignment.id === nextProps.assignment.id
);

MemoizedTeacherWorkCard.Placeholder = Placeholder;

export default MemoizedTeacherWorkCard;
