import React, { useContext, useEffect, useState } from 'react';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const TabbedChartSelection = ({ tabs, onChange }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabNames = tabs.map((tab) => tab.name);

  const { user } = useContext(UserContext);

  useEffect(() => onChange(selectedTab), [selectedTab, onChange]);

  const onTabClick = ({ event, index }) => {
    event.currentTarget.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    });
    setSelectedTab(index);

    const tab = tabs[index];

    trackMixpanelEvent(
      user.id,
      `[PathwaysInsights] User clicked ${tab.name} tab`
    );
  };

  return (
    <div className={styles.tabbedChartSelectionContainer}>
      {tabNames.map((tabName, index) => (
        <Typography
          key={`tab-${index}`}
          onClick={(event) => onTabClick({ event, index })}
          variant="S-TEXT-2"
          className={selectedTab === index ? styles.tabActive : styles.tab}
        >
          {tabName}
        </Typography>
      ))}
    </div>
  );
};

export default TabbedChartSelection;
