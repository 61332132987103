import React, { useEffect } from 'react';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { STUDENTS_MY_PORTFOLIO_STANDARDS_VIEW_URL } from 'config/urls';

import { STUDENT_PORTFOLIO_VIEW_OPTIONS } from 'pages/Students/constants';
import { withNavbar } from 'pages/Students/shared';
import { useBreakpoints } from 'utils/resizing';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const MyPortfolioGroupingChoice = ({ history }) => {
  const [isDesktop, isTablet] = useBreakpoints({
    tablet: 992,
    mobile: 768
  });

  useEffect(() => {
    if (isDesktop || isTablet) {
      history.push(STUDENTS_MY_PORTFOLIO_STANDARDS_VIEW_URL);
    }
  }, [isDesktop, isTablet, history]);

  return STUDENT_PORTFOLIO_VIEW_OPTIONS.map((option) => (
    <div
      className={styles.item}
      onClick={() => history.push(option.value)}
      key={option.value}
    >
      <option.icon />
      <Typography variant="H-TEXT-2">{option.label}</Typography>
      <KeyboardArrowRightIcon size="small" className={styles.arrowRight} />
    </div>
  ));
};

export default withNavbar(MyPortfolioGroupingChoice, {
  title: 'My portfolio'
});
