import React, { forwardRef, memo, useCallback } from 'react';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import classnames from 'classnames';

import { colors } from 'theme/palette';
import { useMenu } from 'utils/hooks';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const useIconButtonStyles = makeStyles({
  base: {
    width: '40px'
  },
  blue: {
    backgroundColor: colors.blue3,
    '&:hover': {
      backgroundColor: colors.blue2
    }
  },
  active: {
    backgroundColor: colors.blue2,
    '& > span > svg': {
      transform: 'rotate(180deg)'
    }
  }
});

const useButtonStyles = makeStyles({
  blue: {
    backgroundColor: colors.blue4,
    '&:hover': {
      backgroundColor: colors.blue3
    }
  },
  active: {
    backgroundColor: colors.blue3
  }
});

const useButtonGroupStyles = makeStyles({
  root: {
    '& > button': {
      minWidth: 'auto'
    }
  }
});

const DropdownButton = (
  { children, className, color, title, disabled, ...props },
  ref
) => {
  const iconButtonClasses = useIconButtonStyles();
  const buttonClasses = useButtonStyles();
  const groupClasses = useButtonGroupStyles();

  const [anchorEl, openMenu, closeMenu] = useMenu();

  const isActive = Boolean(anchorEl);

  const onButtonGroupClick = useCallback(
    (event) => {
      if (!disabled) {
        openMenu(event);
      }
    },
    [openMenu, disabled]
  );

  return (
    <>
      <ButtonGroup
        disableElevation
        ref={ref}
        onClick={onButtonGroupClick}
        disabled={disabled}
        className={classnames(groupClasses.root, className)}
        {...props}
      >
        <Button
          className={classnames(buttonClasses[color], {
            [buttonClasses.active]: isActive
          })}
          color={color}
          variant="base"
          {...props.ButtonProps}
        >
          {title}
        </Button>
        <Button
          noTypography
          className={classnames(
            iconButtonClasses.base,
            iconButtonClasses[color],
            {
              [iconButtonClasses.active]: isActive
            }
          )}
          color={color}
          variant="base"
          {...props.IconButtonProps}
        >
          <ArrowDownIcon htmlColor="inherit" />
        </Button>
      </ButtonGroup>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
        open={isActive}
        autoFocus={false}
        classes={{ list: styles.menuList, paper: styles.menuPaper }}
        onClose={closeMenu}
        onClick={closeMenu}
      >
        {children}
      </Menu>
    </>
  );
};

// We need to add `!important` to the colors as Material UI has bug with the `disableRipple` prop.
const useMenuItemStyles = makeStyles({
  base: {
    height: '40px'
  },
  blue: {
    backgroundColor: `${colors.blue3} !important`,
    '&:hover': {
      backgroundColor: `${colors.blue2} !important`
    }
  }
});

export const DropdownButtonItem = (
  { onClick, children, noTypography, color, className, ...props },
  ref
) => {
  const menuItemStyles = useMenuItemStyles();

  return (
    <MenuItem
      ref={ref}
      disableRipple
      disableTouchRipple
      onClick={onClick}
      className={classnames(
        menuItemStyles.base,
        menuItemStyles[color],
        className
      )}
      {...props}
    >
      {!noTypography && (
        <Typography variant="B-Text-2" color={colors.white}>
          {children}
        </Typography>
      )}
      {noTypography && children}
    </MenuItem>
  );
};

const DropdownButtonMemo = memo(forwardRef(DropdownButton));
DropdownButtonMemo.Item = memo(forwardRef(DropdownButtonItem));

export default DropdownButtonMemo;
