import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import _ from 'lodash';

import { useNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { useDialog, useInfiniteScroll, usePagination } from 'utils/hooks';
import { useMixpanelPageOpenTrack } from 'utils/integrations/mixpanel';

import AddButton from 'components/AddButton';
import CreateTeacherTeamDialog from 'components/CreateTeacherTeamDialog';
import ScheduleMeetingDialog from 'components/ScheduleMeetingDialog';
import Typography from 'components/Typography';

import {
  Filters,
  TeamCard,
  TeamsListSkeleton,
  UpcomingMeetingsTable
} from './components';
import {
  useFilters,
  useTeams,
  useTeamsCopy,
  useUpcomingMeetings
} from './hooks';
import styles from './styles.module.scss';

const DEFAULT_LIMIT = 5;

const InfiniteScrollContainer = ({ children, length, fetchNext, hasNext }) => (
  <InfiniteScroll
    dataLength={length}
    next={fetchNext}
    hasMore={hasNext}
    loader={<TeamsListSkeleton cardsCount={3} />}
  >
    {children}
  </InfiniteScroll>
);

const MeetingsList = ({ history }) => {
  useNavbar({
    title: 'Meetings',
    sticky: true
  });

  useMixpanelPageOpenTrack('Teacher opened Meetings Page');

  const [filters, setFilters] = useFilters(history);

  const [{ data: teamsResponse, loading: loadingTeams }, loadNextPage] =
    useInfiniteScroll(useTeams, filters);

  const [teams, setTeams] = useTeamsCopy(teamsResponse, loadingTeams);

  const {
    isOpened: isCreateTeacherTeamDialogOpened,
    openDialog: openCreateTeacherTeamDialog,
    closeDialog: closeCreateTeacherTeamDialog
  } = useDialog();

  const {
    isOpened: isScheduleMeetingDialogOpened,
    openDialog: openScheduleMeetingDialog,
    closeDialog: closeScheduleMeetingDialog
  } = useDialog();

  const [
    { data: upcomingMeetingsData, loading: upcomingMeetingsLoading },
    currentMeetingsPage,
    setMeetingsPage,
    meetingsPageCount
  ] = usePagination(useUpcomingMeetings, DEFAULT_LIMIT);

  const updateNewMeetingTeam = useCallback(
    (teamId, newMeetingsCount = 1) =>
      setTeams((prevTeams) => {
        const teamsCopy = _.cloneDeep(prevTeams);
        const teamIndex = _.findIndex(teamsCopy, { id: teamId });

        if (teamIndex !== -1) {
          teamsCopy[teamIndex].meetings_count += newMeetingsCount;
        }

        return teamsCopy;
      }),
    [setTeams]
  );

  const onMeetingScheduleSuccess = useCallback(
    (newMeetingData) => {
      closeCreateTeacherTeamDialog();
      // We set the page to -1 in order to trigger table data refetch. This is properly handled in `usePagination`.
      setMeetingsPage(-1);
      updateNewMeetingTeam(
        _.head(newMeetingData).team.id,
        newMeetingData.length
      );
    },
    [closeCreateTeacherTeamDialog, setMeetingsPage, updateNewMeetingTeam]
  );

  const onTeamCreate = useCallback(
    (data) =>
      setTeams((prevTeams) => [{ ...data, meetings_count: 0 }, ...prevTeams]),
    [setTeams]
  );

  const onTeamUpdate = useCallback(
    (newTeam) => {
      setTeams((prevTeams) => {
        const teamsCopy = _.cloneDeep(prevTeams);
        const teamIndex = _.findIndex(prevTeams, { id: newTeam.id });

        if (teamIndex === -1) {
          return prevTeams;
        }

        const team = teamsCopy[teamIndex];

        if (team.name !== newTeam.name) {
          team.name = newTeam.name;

          // We cannot update the table data here, since it is wrapped by `usePagination` and we cannot modify its data directly.
          // That's why we set the page to -1 in order to trigger table data refetch. This is properly handled in `usePagination`.
          setMeetingsPage(-1);
        }

        if (!_.isEqual(team.teacher_members, newTeam.teacher_members)) {
          team.teacher_members = newTeam.teacher_members;
        }

        teamsCopy[teamIndex] = team;

        return teamsCopy;
      });
    },
    [setMeetingsPage, setTeams]
  );
  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerLimiter}>
          <div className={styles.labelContainer}>
            <Typography
              display="inline"
              variant="S-TEXT-1"
              color={colors.grey3}
            >
              My Meetings
            </Typography>
            <AddButton
              className={styles.addButton}
              onClick={openScheduleMeetingDialog}
              color="blue"
              noTypography
            >
              <Typography variant="S-TEXT-1">MEETING</Typography>
            </AddButton>
          </div>
          <UpcomingMeetingsTable
            meetings={upcomingMeetingsData.results}
            loading={upcomingMeetingsLoading}
            currentPage={currentMeetingsPage}
            setPage={setMeetingsPage}
            pageCount={meetingsPageCount}
          />
        </div>

        <div className={styles.headerLimiter}>
          <div
            className={classnames(
              styles.labelContainer,
              styles.teamsLabelContainer
            )}
          >
            <Typography
              display="inline"
              variant="S-TEXT-1"
              color={colors.grey3}
            >
              Teacher Teams
            </Typography>
            <AddButton
              className={styles.addButton}
              onClick={openCreateTeacherTeamDialog}
              color="blue"
              noTypography
            >
              <Typography variant="S-TEXT-1">TEAM</Typography>
            </AddButton>
          </div>
        </div>

        <Filters filters={filters} setFilters={setFilters} history={history} />

        {loadingTeams && _.isEmpty(teams) && (
          <TeamsListSkeleton cardsCount={6} />
        )}
        {!loadingTeams && _.isEmpty(teams) && (
          <Paper className={styles.noResultsContainer}>
            <Typography variant="H-TEXT-1" align="center" color={colors.blue3}>
              Use Meetings to Create Teacher Teams
              <br />
              and Share Student Work with Your Colleagues
            </Typography>
          </Paper>
        )}
        {teams.length > 0 && (
          <InfiniteScrollContainer
            length={teams.length}
            fetchNext={loadNextPage}
            hasNext={teamsResponse.next}
          >
            <div className={styles.teamsContainer}>
              {_.map(teams, (team) => (
                <TeamCard
                  key={team.id}
                  team={team}
                  onTeamUpdate={onTeamUpdate}
                />
              ))}
            </div>
          </InfiniteScrollContainer>
        )}

        {isCreateTeacherTeamDialogOpened && (
          <CreateTeacherTeamDialog
            onClose={closeCreateTeacherTeamDialog}
            onSuccess={onTeamCreate}
          />
        )}
        {isScheduleMeetingDialogOpened && (
          <ScheduleMeetingDialog
            onClose={closeScheduleMeetingDialog}
            onSuccess={onMeetingScheduleSuccess}
            onTeamCreate={onTeamCreate}
          />
        )}
      </div>
    </>
  );
};

export default MeetingsList;
