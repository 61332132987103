import { toast } from 'react-toastify';

import _ from 'lodash';

const DEFAULT_ERROR_MESSAGE = 'Error occurred.';

export const getErrorMessage = (error) => {
  if (_.isString(error)) {
    return error;
  }

  return _.get(error, 'message', DEFAULT_ERROR_MESSAGE);
};

export const notifyInfo = (message, options) => toast.info(message, options);

export const notifyWarning = (message, options) =>
  toast.warning(message, options);

export const notifySuccess = (message, options) =>
  toast.success(message, options);

export const notifyError = (error, options) => {
  toast.error(getErrorMessage(error), options);
};

export const notifyErrors = (errors, options) => {
  if (_.isEmpty(errors)) {
    notifyError(DEFAULT_ERROR_MESSAGE);
    return;
  }

  _.forEach(errors, (e) => notifyError(e, options));
};

export const dismissNotification = (toastId) => toast.dismiss(toastId);
