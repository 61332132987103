import { AssignmentTaskValueTypes } from 'utils/constants';

export const getObjectiveValue = ({ responses, objective }) => {
  if (objective.value_type === AssignmentTaskValueTypes.CHECKBOX) {
    return responses[objective.id].value ? 'Yes' : 'No';
  }

  if (objective.value_type === AssignmentTaskValueTypes.TEXT) {
    return responses[objective.id].raw_value;
  }

  return responses[objective.id].value;
};
