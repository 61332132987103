import React from 'react';

import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

const LoadingPlaceholder = () => (
  <Box padding={2}>
    <Box display="flex">
      <Skeleton variant="rect" width={50} height={50} />
      <Box paddingLeft={1} width="100%">
        <Skeleton width="70%" />
        <Skeleton width="40%" />
      </Box>
    </Box>
    <Box marginTop={4}>
      <Skeleton variant="rect" width="100%" height={300} />
      <Skeleton />
      <Skeleton />
    </Box>
  </Box>
);

export default LoadingPlaceholder;
