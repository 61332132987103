import _ from 'lodash';

export const getPaymentMethodRepresentation = (customer) =>
  `${customer.card_brand} **** **** **** ${customer.card_last4}`;

export const SubscriptionStatus = {
  ACTIVE: 'active',
  UNPAID: 'unpaid',
  INCOMPLETE: 'incomplete',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled'
};

export const SubscriptionPaymentStatus = {
  REQUIRES_ACTION: 'requires_action',
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method'
};

export const subscriptionState = ({
  currentSubscription,
  lastSubscription,
  nextBillingDate,
  freeSubscriptionTemplate,
  paidSubscriptionTemplate
}) => {
  const currentSubscriptionIsFree = currentSubscription.template.is_free;
  const currentSubscriptionIsActive =
    currentSubscription.status === SubscriptionStatus.ACTIVE;
  const currentSubscriptionIsScheduledForCanceling = !_.isNull(
    currentSubscription.canceled_at
  );
  const currentSubscriptionAmount =
    currentSubscription.amount.amount.toFixed(1);
  const currentSubscriptionCurrency = currentSubscription.amount.currency;

  const lastSubscriptionStatus = _.get(lastSubscription, 'status');
  const lastSubscriptionIsFree = _.get(lastSubscription, 'template.is_free');
  const lastSubscriptionPaymentStatus = _.get(
    lastSubscription,
    'payment_status'
  );
  const lastSubscriptionPaymentError = _.get(
    lastSubscription,
    'payment_intent_error'
  );

  const freeSubscriptionTemplateName = _.get(
    freeSubscriptionTemplate,
    'display_name'
  );
  const paidSubscriptionTemplateName = _.get(
    paidSubscriptionTemplate,
    'display_name'
  );

  if (currentSubscriptionIsFree && currentSubscriptionIsActive) {
    if (!lastSubscriptionIsFree) {
      if (lastSubscriptionStatus === SubscriptionStatus.UNPAID) {
        return {
          nextPaymentText: `Upgrading to ${paidSubscriptionTemplateName}`,
          freeButtonState: {
            text: 'Current plan',
            disabled: true
          },
          paidButtonState: {
            text: `Upgrading to ${paidSubscriptionTemplateName}`,
            disabled: true
          }
        };
      } else if (
        lastSubscriptionStatus === SubscriptionStatus.INCOMPLETE &&
        lastSubscriptionPaymentStatus ===
          SubscriptionPaymentStatus.REQUIRES_PAYMENT_METHOD
      ) {
        return {
          nextPaymentText: [
            'Payment error.',
            `${lastSubscriptionPaymentError}`
          ],
          // nextPaymentText: `Payment error: ${lastSubscriptionPaymentError}`,
          freeButtonState: {
            text: 'Current plan',
            disabled: true
          },
          paidButtonState: {
            text: `Payment error while upgrading to ${paidSubscriptionTemplateName}`,
            disabled: true
          }
        };
      } else if (
        lastSubscriptionStatus === SubscriptionStatus.INCOMPLETE &&
        lastSubscriptionPaymentStatus ===
          SubscriptionPaymentStatus.REQUIRES_ACTION
      ) {
        return {
          nextPaymentText: `Upgrading to ${paidSubscriptionTemplateName} (Card confirmation required)`,
          freeButtonState: {
            text: 'Current plan',
            disabled: true
          },
          paidButtonState: {
            text: `Upgrading to ${paidSubscriptionTemplateName} (Card confirmation required)`,
            disabled: true
          }
        };
      }
    }

    return {
      nextPaymentText: 'None',
      freeButtonState: {
        text: 'Current plan',
        disabled: true
      },
      paidButtonState: {
        text: `Choose ${paidSubscriptionTemplateName}`,
        disabled: false
      }
    };
  } else if (!currentSubscriptionIsFree && currentSubscriptionIsActive) {
    if (currentSubscriptionIsScheduledForCanceling) {
      return {
        nextPaymentText: [
          `Downgrading to ${freeSubscriptionTemplateName}`,
          `On ${nextBillingDate}`
        ],
        // nextPaymentText: `Downgrading to ${freeSubscriptionTemplateName} on ${nextBillingDate}`,
        freeButtonState: {
          text: `Downgrading to ${freeSubscriptionTemplateName}`,
          disabled: true
        },
        paidButtonState: {
          text: `Current plan`,
          disabled: true
        }
      };
    }

    return {
      nextPaymentText: [
        `$${currentSubscriptionAmount} ${currentSubscriptionCurrency}`,
        `On ${nextBillingDate}`
      ],
      // nextPaymentText: `$${currentSubscriptionAmount} ${currentSubscriptionCurrency} on ${nextBillingDate}`,
      freeButtonState: {
        text: `Downgrade to ${freeSubscriptionTemplateName}`,
        disabled: false
      },
      paidButtonState: {
        text: `Current plan`,
        disabled: true
      }
    };
  }
};
