import React from 'react';

import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { copyToClipboard } from 'utils';

import { colors } from 'theme/palette';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

export const StudentLinkCopyButton = ({
  deeplink,
  children = 'Student link'
}) => (
  <Button
    noTypography
    variant="link"
    startIcon={<FileCopyRoundedIcon htmlColor={colors.blue4} />}
    onClick={() => copyToClipboard(deeplink)}
  >
    <Typography variant="S-TEXT-1" className={styles.text} color={colors.blue4}>
      {children}
    </Typography>
  </Button>
);
