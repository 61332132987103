export const bootIntercomWidget = (options) => {
  window.Intercom &&
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      ...options
    });
};

export const showIntercomWidget = () => {
  window.Intercom && window.Intercom.booted && window.Intercom('show');
};

export const updateIntercomEntity = (data) => {
  window.Intercom && window.Intercom.booted && window.Intercom('update', data);
};

export const shutdownIntercomWidget = () => {
  window.Intercom && window.Intercom.booted && window.Intercom('shutdown');
};
