import * as yup from 'yup';

export const INITIAL_VALUES = {
  preferredUserName: '',
  schoolId: null
};

export const VALIDATION_SCHEMA = yup.object().shape({
  preferredUserName: yup.string().trim().required('Name is required.'),
  schoolId: yup.number().nullable()
});
