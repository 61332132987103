import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import _ from 'lodash';

import { VIEW_MODES } from 'pages/Teachers/StudentPortfolio/constants';

import Typography from 'components/Typography';

import { ReactComponent as ArrowDownIcon } from './assets/arrow-down.svg';
import { ReactComponent as BellIcon } from './assets/bell.svg';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import { ReactComponent as StandardsIcon } from './assets/standards.svg';
import styles from './styles.module.scss';

const options = [
  {
    label: 'STANDARD VIEW',
    value: VIEW_MODES.STANDARDS,
    icon: StandardsIcon
  },
  {
    label: 'DATE VIEW',
    value: VIEW_MODES.DATE,
    icon: CalendarIcon
  },
  {
    label: 'INCOMPLETE ASSIGNMENTS',
    value: VIEW_MODES.INCOMPLETE_ASSIGNMENTS,
    icon: BellIcon
  }
];

const defaultMenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  getContentAnchorEl: null,
  classes: {
    paper: styles.menuPaper,
    list: styles.menuList
  }
};

const Option = ({ option }) => (
  <div className={styles.optionContainer}>
    <option.icon />
    <Typography variant="H-TEXT-3" noWrap>
      {option.label}
    </Typography>
  </div>
);

const ViewToggle = ({ viewMode, setViewMode }) => {
  return (
    <Select
      disableUnderline
      value={viewMode || ''}
      renderValue={(selectedValue) => (
        <Option
          option={_.find(options, (option) => option.value === selectedValue)}
        />
      )}
      IconComponent={ArrowDownIcon}
      onChange={(event) => setViewMode(event.target.value)}
      MenuProps={defaultMenuProps}
      classes={{ icon: styles.arrowDownIcon }}
    >
      {_.map(options, (option, index) => (
        <MenuItem
          key={index}
          value={option.value}
          classes={{ root: styles.item, selected: styles.itemSelected }}
        >
          <Option option={option} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default ViewToggle;
