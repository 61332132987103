import React from 'react';

import Button from 'components/Button';
import Dialog from 'components/Dialog';

import styles from './styles.module.scss';

const ConfirmDialog = ({ onClose, onConfirm, children, ...props }) => (
  <Dialog
    maxWidth="xs"
    open
    onClose={onClose}
    classes={{ paper: styles.dialog }}
    {...props}
  >
    {children}
    <div className={styles.actionButtons}>
      <Button color="lightGrey" onClick={onClose}>
        Cancel
      </Button>
      <Button color="pink" onClick={onConfirm}>
        Confirm
      </Button>
    </div>
  </Dialog>
);

export default ConfirmDialog;
