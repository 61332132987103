import React from 'react';

import { ImgixDimensions } from 'utils/constants';
import { setImgixParams } from 'utils/files';

import Image from 'components/Image';

const ImgixImage = ({ src, dimensions, ...props }) => {
  return (
    <Image
      src={setImgixParams(src, dimensions || ImgixDimensions.MEDIUM)}
      {...props}
    />
  );
};

export default React.memo(ImgixImage);
