import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import classnames from 'classnames';
import { TEACHERS_MEETING_FEED_URL } from 'config/urls';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { formatDate } from 'utils/moment';
import { reverse } from 'utils/urls';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const UpcomingMeetingsTableSkeleton = ({ count }) => (
  <>
    {_.times(count, (c) => (
      <div
        className={classnames(
          styles.row,
          styles.contentRow,
          styles.skeletonRow
        )}
        key={c}
      >
        {_.times(3, (num) => (
          <Skeleton key={num} variant="text" animation="wave" />
        ))}
      </div>
    ))}
  </>
);

const UpcomingMeetingsTable = ({
  meetings,
  loading,
  currentPage,
  setPage,
  pageCount
}) => {
  const history = useHistory();

  const goToMeetingFeed = useCallback(
    (meeting) =>
      history.push({
        pathname: reverse(TEACHERS_MEETING_FEED_URL, {
          invitationIdentifier: meeting.invitation_identifier
        })
      }),
    [history]
  );

  return (
    <div className={styles.container}>
      <div className={classnames(styles.row, styles.headerRow)}>
        <Typography variant="S-TEXT-1" color={colors.blue1}>
          Date/Time
        </Typography>
        <Typography variant="S-TEXT-1" color={colors.blue1}>
          Meeting Topic
        </Typography>
        <Typography variant="S-TEXT-1" color={colors.blue1}>
          Team
        </Typography>
        <Typography variant="S-TEXT-1" color={colors.blue1}>
          Work shared by
        </Typography>
      </div>

      {loading && <UpcomingMeetingsTableSkeleton count={5} />}

      {!loading && _.isEmpty(meetings) && (
        <div className={styles.noMeetings}>
          <Typography variant="B-Text-2" color={colors.grey3}>
            No upcoming meetings
          </Typography>
        </div>
      )}

      {!loading &&
        !_.isEmpty(meetings) &&
        _.map(meetings, (meeting) => {
          const scheduleDate = formatDate(
            meeting.scheduled_for,
            'ddd MM/DD h:mm A'
          );
          const sharedByNames = _.join(meeting.work_shared_by, ', ');

          return (
            <div
              key={meeting.id}
              className={classnames(styles.row, styles.contentRow)}
              onClick={() => goToMeetingFeed(meeting)}
            >
              <Typography variant="B-Text-2" color={colors.grey2}>
                {scheduleDate}
              </Typography>
              <Typography variant="B-Text-2" color={colors.grey2}>
                {meeting.topic}
              </Typography>
              <Typography variant="B-Text-2" color={colors.grey1}>
                {meeting.team.name}
              </Typography>

              <Typography variant="B-Text-2" color={colors.grey1}>
                {sharedByNames}
              </Typography>
            </div>
          );
        })}

      {pageCount > 1 && (
        <div className={classnames(styles.row, styles.paginationRow)}>
          <Pagination
            count={pageCount}
            page={currentPage + 1}
            onChange={(_, page) => setPage(page - 1)}
            size="small"
            color="secondary"
          />
        </div>
      )}
    </div>
  );
};

export default UpcomingMeetingsTable;
