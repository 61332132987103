import React from 'react';

const CheckCircleIcon = ({
  showOutline = false,
  foregroundColor = '#ffffff',
  backgroundColor = '#438642',
  outlineColor = '#77d376',
  width = 52,
  height = 52
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Check circle" clipPath="url(#clip0_18730_7755)">
        <path
          id="Vector"
          d="M26 4.33398C14.04 4.33398 4.33331 14.0407 4.33331 26.0006C4.33331 37.9606 14.04 47.6673 26 47.6673C37.96 47.6673 47.6666 37.9606 47.6666 26.0006C47.6666 14.0407 37.96 4.33398 26 4.33398Z"
          fill={backgroundColor}
        />
        <g id="Ellipse 79">
          <mask id="path-2-inside-1_18730_7755" fill="white">
            <path d="M26 4.33398C31.7463 4.33398 37.2573 6.61672 41.3206 10.68C45.3839 14.7433 47.6666 20.2543 47.6666 26.0006C47.6666 31.747 45.3839 37.258 41.3206 41.3213C37.2573 45.3846 31.7463 47.6673 26 47.6673L26 44.2333C30.8356 44.2333 35.4731 42.3124 38.8924 38.8931C42.3117 35.4738 44.2327 30.8363 44.2327 26.0006C44.2327 21.165 42.3117 16.5275 38.8924 13.1082C35.4731 9.6889 30.8356 7.76796 26 7.76796V4.33398Z" />
          </mask>
          {showOutline && (
            <path
              id="circle-outline"
              d="M26 4.33398C31.7463 4.33398 37.2573 6.61672 41.3206 10.68C45.3839 14.7433 47.6666 20.2543 47.6666 26.0006C47.6666 31.747 45.3839 37.258 41.3206 41.3213C37.2573 45.3846 31.7463 47.6673 26 47.6673L26 44.2333C30.8356 44.2333 35.4731 42.3124 38.8924 38.8931C42.3117 35.4738 44.2327 30.8363 44.2327 26.0006C44.2327 21.165 42.3117 16.5275 38.8924 13.1082C35.4731 9.6889 30.8356 7.76796 26 7.76796V4.33398Z"
              fill="#ECEBFE"
              stroke={outlineColor}
              strokeWidth="102.414"
              mask="url(#path-2-inside-1_18730_7755)"
            />
          )}
        </g>
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3975 27.2892L21.0091 33.9008C21.7273 34.619 22.906 34.619 23.6058 33.9008L37.5841 19.9225C38.3023 19.2043 38.3023 18.044 37.5841 17.3258C36.8658 16.6075 35.7056 16.6075 34.9873 17.3258L22.3167 29.9965L16.9943 24.6925C16.276 23.9742 15.1158 23.9742 14.3975 24.6925C13.6793 25.4107 13.6793 26.571 14.3975 27.2892Z"
          fill={foregroundColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_18730_7755">
          <rect width="52" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckCircleIcon;
