import React, { useCallback, useEffect } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import cx from 'classnames';
import _ from 'lodash';

import Stepper from 'pages/Teachers/BulkUpload/components/Stepper';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Layout = ({
  className,
  currentStepIndex,
  children,
  isNextButtonDisabled,
  nextButtonAction,
  backButtonAction,
  nextButtonTooltip,
  showLoader = false,
  showDoneButton = false
}) => {
  useEffect(() => {
    const overflowDisableCSS = 'overflow: hidden';
    // Disable page scroll when you land in a page with this Layout.
    document.body.style.cssText += overflowDisableCSS;

    return () => (document.body.style.cssText -= overflowDisableCSS);
  }, []);

  const getBackButton = useCallback(() => {
    if (_.isNil(backButtonAction)) {
      return <div className={styles.nextButton} />;
    }

    const backButton = (
      <Button
        className={cx(styles.nextButton, styles.backButton)}
        onClick={backButtonAction}
        disableElevation
        startIcon={<ChevronRightRoundedIcon />}
      >
        Back
      </Button>
    );

    return backButton;
  }, [backButtonAction]);

  const getNextButton = useCallback(() => {
    if (_.isNil(nextButtonAction)) {
      return <div className={styles.nextButton} />;
    }

    const nextButton = (
      <Button
        color="pink"
        className={styles.nextButton}
        disabled={isNextButtonDisabled}
        onClick={nextButtonAction}
        disableElevation
        endIcon={<ChevronRightRoundedIcon />}
      >
        Next
      </Button>
    );

    if (_.isEmpty(nextButtonTooltip)) {
      return nextButton;
    }

    return (
      <Tooltip
        arrow
        title={<Typography variant="B-Text-2">{nextButtonTooltip}</Typography>}
      >
        <span>{nextButton}</span>
      </Tooltip>
    );
  }, [isNextButtonDisabled, nextButtonTooltip, nextButtonAction]);

  const getDoneButton = useCallback(() => {
    if (_.isNil(nextButtonAction)) {
      return <div className={styles.nextButton} />;
    }

    const doneButton = (
      <Button
        color="pink"
        className={styles.nextButton}
        disabled={isNextButtonDisabled}
        onClick={nextButtonAction}
        disableElevation
        endIcon={<CheckIcon />}
      >
        Done
      </Button>
    );

    if (_.isEmpty(nextButtonTooltip)) {
      return doneButton;
    }

    return (
      <Tooltip
        arrow
        title={<Typography variant="B-Text-2">{nextButtonTooltip}</Typography>}
      >
        <span>{doneButton}</span>
      </Tooltip>
    );
  }, [isNextButtonDisabled, nextButtonTooltip, nextButtonAction]);

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.header}>
        {getBackButton()}

        <Stepper
          className={styles.stepper}
          currentStepIndex={currentStepIndex}
        />

        {showDoneButton ? getDoneButton() : getNextButton()}
      </div>
      {children}
      {showLoader && <div className={styles.loadingOverlay} />}
    </div>
  );
};

export default Layout;
