import { useCallback, useEffect, useState } from 'react';

export const useMeetingStandards = (initialStandards) => {
  const [standards, setStandards] = useState(initialStandards);

  const addStandard = useCallback(
    (newStandard) => setStandards((prev) => [...prev, newStandard]),
    []
  );

  const removeStandard = useCallback(
    (standardId) =>
      setStandards((prev) => prev.filter((x) => x.id !== standardId)),
    []
  );

  useEffect(() => setStandards(initialStandards), [initialStandards]);

  return [standards, addStandard, removeStandard];
};
