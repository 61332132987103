import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { notifyErrors } from 'utils/notifications';
import { useFetch } from 'utils/sdk';

import { addExample as addExampleSdk, getStandard } from './sdk';

export const useStandard = (standardId) => {
  const [standard, setStandard] = useState({});

  const { data } = useFetch(getStandard, { data: {} }, standardId);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setStandard(data);
    }
  }, [data]);

  const addExample = useCallback(
    (values) => {
      addExampleSdk(standardId, values).then(({ success, data, errors }) => {
        if (success) {
          setStandard((prev) => ({
            ...prev,
            example_problems: [...prev.example_problems, data]
          }));
        } else {
          notifyErrors(errors);
        }
      });
    },
    [standardId]
  );

  return [standard, addExample];
};

export const useStandardsStack = (initialStadardId) => {
  const [stack, setStack] = useState([initialStadardId]);

  const push = useCallback(
    (standardId) => setStack((prev) => [...prev, standardId]),
    []
  );
  const pop = useCallback(() => setStack((prev) => _.dropRight(prev)), []);

  return [stack, push, pop];
};
