import React from 'react';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import cx from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StandardTab = ({ onClick, icon: Icon, children }) => (
  <div
    onClick={onClick}
    className={cx(styles.item, { [styles.withIcon]: !_.isNil(Icon) })}
  >
    {Icon && <Icon fill={colors.blue2} />}
    <Typography variant="H-TEXT-2">{children}</Typography>

    <KeyboardArrowRightIcon size="small" className={styles.arrowRight} />
  </div>
);

export default StandardTab;
