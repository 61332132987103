import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';
import { useStudentStrengthsData } from 'sdk';

import Strengths from './components/Stengths';
import WhereToGrow from './components/WhereToGrow';

import styles from './styles.module.scss';

const StrengthsAndWhereToGrow = ({
  studentId,
  selectedYear,
  selectedMonthsBack
}) => {
  const { data: strengthsData, isLoading } = useStudentStrengthsData({
    studentId,
    params: {
      year: get(selectedYear, 'value'),
      months_back:
        get(selectedMonthsBack, 'value') === 0
          ? null
          : get(selectedMonthsBack, 'value')
    }
  });

  return (
    <Paper className={styles.strengthSectionContainer} variant="outlined">
      {isLoading && <CircularProgress size={60} />}
      {!isLoading && (
        <>
          <Strengths
            studentMasteredCount={strengthsData.student_concepts_mastered}
            classMasteredAverageCount={
              strengthsData.class_average_concepts_mastered
            }
            studentMasteredWorks={strengthsData.student_concepts_mastered_works}
          />
          <WhereToGrow
            studentAssignmentsAssignedCount={
              strengthsData.student_assignments_assigned
            }
            classAssignmentsAssignedAverageCount={
              strengthsData.class_average_assignments_assigned
            }
            studentConceptsToReviewCount={
              strengthsData.student_concepts_to_review
            }
            classConceptsToReviewAverageCount={
              strengthsData.class_average_concepts_to_review
            }
            studentConceptsToReviewWorks={
              strengthsData.student_concepts_to_review_works
            }
          />
        </>
      )}
    </Paper>
  );
};

export default StrengthsAndWhereToGrow;
