import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import AddButton from 'components/AddButton';

import styles from './styles.module.scss';

const ColumnSkeleton = ({ height }) => (
  <div className={styles.columnSkeleton}>
    <Skeleton variant="rect" width={350} height={40} />
    <Skeleton variant="rect" width={350} height={height} animation="pulse" />
  </div>
);

const COLUMN_HEIGHTS = [700, 500, 600];

const MeetingSortSkeleton = () => (
  <div className={styles.container}>
    {_.map(_.shuffle(COLUMN_HEIGHTS), (height, index) => (
      <ColumnSkeleton key={index} height={height} />
    ))}
    <AddButton color="blue" disabled>
      Add Column
    </AddButton>
  </div>
);

export default React.memo(MeetingSortSkeleton);
