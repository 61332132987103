import React from 'react';

import { ReactComponent as FileUploadIcon } from 'images/file_upload.svg';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const AttachmentUploadOverlay = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <FileUploadIcon className={styles.icon} />

      <Typography variant="S-TEXT-1" color={colors.blue2}>
        Drag & Drop <br /> to upload
      </Typography>
    </div>
  </div>
);

export default AttachmentUploadOverlay;
