import { fabric } from 'fabric';

export const addStickerToImage = (imageSrc, iconSrc) => {
  return new Promise((resolve) => {
    const canvas = new fabric.Canvas();
    //https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
    fabric.Image.fromURL(
      `${imageSrc}?dont-cache=true`,
      (image) => {
        image.set({ left: 0, top: 0 });
        canvas.setWidth(image.width);
        canvas.setHeight(image.height);
        canvas.add(image);
        fabric.loadSVGFromURL(iconSrc, (objects, options) => {
          const icon = fabric.util.groupSVGElements(objects, options);
          const scale = 6;
          icon.set({
            top: image.height / 2 - (icon.height / 2) * scale,
            left: image.width / 2 - (icon.width / 2) * scale,
            originX: 'left',
            originY: 'top',
            scaleX: scale,
            scaleY: scale
          });
          canvas.add(icon);
          canvas.renderAll();

          canvas.getElement().toBlob(resolve);
        });
      },
      {
        crossOrigin: 'Anonymous'
      }
    );
  });
};
