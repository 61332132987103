import React from 'react';

import _ from 'lodash';
import { useBulkUploadDetail, useBulkUploadSubmissionsCount } from 'sdk';

import SectionLine from 'pages/Teachers/BulkUpload/SectionLine';
import Layout from 'pages/Teachers/BulkUpload/components/Layout';
import { useBulkUploadStatusRedirect } from 'pages/Teachers/BulkUpload/hooks';
import { useNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import confettiImageSource from './assets/success.gif';
import styles from './styles.module.scss';

const Success = ({ match }) => {
  const bulkUploadId = _.get(match, 'params.bulkUploadId');

  useNavbar({ title: 'CAPTURE WORK' });

  const { data: bulkUpload, isLoading: isFetchingBulkUpload } =
    useBulkUploadDetail({ bulkUploadId });

  useBulkUploadStatusRedirect({
    bulkUpload,
    isLoading: isFetchingBulkUpload
  });

  const { data, isLoading } = useBulkUploadSubmissionsCount({ bulkUploadId });

  return (
    <Layout currentStepIndex={3} className={styles.container}>
      <img
        src={`${confettiImageSource}?rand=${Math.random()}`}
        className={styles.confetti}
        alt="confetti"
      />
      <Typography
        variant="H-TEXT-1"
        color={colors.pink}
        align="center"
        className={styles.title}
      >
        Done!
      </Typography>

      <Typography variant="H-TEXT-1" color={colors.blue1} align="center">
        Go to results
      </Typography>

      <div className={styles.sectionContainer}>
        {isLoading || _.isEmpty(data) ? (
          <SectionLine.Placeholder count={5} />
        ) : (
          _.map(
            data,
            (
              {
                name,
                submission_count: submissionCount,
                tracker_id: trackerId,
                has_math_standards: hasMathStandards,
                is_multipage: isMultiPage
              },
              sectionId
            ) => (
              <SectionLine
                name={name}
                key={trackerId}
                trackerId={trackerId}
                sectionId={sectionId}
                submissionCount={submissionCount}
                hasMathStandards={hasMathStandards}
                isMultiPage={isMultiPage}
              />
            )
          )
        )}
      </div>
    </Layout>
  );
};

export default Success;
