import { BASE_URL } from 'config/urls';

import { get, post, requestSdk } from 'utils/sdk';

export const assignmentDetail = async (studentId, assignmentId) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v1/students/${studentId}/assignments/${assignmentId}/`)
  );

export const generateMagicLoginToken = async (data) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/auth/magic-login/generate/`, data)
  );
