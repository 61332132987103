import React from 'react';

import DoughnutChart from 'pages/Pathways/components/Insights/components/DoughnutChart';
import PieChartDivider from 'pages/Teachers/StrategiesOverview/components/PieChartDivider';
import { colors } from 'theme/palette';

import styles from './styles.module.scss';

const palette = [colors.pink5, colors.pink6, colors.pink7, colors.pink8];

const StrategyDoughnutChart = ({ selectedStrategy, onRubricSelected }) => {
  const data = selectedStrategy.answers.map((answer, index) => ({
    name: answer.label,
    value: answer.value,
    index: index,
    fill: palette[index]
  }));

  return (
    <>
      <div>
        <DoughnutChart
          data={data}
          onChange={(index) => onRubricSelected(data[index].name)}
        />
      </div>
      <div>
        <p className={styles.studentsText}>
          {selectedStrategy.student_count} STUDENTS
        </p>
        <PieChartDivider color={palette[0]} />
      </div>
    </>
  );
};

export default StrategyDoughnutChart;
