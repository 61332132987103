import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router';

import MomentUtils from '@date-io/moment';
import { Box, Container, Grid, Menu, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { LOGIN_URL } from 'config/urls';
import { isEmpty } from 'lodash';
import { logout, useFamilyStudentList } from 'sdk';

import familyLogo from 'pages/Family/assets/family-logo.svg';
import BottomNavBar from 'pages/Family/components/BottomNavBar';
import ManageStudents from 'pages/Family/components/Profile/components/ManageStudents';
import UpdateProfile from 'pages/Family/components/Profile/components/UpdateProfile';
import StudentSelectDropdown from 'pages/Family/components/StudentSelectDropdown';

import { useStudent } from '../FamilyContext';
import FamilyMemberRequired from '../FamilyMemberRequired';
import styles from './styles.module.scss';
import { firstTimeLogin, useCurrentPageName } from './utils';

const promptLogout = () => {
  const confirmed = window.confirm('Log out of EdLight?');
  return new Promise((resolve) => {
    if (confirmed) {
      resolve(logout());
    }
  });
};

const FamilyPageLayout = ({
  children,
  history,
  user,
  limitContentToCenterPortionOfTheScreen = true
}) => {
  const logoutHandler = () =>
    promptLogout().then(() => history.push(LOGIN_URL));
  const { selectedStudent, setSelectedStudent } = useStudent();
  const { data: students, isLoading: isLoadingStudents } =
    useFamilyStudentList();
  const [selectedPage, setSelectedPage] = useState(useCurrentPageName(history));
  const [anchorEl, setAnchorEl] = useState(null);
  const [showManageStudents, setShowManageStudents] = useState(false);
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);

  const confirmedStudents = useMemo(() => {
    return students ? students.filter((student) => student.confirmed) : [];
  }, [students]);

  const handleSubmit = () => {
    window.location.reload();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManageStudents = () => {
    setShowManageStudents(true);
    handleClose();
  };

  const handleUpdateProfile = () => {
    setShowUpdateProfile(true);
    handleClose();
  };

  if (
    !isEmpty(students) &&
    !isLoadingStudents &&
    firstTimeLogin({ students })
  ) {
    return (
      <ManageStudents
        students={students}
        firstTimeLogin={true}
        onSubmit={handleSubmit}
      />
    );
  }

  if (showManageStudents) {
    return (
      <ManageStudents
        students={students}
        firstTimeLogin={false}
        onSubmit={handleSubmit}
      />
    );
  }

  if (showUpdateProfile) {
    return (
      <UpdateProfile
        students={confirmedStudents}
        firstTimeLogin={false}
        onSubmit={handleSubmit}
      />
    );
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={styles.pageWrapper}>
        <div className={styles.topNavigation}>
          <Grid container justifyContent="space-around" spacing={2}>
            <IconButton
              aria-label="Account"
              size="medium"
              className="profile"
              onClick={handleClick}
            >
              <AccountCircleIcon color="primary" fontSize="large" />
            </IconButton>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleUpdateProfile}>Update Profile</MenuItem>
              <MenuItem onClick={handleManageStudents}>
                Manage Students
              </MenuItem>
            </Menu>
            <Box>
              <img className={styles.familyLogo} src={familyLogo} alt="Logo" />
            </Box>
            {!isEmpty(students) && !isLoadingStudents && (
              <StudentSelectDropdown
                students={confirmedStudents}
                selectedStudent={selectedStudent}
                setSelectedStudent={setSelectedStudent}
              />
            )}
          </Grid>
        </div>
        <Container maxWidth="md" className={styles.content}>
          {children}
        </Container>
        <BottomNavBar
          logout={logoutHandler}
          history={history}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default FamilyMemberRequired(withRouter(FamilyPageLayout));
