import { BASE_URL } from 'config/urls';

import { get, post, requestSdk } from 'utils/sdk';

export const assignmentDetail = async (trackerId) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v2/trackers/assignments/${trackerId}/detail/`)
  );

export const updateAssignmentOpenDate = async (assignmentId, openDate) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/assignments/${assignmentId}/update-open-date/`,
      { open_at: openDate }
    )
  );

export const updateAssignmentDueDate = async (assignmentId, dueDate) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/assignments/${assignmentId}/update-due-date/`,
      { due_at: dueDate }
    )
  );

export const softDeleteAssignment = async (assignmentId) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/trackers/assignments/${assignmentId}/soft-delete/`)
  );

export const bulkSoftDeleteAssignments = async (assignmentsIds) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/trackers/assignments/bulk-soft-delete/`, {
      assignments: assignmentsIds
    })
  );

export const restoreAssignment = async (assignmentId) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/trackers/assignments/${assignmentId}/restore/`)
  );

export const canPreviewAsStudent = async (trackerInstanceId) => {
  const url = `${BASE_URL}/v1/trackers/assignments/${trackerInstanceId}/can-preview-as-student/`;
  return await requestSdk(() => get(url));
};

export const addTeacherToSections = async (sectionIds) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/sections/add-teacher/`, { sections: sectionIds })
  );
