import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import SchoolIcon from '@material-ui/icons/School';
import classnames from 'classnames';

import { colors } from 'theme/palette';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StandardButton = ({ label, onClick, blue, className, disabled }) => (
  <Button
    disabled={disabled}
    color="lightGrey"
    tabIndex="-1"
    className={classnames(styles.container, className, {
      [styles.selected]: blue,
      [styles.disabled]: disabled
    })}
    onClick={onClick}
  >
    <Typography
      variant="B-Text-3"
      color={colors.blue3}
      className={styles.content}
    >
      <SvgIcon
        component={SchoolIcon}
        color="primary"
        className={styles.buttonIcon}
      />
      {label}
    </Typography>
  </Button>
);

export default StandardButton;
