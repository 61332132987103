import React from 'react';

import ResultExplanationItem from './components/ResultExplanationItem';

import styles from './styles.module.scss';

const ResultExplanationList = ({ results }) => {
  return (
    <div className={styles.resultExplanationList}>
      <ResultExplanationItem
        results={results}
        title="Teacher Knowledge & Skill"
        description="Teachers in this category often show a strong commitment to improving their teaching methods and classroom strategies, with an emphasis on differentiated tasks, advanced questioning techniques, and effective formative data analysis."
      />
      <ResultExplanationItem
        results={results}
        title="Student Engagement"
        description="Teachers in this category demonstrate a strong focus on student collaboration, culturally responsive teaching, and independent problem-solving skills. They prioritize creating an environment where students are actively involved and invested in their learning process."
      />
      <ResultExplanationItem
        results={results}
        title="Content Rigor & Relevance"
        description="Teachers in this category are looking to deepen their understanding of math concepts, curriculum scope, and effective diagnostic skills in order to guide their students to a deep conceptual understanding."
      />
    </div>
  );
};

export default ResultExplanationList;
