import React from 'react';

import DebouncedSearchFilter from 'components/DebouncedSearchFilter';

import styles from './styles.module.scss';

const Filters = ({ filters, setFilters }) => (
  <div className={styles.filtersWrapper}>
    <DebouncedSearchFilter
      freeSolo
      autoComplete
      placeholder="Search Teams"
      options={[]}
      value={filters.search || ''}
      onChange={(newValue) =>
        setFilters((prev) => ({ ...prev, search: newValue }))
      }
    />
  </div>
);

export default Filters;
