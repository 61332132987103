import React from 'react';
import Linkify from 'react-linkify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { Comments } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { openInNewTab } from 'utils/urls';

import StandardItem from 'components/StandardItem';
import Typography from 'components/Typography';

import { ReactComponent as FileIcon } from './assets/file.svg';
import styles from './styles.module.scss';

const AssignmentPaper = ({
  assignment,
  assignmentTasksCount,
  headerComponent,
  comments,
  addComment,
  className
}) => (
  <div className={className}>
    <div className={styles.headerContainer}>
      <Paper variant="outlined" className={styles.assignmentHeader}>
        <div className={styles.assignmentInfo}>
          {headerComponent}
          <div className={styles.flexRowContainer}>
            <Typography variant="S-TEXT-1" color={colors.grey2}>
              {assignmentTasksCount} Pages
            </Typography>
            {!assignment.is_tutoring_session && (
              <Typography variant="B-Text-3" color={colors.grey2}>
                Due:{' '}
                {moment(_.get(assignment, 'due_at')).format(
                  'ddd MM/D [at] h:mm A'
                )}
              </Typography>
            )}
          </div>
          <Typography
            variant="B-Text-2"
            color={colors.blue1}
            className={styles.assignmentDescription}
          >
            <Linkify properties={{ target: '_blank' }}>
              {_.get(assignment, 'description')}
            </Linkify>
          </Typography>
          {!_.isEmpty(_.get(assignment, 'attachments', [])) && (
            <>
              {_.map(assignment.attachments, (attachment) => (
                <div
                  className={classnames(styles.attachment, 'pointer')}
                  key={attachment.id}
                  onClick={() => openInNewTab(attachment.file_url)}
                >
                  <FileIcon fill={colors.blue3} />
                  <Typography
                    variant="B-Text-3"
                    color={colors.blue3}
                    className={styles.attachmentName}
                  >
                    {attachment.original_filename}
                  </Typography>
                </div>
              ))}
            </>
          )}
          <Grid container className={styles.standardsList} spacing={1}>
            {assignment.standards.map((standard) => (
              <Grid item key={standard.id}>
                <StandardItem standard={standard} />
              </Grid>
            ))}
          </Grid>
        </div>
      </Paper>
      <div className={styles.commentsContainer}>
        <Comments
          className={styles.comments}
          comments={comments}
          onSubmit={addComment}
        />
      </div>
    </div>
  </div>
);

export default AssignmentPaper;
