import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import { useAssignmenTasks, useBulkUploadGroups } from 'sdk';

import BulkUploadImageUpdate from 'pages/Teachers/BulkUpload/StudentsConfirmation/components/BulkUploadImageUpdate';
import MatchedBulkUploadGroup from 'pages/Teachers/BulkUpload/StudentsConfirmation/components/MatchedBulkUploadGroup';
import UnmatchedBulkUploadGroup from 'pages/Teachers/BulkUpload/StudentsConfirmation/components/UnmatchedBulkUploadGroup';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Placeholder = ({ count = 1, boxCount = 10 }) =>
  _.range(count).map((index) => (
    <>
      <Typography variant="H-TEXT-2" className={styles.title}>
        <Skeleton width={250} />
      </Typography>

      {_.range(boxCount).map((boxIndex) => (
        <Skeleton
          height={243}
          width={250}
          key={boxIndex}
          variant="rect"
          className={styles.placeholderBox}
        />
      ))}
    </>
  ));

const GroupsList = ({
  bulkUploadId,
  assignmentId,
  setHasUnmatchedImages,
  handleNextClick,
  shouldPoll
}) => {
  const [selectedBulkUploadImage, setSelectedBulkUploadImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const { data: assignmentTasks } = useAssignmenTasks({ assignmentId });

  const {
    data: matchedBulkUploadImageGroups,
    refetch: refetchMatchedImageGroups
  } = useBulkUploadGroups({
    params: { matched: true },
    bulkUploadId
  });
  const {
    data: unmatchedBulkUploadImageGroups,
    isLoading: isLoadingUnmatchedBulkUploadImageGroups,
    refetch: refetchUnmatchedImageGroups
  } = useBulkUploadGroups({
    params: { matched: false },
    bulkUploadId
  });

  const tasksIds = useMemo(
    () => assignmentTasks?.map((task) => task.id),
    [assignmentTasks]
  );

  useEffect(() => {
    if (shouldPoll) {
      const interval = setInterval(() => {
        refetchMatchedImageGroups();
        refetchUnmatchedImageGroups();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [shouldPoll, refetchMatchedImageGroups, refetchUnmatchedImageGroups]);

  useEffect(() => {
    if (_.isNil(unmatchedBulkUploadImageGroups)) {
      setHasUnmatchedImages(true);
    } else {
      setHasUnmatchedImages(unmatchedBulkUploadImageGroups?.length > 0);
    }
  }, [unmatchedBulkUploadImageGroups, setHasUnmatchedImages]);

  const handleBulkUploadImageModifyDialogClose = useCallback(() => {
    setSelectedBulkUploadImage(null);
    refetchMatchedImageGroups();
    refetchUnmatchedImageGroups();
  }, [refetchMatchedImageGroups, refetchUnmatchedImageGroups]);

  const handleMatchSuccess = useCallback(() => {
    const unmatchedGroups = unmatchedBulkUploadImageGroups.filter(
      (group) => group.id !== selectedGroup?.id
    );

    if (unmatchedGroups.length > 0) {
      setSelectedBulkUploadImage(unmatchedGroups[0].bulk_upload_images[0]);
      setSelectedGroup(unmatchedGroups[0]);
    }
    refetchMatchedImageGroups();
    refetchUnmatchedImageGroups();
  }, [
    refetchUnmatchedImageGroups,
    refetchMatchedImageGroups,
    selectedGroup?.id,
    unmatchedBulkUploadImageGroups
  ]);

  const handleNextButtonClick = () => {
    if (shouldPoll) {
      return;
    }

    setSelectedBulkUploadImage(null);
    setSelectedImageIndex(null);
    setSelectedGroup(null);
    handleNextClick();
  };

  const preparingTaskIds = !_.isNil(tasksIds);

  return (
    <div className={styles.container}>
      {unmatchedBulkUploadImageGroups?.length === 0 &&
        matchedBulkUploadImageGroups?.length === 0 && <Placeholder count={2} />}

      {preparingTaskIds && unmatchedBulkUploadImageGroups?.length > 0 && (
        <>
          <Typography variant="H-TEXT-2" className={styles.title}>
            {unmatchedBulkUploadImageGroups.length} students not matched
          </Typography>

          {unmatchedBulkUploadImageGroups.map((item) => (
            <UnmatchedBulkUploadGroup
              key={item.id}
              disabled={shouldPoll}
              bulkUploadGroup={item}
              onClick={(image, imageIndex) => {
                setSelectedBulkUploadImage(image);
                setSelectedImageIndex(imageIndex);
                setSelectedGroup(item);
              }}
              showSkeleton={shouldPoll}
              tasksIds={tasksIds}
            />
          ))}
        </>
      )}

      {!_.isNil(tasksIds) && matchedBulkUploadImageGroups?.length > 0 && (
        <>
          <Typography variant="H-TEXT-2" className={styles.title}>
            {matchedBulkUploadImageGroups.length}{' '}
            {matchedBulkUploadImageGroups.length === 1 ? 'student' : 'students'}{' '}
            matched
          </Typography>

          {matchedBulkUploadImageGroups.map((item) => (
            <MatchedBulkUploadGroup
              key={item.id}
              disabled={shouldPoll}
              bulkUploadGroup={item}
              onClick={(image, imageIndex) => {
                setSelectedBulkUploadImage(image);
                setSelectedImageIndex(imageIndex);
                setSelectedGroup(item);
              }}
              showSkeleton={shouldPoll}
              tasksIds={tasksIds}
            />
          ))}
        </>
      )}

      {!_.isNil(selectedBulkUploadImage) && (
        <BulkUploadImageUpdate
          loadingUnmatchedGroups={isLoadingUnmatchedBulkUploadImageGroups}
          selectedImageIndex={selectedImageIndex}
          onDialogClose={handleBulkUploadImageModifyDialogClose}
          bulkUploadId={bulkUploadId}
          bulkUploadImage={selectedBulkUploadImage}
          onMatchSuccess={handleMatchSuccess}
          student={selectedGroup?.student}
          tasks={assignmentTasks}
          allGroupsMatched={unmatchedBulkUploadImageGroups?.length === 0}
          onNextButtonClick={handleNextButtonClick}
          unmatchedGroupsCount={unmatchedBulkUploadImageGroups.length}
        />
      )}
    </div>
  );
};

export default GroupsList;
