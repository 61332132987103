import Picker from '@emoji-mart/react';
import React, { useState } from 'react';

import data from '@emoji-mart/data';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import SmileIcon from '@material-ui/icons/SentimentSatisfiedRounded';
import cx from 'classnames';
import _ from 'lodash';

import InputField from 'components/InputField';

import styles from './styles.module.scss';

const EmojiInputField = ({
  EmojiButtonProps,
  InputProps,
  hideEmojiTogglerOnInactiveInput = false,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const showEmojiToggler = hideEmojiTogglerOnInactiveInput
    ? isFocused || isHovered || !_.isNil(anchorEl) // Show the emoji toggler if the field is focused, hovered or if the emoji selector is open.
    : true;

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFocus = () => {
    setIsFocused(true);

    if (props.onFocus) {
      props.onFocus();
    }
  };

  const handleBlur = () => {
    setIsFocused(false);

    if (props.onBlur) {
      props.onBlur();
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);

    if (props.onMouseEnter) {
      props.onMouseEnter();
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);

    if (props.onMouseLeave) {
      props.onMouseLeave();
    }
  };

  const selectEmoji = (emoji) => {
    const input = `${props.value} ${emoji.native}`;
    const fakeEvent = {
      target: { value: input }
    };

    if (props.onChange) {
      props.onChange(fakeEvent);
    }
  };

  return (
    <>
      <InputField
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment
              position="end"
              className={cx(styles.emojiToggler, {
                [styles.visible]: showEmojiToggler
              })}
            >
              <IconButton
                aria-controls="emoji-menu"
                aria-haspopup="true"
                onClick={handleOpen}
                size="small"
                {...EmojiButtonProps}
              >
                <SmileIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Menu
        keepMounted
        id="emoji-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={!_.isNil(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Picker
          data={data}
          previewEmoji="bulb"
          title="Pick an emoji"
          onEmojiSelect={selectEmoji}
        />
      </Menu>
    </>
  );
};

export default EmojiInputField;
