import { BASE_URL } from 'config/urls';

import { get, post, requestSdk } from 'utils/sdk';

export const assignmentDetail = async ({ studentId, assignmentId }) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v1/students/${studentId}/assignments/${assignmentId}/`)
  );

export const addAssignmentComment = async ({ studentId, assignmentId, data }) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/students/${studentId}/assignments/${assignmentId}/add-comment/`,
      data
    )
  );

export const uploadStudentWorkV2 = async (studentId, formData) => {
  formData.append('is_student_mobile_captured', false);

  return requestSdk(() =>
    post(`${BASE_URL}/v2/students/${studentId}/assignments/upload/`, formData)
  );
};
