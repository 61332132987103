import React, { useEffect, useMemo, useState } from 'react';

import { workTagImageFeedbackCreate } from 'sdk';

import { errorOptions } from 'pages/Teachers/shared/AssignmentDialog/constants';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import MultipleSelect from 'components/MultipleSelect';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const AIFeedbackDialog = ({ setIsOpen, onClose, workTagImageId }) => {
  const [errorTypes, setErrorTypes] = useState([]);
  const [description, setDescription] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const buildPayload = () => {
    return {
      error_types: errorTypes,
      comment: description
    };
  };

  const feedbackSubmit = async () => {
    const { success, errors } = await workTagImageFeedbackCreate({
      workTagImageId,
      data: buildPayload()
    });

    if (success) {
      setIsOpen(false);
      notifySuccess('Feedback successfully submitted');
    } else {
      setIsOpen(false);
      notifyErrors(errors);
    }
  };

  const isAnyFieldEmpty = useMemo(
    () => errorTypes.length === 0 && description.trim() === '',
    [errorTypes, description]
  );

  useEffect(() => {
    setSubmitButtonDisabled(isAnyFieldEmpty);
  }, [isAnyFieldEmpty]);

  return (
    <Dialog
      open
      classes={{ paper: styles.paperContainer }}
      onClose={onClose}
      onClick={(e) => {
        // Prevent event bubbling to parent components to avoid unintended toggling
        e.stopPropagation();
      }}
    >
      <div className={styles.dialogContent}>
        <Typography variant="H-TEXT-1" component="h1">
          let us know how to improve insights:
        </Typography>

        <div className={styles.listItem}>
          Error types:{' '}
          <MultipleSelect
            options={errorOptions || []}
            onChange={(value) => setErrorTypes(value)}
          />
        </div>

        <Typography variant="H-TEXT-1" component="h1">
          provide details (optional):
        </Typography>
        <InputField
          multiline={true}
          classes={{ root: styles.textAreaRoot }}
          minRows={3}
          onChange={(e) => setDescription(e.target.value)}
          inputProps={{ maxLength: 200 }}
        />
        <Button
          color="pink"
          className={styles.submitButton}
          onClick={feedbackSubmit}
          disabled={submitButtonDisabled}
        >
          Submit
        </Button>
      </div>
    </Dialog>
  );
};

export default AIFeedbackDialog;
