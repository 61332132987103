import React from 'react';

import PaginationItem from '@material-ui/lab/PaginationItem';
import cx from 'classnames';
import _ from 'lodash';

import styles from './styles.module.scss';

const DEFAULT_VISIBLE = 3;

class SimplePagination extends React.Component {
  getVisiblePages = () => {
    const { current, count } = this.props;

    if (current === count - 1) {
      return [current - 1, current];
    }

    return [current, current + 1];
  };

  render() {
    const {
      count,
      current,
      onChange,
      hideArrows = false,
      className
    } = this.props;

    if (count === 0) {
      return null;
    }

    return (
      <div className={cx(styles.pagination, className)}>
        {count > 1 && !hideArrows && (
          <PaginationItem
            type="previous"
            size="small"
            onClick={(e) =>
              onChange(e, current === 0 ? count - 1 : current - 1)
            }
          />
        )}

        {count <= DEFAULT_VISIBLE &&
          _.times(count, (page) => (
            <PaginationItem
              key={page}
              page={page + 1}
              selected={current === page}
              type="page"
              color="secondary"
              size="small"
              onClick={(e) => onChange(e, page)}
            />
          ))}

        {count > DEFAULT_VISIBLE && (
          <React.Fragment>
            {current > 0 && '...'}

            {_.map(this.getVisiblePages(), (page) => (
              <PaginationItem
                key={page}
                page={page + 1}
                selected={current === page}
                type="page"
                color="secondary"
                size="small"
                onClick={(e) => onChange(e, page)}
              />
            ))}

            {current < count - 1 && '...'}
          </React.Fragment>
        )}

        {count > 1 && !hideArrows && (
          <PaginationItem
            type="next"
            color="standard"
            size="small"
            onClick={(e) =>
              onChange(e, current === count - 1 ? 0 : current + 1)
            }
          />
        )}
      </div>
    );
  }
}

export default SimplePagination;
