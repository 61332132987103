import React from 'react';

import _ from 'lodash';

import DebouncedField from 'components/DebouncedField';
import SearchFilter from 'components/SearchFilter';

const DebouncedSearchFilter = (props) => (
  <DebouncedField {...props} component={DebouncedSearchFilterComponent} />
);

const DebouncedSearchFilterComponent = ({
  onChange,
  SearchFilterComponent = SearchFilter,
  ...props
}) => (
  <SearchFilterComponent
    filterOptions={(options) =>
      _.filter(options, (opt) =>
        opt.toLowerCase().includes(props.value.toLowerCase())
      )
    }
    onInputChange={(e, newValue) => {
      if (props.value !== newValue) {
        onChange({ target: { value: newValue } });
      }
    }}
    {...props}
  />
);

export default DebouncedSearchFilter;
