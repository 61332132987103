import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import Button from 'components/Button';

const useStyles = makeStyles({
  label: {
    justifyContent: 'space-between'
  },
  invisible: {
    visibility: 'hidden'
  }
});

const ForwardButton = ({ largeIcon, ...props }) => {
  const styles = useStyles();

  return (
    <Button
      classes={{ label: styles.label }}
      startIcon={
        <ChevronRightRoundedIcon
          style={{ fontSize: largeIcon ? '30px' : '24px' }}
          className={styles.invisible}
        />
      }
      endIcon={
        <ChevronRightRoundedIcon
          style={{ fontSize: largeIcon ? '30px' : '24px' }}
        />
      }
      {...props}
    />
  );
};

export default ForwardButton;
