import React, { useState } from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ConfirmDialog from 'pages/Teachers/InsightsV2/components/ConfirmDialog';
import { MISCONCEPTION_OPTIONS } from 'pages/Teachers/InsightsV2/constants';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import MultipleSelect from 'components/MultipleSelect';

const misconceptionOptions = MISCONCEPTION_OPTIONS.map((option) => ({
  value: option,
  label: option
}));

const AddMisconceptionsDialog = ({
  isOpen,
  onConfirm,
  onClose,
  setMisconceptionsToAdd,
  setPreAnnotationFeedback,
  disableFeedback
}) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const submitAdditions = () => setIsConfirmOpen(true);
  const closeConfirm = () => setIsConfirmOpen(false);

  const confirmAddition = () => {
    onConfirm();
    setIsConfirmOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Select Misconceptions</DialogTitle>
        <DialogContent>
          <MultipleSelect
            options={misconceptionOptions}
            onChange={setMisconceptionsToAdd}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={submitAdditions} color="pink">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {isConfirmOpen && (
        <ConfirmDialog
          onClose={closeConfirm}
          onConfirm={confirmAddition}
          text={'Are you sure you want to add these misconceptions?'}
          onPreannotationFeedbackChange={({ newFeedback }) =>
            setPreAnnotationFeedback({ newFeedback })
          }
          disableFeedback={disableFeedback}
        />
      )}
    </>
  );
};

export default AddMisconceptionsDialog;
