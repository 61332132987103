import React from 'react';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const AllStudentsMatched = ({ onReviewClick, onNextClick }) => (
  <div className={styles.container}>
    <Typography variant="H-TEXT-2" className={styles.allMatchedText}>
      All students matched!
    </Typography>
    <Button fullWidth onClick={onReviewClick} className={styles.reviewButton}>
      Review matches
    </Button>
    <Button
      fullWidth
      endIcon={<ChevronRightRoundedIcon />}
      onClick={onNextClick}
      color="pink"
    >
      Next
    </Button>
  </div>
);

export default AllStudentsMatched;
