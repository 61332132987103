import React, { useCallback, useContext, useMemo } from 'react';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SvgIcon from '@material-ui/core/SvgIcon';
import _ from 'lodash';

import MoveMenu from 'pages/Teachers/MeetingSort/components/MoveMenu';
import StudentWorkDialog from 'pages/Teachers/MeetingSort/components/StudentWorkDialog';
import { STATUS_ICONS } from 'pages/Teachers/shared/StatusBoxIcon';
import { useDialog } from 'utils/hooks';

import MeetingSortContext from 'components/MeetingSortContext';
import PaginatedStudentWorkTasksButtons from 'components/PaginatedStudentWorkTasksButtons';
import StudentWorkTasksButtons from 'components/StudentWorkTasksButtons';
import Typography from 'components/Typography';
import WorkCardImage from 'components/WorkCardImage';

import styles from './styles.module.scss';

const StudentWorkCard = ({ studentWork }) => {
  const {
    workIndexes,
    setWorkIndexes,
    taskIndexes,
    setTaskIndexes,
    paginatedTasksStartIndexes,
    setPaginatedTasksStartIndexes
  } = useContext(MeetingSortContext);

  const taskIndex = _.get(taskIndexes, studentWork.id, 0);
  const workIndex = _.get(workIndexes, studentWork.id, 0);
  const paginatedTaskStartIndex = _.get(
    paginatedTasksStartIndexes,
    studentWork.id,
    0
  );

  const tasks = studentWork.tasks;

  const activeTask = _.get(tasks, taskIndex, {});
  const activeWork = _.get(activeTask.work, workIndex, {});

  const student = studentWork.student;
  const exemplar = student.is_teacher_demo_student;

  const showTaskButtons = useMemo(
    () => tasks.length > 1 && tasks.length <= 5,
    [tasks.length]
  );
  const showActiveWorksDropdown = activeTask.work?.length > 1;
  const showPaginatedTaskButtons = useMemo(
    () => tasks.length > 5,
    [tasks.length]
  );

  const isEmptyWork = _.isEmpty(activeWork.work_url);

  const studentName = useMemo(
    () => (exemplar ? `Exemplar - ${student.preferred_name}` : student.name),
    [exemplar, student]
  );

  const taskButtonElements = useMemo(
    () =>
      tasks.map((task, index) => ({
        label: index + 1,
        key: task.id,
        value: index,
        isActive: index === taskIndex
      })),
    [tasks, taskIndex]
  );

  const updateWorkIndex = useCallback(
    ({ newIndex }) => {
      setWorkIndexes((previousValue) => ({
        ...previousValue,
        ...{ [studentWork.id]: newIndex }
      }));
    },
    [setWorkIndexes, studentWork.id]
  );

  const updateTaskIndex = useCallback(
    ({ newIndex }) => {
      setTaskIndexes((previousValue) => ({
        ...previousValue,
        ...{ [studentWork.id]: newIndex }
      }));
    },
    [setTaskIndexes, studentWork.id]
  );

  const updatePaginatedTasksStartIndexes = useCallback(
    ({ newIndex }) => {
      setPaginatedTasksStartIndexes((previousValue) => ({
        ...previousValue,
        ...{ [studentWork.id]: newIndex }
      }));
    },
    [setPaginatedTasksStartIndexes, studentWork.id]
  );

  const handleRightArrowClick = useCallback(() => {
    // When tasks are paginated we show 3 of them.
    // When the active task is the third one, on left arrow click we should change the active task.
    // With this if we handle this case.
    if (paginatedTaskStartIndex + 2 === taskIndex) {
      updatePaginatedTasksStartIndexes({
        newIndex: paginatedTaskStartIndex + 1
      });
    }

    updateTaskIndex({ newIndex: taskIndex + 1 });
    updateWorkIndex({ newIndex: 0 });
  }, [
    paginatedTaskStartIndex,
    taskIndex,
    updateWorkIndex,
    updateTaskIndex,
    updatePaginatedTasksStartIndexes
  ]);

  const handleLeftArrowClick = useCallback(() => {
    if (paginatedTaskStartIndex === taskIndex) {
      updatePaginatedTasksStartIndexes({
        newIndex: paginatedTaskStartIndex - 1
      });
    }

    updateTaskIndex({ newIndex: taskIndex - 1 });
    updateWorkIndex({ newIndex: 0 });
  }, [
    paginatedTaskStartIndex,
    taskIndex,
    updateWorkIndex,
    updateTaskIndex,
    updatePaginatedTasksStartIndexes
  ]);

  const {
    isOpened: isStudentWorkDialogOpened,
    openDialog: openStudentWorkDialog,
    closeDialog: closeStudentWorkDialog
  } = useDialog();

  return (
    <>
      <div className={styles.container}>
        <WorkCardImage
          isEmptyWork={isEmptyWork}
          onClick={openStudentWorkDialog}
          activeWork={activeWork}
          activeWorkIndex={workIndex}
          activeTaskIndex={taskIndex}
          assignmentTasks={tasks}
          activeTaskWork={activeTask.work}
          onLeftArrowClick={handleLeftArrowClick}
          onTopArrowClick={() => updateWorkIndex({ newIndex: workIndex - 1 })}
          onBottomArrowClick={() =>
            updateWorkIndex({ newIndex: workIndex + 1 })
          }
          onRightArrowClick={handleRightArrowClick}
        />
        <div className={styles.actionRow}>
          <SvgIcon
            fontSize="small"
            component={STATUS_ICONS[activeTask.status]}
          />

          <div className={styles.taskButtons}>
            {showPaginatedTaskButtons && (
              <PaginatedStudentWorkTasksButtons
                elements={taskButtonElements}
                onChange={(element) => {
                  updateTaskIndex({ newIndex: element.value });
                  updateWorkIndex({ newIndex: 0 });
                }}
                onLeftArrowClick={handleLeftArrowClick}
                onRightArrowClick={handleRightArrowClick}
                firstElementIndex={paginatedTaskStartIndex}
                elementsCount={tasks.length}
              />
            )}
            {showTaskButtons && (
              <StudentWorkTasksButtons
                elements={taskButtonElements}
                onChange={(element) => {
                  updateTaskIndex({ newIndex: element.value });
                  updateWorkIndex({ newIndex: 0 });
                }}
              />
            )}
          </div>
          <div className={styles.selectWork}>
            {showActiveWorksDropdown && (
              <FormControl>
                <Select
                  className={styles.selectField}
                  classes={{ root: styles.selectFieldRoot }}
                  value={workIndex}
                  onChange={(e, element) => {
                    updateWorkIndex({ newIndex: element.props.value });
                  }}
                >
                  {activeTask.work.map((workItem, index) => {
                    if (workItem.is_feedback) {
                      return (
                        <MenuItem key={index} value={index}>
                          Feedback
                        </MenuItem>
                      );
                    }

                    if (workItem.is_revision) {
                      return (
                        <MenuItem key={index} value={index}>
                          Revision
                        </MenuItem>
                      );
                    }

                    return (
                      <MenuItem key={index} value={index}>
                        Original
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </div>
        </div>
        <div className={styles.bottomRow}>
          <Typography variant="B-Text-1">{studentName}</Typography>

          <MoveMenu studentWork={studentWork} />
        </div>
      </div>

      {isStudentWorkDialogOpened && (
        <StudentWorkDialog
          initialTaskIndex={taskIndex}
          initialWorkIndex={workIndex}
          studentWork={{
            ...studentWork,
            tasks,
            student
          }}
          assignmentId={activeTask.tracker_instance_section_id}
          trackerId={activeTask.tracker_id}
          onClose={closeStudentWorkDialog}
        />
      )}
    </>
  );
};

export default React.memo(StudentWorkCard);
