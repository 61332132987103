import React from 'react';

import logoSmall from 'images/logo-small.svg';

import { formatDateToString } from '../AssignmentsList/utils';
import styles from './styles.module.scss';

const NoAssignments = ({ selectedDate, message }) => {
  const displayDate = formatDateToString(selectedDate);
  return (
    <div>
      <img className={styles.icon} src={logoSmall} alt="Logo" />
      <h2 className={styles.messageTitle}>No deadlines today!</h2>
      <div className={styles.messageText}>
        {message ? (
          <p>
            {message} on {displayDate}
          </p>
        ) : (
          <p>
            It looks like there are no assignments due on{' '}
            <b>{selectedDate ? displayDate : 'today'}</b>. It is a great day to
            relax or get ahead of future assignments!
          </p>
        )}
      </div>
    </div>
  );
};

export default NoAssignments;
