import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import ReactRegionSelect from 'react-region-select';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import {
  TEACHERS_BULK_UPLOAD_MATCH_STUDENTS_URL,
  TEACHERS_BULK_UPLOAD_SUBMISSIONS_MATCH_URL
} from 'config/urls';
import _ from 'lodash';
import { selectBulkUploadNameRegion, useBulkUploadDetail } from 'sdk';
import { getItemFromLocalStorage } from 'utils';

import { Typography } from 'pages/Students/shared';
import Layout from 'pages/Teachers/BulkUpload/components/Layout';
import { useBulkUploadStatusRedirect } from 'pages/Teachers/BulkUpload/hooks';
import { useNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { notifyErrors } from 'utils/notifications';
import { reverse } from 'utils/urls';

import ImageComponent from 'components/Image';
import UserContext from 'components/UserContext';

import NameSelectGif from './assets/name-selection.gif';
import styles from './styles.module.scss';

// x, y, width, height are percentages, off the upper left corner
const INITIAL_REGION = {
  x: 20,
  y: 1.55,
  width: 27.78,
  height: 5.13,
  data: {}
};

const NameRegionSelect = ({ match }) => {
  const user = useContext(UserContext);
  const history = useHistory();

  const [showLoader, setShowLoader] = useState(false);
  const [regions, setRegions] = useState([INITIAL_REGION]);

  const showSelectRegionGif = useMemo(
    () => getItemFromLocalStorage({ item: 'showSelectRegionGif' }) !== false,
    []
  );

  useNavbar({ title: 'CAPTURE WORK' });

  const [imageSize, setImageSize] = useState({});
  const [showGif, setShowGif] = useState(showSelectRegionGif);
  const [rotationCount, setRotationCount] = useState(0);

  const bulkUploadId = _.get(match, 'params.bulkUploadId');

  const { data: bulkUpload, isLoading: isFetchingBulkUpload } =
    useBulkUploadDetail({ bulkUploadId });
  useBulkUploadStatusRedirect({
    bulkUpload,
    isLoading: isFetchingBulkUpload
  });

  useEffect(() => {
    if (showGif) {
      const timer = setTimeout(() => setShowGif(false), 10000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showGif]);

  const replayGif = () => {
    setShowGif(true);
  };

  useEffect(() => {
    localStorage.setItem('showSelectRegionGif', false);
  }, [showGif]);

  const bulkUploadCoverImage = bulkUpload?.cover_image;

  useEffect(() => {
    if (!_.isNil(bulkUploadCoverImage)) {
      const img = new Image();
      img.src = bulkUploadCoverImage;

      img.onload = () => {
        setImageSize({ width: img.width, height: img.height });
      };
    }
  }, [bulkUploadCoverImage]);

  const trackImageRotate = () => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'capture',
        view: 'confirm',
        button: 'rotate'
      }
    );
  };

  const rotate = () => {
    setRotationCount(
      (previousRotationCount) => (previousRotationCount + 2) % 4
    );
    setRegions([INITIAL_REGION]);
    trackImageRotate();
  };

  const updateRegions = useCallback(([{ x, y, width, height, isChanging }]) => {
    if (!isChanging) {
      return;
    }

    // We only support 0 & 2.
    setRegions([
      {
        x,
        y,
        width,
        height,
        data: {}
      }
    ]);
  }, []);

  const handleNextButtonClick = async () => {
    setShowLoader(true);

    const selectedRegion = regions[0];

    const top_left_x = imageSize.width * selectedRegion.x * 0.01;
    const top_left_y = imageSize.height * selectedRegion.y * 0.01;
    const widthPx = imageSize.width * selectedRegion.width * 0.01;
    const heightPx = imageSize.height * selectedRegion.height * 0.01;

    const bottom_right_x = top_left_x + widthPx;
    const bottom_right_y = top_left_y + heightPx;

    const data = {
      top_left_x,
      top_left_y,
      bottom_right_x,
      bottom_right_y,
      rotation_count: rotationCount
    };

    const { success, errors } = await selectBulkUploadNameRegion({
      bulkUploadId,
      data
    });

    if (success) {
      const url =
        process.env.REACT_APP_BULK_UPLOAD_NEW_UI_FOR_MATCH_STUDENTS_ENABLED ===
        'true'
          ? TEACHERS_BULK_UPLOAD_MATCH_STUDENTS_URL
          : TEACHERS_BULK_UPLOAD_SUBMISSIONS_MATCH_URL;
      history.push(
        reverse(url, {
          bulkUploadId: bulkUpload.id
        })
      );
    } else {
      notifyErrors(errors);
    }

    setShowLoader(false);
  };

  return (
    <Layout
      currentStepIndex={1}
      nextButtonAction={handleNextButtonClick}
      isNextButtonDisabled={showGif || showLoader}
      showLoader={showLoader}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant="H-TEXT-1" className={styles.heading}>
            Select the student name region
          </Typography>

          <div className={styles.actions}>
            <IconButton
              size="small"
              disabled={showGif}
              className={styles.rotateButton}
              onClick={rotate}
            >
              <Rotate90DegreesCcwIcon />
            </IconButton>

            <IconButton
              size="small"
              disabled={showGif}
              className={styles.helpButton}
              onClick={replayGif}
            >
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>

        {!_.isEmpty(imageSize) && (
          <div className={styles.imageContainer}>
            <>
              {showGif ? (
                <div className={styles.gifContainer}>
                  <Typography
                    variant="H-TEXT-1"
                    color={colors.pink1}
                    className={styles.gifHeader}
                  >
                    Select The entire Name Region
                  </Typography>

                  <ImageComponent
                    className={styles.gif}
                    src={NameSelectGif}
                    alt="bulk upload select region gif"
                  />
                </div>
              ) : (
                <ReactRegionSelect
                  constraint
                  maxRegions={1}
                  regions={regions}
                  className={styles.regionSelect}
                  regionStyle={{
                    background: 'rgba(230, 231, 255, 0.5)',
                    border: '2px dashed #7477B8',
                    zIndex: '2'
                  }}
                  onChange={updateRegions}
                >
                  <img
                    id="image"
                    className={styles.image}
                    src={bulkUpload?.cover_image}
                    alt="bulk upload cover"
                    style={{ transform: `rotate(${rotationCount * -90}deg)` }}
                  />
                </ReactRegionSelect>
              )}
            </>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default NameRegionSelect;
