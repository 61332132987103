import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TEACHERS_ASSIGNMENTS_RESULTS_URL } from 'config/urls';
import _ from 'lodash';
import { noop } from 'utils';

import { withNavbar } from 'pages/Teachers/shared';
import { useMixpanelPageOpenTrack } from 'utils/integrations/mixpanel';
import { parseParams, reverse, stringifyParams } from 'utils/urls';

import {
  AssignmentSkeleton,
  CelebratoryDialog,
  EmptyAssignment,
  FeedbackTool
} from './components';
import { fetchStudentAssignmentDetail } from './sdk';
import styles from './styles.module.scss';
import {
  changePageParams,
  getObjectFromListOrFirst,
  useCelebratoryDialogActions,
  useEnsurePageParams,
  useNavigation,
  usePreviousAndNextTasks,
  useSections
} from './utils';

const Feedback = ({ match, history }) => {
  useMixpanelPageOpenTrack('Teacher opened Feedback page');

  const urlParams = parseParams(history.location.search);
  const trackerId = _.get(match, 'params.trackerId');

  const [loadingEnded, setLoadingEnded] = useState(false);
  const [sections, fetchSections] = useSections();
  const [selectedSection, setSelectedSection] = useState(null);

  const students = useMemo(
    () => _.get(selectedSection, 'students', []),
    [selectedSection]
  );
  const [selectedStudent, setSelectedStudent] = useState();

  const [studentAssignment, setStudentAssignment] = useState();
  const fetchAssignment = ({ studentId, sectionId }) =>
    fetchStudentAssignmentDetail({
      assignmentId: sectionId,
      studentId
    }).then((assignment) => {
      setStudentAssignment(assignment);
      return assignment;
    });
  const refetchAssignment = () => {
    return fetchStudentAssignmentDetail({
      assignmentId: studentAssignment.id,
      studentId: selectedStudent.id
    }).then((assignment) => {
      setStudentAssignment(assignment);
      return assignment;
    });
  };

  useEffect(() => {
    if (selectedStudent && selectedSection && !studentAssignment) {
      fetchAssignment({
        studentId: selectedStudent.id,
        sectionId: selectedSection.id
      });
    }
  }, [selectedStudent, selectedSection, studentAssignment]);

  const tasks = _.get(studentAssignment, 'tasks', []);
  const [activeTask, setActiveTask] = useState();
  const [activeWork, setActiveWork] = useState();

  useEffect(() => {
    // Reset the whole page state when the whole assignment is changed
    setSelectedSection(null);
    setSelectedStudent(null);
    setStudentAssignment(null);
    setActiveTask(null);
    setActiveWork(null);
  }, [trackerId]);

  const [
    isCelebratoryDialogVisible,
    setIsCelebratoryDialogVisible,
    closestAssignment,
    setClosestAssignment,
    goToAssignmentResults,
    goToNextAssignment
  ] = useCelebratoryDialogActions({
    trackerId,
    sectionId: selectedSection?.id
  });

  useEnsurePageParams({
    urlParams,
    selectedSectionId: _.get(selectedSection, 'id'),
    selectedStudentId: _.get(selectedStudent, 'id'),
    activeTaskId: _.get(activeTask, 'id'),
    workItemId: _.get(activeWork, 'id')
  });

  useEffect(() => {
    if (!selectedSection) {
      fetchSections(trackerId).then((sections) => {
        setSelectedSection(
          getObjectFromListOrFirst(sections, urlParams.section)
        );
      });
    }
  }, [urlParams.section, selectedSection, fetchSections, trackerId]);

  useEffect(() => {
    if (!selectedStudent) {
      setSelectedStudent(getObjectFromListOrFirst(students, urlParams.student));
    }
  }, [selectedStudent, students, urlParams.student, setSelectedStudent]);

  useEffect(() => {
    if (studentAssignment && !activeTask) {
      setActiveTask(getObjectFromListOrFirst(tasks, urlParams.task));
    }
  }, [studentAssignment, activeTask, tasks, urlParams.task]);

  useEffect(() => {
    setActiveWork(
      getObjectFromListOrFirst(_.get(activeTask, 'work', []), urlParams.work)
    );
  }, [activeTask, urlParams.work]);

  const [previousTask, nextTask] = usePreviousAndNextTasks({
    tasks,
    task: activeTask
  });

  useEffect(() => {
    if (selectedSection && _.isEmpty(students)) {
      setLoadingEnded(true);
    }
  }, [selectedSection, students]);

  const onCanvasLoaded = () => {
    setLoadingEnded(true);
  };

  const changeSelectedSection = useCallback((section) => {
    const firstStudent = _.first(section.students);

    setSelectedSection(section);
    setSelectedStudent(firstStudent);
  }, []);

  const switchAssignment = useCallback(
    ({ assignment, task, studentId, sectionId }) => {
      // If task is set - then there is a next assignment to show
      if (task) {
        const section = getObjectFromListOrFirst(sections, sectionId);
        const student = getObjectFromListOrFirst(section.students, studentId);
        const nextTask = { ...task, alreadyRendered: true };
        const workItem = _.first(_.get(task, 'work', []));

        setSelectedSection(section);
        setSelectedStudent(student);
        setStudentAssignment(assignment);
        setActiveTask(nextTask);
        setActiveWork(workItem);
      }
    },
    [sections]
  );

  const switchAssignmentFromNewTracker = useCallback(() => {
    goToNextAssignment()
      .then(({ sectionId, studentId }) => {
        changePageParams({
          selectedStudentId: studentId,
          selectedSectionId: sectionId,
          activeTaskId: null,
          workItemId: null
        });

        setSelectedSection(null);
        setSelectedStudent(null);
        setStudentAssignment(null);
        setActiveTask(null);
        setActiveWork(null);
      })
      .catch(noop);
  }, [goToNextAssignment]);

  const onSectionSelect = useCallback(
    (newSection) => {
      setLoadingEnded(false);

      const firstStudent = _.first(newSection.students);
      changeSelectedSection(newSection);

      if (firstStudent) {
        fetchAssignment({
          studentId: firstStudent.id,
          sectionId: newSection.id
        }).then((assignment) => {
          setStudentAssignment(assignment);
          const task = _.first(assignment.tasks);
          setActiveTask(task);
          setActiveWork(_.first(_.get(task, 'work', [])));
        });
      }
    },
    [changeSelectedSection]
  );

  const onStudentSelect = useCallback(
    (newStudent) => {
      setLoadingEnded(false);

      setSelectedStudent(newStudent);

      if (selectedSection) {
        fetchAssignment({
          studentId: newStudent.id,
          sectionId: selectedSection.id
        }).then((assignment) => {
          setStudentAssignment(assignment);
          const task = _.first(assignment.tasks);
          setActiveTask(task);
          setActiveWork(_.first(_.get(task, 'work', [])));
        });
      }
    },
    [selectedSection]
  );

  const onTitleAndBackClick = useCallback(
    () =>
      history.push({
        pathname: reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
          trackerId: trackerId
        }),
        search: stringifyParams({
          section: _.get(selectedSection, 'id', '')
        })
      }),
    [trackerId, selectedSection, history]
  );
  const assignmentName = useMemo(
    () => _.get(studentAssignment, 'name'),
    [studentAssignment]
  );

  useNavigation({
    assignmentName,
    onTitleClick: onTitleAndBackClick,
    sections,
    selectedSection,
    onSectionSelect,
    students,
    selectedStudent,
    onStudentSelect,
    onBackButtonClick: onTitleAndBackClick
  });

  const noActiveStudentWork =
    loadingEnded && (!activeWork || _.isEmpty(students));

  return (
    <div className={styles.container}>
      {noActiveStudentWork && <EmptyAssignment />}
      {!loadingEnded && !activeWork && <AssignmentSkeleton />}
      {!noActiveStudentWork && activeWork && activeTask && (
        <FeedbackTool
          activeTask={activeTask}
          activeWork={activeWork}
          setActiveTask={setActiveTask}
          setActiveWork={setActiveWork}
          onCanvasLoaded={onCanvasLoaded}
          previousTask={previousTask}
          nextTask={nextTask}
          refetchAssignment={refetchAssignment}
          switchAssignment={switchAssignment}
          fetchAssignment={fetchAssignment}
          setStudentAssignment={setStudentAssignment}
          setShowCelebratoryDialog={setIsCelebratoryDialogVisible}
          setClosestAssignment={setClosestAssignment}
        />
      )}
      <CelebratoryDialog
        open={isCelebratoryDialogVisible}
        assignmentName={assignmentName}
        onClose={() => setIsCelebratoryDialogVisible(false)}
        nextAssignment={closestAssignment}
        goToNextAssignment={switchAssignmentFromNewTracker}
        goToAssignmentResults={goToAssignmentResults}
      />
    </div>
  );
};

export default withNavbar(Feedback);
