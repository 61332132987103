import React, { useState } from 'react';
import Linkify from 'react-linkify';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { colors } from 'theme/palette';
import { openInNewTab } from 'utils/urls';

import EmojiInputField from 'components/EmojiInputField';
import Typography from 'components/Typography';

import { ReactComponent as CommentIcon } from './assets/add_comment.svg';
import { ReactComponent as FileIcon } from './assets/file.svg';
import styles from './styles.module.scss';

const Avatar = ({ user }) => {
  const initials = _.take(user.name.split(' '), 2)
    .map((name) => _.first(name)?.toUpperCase())
    .join('');

  return <div className={styles.initials}>{initials}</div>;
};

const Attachment = ({ attachment }) => {
  return (
    <div
      className={styles.attachment}
      onClick={() => openInNewTab(attachment.file_url)}
    >
      <FileIcon fill={colors.blue3} />
      <Typography variant="B-Text-3" color={colors.blue3}>
        {attachment.original_filename}
      </Typography>
    </div>
  );
};

const CommentInput = ({ onSubmit }) => {
  const [value, setValue] = useState('');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e, value);
        setValue('');
      }}
    >
      <div className={styles.inputFieldContainer}>
        <EmojiInputField
          fullWidth
          variant="underlined"
          placeholder="Reply..."
          value={value}
          InputProps={{
            disableUnderline: true
          }}
          classes={{ root: styles.inputField }}
          onChange={(event) => setValue(event.target.value)}
        />
        <IconButton type="submit" size="small">
          <CommentIcon
            fill={value !== '' ? colors.green3 : colors.grey5}
            width={30}
            height={30}
          />
        </IconButton>
      </div>
    </form>
  );
};

const Comment = ({ comment }) => {
  const user = _.get(comment, 'user');
  const attachments = _.get(comment, 'attachments', []);

  if (!user) {
    return null;
  }

  return (
    <div className={styles.author}>
      <Avatar user={user} />
      <div className={styles.container}>
        <div className={styles.nameAndDateContainer}>
          <Typography variant="B-Text-3" color={colors.blue1}>
            {user.name}
          </Typography>
          <Typography variant="B-Text-3" color={colors.grey3}>
            {moment(comment.created_at).format('ddd MM/DD h:mm a')}
          </Typography>
        </div>
        <Typography variant="B-Text-2" color={colors.grey1}>
          <Linkify properties={{ target: '_blank' }}>{comment.text}</Linkify>
        </Typography>
        {attachments.length > 0 && (
          <div className={styles.attachments}>
            {attachments.map((attachment) => (
              <Attachment key={attachment.id} attachment={attachment} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Comments = ({ comments, onSubmit, className }) => {
  return (
    <Paper
      variant="outlined"
      classes={{
        root: classnames(styles.paper, className)
      }}
    >
      <Typography variant="S-TEXT-1" color={colors.blue1}>
        Notes
      </Typography>

      {comments.map((comment) => (
        <React.Fragment key={comment.id}>
          <Comment comment={comment} />
          {_.map(_.get(comment, 'replies'), (replyComment) => (
            <Comment key={replyComment.id} comment={replyComment} />
          ))}
        </React.Fragment>
      ))}

      <CommentInput onSubmit={onSubmit} />
    </Paper>
  );
};

export default Comments;
