import React from 'react';
import { useHistory } from 'react-router-dom';

import cx from 'classnames';
import { TEACHERS_MEETING_FEED_URL } from 'config/urls';
import _ from 'lodash';

import { Typography } from 'pages/Walkthrough/components';
import { parseParams, reverse } from 'utils/urls';

import Button from 'components/Button';

import { ReactComponent as GoogleClassroomIcon } from './assets/classroom.svg';
import { ReactComponent as CleverIcon } from './assets/clever.svg';
import styles from './styles.module.scss';

const StudentsLoadMethodChooser = ({
  loadViaGoogleClassroom,
  loadViaClever,
  invitationIdentifier
}) => {
  const history = useHistory();

  const queryParams = parseParams(history.location.search);

  return (
    <div className={styles.loaderChooserContainer}>
      <Typography variant="H-TEXT-2">Load students from:</Typography>
      <Button
        className={cx(styles.button, styles.googleClassroomButton)}
        startIcon={<GoogleClassroomIcon />}
        variant="textAndIcon"
        onClick={loadViaGoogleClassroom}
        noTypography
      >
        <Typography variant="B-Text-2">Google Classroom</Typography>
      </Button>
      <Button
        className={cx(styles.button, styles.cleverButton)}
        startIcon={<CleverIcon />}
        variant="textAndIcon"
        onClick={loadViaClever}
        noTypography
      >
        <Typography variant="B-Text-2">Clever</Typography>
      </Button>
      {!_.isNil(invitationIdentifier) && (
        <Button
          fullWidth
          onClick={() => {
            const invitationIdentifier = _.get(
              queryParams,
              'invitationIdentifier'
            );
            history.push({
              pathname: reverse(TEACHERS_MEETING_FEED_URL, {
                invitationIdentifier
              })
            });
          }}
          className={styles.skipButton}
        >
          Skip Loading students
        </Button>
      )}
    </div>
  );
};

export default StudentsLoadMethodChooser;
