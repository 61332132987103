import React from 'react';

import DropdownButton from 'components/DropdownButton';

import styles from './styles.module.scss';

const ShareToButton = ({ disabled, onShareTooltipClick }) => {
  return (
    <DropdownButton
      color="blue"
      title="Share To"
      disabled={disabled}
      className={styles.sameWidth}
    >
      <DropdownButton.Item
        onClick={onShareTooltipClick}
        color="blue"
        className={styles.sameWidth}
      >
        Meeting
      </DropdownButton.Item>
    </DropdownButton>
  );
};

export default React.memo(ShareToButton);
