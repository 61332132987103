import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import classnames from 'classnames';

import assignmentSummaryStyles from 'pages/Teachers/Assignments/Results/components/AssignmentSummary/styles.module.scss';
import studentWorkStyles from 'pages/Teachers/Assignments/Results/components/StudentWorkList/styles.module.scss';
import pageStyles from 'pages/Teachers/Assignments/Results/styles.module.scss';
import { StudentCardSkeleton } from 'pages/Teachers/shared/StudentWorkSkeleton';
import { colors } from 'theme/palette';

import AccordionSummary from 'components/AccordionSummary';
import Button from 'components/Button';
import DropdownButton from 'components/DropdownButton';
import MultiSelectFilter from 'components/MultiSelectFilter';
import SelectFilter from 'components/SelectFilter';
import Typography from 'components/Typography';

import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import { ReactComponent as MultiSelectIcon } from '../../assets/multiselect-icon.svg';
import { ReactComponent as ShareIcon } from '../../assets/share-icon.svg';
import filterStyles from '../Filters/styles.module.scss';

const FiltersSkeleton = () => (
  <div className={filterStyles.filterWrapper}>
    <div className={filterStyles.filterBox}>
      <Typography variant="S-TEXT-1" color={colors.grey4}>
        Filter Pages
      </Typography>
      <MultiSelectFilter
        disabled
        className={filterStyles.filterInput}
        options={[]}
        placeholder="All"
      />
    </div>
    <div className={filterStyles.filterBox}>
      <Typography variant="S-TEXT-1" color={colors.grey4}>
        Filter Students
      </Typography>
      <MultiSelectFilter
        disabled
        className={filterStyles.filterInput}
        options={[]}
        placeholder="All"
      />
    </div>
    <div className={filterStyles.filterBox}>
      <Typography variant="S-TEXT-1" color={colors.grey4}>
        Sort by
      </Typography>
      <SelectFilter
        disabled
        placeholder="Most Recent"
        className={filterStyles.filterInput}
        options={[]}
      />
    </div>
    <div className={filterStyles.filterBox}>
      <Typography variant="S-TEXT-1" color={colors.grey4}>
        Show
      </Typography>
      <SelectFilter
        disabled
        placeholder="All Submitted"
        className={filterStyles.filterInput}
        options={[]}
      />
    </div>
  </div>
);

const AssignmentSummarySkeleton = () => (
  <div className={assignmentSummaryStyles.summaryWrapper}>
    <div className={assignmentSummaryStyles.titleWrapper}>
      <Skeleton variant="text" width={125} />
      <Skeleton variant="text" width={80} />
    </div>
    <div className={assignmentSummaryStyles.buttonsWrapper}>
      <div className={assignmentSummaryStyles.blueButtonsWrapper}>
        <Button color="blue" disabled>
          Data
        </Button>
        <DropdownButton disabled color="blue" title="Share To" />
        <Button color="blue" disabled>
          Manage
        </Button>
      </div>

      <div className={assignmentSummaryStyles.iconButtonsWrapper}>
        <IconButton size="small" disabled>
          <MultiSelectIcon />
        </IconButton>
        <IconButton size="small" disabled>
          <ShareIcon />
        </IconButton>
        <IconButton size="small" disabled>
          <DownloadIcon />
        </IconButton>
      </div>
    </div>
  </div>
);

const AccordionSkeleton = ({ lgScreen }) => (
  <Accordion
    square={!lgScreen}
    variant="outlined"
    expanded={false}
    className={pageStyles.panel}
  >
    <AccordionSummary
      classes={{
        root: pageStyles.panelSummary,
        expandIcon: pageStyles.accordionExpandIcon
      }}
      IconButtonProps={{
        size: 'small'
      }}
    >
      <AssignmentSummarySkeleton />
    </AccordionSummary>
  </Accordion>
);

const StudentWorkListSkeleton = ({ lgScreen }) => (
  <div
    className={classnames({
      [studentWorkStyles.wrapper]: lgScreen,
      [studentWorkStyles.smallwrapper]: !lgScreen
    })}
  >
    <div className={studentWorkStyles.studentCardsContainer}>
      <StudentCardSkeleton />
      <StudentCardSkeleton />
      {lgScreen && <StudentCardSkeleton />}
    </div>
  </div>
);

const PageSkeleton = ({ lgScreen }) => (
  <div>
    <div className={pageStyles.headerContainer}>
      <div className={pageStyles.header}>
        <FiltersSkeleton />
      </div>
    </div>
    <div className={pageStyles.paperContainer}>
      <div className={pageStyles.paper}>
        <AccordionSkeleton lgScreen={lgScreen} />
      </div>
    </div>
    <StudentWorkListSkeleton lgScreen={lgScreen} />
  </div>
);

export default PageSkeleton;
