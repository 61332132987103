import React, { useCallback, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';

import { buildColumnId } from 'pages/Teachers/MeetingSort/utils';
import { colors } from 'theme/palette';

import EditableText from 'components/EditableText';

import { DroppableItemsList, ItemsPercentage } from './components';
import styles from './styles.module.scss';

const Column = ({
  column: { id, name: initialColumnName },
  index,
  onColumnNameChange,
  removeColumn,
  items,
  nonExemplarItemsCount
}) => {
  const [columnControlsVisible, setColumnControlsVisible] = useState(true);

  const onColumnNameChangeCallback = useCallback(
    (newColumnName) => onColumnNameChange(id, initialColumnName, newColumnName),
    [onColumnNameChange, id, initialColumnName]
  );

  const showColumnControls = useCallback(
    () => setColumnControlsVisible(true),
    []
  );
  const hideColumnControls = useCallback(
    () => setColumnControlsVisible(false),
    []
  );

  const isExemplarColumn = useMemo(
    () => _.toLower(initialColumnName) === 'exemplar',
    [initialColumnName]
  );

  const hasItems = !_.isEmpty(items);

  return (
    <Draggable draggableId={buildColumnId(id)} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          className={styles.column}
          {...provided.draggableProps}
        >
          <div className={styles.columnHeader} {...provided.dragHandleProps}>
            <div className={styles.columnNameWrapper}>
              <EditableText
                initialText={initialColumnName}
                onTextUpdate={onColumnNameChangeCallback}
                iconsColor={colors.greyLight}
                onOpen={hideColumnControls}
                onClose={showColumnControls}
                typographyProps={{
                  variant: 'S-TEXT-1',
                  color: colors.white
                }}
              />
              {columnControlsVisible && (
                <div className={styles.columnControls}>
                  {!isExemplarColumn && (
                    <ItemsPercentage
                      columnItemsCount={items.length}
                      nonExemplarItemsCount={nonExemplarItemsCount}
                    />
                  )}
                  {!hasItems && (
                    <IconButton size="small" onClick={() => removeColumn(id)}>
                      <CloseIcon
                        fontSize="small"
                        style={{ color: colors.white }}
                      />
                    </IconButton>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.scrollable}>
            <DroppableItemsList droppableId={buildColumnId(id)} items={items} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(Column);
