import React from 'react';
import { useHistory } from 'react-router-dom';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { PATHWAYS_TODAY_LESSON_URL } from 'config/urls';

import CheckCircleIcon from 'pages/Pathways/components/PathwaysProgress/components/CheckCircleIcon';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

function createVerticalGradient({ startColor, endColor }) {
  return `linear-gradient(to bottom, ${startColor}, ${endColor})`;
}

const getPaletteAndTitle = ({ lesson }) => {
  let palette, title;

  if (lesson.completed) {
    palette = {
      lessonBackgroundColor: '#DBF3E2',
      checkForegroundColor: '#ffffff',
      checkBackgroundColor: '#438642',
      checkOutlineColor: '#77d376'
    };
    title = 'Lesson Completed';
  } else if (lesson.not_started_yet) {
    palette = {
      lessonBackgroundColor: '#FFE5EE',
      checkForegroundColor: '#F05B94',
      checkBackgroundColor: '#FFCBE1',
      checkOutlineColor: '#F05B94'
    };
    title = 'Lesson not started yet';
  } else if (lesson.not_available) {
    palette = {
      lessonBackgroundColor: '#E8E8E8',
      checkForegroundColor: '#E8E8E8',
      checkBackgroundColor: '#F9F9F9',
      checkOutlineColor: '#666666'
    };
    title = 'Not Available';
  } else {
    palette = {
      lessonBackgroundColor: '#DBF3E2',
      checkForegroundColor: '#ffffff',
      checkBackgroundColor: '#438642',
      checkOutlineColor: '#77d376'
    };
    title = 'Lesson Completed';
  }

  return { title, palette };
};

const LessonList = ({ lessons }) => {
  const history = useHistory();

  const handleLessonClick = ({ lesson }) => {
    history.push(PATHWAYS_TODAY_LESSON_URL, { lesson });
  };

  return (
    <div className={styles.lessonListContainer}>
      {lessons.map((lesson, index) => {
        let nextPalette;
        let { palette, title } = getPaletteAndTitle({ lesson });
        const nextLesson = lessons[index + 1];

        if (nextLesson) {
          const nextLessonData = getPaletteAndTitle({ lesson: nextLesson });
          nextPalette = nextLessonData.palette;
        } else {
          nextPalette = palette;
        }

        return (
          <div
            key={`lesson-${lesson.id}-${index}`}
            className={styles.lessonItem}
            style={{ background: palette.lessonBackgroundColor }}
            onClick={() =>
              !lesson.not_available && handleLessonClick({ lesson })
            }
          >
            <div className={styles.iconContainer}>
              <CheckCircleIcon
                foregroundColor={palette.checkForegroundColor}
                backgroundColor={palette.checkBackgroundColor}
                outlineColor={palette.checkOutlineColor}
                showOutline={false}
              />
              {index < lessons.length - 1 && (
                <div
                  style={{
                    display: 'block',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translate(-50%, 100%)',
                    bottom: 5,
                    height: 54,
                    width: 3,
                    background: createVerticalGradient({
                      startColor: palette.checkBackgroundColor,
                      endColor: nextPalette.checkBackgroundColor
                    }),
                    zIndex: 200
                  }}
                ></div>
              )}
            </div>
            <div className={styles.middleContainer}>
              <Typography
                variant="H-TEXT-3"
                classes={{ root: styles.itemTitle }}
              >
                {title}
              </Typography>
              <Typography
                variant="B-Text-4"
                classes={{ root: styles.itemContent }}
              >
                {lesson.title}
              </Typography>
            </div>
            <div className={styles.arrowContainer}>
              {!lesson.not_available && (
                <ChevronRightIcon htmlColor="#949494" />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LessonList;
