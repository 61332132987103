import React from 'react';

import { Tooltip } from '@material-ui/core';

import Typography from 'components/Typography';

import SwitchToggle from '../SwitchToggle';
import { ReactComponent as InfoIconAccelerated } from './assets/info-accelerated.svg';
import styles from './styles.module.scss';

const AcceleratedCurriculumSwitch = ({
  isAcceleratedCurriculum,
  setIsAcceleratedCurriculum
}) => {
  return (
    <div className={styles.acceleratedOuterContainer}>
      <div className={styles.acceleratedInnerContainer}>
        <Typography variant="B-Text-1" className={styles.acceleratedText}>
          Accelerated
        </Typography>

        <Tooltip
          classes={{ tooltip: styles.acceleratedTooltip }}
          title={
            <Typography variant="B-Text-3">
              Select &apos;Accelerated&apos; if using the IM 6-8 Math
              Accelerated curriculum.
            </Typography>
          }
          arrow
          placement="top"
        >
          <InfoIconAccelerated width={20} height={20} />
        </Tooltip>
        <SwitchToggle
          checked={isAcceleratedCurriculum}
          onChange={(e) => setIsAcceleratedCurriculum(e.target.checked)}
        />
      </div>
    </div>
  );
};

export default AcceleratedCurriculumSwitch;
