import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import cx from 'classnames';
import _ from 'lodash';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const UnmatchedBulkUploadGroup = ({
  disabled,
  bulkUploadGroup,
  onClick,
  showSkeleton,
  tasksIds
}) => (
  <>
    {bulkUploadGroup.bulk_upload_images.map((bulkUploadImage, index) => (
      <div
        key={bulkUploadImage.id}
        className={cx(
          styles.container,
          { [styles.disabled]: disabled },
          { [styles.columnStart]: index === 0 }
        )}
        onClick={disabled ? undefined : () => onClick(bulkUploadImage, index)}
      >
        <img src={bulkUploadImage.file_url} alt="bulk upload preview" />

        <div className={styles.buttonContainer}>
          <Button
            disabled={disabled}
            variant="small"
            color="blue"
            className={cx(styles.button, { [styles.hidden]: index !== 0 })}
          >
            select student
          </Button>
          <Typography
            variant="S-TEXT-1"
            display="inline"
            className={styles.element}
          >
            {tasksIds?.indexOf(bulkUploadImage.task.id) + 1}
          </Typography>
        </div>
      </div>
    ))}
    {showSkeleton &&
      _.range(bulkUploadGroup.unprocessed_images_count).map((index) => (
        <Skeleton key={index} height={262} width={250} variant="rect" />
      ))}
  </>
);

export default UnmatchedBulkUploadGroup;
