import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

export const NextStepsTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={styles.assignmentCell}>
          <Typography variant="H-TEXT-3" className={styles.headerText}>
            <b>Assignment</b>
          </Typography>
        </TableCell>
        <TableCell className={styles.studentCell}>
          <Typography variant="H-TEXT-3" className={styles.headerText}>
            <b>Students To Support</b>
          </Typography>
        </TableCell>
        <TableCell className={styles.nextStepsCell}>
          <Typography variant="H-TEXT-3" className={styles.headerText}>
            <b>Next Step</b>
          </Typography>
        </TableCell>
        <TableCell className={styles.resourcesCell}>
          <Typography variant="H-TEXT-3" className={styles.headerText}>
            <b>Suggested Resource</b>
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
