import React, { createContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import SvgIcon from '@material-ui/core/SvgIcon';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import TextFormatRoundedIcon from '@material-ui/icons/TextFormatRounded';
import _ from 'lodash';

import { TrackerObjectiveValueTypes } from 'utils/constants';

import { ReactComponent as HashtagIcon } from './assets/hashtag.svg';

export const getWorkName = (workItem) => {
  if (!workItem) return '';

  if (workItem.is_feedback) return 'Feedback';
  if (workItem.is_revision) return 'Revision';

  return 'Original';
};

export const getObjectiveValueTypeIcon = (valueType) => {
  const mapper = {
    [TrackerObjectiveValueTypes.CHECKBOX]: CheckBoxOutlinedIcon,
    [TrackerObjectiveValueTypes.CHECK_GRADE]: CheckIcon,
    [TrackerObjectiveValueTypes.RUBRIC]: FormatListNumberedIcon,
    [TrackerObjectiveValueTypes.RUBRIC_ZERO_TWO]: FormatListNumberedIcon,
    [TrackerObjectiveValueTypes.RUBRIC_ZERO_THREE]: FormatListNumberedIcon,
    [TrackerObjectiveValueTypes.RUBRIC_ZERO_FOUR]: FormatListNumberedIcon,
    [TrackerObjectiveValueTypes.NUMBER]: (props) => (
      <SvgIcon component={HashtagIcon} {...props} />
    ),
    [TrackerObjectiveValueTypes.TEXT]: TextFormatRoundedIcon
  };

  return mapper[valueType];
};

export const parseUsage = (usage) => {
  if (!usage) {
    return null;
  }

  const hasAccess = _.get(usage, 'has_access', true);

  const imagesLimit = _.get(usage, 'images_limit', null);
  const imagesUsed = _.get(usage, 'images_used', 0);
  const imagesLeft = _.get(usage, 'images_left', null);

  const messages = [];

  if (hasAccess) {
    messages.push('User has access.');

    if (imagesLimit !== null) {
      messages.push(
        `Current usage: ${imagesUsed} / ${imagesLimit}, ${imagesLeft} left.`
      );
    } else {
      messages.push(`Unlimited images available. ${imagesUsed} used so far.`);
    }
  } else {
    messages.push('User has no access.');
    messages.push(`Usage: ${imagesUsed} / ${imagesLimit}.`);
  }

  return {
    hasAccess,
    imagesLimit,
    imagesUsed,
    imagesLeft,
    messages
  };
};

export const showUsage = (parsedUsage) => {
  // We don't have any associated usage, no need to show anything
  if (parsedUsage === null) {
    return false;
  }

  // We have unlimited usage, no need to show anything
  if (parsedUsage.imagesLimit === null) {
    return false;
  }

  return true;
};

export const getStandardOptionLabel = ({ standard }) => {
  const shortDescription = _.get(standard, 'short_description', '');

  if (_.isEmpty(shortDescription)) {
    return _.get(standard, 'code', '');
  }

  return `${standard.code} - ${shortDescription}`;
};

// Context and provider for storing and accessing the previous location in the app.
export const PreviousLocationContext = createContext();

export const PreviousLocationProvider = ({ children }) => {
  const location = useLocation();
  const previousLocationRef = useRef(null);

  useEffect(() => {
    previousLocationRef.current = location;
  }, [location]);

  return (
    <PreviousLocationContext.Provider value={previousLocationRef.current}>
      {children}
    </PreviousLocationContext.Provider>
  );
};
