import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import styles from './styles.module.scss';

export const AssignmentDetailSkeleton = () => (
  <React.Fragment>
    <Grid container alignItems="center" className={styles.paperSection}>
      <Grid container item xs direction="column" spacing={1}>
        <Grid item>
          <Skeleton variant="text" width={300} height={30} />
        </Grid>
        <Grid item>
          <Skeleton variant="text" width={300} height={30} />
        </Grid>
      </Grid>
      <Grid container item xs justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Skeleton variant="rect" width={150} height={40} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" width={150} height={40} />
        </Grid>
      </Grid>
    </Grid>
    <Divider />
    <Grid container spacing={2} className={styles.paperSection}>
      <Grid container item justifyContent="space-between">
        <Grid container item xs direction="column" spacing={1}>
          <Grid item>
            <Skeleton variant="text" width={300} height={30} />
          </Grid>
        </Grid>
        <Grid container item xs justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Skeleton variant="rect" width={150} height={40} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" width={150} height={40} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {_.times(3, (index) => (
          <Skeleton key={index} variant="text" width={400} height={20} />
        ))}
      </Grid>
    </Grid>
    <Divider />
    <Grid container spacing={2} className={styles.paperSection}>
      <Grid container item justifyContent="space-between">
        <Grid item>
          <Skeleton variant="text" width={150} height={30} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" width={150} height={40} />
        </Grid>
      </Grid>
      <Grid container item direction="column" spacing={1}>
        {_.times(3, (index) => (
          <Grid item xs key={index}>
            <Skeleton variant="rect" height={35} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </React.Fragment>
);
