/*
The latest breakpoints are:
1. From 0 to 767 is mobile
2. From 768 to 992 is tablet
3. From 993 to 1279 is small desktop
4. From 1280 to infinity is desktop
*/

export const MAX_MOBILE_SCREEN = 767;

export const MIN_TABLET_SCREEN = 768;
export const MAX_TABLET_SCREEN = 992;

export const MIN_SMALL_DESKTOP_SCREEN = 993;
export const MAX_SMALL_DESKTOP_SCREEN = 1279;

export const MIN_DESKTOP_SCREEN = 1280;
