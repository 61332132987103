import React, { useMemo } from 'react';

import cx from 'classnames';
import Arrow from 'images/navigation-arrow.svg';
import _ from 'lodash';

import Image from 'components/Image';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const PaginatedStudentWorkTasksButtons = ({
  elements,
  onLeftArrowClick,
  onRightArrowClick,
  firstElementIndex,
  elementsCount,
  onChange = () => {}
}) => {
  const firstElementIsActive = elements[0].isActive;
  const lastElementIsActive = elements[elementsCount - 1].isActive;

  const paginatedElements = useMemo(() => {
    const assignmentTasksCopy = _.cloneDeep(elements); // we don't want to modify elements

    return assignmentTasksCopy.splice(firstElementIndex, 3);
  }, [firstElementIndex, elements]);

  return (
    <div className={styles.container}>
      <Image
        src={Arrow}
        onClick={!firstElementIsActive && onLeftArrowClick}
        className={cx(styles.leftArrow, {
          [styles.disabled]: firstElementIsActive
        })}
      />

      {_.map(paginatedElements, (element) => (
        <Typography
          key={element.key || element.label}
          onClick={() => onChange(element)}
          variant="S-TEXT-1"
          display="inline"
          className={cx(
            {
              [styles.selected]: element.isActive
            },
            styles.element
          )}
        >
          {element.label}
        </Typography>
      ))}
      <Image
        src={Arrow}
        onClick={!lastElementIsActive && onRightArrowClick}
        className={cx(styles.rightArrow, {
          [styles.disabled]: lastElementIsActive
        })}
      />
    </div>
  );
};

export default PaginatedStudentWorkTasksButtons;
