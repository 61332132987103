import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { isEmpty } from 'lodash';
import { trackerInstanceSectionToggleCompleteNextStep } from 'sdk';

import StudentWorkViewDialog from 'pages/MisconceptionsDashboard/components/StudentWorkViewDialog';
import { notifyError, notifySuccess } from 'utils/notifications';

import NextStepsTableBody from './components/NextStepsTableBody';
import TableHeader from './components/NextStepsTableHeader';

import styles from './styles.module.scss';

const NextStepsTable = ({
  nextSteps,
  refetchOverview,
  exemplarUrls,
  trackNextStepCompleteEvent
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedExemplarUrl, setSelectedExemplarUrl] = useState(null);

  const exemplarUrlsFormatted = exemplarUrls.map((imageUrl) => ({
    imageUrl: imageUrl
  }));

  const onCheckboxToggleClick = async ({ nextStep }) => {
    const { success } = await trackerInstanceSectionToggleCompleteNextStep({
      trackerInstanceSectionToNextStepId: nextStep.id
    });

    if (success) {
      trackNextStepCompleteEvent({ completedNextStep: nextStep.text });

      notifySuccess('Successfully updated Next step.');
      refetchOverview();
    } else {
      notifyError('Something went wrong. Please try again.');
    }
  };

  return (
    <Paper variant="outlined" className={styles.panelContainer}>
      <Table>
        <TableHeader hasResources={!isEmpty(exemplarUrlsFormatted)} />
        <NextStepsTableBody
          nextSteps={nextSteps}
          exemplarUrls={exemplarUrlsFormatted}
          onResourceClick={() => {
            setIsDialogOpen(true);
            setSelectedExemplarUrl(exemplarUrlsFormatted[0]);
          }}
          onCheckboxToggleClick={onCheckboxToggleClick}
        />
      </Table>
      {isDialogOpen && selectedExemplarUrl && (
        <StudentWorkViewDialog
          studentWork={selectedExemplarUrl}
          studentWorks={exemplarUrlsFormatted}
          setSelectedStudentWork={setSelectedExemplarUrl}
          onClose={() => {
            setIsDialogOpen(false);
            setSelectedExemplarUrl(null);
          }}
        />
      )}
    </Paper>
  );
};

export default NextStepsTable;
