import _ from 'lodash';
import * as yup from 'yup';

export const INITIAL_VALUES = {
  schoolId: null,
  organization: '',
  stateAbbreviation: '',
  city: ''
};

export const VALIDATION_SCHEMA = yup.object().shape({
  preferredUserName: yup.string().trim().required('Name is required.'),
  stateAbbreviation: yup.string().required('State is required.'),
  schoolId: yup.number().nullable(),
  organization: yup.string().when('schoolId', {
    is: (schoolId) => _.isNull(schoolId),
    then: yup.string().required('Please enter organization.')
  }),
  city: yup
    .string()
    .trim()
    .when('schoolId', {
      is: (schoolId) => _.isNull(schoolId),
      then: yup.string().required('Please select city.')
    })
});
