import { useCallback, useEffect, useRef, useState } from 'react';

export const useTouchSideScroll = (containerRef) => {
  const [containerRefIsSet, setContainerRefIsSet] = useState(false);
  const [containerElement, setContainerElement] = useState();
  const isScrolling = useRef();

  useEffect(() => {
    const moveHandler = (event) => {
      if (containerRef.current) {
        containerRef.current.scrollLeft =
          window.startScroll + window.startCoordinates - event.pageX;
      }
      if (isScrolling.current === false) {
        document.removeEventListener('mousemove', moveHandler);
      }
    };
    const mouseDownHandler = (event) => {
      window.startCoordinates = event.pageX;
      window.startScroll = containerRef.current.scrollLeft;
      isScrolling.current = true;
      document.addEventListener('mousemove', moveHandler);
    };
    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', moveHandler);
    };

    if (containerRef && containerRef.current && !containerRefIsSet) {
      setContainerRefIsSet(true);
      setContainerElement(containerRef.current);

      containerRef.current.addEventListener('mousedown', mouseDownHandler);
      containerRef.current.addEventListener('mouseup', mouseUpHandler);
    }
    return () => {
      if (containerElement) {
        containerElement.removeEventListener('mousedown', mouseDownHandler);
        containerElement.removeEventListener('mouseup', mouseUpHandler);
      }
    };
  }, [containerRef, containerElement, containerRefIsSet]);

  const stopTouchScroll = useCallback(() => {
    isScrolling.current = false;
  }, []);

  return stopTouchScroll;
};
