import QRCode from 'qrcode.react';
import React from 'react';

import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { MAGIC_LOGIN_URL } from 'config/urls';
import { copyToClipboard } from 'utils';

import { Typography } from 'pages/Students/shared';
import { colors } from 'theme/palette';
import { reverse } from 'utils/urls';

import Button from 'components/Button';
import Dialog from 'components/Dialog';

import qrCodeGif from '../../assets/qr-code.gif';
import styles from './styles.module.scss';

export const MagicLoginDialog = ({ token, closeDialog }) => {
  const redirectUrl =
    process.env.REACT_APP_BASE_FRONTEND_URL +
    reverse(MAGIC_LOGIN_URL, { token });

  return (
    <Dialog
      open
      showClose={false}
      onClose={closeDialog}
      classes={{ paper: styles.dialogPaper }}
    >
      <div className={styles.content}>
        <Typography
          variant="H-TEXT-2"
          color={colors.pink1}
          align="center"
          className={styles.title}
        >
          Scan QR code with your phone
        </Typography>
        <QRCode
          className={styles.qrCode}
          value={redirectUrl}
          imageSettings={{ src: '', width: 130, height: 120 }}
        />
        <img src={qrCodeGif} alt="QR Code gif" className={styles.gif} />
        <Button
          fullWidth
          variant="medium"
          color="lightGrey"
          className={styles.copyButton}
          startIcon={<FileCopyRoundedIcon className={styles.copyButtonIcon} />}
          onClick={() => copyToClipboard(redirectUrl)}
        >
          COPY LINK
        </Button>
        <Button fullWidth variant="medium" color="blue" onClick={closeDialog}>
          DONE
        </Button>
      </div>
    </Dialog>
  );
};
