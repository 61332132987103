import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Skeleton from '@material-ui/lab/Skeleton';
import cx from 'classnames';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import { ReactComponent as ViewIcon } from './assets/view.svg';
import styles from './styles.module.scss';

const BulkUploadGroup = ({
  disabled,
  bulkUploadGroup,
  onClick,
  showSkeleton
}) => {
  const pageCount = bulkUploadGroup.bulk_upload_images.length;
  const bulkUploadImage = bulkUploadGroup.bulk_upload_images[0];

  if (!bulkUploadImage) {
    return null;
  }

  return (
    <>
      <div
        key={bulkUploadImage.id}
        className={cx(styles.container, { [styles.disabled]: disabled })}
        onClick={disabled ? undefined : () => onClick()}
      >
        <div className={styles.header}>
          <div>
            <Typography variant="B-TEXT-4" color={colors.white}>
              ({pageCount}) Pages
            </Typography>
            <SvgIcon component={ViewIcon} />
          </div>
        </div>
        <img src={bulkUploadImage?.file_url} alt="bulk upload preview" />

        <div className={styles.buttonContainer}>
          <div className={styles.button}>
            <Typography variant="B-TEXT-4">
              {bulkUploadGroup.student
                ? bulkUploadGroup.student.name
                : 'Select student'}
            </Typography>
            <SvgIcon component={BorderColorIcon} />
          </div>
        </div>
      </div>

      {showSkeleton && <Skeleton height={170} width={250} variant="rect" />}
    </>
  );
};

export default BulkUploadGroup;
