import React, { memo, useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SvgIcon from '@material-ui/core/SvgIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import { STUDENTS_ASSIGNMENTS_DETAIL_URL } from 'config/urls';
import { ReactComponent as AddCommentIcon } from 'images/add-comment.svg';
import { ReactComponent as DefaultAddCommentIcon } from 'images/default-add-comment.svg';
import { ReactComponent as HideCommentsIcon } from 'images/hide-comments.svg';
import _ from 'lodash';
import { addAssignmentComment } from 'sdk';

import { AssignmentStatusIcon } from 'pages/Students/shared/AssignmentStatus';
import { colors } from 'theme/palette';
import { useWorkCard } from 'utils/hooks';
import { reverse, stringifyParams } from 'utils/urls';

import AssignmentTaskValueTypeIcon from 'components/AssignmentTaskValueTypeIcon';
import PaginatedStudentWorkTasksButtons from 'components/PaginatedStudentWorkTasksButtons';
import StudentWorkCardComments from 'components/StudentWorkCardComments';
import StudentWorkTasksButtons from 'components/StudentWorkTasksButtons';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';
import WorkCardImage from 'components/WorkCardImage';

import styles from './styles.module.scss';

const Placeholder = ({ count = 1 }) =>
  _.range(count).map((index) => (
    <div className={styles.container} key={index}>
      <Skeleton height={200} variant="rect" />

      <div className={styles.actionRow}>
        <Grid container alignItems="center">
          <Grid item>
            <Skeleton width={83} height={24} />
          </Grid>
        </Grid>

        <div />

        <div className={styles.taskButtons} />
      </div>

      <Typography variant="B-Text-1" className={styles.name}>
        <Skeleton width={200} />
      </Typography>

      <div className={styles.taskInfoContainer}>
        <Grid container className={styles.spaceBetween}>
          <Grid item>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              <Skeleton width={150} />
            </Typography>
          </Grid>
        </Grid>
        {_.range(3).map((index) => (
          <div key={index} className={styles.criteria}>
            <Typography variant="B-Text-3" color={colors.grey2}>
              <Skeleton width={50} />
            </Typography>
            <Typography
              variant="S-TEXT-2"
              color={colors.blue4}
              className={styles.criteriaStatus}
            >
              <Skeleton width={30} />
            </Typography>
          </div>
        ))}
      </div>
    </div>
  ));

const StudentWorkCard = ({
  assignment: initialAssignmentData,
  postHeightChangeAction = () => {}
}) => {
  const { user } = useContext(UserContext);
  const studentId = user.student_id;

  const history = useHistory();

  const {
    assignment,
    setAssignment,
    showComments,
    setShowComments,
    tasksButtonsElements,
    hasComments,
    isEmptyWork,
    activeTask,
    activeWork,
    assignmentTasks,
    activeTaskWork,
    activeTaskIndex,
    setActiveTaskIndex,
    activeWorkIndex,
    setActiveWorkIndex,
    paginatedTasksStart,
    handleRightArrowClick,
    handleLeftArrowClick,
    showActiveWorksDropdown,
    showPaginatedTaskButtons,
    showAssignmentTasksButtons
  } = useWorkCard({ assignment: initialAssignmentData });

  const handleCommentAdd = useCallback(
    ({ value }) => {
      if (value === '') {
        return;
      }

      addAssignmentComment({
        studentId,
        assignmentId: activeTask.id,
        data: { text: value }
      }).then(({ data }) => {
        setAssignment((prevData) =>
          _.update(
            _.clone(prevData),
            `tasks.${activeTaskIndex}.comments`,
            (oldValue) => [...oldValue, data]
          )
        );
      });
    },
    [studentId, activeTask.id, activeTaskIndex, setAssignment]
  );

  useEffect(() => {
    postHeightChangeAction();
  }, [showComments, activeTaskIndex, activeWorkIndex, postHeightChangeAction]);

  const goToTaskDetail = () => {
    history.push({
      pathname: reverse(STUDENTS_ASSIGNMENTS_DETAIL_URL, {
        assignmentId: assignment.id
      }),
      search: stringifyParams({
        task: activeTask.id,
        camera: false,
        backUrl: history.location.pathname
      })
    });
  };

  return (
    <div className={styles.container}>
      <WorkCardImage
        isEmptyWork={isEmptyWork}
        onClick={goToTaskDetail}
        activeWork={activeWork}
        activeWorkIndex={activeWorkIndex}
        activeTaskIndex={activeTaskIndex}
        assignmentTasks={assignmentTasks}
        activeTaskWork={activeTaskWork}
        onLeftArrowClick={handleLeftArrowClick}
        onTopArrowClick={() => setActiveWorkIndex((prevIndex) => prevIndex - 1)}
        onBottomArrowClick={() =>
          setActiveWorkIndex((prevIndex) => prevIndex + 1)
        }
        onRightArrowClick={handleRightArrowClick}
      />

      <div className={styles.actionRow}>
        <Grid container alignItems="center" className={styles.icons}>
          <Grid item>
            <AssignmentStatusIcon status={assignment.overall_status} />
          </Grid>

          {showComments ? (
            <Grid item>
              <SvgIcon
                component={HideCommentsIcon}
                className={styles.messageIcon}
                onClick={() => setShowComments(false)}
              />
            </Grid>
          ) : (
            <Grid item>
              <SvgIcon
                component={hasComments ? AddCommentIcon : DefaultAddCommentIcon}
                className={styles.messageIcon}
                onClick={() => setShowComments(true)}
              />
            </Grid>
          )}
        </Grid>

        <div className={styles.taskButtons}>
          {showPaginatedTaskButtons && (
            <PaginatedStudentWorkTasksButtons
              elements={tasksButtonsElements}
              onChange={(element) => {
                setActiveTaskIndex(element.value);
                setActiveWorkIndex(0);
              }}
              onLeftArrowClick={handleLeftArrowClick}
              onRightArrowClick={handleRightArrowClick}
              firstElementIndex={paginatedTasksStart}
              elementsCount={assignmentTasks.length}
            />
          )}
          {showAssignmentTasksButtons && (
            <StudentWorkTasksButtons
              elements={tasksButtonsElements}
              onChange={(element) => {
                setActiveTaskIndex(element.value);
                setActiveWorkIndex(0);
              }}
            />
          )}
        </div>
        <div className={styles.selectWork}>
          {showActiveWorksDropdown && (
            <FormControl>
              <Select
                className={styles.selectField}
                classes={{ root: styles.selectFieldRoot }}
                value={activeWorkIndex}
                onChange={(e, element) => {
                  setActiveWorkIndex(element.props.value);
                }}
              >
                {activeTask.work.map((workItem, index) => {
                  if (workItem.is_feedback) {
                    return (
                      <MenuItem key={index} value={index}>
                        Feedback
                      </MenuItem>
                    );
                  }

                  if (workItem.is_revision) {
                    return (
                      <MenuItem key={index} value={index}>
                        Revision
                      </MenuItem>
                    );
                  }

                  return (
                    <MenuItem key={index} value={index}>
                      Original
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </div>
      </div>

      <Typography
        variant="B-Text-1"
        className={styles.name}
        onClick={goToTaskDetail}
      >
        {assignment.name}
      </Typography>

      <div className={styles.taskInfoContainer}>
        <Grid container className={styles.spaceBetween}>
          <Grid item>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              {activeTask.task_index + 1}. {activeTask.name}
            </Typography>
          </Grid>

          {!_.isNull(activeTask.value) && (
            <Grid item>
              <div className={styles.taskScore}>
                <AssignmentTaskValueTypeIcon
                  task={activeTask}
                  color={colors.blue4}
                />
              </div>
            </Grid>
          )}
        </Grid>
        {_.get(activeTask, 'criteria', []).map((criteria, index) => (
          <div key={index} className={styles.criteria}>
            <Typography variant="B-Text-3" color={colors.grey2}>
              {criteria.name}
            </Typography>
            <Typography
              variant="S-TEXT-2"
              color={colors.blue4}
              className={styles.criteriaStatus}
            >
              {criteria.value ? 'Yes' : 'No'}
            </Typography>
          </div>
        ))}
      </div>

      {showComments && (
        <StudentWorkCardComments
          comments={activeTask.comments}
          onSubmit={({ value }) =>
            handleCommentAdd({
              value
            })
          }
        />
      )}
    </div>
  );
};

const MemoizedStudentWorkCard = memo(
  StudentWorkCard,
  (prevProps, nextProps) => prevProps.assignment.id === nextProps.assignment.id
);

MemoizedStudentWorkCard.Placeholder = Placeholder;

export default MemoizedStudentWorkCard;
