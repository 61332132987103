import { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

export const usePaginatedTeamMeetings = ({
  action,
  teamId,
  search = '',
  limit = 10
}) => {
  const [page, setPage] = useState(0);
  const [resultsCount, setResultsCount] = useState();

  const {
    data: meetingsData,
    isLoading,
    refetch
  } = action({
    teamId,
    params: { offset: page * limit, limit, search }
  });

  useEffect(() => {
    if (!resultsCount) {
      setResultsCount(meetingsData?.count);
    }
  }, [meetingsData, resultsCount]);

  const pageCount = useMemo(() => {
    if (!isLoading) {
      const count = meetingsData.count;
      const limit = meetingsData.limit;

      if (count !== 0 && limit !== 0) {
        return _.ceil(count / limit);
      }
      return 0;
    }
  }, [meetingsData?.limit, meetingsData?.count, isLoading]);

  const handleMeetingsRefetch = () => {
    setResultsCount(0);
    if (page === 0) {
      refetch();
    } else {
      setPage(0);
    }
  };

  // This is needed for the case when we trigger pre-render by setting page to -1
  useEffect(() => {
    if (page < 0) {
      setPage(0);
    }
  }, [page]);

  return [
    meetingsData?.results,
    isLoading,
    page,
    setPage,
    pageCount,
    handleMeetingsRefetch
  ];
};
