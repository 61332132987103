import _ from 'lodash';

export const getVisibleStandards = ({ assignment, visibleTaskIds }) => {
  let assignmentTasks = assignment.objectives;

  if (!_.isEmpty(visibleTaskIds)) {
    assignmentTasks = assignmentTasks.filter((task) =>
      visibleTaskIds.includes(task.id)
    );
  }

  return _.uniqBy(_.flatMap(assignmentTasks, 'standards'), 'id');
};
