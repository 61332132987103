import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

import { buildFilters } from 'utils/filters';
import { notifyErrors } from 'utils/notifications';
import { useFetch } from 'utils/sdk';

import { getTeamMeetings, getUserTeams } from './sdk';

export const useTeams = (teamSearchValue) => {
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const requestData = useMemo(
    () => buildFilters({ search: teamSearchValue }),
    [teamSearchValue]
  );

  const {
    data: { results: teamsResults }
  } = useFetch(getUserTeams, { data: [] }, requestData);

  useEffect(() => {
    if (!_.isNull(teamsResults)) {
      setTeams(teamsResults);
    }
  }, [teamsResults, teamSearchValue]);

  return { teams, setTeams, selectedTeam, setSelectedTeam };
};

export const useMeetings = (team) => {
  const [meetings, setMeetings] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  const fetchTeamMeetings = useCallback(async (teamId) => {
    const { success, data, errors } = await getTeamMeetings(teamId);

    if (success) {
      setMeetings(data);
    } else {
      notifyErrors(errors);
    }
  }, []);

  useEffect(() => {
    if (!_.isNull(team)) {
      fetchTeamMeetings(team.id);
    }
  }, [team, fetchTeamMeetings]);

  return { meetings, setMeetings, selectedMeeting, setSelectedMeeting };
};

const buildStudentNotes = (students, studentsNotes) =>
  _.reduce(
    students,
    (result, student) => ({
      ...result,
      [student.id]: _.get(studentsNotes, student.id, '')
    }),
    {}
  );

export const useStudentNotes = (students) => {
  const [studentsNotes, setStudentsNotes] = useState({});

  const setStudentNotes = useCallback(
    (studentId, text) => {
      setStudentsNotes((prevStudentsNotes) => {
        const notes = buildStudentNotes(students, prevStudentsNotes);
        notes[studentId] = text;
        return notes;
      });
    },
    [students]
  );

  return { studentsNotes, setStudentNotes };
};

export const useAttachments = () => {
  const [attachments, setAttachments] = useState([]);

  const areAttachmentsUploading = useMemo(
    () => !_.every(attachments, 'uploaded'),
    [attachments]
  );

  return { attachments, setAttachments, areAttachmentsUploading };
};
