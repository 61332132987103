import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import { TopBarSelect } from 'pages/Teachers/shared';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { parseParams, stringifyParams } from 'utils/urls';

import UserContext from 'components/UserContext';

const SectionSelectDropdown = ({
  selectedSection,
  setSelectedSection,
  setSelectedGrade,
  setSelectedUnit,
  setSelectedLesson,
  className,
  sections
}) => {
  const { user } = useContext(UserContext);

  const history = useHistory();
  const urlParams = parseParams(history.location.search, {
    parseNumbers: true
  });
  const sectionId = _.get(urlParams, 'sectionId');

  useEffect(() => {
    if (!_.isEmpty(sections)) {
      const section = sections.find((section) => section.id === sectionId);
      if (section) {
        setSelectedSection(section);
      } else {
        const defaultsection = sections.find((section) => section.is_default);
        setSelectedSection(defaultsection);

        delete urlParams.sectionId;
        urlParams.sectionId = defaultsection.id;
        history.replace({ search: stringifyParams(urlParams) });
      }
    } else {
      setSelectedSection(null);

      delete urlParams.sectionId;
      delete urlParams.grade;
      delete urlParams.unit;
      delete urlParams.lesson;
      history.replace({ search: stringifyParams(urlParams) });
    }
  }, [sections, sectionId, setSelectedSection, history, urlParams]);

  const selectSection = (newSection) => {
    setSelectedLesson(null);
    setSelectedUnit(null);
    setSelectedGrade(null);
    setSelectedSection(newSection);

    urlParams.sectionId = newSection.id;
    history.replace({ search: stringifyParams(urlParams) });

    trackMixpanelEvent(
      user.id,
      '[StrategiesOverview] Teacher changed section.',
      {
        section: newSection.name
      }
    );
  };

  return (
    <TopBarSelect
      value={selectedSection}
      renderValue={(section) => _.get(section, 'name')}
      onChange={selectSection}
      options={sections.map((section) => ({
        value: section,
        label: section.name
      }))}
      className={className}
    />
  );
};

export default SectionSelectDropdown;
