import React from 'react';

const SemiCircleProgressBar = ({
  percentage,
  diameter,
  primaryColor = '#27b272',
  secondaryColor = '#b6f2ca'
}) => {
  const radius = diameter / 2;
  const perimeter = 2 * Math.PI * radius;
  const progressBarLength = perimeter - (perimeter / 2) * (percentage / 100);

  return (
    <div style={{ width: diameter, height: radius, overflow: 'hidden' }}>
      <svg
        width={diameter}
        height={diameter}
        viewBox={`-25 -30 ${diameter + 50} ${diameter + 50}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: 'rotate(180deg)' }}
      >
        <circle
          r={radius}
          cx={radius}
          cy={radius}
          fill="transparent"
          stroke={secondaryColor}
          strokeWidth="10px"
          strokeDasharray={perimeter}
          strokeLinecap="round"
        ></circle>
        <circle
          r={radius}
          cx={radius}
          cy={radius}
          stroke={primaryColor}
          strokeWidth="10px"
          strokeLinecap="round"
          strokeDashoffset={progressBarLength}
          fill="transparent"
          strokeDasharray={perimeter}
        ></circle>
      </svg>
    </div>
  );
};

export default SemiCircleProgressBar;
