import React, { forwardRef, memo } from 'react';

import MuiTypography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles({
  'H-TEXT-1': {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 700,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'H-TEXT-2': {
    fontSize: '17px',
    lineHeight: 'initial',
    fontWeight: 600,
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'H-TEXT-3': {
    fontSize: '15px',
    lineHeight: 'initial',
    fontWeight: 600,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'B-Text-1': {
    fontSize: '17px',
    lineHeight: '20px',
    fontWeight: 400,
    letterSpacing: '0px',
    fontFamily: 'Nunito'
  },
  'B-Text-2': {
    fontSize: '15px',
    lineHeight: 'initial',
    fontWeight: 400,
    letterSpacing: '0px',
    fontFamily: 'Nunito'
  },
  'B-Text-3': {
    fontSize: '13px',
    lineHeight: 'initial',
    fontWeight: 400,
    letterSpacing: '0px',
    fontFamily: 'Nunito'
  },
  'B-Text-4': {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    fontFamily: 'Nunito'
  },
  'S-TEXT-1': {
    fontSize: '13px',
    lineHeight: 'initial',
    fontWeight: 600,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'S-TEXT-2': {
    fontSize: '12px',
    lineHeight: 'initial',
    fontWeight: 700,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  'S-TEXT-3': {
    fontSize: '11px',
    lineHeight: 'initial',
    fontWeight: 600,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  // Students typography only
  'S-TEXT-4': {
    fontSize: '10px',
    lineHeight: 'initial',
    fontWeight: 600,
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  },
  default: {}
});

const Typography = ({ variant, color, children, className, ...props }, ref) => {
  const classes = useStyles();

  return (
    <MuiTypography
      ref={ref}
      className={classnames(className, classes[variant])}
      style={{ color }}
      {...props}
    >
      {children}
    </MuiTypography>
  );
};

export default memo(forwardRef(Typography));
