import React from 'react';

import {
  MISCONCEPTIONS_TAB,
  MISCONCEPTIONS_TAB_INTERCOM_TARGET,
  OVERALL_RESPONSE_TAB,
  OVERALL_RESPONSE_TAB_INTERCOM_TARGET
} from 'pages/Teachers/InsightsV2/constants';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const TabSwitcher = ({ activeTab, setActiveTab }) => {
  const setClassName = (currentTab) => {
    return `${styles.tab} ${activeTab === currentTab ? styles.active : ''}`;
  };

  return (
    <div className={styles.tabContainer}>
      <div
        className={setClassName(OVERALL_RESPONSE_TAB)}
        onClick={() => setActiveTab(OVERALL_RESPONSE_TAB)}
        data-intercom-target={OVERALL_RESPONSE_TAB_INTERCOM_TARGET}
      >
        <Typography variant="H-TEXT-2" className={styles.header}>
          {OVERALL_RESPONSE_TAB}
        </Typography>
      </div>

      <div
        className={setClassName(MISCONCEPTIONS_TAB)}
        onClick={() => setActiveTab(MISCONCEPTIONS_TAB)}
        data-intercom-target={MISCONCEPTIONS_TAB_INTERCOM_TARGET}
      >
        <Typography variant="H-TEXT-2" className={styles.header}>
          {MISCONCEPTIONS_TAB}
        </Typography>
      </div>
    </div>
  );
};

export default TabSwitcher;
