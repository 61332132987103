import * as Sentry from '@sentry/react';

import { Integrations } from '@sentry/tracing';
import { history } from 'config/routes';

import { Environments } from 'utils/constants';

// List of common ignore rules. Source:
// https://docs.sentry.io/platforms/javascript/guides/react/config/filter/#decluttering-sentry
// https://gist.github.com/impressiver/5092952
const IGNORE_ERRORS = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  'ResizeObserver loop limit exceeded',
  // Google Translate extension error
  // See: https://medium.com/@amir.harel/a-b-target-classname-indexof-is-not-a-function-at-least-not-mine-8e52f7be64ca
  'a[b].target.className.indexOf is not a function',
  // ---- Network errors messages ------
  // Source: https://github.com/getsentry/sentry/issues/12676#issuecomment-533538114
  'Network request failed',
  'Failed to fetch',
  'NetworkError',
  'withrealtime/messaging',
  //  -------- Invalid errors ----------
  'Non-Error exception captured'
];

const DENY_URLS = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
];

export function initSentry() {
  const currentEnv = process.env.REACT_APP_ENV_NAME;

  if (currentEnv !== Environments.DEV) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      ignoreErrors: IGNORE_ERRORS,
      denyUrls: DENY_URLS,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
          tracingOrigins: [
            `https://${process.env.REACT_APP_BUCKET_NAME}.s3.us-east-2.amazonaws.com.*`,
            `${process.env.REACT_APP_BASE_BACKEND_URL}.*`,
            'https://calendly.com/edlight.*',
            'https://classroom.google.com.*'
          ]
        })
      ],
      tracesSampleRate: 1,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'dev'
    });
  }
}
