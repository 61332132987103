import React, { useEffect, useMemo, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import MuiTooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { get, isNull } from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useStudentConceptMasteryData } from 'sdk';
import { parsePercentage } from 'utils';

import { ReactComponent as MATHIcon } from 'pages/Teachers/StudentPortfolio/components/StandardTabs/assets/math.svg';
import { colors } from 'theme/palette';

import DiagramLegend from './components/DiagramLegend';
import DiagramTooltip from './components/DiagramTooltip';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const BAR_COLORS = ['#CC2364', '#333966'];
const BAR_GAP = 10;
const BAR_WIDTH = 30;
const CATEGORY_WIDTH = 100;
const INITIAL_CATEGORY_WIDTH = 70;
const DIAGRAM_HEIGHT = 500;

const ConceptMastery = ({
  width,
  studentId,
  studentName,
  selectedYear,
  selectedMonthsBack,
  setExportData
}) => {
  const { data, isLoading } = useStudentConceptMasteryData({
    studentId,
    params: {
      year: get(selectedYear, 'value'),
      months_back:
        get(selectedMonthsBack, 'value') === 0
          ? null
          : get(selectedMonthsBack, 'value')
    }
  });

  const [innerChartWidth, setInnerChartWidth] = useState(0);

  const conceptMasteryData = useMemo(() => {
    if (isLoading) return null;
    setExportData(data);

    const parsedData = data.map((item) => ({
      ...item,
      student_concept_mastery_percentage: parsePercentage({
        firstNumber: item.student_concepts_mastered,
        secondNumber: item.total_concepts
      }),
      class_concept_mastery_percentages: parsePercentage({
        firstNumber: item.class_average_concepts_mastered,
        secondNumber: item.total_concepts
      })
    }));
    return parsedData;
  }, [data, isLoading, setExportData]);

  useEffect(() => {
    if (isNull(conceptMasteryData)) return;

    const dataLength = conceptMasteryData.length;
    const calculatedWidth =
      dataLength * CATEGORY_WIDTH + INITIAL_CATEGORY_WIDTH;

    setInnerChartWidth(calculatedWidth);
  }, [conceptMasteryData]);

  return (
    <div className={styles.comparisonChartContainer} style={{ width: width }}>
      <div className={styles.chartTitleSection}>
        <div className={styles.chartSubTitleSection} style={{ width: width }}>
          <div className={styles.chartSubTitleSectionContainer}>
            <Typography variant="H-TEXT-1" className={styles.barSubTitle}>
              Concept Mastery
            </Typography>

            <MuiTooltip
              classes={{
                tooltip: styles.tooltipInfo,
                arrow: styles.tooltipArrow
              }}
              title={
                <Typography
                  variant="B-Text-3"
                  component="h5"
                  classes={{ root: styles.tooltipText }}
                >
                  Concept is mastered when work is marked Complete and Correct
                </Typography>
              }
              placement="top-start"
              arrow={true}
            >
              <HelpIcon className={styles.infoIcon} fontSize="small" />
            </MuiTooltip>

            <div className={styles.standardContainer}>
              <MATHIcon
                fill={colors.greyDark}
                className={styles.standardIcon}
              />
              <Typography variant="H-TEXT-3">MATH</Typography>
            </div>

            <div className={styles.spacer}></div>

            <DiagramLegend studentName={studentName} />
          </div>
        </div>
      </div>

      {isLoading && (
        <div className={styles.loader}>
          <CircularProgress size={60} />
        </div>
      )}

      {!isLoading && (
        <div className={styles.chartWrapperOuterContainer}>
          <ResponsiveContainer width={innerChartWidth} height={DIAGRAM_HEIGHT}>
            <BarChart
              data={conceptMasteryData}
              barGap={BAR_GAP}
              margin={{ top: 5, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                height={70} // Adjust the height to fit multiline labels
                interval={0}
              />
              <YAxis
                type="number"
                domain={[0, 100]}
                tickFormatter={(tick) => `${tick}%`}
              />
              <Tooltip content={<DiagramTooltip studentName={studentName} />} />

              <Bar
                dataKey="student_concept_mastery_percentage"
                fill={BAR_COLORS[0]}
                background={{ fill: '#eee' }}
                barSize={BAR_WIDTH}
              />
              <Bar
                dataKey="class_concept_mastery_percentages"
                fill={BAR_COLORS[1]}
                background={{ fill: '#eee' }}
                barSize={BAR_WIDTH}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default ConceptMastery;
