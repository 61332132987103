import React, { useContext } from 'react';

import Drawer from '@material-ui/core/Drawer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { isEmpty } from 'lodash';
import { useSectionGrades, useTeacherSectionsForSchool } from 'sdk';

import LessonSelectDropdown from 'pages/Teachers/StrategiesOverview/components/LessonSelectDropdown';
import UnitSelectDropdown from 'pages/Teachers/StrategiesOverview/components/UnitSelectDropdown';

import GradeSelectDropdown from './components/GradeSelectDropdown';
import SectionSelectDropdown from './components/SectionSelectDropdown';
import Typography from 'components/Typography';

import AssignmentSelectorContext from '../AssignmentSelectorContext';
import styles from './styles.module.scss';

const RightDrawer = ({ open, onClose, drawerVisible }) => {
  const {
    selectedSection,
    selectedSchool,
    selectedYear,
    selectedGrade,
    selectedUnit,
    selectedLesson,
    setSelectedSection,
    setSelectedGrade,
    setSelectedUnit,
    setSelectedLesson
  } = useContext(AssignmentSelectorContext);

  const { data: grades } = useSectionGrades({
    sectionId: selectedSection?.id
  });

  const { data: sections } = useTeacherSectionsForSchool({
    schoolId: selectedSchool?.id,
    yearId: selectedYear?.value
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      style={drawerVisible ? {} : { visibility: 'hidden' }}
    >
      <div className={styles.content} role="presentation">
        <div className={styles.dialogTitle}>
          <div className={styles.arrowIconContainer}>
            <ArrowBackIcon className={styles.arrow} onClick={onClose} />
          </div>
          <div>
            <Typography
              className={styles.title}
              variant="B-Text-3"
              component="h5"
            >
              SELECT ASSIGNMENT
            </Typography>
          </div>
        </div>
        <div className={styles.selectBoxes}>
          <div>
            {selectedYear && !isEmpty(sections) && (
              <SectionSelectDropdown
                selectedSection={selectedSection}
                setSelectedSection={setSelectedSection}
                setSelectedGrade={setSelectedGrade}
                setSelectedUnit={setSelectedUnit}
                setSelectedLesson={setSelectedLesson}
                className={styles.drawerSelect}
                sections={sections}
              />
            )}
          </div>
          <div>
            {selectedSection && !isEmpty(grades) && (
              <GradeSelectDropdown
                selectedGrade={selectedGrade}
                setSelectedGrade={setSelectedGrade}
                setSelectedUnit={setSelectedUnit}
                setSelectedLesson={setSelectedLesson}
                className={styles.drawerSelect}
                grades={grades}
              />
            )}
          </div>
          <div>
            {selectedSection && selectedGrade && (
              <UnitSelectDropdown
                selectedUnit={selectedUnit}
                selectedSection={selectedSection}
                selectedGrade={selectedGrade}
                setSelectedUnit={setSelectedUnit}
                setSelectedLesson={setSelectedLesson}
                className={styles.drawerSelect}
              />
            )}
          </div>
          <div>
            {selectedUnit && (
              <LessonSelectDropdown
                selectedLesson={selectedLesson}
                selectedSection={selectedSection}
                selectedGrade={selectedGrade}
                selectedUnit={selectedUnit}
                setSelectedLesson={setSelectedLesson}
                className={styles.drawerSelect}
              />
            )}
          </div>
          <div className={styles.spacer}></div>
        </div>
      </div>
    </Drawer>
  );
};

export default RightDrawer;
