import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { useNextStepsPanelDetails } from 'sdk';

import StudentWorkViewDialog from 'pages/MisconceptionsDashboard/components/StudentWorkViewDialog';
import { NextStepsTableHeader } from 'pages/Teachers/SearchableAssignments/components/NextStepsPanel/components/NextStepsTableHeader';
import { NextStepsTableRow } from 'pages/Teachers/SearchableAssignments/components/NextStepsPanel/components/NextStepsTableRow';
import { NextStepsTableSkeletonRow } from 'pages/Teachers/SearchableAssignments/components/NextStepsPanel/components/NextStepsTableSkeletonRow';

import styles from './styles.module.scss';

export const NextStepsTable = ({
  userSections,
  filters,
  trackNextStepCompleteEvent
}) => {
  const [selectedWork, setSelectedWork] = useState(null);
  const [studentWorks, setStudentWorks] = useState([]);
  const [isWorkDialogOpened, setIsWorkDialogOpened] = useState(false);
  let sectionId;

  for (let i = 0; i < userSections?.length; i++) {
    if (userSections[i].name === filters.section) {
      sectionId = userSections[i].id;
      break;
    }
  }
  const {
    data: rows,
    isLoading: isLoadingNextSteps,
    mutate: mutateNextStepsRows
  } = useNextStepsPanelDetails({
    params: sectionId ? { section_id: sectionId } : {}
  });

  return (
    <>
      {isWorkDialogOpened && (
        <StudentWorkViewDialog
          studentWork={selectedWork}
          setSelectedStudentWork={setSelectedWork}
          studentWorks={studentWorks}
          onClose={() => setIsWorkDialogOpened(false)}
        />
      )}
      <Table aria-label="next steps table" className={styles.nextStepsTable}>
        <NextStepsTableHeader />
        {!isLoadingNextSteps && (
          <TableBody>
            {rows.map((row) => (
              <NextStepsTableRow
                row={row}
                key={row.assignment_id}
                setSelectedWork={setSelectedWork}
                setStudentWorks={setStudentWorks}
                setIsWorkDialogOpened={setIsWorkDialogOpened}
                trackNextStepCompleteEvent={trackNextStepCompleteEvent}
                mutateNextStepsRows={mutateNextStepsRows}
              />
            ))}
          </TableBody>
        )}
        {isLoadingNextSteps && (
          <TableBody>
            <NextStepsTableSkeletonRow />
          </TableBody>
        )}
      </Table>
    </>
  );
};
