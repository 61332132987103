import React from 'react';

import styles from './styles.module.scss';

class Timer extends React.Component {
  state = {
    seconds: null
  };

  componentDidMount() {
    this.setState({ seconds: this.props.seconds }, this.tick);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  tick() {
    const { onFinish } = this.props;
    const { seconds } = this.state;

    if (seconds <= 0) {
      return;
    }

    this.timeout = setTimeout(() => {
      let nextCallback = this.tick;

      if (seconds - 1 === 0) {
        nextCallback = onFinish;
      }

      this.setState((prevState) => {
        const { seconds } = prevState;
        return { seconds: seconds - 1 };
      }, nextCallback);
    }, 1000);
  }
  render() {
    const { seconds } = this.state;

    return <div className={styles.timer}>{seconds}</div>;
  }
}

export default Timer;
