import React from 'react';

import { makeStyles } from '@material-ui/core';

import { colors } from 'theme/palette';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    margin: '0 auto'
  },
  border: {
    borderBottom: `1px solid ${colors.grey5}`,
    width: '100%'
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontWeight: 600,
    fontSize: 13,
    color: colors.grey5,
    margin: 'var(--offset-sm) 0px'
  }
}));

const DividerWithText = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
};
export default DividerWithText;
