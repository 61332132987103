import React, { useEffect, useMemo } from 'react';

import { CircularProgress } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { usePathwaysMiniLessonProgress } from 'sdk';

import BottomNavigation from 'pages/Pathways/components/BottomNavigation';
import { withNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';

import ArcProgressDisplay from './components/ArcProgressDisplay';
import LessonList from './components/LessonList';
import Typography from 'components/Typography';

import AskEmberButton from '../AskEmberButton/index';
import styles from './styles.module.scss';

const PathwaysProgress = ({ navigation }) => {
  const userTimezone = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    []
  );

  const { data, isLoading } = usePathwaysMiniLessonProgress({
    timezone: userTimezone
  });

  useEffect(() => {
    navigation.setLeftSubheader(
      <Typography noWrap variant="B-Text-1" className={styles.navigationTitle}>
        Today's Progress
      </Typography>
    );
  }, [navigation]);

  if (isLoading) {
    return (
      <div className={styles.progress}>
        <CircularProgress size={60} />
      </div>
    );
  }

  const { progress_percentage: percentage, mini_lessons: lessons } = data;

  return (
    <>
      <div className={styles.container}>
        <ArcProgressDisplay percentage={percentage} />
        <Typography
          variant="H-TEXT-1"
          color={colors.blueDark}
          classes={{ root: styles.journeyTitle }}
        >
          Your Learning Journey
        </Typography>
        <LessonList lessons={lessons} />
        <AskEmberButton />
      </div>
      <BottomNavigation />
    </>
  );
};

export default withWidth()(withNavbar(PathwaysProgress, { sticky: true }));
