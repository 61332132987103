import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';
import SchoolIcon from '@material-ui/icons/School';
import TodayIcon from '@material-ui/icons/Today';
import {
  STUDENTS_MY_PORTFOLIO_DATE_VIEW_URL,
  STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL,
  STUDENTS_MY_PORTFOLIO_INCOMPLETE_ASSIGNMENTS_VIEW_URL,
  STUDENTS_MY_PORTFOLIO_STANDARDS_VIEW_URL
} from 'config/urls';

export const URLS_WHOSE_CONTENT_IS_NOT_LIMITED_TO_THE_CENTER_PORTION_OF_THE_SCREEN =
  [
    STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL,
    STUDENTS_MY_PORTFOLIO_STANDARDS_VIEW_URL,
    STUDENTS_MY_PORTFOLIO_DATE_VIEW_URL,
    STUDENTS_MY_PORTFOLIO_INCOMPLETE_ASSIGNMENTS_VIEW_URL
  ];

export const STUDENT_PORTFOLIO_VIEW_OPTIONS = [
  {
    label: 'BY STANDARD',
    value: STUDENTS_MY_PORTFOLIO_STANDARDS_VIEW_URL,
    icon: SchoolIcon
  },
  {
    label: 'BY DATE',
    value: STUDENTS_MY_PORTFOLIO_DATE_VIEW_URL,
    icon: TodayIcon
  },
  {
    label: 'INCOMPLETE ASSIGNMENTS',
    value: STUDENTS_MY_PORTFOLIO_INCOMPLETE_ASSIGNMENTS_VIEW_URL,
    icon: AddAlertOutlinedIcon
  }
];
