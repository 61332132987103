import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { noopPromise } from 'utils';

import { useFetch } from 'utils/sdk';

import {
  getSchoolsForOrganization,
  getTeamDetail,
  getUsersForSchools
} from './sdk';

export const useTeam = (teamId) => {
  const [teamName, setTeamName] = useState('');

  const { data: team, loading } = useFetch(
    getTeamDetail,
    { data: null, loading: true },
    teamId
  );

  useEffect(() => {
    if (!_.isNil(team) && !loading) {
      setTeamName(team.name);
    }
  }, [team, loading]);

  return { team, teamName, setTeamName };
};

export const useTeamSchools = (team) => {
  const [organizationId, setOrganizationId] = useState();
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [schoolName, setSchoolName] = useState('');

  useEffect(() => {
    if (!_.isNil(team)) {
      setOrganizationId(team.organization_id);
      setSelectedSchools(team.schools);
    }
  }, [team]);

  const requestParams = useMemo(
    () => ({ name: schoolName, organizationsIds: [organizationId] }),
    [schoolName, organizationId]
  );
  const sdk = useMemo(
    () => (organizationId ? getSchoolsForOrganization : noopPromise),
    [organizationId]
  );

  const { data: schools } = useFetch(sdk, { data: [] }, requestParams);

  const addSchool = useCallback((school) => {
    setSelectedSchools((prev) => [school, ...prev]);
    setSchoolName('');
  }, []);

  return {
    schools,
    addSchool,
    schoolName,
    setSchoolName,
    selectedSchools
  };
};

export const useTeamMembers = (team, selectedSchools) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (!_.isNil(team)) {
      setSelectedUsers(_.map(team.teacher_members, 'user'));
    }
  }, [team]);

  const selectedSchoolsIds = useMemo(
    () => _.map(selectedSchools, 'id'),
    [selectedSchools]
  );
  const requestParams = useMemo(
    () => ({ name: userName, selectedSchoolsIds: selectedSchoolsIds }),
    [userName, selectedSchoolsIds]
  );

  const { data: users } = useFetch(
    getUsersForSchools,
    { data: [] },
    requestParams
  );

  const addUser = useCallback((user) => {
    setSelectedUsers((prev) => [user, ...prev]);
    setUserName('');
  }, []);
  const removeUser = useCallback(
    (user) => setSelectedUsers((prev) => _.reject(prev, { id: user.id })),
    []
  );

  return {
    users,
    addUser,
    removeUser,
    userName,
    setUserName,
    selectedUsers
  };
};
