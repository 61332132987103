import { useFetch } from 'utils/sdk';

import { getSchoolNameFromIdentifier } from './sdk';

export const useSchoolName = (schoolIdentifier) => {
  const { data: name } = useFetch(
    getSchoolNameFromIdentifier,
    { data: null },
    schoolIdentifier
  );
  return name;
};
