import { BASE_URL } from 'config/urls';

import { callUrl, get, post, requestSdk } from 'utils/sdk';

export const getTeamDetail = (teamId) =>
  callUrl(get, `${BASE_URL}/v1/meetings/teams/${teamId}`);

export const getSchoolsForOrganization = ({ name, organizationsIds }) =>
  callUrl(get, `${BASE_URL}/v1/schools/`, {
    name,
    organizations_ids: organizationsIds
  });

export const getUsersForSchools = ({ name, selectedSchoolsIds }) =>
  callUrl(get, `${BASE_URL}/v1/schools/schools-school-users/`, {
    name,
    schools_ids: selectedSchoolsIds
  });

export const updateTeacherTeam = (teamId, data) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/teams/${teamId}/update/`, data)
  );
