import { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { noopPromise } from 'utils';

import { useFetch } from 'utils/sdk';
import { parseParams, stringifyParams } from 'utils/urls';

import { fetchTeams, getUpcomingMeetings } from './sdk';

export const useUpcomingMeetings = (requestData) => {
  const result = useFetch(
    getUpcomingMeetings,
    {
      data: [],
      loading: true
    },
    requestData
  );

  return result;
};

export const useTeams = (requestData, triggerFetch) => {
  const sdk = useMemo(
    () => (triggerFetch ? fetchTeams : noopPromise),
    [triggerFetch]
  );

  const result = useFetch(
    sdk,
    {
      data: [],
      loading: true
    },
    requestData
  );

  const data = useMemo(() => {
    // If the page is not ready to perform the request we need to make sure that it's still in a loading state
    if (!triggerFetch) {
      return { ...result, loading: true };
    }
    return result;
  }, [result, triggerFetch]);

  return data;
};

export const useTeamsCopy = (teamsResponse, loading) => {
  // We copy the teams response in the state so we can implement optimistic re-render when we edit them
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    if (!loading) {
      setTeams(teamsResponse.results);
    }
  }, [teamsResponse, loading, setTeams]);

  return [teams, setTeams];
};

export const useFilters = (history) => {
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const initialPageParams = parseParams(history.location.search);
    setFilters({ search: '', ...initialPageParams });
  }, [history]);

  useEffect(() => {
    if (!_.isEmpty(filters)) {
      history.push({
        search: stringifyParams(filters)
      });
    }
  }, [filters, history]);

  return [filters, setFilters];
};
