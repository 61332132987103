import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

const StudentWorkSkeleton = ({ className, count = 3 }) => (
  <div className={className}>
    <Grid container spacing={2}>
      {_.times(count, (index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <StudentCardSkeleton />
        </Grid>
      ))}
    </Grid>
  </div>
);

export const StudentCardSkeleton = ({ className, objectivesCount = 3 }) => {
  return (
    <Card variant="outlined" className={className}>
      <CardActionArea>
        <Skeleton variant="rect" animation="wave" height={200} />
      </CardActionArea>
      <CardContent>
        <Grid container item direction="column" spacing={1}>
          <Grid item>
            <Skeleton variant="rect" height={30} />
          </Grid>
          {_.times(objectivesCount, (objectiveIndex) => (
            <Grid
              container
              item
              key={objectiveIndex}
              justifyContent="space-between"
            >
              <Grid item xs={8}>
                <Skeleton variant="rect" height={30} />
              </Grid>
              <Grid item xs={2}>
                <Skeleton variant="rect" height={30} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StudentWorkSkeleton;
