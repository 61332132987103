import React from 'react';

import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

import { NextStepsTable } from './components/NextStepsTable';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

export const NextStepsPanel = ({
  userSections,
  filters,
  trackNextStepCompleteEvent
}) => {
  const tooltipText = `Get the most out of EdLight with 
                        our AI-generated next steps suggestions 
                        on your classroom's 3 most recent assignments`;

  return (
    <Paper variant="outlined" className={styles.nextStepsPanelContainer}>
      <div className={styles.header}>
        <Typography variant="H-TEXT-1" className={styles.title}>
          Maximize Your Classroom
        </Typography>
        <Tooltip title={tooltipText} placement="top-start" arrow={true}>
          <HelpIcon fontSize="small" className={styles.helpIcon} />
        </Tooltip>
      </div>

      <NextStepsTable
        userSections={userSections}
        filters={filters}
        trackNextStepCompleteEvent={trackNextStepCompleteEvent}
      />
    </Paper>
  );
};
