import React from 'react';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const PathwaysChatBotQuery = ({ message }) => {
  return (
    <div className={styles.messageContainer}>
      <Typography variant="B-Text-3" className={styles.queryContainer}>
        {message.text}
      </Typography>
    </div>
  );
};

export default PathwaysChatBotQuery;
