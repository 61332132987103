import React, { useState } from 'react';

import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';

import InfoPanel from 'pages/Pathways/components/PathwaysDailyProgress/components/InfoPanel';

import InputField from 'components/InputField';
import Typography from 'components/Typography';

import { ReactComponent as FrownIcon } from './assets/frown.svg';
import { ReactComponent as NeutralIcon } from './assets/neutral.svg';
import { ReactComponent as SmileIcon } from './assets/smile.svg';

const HEADER_HEIGHT = 48;

const CLICKED_COLOR = '#BDC0FF';
const UNCLICKED_COLOR = 'white';
const BORDER_COLOR = '#B0B3F4';

const SMILE = 'smile';
const NEUTRAL = 'neutral';
const FROWN = 'frown';

const useStyles = makeStyles({
  headerStyle: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    '& > p': {
      lineHeight: '32px',
      fontSize: 17,
      fontWeight: 600
    }
  }),
  icon: {
    cursor: 'pointer'
  },
  contentStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    paddingTop: 32,
    fontWeight: 600
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5
  },
  input: (theme) => ({
    background: 'transparent',
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottomColor: `${BORDER_COLOR} !important`,
      borderBottomWidth: 2
    },
    '& .Mui-focused': {
      background: theme.accentColor
    },
    '&:hover': {
      background: theme.accentColor
    },
    '& .MuiInput-root': {
      borderRadius: 0,
      minHeight: theme.headerHeight
    }
  }),
  chips: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    '& .MuiChip-root': {
      backgroundColor: theme.primaryColor,
      color: theme.secondaryColor,
      borderRadius: 3
    }
  }),
  sendButton: (theme) => ({
    borderRadius: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.primaryColor,
    borderBottom: `2px solid ${BORDER_COLOR}`,
    backgroundColor: theme.primaryColor,
    '&:hover': {
      backgroundColor: '#f5f5fa'
    },
    '& svg': {
      color: BORDER_COLOR
    }
  })
});

const Header = () => <Typography>Today's self reflection</Typography>;

const HeaderButtons = ({ styles, onIconClicked }) => {
  const [smileColor, setSmileColor] = useState('white');
  const [neutralColor, setNeutralColor] = useState('white');
  const [frownColor, setFrownColor] = useState('white');

  const handleClick = (newClickedIcon) => {
    if (newClickedIcon === SMILE) {
      setSmileColor(CLICKED_COLOR);
      setNeutralColor(UNCLICKED_COLOR);
      setFrownColor(UNCLICKED_COLOR);
    }
    if (newClickedIcon === NEUTRAL) {
      setSmileColor(UNCLICKED_COLOR);
      setNeutralColor(CLICKED_COLOR);
      setFrownColor(UNCLICKED_COLOR);
    }
    if (newClickedIcon === FROWN) {
      setSmileColor(UNCLICKED_COLOR);
      setNeutralColor(UNCLICKED_COLOR);
      setFrownColor(CLICKED_COLOR);
    }

    onIconClicked(newClickedIcon);
  };

  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
        bottom: -30
      }}
    >
      <SmileIcon
        onClick={() => handleClick(SMILE)}
        fill={smileColor}
        className={styles.icon}
      />
      <NeutralIcon
        onClick={() => handleClick(NEUTRAL)}
        fill={neutralColor}
        className={styles.icon}
      />
      <FrownIcon
        onClick={() => handleClick(FROWN)}
        fill={frownColor}
        className={styles.icon}
      />
    </div>
  );
};

const DailyReflectionInput = ({ onSubmit }) => {
  const panelTheme = {
    primaryColor: '#E5E7FF',
    secondaryColor: '#292E52',
    accentColor: '#F6F5FF',
    headerHeight: HEADER_HEIGHT
  };
  const styles = useStyles(panelTheme);
  const [reflectionText, setReflectionText] = useState('');
  const [facialExpression, setFacialExpression] = useState();

  return (
    <div className={styles.infoPanelContainer}>
      <InfoPanel
        theme={panelTheme}
        header={
          <div className={styles.headerStyle}>
            <Header />
            <HeaderButtons
              styles={styles}
              onIconClicked={(facialExpression) =>
                setFacialExpression(facialExpression)
              }
            />
          </div>
        }
        content={
          <div className={styles.contentStyle}>
            <div className={styles.inputContainer}>
              <InputField
                fullWidth
                variant="underlined"
                placeholder="Share your thoughts here..."
                className={styles.input}
                multiline
                onChange={(e) => setReflectionText(e.target.value)}
                value={reflectionText}
              />
              <IconButton
                size="medium"
                title="Send"
                className={styles.sendButton}
                onClick={(e) => {
                  // add validation if facial expression is selected and text is filled in, otherwise show error
                  // max 255 characters allowed
                  onSubmit({ reflectionText, facialExpression });
                }}
              >
                <SendIcon />
              </IconButton>
            </div>
            <div className={styles.chips}>
              <Chip
                size="small"
                label="I was proud of..."
                onClick={() => setReflectionText('I was proud of...')}
              />
              <Chip
                size="small"
                label="A challenge I faced was..."
                onClick={() => setReflectionText('A challenge I faced was...')}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default DailyReflectionInput;
