import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import SvgIcon from '@material-ui/core/SvgIcon';
import { TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL } from 'config/urls';
import {
  getInsightsOverviewExportURL,
  useTrackerInstanceSectionDetail
} from 'sdk';

import MisconceptionsOverview from 'pages/Teachers/InsightsV2/components/MisconceptionsOverview';
import OverallResponseOverview from 'pages/Teachers/InsightsV2/components/OverallResponseOverview';
import {
  MISCONCEPTIONS_TAB,
  OVERALL_RESPONSE_TAB
} from 'pages/Teachers/InsightsV2/constants';
import { useNavbar } from 'pages/Teachers/shared/TeacherPageLayout';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { openInNewTab } from 'utils/urls';

import TabSwitcher from './components/TabSwitcher';
import ExportButton from 'components/ExportButton';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as MainIcon } from './assets/edlightMain.svg';
import styles from './styles.module.scss';

const InsightsV2 = ({ history }) => {
  const user = useContext(UserContext);

  const { trackerInstanceSectionId } = useParams();

  const [activeTab, setActiveTab] = useState(OVERALL_RESPONSE_TAB);

  const { data: trackerInstanceSection } = useTrackerInstanceSectionDetail({
    trackerInstanceSectionId
  });

  const handleTitleClick = useCallback(
    () => history.push(TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL),
    [history]
  );

  useNavbar({
    title: '',
    handleTitleClick,
    sticky: true,
    onBackButtonClick: handleTitleClick
  });

  const exportResults = useCallback(() => {
    openInNewTab(getInsightsOverviewExportURL({ trackerInstanceSectionId }));
  }, [trackerInstanceSectionId]);

  const handleExportClick = useCallback(() => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'insights',
        button: 'export'
      }
    );

    exportResults();
  }, [user, exportResults]);

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <Typography variant="H-TEXT-1" className={styles.headerText}>
          {trackerInstanceSection?.tracker_name} -{' '}
          {trackerInstanceSection?.section_name}
        </Typography>
        <ExportButton handleClick={handleExportClick} />
      </div>
      <div>
        {trackerInstanceSection?.standards.map((standard, index) => (
          <div key={index} className={styles.standardsContainer}>
            <SvgIcon component={MainIcon} />
            <span>
              {standard['code']} - {standard['short_description']}
            </span>
          </div>
        ))}
      </div>
      <TabSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />

      <div className={styles.detailContainer}>
        {activeTab === OVERALL_RESPONSE_TAB && (
          <OverallResponseOverview
            trackerInstanceSectionId={trackerInstanceSectionId}
          />
        )}
        {activeTab === MISCONCEPTIONS_TAB && (
          <MisconceptionsOverview
            trackerInstanceSectionId={trackerInstanceSectionId}
          />
        )}
      </div>
    </div>
  );
};

export default InsightsV2;
