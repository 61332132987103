import React, { useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import classnames from 'classnames';
import _ from 'lodash';

import {
  getPresentStudents,
  getScoreResponsesForStudents
} from 'pages/Teachers/Assignments/Results/utils';
import { colors } from 'theme/palette';
import { flattenObjectives } from 'utils/trackers';

import Flex from 'components/Flex';
import Typography from 'components/Typography';

import { ObjectiveStatistics } from './components';
import styles from './styles.module.scss';

const Objective = ({ objective, groupedStudents, groupedScoreResponses }) => {
  return (
    <Grid
      container
      item
      alignItems="center"
      spacing={2}
      className={classnames(styles.objective, {
        [styles.isChild]: objective.isChild
      })}
    >
      <Grid container item alignItems="flex-end">
        {objective.isChild ? (
          <React.Fragment>
            <Grid item xs={4}>
              <Flex flexStart className={styles.criteriaLabel}>
                <CheckCircleIcon htmlColor={colors.grey4} fontSize="small" />
                <Typography
                  variant="B-Text-1"
                  color={colors.grey1}
                  display="inline"
                  className={styles.criteriaName}
                >
                  {objective.name}
                </Typography>
              </Flex>
            </Grid>
            <Grid item xs>
              <ObjectiveStatistics
                objective={objective}
                groupedStudents={groupedStudents}
                groupedScoreResponses={groupedScoreResponses}
              />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={4}>
              <Typography variant="H-TEXT-3" color={colors.blue1}>
                {objective.name}
              </Typography>
            </Grid>
            <Grid item xs>
              <ObjectiveStatistics
                objective={objective}
                groupedStudents={groupedStudents}
                groupedScoreResponses={groupedScoreResponses}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

const ensureObjectives = (objectives, visibleTaskIds) => {
  const groupedObjectivesWithFullNames = _.map(
    objectives,
    (parentObj, index) => ({
      ...parentObj,
      name: `${index + 1}. ${parentObj.name}`
    })
  );

  const filteredObjectives = _.isEmpty(visibleTaskIds)
    ? groupedObjectivesWithFullNames
    : _.filter(groupedObjectivesWithFullNames, (parentObj) =>
        _.includes(visibleTaskIds, parentObj.id)
      );

  const flatObjectives = flattenObjectives(filteredObjectives);

  return flatObjectives;
};

const AssignmentStatistics = ({ assignment, filters }) => {
  const visibleTaskIds = useMemo(
    () => _.map(filters.tasks, 'id'),
    [filters.tasks]
  );
  const visibleStudentIds = useMemo(
    () => _.map(filters.students, 'id'),
    [filters.students]
  );

  const objectives = useMemo(
    () => ensureObjectives(assignment.objectives, visibleTaskIds),
    [assignment.objectives, visibleTaskIds]
  );

  const groupedStudents = useMemo(() => {
    const presentStudents = getPresentStudents(assignment, visibleStudentIds);
    return _.keyBy(presentStudents, 'id');
  }, [assignment, visibleStudentIds]);

  const groupedScoreResponses = useMemo(() => {
    const scoreResponses = getScoreResponsesForStudents(
      assignment,
      groupedStudents
    );
    return _.groupBy(scoreResponses, 'objective_id');
  }, [assignment, groupedStudents]);

  return (
    <Grid container alignItems="center" className={styles.objectives}>
      {_.map(objectives, (objective) => (
        <Objective
          key={objective.id}
          objective={objective}
          groupedStudents={groupedStudents}
          groupedScoreResponses={groupedScoreResponses}
        />
      ))}
    </Grid>
  );
};

export default AssignmentStatistics;
