import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const SideNavItemWrapper = ({ children, open, text }) => {
  if (open) {
    return children;
  }

  return (
    <Tooltip
      arrow
      placement="right"
      classes={{ tooltip: styles.tooltip }}
      title={<Typography variant="B-Text-2">{text}</Typography>}
    >
      {children}
    </Tooltip>
  );
};

export default React.memo(SideNavItemWrapper);
