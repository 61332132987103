import { BASE_URL } from 'config/urls';

import { post, requestSdk } from 'utils/sdk';

export const getPresignedPost = async (data) =>
  await requestSdk(() => post(`${BASE_URL}/v1/files/presigned-post/`, data));

export const markUpload = async (fileId, successfullyUploaded) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/files/${fileId}/mark-upload/`, {
      successfully_uploaded: successfullyUploaded
    })
  );
