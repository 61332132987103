import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import withWidth from '@material-ui/core/withWidth';
import { PATHWAYS_DAILY_PROGRESS_URL } from 'config/urls';
import Markdown from 'markdown-to-jsx';
import { miniLessonComplete } from 'sdk';

import BottomNavigation from 'pages/Pathways/components/BottomNavigation';
import { withNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { notifyError, notifySuccess } from 'utils/notifications';

import Button from 'components/Button';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as BackButtonIcon } from './assets/back-arrow.svg';
import { ReactComponent as NextArrow } from './assets/next-arrow.svg';
import styles from './styles.module.scss';

const PathwaysTodayLesson = ({ navigation }) => {
  const location = useLocation();
  const lesson = location.state?.lesson;
  const user = useContext(UserContext);

  const [disableCompleteButton, setDisableCompleteButton] = useState(
    !lesson || (lesson && lesson?.is_completed)
  );

  const history = useHistory();

  useEffect(() => {
    navigation.setLeftSubheader(
      <Typography noWrap variant="B-Text-1" className={styles.navigationTitle}>
        Today's Lesson
      </Typography>
    );
  }, [navigation]);

  const handleMiniLessonComplete = async () => {
    if (disableCompleteButton) return;

    const { success, error } = await miniLessonComplete({
      mini_lesson_id: lesson?.id
    });

    if (success) {
      trackMixpanelEvent(user.id, 'Form Submit', {
        feature: 'pathways',
        view: 'lesson',
        form: 'lesson',
        lesson: lesson.id
      });
      notifySuccess('Lesson marked as completed');
      setDisableCompleteButton(true);
      history.push(PATHWAYS_DAILY_PROGRESS_URL);
    } else {
      notifyError(error);
    }
  };

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.scrollableContainer}>
          <div className={styles.backButton} onClick={handleBackClick}>
            <BackButtonIcon />
          </div>
          <div className={styles.titleConteiner}>
            <Typography className={styles.title}>
              {lesson
                ? lesson.title
                : 'No lesson today — take a moment to recharge and reflect on your progress!'}
            </Typography>
          </div>

          <div className={styles.contentContainer}>
            <Typography
              variant="H-TEXT-1"
              color={colors.pink}
              className={styles.contentTitle}
            >
              {lesson ? lesson.title : ''}
            </Typography>
            <Markdown>{lesson ? lesson.content : ''}</Markdown>
          </div>
        </div>

        <div className={styles.bottomButton}>
          <Button
            disabled={disableCompleteButton}
            className={styles.submitButton}
            onClick={handleMiniLessonComplete}
            endIcon={
              !disableCompleteButton && <NextArrow className={styles.icon} />
            }
          >
            {disableCompleteButton
              ? 'Lesson Completed'
              : 'Mark Lesson as Complete'}
          </Button>
        </div>
      </div>

      <BottomNavigation />
    </>
  );
};

export default withWidth()(withNavbar(PathwaysTodayLesson));
