import { BASE_URL } from 'config/urls';

import { get, requestSdk } from 'utils/sdk';

export const fetchCustomer = async () => {
  return await requestSdk(() => get(`${BASE_URL}/v1/subscriptions/customer/`));
};

export const fetchSubscriptionTemplates = async () =>
  await requestSdk(() => get(`${BASE_URL}/v1/subscriptions/templates/`));

export const fetchNextBillingDate = async (subscriptionStripeId) => {
  return await requestSdk(() =>
    get(
      `${BASE_URL}/v1/subscriptions/${subscriptionStripeId}/next-billing-date/`
    )
  );
};
