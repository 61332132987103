import { BASE_URL } from 'config/urls';

import { get, post, requestSdk } from 'utils/sdk';

export const getSchoolsList = (search) =>
  requestSdk(() =>
    get(`${BASE_URL}/v1/walkthrough/schools/names-list/`, search)
  );

export const createSchool = (values) =>
  requestSdk(() => post(`${BASE_URL}/v1/walkthrough/schools/create/`, values));

export const getStatesList = () =>
  requestSdk(() => get(`${BASE_URL}/v1/schools/states/`));

export const fetchMe = () => requestSdk(() => get(`${BASE_URL}/v1/auth/me/`));

export const updateUserPreferredName = (userId, data) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/users/${userId}/update-preferred-name/`, data)
  );

export const completeWalkthroughProfile = (values) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/walkthrough/complete-profile/`, values)
  );

export const getOrCreateWalkthroughOrganization = (data) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/walkthrough/organizations/get-or-create/`, data)
  );
