import React, { useCallback } from 'react';

import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { MSA_LABEL_MAPPING } from 'utils/constants';
import { openInNewTab } from 'utils/urls';

import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import { ReactComponent as BackButtonIcon } from './assets/back_arrow.svg';
import { AddExample, TitledList } from './components';
import { useStandard, useStandardsStack } from './hooks';
import styles from './styles.module.scss';

const getTitle = (standard) => {
  const shortDescription = _.get(standard, 'short_description', '');
  if (_.isEmpty(shortDescription)) {
    return <strong>{_.get(standard, 'code', '')}</strong>;
  } else {
    return (
      <>
        <strong>{standard.code}</strong>
        <span> - {shortDescription}</span>
      </>
    );
  }
};

const StandardDetailDialog = ({ standardId, onClose, forStudents = false }) => {
  const [stack, stackPush, stackPop] = useStandardsStack(standardId);
  const [standard, onAddExample] = useStandard(_.last(stack)); // always show only the last element from the stack

  const stackPushCallback = useCallback(
    (standard) => stackPush(standard.id),
    [stackPush]
  );

  const getStandardParentLabel = useCallback(
    () => `${standard.parent.code} - ${MSA_LABEL_MAPPING[standard.parent.msa]}`,
    [standard]
  );

  if (!standard) {
    return null;
  }

  return (
    <Dialog open alignTop onClose={onClose} classes={{ paper: styles.dialog }}>
      <div
        className={classnames(styles.titleContainer, {
          [styles.backButtonVisible]: stack.length > 1
        })}
      >
        {stack.length > 1 && (
          <IconButton onClick={stackPop} size="small">
            <SvgIcon
              titleAccess="Back"
              component={BackButtonIcon}
              htmlColor={colors.blue1}
            />
          </IconButton>
        )}
        <Typography variant="H-TEXT-3" color={colors.blue1} component="span">
          {getTitle(standard)}
        </Typography>
      </div>
      <div className={styles.contentContainer}>
        {!_.isEmpty(standard.msa_verbose) && (
          <div className={styles.msaWrapper}>
            <Typography variant="S-TEXT-1" color={colors.blue4}>
              {standard.msa_verbose}
            </Typography>
          </div>
        )}

        {!_.isEmpty(standard.description) && (
          <Typography
            variant="B-Text-2"
            color={colors.blue1}
            className={styles.description}
          >
            {standard.description}
          </Typography>
        )}

        <TitledList
          title="Components"
          elements={standard.children}
          onElementClick={stackPushCallback}
          getOptionLabel={getTitle}
        />

        {_.isEmpty(standard.children) && !_.isEmpty(standard.sub_components) && (
          <div>
            <Typography variant="S-TEXT-1" color={colors.blue4}>
              Components
            </Typography>
            <ul>
              {_.map(standard.sub_components, (subComponent) => (
                <li key={subComponent}>
                  <Typography variant="B-Text-2" color={colors.blue1}>
                    {subComponent}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        )}

        {!_.isNil(standard.parent) && (
          <TitledList
            title={standard.parent.type}
            elements={[standard.parent]}
            onElementClick={stackPushCallback}
            getOptionLabel={getStandardParentLabel}
          />
        )}

        <TitledList
          title="Depends on"
          elements={standard.from_standards}
          onElementClick={stackPushCallback}
          getOptionLabel={getTitle}
        />
        <TitledList
          title="Builds to"
          elements={standard.to_standards}
          onElementClick={stackPushCallback}
          getOptionLabel={getTitle}
        />

        <TitledList
          title="Example Problems"
          getOptionLabel={(x) => x.attribution}
          elements={standard.example_problems}
          onElementClick={(el) => openInNewTab(el.url)}
        />

        {!forStudents && <AddExample onSubmit={onAddExample} />}
      </div>
    </Dialog>
  );
};

export default React.memo(StandardDetailDialog);
