import { BASE_URL } from 'config/urls';
import _ from 'lodash';
import Pusher from 'pusher-js';

class PusherManager {
  /* A Singleton class for managing Pusher connections */

  constructor() {
    this.pusherClient = null;
  }

  initPusherClient() {
    const pusherKey = process.env.REACT_APP_PUSHER_KEY;

    if (!pusherKey) {
      console.warn('Set `REACT_APP_PUSHER_KEY` in order to use Pusher');
      return;
    }

    /* The only way to set headers is via passing a headers dictionary. For reference:
     * https://github.com/pusher/pusher-js/blob/8dc4a9106313f930bb41e2d5426df69cb2c6b331/src/runtimes/worker/auth/fetch_auth.ts#L15
     * Setting the Cookie header is forbidden by the browser. There's an issue in Pusher about
     * adding `withCredentials` to the request params, therefore the only way is to patch the XHR request:
     * Reference: https://github.com/pusher/pusher-js/issues/471
     */
    Pusher.Runtime.createXHR = () => {
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      return xhr;
    };

    return new Pusher(pusherKey, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authEndpoint: `${BASE_URL}/v1/integrations/pusher/auth/`
    });
  }

  getPusherClient() {
    if (_.isNil(this.pusherClient)) {
      this.pusherClient = this.initPusherClient();
    }

    return this.pusherClient;
  }

  subscribeToChannel(channelName) {
    const pusherClient = this.getPusherClient();

    if (_.isNil(pusherClient)) {
      return;
    }

    return pusherClient.subscribe(channelName);
  }

  unsubcribeFromChannel(channelName) {
    const pusherClient = this.getPusherClient();

    if (_.isNil(pusherClient)) {
      return;
    }

    pusherClient.unsubscribe(channelName);
  }

  getPrivateAssignmentChannelName(trackerInstanceSectionId) {
    return `private-assignment-${trackerInstanceSectionId}`;
  }

  getPrivateGoogleClassroomSyncChannelName(googleSyncId) {
    return `private-google-sync-run-${googleSyncId}`;
  }

  getPrivateCleverSyncChannelName(cleverSyncId) {
    return `private-clever-sync-run-${cleverSyncId}`;
  }

  getPrivateBoardChannelName(boardId) {
    return `private-board-${boardId}`;
  }

  listenForNewStudentWork(trackerInstanceSectionId, callback) {
    const channelName = this.getPrivateAssignmentChannelName(
      trackerInstanceSectionId
    );
    const eventName = 'new-work';
    const channel = this.subscribeToChannel(channelName);

    if (!_.isNil(channel)) {
      channel.bind(eventName, callback);
    }
  }

  stopListeningForNewStudentWork(trackerInstanceSectionId) {
    const channelName = this.getPrivateAssignmentChannelName(
      trackerInstanceSectionId
    );

    this.unsubcribeFromChannel(channelName);
  }

  async listenForStudentPortfolioAssignmentsExport(callback) {
    const channelName = 'private-students-portfolio-export';
    const eventName = 'assignments-export-ready';
    const channel = this.subscribeToChannel(channelName);

    if (!_.isNil(channel)) {
      channel.bind(eventName, callback);
    }
  }

  stopListeningForStudentPortfolioAssignmentsExport() {
    const channelName = 'private-students-portfolio-export';
    this.unsubcribeFromChannel(channelName);
  }

  listenForGoogleClassroomSyncStatusUpdates(googleSyncId, callback) {
    const channelName =
      this.getPrivateGoogleClassroomSyncChannelName(googleSyncId);
    const eventName = 'status-updated';
    const channel = this.subscribeToChannel(channelName);

    if (!_.isNil(channel)) {
      channel.bind(eventName, callback);
    }
  }

  stopListeningForGoogleClassroomSyncStatusUpdates(googleSyncId) {
    const channelName =
      this.getPrivateGoogleClassroomSyncChannelName(googleSyncId);
    this.unsubcribeFromChannel(channelName);
  }

  listenForCleverSyncStatusUpdates(cleverSyncId, callback) {
    const channelName = this.getPrivateCleverSyncChannelName(cleverSyncId);
    const eventName = 'status-updated';
    const channel = this.subscribeToChannel(channelName);

    if (!_.isNil(channel)) {
      channel.bind(eventName, callback);
    }
  }

  stopListeningForCleverSyncStatusUpdates(cleverSyncId) {
    const channelName = this.getPrivateCleverSyncChannelName(cleverSyncId);
    this.unsubcribeFromChannel(channelName);
  }

  listenForBoardUpdates(boardId, callback) {
    const channelName = this.getPrivateBoardChannelName(boardId);
    const eventName = 'board-updated';
    const channel = this.subscribeToChannel(channelName);

    if (!_.isNil(channel)) {
      channel.bind(eventName, callback);
    }
  }

  listenForBoardForceRerenders(boardId, callback) {
    const channelName = this.getPrivateBoardChannelName(boardId);
    const eventName = 'board-force-rerender';
    const channel = this.subscribeToChannel(channelName);

    if (!_.isNil(channel)) {
      channel.bind(eventName, callback);
    }
  }

  disconnect() {
    if (_.isNil(this.pusherClient)) {
      return;
    }

    this.pusherClient.disconnect();
    this.pusherClient = null;
  }
}

const PusherManagerInstance = new PusherManager();

export default PusherManagerInstance;
