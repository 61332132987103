import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import classnames from 'classnames';
import _ from 'lodash';

import DraggableItemCard from 'pages/Teachers/MeetingSort/components/Column/components/DraggableItemCard';

import styles from './styles.module.scss';

const DroppableItemList = ({ droppableId, items }) => (
  <Droppable droppableId={droppableId} type="item">
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        className={classnames(styles.itemsList, {
          [styles.isDraggingOver]: snapshot.isDraggingOver
        })}
        {...provided.droppableProps}
      >
        {_.map(items, (item, index) =>
          _.isNil(item) ? null : (
            <DraggableItemCard
              key={item.id}
              id={item.item_id}
              index={index}
              item={item}
            />
          )
        )}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export default React.memo(DroppableItemList);
