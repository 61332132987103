import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const NextStepsTableHeader = ({ hasResources }) => (
  <TableHead>
    <TableRow>
      <TableCell className={styles.headerCell}>
        <Typography variant="H-TEXT-3" color={colors.grey3}>
          NEXT STEP (Check when completed)
        </Typography>
      </TableCell>
      {hasResources && (
        <TableCell className={styles.headerCell}>
          <Typography variant="H-TEXT-3" color={colors.grey3}>
            RESOURCES
          </Typography>
        </TableCell>
      )}
    </TableRow>
  </TableHead>
);

export default NextStepsTableHeader;
