import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Checkbox from '@material-ui/core/Checkbox';
import cx from 'classnames';
import { TEACHERS_MEETING_SORT_URL } from 'config/urls';
import { ReactComponent as TrashIcon } from 'images/trashicon.svg';
import _ from 'lodash';
import {
  meetingBoardDelete,
  meetingBoardUpdate,
  useMeetingBoardShares
} from 'sdk';

import { colors } from 'theme/palette';
import { notifyErrors } from 'utils/notifications';
import { reverse, stringifyParams } from 'utils/urls';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import Typography from 'components/Typography';

import styles from './styles.module.scss';
import { getDefaultMeetingShares, getNewSelectedMeetingShares } from './utils';

const SortUpdateDialog = ({
  sortName,
  sortId,
  invitationIdentifier,
  meetingShares,
  onClose,
  onDelete
}) => {
  const [title, setTitle] = useState(sortName);
  const [submitting, setSubmitting] = useState(false);
  const [selectedMeetingShares, setSelectedMeetingShares] = useState([]);

  const history = useHistory();

  const { data: sortShares, isLoading } = useMeetingBoardShares({
    meetingBoardId: sortId
  });

  const sortSharesIds = useMemo(() => _.map(sortShares, 'id'), [sortShares]);

  const defaultMeetingShares = useMemo(
    () =>
      getDefaultMeetingShares({
        meetingShares: meetingShares,
        meetingBoardMeetingShareIds: sortSharesIds
      }),
    [meetingShares, sortSharesIds]
  );

  useEffect(() => {
    setSelectedMeetingShares(defaultMeetingShares);
  }, [defaultMeetingShares]);

  const onSubmit = async () => {
    setSubmitting(true);

    const markedMeetingSharesIds = getNewSelectedMeetingShares({
      meetingShares: selectedMeetingShares,
      meetingBoardMeetingShareIds: sortSharesIds
    });

    const { success, errors } = await meetingBoardUpdate({
      meetingBoardId: sortId,
      data: {
        name: title,
        meeting_shares_ids: markedMeetingSharesIds
      }
    });

    if (success) {
      onClose();
      history.push({
        pathname: reverse(TEACHERS_MEETING_SORT_URL, { invitationIdentifier }),
        search: stringifyParams({ sortId })
      });
    } else {
      notifyErrors(errors);
    }

    setSubmitting(false);
  };

  const handleMeetingShareSelect = useCallback(
    ({ meetingSharedId }) =>
      setSelectedMeetingShares((prevSelectedMeetingShares) => ({
        ...prevSelectedMeetingShares,
        [meetingSharedId]: !prevSelectedMeetingShares[meetingSharedId]
      })),
    []
  );

  const allMeetingSharesAreSelected = useMemo(
    () => _.every(_.values(selectedMeetingShares)),
    [selectedMeetingShares]
  );

  const someMeetingSharesAreSelected = useMemo(
    () => _.some(_.values(selectedMeetingShares)),
    [selectedMeetingShares]
  );

  const disableSelectAll = useMemo(() => {
    if (!allMeetingSharesAreSelected) {
      return false;
    }

    const notSharedYet = Object.keys(
      _.pickBy(
        selectedMeetingShares,
        (value, key) => !_.includes(sortSharesIds, parseInt(key))
      )
    );

    return allMeetingSharesAreSelected && notSharedYet.length === 0;
  }, [allMeetingSharesAreSelected, sortSharesIds, selectedMeetingShares]);

  const handleSelectAllClick = useCallback(() => {
    if (!disableSelectAll) {
      setSelectedMeetingShares((prevSelectedMeetingShares) =>
        _.mapValues(
          prevSelectedMeetingShares,
          (value, key) =>
            _.includes(sortSharesIds, parseInt(key)) ||
            !allMeetingSharesAreSelected
        )
      );
    }
  }, [allMeetingSharesAreSelected, sortSharesIds, disableSelectAll]);

  const handleSortDelete = useCallback(async () => {
    const { success, errors } = await meetingBoardDelete({
      meetingBoardId: sortId
    });

    if (success) {
      onClose();
      onDelete();
    } else {
      notifyErrors(errors);
    }
  }, [sortId, onClose, onDelete]);

  return (
    <Dialog
      open
      alignTop
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <div className={styles.header}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          Update Sort
        </Typography>

        <Button
          color="lightGrey"
          startIcon={<TrashIcon className={styles.trashIcon} />}
          onClick={handleSortDelete}
          className={styles.deleteButton}
        >
          Delete sort
        </Button>

        <Button
          color="pink"
          onClick={onSubmit}
          disabled={submitting || !someMeetingSharesAreSelected}
          className={styles.saveButton}
        >
          Save
        </Button>
      </div>
      <InputField
        fullWidth
        variant="underlined"
        placeholder="Name the Sort (optional)"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        className={styles.sortTitleInput}
      />
      <div className={styles.meetingShareSelect}>
        <div
          className={cx(styles.selectAll, {
            [styles.disabled]: disableSelectAll
          })}
          onClick={handleSelectAllClick}
        >
          <Checkbox
            color="primary"
            checked={allMeetingSharesAreSelected}
            disabled={disableSelectAll}
          />
          <Typography variant="S-TEXT-1" color={colors.grey3}>
            Select all assignments
          </Typography>
        </div>
        {!isLoading &&
          !_.isEmpty(selectedMeetingShares) &&
          meetingShares.map((meetingShare) => {
            const isSelected =
              selectedMeetingShares[meetingShare.meeting_share_id];
            const isDisabled = sortSharesIds.includes(
              meetingShare.meeting_share_id
            );

            return (
              <div
                key={meetingShare.meeting_share_id}
                className={cx(styles.selectableMeetingShare, {
                  [styles.selected]: isSelected,
                  [styles.disabled]: isDisabled
                })}
                onClick={() =>
                  !isDisabled
                    ? handleMeetingShareSelect({
                        meetingSharedId: meetingShare.meeting_share_id
                      })
                    : {}
                }
              >
                <Checkbox
                  color="primary"
                  checked={isSelected}
                  disabled={isDisabled}
                />
                <Typography variant="B-Text-2" color={colors.grey1}>
                  {meetingShare.name} - {meetingShare.section_name}
                </Typography>
              </div>
            );
          })}
      </div>
    </Dialog>
  );
};

export default React.memo(
  SortUpdateDialog,
  (prevProps, nextProps) =>
    prevProps.sortName === nextProps.sortName &&
    prevProps.sortId === nextProps.sortId &&
    prevProps.invitationIdentifier === nextProps.invitationIdentifier
);
