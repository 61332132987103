import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import SchoolIcon from '@material-ui/icons/School';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Standard = ({ label }) => (
  <div className={styles.container}>
    <Typography
      variant="B-Text-3"
      color={colors.blue3}
      className={styles.content}
    >
      <SvgIcon
        component={SchoolIcon}
        color="primary"
        className={styles.buttonIcon}
      />
      {label}
    </Typography>
  </div>
);

export default Standard;
