import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import Collapse from '@material-ui/core/Collapse';
import _ from 'lodash';
import { useStandardsForStudentPortfolioWithFilters, useStudent } from 'sdk';

import { buildFilters } from 'utils/filters';
import { getStandardSiblings } from 'utils/standards';

import NoResultsPlaceholder from 'components/NoResultsPlaceholder';
import StandardNode from 'components/StandardNode';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const AssignmentsStandardsView = ({ filters, initialFilters }) => {
  const [standardIdsToRender, setStandardIdsToRender] = useState([]);

  const { user } = useContext(UserContext);
  const studentId = user.student_id;
  const { data: student } = useStudent({ studentId });

  const { data: standards, isLoading } =
    useStandardsForStudentPortfolioWithFilters({
      studentId,
      filters: buildFilters(filters)
    });

  useEffect(() => {
    if (!_.isEmpty(standards)) {
      const standardsToExpand = !_.isEqual(initialFilters, filters)
        ? standards
        : standards.filter((standard) => _.inRange(standard.level, 0, 3));

      const initiallyOpenedStandards = _.map(standardsToExpand, 'id');

      setStandardIdsToRender(initiallyOpenedStandards);
    }
  }, [standards, filters, initialFilters]);

  const getStandardDirectChildren = useCallback(
    ({ standard }) => _.filter(standards, { parent_id: standard.id }),
    [standards]
  );

  const getStandardChildren = ({ children, standard }) => {
    const directChildren = getStandardDirectChildren({ standard });

    children.push(directChildren);

    _.each(directChildren, (child) => {
      getStandardChildren({ children, standard: child });
    });

    return _.flatten(children);
  };

  const openStandard = ({ standard }) => {
    // Get all direct standard children
    const standardsToToggle = getStandardDirectChildren({ standard });

    setStandardIdsToRender(
      _.xor(standardIdsToRender, _.map(standardsToToggle, 'id'))
    );
  };

  const transformedStandards = useMemo(
    () =>
      _.map(standards, (standard) => {
        return {
          ...standard,
          direct_children: getStandardDirectChildren({ standard })
        };
      }),
    [standards, getStandardDirectChildren]
  );

  const openStandardAndAllChildren = ({ standard }) => {
    const standardsToToggle = getStandardChildren({ children: [], standard });
    const standardIdsToToggle = _.map(standardsToToggle, 'id');

    setStandardIdsToRender(_.union(standardIdsToRender, standardIdsToToggle));
  };

  const getStandardParent = ({ standard }) =>
    _.find(transformedStandards, {
      id: standard.parent_id
    });

  const getStandardParents = ({ parents, standard }) => {
    if (standard.parent_id) {
      const parent = getStandardParent({ standard });

      if (parent) {
        parents.push(parent);
      }

      if (parent?.parent_id) {
        getStandardParents({ parents, standard: parent });
      }
    }

    return _.flatten(parents);
  };

  const parentOnLevelIsOnlyChildOrLastChild = ({ level, standard }) => {
    const standardAllParents = getStandardParents({
      parents: [],
      standard
    });

    const parentOnLevel = _.first(
      _.filter(standardAllParents, { level: level })
    );

    if (!parentOnLevel) {
      return false;
    }

    const parentSiblings = getStandardSiblings({
      standard: parentOnLevel,
      standards: transformedStandards
    });

    const parentOnLevelIsOnlyChild = _.isEmpty(parentSiblings);

    const standardsOnLevel = _.filter(transformedStandards, {
      level,
      parent_id: parentOnLevel.parent_id
    });

    const parentOnLevelIsLastChild =
      _.last(standardsOnLevel).id === parentOnLevel.id;

    return parentOnLevelIsOnlyChild || parentOnLevelIsLastChild;
  };

  const closeStandard = ({ standard }) => {
    // Get all standard children down the tree
    const standardsToToggle = getStandardChildren({ children: [], standard });
    const standardIdsToToggle = _.map(standardsToToggle, 'id');

    setStandardIdsToRender(
      _.filter(
        standardIdsToRender,
        (standardId) => !_.includes(standardIdsToToggle, standardId)
      )
    );
  };

  return (
    <>
      {isLoading && <StandardNode.Placeholder count={3} />}

      {!isLoading && _.isEmpty(transformedStandards) && (
        <div className={styles.noResultsPlaceholderContainer}>
          <NoResultsPlaceholder />
        </div>
      )}

      {!isLoading &&
        _.map(transformedStandards, (standard) => {
          // A standard is opened if all of their direct children are rendered
          const standardIsOpened = _.every(standard.direct_children, (child) =>
            _.includes(standardIdsToRender, child.id)
          );

          return (
            <Collapse
              in={_.includes(standardIdsToRender, standard.id)}
              key={standard.id}
            >
              <StandardNode
                forStudents={true}
                standard={standard}
                standards={transformedStandards}
                opened={standardIsOpened}
                openStandard={openStandard}
                closeStandard={closeStandard}
                openStandardAndAllChildren={openStandardAndAllChildren}
                parentOnLevelIsOnlyChildOrLastChild={
                  parentOnLevelIsOnlyChildOrLastChild
                }
                student={student}
              />
            </Collapse>
          );
        })}
    </>
  );
};

export default AssignmentsStandardsView;
