import React from 'react';

import pathwaysLogo from './assets/pathways_logo.svg';

const PathwaysLogo = () => {
  return (
    <img
      src={pathwaysLogo}
      alt="Pathways"
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    />
  );
};

export default PathwaysLogo;
