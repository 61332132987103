import { BASE_URL } from 'config/urls';

import { get, requestSdk } from 'utils/sdk';

export const assignmentsList = async (studentId, params) => {
  const url = `${BASE_URL}/v1/students/${studentId}/assignments/`;
  return await requestSdk(() => get(url, params));
};

export const assignmentsStatistics = async (studentId) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v1/students/${studentId}/assignments/statistics/`)
  );

export const fetchStudentSections = async () =>
  await requestSdk(() => get(`${BASE_URL}/v1/students/sections/`));
