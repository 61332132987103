import _ from 'lodash';

import { notifyErrors } from 'utils/notifications';

export const setFormErrors = (errors, setFieldError) => {
  const fieldErrors = {};
  const formErrors = [];

  for (let error of errors) {
    const errorField = _.get(error, 'field', null);
    const errorMessage = _.get(error, 'message');

    if (errorField === '__all__') {
      // When the error message is related to more than 1 field (e.g. unique together fields),
      // the field name is returned from the back-end as `__all__`.
      // In this case, we want to directly push the error and continue because this field cannot be mapped any further.
      formErrors.push(errorMessage);
      continue;
    }

    const fieldName = _.camelCase(_.last(_.split(errorField, '.')));

    if (fieldName) {
      if (!fieldErrors[fieldName]) {
        fieldErrors[fieldName] = [];
      }

      fieldErrors[fieldName].push(errorMessage);
    } else {
      formErrors.push(errorMessage);
    }
  }

  for (let [field, errors] of Object.entries(fieldErrors)) {
    for (let error of errors) {
      setFieldError(field, error);
    }
  }

  notifyErrors(formErrors);

  return formErrors;
};
