import React from 'react';

import Avatar from '@material-ui/core/Avatar';

import styles from '../styles.module.scss';

const StudentAvatar = ({ bgColor, studentInitials }) => {
  return (
    <Avatar
      className={styles.studentMenuAvatar}
      style={{ backgroundColor: bgColor }}
    >
      {studentInitials}
    </Avatar>
  );
};

export default StudentAvatar;
