import React, { useCallback, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/';
import classnames from 'classnames';
import { GOOGLE_CLASSROOM_SCHOOL_SIGN_UP_REDIRECT_URI } from 'config/constants';
import {
  EDLIGHT_MAIN_DOMAIN_URL,
  WALKTHROUGH_CREATE_PROFILE_URL
} from 'config/urls';
import logo from 'images/logo-dark.svg';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { openGoogleLoginPage } from 'utils/integrations/google';
import { useBreakpoints } from 'utils/resizing';

import Button from 'components/Button';
import Image from 'components/Image';
import InputField from 'components/InputField';
import Typography from 'components/Typography';

import { ReactComponent as GoogleClassroomIcon } from './assets/classroom.svg';
import { useSchoolName } from './hooks';
import styles from './styles.module.scss';

const SchoolLogin = () => {
  const { identifier: schoolIdentifier } = useParams();
  const school = useSchoolName(schoolIdentifier);
  const schoolName = useMemo(() => _.get(school, 'name'), [school]);

  const [preferredName, setPreferredName] = useState('');

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  const onPreferredNameChange = useCallback(
    (event) => setPreferredName(event.target.value),
    []
  );

  const signUpWithGoogleClassroom = useCallback(() => {
    const redirectUri = GOOGLE_CLASSROOM_SCHOOL_SIGN_UP_REDIRECT_URI;

    const scope = _.join(
      [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/classroom.profile.emails',
        'https://www.googleapis.com/auth/classroom.courses.readonly',
        'https://www.googleapis.com/auth/classroom.rosters.readonly'
      ],
      ' '
    );

    const state = {
      preferredName,
      schoolIdentifier
    };

    openGoogleLoginPage(redirectUri, scope, state);
  }, [preferredName, schoolIdentifier]);

  const [
    termsConditionsAndPrivacyAccepted,
    setTermsConditionsAndPrivacyAccepted
  ] = useState(false);

  const onTermsConditionsAndPrivacyClick = useCallback(
    () => setTermsConditionsAndPrivacyAccepted((prevValue) => !prevValue),
    []
  );

  return (
    <div className={styles.page}>
      {!isMobile && (
        <a href={EDLIGHT_MAIN_DOMAIN_URL}>
          <Image src={logo} className={styles.headerLogo} alt="Header logo" />
        </a>
      )}

      <div className={styles.loginWrapper}>
        <div className={styles.loginBox}>
          <Image src={logo} className={styles.logo} alt="Logo" />

          <Typography variant="S-TEXT-1" color={colors.grey2}>
            EdLight sign up for:
          </Typography>
          <Typography
            variant="H-TEXT-1"
            color={colors.grey1}
            className={styles.schoolName}
          >
            {schoolName}
          </Typography>

          <FormControlLabel
            className={classnames(
              styles.horizontalPadding,
              styles.checkboxContainer
            )}
            control={
              <Checkbox
                size="small"
                checked={termsConditionsAndPrivacyAccepted}
                onChange={onTermsConditionsAndPrivacyClick}
              />
            }
            label={
              <Typography
                variant="B-Text-2"
                color={colors.grey1}
                className={styles.checkboxLabelText}
              >
                I agree to the Edlight{' '}
                <a
                  href="https://www.edlight.com/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                  onClick={(e) => e.stopPropagation()}
                >
                  Terms of service
                </a>{' '}
                &{' '}
                <a
                  href="https://www.edlight.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                  onClick={(e) => e.stopPropagation()}
                >
                  Privacy Policy
                </a>
              </Typography>
            }
          />

          <div
            className={classnames(
              styles.horizontalPadding,
              styles.preferredNameField
            )}
          >
            <Typography variant="S-TEXT-1" color={colors.grey2}>
              Students call me
            </Typography>
            <InputField
              fullWidth
              type="text"
              variant="underlined"
              placeholder="Mr. T"
              value={preferredName}
              onChange={onPreferredNameChange}
            />
          </div>

          <Button
            noTypography
            className={styles.googleClassroomButton}
            startIcon={
              <GoogleClassroomIcon
                className={styles.googleClassroomButtonIcon}
              />
            }
            variant="textAndIcon"
            disabled={!termsConditionsAndPrivacyAccepted}
            onClick={signUpWithGoogleClassroom}
          >
            <Typography
              variant="B-Text-3"
              color={colors.grey1}
              className={styles.googleClassroomButtonText}
            >
              Sign Up with Google Classroom
            </Typography>
          </Button>

          <Typography variant="B-Text-3" className={styles.notFromSchool}>
            <Link
              to={WALKTHROUGH_CREATE_PROFILE_URL}
              className={styles.linkToLogin}
            >
              Not from {schoolName}?
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SchoolLogin;
