import React from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import InputField from 'components/InputField';

const useStyles = makeStyles({
  input: {
    width: '150px',
    height: '24px',

    minHeight: 24,

    '& .MuiInputBase-root': {
      minHeight: 24,
      paddingRight: 0
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: `${colors.grey3}`,
      borderBottomWidth: '1px'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${colors.grey3}`,
      borderBottomWidth: '1px'
    },

    '& .MuiInput-underline:hover:before': {
      borderBottomColor: `${colors.blue4}`,
      borderBottomWidth: '1px'
    },

    '& .MuiInput-underline:hover:after': {
      borderBottomColor: `${colors.blue4}`,
      borderBottomWidth: '1px'
    },

    '& .Mui-focused.MuiInput-underline:after': {
      backgroundColor: colors.grey6,
      borderBottomColor: `${colors.grey3}`,
      borderBottomWidth: '1px'
    },

    '& input': {
      padding: '0 10px 0 4px !important', // Mui Autocomplete sets it to '6px 0' by deafult

      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '13px',
      lineHeight: '15px',

      minWidth: `unset !important`,

      '&:focus': {
        height: '100%'
      }
    }
  },
  searchIcon: {
    '& svg': { fill: colors.grey4, width: '20px', height: '20px' }
  },

  inputHasValue: {
    width: '200px',

    '& .MuiInputBase-root': {
      backgroundColor: colors.blue6,
      '&:hover': {
        backgroundColor: colors.grey6
      }
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: `${colors.blue4}`
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${colors.blue4}`
    }
  },

  chipRoot: {
    height: '18px',
    textOverflow: 'ellipsis',
    borderRadius: 5
  },

  chipLabel: {
    maxWidth: 150,

    padding: '2px 12px',

    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '15px',

    '&:hover': {
      color: colors.blue1
    }
  },

  chipDeleteIcon: {
    width: '16px',
    height: '16px'
  },

  adornmentPositionStart: {
    marginRight: 0
  },

  autoCompleteFocused: {
    '& > div': {
      height: '100%'
    }
  },
  autoCompleteTag: {
    height: '18px'
  }
});

const getAdornment = ({ hasValue, styles }) => {
  if (hasValue) {
    return;
  }

  return {
    endAdornment: (
      <InputAdornment
        position="start"
        className={classnames(styles.searchIcon, {
          [styles.searchIconHasValue]: hasValue
        })}
      >
        <SearchRoundedIcon />
      </InputAdornment>
    )
  };
};

const SearchFilter = ({ placeholder, value, ...rest }) => {
  const hasValue = !_.isEmpty(value);
  const styles = useStyles();

  const adornment = getAdornment({ hasValue, styles });

  placeholder = hasValue ? '' : placeholder;

  const renderInput = (params) => {
    const enhancedParams = {
      ...params,
      InputProps: {
        ...params.InputProps,
        ...adornment
      },
      variant: 'underlined',
      placeholder: placeholder,
      value: value,
      className: classnames(styles.input, {
        [styles.inputHasValue]: hasValue,
        [styles.disabled]: rest.disabled
      })
    };

    return <InputField {...enhancedParams} />;
  };

  return (
    <Autocomplete
      classes={{
        focused: styles.autoCompleteFocused,
        tag: styles.autoCompleteTag
      }}
      forcePopupIcon={false}
      disableClearable={!hasValue}
      renderInput={renderInput}
      value={value}
      ChipProps={{
        classes: {
          root: styles.chipRoot,
          label: styles.chipLabel,
          deleteIcon: styles.chipDeleteIcon
        }
      }}
      {...rest}
    />
  );
};

export default SearchFilter;
