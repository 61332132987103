import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';

import MomentUtils from '@date-io/moment';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import cx from 'classnames';
import {
  LOGIN_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL,
  STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL,
  STUDENTS_MY_PORTFOLIO_STANDARDS_VIEW_URL,
  STUDENTS_NOTIFICATIONS_LIST_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL
} from 'config/urls';
import { ReactComponent as FilterIcon } from 'images/filter.svg';
import { ReactComponent as ResultsIcon } from 'images/results.svg';

import { Typography } from 'pages/Students/shared';
import { colors } from 'theme/palette';
import { useBreakpoints } from 'utils/resizing';

import StudentRequired from '../StudentRequired';
import { getUnseenNotificationsCount, logout } from './sdk';
import styles from './styles.module.scss';

const promptLogout = () => {
  const confirmed = window.confirm('Log out of EdLight?');

  return new Promise((resolve) => {
    if (confirmed) {
      resolve(logout());
    }
  });
};

// This is needed because React Hooks "setState" executes a function when it receives one,
// and sets the result of that function as the value of the variable. We need the value of the variable to
// be a function and we want the interface to remain the same:
//
// like this:
// this.props.navigation.setBackButtonAction(() => console.log("test"))
//
// or this for setting it to "empty":
// this.props.navigation.setBackButtonAction(null)
//
// and not like this:
// this.props.navigation.setBackButtonAction(() => () => console.log("test"))
const setFunction = (setState) => (func) => {
  // This is the "empty" value
  if (func === null) {
    setState(null);
    return;
  }
  if (typeof func != 'function') {
    throw new Error(`Navigation action should be a function, not ${func}`);
  }

  setState(() => func);
};

const StudentLayoutContext = React.createContext();

const StudentPageLayout = ({
  children,
  history,
  user,
  limitContentToCenterPortionOfTheScreen = true
}) => {
  const [title, setTitle] = useState();
  const [viewSwitcher, setViewSwitcher] = useState(null);
  const [backButtonAction, setBackButtonAction] = useState();
  const [doneButtonAction, setDoneButtonAction] = useState();
  const [clearButtonAction, setClearButtonAction] = useState();
  const [filterIconVisible, setFilterIconVisible] = useState(false);
  const [filterIconClicked, setFilterIconClicked] = useState(false);
  const [filterIconColor, setFilterIconColor] = useState(colors.white);
  const [bottomNavigationVisible, setBottomNavigationVisible] = useState(true);
  const [unseenNotificationsCount, setUnseenNotificationsCount] = useState(0);

  const currentPathName = history.location.pathname;

  const [, , isMobile] = useBreakpoints({
    tablet: 992,
    mobile: 768
  });

  const logout = () => promptLogout().then(() => history.push(LOGIN_URL));

  const goToTeacherApp = () => history.push(TEACHERS_ASSIGNMENTS_LIST_URL);
  const goToNotifications = () =>
    history.push({
      pathname: STUDENTS_NOTIFICATIONS_LIST_URL,
      state: { prevPath: currentPathName }
    });

  const fetchUnseenNotificationsCount = () => {
    getUnseenNotificationsCount().then(
      (data) => data && setUnseenNotificationsCount(data.count)
    );
  };

  useEffect(() => {
    // Poll for unseen notifications when current path changes
    fetchUnseenNotificationsCount();
  }, [currentPathName]);

  const teacherDemoStudent = user.is_teacher && user.student_id;

  const studentLayoutContextValue = useMemo(
    () => ({
      navigation: {
        setTitle,
        setBottomNavigationVisible,
        setBackButtonAction: setFunction(setBackButtonAction),
        setDoneButtonAction: setFunction(setDoneButtonAction),
        setClearButtonAction: setFunction(setClearButtonAction),
        setFilterIconVisible,
        setFilterIconColor,
        setViewSwitcher,
        filterIconClicked,
        setFilterIconClicked
      }
    }),
    [filterIconClicked]
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={styles.pageWrapper}>
        <div className={styles.topNavigation}>
          <div
            className={cx({
              [styles.container]: limitContentToCenterPortionOfTheScreen
            })}
          >
            <Grid
              className={styles.gridContainer}
              container
              alignItems="center"
              wrap="nowrap"
            >
              <div>
                <IconButton
                  onClick={() => backButtonAction && backButtonAction()}
                  className={cx({
                    [styles.invisible]: !backButtonAction
                  })}
                >
                  <ArrowBackRoundedIcon color="secondary" />
                </IconButton>
              </div>
              <Typography
                variant="H-TEXT-3"
                color="white"
                className={styles.title}
              >
                {title}
              </Typography>
              <div className={styles.group}>
                {viewSwitcher && (
                  <div className={styles.viewSwitcher}>{viewSwitcher()}</div>
                )}
                {filterIconVisible && (
                  <IconButton
                    className={styles.filterIcon}
                    onClick={() => setFilterIconClicked(true)}
                  >
                    <FilterIcon fill={filterIconColor} />
                  </IconButton>
                )}
                {doneButtonAction && (
                  <Button onClick={doneButtonAction}>
                    <Typography variant="S-TEXT-3" color="white">
                      Done
                    </Typography>
                  </Button>
                )}
                {clearButtonAction && (
                  <Button onClick={clearButtonAction}>
                    <Typography variant="S-TEXT-3" color="white">
                      Clear
                    </Typography>
                  </Button>
                )}
              </div>
            </Grid>
          </div>
        </div>
        <div
          className={cx(styles.content, {
            [styles.container]: limitContentToCenterPortionOfTheScreen,
            [styles.greyOnDesktop]: !limitContentToCenterPortionOfTheScreen
          })}
        >
          <StudentLayoutContext.Provider value={studentLayoutContextValue}>
            {children}
          </StudentLayoutContext.Provider>
        </div>
        {bottomNavigationVisible && (
          <div className={styles.bottomNavigation}>
            <div className={styles.container}>
              <Grid container justifyContent="space-around">
                <IconButton
                  aria-label="Home"
                  size="medium"
                  onClick={() => {
                    setFilterIconClicked(false);
                    history.push(STUDENTS_ASSIGNMENTS_LIST_URL);
                  }}
                >
                  <HomeRoundedIcon color="primary" />
                </IconButton>
                <IconButton
                  aria-label="My portfolio"
                  size="medium"
                  onClick={() => {
                    setFilterIconClicked(false);
                    history.push(
                      isMobile
                        ? STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL
                        : STUDENTS_MY_PORTFOLIO_STANDARDS_VIEW_URL
                    );
                  }}
                >
                  <SvgIcon component={ResultsIcon} color="primary" />
                </IconButton>
                <IconButton
                  aria-label="Notifications"
                  size="medium"
                  onClick={goToNotifications}
                >
                  <Badge
                    color="primary"
                    variant="dot"
                    invisible={unseenNotificationsCount === 0}
                  >
                    <NotificationsRoundedIcon color="primary" />
                  </Badge>
                </IconButton>
                <IconButton
                  aria-label="Sign out"
                  size="medium"
                  onClick={teacherDemoStudent ? goToTeacherApp : logout}
                >
                  <ExitToAppRoundedIcon color="primary" />
                </IconButton>
              </Grid>
            </div>
          </div>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export const withNavbar = (
  Component,
  {
    title,
    viewSwitcher,
    doneButtonAction,
    clearButtonAction,
    backButtonAction,
    bottomNavigationVisible,
    filterIconVisible
  } = {}
) => {
  class Wrapper extends React.Component {
    componentDidMount() {
      title && this.navigation.setTitle(title);
      backButtonAction && this.navigation.setBackButtonAction(backButtonAction);
      doneButtonAction && this.navigation.setDoneButtonAction(doneButtonAction);
      clearButtonAction &&
        this.navigation.setClearButtonAction(clearButtonAction);
      bottomNavigationVisible &&
        this.navigation.setBottomNavigationVisible(bottomNavigationVisible);
      filterIconVisible &&
        this.navigation.setFilterIconVisible(filterIconVisible);
      viewSwitcher && this.navigation.setViewSwitcher(() => viewSwitcher);
    }

    componentWillUnmount() {
      if (this.navigation) {
        // Return to defaults on unmount
        this.navigation.setBackButtonAction(null);
        this.navigation.setDoneButtonAction(null);
        this.navigation.setClearButtonAction(null);
        this.navigation.setBottomNavigationVisible(true);
        this.navigation.setTitle(null);
        this.navigation.setFilterIconVisible(false);
        this.navigation.setViewSwitcher(null);
      }
    }

    render() {
      return (
        <StudentLayoutContext.Consumer>
          {(hocProps) => {
            // For proper unmounting
            this.navigation = hocProps.navigation;
            return <Component {...this.props} {...hocProps} />;
          }}
        </StudentLayoutContext.Consumer>
      );
    }
  }
  return Wrapper;
};

export default StudentRequired(withRouter(StudentPageLayout));
