import React, { useEffect, useMemo } from 'react';

import { filter, get, isEmpty, map } from 'lodash';
import moment from 'moment';

import SelectFilter from 'components/SelectFilter';

const YearFilter = ({ years, selectedYear, setSelectedYear }) => {
  const yearsOptions = useMemo(() => {
    const today = new Date();

    const activeYears = filter(years, (year) =>
      moment(today).isAfter(year.naive_start_date)
    );

    return map(activeYears, (year) => {
      const startYear = new Date(year.naive_start_date).getFullYear();
      const endYear = new Date(year.naive_end_date).getFullYear();
      const label = `${startYear} - ${endYear}`;

      return {
        label,
        value: year.id
      };
    });
  }, [years]);

  const handleChange = (event) => {
    const newYearValue = event.target.value;

    setSelectedYear(yearsOptions.find((item) => item.value === newYearValue));
  };

  useEffect(() => {
    if (!isEmpty(yearsOptions) && !selectedYear) {
      setSelectedYear(yearsOptions[0]);
    }
  }, [yearsOptions, selectedYear, setSelectedYear]);

  return (
    <SelectFilter
      value={get(selectedYear, 'value', '')}
      options={yearsOptions}
      onChange={handleChange}
      placeholder="Years"
    />
  );
};

export default YearFilter;
