import { BASE_URL } from 'config/urls';

import { callUrl, get, post, requestSdk } from 'utils/sdk';

export const getStandard = (standardId) =>
  callUrl(get, `${BASE_URL}/v1/standards/${standardId}/`);

export const addExample = (standardId, data) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/standards/example-problems/create/`, {
      standard: standardId,
      ...data
    })
  );
