import React, { useCallback, useEffect, useRef } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import classnames from 'classnames';

import { colors } from 'theme/palette';

import InputField from 'components/InputField';
import Typography from 'components/Typography';

import { ReactComponent as EditIcon } from './assets/edit.svg';
import { useInput } from './hooks';
import styles from './styles.module.scss';

const EditableText = ({
  initialText,
  label,
  onTextUpdate,
  iconsColor = colors.grey2,
  typographyProps = {},
  onOpen,
  onClose
}) => {
  const inputRef = useRef(null);

  const {
    inputOpen,
    toggleInput,
    value,
    setValue,
    onSave,
    onCancel,
    onKeyDown
  } = useInput(initialText, onTextUpdate, onOpen, onClose);

  const handleClickOutsideInputField = useCallback(
    (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        onSave();
      }
    },
    [inputRef, onSave]
  );

  useEffect(() => {
    if (inputOpen) {
      window.addEventListener('mousedown', handleClickOutsideInputField);

      return () =>
        window.removeEventListener('mousedown', handleClickOutsideInputField);
    }
  }, [inputOpen, handleClickOutsideInputField]);

  return (
    <div className={styles.header}>
      {!inputOpen && (
        <>
          <Typography
            variant="H-TEXT-3"
            color={colors.blue2}
            {...typographyProps}
          >
            {label || initialText}
          </Typography>
          <IconButton
            className={classnames(styles.icon, styles.edit)}
            size="small"
            onClick={toggleInput}
          >
            <EditIcon fill={iconsColor} />
          </IconButton>
        </>
      )}
      {inputOpen && (
        <>
          <InputField
            autoFocus
            ref={inputRef}
            variant="underlined"
            placeholder="Add topic"
            value={value}
            className={styles.input}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={onKeyDown}
            onBlur={onSave}
          />
          <IconButton
            className={classnames(styles.icon, styles.save)}
            size="small"
            onClick={onSave}
          >
            <DoneRoundedIcon style={{ color: iconsColor }} />
          </IconButton>
          <IconButton
            className={classnames(styles.icon, styles.save)}
            size="small"
            onClick={onCancel}
          >
            <ClearRoundedIcon style={{ color: iconsColor }} />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default React.memo(EditableText);
