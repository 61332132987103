import React from 'react';

import {
  STUDENTS_ASSIGNMENTS_DETAIL_URL,
  STUDENTS_ASSIGNMENTS_OVERVIEW_URL
} from 'config/urls';

import { notifyErrors } from 'utils/notifications';
import { parseParams, reverse, stringifyParams } from 'utils/urls';

import UserContext from 'components/UserContext';

import { findAssignment } from './sdk';

class StudentAssignmentsDetailRedirect extends React.Component {
  static contextType = UserContext;

  state = { notFound: false };

  componentDidMount() {
    this.findAssignment();
  }

  async findAssignment() {
    const { student_id: studentId } = this.context.user;
    const { trackerInstanceId } = this.props.match.params;
    const { taskId } = parseParams(this.props.history.location.search);

    const { data, success, errors } = await findAssignment(
      studentId,
      trackerInstanceId,
      taskId
    );

    if (success) {
      const { task_id: taskId, assignment_id: assignmentId } = data;

      if (!assignmentId) {
        // TODO: Redirect to 404 page once we have it in the project.
        this.setState({ notFound: true }, () =>
          notifyErrors(['Assignment not found.'])
        );
        return;
      }

      if (taskId) {
        this.goToAssignmentDetail(assignmentId, taskId);
      } else {
        this.goToAssignmentOverview(assignmentId);
      }
    } else {
      notifyErrors(errors);
    }
  }

  goToAssignmentDetail = (assignmentId, taskId) =>
    this.props.history.push({
      pathname: reverse(STUDENTS_ASSIGNMENTS_DETAIL_URL, {
        assignmentId
      }),
      search: stringifyParams({ task: taskId, camera: false })
    });

  goToAssignmentOverview = (assignmentId) =>
    this.props.history.push(
      reverse(STUDENTS_ASSIGNMENTS_OVERVIEW_URL, {
        assignmentId
      })
    );

  render() {
    const { notFound } = this.state;

    return <h1>{!notFound ? 'Redirecting...' : 'Oops.'}</h1>;
  }
}

export default StudentAssignmentsDetailRedirect;
