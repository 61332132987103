import { useCallback, useEffect, useState } from 'react';

import { MAX_MOBILE_SCREEN } from 'theme/breakpoints';

const useWidth = () => {
  const [width, setWidth] = useState(0);

  const handler = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    // Call it once on load, to set thet Width value;
    handler();

    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [handler]);

  return width;
};

/*
  Example usage:

  const tabletThreshold = 970;
  const mobileThreshold = 600;

  const [isLgScreen, isTablet, isMobile] = useBreakpoints({
    tablet: tabletThreshold,
    mobile: mobileThreshold
  )});
 */

// TODO: Rename "tablet" to "upperResoltion" and "mobile" to "lowerResolution" as this util is used for desktop breakpoints as well
export const useBreakpoints = ({ tablet, mobile }) => {
  const windowWidth = useWidth();

  if (windowWidth > tablet) {
    return [true, false, false];
  }

  if (windowWidth <= tablet && windowWidth > mobile) {
    return [false, true, false];
  }

  if (windowWidth <= mobile) {
    return [false, false, true];
  }
};

export const useIsMobile = () => {
  const windowWidth = useWidth();

  if (windowWidth <= MAX_MOBILE_SCREEN) {
    return true;
  }

  return false;
};
