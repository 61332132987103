import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';

import { TEACHERS_MEETING_FEED_URL } from 'config/urls';
import _ from 'lodash';
import { useMeetingBoards } from 'sdk';
import { noopPromise } from 'utils';

import { getMeetingTitle } from 'pages/Teachers/MeetingFeed/utils';
import { TopBarSelect, useNavbar } from 'pages/Teachers/shared';
import { default as pusherManager } from 'utils/integrations/pusher';
import { notifyErrors } from 'utils/notifications';
import { useFetch } from 'utils/sdk';
import { parseParams, reverse, stringifyParams } from 'utils/urls';

import UserContext from 'components/UserContext';

import {
  createColumn,
  deleteColumn,
  getBoardDetail,
  getMeetingBoardDetail,
  getMeetingDetail,
  updateColumnName as updateColumnNameSdk
} from './sdk';
import { buildSortsOptions, getSortLabel } from './utils';

export const useMeetingDetail = (meetingId) => {
  const sdk = useMemo(
    () => (meetingId ? getMeetingDetail : noopPromise),
    [meetingId]
  );

  const { data } = useFetch(sdk, { data: [] }, meetingId);

  return data;
};

export const useSorts = ({ meetingId }) => {
  const [selectedSort, setSelectedSort] = useState(null);

  const history = useHistory();
  const queryParams = useMemo(
    () =>
      parseParams(history.location.search, {
        parseNumbers: true
      }),
    [history.location.search]
  );

  const {
    data,
    error,
    refetch: refetchSorts,
    isLoading: loading
  } = useMeetingBoards({
    selectedMeetingId: meetingId,
    queryParams
  });

  const sorts = useMemo(() => data || [], [data]);

  const selectSort = useCallback(
    (sortId) => {
      setSelectedSort(_.find(sorts, { id: sortId }));
      history.replace({ search: stringifyParams({ sortId }) });
    },
    [sorts, history]
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    if (error) {
      setSelectedSort(null);
      notifyErrors(error);
    }
  }, [loading, error]);

  useEffect(() => {
    if (loading || !_.isNil(selectedSort)) {
      return;
    }

    const sortIdFromParams = _.get(queryParams, 'sortId');
    const sortFromParams = _.find(sorts, { id: sortIdFromParams });

    const sortToSelect = sortFromParams || _.head(sorts);

    selectSort(_.get(sortToSelect, 'id'));
  }, [
    loading,
    history.location.search,
    selectedSort,
    selectSort,
    sorts,
    queryParams
  ]);

  return { sorts, refetchSorts, loading, selectedSort, selectSort };
};

export const useNavigation = ({
  meeting,
  sorts,
  loadingSorts,
  selectedSortId,
  onSortSelect,
  onSelectOpen
}) => {
  const history = useHistory();

  const redirectBackToMeetingFeed = useCallback(
    () =>
      history.push({
        pathname: reverse(TEACHERS_MEETING_FEED_URL, {
          invitationIdentifier: meeting.invitation_identifier
        })
      }),
    [meeting, history]
  );

  const { navigation } = useNavbar({
    title: getMeetingTitle(meeting),
    onTitleClick: redirectBackToMeetingFeed,
    onBackButtonClick: redirectBackToMeetingFeed
  });

  const renderValue = useCallback(
    (value) => {
      const sort = _.find(sorts, { id: value });
      return getSortLabel(sort);
    },
    [sorts]
  );

  useEffect(() => {
    if (!_.isEmpty(sorts) && selectedSortId) {
      navigation.setLeftSubheader(
        <TopBarSelect
          value={selectedSortId}
          onChange={onSortSelect}
          renderValue={renderValue}
          options={buildSortsOptions(sorts)}
          onOpen={onSelectOpen}
          optionsLoading={loadingSorts}
        />
      );
    }
  }, [
    navigation,
    sorts,
    selectedSortId,
    onSortSelect,
    renderValue,
    onSelectOpen,
    loadingSorts
  ]);
};

export const useSortInfo = (selectedSortId) => {
  const [sortInfo, setSortInfo] = useState(null);

  const sdk = useMemo(
    () => (selectedSortId ? getMeetingBoardDetail : noopPromise),
    [selectedSortId]
  );

  const { data, loading } = useFetch(
    sdk,
    { data: {}, loading: true },
    selectedSortId
  );

  useEffect(() => {
    if (!loading && !_.isNil(data)) {
      setSortInfo(data);
    }
  }, [loading, data]);

  useEffect(() => setSortInfo(null), [selectedSortId]);

  return [loading, sortInfo];
};

export const useBoardColumns = (boardId) => {
  const [columns, setColumns] = useState(null);
  const [columnsOrder, setColumnsOrder] = useState([]);
  const [fetchBoardColumns, setFetchBoardColumns] = useState(false);

  useEffect(() => {
    const getColumnsForBoard = async () => {
      const { success, data, errors } = await getBoardDetail(boardId);

      if (success) {
        const { columns, columns_order } = data;
        setColumns(_.keyBy(columns, 'id'));
        setColumnsOrder(columns_order);
      } else {
        notifyErrors(errors);
      }

      setFetchBoardColumns(false);
    };

    if (boardId && fetchBoardColumns) {
      getColumnsForBoard();
    }
  }, [boardId, fetchBoardColumns]);

  const refetchBoardColumns = useCallback(() => setFetchBoardColumns(true), []);

  const updateColumnName = useCallback(
    (columnId, oldName, newName) => {
      // Do not perform unnecessary updates if the name stayed the same.
      if (oldName !== newName) {
        setColumns((prevColumns) => ({
          ...prevColumns,
          [columnId]: { ...prevColumns[columnId], name: newName }
        }));

        updateColumnNameSdk(columnId, { name: newName });
      }
    },
    [setColumns]
  );

  const removeColumn = useCallback(
    (columnId) => {
      setColumns((prevColumns) => _.omit(prevColumns, columnId));
      setColumnsOrder((prevColumnsOrder) =>
        _.without(prevColumnsOrder, columnId)
      );
      deleteColumn(columnId);
    },
    [setColumns, setColumnsOrder]
  );

  const addNewColumn = useCallback(
    (name) => {
      // Place the newly created column as a last one
      const newColumnData = {
        name,
        board: boardId,
        order: columnsOrder.length
      };

      createColumn(newColumnData).then(({ success, data, errors }) => {
        if (success) {
          const { id: newColumnId } = data;
          setColumns((prevColumns) => ({
            ...prevColumns,
            [newColumnId]: data
          }));
          setColumnsOrder((prevColumnsOrder) => [
            ...prevColumnsOrder,
            newColumnId
          ]);
        } else {
          notifyErrors(errors);
        }
      });
    },
    [boardId, columnsOrder, setColumns, setColumnsOrder]
  );

  return {
    columns,
    setColumns,
    columnsOrder,
    setColumnsOrder,
    refetchBoardColumns,
    addNewColumn,
    removeColumn,
    updateColumnName
  };
};

export const usePusherManager = (boardId, refetchBoardColumns) => {
  const { user: currentUser } = useContext(UserContext);

  const onBoardUpdateWebhookEvent = useCallback(
    ({ user_id }) => {
      const isAnotherUser = user_id !== currentUser.id;

      if (isAnotherUser) {
        refetchBoardColumns();
        // notifyInfo(message);
      }
    },
    [refetchBoardColumns, currentUser.id]
  );

  useEffect(() => {
    if (!_.isNil(boardId)) {
      pusherManager.listenForBoardUpdates(boardId, onBoardUpdateWebhookEvent);
      pusherManager.listenForBoardForceRerenders(boardId, refetchBoardColumns);

      return () => pusherManager.disconnect();
    }
  }, [boardId, onBoardUpdateWebhookEvent, refetchBoardColumns]);
};
