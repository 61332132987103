import React from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import InputField from 'components/InputField';

const useStyles = makeStyles({
  input: {
    height: '100%',
    minHeight: 40,
    '& .MuiInputBase-root': {
      minHeight: 40
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: `${colors.grey4} !important`
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${colors.grey4} !important`
    },
    '& .Mui-focused.MuiInput-underline:after': {
      borderBottomColor: `${colors.grey2} !important`
    },
    '& input': {
      padding: '0 10px !important' // Mui Autocomplete sets it to '6px 0' by deafult
    }
  },
  searchIcon: {
    marginLeft: 10,
    marginRight: 0,
    '& svg': { fill: colors.grey4 }
  },
  inputHasValue: {
    '& .MuiInput-underline:before': {
      borderBottomColor: `${colors.blue4} !important`
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${colors.blue4} !important`
    },
    '& .Mui-focused.MuiInput-underline:after': {
      borderBottomColor: `${colors.blue4} !important`
    }
  },
  withWhiteBackground: {
    backgroundColor: colors.white
  },
  searchIconHasValue: {
    '& svg': { fill: colors.grey2 }
  },
  disabled: {
    backgroundColor: `${colors.grey6} !important`
  },
  chipRoot: {
    textOverflow: 'ellipsis',
    borderRadius: 5
  },
  chipLabel: {
    maxWidth: 150
  }
});

const getAdornment = ({ hasValue, styles }) => {
  if (hasValue) {
    return;
  }

  return {
    endAdornment: (
      <InputAdornment
        position="start"
        className={classnames(styles.searchIcon, {
          [styles.searchIconHasValue]: hasValue
        })}
      >
        <SearchRoundedIcon />
      </InputAdornment>
    )
  };
};

// TODO: Add variants if needed
const SearchFilter = ({
  placeholder,
  value,
  withWhiteBackground = false,
  ...rest
}) => {
  const hasValue = !_.isEmpty(value);
  const styles = useStyles();

  const adornment = getAdornment({ hasValue, styles });

  placeholder = hasValue ? '' : placeholder;

  const renderInput = (params) => {
    const enhancedParams = {
      ...params,
      InputProps: {
        ...params.InputProps,
        ...adornment
      },
      variant: 'underlined',
      placeholder: placeholder,
      value: value,
      className: classnames(styles.input, {
        [styles.inputHasValue]: hasValue,
        [styles.disabled]: rest.disabled,
        [styles.withWhiteBackground]: withWhiteBackground
      })
    };

    return <InputField {...enhancedParams} />;
  };

  return (
    <Autocomplete
      forcePopupIcon={false}
      disableClearable={!hasValue}
      renderInput={renderInput}
      value={value}
      ChipProps={{
        classes: {
          root: styles.chipRoot,
          label: styles.chipLabel
        },
        size: 'small',
        ...rest.ChipProps
      }}
      {...rest}
    />
  );
};

export default SearchFilter;
