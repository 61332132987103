import React, { useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'theme/palette';

const useStyles = (hasValue) =>
  makeStyles(() => {
    return {
      root: {
        '& .MuiSvgIcon-root': {
          display: 'none'
        },
        '& .MuiTextField-root': {
          width: '100%',
          height: '24px',

          border: '0px',
          padding: '0px'
        },

        '& .MuiFilledInput-root': {
          height: '24px ',

          borderRadius: '5px 5px 1px 1px',
          backgroundColor: hasValue ? colors.blue6 : colors.white,

          '&:hover': {
            backgroundColor: colors.grey6
          }
        },

        '& .MuiFilledInput-underline:not(.Mui-disabled):after': {
          borderBottomColor: hasValue ? colors.blue1 : colors.grey4,
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid'
        },

        '& .MuiFilledInput-underline:hover:before': {
          borderBottomColor: `${colors.blue4}`,
          borderBottomWidth: '1px'
        },

        '& .MuiFilledInput-underline:hover:after': {
          borderBottomColor: `${colors.blue4}`,
          borderBottomWidth: '1px'
        },

        '& .MuiFilledInput-input': {
          padding: '4px',
          fontFamily: 'Nunito',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '13px',
          lineHeight: '15px',
          color: hasValue ? 'unset' : colors.grey1,

          '&:hover': {
            color: colors.blue1
          }
        },
        '& .MuiList-root .MuiMenu-list': {
          maxWidth: '93px'
        }
      },
      list: {
        width: '93px',
        padding: 'unset',

        '& .MuiMenuItem-root': {
          padding: '4px 10px',
          color: colors.grey2,

          fontFamily: 'Nunito',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '13px',
          lineHeight: '15px'
        },

        '& .MuiListItem-root.Mui-selected': {
          backgroundColor: colors.blue6,
          color: colors.grey1
        }
      }
    };
  });

const SelectFilter = ({ value, options, onChange }) => {
  const [valueChanged, setValueChanged] = useState(false);

  const styles = useStyles(valueChanged)();

  return (
    <div className={styles.root}>
      <TextField
        select
        value={value}
        variant="filled"
        SelectProps={{
          MenuProps: {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            classes: {
              list: styles.list
            }
          }
        }}
        onChange={onChange}
      >
        {options.map((option) => (
          <MenuItem
            key={option.key}
            value={option.value}
            onClick={() => setValueChanged(true)}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default SelectFilter;
