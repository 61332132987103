import { BASE_URL } from 'config/urls';

import { callUrl, get, post, requestSdk } from 'utils/sdk';

export const getUserTeams = (params) =>
  callUrl(get, `${BASE_URL}/v1/meetings/teams/`, params);

export const getTeamMeetings = (teamId) =>
  requestSdk(() => get(`${BASE_URL}/v1/meetings/teams/${teamId}/meetings/`));

export const createMeetingShareBatch = (data) =>
  requestSdk(() => post(`${BASE_URL}/v1/meetings/share/create-batch/`, data));
