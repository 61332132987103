import React, { useContext, useMemo } from 'react';

import _ from 'lodash';
import { usePagination, useStudentPortfolio } from 'sdk';

import InfiniteScroll from 'pages/Students/InfiniteScroll';
import { splitAssignmentsToMonths } from 'utils/assignments';

import GridView from './components/GridView';
import NoResultsPlaceholder from 'components/NoResultsPlaceholder';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const AssignmentsDateView = ({ filters }) => {
  const { user } = useContext(UserContext);
  const studentId = user.student_id;

  const { data: initialData, isLoading: isDoingAnInitialFetch } =
    useStudentPortfolio({ studentId, filters });
  let {
    results: assignments,
    fetchMore,
    isLoading: isDoingASecondaryFetch
  } = usePagination({
    initialData
  });

  const isLoading = isDoingAnInitialFetch || isDoingASecondaryFetch;

  const monthsAssignments = useMemo(
    () => splitAssignmentsToMonths({ assignments }),
    [assignments]
  );

  return (
    <>
      {!isLoading && _.isEmpty(assignments) && (
        <div className={styles.noResultsPlaceholderContainer}>
          <NoResultsPlaceholder />
        </div>
      )}

      <div className={styles.gridViewContainer}>
        <GridView monthsAssignments={monthsAssignments} />
      </div>

      {isLoading && (
        <div className={styles.gridViewContainer}>
          <GridView.Placeholder />
        </div>
      )}

      <InfiniteScroll action={fetchMore} isLoading={isLoading} />
    </>
  );
};

export default AssignmentsDateView;
