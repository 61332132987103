import React from 'react';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import classnames from 'classnames';
import { ReactComponent as AssignedIcon } from 'images/assigned_small.svg';

import { setImgixFallbackUrl, setImgixParams } from 'utils/files';

import { ReactComponent as ArrowWithBorder } from './assets/arrow-with-border.svg';
import styles from './styles.module.scss';

const EmptyWorkPlaceholder = () => (
  <div className={styles.emptyWorkPlaceholder}>
    <SvgIcon component={AssignedIcon} />
  </div>
);

const StudentWorkCard = ({
  imageUrl,
  fallbackImageUrl = '',
  linkTo,
  onClick,
  children,
  onNextClick,
  exemplar = false,
  classes = {},
  onPreviousClick,
  stopTouchScroll = () => {}
}) => {
  const isEmptyWork = !imageUrl || imageUrl === '';

  return (
    <Card
      variant="outlined"
      className={classnames({ [styles.exemplarCard]: exemplar })}
    >
      <CardActionArea
        component="div"
        disableRipple={isEmptyWork}
        classes={{
          focusHighlight: classnames({
            [styles.disableHighlightHover]: isEmptyWork
          })
        }}
      >
        <Link to={linkTo ? linkTo : () => false}>
          {!isEmptyWork && (
            <CardMedia
              height={200}
              component="img"
              alt="Student work"
              image={setImgixParams(imageUrl)}
              onError={setImgixFallbackUrl(fallbackImageUrl)}
              onClick={onClick}
              onMouseDown={stopTouchScroll}
            />
          )}
        </Link>
        {isEmptyWork && <EmptyWorkPlaceholder />}
        {onPreviousClick && (
          <IconButton className={styles.leftArrow} onClick={onPreviousClick}>
            <SvgIcon
              component={ArrowWithBorder}
              style={{ transform: 'rotate(180deg)' }}
            />
          </IconButton>
        )}
        {onNextClick && (
          <IconButton className={styles.rightArrow} onClick={onNextClick}>
            <SvgIcon component={ArrowWithBorder} />
          </IconButton>
        )}
      </CardActionArea>
      <CardContent classes={{ root: classes.cardContent }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default React.memo(StudentWorkCard);
