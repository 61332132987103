import { useContext, useEffect } from 'react';

import _ from 'lodash';

import { notifyErrors } from 'utils/notifications';

import { WalkthroughContext } from '../';
import { startWalkthrough, trackMixpanelEvent } from './sdk';

export const useWalkthroughUser = (url) => {
  const { walkthroughUser, setWalkthroughUser } =
    useContext(WalkthroughContext);

  useEffect(() => {
    if (walkthroughUser) {
      if (!_.isNil(url)) {
        const mixpanelEventName = `Walkthrough User landed on ${url}`;
        trackMixpanelEvent({
          user_id: walkthroughUser.id,
          event_name: mixpanelEventName
        });
      }
    } else {
      startWalkthrough()
        .then(({ data }) => setWalkthroughUser(data))
        .catch(notifyErrors);
    }
  }, [url, walkthroughUser, setWalkthroughUser]);

  return { walkthroughUser, setWalkthroughUser };
};
