import { BASE_URL } from 'config/urls';

import { get, post, requestSdk } from 'utils/sdk';

export const assignmentStatus = async (assignmentId) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v1/trackers/assignments/${assignmentId}/status/`)
  );

export const studentsStatuses = async (assignmentId) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v1/trackers/assignments/${assignmentId}/students-status/`)
  );

export const getStudentsStatusesCsvDownloadUrl = (assignmentId) =>
  `${BASE_URL}/v1/trackers/assignments/${assignmentId}/students-status-csv/`;

export const bulkUpdateTasksStatus = async (assignmentId, data) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/assignments/${assignmentId}/tasks/status-bulk-update/`,
      data
    )
  );
