import React, { useCallback, useContext } from 'react';

import classnames from 'classnames';

import { colors } from 'theme/palette';
import {
  bootIntercomWidget,
  showIntercomWidget
} from 'utils/integrations/intercom';
import { useIsMobile } from 'utils/resizing';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import { ReactComponent as HelpIcon } from './assets/help.svg';
import { ReactComponent as MailIcon } from './assets/mail.svg';
import { ReactComponent as PhoneIcon } from './assets/phone.svg';
import { ReactComponent as TextBubbleIcon } from './assets/text-bubble.svg';
import styles from './styles.module.scss';

const HelpDialog = ({ onClose }) => {
  const isMobile = useIsMobile();

  const { user } = useContext(UserContext);

  const startChat = useCallback(() => {
    bootIntercomWidget({
      email: user.email,
      name: user.name
    });
    showIntercomWidget();

    onClose();
  }, [user, onClose]);

  return (
    <Dialog onClose={onClose} open classes={{ paper: styles.dialog }}>
      <div
        className={classnames(styles.container, {
          [styles.mobileContainer]: isMobile
        })}
      >
        <HelpIcon className={styles.icon} />

        <Typography
          variant="H-TEXT-2"
          color={colors.pink2}
          className={styles.title}
        >
          We're here to help!
        </Typography>

        <div className={styles.infoWrapper}>
          <MailIcon />
          <div>
            <Typography variant="S-TEXT-1" color={colors.grey4}>
              Email us:
            </Typography>
            <Button variant="link" href="mailto:support@edlight.com">
              support@edlight.com
            </Button>
          </div>
        </div>

        <div className={styles.infoWrapper}>
          <PhoneIcon />
          <div>
            <Typography variant="S-TEXT-1" color={colors.grey4}>
              Give us a call:
            </Typography>
            <Button variant="link" href="tel:617-982-3242">
              (856) 244-8113
            </Button>
          </div>
        </div>

        {window.Intercom && (
          <div className={styles.infoWrapper}>
            <TextBubbleIcon />
            <div>
              <Button variant="link" onClick={startChat}>
                Start a Chat
              </Button>
            </div>
          </div>
        )}

        <div className={styles.infoWrapper}>
          <CalendarIcon />
          <div>
            <Button
              variant="link"
              href={'https://calendly.com/edlight-collab/new-user-10-min-q-a'}
              target="_blank"
            >
              Schedule a 1:1 session
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default React.memo(HelpDialog);
