import React, { useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import InputField from 'components/InputField';
import Typography from 'components/Typography';

import { ReactComponent as CommentIcon } from './assets/add_comment.svg';
import styles from './styles.module.scss';

const TooltipContent = ({ comments, addComment, handleClose }) => {
  const [newCommentText, setNewCommentText] = useState('');

  const saveComment = () => {
    if (_.isEmpty(newCommentText)) {
      return;
    }
    addComment({ text: newCommentText });
    setNewCommentText('');
  };
  const handleEnterKey = (event) => {
    // Handle submit on `Enter` key, but preserve the default behaviour (adding a new line) if `Shift` is pressed too.
    if (event.keyCode === 13 && !event.shiftKey) {
      saveComment();
    }
  };

  return (
    <>
      <div className={styles.closeIconWrapper}>
        <div className={styles.closeIcon} onClick={handleClose}>
          <CloseRoundedIcon />
        </div>
      </div>
      <div className={styles.commentsList}>
        {_.map(comments, (comment) => (
          <span
            key={comment.id}
            className={classnames(styles.comment, {
              [styles.studentComment]: comment.user.is_student
            })}
          >
            <Typography
              component="span"
              variant="B-Text-4"
              color={colors.blue4}
            >
              {comment.user.name}
            </Typography>{' '}
            <Typography
              component="span"
              variant="B-Text-4"
              color={colors.grey1}
            >
              {comment.text}
            </Typography>
          </span>
        ))}
        {!_.isEmpty(comments) && <Divider style={{ marginBottom: 10 }} />}
      </div>
      <div className={styles.inputWrapper}>
        <InputField
          variant="underlined"
          className={styles.inputField}
          fullWidth
          placeholder="Add a comment..."
          value={newCommentText}
          onChange={(e) => setNewCommentText(e.target.value)}
          onKeyDown={handleEnterKey}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ paddingRight: 10 }}>
                <SvgIcon
                  onClick={saveComment}
                  component={CommentIcon}
                  htmlColor={
                    _.isEmpty(newCommentText) ? colors.grey4 : colors.green3
                  }
                  className="pointer"
                />
              </InputAdornment>
            )
          }}
        />
      </div>
    </>
  );
};

const CommentsBoxIcon = ({ comments, addComment }) => {
  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Tooltip
          arrow
          open={open}
          onClose={() => setOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="right-end"
          classes={{
            tooltip: styles.commentsTooltip,
            arrow: styles.tooltipArrow
          }}
          title={
            <TooltipContent
              comments={comments}
              addComment={addComment}
              handleClose={() => setOpen(false)}
            />
          }
        >
          <IconButton
            onClick={() => setOpen(!open)}
            className={styles.iconButton}
          >
            <SvgIcon
              component={CommentIcon}
              htmlColor={_.isEmpty(comments) ? colors.grey4 : colors.green3}
            />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CommentsBoxIcon;
