export const getStudentInitials = ({ student }) => {
  return `${student?.first_name?.charAt(0) ?? ''}${
    student?.last_name?.charAt(0) ?? ''
  }`;
};

export const getIndexOfStudent = ({ studentId, students }) => {
  return students.findIndex((element) => element.id === studentId);
};

export const getStudentFromId = ({ studentId, students }) => {
  for (const student of students) {
    if (student.id === studentId) {
      return student;
    }
  }
};

export const getAvatarColor = ({ index }) => {
  const colors = {
    0: 'goldenrod',
    1: 'deepskyblue',
    2: 'deeppink',
    3: 'seagreen',
    4: 'plum'
  };
  const key = index % 5;
  return colors[key];
};
