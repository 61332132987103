import React, { useCallback, useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

import { Emojis } from 'pages/Teachers/Assignments/Feedback/components/Emojis';
import { colors } from 'theme/palette';

import Image from 'components/Image';

import { ReactComponent as StickerIcon } from './assets/sticker_icon.svg';
import styles from './styles.module.scss';
import { addStickerToImage } from './utils';

const TooltipContent = ({ onStickerClick }) => (
  <div className={styles.emojiContainer}>
    {_.map(Emojis, (iconSrc) => (
      <IconButton key={iconSrc} onClick={() => onStickerClick(iconSrc)}>
        <Image src={iconSrc} />
      </IconButton>
    ))}
  </div>
);

export const StickerIconButton = ({
  onStickerClick,
  ButtonRender,
  text = null,
  stickerIconFill = colors.white,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div>
        <Tooltip
          arrow
          open={isOpen}
          onClose={() => setIsOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          classes={{
            tooltip: styles.statusTooltip,
            arrow: styles.tooltipArrow
          }}
          title={
            <TooltipContent
              onStickerClick={(stickerSrc) => {
                onStickerClick(stickerSrc);
                setIsOpen(false);
              }}
            />
          }
        >
          <IconButton onClick={() => setIsOpen(!isOpen)} {...rest}>
            <StickerIcon fill={stickerIconFill} />

            {!_.isEmpty(text) && (
              <span className={styles.textContainer}>{text}</span>
            )}
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

const StickerAddingTool = ({
  imageSrc,
  onStickerAdd,
  iconButtonClasses = {},
  ...rest
}) => {
  const handleStickerClick = useCallback(
    (stickerUrl) => addStickerToImage(imageSrc, stickerUrl).then(onStickerAdd),
    [imageSrc, onStickerAdd]
  );

  return (
    <StickerIconButton
      onStickerClick={handleStickerClick}
      classes={iconButtonClasses}
      {...rest}
    />
  );
};

export default StickerAddingTool;
