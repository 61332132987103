import React, { useState } from 'react';

import { IconButton } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Button from 'components/Button';

import styles from './styles.module.scss';

const PopoverMenu = ({ label, icon, children }) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopover(false);
  };

  return (
    <div className={styles.selectContainer}>
      {icon ? (
        <IconButton size="small" onClick={handleClick}>
          {icon}
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color={openPopover ? 'primary' : 'secondary'}
          classes={{
            root: `${
              openPopover ? styles.buttonRootActive : styles.buttonRoot
            } ${styles.buttonSpace}`
          }}
          endIcon={
            openPopover ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          }
          onClick={handleClick}
        >
          <span className={styles.buttonLabel}>{label}</span>
        </Button>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div>{children}</div>
      </Popover>
    </div>
  );
};

export default PopoverMenu;
