import React from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

import RecordButton from 'components/RecordButton';
import StickerSelector from 'components/StickerSelector';
import ZoomControlls from 'components/ZoomControlls';

import styles from './styles.module.scss';

// This way we render a maximum of 1 controlls component
const Content = ({
  recorder,
  showStickers,
  closeStickers,
  editingContext,
  zoom
}) => {
  if (recorder.isVisible) {
    return <RecordButton recorder={recorder} />;
  }

  if (showStickers) {
    return (
      <div className={styles.stickersContainer}>
        <StickerSelector
          onStickerSelected={(sticker) => {
            editingContext.addSticker(sticker);
          }}
          close={closeStickers}
        />
      </div>
    );
  }

  if (editingContext.cropping.active) {
    return (
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={editingContext.cropping.cropImage}
        className={styles.transparentButton}
      >
        Crop
      </Button>
    );
  }

  if (editingContext.filter.isActive) {
    return (
      <div className={styles.filterSliderContainer}>
        <div className={styles.filterSlider}>
          <Slider
            value={editingContext.filter.threshold}
            onChange={(e, value) => {
              editingContext.filter.setThreshold(value);
            }}
            onChangeCommitted={editingContext.filter.apply}
            min={2}
            max={20}
          />
        </div>
        <Button
          variant="contained"
          disableElevation
          onClick={() => {
            editingContext.filter.turnOff();
            editingContext.filter.remove();
          }}
          startIcon={<DeleteRoundedIcon />}
        >
          Delete
        </Button>
      </div>
    );
  }

  if (editingContext.objectDeletion.hasSelectedObject) {
    return (
      <IconButton
        onClick={editingContext.objectDeletion.apply}
        className={styles.transparentButton}
      >
        <DeleteRoundedIcon />
      </IconButton>
    );
  }

  return <ZoomControlls zoom={{ ...editingContext.zoom, ...zoom }} />;
};

const ControllsOverImage = (props) => {
  return (
    <div className={styles.container}>
      <Content {...props} />
    </div>
  );
};

export default ControllsOverImage;
