import React, { useMemo, useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import { ReactComponent as ArrowDownIcon } from './assets/arrow-down.svg';
import styles from './styles.module.scss';

const defaultMenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  getContentAnchorEl: null,
  classes: { paper: styles.menuPaper, list: styles.menuList }
};

const TopBarSelect = ({
  value,
  renderValue,
  onChange,
  options,
  className,
  MenuProps = {},
  onOpen = () => {},
  optionsLoading = false
}) => {
  const [open, setOpen] = useState(false);

  const onChangeMemo = useMemo(
    () => _.debounce((event) => onChange(event.target.value), 200),
    [onChange]
  );
  const mergedMenuProps = {
    ...defaultMenuProps,
    ...MenuProps,
    classes: {
      ..._.get(MenuProps, 'classes', {}),
      paper: classnames(
        defaultMenuProps.classes.paper,
        _.get(MenuProps, 'classes.paper')
      ),
      list: classnames(
        defaultMenuProps.classes.list,
        _.get(MenuProps, 'classes.list')
      )
    }
  };

  const handleOpen = () => {
    setOpen(true);
    onOpen();
  };

  return (
    <FormControl className={className}>
      <Select
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        disableUnderline
        value={value || ''}
        renderValue={(value) => (
          <Typography variant="H-TEXT-3" color={colors.grey2} noWrap>
            {renderValue(value)}
          </Typography>
        )}
        IconComponent={ArrowDownIcon}
        onChange={onChangeMemo}
        MenuProps={mergedMenuProps}
      >
        {optionsLoading
          ? _.range(5).map((index) => (
              <MenuItem disabled key={index}>
                <Skeleton width={120} />
              </MenuItem>
            ))
          : _.map(options, (opt, index) => (
              <MenuItem
                value={opt.value}
                key={index}
                classes={{ root: styles.item, selected: styles.itemSelected }}
              >
                <Typography variant="B-Text-2" noWrap>
                  {opt.label}
                </Typography>
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};

export default TopBarSelect;
