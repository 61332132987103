import imageCompression from 'browser-image-compression';
import FileType from 'file-type';
import _ from 'lodash';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { notifyError, notifyErrors } from 'utils/notifications';
import { post, requestSdk } from 'utils/sdk';

import {
  MAX_FILE_SIZE_AS_MEGABYTES_STRING,
  MAX_FILE_SIZE_IN_BYTES
} from './constants';
import { getPresignedPost, markUpload } from './sdk';

function confirmExit(e) {
  // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
  // Cancel the event
  e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  e.returnValue = '';
}

function validateBlob(blob, maxSize, trackMixpanelEventOnFailure = false) {
  if (!blob || !(blob instanceof Blob)) {
    notifyError('Could not upload file.');

    if (trackMixpanelEventOnFailure) {
      trackMixpanelEvent(
        'not tracked',
        'File upload validation failed. Reason: Could not upload file.'
      );
    }

    return false;
  }

  if (blob && blob.size > maxSize.sizeInBytes) {
    notifyError(
      `This file is too big, try uploading a file less than ${maxSize.sizeAsString}`
    );

    if (trackMixpanelEventOnFailure) {
      trackMixpanelEvent(
        'not tracked',
        `File upload validation failed. Reason: This file is too big, try uploading a file less than ${maxSize.sizeAsString}`,
        {
          fileSizeInBytes: blob.size
        }
      );
    }

    return false;
  }

  return true;
}

async function uploadFileToS3(blob, fileName, fileType) {
  // Don't close the browser tab if the files are not yet uploaded
  window.addEventListener('beforeunload', confirmExit);

  const {
    success,
    errors,
    data: { url, fields, identifier }
  } = await getPresignedPost({
    file_name: fileName,
    file_type: fileType
  });

  if (success) {
    let postParams = {};

    // If we are uploading to S3, detach authorization cookie
    if (!fields.file_instance) {
      postParams = {
        withCredentials: false
      };
    }
    const postData = new FormData();

    for (let key in fields) {
      postData.append(key, fields[key]);
    }

    postData.append('file', blob);

    const { errors: uploadErrors, success: uploadSuccess } = await requestSdk(
      () => post(url, postData, postParams)
    );

    if (!uploadSuccess) {
      await markUpload(identifier, false); // If this fails - we don't get notified
      notifyError("Couldn't upload your file.");
      !_.isEmpty(uploadErrors) && notifyErrors(uploadErrors);

      window.removeEventListener('beforeunload', confirmExit);
      return;
    }

    await markUpload(identifier, true); // If this fails - we don't get notified
  } else {
    notifyErrors(errors);
  }

  window.removeEventListener('beforeunload', confirmExit);

  return identifier;
}

export async function uploadFile({
  blob,
  name,
  maxSize = {
    sizeInBytes: MAX_FILE_SIZE_IN_BYTES,
    sizeAsString: MAX_FILE_SIZE_AS_MEGABYTES_STRING
  },
  trackMixpanelEventOnFailure = false
}) {
  const valid = validateBlob(blob, maxSize, trackMixpanelEventOnFailure);
  if (!valid) {
    return;
  }

  const identifier = await uploadFileToS3(blob, name, blob.type);

  return identifier;
}

export async function uploadImage({
  blob,
  name,
  guessExtension = true,
  maxSize = {
    sizeInBytes: MAX_FILE_SIZE_IN_BYTES,
    sizeAsString: MAX_FILE_SIZE_AS_MEGABYTES_STRING
  }
}) {
  const valid = validateBlob(blob, maxSize);
  if (!valid) {
    return;
  }

  const fileData = await getFileData(blob);

  if (!_.startsWith(fileData.mime, 'image/')) {
    notifyError('Could not upload file.');
    return;
  }

  const fileName = guessExtension ? `${name}.${fileData.ext}` : name;

  const identifier = await uploadFileToS3(blob, fileName, fileData.mime);

  return identifier;
}

async function getFileData(blob) {
  const readFilePromise = () =>
    new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;

      reader.readAsArrayBuffer(blob);
    });

  const fileBuffer = await readFilePromise();

  if (fileBuffer) {
    const fileData = await FileType.fromBuffer(fileBuffer);

    return fileData;
  }
}

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result);
      } else {
        reject(Error('Failed converting to base64'));
      }
    };
  });
}

function getImageDimensions(file) {
  return new Promise((resolve, reject) => {
    const { createObjectURL, revokeObjectURL } = window.URL || window.webkitURL;

    const img = new Image();
    img.onload = () => {
      revokeObjectURL(img.src);
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      revokeObjectURL(img.src);
      reject(Error('not a valid file: ' + file.type));
    };

    img.src = createObjectURL(file);
  });
}

export function compressImage(
  image,
  { maxSizeMB, maxWidth, maxHeight, useWebWorker = true }
) {
  return getImageDimensions(image).then(({ width, height }) => {
    const maxWidthOrHeight =
      maxWidth && maxHeight && width > height ? maxWidth : maxHeight;

    return imageCompression(image, {
      maxSizeMB,
      maxWidthOrHeight,
      useWebWorker
    });
  });
}

export { setImgixParams, setImgixFallbackUrl } from './imgix';
