import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import { KeyboardDatePicker } from '@material-ui/pickers';
import classnames from 'classnames';

import InputField from 'components/InputField';

const useStyles = makeStyles(() => ({
  field: {
    '& .MuiInputBase-root > input': {
      paddingRight: 0
    },
    '& .MuiInputBase-root > .MuiInputAdornment-root button': { padding: 6 }
  }
}));

const defaultProps = {
  variant: 'inline',
  disablePast: true,
  format: 'MM/DD/YYYY',
  inputVariant: 'underlined' // "standard" | "outlined" | "filled"
};

const DateField = (props) => {
  const styles = useStyles();

  return (
    <KeyboardDatePicker
      {...defaultProps}
      {...props}
      autoOk
      className={classnames(styles.field, props.className)}
      TextFieldComponent={InputField}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <TodayRoundedIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default DateField;
