import React, { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { useStudentAssignments, useStudentSections } from 'sdk';
import { getStandardButtonLabel } from 'utils';

import { STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTIONS } from 'utils/constants';
import { useDialog } from 'utils/hooks';

import ClassesSearchFilter from './components/ClassesSearchFilter';
import DateField from './components/DateField';
import SearchFilterComponent from './components/SearchFilter';
import SelectFilter from './components/SelectFilter';
import StandardFilter from './components/StandardFilter';
import Button from 'components/Button';
import StandardDialog from 'components/StandardDialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';
import { getObjectName } from './utils';

const Filters = ({
  initialFilters,
  filters,
  standards,
  setFilters,
  student,
  assignmentsCount,
  renderStandardsFilter = false
}) => {
  const { data: studentSections } = useStudentSections({
    studentId: student.id
  });
  const { data: studentAssignments } = useStudentAssignments({
    studentId: student.id
  });

  const [selectedStandards, setSelectedStandards] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(filters.standards)) {
      setSelectedStandards(standards.results);
    }
  }, [standards.results, filters.standards]);

  const {
    isOpened: isStandardDialogOpened,
    closeDialog: closeStandardDialog,
    openDialog: openStandardDialog
  } = useDialog();

  const classFilterOptions = useMemo(
    () =>
      _.filter(
        studentSections,
        (section) => !_.some(_.get(filters, 'sections', []), section)
      ) || [],
    [studentSections, filters]
  );

  const classFilterValue = useMemo(
    () =>
      _.filter(classFilterOptions, (option) =>
        _.includes(
          _.map(_.get(filters, 'sections', []), (sectionId) =>
            parseInt(sectionId)
          ),
          option.id
        )
      ),
    [classFilterOptions, filters]
  );

  const assignmentFilterValue = useMemo(
    () =>
      _.find(
        studentAssignments,
        (option) => _.get(filters, 'assignment', '') === option.name
      ),
    [studentAssignments, filters]
  );

  if (_.isEmpty(filters) || !filters) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.studentInfo}>
          <Typography variant="H-TEXT-3">
            {_.get(student, 'grade_level.name', 'Unknown')}
          </Typography>
        </div>

        <div className={styles.filtersContainer}>
          <div className={styles.filters}>
            <Typography
              variant="B-Text-2"
              textTransform="none"
              className={styles.text}
            >
              {assignmentsCount}{' '}
              {assignmentsCount === 1 ? 'Assignment' : 'Assignments'}
            </Typography>

            <div className={styles.filter}>
              <SelectFilter
                options={STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTIONS}
                value={_.get(filters, 'status', '')}
                onChange={(e) =>
                  setFilters({ ...filters, status: e.target.value })
                }
              />
            </div>

            <Typography
              variant="B-Text-2"
              textTransform="none"
              className={styles.text}
            >
              between
            </Typography>

            <div className={styles.filter}>
              <DateField
                disablePast={false}
                value={_.get(filters, 'startDate')}
                onChange={(e, value) =>
                  setFilters({ ...filters, startDate: value })
                }
              />
            </div>

            <Typography
              variant="B-Text-2"
              textTransform="none"
              className={styles.text}
            >
              and
            </Typography>

            <div className={styles.filter}>
              <DateField
                disablePast={false}
                value={_.get(filters, 'endDate')}
                onChange={(e, value) =>
                  setFilters({ ...filters, endDate: value })
                }
              />
            </div>
          </div>
          <div className={styles.filters}>
            {renderStandardsFilter && (
              <>
                <Typography
                  variant="B-Text-2"
                  textTransform="none"
                  className={styles.text}
                >
                  on
                </Typography>
                <div className={styles.filter}>
                  <StandardFilter
                    label={
                      _.isEmpty(selectedStandards)
                        ? 'All Standards'
                        : getStandardButtonLabel({
                            standards: selectedStandards
                          })
                    }
                    onClick={openStandardDialog}
                  />
                </div>
              </>
            )}
            <Typography
              variant="B-Text-2"
              textTransform="none"
              className={styles.text}
            >
              and
            </Typography>
            <div className={styles.filter}>
              <SearchFilterComponent
                freeSolo
                autoComplete
                placeholder="All Assignments"
                options={studentAssignments || []}
                value={assignmentFilterValue || ''}
                onChange={(e, value) => {
                  setFilters({ ...filters, assignment: value?.name || '' });
                }}
                getOptionLabel={getObjectName}
              />
            </div>

            <Typography
              variant="B-Text-2"
              textTransform="none"
              className={styles.text}
            >
              in
            </Typography>

            <div className={styles.filter}>
              <ClassesSearchFilter
                multiple
                limitTags={1}
                placeholder="Class Name"
                options={classFilterOptions || []}
                getOptionLabel={getObjectName}
                value={classFilterValue}
                onChange={(e, value) => {
                  setFilters({ ...filters, sections: _.map(value, 'id') });
                }}
              />
            </div>

            {!_.isEqual(initialFilters, filters) && (
              <div className={styles.resetFilters}>
                <Button
                  variant="small"
                  onClick={() => {
                    setFilters(initialFilters);
                    setSelectedStandards([]);
                  }}
                >
                  Reset <span>filters</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isStandardDialogOpened && (
        <StandardDialog
          selectedItems={selectedStandards}
          saveButtonLabel="Filter"
          onClose={closeStandardDialog}
          onSave={({ selectedStandards }) => {
            setFilters({
              ...filters,
              standards: _.map(selectedStandards, 'id')
            });
            setSelectedStandards(selectedStandards);
          }}
        />
      )}
    </div>
  );
};

export default Filters;
