import React, { useContext, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { throttle } from 'lodash';

import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import UserContext from 'components/UserContext';

import PieChartDivider from '../PieChartDivider';
import styles from './styles.module.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartDoughnut = ({
  answers,
  student_count,
  strategy_name,
  isSelected,
  setSelectedRubric,
  dark
}) => {
  const { user } = useContext(UserContext);
  const chartRef = useRef(null);

  const container = {
    backgroundColor: isSelected ? colors.white : colors.blue0,
    width: '300px',
    padding: '35px'
  };

  const circleContainerStyle = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    bottom: 4,
    zIndex: 1,
    padding: '25%',
    pointerEvents: 'none'
  };

  const textStyle = {
    color: colors.blue1,
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily: 'Nunito'
  };

  Tooltip.positioners.top = function (items) {
    // Custom positioner needed to place the tooltip always on top
    // since otherwise it overlaps with the text in the middle and it becomes unreadable
    const pos = Tooltip.positioners.average(items);

    if (pos === false) {
      return false;
    }

    const chart = this.chart;

    return {
      x: pos.x,
      y: chart.chartArea.top,
      xAlign: 'center',
      yAlign: 'bottom'
    };
  };

  const getPaletteIndex = ({ strategyName }) =>
    ({
      'General conceptual understanding but work may be insufficient or contain some errors': 0,
      'Work is complete and correct': 1,
      'Significant errors in work': 2,
      'N/A - No evidence of strategy': 3
    }[strategyName]);

  const selectedPalette = [
    colors.pink5,
    colors.pink6,
    colors.pink7,
    colors.pink8
  ];
  const deselectedPalette = [
    colors.blue4,
    colors.blue7,
    colors.blue8,
    colors.blue9
  ];
  let currentPalette;
  if (dark) {
    currentPalette = isSelected
      ? selectedPalette
      : [colors.blueDark, colors.blue10, colors.blue11, colors.grey5];
  } else {
    currentPalette = isSelected ? selectedPalette : deselectedPalette;
  }

  const sortByColorPalette = (a, b) =>
    getPaletteIndex({ strategyName: a.label }) -
    getPaletteIndex({ strategyName: b.label });

  const sortedAnswers = answers.sort(sortByColorPalette);

  const labels = sortedAnswers.map((item) => item.label);
  const values = sortedAnswers.map((item) => item.value);
  const segmentColors = labels.map(
    (label) => currentPalette[getPaletteIndex({ strategyName: label })]
  );

  const widthHeight = isSelected ? 166 : 150;
  const chartWrapper = {
    position: 'relative'
  };
  if (isSelected) {
    container['width'] = '400px';
    container['display'] = 'flex';
    container['flexDirection'] = 'column';
    container['alignItems'] = 'center';
    chartWrapper['width'] = '235px';
    chartWrapper['height'] = '235px';
  }

  const remappedData = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: segmentColors,
        cutout: '70%',
        hoverOffset: 15
      }
    ]
  };

  const handleChartOnClick = (e) => {
    const chart = chartRef.current;
    const selectedElements = chart.getElementsAtEventForMode(
      e,
      'nearest',
      { intersect: true },
      false
    );

    if (selectedElements.length > 0) {
      const selectedSegment = selectedElements[0];
      if (selectedSegment) {
        const selectedSegmentIndex = selectedSegment.index;
        const selectedRubric = sortedAnswers[selectedSegmentIndex];
        setSelectedRubric(selectedRubric.label);
      }
    }
  };

  // Custom logic for allowing multiline text in the tooltip
  const generateMultilineTooltipText = (context) => {
    const label = `${context.label} ${Math.round(context.parsed * 100)}%`;
    const maxLineLength = 35;

    const words = label.split(' ');
    const wrappedLines = [];

    let currentLine = '';
    for (const word of words) {
      if ((currentLine + word).length <= maxLineLength) {
        currentLine += `${word} `;
      } else {
        wrappedLines.push(currentLine.trim());
        currentLine = `${word} `;
      }
    }

    wrappedLines.push(currentLine.trim());
    return wrappedLines;
  };

  const trackMixpanelEventOnDoughnutLabelHover = () => {
    trackMixpanelEvent(
      user.id,
      '[StrategiesOverview] Teacher hovered Strategy pie chart doughnut.',
      {
        strategy: strategy_name
      }
    );

    // Updating the animations of the chart to be "none", so that we can prevent calling  mixpanel when we hover OUT of the Doughnut label.
    chartRef.current.update('none');
  };

  let trackMixpanelEventOnDoughnutLabelHoverThrottled = throttle(
    trackMixpanelEventOnDoughnutLabelHover,
    1000,
    { leading: true, trailing: false }
  );

  const tooltipCallback = (context) => {
    /*
    This function is fired when a user hovers over the Doughnut label. 
    We are calling mixpanel here, because if we use onHover of the Doughnut itself, we will fire the hover event outside the Doughnut label, as the Doughnut is a canvas.
    */
    trackMixpanelEventOnDoughnutLabelHoverThrottled();

    return generateMultilineTooltipText(context);
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        position: 'top',
        backgroundColor: colors.blueDark,
        bodyFontColor: colors.white,
        displayColors: false,
        bodyFontSize: 14,
        callbacks: {
          label: tooltipCallback
        }
      },
      legend: {
        display: false
      }
    },
    layout: {
      padding: 20
    }
  };

  const chartOptionsSelected = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      tooltip: {
        ...chartOptions.plugins.tooltip,
        backgroundColor: colors.pink1
      }
    }
  };

  return (
    <div style={container}>
      <div style={chartWrapper}>
        <div className={styles.chartContainer}>
          <Doughnut
            ref={chartRef}
            data={remappedData}
            options={isSelected ? chartOptionsSelected : chartOptions}
            width={widthHeight}
            height={widthHeight}
            onClick={handleChartOnClick}
          />
        </div>
        <div className={styles.circleContainer} style={circleContainerStyle}>
          <span className={styles.text} style={textStyle}>
            {strategy_name}
          </span>
        </div>
      </div>
      <div>
        <p className={styles.studentsText}>{student_count} STUDENTS</p>
        <PieChartDivider color={currentPalette[0]} />
      </div>
    </div>
  );
};

export default PieChartDoughnut;
