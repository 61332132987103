import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { Field } from 'formik';
import _ from 'lodash';
import { useOrganizations } from 'sdk';

import { Typography } from 'pages/Walkthrough/components';
import { colors } from 'theme/palette';

import InputField from 'components/InputField';
import SearchFilter from 'components/SearchFilter';

import styles from './styles.module.scss';
import {
  getOrganizationFilterOptions,
  getOrganizationOptionLabel
} from './utils';

const NewSchoolFields = ({
  dirty,
  errors,
  setFieldValue,
  selectedOrganization,
  setSelectedOrganization
}) => {
  const { data: organizations } = useOrganizations();

  const handleOrganizationChange = ({ organization }) => {
    // A teacher can select from the existing organizations or can enter a new one.
    // Here we are setting selectedOrganization which we use when we submit
    // and organization field which we use for the form validation.
    if (typeof organization === 'string') {
      setSelectedOrganization({
        id: null,
        name: organization
      });
      setFieldValue('organization', organization);
    } else if (organization && organization.newOrganization) {
      // Create a new value from the user input
      setSelectedOrganization({
        id: null,
        name: organization.inputValue
      });
      setFieldValue('organization', organization.inputValue);
    } else {
      setSelectedOrganization(organization);
      setFieldValue('organization', _.get(organization, 'name', ''));
    }
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <Typography
          variant="H-TEXT-3"
          color={colors.blue1}
          className={styles.inputLabel}
        >
          District / Organization
        </Typography>
        <Field
          type="text"
          name="organization"
          error={_.has(errors, 'organization')}
          freeSolo
          autoComplete
          selectOnFocus
          clearOnBlur
          disableListWrap
          component={SearchFilter}
          placeholder="District, Network or Organization that my school is part of"
          options={organizations}
          value={selectedOrganization}
          getOptionLabel={(organization) =>
            getOrganizationOptionLabel({ organization })
          }
          renderOption={(organization) => organization.name}
          filterOptions={(options, params) =>
            getOrganizationFilterOptions({ options, params })
          }
          onChange={(event, organization) =>
            handleOrganizationChange({ organization })
          }
        />
      </div>
      <div className={styles.inputContainer}>
        <Typography
          variant="H-TEXT-3"
          color={colors.blue1}
          className={styles.inputLabel}
        >
          City
        </Typography>
        <Field
          fullWidth
          type="text"
          name="city"
          placeholder="City"
          className={styles.textField}
          component={InputField}
          variant="underlined"
          error={_.has(errors, 'city')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disabled className={styles.icon}>
                  {(errors.city || !dirty) && (
                    <CheckCircleRoundedIcon fontSize="small" />
                  )}
                  {!(errors.city || !dirty) && (
                    <CheckCircleRoundedIcon
                      fontSize="small"
                      style={{ color: colors.green3 }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    </>
  );
};

export default NewSchoolFields;
