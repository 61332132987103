import { BASE_URL } from 'config/urls';

import { callUrl, get, post, requestSdk } from 'utils/sdk';

export const getMeetingDetail = (meetingId) =>
  callUrl(get, `${BASE_URL}/v1/meetings/${meetingId}`);

export const getMeetingBoardDetail = (sortId) =>
  callUrl(get, `${BASE_URL}/v1/meetings/boards/${sortId}/`);

export const getBoardDetail = (boardId) =>
  requestSdk(() => get(`${BASE_URL}/v1/boards/${boardId}/`));

export const createColumn = async (data) =>
  requestSdk(() => post(`${BASE_URL}/v1/boards/columns/create/`, data));

export const updateBoardColumnsOrder = async (boardId, data) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/boards/${boardId}/update-columns-order/`, data)
  );

export const updateItemPosition = async (itemId, data) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/boards/items/${itemId}/update-position/`, data)
  );

export const updateColumnName = async (columnId, data) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/boards/columns/${columnId}/update-name/`, data)
  );

export const deleteColumn = async (columnId) =>
  requestSdk(() => post(`${BASE_URL}/v1/boards/columns/${columnId}/delete/`));
