import moment from 'moment-timezone';

const TIMEZONE = moment.tz.guess();

export const formatDate = (date, format = 'MM/DD/YYYY') => {
  return date ? moment(date).format(format) : 'N/A';
};

export const calendarDate = (date) =>
  moment(date).calendar(null, {
    sameDay: '[today] [at] h:mma',
    lastDay: '[yesterday] [at] h:mma',
    lastWeek: '[on] MM/DD/YYYY',
    sameElse: '[on] MM/DD/YYYY'
  });

export const localizeDate = (date, format, timezone = TIMEZONE) =>
  formatDate(moment.tz(date, timezone), format);
