import React from 'react';

import classnames from 'classnames';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StandardFilter = ({ label, onClick }) => (
  <div
    className={classnames(styles.container, {
      [styles.selected]: label !== 'All Standards'
    })}
    onClick={onClick}
  >
    <Typography variant="B-Text-3" className={styles.content}>
      {label}
    </Typography>
  </div>
);

export default StandardFilter;
