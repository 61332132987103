import React, { useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import InputField from 'components/InputField';

const useStyles = makeStyles({
  input: {
    height: 24,
    width: '150px',

    '& .MuiInput-underline:before': {
      borderBottomColor: `${colors.grey3}`,
      borderBottomWidth: '1px'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${colors.grey3}`,
      borderBottomWidth: '1px'
    },

    '& .Mui-focused.MuiInput-underline:after': {
      backgroundColor: colors.grey6,
      borderBottomColor: `${colors.grey3}`,
      borderBottomWidth: '1px'
    },

    '& .MuiInput-underline:hover:before': {
      borderBottomColor: `${colors.blue4}`,
      borderBottomWidth: '1px'
    },

    '& .MuiInput-underline:hover:after': {
      borderBottomColor: `${colors.blue4}`,
      borderBottomWidth: '1px'
    },

    '& input': {
      padding: '0 10px 0 4px !important', // Mui Autocomplete sets it to '6px 0' by deafult

      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '13px',
      lineHeight: '15px',

      '&:hover': {
        color: colors.blue1
      }
    }
  },

  applyMargin: {
    '& input': {
      marginRight: '12px'
    }
  },

  hideSvg: {
    '& svg': { display: 'none' }
  },

  searchIcon: {
    '& svg': {
      display: 'inline',
      fill: colors.grey4,

      width: '20px',
      height: '20px'
    }
  },

  searchIconHasValue: {
    '& svg': { display: 'none' }
  },

  inputHasValue: {
    '& .MuiInputBase-root': {
      backgroundColor: colors.blue6,
      paddingRight: '0',

      '&:hover': {
        backgroundColor: colors.grey6
      }
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: `${colors.blue4}`
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${colors.blue4}`
    },
    '& .Mui-focused.MuiInput-underline:after': {
      borderBottomColor: `${colors.blue4} `
    }
  },

  adornment: {
    marginLeft: 0
  },

  popper: {
    top: `-20px !important`,
    left: `25px !important`,
    maxWidth: '187px'
  },

  tooltip: {
    backgroundColor: colors.grey1,

    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '13px'
  }
});

const SearchFilter = ({ placeholder, value, ...rest }) => {
  const hasValue = !_.isEmpty(value);

  const [applyInputMargin, setApplyInputMargin] = useState(false);
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const styles = useStyles();

  const adornment = {
    startAdornment: (
      <InputAdornment
        position="end"
        classes={{ root: styles.adornment }}
        className={classnames(styles.searchIcon, {
          [styles.searchIconHasValue]: hasValue
        })}
      >
        <SearchRoundedIcon />
      </InputAdornment>
    )
  };

  const renderInput = (params) => {
    const enhancedParams = {
      ...params,
      InputProps: {
        ...params.InputProps,
        ...adornment
      },
      variant: 'underlined',
      placeholder: placeholder,
      value: value,
      className: classnames(styles.input, {
        [styles.inputHasValue]: hasValue,
        [styles.disabled]: rest.disabled,
        [styles.applyMargin]: applyInputMargin,
        [styles.hideSvg]: !applyInputMargin
      })
    };

    return <InputField {...enhancedParams} />;
  };

  return (
    <Tooltip
      title={value.name}
      classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      open={!menuIsOpened && isHover && value?.name?.length > 20}
    >
      <Autocomplete
        forcePopupIcon={false}
        disableClearable={!hasValue}
        renderInput={renderInput}
        value={value}
        onOpen={() => {
          setApplyInputMargin(true);
          setMenuIsOpened(true);
        }}
        onClose={() => {
          setApplyInputMargin(false);
          setMenuIsOpened(false);
        }}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        ChipProps={{
          classes: {
            root: styles.chipRoot
          },
          size: 'small'
        }}
        {...rest}
      />
    </Tooltip>
  );
};

export default SearchFilter;
