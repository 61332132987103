import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import _ from 'lodash';

import {
  addElement,
  addSticker,
  addTextBox,
  applyFilter,
  cropImage,
  deleteElement,
  drawInitialImageObject,
  fitImageAndCanvasToContainer,
  getInitialImage,
  groupCanvasUp,
  removeFilter,
  rotateCanvas,
  toggleDrawingMode,
  turnOffDrawingMode,
  ungroupCanvas
} from './components/EditingCanvas/utils';

const ADDED_FROM_UNDO_FIELD = '__added_from_undo';

const useCropping = (canvas, undoPush) => {
  const [active, setActive] = useState(false);
  const [cropArea, setCropArea] = useState();
  const [imageForCropping, setImageForCropping] = useState();

  const [response, setResponse] = useState({});

  // Workaround for returning an object and not having it
  // recognised as a different object from before on every render.
  useEffect(() => {
    const resetCrop = () => {
      const initialImage = getInitialImage(canvas);
      if (!initialImage) {
        return;
      }
      const scale = 1 / initialImage.scaleX;

      const timesRotated = parseInt((Math.round(initialImage.angle) / 90) % 4);
      for (let i = 0; i < timesRotated; i++) {
        rotateCanvas(canvas, -90);
      }

      const group = groupCanvasUp(canvas);
      group.set({ left: 0, top: 0, scaleX: scale, scaleY: scale });
      ungroupCanvas(canvas);

      for (let i = 0; i < timesRotated; i++) {
        rotateCanvas(canvas, 90);
      }

      // Have in mind that fabricJS sets the coordinates to a bit above zero when resetting. It's most probably a rounding error.
      // getInitialImage(canvas).set({ left: 0, top: 0 });

      canvas.renderAll();
      if (timesRotated % 2) {
        canvas.setHeight(
          canvas.getWidth() * (initialImage.width / initialImage.height)
        );
      } else {
        canvas.setHeight(
          canvas.getWidth() * (initialImage.height / initialImage.width)
        );
      }
    };

    const rotateCropArea = (canvas, times) => {
      if (times === 0) {
        // done
      }

      if (times === 1) {
        setCropArea({
          x: canvas.getWidth() - (cropArea.height + cropArea.y),
          y: cropArea.x,
          height: cropArea.width,
          width: cropArea.height
        });
      }

      if (times === 2) {
        setCropArea({
          x: canvas.getWidth() - (cropArea.width + cropArea.x),
          y: canvas.getHeight() - (cropArea.height + cropArea.y),
          height: cropArea.height,
          width: cropArea.width
        });
      }

      if (times === 3) {
        setCropArea({
          x: cropArea.y,
          y: canvas.getHeight() - (cropArea.width + cropArea.x),
          height: cropArea.width,
          width: cropArea.height
        });
      }
    };

    setResponse({
      active,
      activate: () => {
        if (!cropArea) {
          setCropArea({
            x: canvas.width * 0.1,
            y: canvas.height * 0.1,
            width: canvas.width * 0.8,
            height: canvas.height * 0.8
          });
        }

        if (active) {
          return;
        } else {
          setActive(true);
          resetCrop();
          const initialImage = getInitialImage(canvas);
          const timesImageHasBeenRotated = (initialImage.angle / 90) % 4;
          if (cropArea) {
            rotateCropArea(canvas, timesImageHasBeenRotated);
            if (
              cropArea.x + cropArea.width > canvas.getWidth() ||
              cropArea.y + cropArea.height > canvas.getHeight()
            ) {
              // Something happened. Reset crop area.
              setCropArea({
                x: canvas.width * 0.1,
                y: canvas.height * 0.1,
                width: canvas.width * 0.8,
                height: canvas.height * 0.8
              });
            }
          }
          setImageForCropping(canvas.toDataURL({ format: 'jpeg' }));
        }
      },
      cropImage: () => {
        cropImage(canvas, cropArea, setCropArea);
        setActive(false);
        setImageForCropping();
        undoPush(resetCrop);
      },
      turnOff: () => {
        setActive(false);
        setImageForCropping();
        resetCrop();
      },
      cropArea,
      setCropArea,
      imageForCropping
    });
  }, [active, canvas, cropArea, imageForCropping, undoPush]);

  return response;
};

const useRotation = (canvas, undoPush) => {
  const [response, setResponse] = useState({});

  // Workaround for returning an object and not having it
  // recognised as a different object from before on every render.
  useEffect(() => {
    setResponse({
      rotateCanvas: () => {
        rotateCanvas(canvas, 90);
        undoPush(() => rotateCanvas(canvas, -90));
      }
    });
  }, [canvas, undoPush]);

  return response;
};

const useFilter = (canvas, imageSrc, undoPush) => {
  const defaultTreshold = 4;
  const [isActive, setIsActive] = useState(false);
  const [threshold, setThreshold] = useState(defaultTreshold);
  const [response, setResponse] = useState({});

  // Workaround for returning an object and not having it
  // recognised as a different object from before on every render.
  useEffect(() => {
    const remove = () => {
      removeFilter(canvas);
      setThreshold(defaultTreshold);
    };

    setResponse({
      isActive,
      threshold,
      setThreshold,
      toggle: () => {
        if (isActive) {
          setIsActive(false);
        } else {
          applyFilter(canvas, { imageSrc, threshold });
          setIsActive(true);
        }
      },
      turnOff: () => setIsActive(false),
      apply: () => {
        applyFilter(canvas, { imageSrc, threshold });
        undoPush(remove);
      },
      remove: remove
    });
  }, [canvas, imageSrc, isActive, threshold, undoPush]);

  return response;
};

const useDraw = (canvas) => {
  const [isActive, setIsActive] = useState(false);
  const [response, setResponse] = useState({});

  // Workaround for returning an object and not having it
  // recognised as a different object from before on every render.
  useEffect(() => {
    setResponse({
      isActive,
      toggle: () => {
        toggleDrawingMode(canvas);
        setIsActive(!isActive);
      },
      turnOff: () => {
        turnOffDrawingMode(canvas);
        setIsActive(false);
      }
    });
  }, [canvas, isActive]);

  return response;
};

const useTextBox = (canvas) => {
  const [response, setResponse] = useState({});

  // Workaround for returning an object and not having it
  // recognised as a different object from before on every render.
  useEffect(() => {
    setResponse({
      addTextBox: () => addTextBox(canvas)
    });
  }, [canvas]);

  return response;
};

const useObjectDeletion = (canvas, undoPush) => {
  const [selectedObject, setSelectedObject] = useState();
  const [response, setResponse] = useState({});

  const deleteSelectedObject = useCallback(() => {
    if (canvas) {
      deleteElement(canvas, { element: selectedObject });
      if (selectedObject) {
        selectedObject[ADDED_FROM_UNDO_FIELD] = true; // marks the element in order to prevent from infinite loop. Check useUndo
        undoPush(() => addElement(canvas, { element: selectedObject }));
      }
      setSelectedObject();
    }
  }, [setSelectedObject, canvas, selectedObject, undoPush]);

  // Workaround for returning an object and not having it
  // recognised as a different object from before on every render.
  useEffect(() => {
    setResponse({
      hasSelectedObject: !!selectedObject,
      setSelectedObject,
      apply: deleteSelectedObject
    });
  }, [selectedObject, deleteSelectedObject]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const deleteKeyPressed =
        event.key === 'Backspace' || event.key === 'Delete';
      const notInput =
        !(event.target instanceof HTMLTextAreaElement) &&
        !(event.target instanceof HTMLInputElement);

      if (deleteKeyPressed && notInput) {
        deleteSelectedObject();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [deleteSelectedObject]);

  return response;
};

const useZoom = (canvas) => {
  const [response, setResponse] = useState({});
  const [resetTransform, setResetTransform] = useState(() => () => {});

  useEffect(() => {
    setResponse({
      zoomToNormal: resetTransform,
      setAction: setResetTransform
    });
  }, [canvas, resetTransform]);

  return response;
};

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const useUndo = (canvas) => {
  const [undoStack, setUndoStack] = useState([]);
  const [response, setResponse] = useState({});

  const push = useCallback(
    (action) => setUndoStack((prev) => [action, ...prev]),
    []
  );
  const pop = useCallback(() => {
    const action = _.head(undoStack);
    action && action();

    setUndoStack((prev) => _.drop(prev));
  }, [undoStack]);

  useEffect(() => {
    if (canvas) {
      canvas.on('object:added', (event) => {
        const element = event.target;

        if (element) {
          if (element.get('type') === 'image') {
            // The 'image' type object is the background canvas
            return;
          }
          if (_.get(element, ADDED_FROM_UNDO_FIELD)) {
            return;
          }

          // Handles drawings, text and emojis
          push(() => deleteElement(canvas, { element }));
        }
      });
    }
  }, [canvas, push]);

  useEffect(() => {
    setResponse({
      isEmpty: _.isEmpty(undoStack),
      clear: () => setUndoStack([]),
      push,
      pop
    });
  }, [undoStack, push, pop]);

  return response;
};

export const useFastContext = () => {
  const [canvas, setCanvas] = useState();
  const [response, setResponse] = useState({ setCanvas, isReady: false });

  useEffect(() => {
    if (canvas) {
      setResponse({
        canvas,
        imageBlobFromCanvas: () =>
          new Promise((resolve) =>
            canvas.getElement().toBlob((blob) => resolve(blob))
          ),
        drawInitialImageObject: (imageObject) =>
          drawInitialImageObject(canvas, imageObject),
        discardSelection: () =>
          canvas && canvas.discardActiveObject().renderAll(),
        hasNewObjects: () => {
          if (!canvas) {
            return false;
          }
          const objects = canvas.getObjects();
          // The canvas itself is part of the `_objects` array. We exclude it with the below filter.
          // return !_.isEmpty(_.filter(objects, x => x.get('type') !== 'image'));
          return !_.isEmpty(_.filter(objects, (obj) => !obj.isInitialImage));
        },
        isReady: !_.isNil(canvas),
        addSticker: (sticker) => {
          addSticker(canvas, sticker);
        }
      });
    }
  }, [canvas]);

  return response;
};

export const useEditingContext = (imageSrc) => {
  const [canvas, setCanvas] = useState();

  const undo = useUndo(canvas);
  const cropping = useCropping(canvas, undo.push);
  const rotation = useRotation(canvas, undo.push);
  const filter = useFilter(canvas, imageSrc, undo.push);
  const draw = useDraw(canvas);
  const textBox = useTextBox(canvas);
  const objectDeletion = useObjectDeletion(canvas, undo.push);
  const zoom = useZoom(canvas);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (canvas) {
      fitImageAndCanvasToContainer(canvas);
    }
  }, [windowSize, canvas]);

  const setCropArea = cropping.setCropArea;
  useEffect(() => {
    // The image has been reset
    if (setCropArea) {
      // Reset the crop area
      setCropArea();
    }
  }, [imageSrc, setCropArea]);

  const setSelectedObject = objectDeletion.setSelectedObject;

  useEffect(() => {
    if (canvas) {
      canvas.on('selection:created', () => {
        setSelectedObject(canvas.getActiveObject());
      });
      canvas.on('selection:cleared', () => {
        setSelectedObject();
      });
    }
  }, [canvas, setSelectedObject]);

  const addStickerToCanvas = useCallback(
    (sticker) => {
      addSticker(canvas, sticker);
    },
    [canvas]
  );

  return {
    cropping,
    rotation,
    filter,
    draw,
    textBox,
    objectDeletion,
    zoom,
    canvas,
    setCanvas,
    undo,
    addSticker: addStickerToCanvas
  };
};
