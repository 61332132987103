import { BASE_URL } from 'config/urls';

import { get, post, requestSdk } from 'utils/sdk';

export const recentSectionsList = async () => {
  const url = `${BASE_URL}/v1/trackers/assignments/recent-sections/`;
  return await requestSdk(() => get(url));
};

export const allSectionsList = () => {
  const url = `${BASE_URL}/v1/sections/user/list/`;
  return requestSdk(() => get(url));
};

export const assignmentAssign = async (trackerId, sectionsData) => {
  const url = `${BASE_URL}/v1/trackers/assignments/${trackerId}/assign/`;

  return await requestSdk(() => post(url, sectionsData));
};
