import React, { useCallback } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import LinkIcon from '@material-ui/icons/Link';
import { useFormik } from 'formik';
import _ from 'lodash';
import { addMeetingAgendas } from 'sdk';
import * as yup from 'yup';

import { colors } from 'theme/palette';

import AgendaFile from 'components/AgendaFileUpload';
import { useAgendaFile } from 'components/AgendaFileUpload/hooks';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const validationSchema = yup.object().shape({
  url: yup.string().url()
});

const AddAgendaDialog = ({ meetingId, onSuccess, onClose }) => {
  const {
    agendaFile,
    agendaFileIsUploading,
    onAgendaFileDrop,
    onAgendaFileDropRejected,
    onAgendaFileRemove
  } = useAgendaFile();

  const onSubmit = useCallback(
    async (values) => {
      const data = {};

      if (agendaFile) data.file = agendaFile.id;
      if (values.url) data.url = values.url;

      const agendas = await addMeetingAgendas({ meetingId, data });

      onSuccess(agendas.data);
      onClose();
    },
    [meetingId, agendaFile, onSuccess, onClose]
  );

  const {
    values: formikValues,
    isSubmitting: formikIsSubmitting,
    dirty: formikDirty,
    errors: formikErrors,
    handleSubmit: formikHandleSubmit,
    handleChange: formikHandleChange
  } = useFormik({
    initialValues: { url: '' },
    validationSchema,
    onSubmit
  });

  const isSubmitDisabled =
    formikIsSubmitting ||
    (!formikDirty && !agendaFile) ||
    !_.isEmpty(formikErrors) ||
    agendaFileIsUploading;

  return (
    <Dialog
      open
      alignTop
      maxWidth="md"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <div className={styles.headerWrapper}>
        <Typography variant="H-TEXT-2">Agenda</Typography>
        <Button
          color="pink"
          disabled={isSubmitDisabled}
          onClick={formikHandleSubmit}
        >
          Save
        </Button>
      </div>

      <div className={styles.formWrapper}>
        <InputField
          className={styles.urlInput}
          fullWidth
          autoFocus
          name="url"
          placeholder="Link to Agenda"
          variant="underlined"
          value={formikValues.url}
          onChange={formikHandleChange}
          error={formikErrors.url}
          helperText={formikErrors.url && 'Enter a valid agenda URL'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className={styles.searchIcon}>
                <LinkIcon fontSize="small" htmlColor={colors.grey4} />
              </InputAdornment>
            )
          }}
        />

        <AgendaFile
          agendaFile={agendaFile}
          onAgendaFileDrop={onAgendaFileDrop}
          onAgendaFileDropRejected={onAgendaFileDropRejected}
          onRemove={onAgendaFileRemove}
        />
      </div>
    </Dialog>
  );
};

export default AddAgendaDialog;
