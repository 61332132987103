import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import { getStrategiesOverviewExportUrl, useSectionStrategies } from 'sdk';

import { OVERALL_RESPONSE_LABEL } from 'pages/MisconceptionsDashboard/constants';
import LoadingContainer from 'pages/Teachers/StrategiesOverview/components/LoadingContainer';
import PieChartDoughnut from 'pages/Teachers/StrategiesOverview/components/PieChart';
import RubricsBreakdown from 'pages/Teachers/StrategiesOverview/components/RubricsBreakdown';
import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { openInNewTab } from 'utils/urls';

import ExportButton from 'components/ExportButton';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import SortFilterComponent from '../SortFilterDialog';
import styles from './styles.module.scss';

const MultiPieChartContainer = ({
  selectedSection,
  selectedGrade,
  selectedUnit,
  selectedLesson
}) => {
  const { user } = useContext(UserContext);

  const { data: strategiesData, isLoading: isLoadingStrategies } =
    useSectionStrategies({
      sectionId: selectedSection.id,
      params: {
        grade: selectedGrade.name,
        unit: selectedUnit.name,
        lesson: selectedLesson.name
      }
    });

  const savedFilterSearch = localStorage.getItem(`strategiesFilterSearch`);
  const intialFilterSortParams = useMemo(() => {
    if (savedFilterSearch) {
      return JSON.parse(savedFilterSearch);
    } else {
      return {
        ascending: true,
        sort_by: 'name_first'
      };
    }
  }, [savedFilterSearch]);

  const [filterSortParams, setFilterSortParams] = useState(
    intialFilterSortParams
  );

  const strategies = useMemo(() => {
    if (!strategiesData) return [];
    return strategiesData['strategies'];
  }, [strategiesData]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(undefined);
  const [selectedRubric, setSelectedRubric] = useState('');
  const [startIndex, setStartIndex] = useState(1);
  const [selectedStrategyIndex, setSelectedStrategyIndex] = useState(undefined);

  const strategiesTabs = useMemo(
    () =>
      strategies
        ? strategies
            .slice(startIndex, strategies.length)
            .concat(strategies.slice(1, startIndex))
        : [],
    [strategies, startIndex]
  );

  const refsByIndex = useMemo(() => {
    return strategiesTabs.map(() => React.createRef());
  }, [strategiesTabs]);

  const showLeftArrow = useMemo(() => {
    if (_.isEmpty(strategiesTabs) || strategiesTabs.length <= 2) {
      return false;
    }

    return true;
  }, [strategiesTabs]);

  useEffect(() => {
    if (
      typeof selectedTabIndex !== 'undefined' &&
      selectedTabIndex !== -1 &&
      refsByIndex[selectedTabIndex]
    ) {
      refsByIndex[selectedTabIndex].current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [selectedTabIndex, refsByIndex]);

  const selectNext = (e) => {
    e.stopPropagation();

    const computeNextIndex = (prev) =>
      prev === strategiesTabs.length ? 1 : prev + 1;

    setStartIndex(computeNextIndex);
    setSelectedStrategyIndex(computeNextIndex);

    trackMixpanelEvent(
      user.id,
      '[StrategiesOverview] Teacher clicked right arrow.'
    );
  };

  const selectPrev = (e) => {
    e.stopPropagation();

    const computePrevIndex = (prev) =>
      prev <= 1 ? strategiesTabs.length : prev - 1;
    setStartIndex(computePrevIndex);
    setSelectedStrategyIndex(computePrevIndex);

    trackMixpanelEvent(
      user.id,
      '[StrategiesOverview] Teacher clicked left arrow.'
    );
  };

  const trackStrategyTabSelect = (strategy_name) => {
    trackMixpanelEvent(
      user.id,
      '[StrategiesOverview] Teacher clicked Strategy pie chart component.',
      {
        strategy: strategy_name
      }
    );
  };

  const selectedStrategy = useMemo(() => {
    if (selectedStrategyIndex === undefined) return null;
    return strategies[selectedStrategyIndex];
  }, [selectedStrategyIndex, strategies]);

  const exportResults = useCallback(
    () =>
      openInNewTab(
        getStrategiesOverviewExportUrl({
          sectionId: selectedSection.id,
          grade: selectedGrade.name,
          unit: selectedUnit.name,
          lesson: selectedLesson.name
        })
      ),
    [selectedSection, selectedGrade, selectedUnit, selectedLesson]
  );

  if (isLoadingStrategies) {
    return (
      <div className={styles.circleContainer}>
        <CircularProgress size={80} />
      </div>
    );
  }

  if (_.isEmpty(strategies))
    return <LoadingContainer text="COME BACK SOON... EDUCATORS AT WORK" />;

  return (
    <>
      <div className={styles.topContainer}>
        <Typography
          variant="S-TEXT-1"
          color={colors.blue1}
          className={styles.dateText}
        >
          Assignment Date:{' '}
          {moment(strategiesData['assignment_date']).format('MM/DD/YYYY')}
        </Typography>
        <div className={styles.filtersContainer}>
          <SortFilterComponent
            label="Sort/Filter"
            selectedSection={selectedSection}
            value={filterSortParams}
            onChange={setFilterSortParams}
            contentClass={styles.filtersPopoverContent}
          />
        </div>
        <div className={styles.spacer}></div>
        <ExportButton handleClick={exportResults} />
      </div>
      <div className={styles.chartTabsContainer}>
        <div className={styles.chartTabsBar}>
          <div
            onClick={(e) => {
              setSelectedTabIndex(-1);
              setSelectedStrategyIndex(0);

              trackStrategyTabSelect(OVERALL_RESPONSE_LABEL);
            }}
          >
            <PieChartDoughnut
              key={`doughnut-overall`}
              {...strategies[0]}
              setSelectedRubric={setSelectedRubric}
              isSelected={selectedTabIndex === -1}
              dark
            />
          </div>
          <div className={styles.chartTabsBarScrollable}>
            {strategiesTabs.map((chartData, index) => (
              <div
                ref={refsByIndex[index]}
                className={styles.tab}
                key={`tab-${index}`}
                onClick={(e) => {
                  const indexInMainList = _.findIndex(
                    strategiesData['strategies'],
                    (el) => el['strategy_name'] === chartData['strategy_name']
                  );
                  setStartIndex(indexInMainList);
                  setSelectedTabIndex(0);
                  setSelectedStrategyIndex(indexInMainList);
                  trackStrategyTabSelect(chartData.strategy_name);
                }}
              >
                {selectedTabIndex !== undefined &&
                  index === selectedTabIndex &&
                  showLeftArrow && (
                    <div className={styles.prevButton} onClick={selectPrev}>
                      <ChevronLeft className={styles.arrowIcon} />
                    </div>
                  )}
                <PieChartDoughnut
                  key={`doughnut-${index}`}
                  {...chartData}
                  isSelected={
                    selectedTabIndex !== undefined && index === selectedTabIndex
                  }
                  setSelectedRubric={setSelectedRubric}
                />
                {selectedTabIndex !== undefined &&
                  index === selectedTabIndex &&
                  index >= 0 &&
                  index < strategiesTabs.length - 1 && (
                    <div className={styles.nextButton} onClick={selectNext}>
                      <ChevronRight className={styles.arrowIcon} />
                    </div>
                  )}
              </div>
            ))}
          </div>
        </div>

        {selectedStrategy && (
          <>
            {user.is_PLAS_user && (
              <Typography variant="H-TEXT-3" className={styles.assetTitle}>
                assets
              </Typography>
            )}
            <div className={styles.chartTabsContent}>
              <RubricsBreakdown
                selectedSection={selectedSection}
                selectedGrade={selectedGrade}
                selectedUnit={selectedUnit}
                selectedLesson={selectedLesson}
                selectedStrategy={selectedStrategy}
                selectedRubric={selectedRubric}
                setSelectedRubric={setSelectedRubric}
                filterSortParams={filterSortParams}
                isPlasUser={user.is_PLAS_user}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MultiPieChartContainer;
