import React from 'react';

import SemiCircleProgressBar from 'pages/Pathways/components/PathwaysProgress/components/SemiCircleProgressBar';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

function ArcProgressDisplay({ percentage, text, diameter = 240 }) {
  let description;
  const palettes = {
    green: {
      strokeColor: '#52A774',
      strokeBgColor: '#C1EACB',
      containerBgColor: '#DBF3E2'
    },
    purple: {
      strokeColor: '#7477B8',
      strokeBgColor: '#BDC0FF',
      containerBgColor: '#ECEBFE'
    },
    pink: {
      strokeColor: '#F05B94',
      strokeBgColor: '#FFCBE1',
      containerBgColor: '#FFE5EE'
    }
  };
  let palette = palettes[0];

  if (percentage >= 75) {
    palette = palettes.green;
    description =
      "You're in the final stretch, just a few more steps to success!";
  } else if (percentage >= 40) {
    palette = palettes.purple;
    description =
      "Keep going! You're well on your way to achieving your learning goal.";
  } else {
    palette = palettes.pink;
    description = "Let's dive in, every step gets you closer to your goal!";
  }

  const { strokeColor, strokeBgColor, containerBgColor } = palette;

  return (
    <div
      style={{ background: containerBgColor }}
      className={styles.progressContainer}
    >
      <Typography
        variant="H-TEXT-1"
        color={strokeColor}
        className={styles.text}
      >
        {text || description}
      </Typography>
      <div
        style={{ width: diameter, height: diameter / 2, position: 'relative' }}
      >
        <SemiCircleProgressBar
          diameter={diameter}
          percentage={percentage}
          primaryColor={strokeColor}
          secondaryColor={strokeBgColor}
        />
        <div
          className={styles.percentageText}
          style={{
            color: strokeColor
          }}
        >
          {percentage}%
        </div>
      </div>
    </div>
  );
}

export default ArcProgressDisplay;
