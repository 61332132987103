import React, { useState } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';

import { StickerIconButton } from 'pages/Teachers/shared/StickerAddingTool';
import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import { AddCommentTooltip } from './AddCommentTooltip';
import { ChangeScoreTooltip } from './ChangeScoreTooltip';
import { ChangeStatusTooltip } from './ChangeStatusTooltip';
import { ReactComponent as CommentsIcon } from './assets/comments-icon.svg';
import { ReactComponent as ScoreIcon } from './assets/score-icon.svg';
import { ReactComponent as SelectAllIcon } from './assets/select-all-icon.svg';
import { ReactComponent as StatusIcon } from './assets/status-icon.svg';
import { ReactComponent as StickerIcon } from './assets/sticker-icon.svg';
import styles from './styles.module.scss';

const SELECT_ALL_TAB = 'select_all_tab';
const STICKER_TAB = 'sticker_tab';
const COMMENT_TAB = 'comment_tab';
const SCORE_TAB = 'score_tab';
const STATUS_TAB = 'status_tab';

const StickerButtonRender = React.forwardRef(
  ({ togglePopup, isActive, handleClick }, ref) => (
    <div
      className={classnames(styles.tab, {
        [styles.active]: isActive(STICKER_TAB)
      })}
      onClick={handleClick(STICKER_TAB, togglePopup)}
      ref={ref}
    >
      <SvgIcon className={styles.tabIcon} component={StickerIcon} />
      <Typography variant="H-TEXT-3" color={colors.white}>
        STICKER
      </Typography>
    </div>
  )
);

StickerButtonRender.displayName = 'StickerButtonRender';

const MultiSelectTooltipContent = ({
  handleClose,
  addCommentBulk,
  addStickersBulk,
  changeTasksStatusesBulk,
  toggleSelectAllVisibleStudentWork,
  changeTasksScoresBulk,
  responsesValueType,
  scoreChangeDisabled,
  statusChangeDisabled
}) => {
  const [activeTab, setActiveTab] = useState(null);

  const handleClick = (tab, func) => (event) => {
    event.stopPropagation();
    if (activeTab !== tab) {
      setActiveTab(tab);
    } else {
      setActiveTab(null);
    }
    func && func(event);
  };

  const addComment = (text) => {
    addCommentBulk(text);
    setActiveTab(null);
  };

  const changeStatus = (status) => {
    changeTasksStatusesBulk(status);
    setActiveTab(null);
  };

  const changeScore = (scoreValue) => {
    changeTasksScoresBulk(scoreValue);
    setActiveTab(null);
  };

  const isActive = (tab) => activeTab === tab;

  return (
    <div className={styles.content}>
      <div
        className={styles.tab}
        onClick={handleClick(SELECT_ALL_TAB, toggleSelectAllVisibleStudentWork)}
      >
        <SvgIcon className={styles.tabIcon} component={SelectAllIcon} />
        <Typography variant="H-TEXT-3" color={colors.white}>
          SELECT ALL
        </Typography>
      </div>
      <StickerIconButton
        onStickerClick={addStickersBulk}
        ButtonRender={StickerButtonRender}
        isActive={isActive}
        handleClick={handleClick}
        text={
          <Typography variant="H-TEXT-3" color={colors.white}>
            STICKER
          </Typography>
        }
      />
      <AddCommentTooltip open={isActive(COMMENT_TAB)} addComment={addComment}>
        <div
          className={classnames(styles.tab, {
            [styles.active]: isActive(COMMENT_TAB)
          })}
          onClick={handleClick(COMMENT_TAB)}
        >
          <SvgIcon className={styles.tabIcon} component={CommentsIcon} />
          <Typography variant="H-TEXT-3" color={colors.white}>
            COMMENT
          </Typography>
        </div>
      </AddCommentTooltip>

      <ChangeScoreTooltip
        open={isActive(SCORE_TAB) && !scoreChangeDisabled}
        changeScore={changeScore}
        valueType={responsesValueType}
      >
        <div
          className={classnames(styles.tab, {
            [styles.active]: isActive(SCORE_TAB) && !scoreChangeDisabled
          })}
          onClick={(e) => {
            !scoreChangeDisabled && handleClick(SCORE_TAB)(e);
          }}
        >
          <SvgIcon
            className={classnames(styles.tabIcon, {
              [styles.tabIconDisabled]: scoreChangeDisabled
            })}
            component={ScoreIcon}
          />
          <Typography
            variant="H-TEXT-3"
            color={scoreChangeDisabled ? colors.blue5 : colors.white}
          >
            SCORE
          </Typography>
        </div>
      </ChangeScoreTooltip>

      <div
        className={classnames(styles.tab, {
          [styles.active]: isActive(STATUS_TAB) && !statusChangeDisabled
        })}
        onClick={(e) => {
          !statusChangeDisabled && handleClick(STATUS_TAB)(e);
        }}
      >
        <ChangeStatusTooltip
          open={isActive(STATUS_TAB) && !statusChangeDisabled}
          changeStatus={changeStatus}
        >
          <SvgIcon
            className={classnames(styles.tabIcon, {
              [styles.tabIconDisabled]: statusChangeDisabled
            })}
            component={StatusIcon}
          />
        </ChangeStatusTooltip>
        <Typography
          variant="H-TEXT-3"
          color={statusChangeDisabled ? colors.blue5 : colors.white}
        >
          STATUS
        </Typography>
      </div>

      <div className={styles.tab} onClick={handleClick(null, handleClose)}>
        <Typography variant="H-TEXT-3" color={colors.white}>
          DONE
        </Typography>
      </div>
    </div>
  );
};

const MultiSelectTooltip = ({
  children,
  open,
  handleClose,
  addCommentBulk,
  addStickersBulk,
  changeTasksStatusesBulk,
  changeTasksScoresBulk,
  toggleSelectAllVisibleStudentWork,
  responsesValueType,
  scoreChangeDisabled,
  statusChangeDisabled
}) => (
  <Tooltip
    open={open}
    placement="top"
    disableFocusListener
    disableHoverListener
    disableTouchListener
    classes={{
      tooltip: styles.tooltip,
      popper: styles.popper
    }}
    title={
      <MultiSelectTooltipContent
        handleClose={handleClose}
        addCommentBulk={addCommentBulk}
        addStickersBulk={addStickersBulk}
        changeTasksStatusesBulk={changeTasksStatusesBulk}
        toggleSelectAllVisibleStudentWork={toggleSelectAllVisibleStudentWork}
        changeTasksScoresBulk={changeTasksScoresBulk}
        responsesValueType={responsesValueType}
        scoreChangeDisabled={scoreChangeDisabled}
        statusChangeDisabled={statusChangeDisabled}
      />
    }
    PopperProps={{
      modifiers: {
        flip: {
          enabled: false
        }
      }
    }}
  >
    {children}
  </Tooltip>
);

export default MultiSelectTooltip;
