import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';

const AccordionSummary = withStyles({
  root: {
    '&.Mui-focused': {
      backgroundColor: 'inherit'
    }
  }
})(MuiAccordionSummary);

export default AccordionSummary;
