import React, { useCallback, useContext, useEffect, useState } from 'react';

import { TEACHERS_ASSIGNMENTS_FEEDBACK_URL } from 'config/urls';
import _ from 'lodash';

import {
  CommentsBoxIcon,
  StatusBoxIcon,
  StickerAddingTool,
  StudentWorkCard,
  StudentWorkSelectInput
} from 'pages/Teachers/shared';
import { getLabelForWork } from 'pages/Teachers/shared/StudentWorkSelectInput';
import { colors } from 'theme/palette';
import { reverse, stringifyParams } from 'utils/urls';

import AssignmentTaskValueTypeIcon from 'components/AssignmentTaskValueTypeIcon';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const TaskScore = ({ task }) => {
  if (_.isNull(task.value)) {
    return null;
  }
  return (
    <div className={styles.taskScore}>
      <AssignmentTaskValueTypeIcon task={task} color={colors.blue4} />
    </div>
  );
};

const TaskCard = ({
  task,
  taskIndex,
  trackerId,
  studentId,
  assignmentId,
  onTaskStatusChange,
  onStickerAdd,
  onCommentAdd
}) => {
  const [activeTask, setActiveTask] = useState(task);
  useEffect(() => {
    setActiveTask(task);
  }, [task]);

  const userContext = useContext(UserContext);
  const user = userContext.user;

  const [activeWorkIndex, setActiveWorkIndex] = useState(0);
  const activeWork = _.get(activeTask, `work.${activeWorkIndex}`, {
    emptyWork: true
  });
  const StudentWorkSelectInputOptions = _.get(activeTask, 'work', []).map(
    (work) => ({
      key: work.id,
      label: getLabelForWork(work),
      value: work.id
    })
  );

  const linkTo =
    reverse(TEACHERS_ASSIGNMENTS_FEEDBACK_URL, { trackerId }) +
    '?' +
    stringifyParams({
      student: studentId,
      section: assignmentId,
      task: task.id,
      work: activeWork.id
    });

  const onWorkChange = useCallback(
    (workId) => {
      const index = _.findIndex(activeTask.work, (el) => el.id === workId);
      setActiveWorkIndex(index);
    },
    [activeTask]
  );

  const handleStatusChange = useCallback(
    (status) => {
      setActiveTask({ ...activeTask, status });
      onTaskStatusChange(activeTask.id, status);
    },
    [activeTask, onTaskStatusChange]
  );

  const handleStickerAdd = useCallback(
    (blob) => {
      const url = window.URL.createObjectURL(blob);
      setActiveTask({
        ...activeTask,
        work: [
          {
            id: `_${activeTask.work.length}`,
            is_feedback: true,
            work_url: url
          },
          ...activeTask.work
        ]
      });
      onStickerAdd(blob, activeWork);
    },
    [activeTask, activeWork, onStickerAdd]
  );
  const handleCommentsAdd = useCallback(
    (data) => {
      onCommentAdd({ trackerScoreResponseId: activeTask.id, data });
      const commentObject = {
        ...data,
        id: `_${activeTask.comments.length}`,
        user: { is_student: false, name: user.name }
      };
      setActiveTask({
        ...activeTask,
        comments: [...activeTask.comments, commentObject]
      });
    },
    [activeTask, user, onCommentAdd]
  );

  return (
    <div className={styles.studentTaskCard}>
      <StudentWorkCard
        classes={{ cardContent: styles.cardContent }}
        imageUrl={activeWork.work_url}
        fallbackImageUrl={activeWork.s3_work_url}
        linkTo={linkTo}
      >
        <div className={styles.buttonsContainer}>
          <div className={styles.actionButtonsContainer}>
            <StatusBoxIcon
              disabled={_.get(activeWork, 'emptyWork', false)}
              currentStatus={activeTask.status}
              changeStatus={handleStatusChange}
            />
            <CommentsBoxIcon
              comments={activeTask.comments}
              addComment={handleCommentsAdd}
            />
            <StickerAddingTool
              disabled={_.get(activeWork, 'emptyWork', false)}
              imageSrc={activeWork.s3_work_url}
              onStickerAdd={handleStickerAdd}
            />
          </div>
          <StudentWorkSelectInput
            options={StudentWorkSelectInputOptions}
            value={activeWork.id}
            onChange={onWorkChange}
            disabled={_.isEmpty(StudentWorkSelectInputOptions)}
          />
        </div>
        <div className={styles.taskInfoContainer}>
          <div className={styles.taskNameAndScore}>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              {taskIndex}. {activeTask.name}
            </Typography>
            <TaskScore task={activeTask} />
          </div>
          {_.get(activeTask, 'criteria', []).map((criteria, index) => (
            <div key={index} className={styles.crietria}>
              <Typography variant="B-Text-3" color={colors.grey2}>
                {criteria.name}
              </Typography>
              <Typography
                variant="S-TEXT-2"
                color={colors.blue4}
                className={styles.criteriaStatus}
              >
                {criteria.value ? 'Yes' : 'No'}
              </Typography>
            </div>
          ))}
        </div>
      </StudentWorkCard>
    </div>
  );
};

export default TaskCard;
