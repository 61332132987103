import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LoadingGridViewSkeleton } from 'pages/Teachers/StudentPortfolio/components';

import styles from './styles.module.scss';

const InfiniteScrollContainer = ({ children, length, fetchNext, hasNext }) => (
  <InfiniteScroll
    dataLength={length}
    next={fetchNext}
    hasMore={hasNext}
    loader={
      <div className={styles.gridViewContainer}>
        <LoadingGridViewSkeleton />
      </div>
    }
  >
    {children}
  </InfiniteScroll>
);

export default InfiniteScrollContainer;
