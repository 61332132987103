import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { colors } from 'theme/palette';

import Dropzone from 'components/Dropzone';
import Typography from 'components/Typography';

import { ReactComponent as FileIcon } from './assets/file.svg';
import { MAX_AGENDA_FILE_SIZE } from './constants';
import styles from './styles.module.scss';

const AgendaFileUpload = ({
  agendaFile,
  onAgendaFileDrop,
  onAgendaFileDropRejected,
  onRemove
}) => {
  if (agendaFile === null) {
    return (
      <Dropzone
        buttonText="Upload agenda"
        multiple={false}
        onDrop={onAgendaFileDrop}
        maxSize={MAX_AGENDA_FILE_SIZE}
        onDropRejected={onAgendaFileDropRejected}
        ButtonProps={{ classes: { root: styles.agendaDropzone } }}
      />
    );
  }
  return (
    <div className={styles.agendaFile}>
      <div className={styles.agendaFileInfo}>
        {agendaFile.uploaded ? (
          <SvgIcon component={FileIcon} />
        ) : (
          <CircularProgress size={20} />
        )}

        <Typography noWrap variant="B-Text-3" color={colors.grey2}>
          {agendaFile.file.name}
        </Typography>
      </div>

      <IconButton
        size="small"
        onClick={onRemove}
        disabled={!agendaFile.uploaded}
      >
        <CloseRoundedIcon />
      </IconButton>
    </div>
  );
};

export default AgendaFileUpload;
