import React, { useCallback, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';

import AddAssignmentDialog from 'pages/Teachers/MeetingFeed/components/AddAssignmentDialog';
import ShareMeetingDialog from 'pages/Teachers/MeetingFeed/components/ShareMeetingDialog';
import { getMeetingTitle } from 'pages/Teachers/MeetingFeed/utils';
import { Comments } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';
import { notifyErrors } from 'utils/notifications';

import AssignmentItem from './components/AssignmentItem';
import AddButton from 'components/AddButton';
import Button from 'components/Button';
import EditableText from 'components/EditableText';
import Typography from 'components/Typography';

import { ReactComponent as ShareIcon } from './assets/share.svg';
import {
  AddAgendaDialog,
  AddStandardDialog,
  AgendaFileChip,
  AgendaLinkChip,
  AttendeeChip,
  StandardItem
} from './components';
import { useMeetingStandards } from './hooks';
import { removeMeetingStandard } from './sdk';
import styles from './styles.module.scss';

const MeetingInfoBox = ({
  meetingInfo,
  handleAddMeetingComment,
  onTopicUpdate,
  onAddAssignmentSuccess,
  onShareMeetingUpdateSuccess
}) => {
  const meetingAttendees = _.get(meetingInfo, 'attendees', []);
  const meetingComments = _.get(meetingInfo, 'comments', []);
  const meetingAssignments = _.get(meetingInfo, 'assignments', []);
  const meetingStandards = _.get(meetingInfo, 'standards', []);

  const [standards, addStandard, removeStandard] =
    useMeetingStandards(meetingStandards);
  const [agendas, setAgendas] = useState(_.get(meetingInfo, 'agenda', []));

  const onRemoveStandard = useCallback(
    (standardId) => {
      removeMeetingStandard(meetingInfo.id, standardId).then(
        ({ errors, success }) => {
          if (!success) {
            notifyErrors(errors);
          } else {
            removeStandard(standardId);
          }
        }
      );
    },
    [meetingInfo.id, removeStandard]
  );

  const {
    isOpened: isAddStandardDialogOpened,
    openDialog: openAddStandardDialog,
    closeDialog: closeAddStandardDialog
  } = useDialog();

  const {
    isOpened: isAddAgendaDialogOpened,
    openDialog: openAddAgendaDialog,
    closeDialog: closeAddAgendaDialog
  } = useDialog();

  const {
    isOpened: isAddAssignmentDialogOpened,
    openDialog: openAddAssignmentDialog,
    closeDialog: closeAddAssignmentDialog
  } = useDialog();

  const {
    isOpened: isShareMeetingDialogOpened,
    openDialog: openShareMeetingDialog,
    closeDialog: closeShareMeetingDialog
  } = useDialog();

  return (
    <>
      <div className={styles.infoBoxContainer}>
        <Paper className={styles.paper} variant="outlined">
          <div className={styles.header}>
            <EditableText
              initialText={meetingInfo.topic}
              label={getMeetingTitle(meetingInfo)}
              onTextUpdate={onTopicUpdate}
            />
            <Button
              color="blue"
              className={styles.shareButton}
              onClick={openShareMeetingDialog}
              startIcon={<ShareIcon fill={colors.white} />}
            >
              Share
            </Button>
          </div>
          <Divider />
          <div className={styles.infoSection}>
            <div className={styles.infoSectionTitle}>
              <Typography variant="S-TEXT-1" color={colors.blue1}>
                Standard
              </Typography>
            </div>

            <Grid container className={styles.standardsList} spacing={1}>
              {_.map(standards, (standard) => (
                <Grid item key={standard.id}>
                  <StandardItem
                    standard={standard}
                    onRemoveStandard={onRemoveStandard}
                    key={standard.id}
                  />
                </Grid>
              ))}
            </Grid>

            <div className={styles.addButtonContainer}>
              <AddButton
                className={styles.addButton}
                onClick={openAddStandardDialog}
                variant="small"
                color="blue"
              >
                Add Standard
              </AddButton>
            </div>
          </div>

          <Divider />

          <div className={styles.infoSection}>
            <div className={styles.infoSectionTitle}>
              <Typography variant="S-TEXT-1" color={colors.blue1}>
                Agenda
              </Typography>
            </div>
            <div className={styles.chipsContainer}>
              {_.map(agendas, (item) => {
                if (item.type === 'file') {
                  return (
                    <AgendaFileChip file={item.file} key={`file${item.id}`} />
                  );
                }
                return (
                  <AgendaLinkChip link={item.url} key={`link${item.id}`} />
                );
              })}
            </div>

            <div className={styles.addButtonContainer}>
              <AddButton
                className={styles.addButton}
                onClick={openAddAgendaDialog}
                variant="small"
                color="blue"
              >
                Add Agenda
              </AddButton>
            </div>
          </div>

          <Divider />

          {/* TODO: Remove this check after we have adding attendee functionality & button here */}
          {!_.isEmpty(meetingAttendees) && (
            <div className={styles.infoSection}>
              <div className={styles.infoSectionTitle}>
                <Typography variant="S-TEXT-1" color={colors.blue1}>
                  Attendees
                </Typography>
              </div>
              <div className={styles.chipsContainer}>
                {_.map(meetingAttendees, (attendee) => (
                  <AttendeeChip attendee={attendee} key={attendee.id} />
                ))}
              </div>
              <div className={styles.addButtonContainer}>
                <AddButton
                  className={styles.addButton}
                  onClick={openShareMeetingDialog}
                  variant="small"
                  color="blue"
                >
                  Add Attendee
                </AddButton>
              </div>
            </div>
          )}

          <Divider />

          <div className={styles.infoSection}>
            <div className={styles.infoSectionTitle}>
              <Typography variant="S-TEXT-1" color={colors.blue1}>
                Assignments
              </Typography>
            </div>

            <Grid container spacing={1}>
              {_.map(meetingAssignments, (assignment, index) => (
                <Grid item key={index}>
                  <AssignmentItem assignment={assignment} />
                </Grid>
              ))}
            </Grid>

            <div className={styles.addButtonContainer}>
              <AddButton
                className={styles.addStandardButton}
                onClick={openAddAssignmentDialog}
                variant="small"
                color="blue"
              >
                Add Assignment
              </AddButton>
            </div>
          </div>
        </Paper>
        <div className={styles.commentsContainer}>
          <Comments
            className={styles.comments}
            comments={meetingComments}
            onSubmit={(_event, value) =>
              handleAddMeetingComment({
                text: value,
                parentId: _.get(meetingComments, '[0].id', null)
              })
            }
          />
        </div>
      </div>

      {isAddStandardDialogOpened && (
        <AddStandardDialog
          meetingId={meetingInfo.id}
          meetingStandards={standards}
          onSuccess={addStandard}
          onClose={closeAddStandardDialog}
        />
      )}

      {isAddAgendaDialogOpened && (
        <AddAgendaDialog
          meetingId={meetingInfo.id}
          onSuccess={setAgendas}
          onClose={closeAddAgendaDialog}
        />
      )}

      {isAddAssignmentDialogOpened && (
        <AddAssignmentDialog
          meetingId={meetingInfo.id}
          onClose={closeAddAssignmentDialog}
          onAddAssignmentSuccess={onAddAssignmentSuccess}
        />
      )}

      {isShareMeetingDialogOpened && (
        <ShareMeetingDialog
          meeting={meetingInfo}
          onClose={closeShareMeetingDialog}
          onUpdateSuccess={onShareMeetingUpdateSuccess}
        />
      )}
    </>
  );
};

export default MeetingInfoBox;
