import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MicIcon from '@material-ui/icons/Mic';
import AudioRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
import classnames from 'classnames';

import styles from './styles.module.scss';

// This is called only once, not on every component mount
// For the audio recorder, for browsers without MediaRecorder
AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = 'audio/mpeg';
window.MediaRecorder = AudioRecorder;
if (!window.MediaRecorder) {
  document.write(decodeURI('%3Cscript defer src="/polyfill.js">%3C/script>'));
}

const RecordButton = ({ recorder }) => (
  <IconButton
    disabled={recorder.isLoading}
    onClick={() => {
      if (!recorder.isActive) {
        recorder.startRecording();
      } else {
        recorder.stopRecording();
      }
    }}
    className={classnames(
      {
        [styles.noPulseButton]: !recorder.isActive,
        [styles.pulseButton]: recorder.isActive
      },
      styles.iconButton
    )}
  >
    <MicIcon className={styles.micIcon} />
    {recorder.isLoading && <CircularProgress className={styles.loading} />}
  </IconButton>
);

export default RecordButton;
