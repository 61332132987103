import React from 'react';

import { Checkbox, TableBody, TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const useStyles = makeStyles({
  noBorder: {
    '& .MuiTableCell-root': {
      borderBottom: 'none'
    }
  }
});

const NextStepsTableBody = ({
  nextSteps,
  exemplarUrls,
  onResourceClick,
  onCheckboxToggleClick
}) => {
  const customStyles = useStyles();

  return (
    <TableBody>
      {nextSteps.map((item, index) => (
        <TableRow key={index} className={customStyles.noBorder}>
          <TableCell className={styles.dataCell}>
            <div className={styles.checkboxItem}>
              <Checkbox
                checked={item.completed}
                onChange={() => onCheckboxToggleClick({ nextStep: item })}
                className={styles.checkbox}
              />
              <Typography
                className={styles.stepDescription}
                variant="B-Text-4"
                color={colors.blue10}
              >
                {item.text}
              </Typography>
            </div>
          </TableCell>

          {!isEmpty(exemplarUrls) && (
            <TableCell className={styles.dataCell}>
              {index === 0 && (
                <Typography
                  className={styles.resourceDescription}
                  variant="B-Text-4"
                  color={'#D03673'}
                >
                  {!isEmpty(exemplarUrls) ? (
                    <span onClick={onResourceClick}>[Exemplar]</span>
                  ) : (
                    '-'
                  )}
                </Typography>
              )}
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default NextStepsTableBody;
