import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';

import SyncIcon from '@material-ui/icons/Sync';
import {
  TEACHERS_ASSIGNMENTS_LIST_URL,
  TEACHERS_ASSIGNMENTS_RESULTS_URL,
  TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL,
  TEACHERS_INSIGHTS_V2_OVERVIEW_URL
} from 'config/urls';
import _ from 'lodash';
import {
  finishBulkUpload,
  useBulkUploadDetail,
  useBulkUploadGroups,
  useBulkUploadSubmissionsCount
} from 'sdk';

import GroupsList from 'pages/Teachers/BulkUpload/MatchStudents/components/GroupsList';
import Layout from 'pages/Teachers/BulkUpload/components/Layout';
import { useBulkUploadStatusRedirect } from 'pages/Teachers/BulkUpload/hooks';
import { useNavbar } from 'pages/Teachers/shared';
import colors from 'theme/palette';
import { useDialog } from 'utils/hooks';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { notifyErrors } from 'utils/notifications';
import { reverse, stringifyParams } from 'utils/urls';

import ConfirmDialog from 'components/ConfirmDialog';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import confettiImageSource from './assets/success.gif';
import styles from './styles.module.scss';

const MatchStudents = ({ match }) => {
  const user = useContext(UserContext);
  const [showLoader, setShowLoader] = useState(false);
  const [confettiAlreadyShown, setConfettiAlreadyShown] = useState(false);
  const [timePageStartsLoading] = useState(Date.now());

  const history = useHistory();

  useNavbar({ title: 'CAPTURE WORK' });

  const {
    isOpened: isConfirmDialogOpened,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog
  } = useDialog();

  const bulkUploadId = _.get(match, 'params.bulkUploadId');

  const {
    data: unmatchedBulkUploadImageGroups,
    isLoading: isLoadingUnmatchedGroups,
    refetch: refetchUnmatchedImageGroups
  } = useBulkUploadGroups({
    params: { matched: false },
    bulkUploadId
  });

  const {
    data: matchedBulkUploadImageGroups,
    refetch: refetchMatchedImageGroups
  } = useBulkUploadGroups({
    params: { matched: true },
    bulkUploadId
  });

  const hasUnmatchedImages = useMemo(() => {
    return (
      !isLoadingUnmatchedGroups && unmatchedBulkUploadImageGroups?.length !== 0
    );
  }, [isLoadingUnmatchedGroups, unmatchedBulkUploadImageGroups]);

  const { data, refetch: refetchBulkUploadSubmissionsCount } =
    useBulkUploadSubmissionsCount({ bulkUploadId });

  const assignmentsCount = useMemo(() => {
    if (data) {
      return Object.keys(data).length;
    }
  }, [data]);

  useEffect(() => {
    if (assignmentsCount && assignmentsCount !== 0) {
      if (assignmentsCount === 1) {
        const sectionId = Object.keys(data)[0];
        const sectionData = data[sectionId];

        const pathname =
          process.env.REACT_APP_SHOW_INSIGHTS_V2 === 'true' &&
          sectionData.has_math_standards &&
          !sectionData.is_multipage
            ? reverse(TEACHERS_INSIGHTS_V2_OVERVIEW_URL, {
                trackerInstanceSectionId: sectionId
              })
            : reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
                trackerId: sectionData.tracker_id
              });

        const search =
          process.env.REACT_APP_SHOW_INSIGHTS_V2 !== 'true' &&
          sectionData.has_math_standards &&
          stringifyParams({
            section: sectionId
          });

        history.push({
          pathname: pathname,
          search: search
        });
      } else {
        const pathname =
          process.env.REACT_APP_SHOW_INSIGHTS_V2 === 'true'
            ? TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL
            : TEACHERS_ASSIGNMENTS_LIST_URL;
        history.push(pathname);
      }
    }
  }, [assignmentsCount, data, history]);

  const {
    data: bulkUpload,
    isLoading: isFetchingBulkUpload,
    refetch: refetchBulkUpload
  } = useBulkUploadDetail({ bulkUploadId });

  useBulkUploadStatusRedirect({
    bulkUpload,
    isLoading: isFetchingBulkUpload
  });

  const bulkUploadHasUnprocessedImages = _.get(
    bulkUpload,
    'has_unprocessed_images',
    true
  );
  const allBulkUploadImagesHaveBeenCreated = _.get(
    bulkUpload,
    'bulk_upload_images_have_been_successfully_created',
    false
  );
  const bulkUploadHasUngroupedImages = _.get(
    bulkUpload,
    'has_ungrouped_images',
    true
  );

  const shouldPoll =
    bulkUploadHasUnprocessedImages ||
    !allBulkUploadImagesHaveBeenCreated ||
    bulkUploadHasUngroupedImages;

  useEffect(() => {
    const delay = 2000;

    const timeoutId = setTimeout(() => {
      if (!hasUnmatchedImages && !shouldPoll && !confettiAlreadyShown) {
        setConfettiAlreadyShown(true);
      }
    }, delay);

    // Cleanup timeout if component unmounts
    return () => clearTimeout(timeoutId);
  }, [hasUnmatchedImages, shouldPoll, confettiAlreadyShown]);

  useEffect(() => {
    if (shouldPoll) {
      const interval = setInterval(() => refetchBulkUpload(), 2000);

      return () => clearInterval(interval);
    }
  }, [shouldPoll, refetchBulkUpload]);

  const trackTimePassedUntilMatchingFinished = useCallback(
    ({ timePassed }) => {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.FORM} ${MixpanelAction.SUBMIT}`,
        {
          feature: 'capture',
          view: 'confirm',
          button: 'matching',
          timePassed: timePassed
        }
      );
    },
    [user.id]
  );

  useEffect(() => {
    if (!shouldPoll) {
      const timePageEndsLoading = Date.now();

      const secondsPassed =
        (timePageEndsLoading - timePageStartsLoading) / 1000;

      trackTimePassedUntilMatchingFinished({ timePassed: secondsPassed });
    }
  }, [shouldPoll, timePageStartsLoading, trackTimePassedUntilMatchingFinished]);

  const handleBulkUploadFinish = async () => {
    if (shouldPoll) {
      return;
    }
    setShowLoader(true);

    const { success, errors } = await finishBulkUpload({
      bulkUploadId
    });

    if (success) {
      refetchBulkUploadSubmissionsCount();
    } else {
      notifyErrors(errors);
    }

    setShowLoader(false);
  };

  const handleNextClick = () => {
    if (hasUnmatchedImages) {
      openConfirmDialog();
    } else {
      handleBulkUploadFinish();
    }
  };

  const assignmentId = bulkUpload?.assignment?.id;

  return (
    <Layout
      currentStepIndex={2}
      nextButtonAction={handleNextClick}
      isNextButtonDisabled={showLoader || shouldPoll || hasUnmatchedImages}
      nextButtonTooltip={
        hasUnmatchedImages
          ? 'You should match all images to students'
          : undefined
      }
      showLoader={showLoader}
      className={styles.wrapper}
      showDoneButton={true}
    >
      <div>
        {shouldPoll && (
          <div className={styles.matchingStudents}>
            <SyncIcon className={styles.syncIcon} />
            <Typography variant="H-TEXT-1" className={styles.matchingHeader}>
              Matching students
            </Typography>
          </div>
        )}
        {hasUnmatchedImages && (
          <div className={styles.subTitle}>
            <Typography variant="B-TEXT-1" className={styles.matchingSubheader}>
              Almost there! Confirm student names to unlock work insights.
            </Typography>
          </div>
        )}
      </div>

      {!hasUnmatchedImages && !shouldPoll && !confettiAlreadyShown && (
        <img
          src={`${confettiImageSource}?rand=${Math.random()}`}
          className={styles.confetti}
          alt="confetti"
        />
      )}

      <GroupsList
        bulkUploadId={bulkUploadId}
        assignmentId={assignmentId}
        shouldPoll={shouldPoll}
        unmatchedBulkUploadImageGroups={unmatchedBulkUploadImageGroups}
        refetchUnmatchedImageGroups={refetchUnmatchedImageGroups}
        matchedBulkUploadImageGroups={matchedBulkUploadImageGroups}
        refetchMatchedImageGroups={refetchMatchedImageGroups}
      />
      {isConfirmDialogOpened && (
        <ConfirmDialog
          onClose={closeConfirmDialog}
          onConfirm={() => {
            closeConfirmDialog();
            handleBulkUploadFinish();
          }}
        >
          <div>
            <Typography variant="B-Text-1" color={colors.grey1} align="center">
              All unmatched images will be permanently lost.
            </Typography>
          </div>
        </ConfirmDialog>
      )}
    </Layout>
  );
};

export default MatchStudents;
