import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { noopPromise } from 'utils';

import { useFetch } from 'utils/sdk';

import UserContext from 'components/UserContext';

import {
  getSchoolsForOrganization,
  getUserOrganizations,
  getUsersForSchools
} from './sdk';

export const useOrganizations = () => {
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const { data: organizations } = useFetch(getUserOrganizations, { data: [] });

  const organizationsLength = useMemo(
    () => _.size(organizations),
    [organizations]
  );

  useEffect(() => {
    if (organizationsLength === 1) {
      setSelectedOrganization(organizations[0]);
    }
  }, [organizations, organizationsLength]);

  return {
    selectedOrganization,
    setSelectedOrganization,
    organizations,
    organizationsLength
  };
};

export const useOrganizationSchools = (organizationId) => {
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [schoolName, setSchoolName] = useState('');

  const requestParams = useMemo(
    () => ({ name: schoolName, organizationsIds: [organizationId] }),
    [schoolName, organizationId]
  );

  const sdk = useMemo(
    () => (organizationId ? getSchoolsForOrganization : noopPromise),
    [organizationId]
  );

  const { data: schools } = useFetch(sdk, { data: [] }, requestParams);

  const addSchool = useCallback((school) => {
    setSelectedSchools((prev) => [school, ...prev]);
    setSchoolName('');
  }, []);

  const removeSchool = useCallback(
    (school) => setSelectedSchools((prev) => _.reject(prev, { id: school.id })),
    []
  );

  useEffect(() => {
    // If the current user is part of the one-and-only school that we fetch - pre-select this school.
    if (_.size(schools) === 1) {
      setSelectedSchools(schools);
    }
  }, [schools]);

  useEffect(() => {
    // Reset schools when the organization changes
    setSelectedSchools([]);
  }, [organizationId]);

  return {
    schools,
    addSchool,
    removeSchool,
    schoolName,
    setSchoolName,
    selectedSchools
  };
};

export const useSchoolsUsers = (selectedSchools) => {
  const { user } = useContext(UserContext);

  const [selectedUsers, setSelectedUsers] = useState([user]);
  const [userName, setUserName] = useState('');

  const selectedSchoolsIds = useMemo(
    () => _.map(selectedSchools, 'id'),
    [selectedSchools]
  );
  const requestParams = useMemo(
    () => ({ name: userName, selectedSchoolsIds: selectedSchoolsIds }),
    [userName, selectedSchoolsIds]
  );

  const { data: users } = useFetch(
    getUsersForSchools,
    { data: [] },
    requestParams
  );

  const addUser = useCallback((user) => {
    setSelectedUsers((prev) => [user, ...prev]);
    setUserName('');
  }, []);
  const removeUser = useCallback(
    (user) => setSelectedUsers((prev) => _.reject(prev, { id: user.id })),
    []
  );

  const removeUsers = useCallback(
    (usersToRemove) =>
      setSelectedUsers((prev) =>
        _.reject(prev, (user) => _.includes(usersToRemove, user))
      ),
    []
  );

  useEffect(() => {
    if (_.isEmpty(selectedSchools)) {
      // Reset selected users whenever the selected schools are emptied
      setSelectedUsers([user]);
    }
  }, [selectedSchools, user]);

  return {
    users,
    addUser,
    removeUser,
    removeUsers,
    userName,
    setUserName,
    selectedUsers
  };
};
