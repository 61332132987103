import React from 'react';

import { colors } from 'theme/palette';

import ConfirmDialog from 'components/ConfirmDialog';
import Typography from 'components/Typography';

const FileDeleteConfirmDialog = ({ onClose, onConfirm }) => (
  <ConfirmDialog onClose={onClose} onConfirm={onConfirm}>
    <Typography variant="B-Text-1" color={colors.grey1} align="center">
      Are you sure that you want to delete this recording?
    </Typography>
  </ConfirmDialog>
);

export default FileDeleteConfirmDialog;
