import React from 'react';

import cx from 'classnames';

import { MEETINGS } from 'pages/Teachers/TeamDetail/constants';

import styles from './styles.module.scss';

const MeetingTabs = ({ activeTab, setActiveTab }) => (
  <div className={styles.tabsWrapper}>
    <div
      onClick={() => setActiveTab(MEETINGS.UPCOMING)}
      className={cx(styles.tab, {
        [styles.activeTab]: activeTab === MEETINGS.UPCOMING
      })}
    >
      Upcoming meetings
    </div>
    <div
      onClick={() => setActiveTab(MEETINGS.PAST)}
      className={cx(styles.tab, {
        [styles.activeTab]: activeTab === MEETINGS.PAST
      })}
    >
      Past meetings
    </div>
  </div>
);

export default MeetingTabs;
