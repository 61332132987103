import React from 'react';

import cx from 'classnames';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const STEPS = [
  { name: 'Upload Scan' },
  { name: 'Select Assignment' },
  { name: 'Match Students' }
];

const Stepper = ({ currentStepIndex, className }) => (
  <div className={cx(styles.container, className)}>
    {STEPS.map(({ name }, index) => {
      const isCompleted = currentStepIndex > index;
      const isActive = currentStepIndex === index;
      return (
        <div
          key={name}
          className={cx(
            styles.step,
            {
              [styles.completed]: isCompleted
            },
            {
              [styles.active]: isActive
            }
          )}
        >
          <div className={styles.prevStepConnector} />

          <Typography variant="S-Text-3" className={styles.name}>
            {name}
          </Typography>
        </div>
      );
    })}
  </div>
);

export default Stepper;
