export const formatDueDate = ({ due_at }) => {
  const date = new Date(due_at);

  const optionsDate = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

  const formatted = `Due ${formattedDate} at ${formattedTime}`;

  return formatted;
};

export const formatCommentInfo = ({ created_at, user }) => {
  const newDate = new Date(created_at);

  const optionsDate = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };
  const formattedDate = newDate.toLocaleDateString('en-US', optionsDate);

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  const formattedTime = newDate.toLocaleTimeString('en-US', optionsTime);

  const name = user?.preferred_name ? user?.preferred_name : user?.name;

  const formatted = `${formattedDate} at ${formattedTime} by ${name}`;

  return formatted;
};
