import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import _ from 'lodash';

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return [anchorEl, openMenu, closeMenu];
};

export const useDialog = (initialDialogData) => {
  const [isOpened, setIsOpened] = useState(false);
  const [dialogData, setDialogData] = useState(initialDialogData);

  const openDialog = useCallback(() => setIsOpened(true), []);
  const closeDialog = useCallback(() => setIsOpened(false), []);
  const toggleDialog = useCallback(() => setIsOpened((prev) => !prev), []);

  useEffect(() => {
    if (!isOpened) {
      setDialogData();
    }
  }, [isOpened]);

  return {
    isOpened,
    openDialog,
    closeDialog,
    toggleDialog,
    dialogData,
    setDialogData
  };
};

const defaultPaginationData = {
  count: 0,
  limit: 0,
  results: []
};

export const usePagination = (hook, limit = 10) => {
  const [page, setPage] = useState(0);
  const [resultsCount, setResultsCount] = useState();

  const requestData = useMemo(
    () => ({ offset: page * limit, limit }),
    [page, limit]
  );

  const result = hook(requestData);

  const [resultsData, setResultsData] = useState({
    data: defaultPaginationData,
    loading: true
  });

  useEffect(() => {
    if (!result.loading) {
      setResultsData(result);
    }
    if (result.loading) {
      setResultsData({ data: defaultPaginationData, loading: true });
    }
  }, [result]);

  useEffect(() => {
    if (!resultsCount) {
      setResultsCount(result.data.count);
    }
  }, [result, resultsCount]);

  const pageCount = useMemo(() => {
    const count = resultsData.data.count;
    const limit = resultsData.data.limit;
    if (count !== 0 && limit !== 0) {
      return _.ceil(count / limit);
    }
    return 0;
  }, [resultsData]);

  // This is needed for the case when we trigger pre-render by setting page to -1
  useEffect(() => {
    if (page < 0) {
      setPage(0);
    }
  }, [page]);

  return [resultsData, page, setPage, pageCount];
};

export const useInfiniteScroll = (fetchHook, filters, limit = 20) => {
  const [page, setPage] = useState(0);
  const [resultsCount, setResultsCount] = useState();

  const triggerFetch = useMemo(() => !_.isEmpty(filters), [filters]);

  const requestData = useMemo(
    () => ({ offset: page * limit, limit, ...filters }),
    [page, filters, limit]
  );
  const result = fetchHook(requestData, triggerFetch);

  const [aggregatedResults, setAggregatedResults] = useState({
    data: { results: [] },
    loading: true
  });

  useEffect(() => {
    setAggregatedResults({
      data: { results: [] },
      loading: true
    });
    setPage(0);
  }, [filters]);

  useEffect(() => {
    if (!result.loading) {
      setAggregatedResults((x) => {
        const obj = {
          ...x,
          ...result,
          data: {
            ...result.data,
            results: [...x.data.results, ..._.get(result, 'data.results', [])]
          }
        };
        return obj;
      });
    }
    if (result.loading) {
      setAggregatedResults((x) => ({ ...x, loading: true }));
    }
  }, [result]);

  useEffect(() => {
    if (!resultsCount && result.data) {
      setResultsCount(result.data.count);
    }
  }, [result, resultsCount]);

  const loadNextPage = useCallback(() => {
    if (resultsCount > page * limit) {
      setPage((x) => x + 1);
    }
  }, [resultsCount, page, limit]);
  return [aggregatedResults, loadNextPage];
};

export const useWorkCard = ({ assignment: initialAssignmentData }) => {
  const [showComments, setShowComments] = useState(false);
  // We do partial updates, that's why we keep a copy of the object here.
  const [assignment, setAssignment] = useState(initialAssignmentData);

  const initialTaskIndex = _.first(assignment.tasks)?.index || 0;

  const [activeTaskIndex, setActiveTaskIndex] = useState(initialTaskIndex);
  const [activeWorkIndex, setActiveWorkIndex] = useState(0);

  const [paginatedTasksStart, setPaginatedTasksStart] = useState(0);

  const activeTask = _.find(assignment.tasks, { index: activeTaskIndex }) || {};
  const activeWork = _.get(activeTask, `work.${activeWorkIndex}`, {});

  const assignmentTasks = assignment.tasks;
  const activeTaskWork = activeTask.work;

  const isEmptyWork = _.isEmpty(activeWork.work_url);

  const hasComments = !_.isEmpty(activeTask.comments);
  const showActiveWorksDropdown = activeTaskWork?.length > 1;
  const showPaginatedTaskButtons = assignmentTasks.length > 5;
  const showAssignmentTasksButtons =
    assignmentTasks.length > 1 && assignmentTasks.length <= 5;

  useEffect(() => {
    // Update the copy of the object each time the initial data changes.
    setAssignment((prevData) => ({ ...prevData, ...initialAssignmentData }));
  }, [initialAssignmentData]);

  const handleRightArrowClick = useCallback(() => {
    if (paginatedTasksStart + 2 === activeTaskIndex) {
      setPaginatedTasksStart((prevTaskIndex) => prevTaskIndex + 1);
    }

    setActiveTaskIndex((taskIndex) => taskIndex + 1);
    setActiveWorkIndex(0);
  }, [paginatedTasksStart, activeTaskIndex]);

  const handleLeftArrowClick = useCallback(() => {
    if (paginatedTasksStart === activeTaskIndex) {
      setPaginatedTasksStart((prevTaskIndex) => prevTaskIndex - 1);
    }

    setActiveTaskIndex((taskIndex) => taskIndex - 1);
    setActiveWorkIndex(0);
  }, [paginatedTasksStart, activeTaskIndex]);

  const tasksButtonsElements = useMemo(
    () =>
      assignmentTasks.map((task, index) => ({
        label: task.task_index + 1,
        key: task.id,
        value: index,
        isActive: index === activeTaskIndex
      })),
    [assignmentTasks, activeTaskIndex]
  );

  return {
    tasksButtonsElements,
    hasComments,
    isEmptyWork,
    assignmentTasks,
    activeTaskWork,
    activeTask,
    activeWork,
    activeTaskIndex,
    setActiveTaskIndex,
    activeWorkIndex,
    setActiveWorkIndex,
    paginatedTasksStart,
    setPaginatedTasksStart,
    handleRightArrowClick,
    handleLeftArrowClick,
    showActiveWorksDropdown,
    showPaginatedTaskButtons,
    showAssignmentTasksButtons,
    showComments,
    setShowComments,
    assignment,
    setAssignment
  };
};
