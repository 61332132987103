import React, { useContext, useEffect, useState } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { get, isNull } from 'lodash';
import { workTagImageUpdateOverallResponseMastery } from 'sdk';

import ConfirmDialog from 'pages/Teachers/InsightsV2/components/ConfirmDialog';
import {
  CELEBRATE,
  INTERVENE,
  SUPPORT
} from 'pages/Teachers/InsightsV2/constants';
import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { notifyError, notifySuccess } from 'utils/notifications';

import SelectFilter from 'components/SelectFilter';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const MASTERY_LEVELS = [
  {
    action: CELEBRATE,
    value: '0',
    icon: <CheckCircleIcon style={{ color: colors.pink5 }} />
  },
  {
    action: SUPPORT,
    value: '1',
    icon: <ErrorIcon style={{ color: colors.pink5 }} />
  },
  {
    action: INTERVENE,
    value: '2',
    icon: <CancelIcon style={{ color: colors.pink5 }} />
  }
];

const OverallResponseMasteryLevel = ({
  workTagImageId,
  masteryLevel,
  refetchOverview,
  refetchMasteryLevel
}) => {
  const user = useContext(UserContext);

  const { value, analysis, is_preannotation } = masteryLevel;

  const [pendingValue, setPendingValue] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [selectedMastery, setSelectedMastery] = useState(null);
  const [preAnnotationFeedback, setPreAnnotationFeedback] = useState('');

  const [isPreannotation, setIsPreannotation] = useState(is_preannotation);

  useEffect(() => {
    setSelectedMastery(MASTERY_LEVELS.find((level) => level.value === value));
  }, [value]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setPendingValue(value);
    setOpenConfirmModal(true);
  };

  const onConfirmClick = async () => {
    const data = isPreannotation
      ? { mastery_level_value: pendingValue, feedback: preAnnotationFeedback }
      : { mastery_level_value: pendingValue };

    const { success } = await workTagImageUpdateOverallResponseMastery({
      workTagImageId,
      data
    });

    if (success) {
      const newSelectedMastery = MASTERY_LEVELS.find(
        (level) => level.value === pendingValue
      );

      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.FORM} ${MixpanelAction.SUBMIT}`,
        {
          feature: 'insights',
          view: 'student work',
          form: 'overall response',
          previous: selectedMastery.action,
          new: newSelectedMastery.action,
          reason: preAnnotationFeedback
        }
      );

      setSelectedMastery(newSelectedMastery);
      setPendingValue(null);
      setIsPreannotation(false);

      notifySuccess('Successfully updated Overall response.');
      refetchOverview();
      refetchMasteryLevel();
    } else {
      notifyError('Something went wrong. Please try again.');
    }

    setOpenConfirmModal(false);
    setPreAnnotationFeedback('');
  };

  const onCloseClick = () => {
    setPendingValue(null);
    setOpenConfirmModal(false);
  };

  const text = `Are you sure you want to change the mastery level to ${
    MASTERY_LEVELS.find((level) => level.value === pendingValue)?.action
  }?`;

  return (
    <>
      <div className={styles.strategyContainer}>
        <Typography
          variant="H-TEXT-3"
          component="h5"
          className={styles.summaryData}
        >
          Overall Response
        </Typography>

        <div className={styles.selectContainer}>
          {get(selectedMastery, 'icon', '')}
          <SelectFilter
            value={get(selectedMastery, 'value', '')}
            options={MASTERY_LEVELS.map((level) => ({
              label: level.action,
              value: level.value
            }))}
            onChange={handleSelectChange}
            placeholder="Select Mastery Level"
            className={styles.select}
          />
        </div>

        <div className={styles.innerData}>
          <Typography variant="S-TEXT-1" className={styles.lineText}>
            {!isNull(analysis) && <>{analysis}</>}
          </Typography>
        </div>
      </div>

      {openConfirmModal && (
        <ConfirmDialog
          onClose={onCloseClick}
          onConfirm={onConfirmClick}
          text={text}
          onPreannotationFeedbackChange={({ newFeedback }) =>
            setPreAnnotationFeedback(newFeedback)
          }
          disableFeedback={!isPreannotation}
        />
      )}
    </>
  );
};

export default OverallResponseMasteryLevel;
