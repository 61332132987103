import React, { useEffect, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import { ArrowBack } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import { familyAssignmentDetail } from 'sdk';

import { ImgixDimensions } from 'utils/constants';
import { setImgixFallbackUrl } from 'utils/files/imgix';

import { getStatusIcon } from '../Planner/components/AssignmentsList/utils';
import ImgixImage from 'components/ImgixImage';
import StandardItem from 'components/StandardItem';
import Typography from 'components/Typography';

import styles from './styles.module.scss';
import { formatCommentInfo, formatDueDate } from './utils';

const FamilyDetailAssignment = ({
  studentId,
  assignmentId,
  setAssignmentId
}) => {
  const [assignmentData, setAssignmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [assignmentDate, setAssignmentDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data, error, success } = await familyAssignmentDetail({
          studentId,
          assignmentId
        });

        if (success && data) {
          const filteredTasks = data.tasks.map((task) => {
            if (
              task.status === 'assigned' &&
              new Date() > new Date(data.due_at)
            ) {
              return { ...task, status: 'missing' };
            }
            return task;
          });
          data.tasks = filteredTasks;
          setAssignmentData(data);
          const writtenDate = formatDueDate(data);
          setAssignmentDate([writtenDate]);
        } else if (error) {
          setError(error);
        }
      } catch (err) {
        setError('An unexpected error occurred.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [studentId, assignmentId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const returnToCalendar = () => {
    setAssignmentId(null);
  };

  return (
    <>
      <ArrowBack
        color="action"
        fontSize="large"
        onClick={returnToCalendar}
        className={styles.returnButton}
      />
      {assignmentData ? (
        <div>
          <div className="assignmentHeader">
            <div>
              <h1>
                <strong>{assignmentData.name}</strong>
              </h1>
            </div>
            <div>
              <h2>{assignmentDate}</h2>
            </div>
            <div>
              <h3>
                <strong className={`${styles.horizontal} ${styles.statusText}`}>
                  STATUS
                </strong>
                <img
                  src={getStatusIcon(assignmentData.tasks[0].status)}
                  alt={`${assignmentData.tasks[0].status} icon`}
                  className={`${styles.statusIcon} ${styles.horizontal}`}
                />
                <span
                  className={`${
                    styles[assignmentData.tasks[0].status.toLowerCase()]
                  }`}
                >
                  {assignmentData.tasks[0].status.toUpperCase()}{' '}
                </span>
              </h3>
              <hr></hr>
            </div>
          </div>
          <div className="assignmentDescription">
            <h4>Description</h4>
            <p>{assignmentData.description}</p>
            <hr></hr>
          </div>
          <div className="assignmentStandards">
            <h4>Standards</h4>
            <Grid className={styles.standardsList} container spacing={1}>
              {assignmentData.objectives.map((concept, index) => (
                <Grid item key={index}>
                  {concept.standards.length !== 0 ? (
                    <StandardItem
                      standard={concept.standards?.[index]}
                      forStudents={true}
                    />
                  ) : (
                    'No standards'
                  )}
                </Grid>
              ))}
            </Grid>
            <hr></hr>
          </div>
          <div>
            {assignmentData.tasks.map((page, index) => (
              <div key={index}>
                <Typography variant="H-TEXT-2">{page?.name}</Typography>
                <div>
                  <Accordion className={styles.accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <strong
                        className={`${styles.horizontal} ${styles.statusText}`}
                      >
                        STATUS
                      </strong>
                      <img
                        src={getStatusIcon(page.status)}
                        alt={`${page.status} icon`}
                        className={`${styles.statusIcon} ${styles.horizontal}`}
                      />
                      <span className={`${styles[page.status.toLowerCase()]}`}>
                        {page.status.toUpperCase()}{' '}
                      </span>
                    </AccordionSummary>
                    <AccordionDetails className={styles.assignmentResolution}>
                      <div className={styles.studentWorkContainer}>
                        <div>
                          {page.work?.[index]?.work_url && (
                            <ImgixImage
                              src={page.work[index].work_url}
                              dimensions={ImgixDimensions.MEDIUM}
                              className={styles.studentWorkImage}
                              onError={setImgixFallbackUrl(
                                page.work[index].s3_work_url
                              )}
                            />
                          )}
                        </div>
                        <div>
                          <h4>TEACHER FEEDBACK</h4>
                          <div>
                            {page.comments.length !== 0
                              ? page.comments.map((comment, marker) => (
                                  <div
                                    key={marker}
                                    className={styles.feedbackItem}
                                  >
                                    <h5 className={styles.feedbackInfo}>
                                      {formatCommentInfo(comment)}
                                    </h5>
                                    <Grid container direction="row" spacing={3}>
                                      <Grid item xs={10}>
                                        {comment.text}
                                      </Grid>
                                      <Grid item xs={2}>
                                        <InsertCommentIcon />
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))
                              : 'No comments'}
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            ))}
            <hr></hr>
          </div>
        </div>
      ) : (
        <div>No assignment data available.</div>
      )}
    </>
  );
};

export default FamilyDetailAssignment;
