import React, { useContext } from 'react';

import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import Typography from 'components/Typography';

import AssignmentSelectorContext from '../AssignmentSelectorContext';
import styles from './styles.module.scss';

const AssignmentSelectionDisplay = ({ onClick }) => {
  const { selectedSection, selectedGrade, selectedUnit, selectedLesson } =
    useContext(AssignmentSelectorContext);

  const displayText = `${selectedSection?.name || '—'} > ${
    selectedGrade ? `GRADE ${selectedGrade?.name}` : '—'
  } > ${selectedUnit ? `UNIT ${selectedUnit?.name}` : '—'} > ${
    selectedLesson ? `LESSON ${selectedLesson?.name}` : '—'
  }`;

  return (
    <div className={styles.itemsContainer}>
      <Typography
        variant="S-TEXT-1"
        className={styles.displayText}
        title={displayText}
      >
        {displayText}
      </Typography>
      <div className={styles.spacer}></div>
      <IconButton className={styles.dots} onClick={onClick}>
        <MoreHorizIcon />
      </IconButton>
    </div>
  );
};
export default AssignmentSelectionDisplay;
