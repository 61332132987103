import React from 'react';

import { Grid, IconButton, Typography } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import {
  FAMILY_HOME_URL,
  FAMILY_NOTIFICATIONS_URL,
  FAMILY_PLANNER_URL,
  FAMILY_RESOURCES_URL
} from 'config/urls';

// importing the custom styles
import styles from './styles.module.scss';

const BottomNavBar = ({ logout, history, selectedPage, setSelectedPage }) => {
  const handleSelect = ({ item }) => {
    setSelectedPage(item);
  };

  const determineClassName = ({ page }) => {
    return selectedPage === page ? styles.selected : '';
  };

  const determineIconColor = ({ page }) => {
    return selectedPage === page ? 'inherit' : 'primary';
  };

  return (
    <div className={styles.bottomNavigation}>
      <Grid container justifyContent="space-around" alignItems="center">
        <div className={styles.navItem}>
          <IconButton
            aria-label="Home"
            onClick={() => {
              handleSelect({ item: 'home' });
              history.push(FAMILY_HOME_URL);
            }}
            className={determineClassName({ page: 'home' })}
          >
            <HomeRoundedIcon
              fontSize="large"
              color={determineIconColor({ page: 'home' })}
            />
          </IconButton>
          <Typography
            variant="caption"
            className={determineClassName({ page: 'home' })}
          >
            Home
          </Typography>
        </div>
        <div className={styles.navItem}>
          <IconButton
            aria-label="Planner"
            onClick={() => {
              handleSelect({ item: 'planner' });
              history.push(FAMILY_PLANNER_URL);
            }}
            className={determineClassName({ page: 'planner' })}
          >
            <CalendarTodayIcon
              fontSize="large"
              color={determineIconColor({ page: 'planner' })}
            />
          </IconButton>
          <Typography
            variant="caption"
            className={determineClassName({ page: 'planner' })}
          >
            Planner
          </Typography>
        </div>
        <div className={styles.navItem}>
          <IconButton
            aria-label="Notifications"
            onClick={() => {
              handleSelect({ item: 'notifications' });
              history.push(FAMILY_NOTIFICATIONS_URL);
            }}
            className={determineClassName({ page: 'notifications' })}
          >
            <NotificationsRoundedIcon
              fontSize="large"
              color={determineIconColor({ page: 'notifications' })}
            />
          </IconButton>
          <Typography
            variant="caption"
            className={determineClassName({ page: 'notifications' })}
          >
            Notifications
          </Typography>
        </div>
        <div className={styles.navItem}>
          <IconButton
            aria-label="Resources"
            onClick={() => {
              handleSelect({ item: 'resources' });
              history.push(FAMILY_RESOURCES_URL);
            }}
            className={determineClassName({ page: 'resources' })}
          >
            <EmojiObjectsIcon
              fontSize="large"
              color={determineIconColor({ page: 'resources' })}
            />
          </IconButton>
          <Typography
            variant="caption"
            className={determineClassName({ page: 'resources' })}
          >
            Resources
          </Typography>
        </div>
        <div className={styles.navItem}>
          <IconButton
            aria-label="Sign out"
            onClick={() => {
              handleSelect({ item: 'logout' });
              logout();
            }}
            className={determineClassName({ page: 'logout' })}
          >
            <ExitToAppRoundedIcon
              fontSize="large"
              color={determineIconColor({ page: 'logout' })}
            />
          </IconButton>
          <Typography
            variant="caption"
            className={determineClassName({ page: 'logout' })}
          >
            Logout
          </Typography>
        </div>
      </Grid>
    </div>
  );
};

export default BottomNavBar;
