import React from 'react';
import { Link } from 'react-router-dom';

import { TEACHERS_SUBSCRIPTIONS_CHANGE_PLAN_URL } from 'config/urls';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Donut from 'components/Donut';
import Flex from 'components/Flex';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const labelFunction = (d) => {
  const left = d.filter((item) => item.identifier === 'left')[0].value;

  if (left <= 0) {
    return 'Limit exceeded'.toUpperCase();
  }

  return `${left} images left`.toUpperCase();
};

const labelColorFunction = (d) => {
  const total = _.sumBy(d, 'value');
  const used = d.filter((item) => item.identifier === 'used')[0].value;

  const percentage = Math.round((used / total) * 100);

  if (percentage < 50) {
    return colors.green4;
  }

  if (percentage >= 50 && percentage < 85) {
    return colors.yellow3;
  }

  if (percentage >= 85 && percentage < 100) {
    return colors.pink3;
  }

  return colors.blue5;
};

const itemColorFunction = (d, item) => {
  if (item.identifier === 'left') {
    return colors.blue4;
  }

  const total = _.sumBy(d, 'value');
  const used = d.filter((item) => item.identifier === 'used')[0].value;

  const percentage = Math.round((used / total) * 100);

  if (percentage < 50) {
    return colors.green4;
  }

  if (percentage >= 50 && percentage < 85) {
    return colors.yellow3;
  }

  return colors.pink3;
};

const sortFunction = (a, b) => {
  if (a.identifier === 'used') {
    return 1;
  }

  if (b.identifier === 'used') {
    return 1;
  }

  return 0;
};

const UsageDonut = ({ usage, small = false }) => {
  const data = [
    {
      identifier: 'used',
      value: usage.imagesUsed
    },
    {
      identifier: 'left',
      value: usage.imagesLeft
    }
  ];

  const size = small ? 80 : 180;

  return (
    <Flex column alignCenter>
      <Donut
        w={size}
        h={size}
        data={data}
        labelFunction={small ? () => '' : labelFunction}
        labelColorFunction={labelColorFunction}
        itemColorFunction={itemColorFunction}
        sortFunction={sortFunction}
      />
      <Typography
        variant="S-TEXT-1"
        color={colors.pink4}
        className={styles.text}
      >
        <Link
          className={styles.link}
          to={TEACHERS_SUBSCRIPTIONS_CHANGE_PLAN_URL}
        >
          Upgrade now
        </Link>
      </Typography>
    </Flex>
  );
};

export default UsageDonut;
