import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import EventIcon from '@material-ui/icons/Event';
import cx from 'classnames';
import { Field } from 'formik';
import { ReactComponent as TrashIcon } from 'images/trashicon.svg';
import _ from 'lodash';

import { SearchField } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { MuiAutocompleteChangeReasons } from 'utils/constants';
import { parseParams } from 'utils/urls';

import Button from 'components/Button';
import DateTimeField from 'components/DateTimeField';
import Typography from 'components/Typography';

import { ReactComponent as TutorIcon } from './assets/tutor.svg';
import styles from './styles.module.scss';

const AssignmentForm = (props) => {
  const history = useHistory();
  const urlParams = parseParams(history.location.search);

  const [
    sectionWhoseOpenDateDateTimePickerIsOpenId,
    setSectionWhoseOpenDateDateTimePickerIsOpenId
  ] = useState(null);
  const [
    sectionWhoseDueDateDateTimePickerIsOpenId,
    setSectionWhoseDueDateDateTimePickerIsOpenId
  ] = useState(null);

  const getAvailableSections = () => {
    const formSectionIds = _.map(props.form.values.sections, 'sectionId');

    return _.filter(
      props.allSections,
      ({ id }) => !_.includes(formSectionIds, id)
    );
  };

  const remove = ({ index }) => {
    props.remove(index);
  };

  const addNewInputRow = (sectionId = null) => {
    const firstSection = _.head(props.form.values.sections);

    if (firstSection) {
      props.push({
        sectionId,
        open: firstSection.open,
        due: firstSection.due
      });
    } else {
      props.push(props.buildDefaultSection(sectionId));
    }
  };

  const selectRecentSections = ({ sections }) => {
    const {
      form: { values, setFieldValue }
    } = props;

    const formSections = values.sections;
    const availableSectionIds = _.map(getAvailableSections(), 'id');

    // We use the first section by default.
    // If all of the input rows are deleted we build a new "empty placeholder section"
    const firstSection =
      _.first(formSections) || props.buildDefaultSection(null);

    _.forEach(sections, (section) => {
      if (_.includes(availableSectionIds, section.id)) {
        formSections.push({
          sectionId: section.id,
          open: firstSection.open,
          due: firstSection.due
        });
      }
    });

    setFieldValue(
      'sections',
      // Remove all "empty placeholder section" rows from the form
      _.filter(formSections, ({ sectionId }) => !_.isNull(sectionId))
    );
  };

  const {
    form: { values, errors, setFieldValue }
  } = props;

  const availableSections = getAvailableSections();

  const is_bulk_upload = _.get(urlParams, 'is_bulk_upload', undefined);
  const disableMultipleSections = useMemo(
    () => is_bulk_upload && values?.sections?.length === 1,
    [is_bulk_upload, values]
  );

  return (
    <>
      <Box className={styles.container}>
        <Typography variant="S-TEXT-2" color={colors.grey3}>
          Assign to classes
        </Typography>
        <Field
          component={SearchField}
          required
          fullWidth
          onChange={(e, value, reason) => {
            if (reason === MuiAutocompleteChangeReasons.SELECT_OPTION) {
              addNewInputRow(value.id);
            }
          }}
          className={styles.transparentBackgroundInput}
          options={availableSections}
          getOptionLabel={(section) => section.name}
          placeholder="Add Class"
          disabled={disableMultipleSections}
        />
      </Box>

      {_.map(values.sections, (section, idx) => {
        const openDateName = `sections.${idx}.open`;
        const dueDateName = `sections.${idx}.due`;
        const isOpenDateDateTimePickerOpen =
          sectionWhoseOpenDateDateTimePickerIsOpenId === idx;
        const isDueDateDateTimePickerOpen =
          sectionWhoseDueDateDateTimePickerIsOpenId === idx;

        const currentSection = _.find(props.allSections, {
          id: section.sectionId
        });

        return (
          <Grid
            container
            direction="column"
            spacing={2}
            className={cx(styles.inputRow, {
              [styles.withTopBorderRadius]: idx === 0,
              [styles.withBottomBorderRadius]:
                idx === values.sections.length - 1
            })}
            key={idx}
          >
            <Grid item xs={12}>
              <Typography variant="H-TEXT-3">{currentSection.name}</Typography>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item container>
                <Grid item className={styles.withLeftOffset}>
                  <Field
                    id={openDateName}
                    name={openDateName}
                    value={section.open}
                    onChange={(date) => {
                      setFieldValue(openDateName, date);
                    }}
                    error={_.has(errors, openDateName)}
                    helperText={_.get(errors, openDateName)}
                    component={DateTimeField}
                    open={isOpenDateDateTimePickerOpen}
                    onClose={() =>
                      setSectionWhoseOpenDateDateTimePickerIsOpenId(null)
                    }
                    TextFieldComponent={({ value }) => (
                      <Button
                        variant="medium"
                        onClick={() =>
                          setSectionWhoseOpenDateDateTimePickerIsOpenId(idx)
                        }
                        className={styles.button}
                      >
                        <Typography
                          variant="B-Text-3"
                          className={styles.buttonContent}
                        >
                          <SvgIcon
                            fontSize="medium"
                            component={EventIcon}
                            className={styles.buttonIcon}
                          />
                          Open: {value}
                        </Typography>
                      </Button>
                    )}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id={dueDateName}
                    name={dueDateName}
                    value={section.due}
                    minDate={section.open}
                    onChange={(date) => {
                      setFieldValue(dueDateName, date);
                    }}
                    error={_.has(errors, dueDateName)}
                    helperText={_.get(errors, dueDateName)}
                    component={DateTimeField}
                    open={isDueDateDateTimePickerOpen}
                    onClose={() =>
                      setSectionWhoseDueDateDateTimePickerIsOpenId(null)
                    }
                    TextFieldComponent={({ value }) => (
                      <Button
                        variant="medium"
                        onClick={() =>
                          setSectionWhoseDueDateDateTimePickerIsOpenId(idx)
                        }
                        className={styles.button}
                      >
                        <Typography
                          variant="B-Text-3"
                          textTransform="none"
                          className={cx(
                            styles.buttonContent,
                            styles.lowercased
                          )}
                        >
                          <SvgIcon
                            fontSize="medium"
                            component={EventIcon}
                            className={styles.buttonIcon}
                          />
                          Due: {value}
                        </Typography>
                      </Button>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item className={styles.deleteContainer}>
                <Tooltip title="REMOVE CLASS">
                  <TrashIcon
                    onClick={() => remove({ index: idx })}
                    className={styles.delete}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        );
      })}

      {!_.isEmpty(props.recentSections) && (
        <Grid
          container
          spacing={1}
          direction="column"
          className={styles.recentSections}
        >
          <Grid
            item
            data-intercom-target="Assignment Create Dialog Assign Recent Section"
          >
            <Typography variant="S-TEXT-2" color={colors.grey3}>
              Assign Recent:
            </Typography>
          </Grid>
          {_.map(props.recentSections, (sectionSet, index) => (
            <Grid item>
              <Button
                key={index}
                variant="small"
                onClick={() => selectRecentSections({ sections: sectionSet })}
                className={styles.button}
                disabled={disableMultipleSections}
              >
                <Typography variant="B-Text-3" className={styles.buttonContent}>
                  <SvgIcon
                    fontSize="medium"
                    component={TutorIcon}
                    className={styles.buttonIcon}
                  />
                  {_.join(_.map(sectionSet, 'name'), ', ')}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default AssignmentForm;
