import { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

import { buildFilters } from 'utils/filters';
import { useFetch } from 'utils/sdk';

import { getCurrentSchoolYear, getUserTeams } from './sdk';

export const useTeams = (teamSearchValue) => {
  const [teams, setTeams] = useState([]);

  const requestData = useMemo(
    () => buildFilters({ search: teamSearchValue }),
    [teamSearchValue]
  );

  const {
    data: { results: teamsResults }
  } = useFetch(getUserTeams, { data: [] }, requestData);

  useEffect(() => {
    if (!_.isNull(teamsResults)) {
      setTeams(teamsResults);
    }
  }, [teamsResults]);

  return { teams, setTeams };
};

export const useCurrentSchoolYear = () =>
  useFetch(getCurrentSchoolYear, { data: null }).data;
