import React, { useCallback, useContext, useMemo, useState } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { MuiAutocompleteChangeReasons } from 'utils/constants';
import { notifyErrors } from 'utils/notifications';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import SearchFilter from 'components/SearchFilter';
import SelectFilter from 'components/SelectFilter';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import {
  useOrganizationSchools,
  useOrganizations,
  useSchoolsUsers
} from './hooks';
import { createTeacherTeam } from './sdk';
import styles from './styles.module.scss';

const getOptionLabel = (option) => _.get(option, 'name', '');

const SelectedSchools = ({ schools, removeSchool }) => (
  <div className={styles.multiSelect}>
    {_.map(schools, (school) => (
      <div key={school.id} className={styles.selectedElementRow}>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {school.name}
        </Typography>
        <Button
          color="blue"
          variant="small"
          noTypography
          className={styles.minusButton}
          onClick={() => removeSchool(school)}
        >
          <Typography variant="H-TEXT-1" color={colors.white}>
            -
          </Typography>
        </Button>
      </div>
    ))}
  </div>
);

const SelectedUsers = ({ users, removeUser }) => (
  <div className={styles.multiSelect}>
    {_.map(users, (user) => (
      <div key={user.id} className={styles.selectedElementRow}>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {user.name}
        </Typography>
        <Button
          color="blue"
          variant="small"
          noTypography
          className={styles.minusButton}
          onClick={() => removeUser(user)}
        >
          <Typography variant="H-TEXT-1" color={colors.white}>
            -
          </Typography>
        </Button>
      </div>
    ))}
  </div>
);

const CreateTeacherTeamDialog = ({ onClose, onSuccess }) => {
  const { user: currentUser } = useContext(UserContext);

  const {
    selectedOrganization,
    setSelectedOrganization,
    organizations,
    organizationsLength
  } = useOrganizations();

  const showTeamFields = !_.isNull(selectedOrganization);
  const organizationId = _.get(selectedOrganization, 'id', null);

  const onOrganizationChange = useCallback(
    (event) => {
      setSelectedOrganization(
        _.find(organizations, { id: event.target.value })
      );
    },
    [organizations, setSelectedOrganization]
  );

  const [teamName, setTeamName] = useState('');

  const {
    schools,
    addSchool,
    schoolName,
    setSchoolName,
    removeSchool,
    selectedSchools
  } = useOrganizationSchools(organizationId);

  const {
    users,
    addUser,
    removeUser,
    removeUsers,
    userName,
    setUserName,
    selectedUsers
  } = useSchoolsUsers(selectedSchools);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const disableSubmit = useMemo(
    () =>
      _.isEmpty(selectedOrganization) ||
      _.isEmpty(teamName) ||
      _.isEmpty(selectedSchools) ||
      _.isEmpty(selectedUsers) ||
      isSubmitting,
    [
      selectedOrganization,
      teamName,
      selectedSchools,
      selectedUsers,
      isSubmitting
    ]
  );

  const filterSchoolOptions = useCallback(
    (options) =>
      _.reject(options, ({ id }) =>
        _.includes(_.map(selectedSchools, 'id'), id)
      ),
    [selectedSchools]
  );
  const onSchoolSearchInputChange = useCallback(
    (e, val, trigger) => {
      if (trigger === 'input') {
        setSchoolName(val);
      }
    },
    [setSchoolName]
  );
  const onSchoolSearchChange = useCallback(
    (e, opt, trigger) => {
      if (trigger === MuiAutocompleteChangeReasons.SELECT_OPTION) {
        addSchool(opt);
      }
    },
    [addSchool]
  );

  const removeRelatedSchoolUsers = useCallback(
    (school) => {
      const usersToRemove = _.filter(selectedUsers, (user) => {
        // When a teacher from School X is added to the list and School X is after that removed from the selected schools, the teacher should be removed as well.
        // Important: Excluding the current user, which is added by default.
        return (
          _.includes(school.users_ids, user.id) && user.id !== currentUser.id
        );
      });

      removeUsers(usersToRemove);
    },
    [selectedUsers, currentUser.id, removeUsers]
  );

  const onSchoolRemove = useCallback(
    (school) => {
      removeSchool(school);
      removeRelatedSchoolUsers(school);
    },
    [removeSchool, removeRelatedSchoolUsers]
  );

  const showSchoolFields = useMemo(() => _.size(schools) > 1, [schools]);

  const filterUserOptions = useCallback(
    (options) =>
      _.reject(options, ({ id }) => _.includes(_.map(selectedUsers, 'id'), id)),
    [selectedUsers]
  );
  const onUserSearchInputChange = useCallback(
    (e, val, trigger) => {
      if (trigger === 'input') {
        setUserName(val);
      }
    },
    [setUserName]
  );
  const onUserSearchChange = useCallback(
    (e, opt, trigger) => {
      if (trigger === MuiAutocompleteChangeReasons.SELECT_OPTION) {
        addUser(opt);
      }
    },
    [addUser]
  );

  const showUserFields = useMemo(
    () => !_.isEmpty(selectedSchools),
    [selectedSchools]
  );

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);

    const newTeamData = {
      name: teamName,
      organization: organizationId,
      schools: _.map(selectedSchools, 'id'),
      users: _.map(selectedUsers, 'id')
    };

    const { success, data, errors } = await createTeacherTeam(newTeamData);

    setIsSubmitting(false);

    if (success) {
      onSuccess(data);
      onClose();
    } else {
      notifyErrors(errors);
    }
  }, [
    teamName,
    organizationId,
    selectedSchools,
    selectedUsers,
    onSuccess,
    onClose
  ]);

  const onTeamNameChange = useCallback(
    (event) => setTeamName(event.target.value),
    []
  );

  return (
    <Dialog
      open
      alignTop
      fullWidth
      maxWidth="md"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <div className={styles.header}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          Create teacher team
        </Typography>
        <Button
          color="pink"
          onClick={onSubmit}
          disabled={disableSubmit}
          className={styles.createButton}
        >
          Create
        </Button>
      </div>
      <>
        {organizationsLength > 1 && (
          <SelectFilter
            fullWidth
            options={_.map(organizations, (organization) => ({
              key: organization.id,
              value: organization.id,
              label: organization.name
            }))}
            value={organizationId}
            onChange={onOrganizationChange}
            className={styles.label}
            placeholder="Select Organization"
          />
        )}
        {showTeamFields && (
          <>
            {showSchoolFields && (
              <>
                <Typography
                  variant="S-TEXT-1"
                  color={colors.grey3}
                  className={styles.label}
                >
                  Schools
                </Typography>
                <SearchFilter
                  inputValue={schoolName}
                  placeholder="Add School"
                  options={schools}
                  filterOptions={filterSchoolOptions}
                  getOptionLabel={getOptionLabel}
                  onChange={onSchoolSearchChange}
                  onInputChange={onSchoolSearchInputChange}
                />
                {!_.isEmpty(selectedSchools) && (
                  <SelectedSchools
                    schools={selectedSchools}
                    removeSchool={onSchoolRemove}
                  />
                )}
              </>
            )}
            <InputField
              fullWidth
              variant="underlined"
              placeholder="Team name"
              value={teamName}
              onChange={onTeamNameChange}
              className={styles.label}
            />
            {showUserFields && (
              <>
                <Typography
                  variant="S-TEXT-1"
                  color={colors.grey3}
                  className={styles.label}
                >
                  Staff
                </Typography>
                <SearchFilter
                  inputValue={userName}
                  placeholder="Add Staff"
                  options={users}
                  filterOptions={filterUserOptions}
                  getOptionLabel={getOptionLabel}
                  onChange={onUserSearchChange}
                  onInputChange={onUserSearchInputChange}
                />
                {!_.isEmpty(selectedUsers) && (
                  <SelectedUsers
                    users={selectedUsers}
                    removeUser={removeUser}
                  />
                )}
              </>
            )}
          </>
        )}
        {isSubmitting && <LinearProgress className={styles.linearProgress} />}
      </>
    </Dialog>
  );
};

export default React.memo(CreateTeacherTeamDialog);
