import { BASE_URL } from 'config/urls';

import { callUrl, get, post, requestSdk } from 'utils/sdk';

export const getStandards = (params) =>
  callUrl(get, `${BASE_URL}/v1/standards/`, params);

export const addMeetingStandard = (meetingId, standardId) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/${meetingId}/standards/add/`, {
      standard: standardId
    })
  );
