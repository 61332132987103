import React from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import Typography from 'components/Typography';

export const matchColortoIcon = ({ valueIcon }) => {
  let color;

  if (valueIcon === 0) {
    color = '#61a460';
  } else if (valueIcon === 1) {
    color = '#d29f3d';
  } else if (valueIcon === 2) {
    color = '#dd3d7b';
  } else {
    color = '#ffffff';
  }

  return color;
};

export const remappedRubricsToChecks = ({
  masteryLevel,
  color,
  onMouseEnter
}) => {
  switch (masteryLevel) {
    case 0:
      return <CheckCircleIcon htmlColor={color} onMouseEnter={onMouseEnter} />;
    case 1:
      return <ErrorIcon htmlColor={color} onMouseEnter={onMouseEnter} />;
    case 2:
      return <CancelIcon htmlColor={color} onMouseEnter={onMouseEnter} />;
    default:
      return null;
  }
};

export const determineTooltipText = ({ iconType }) => {
  switch (iconType.type) {
    case CheckCircleIcon:
      return (
        <Typography variant="B-Text-4'" style={{ textAlign: 'center' }}>
          Got it
        </Typography>
      );
    case ErrorIcon:
      return (
        <Typography variant="B-Text-4'" style={{ textAlign: 'center' }}>
          Almost there
        </Typography>
      );
    case CancelIcon:
      return (
        <Typography variant="B-Text-4'" style={{ textAlign: 'center' }}>
          Not yet
        </Typography>
      );
    default:
      return (
        <Typography variant="B-Text-4'" style={{ textAlign: 'center' }}>
          Almost there
        </Typography>
      );
  }
};

export const getPlasUserStartegyName = ({ strategy }) => {
  if (
    strategy ===
    'General conceptual understanding but work may be insufficient or contain some errors'
  ) {
    return 'General conceptual understanding but work may be incomplete or contain some errors';
  }
  return strategy;
};
