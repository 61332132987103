import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SvgIcon from '@material-ui/core/SvgIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  TEACHERS_ASSIGNMENTS_FEEDBACK_URL,
  TEACHERS_MEETINGS_LIST_URL
} from 'config/urls';
import _ from 'lodash';
import {
  useSessionsStatistics,
  useSubmittedTasksStatistics,
  useUserSectionsCount,
  useUserTodayMeetings
} from 'sdk';

import { colors } from 'theme/palette';
import { reverse, stringifyParams } from 'utils/urls';

import Typography from 'components/Typography';

import { ReactComponent as RightArrowIcon } from './assets/arrow_right.svg';
import { ReactComponent as MeetingsIcon } from './assets/calendar.svg';
import { ReactComponent as SessionsIcon } from './assets/sessions.svg';
import { ReactComponent as SubmittedIcon } from './assets/submitted.svg';
import styles from './styles.module.scss';

const LoadingCard = () => (
  <Skeleton className={styles.card} variant="rect" width={300} height={125} />
);

const SubmittedCard = ({ closestTask }) => {
  const history = useHistory();
  const { data: submittedTasksStatistics, isLoading } =
    useSubmittedTasksStatistics();

  const handleClick = useCallback(() => {
    const closestAssignmentLinkObject = {
      pathname: reverse(TEACHERS_ASSIGNMENTS_FEEDBACK_URL, {
        trackerId: closestTask.tracker
      }),
      search: stringifyParams({
        student: closestTask.student,
        section: closestTask.section,
        task: closestTask.id
      })
    };

    history.push(closestAssignmentLinkObject);
  }, [history, closestTask]);

  if (isLoading) {
    return <LoadingCard />;
  }

  return submittedTasksStatistics.count > 0 ? (
    <Card variant="outlined" className={styles.card} onClick={handleClick}>
      <CardContent className={styles.cardContent}>
        <div className={styles.valueWrapper}>
          <Typography
            className={styles.value}
            color={colors.blueLight}
            variant="H-TEXT-1"
          >
            {submittedTasksStatistics.count}
          </Typography>
          <SvgIcon className={styles.icon} component={SubmittedIcon} />
        </div>
        <div className={styles.widgetTitle}>
          <Typography variant="H-TEXT-1" color={colors.grey3}>
            Pages Submitted
          </Typography>
          <RightArrowIcon />
        </div>
      </CardContent>
    </Card>
  ) : null;
};

const SessionsAssignedCard = () => {
  const { data: sessionsStatistics, isLoading } = useSessionsStatistics();

  if (isLoading) {
    return <LoadingCard />;
  }

  return sessionsStatistics.count > 0 ? (
    <Card variant="outlined" className={styles.card}>
      <CardContent className={styles.cardContent}>
        <div className={styles.valueWrapper}>
          <Typography
            className={styles.value}
            color={colors.yellow2}
            variant="H-TEXT-1"
          >
            {sessionsStatistics.count}
          </Typography>
          <SvgIcon
            className={styles.icon}
            component={SessionsIcon}
            htmlColor={colors.yellow2}
          />
        </div>

        <div className={styles.widgetTitle}>
          <Typography variant="H-TEXT-1" color={colors.grey3}>
            Groups Assigned
          </Typography>
          <RightArrowIcon />
        </div>
      </CardContent>
    </Card>
  ) : null;
};

const ClassesCountCard = () => {
  const { data: sectionsCount, loading } = useUserSectionsCount();

  if (loading || _.isNil(sectionsCount)) {
    return <LoadingCard />;
  }

  return (
    <Card
      variant="outlined"
      className={styles.card}
      style={{ cursor: 'default' }}
    >
      <CardContent className={styles.cardContent}>
        <div className={styles.valueWrapper}>
          <Typography
            className={styles.value}
            color={colors.grey1}
            variant="H-TEXT-1"
          >
            {sectionsCount}
          </Typography>
          <SvgIcon
            className={styles.icon}
            component={SessionsIcon}
            htmlColor={colors.grey1}
          />
        </div>
        <div className={styles.widgetTitle}>
          <Typography variant="H-TEXT-1" color={colors.grey3}>
            my classes
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

const Meetings = () => {
  const history = useHistory();
  const { data: todayMeetingsCount, loading } = useUserTodayMeetings();

  const handleClick = () => {
    history.push(TEACHERS_MEETINGS_LIST_URL);
  };

  if (loading || _.isNil(todayMeetingsCount)) {
    return <LoadingCard />;
  }
  return todayMeetingsCount !== 0 ? (
    <Card variant="outlined" className={styles.card} onClick={handleClick}>
      <CardContent className={styles.cardContent}>
        <div className={styles.valueWrapper}>
          <Typography
            className={styles.value}
            color={colors.blue3}
            variant="H-TEXT-1"
          >
            {todayMeetingsCount}
          </Typography>
          <SvgIcon
            className={styles.icon}
            component={MeetingsIcon}
            htmlColor={colors.blue3}
          />
        </div>
        <div className={styles.widgetTitle}>
          <Typography variant="H-TEXT-1" color={colors.grey3}>
            meeting{todayMeetingsCount > 1 && 's'} today
          </Typography>
          <RightArrowIcon />
        </div>
      </CardContent>
    </Card>
  ) : null;
};

const StatisticsCards = {
  Submitted: memo(SubmittedCard),
  SessionsAssigned: memo(SessionsAssignedCard),
  ClassesCount: memo(ClassesCountCard),
  Meetings: memo(Meetings)
};

export default StatisticsCards;
