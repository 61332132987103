import React, { useCallback } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import DescriptionIcon from '@material-ui/icons/Description';
import { TEACHERS_ASSIGNMENTS_RESULTS_URL } from 'config/urls';

import { colors } from 'theme/palette';
import { openInNewTab, reverse, stringifyParams } from 'utils/urls';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const AssignmentItem = ({ assignment }) => {
  const handleAssignemntClick = useCallback(() => {
    const pageUrl = reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
      trackerId: assignment.tracker_id
    });
    const params = stringifyParams({
      section: assignment.section_id
    });
    openInNewTab(`${pageUrl}?${params}`);
  }, [assignment.tracker_id, assignment.section_id]);

  return (
    <>
      <div className={styles.container} onClick={handleAssignemntClick}>
        <Typography
          variant="B-Text-3"
          color={colors.blue3}
          className={styles.content}
        >
          <SvgIcon
            component={DescriptionIcon}
            color="primary"
            className={styles.buttonIcon}
          />
          <div className={styles.assignmentDescription}>
            {assignment.name} - {assignment.section_name}
          </div>
        </Typography>
      </div>
    </>
  );
};

export default AssignmentItem;
