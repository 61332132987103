import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import CloseIcon from '@material-ui/icons/CloseRounded';
import SchoolIcon from '@material-ui/icons/School';
import cx from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';

import StandardDetailDialog from 'components/StandardDetailDialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StandardItem = ({ standard, onRemoveStandard }) => {
  const {
    isOpened: isStandardDetailDialogOpened,
    openDialog: openStandardDetailDialog,
    closeDialog: closeStandardDetailDialog
  } = useDialog();

  const shortDescription = _.get(standard, 'short_description', '');

  return (
    <>
      <div className={styles.container}>
        <Typography
          variant="B-Text-3"
          color={colors.blue3}
          className={cx(styles.content, 'pointer')}
          onClick={openStandardDetailDialog}
        >
          <SvgIcon
            component={SchoolIcon}
            color="primary"
            className={styles.buttonIcon}
          />
          <div className={styles.standardDescription}>
            <strong>{standard.code}</strong>
            {!_.isEmpty(shortDescription) && ` - ${shortDescription}`}
          </div>
        </Typography>
        <CloseIcon
          onClick={() => onRemoveStandard(standard.id)}
          htmlColor={colors.blue3}
          className="pointer"
        />
      </div>
      {isStandardDetailDialogOpened && (
        <StandardDetailDialog
          standardId={standard.id}
          onClose={closeStandardDetailDialog}
        />
      )}
    </>
  );
};

export default StandardItem;
