import React, { useState } from 'react';
import Linkify from 'react-linkify';

import IconButton from '@material-ui/core/IconButton';
import AddCommentIcon from '@material-ui/icons/AddComment';
import _ from 'lodash';
import moment from 'moment';

import { colors } from 'theme/palette';

import EmojiInputField from 'components/EmojiInputField';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const CardComments = ({ comments, onSubmit }) => {
  const [value, setValue] = useState('');

  return (
    <div className={styles.container}>
      {comments.map((comment, index) => {
        const user = _.get(comment, 'user');

        if (!user) {
          return null;
        }

        const initials = _.take(user.name.split(' '), 2)
          .map((name) => _.first(name)?.toUpperCase())
          .join('');

        return (
          <div className={styles.author} key={index}>
            <div className={styles.initials}>{initials}</div>
            <div className={styles.container}>
              <div className={styles.nameAndDateContainer}>
                <Typography variant="B-Text-3" color={colors.blue1}>
                  {user.name}
                </Typography>
                <Typography variant="B-Text-3" color={colors.grey3}>
                  {moment(comment.created_at).format('ddd MM/DD h:mm a')}
                </Typography>
              </div>
              <Typography variant="B-Text-2" color={colors.grey1}>
                <Linkify properties={{ target: '_blank' }}>
                  {comment.text}
                </Linkify>
              </Typography>
            </div>
          </div>
        );
      })}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit({ value });
          setValue('');
        }}
      >
        <div className={styles.inputFieldContainer}>
          <EmojiInputField
            fullWidth
            variant="underlined"
            placeholder="Reply..."
            value={value}
            InputProps={{
              disableUnderline: true
            }}
            classes={{ root: styles.inputField }}
            onChange={(event) => setValue(event.target.value)}
          />
          <IconButton type="submit" size="small" disabled={value === ''}>
            <AddCommentIcon />
          </IconButton>
        </div>
      </form>
    </div>
  );
};

export default CardComments;
