import { MuiBackdrop } from './MuiBackdrop';
import { MuiButton } from './MuiButton';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiDialogActions } from './MuiDialogActions';
import { MuiDialogContent } from './MuiDialogContent';
import { MuiDialogTitle } from './MuiDialogTitle';
import { MuiFormHelperText } from './MuiFormHelperText';
import { MuiInput } from './MuiInput';
import { MuiInputBase } from './MuiInputBase';
import { MuiPaginationItem } from './MuiPaginationItem';
import { MuiPaper } from './MuiPaper';
import { MuiSelect } from './MuiSelect';
import { MuiSvgIcon } from './MuiSvgIcon';
import { MuiTableCell } from './MuiTableCell';
import { MuiTextField } from './MuiTextField';
import { MuiToggleButton } from './MuiToggleButton';

// NOTE: Preserve this explicit structure! JS functions have no names after being minified.
const Overrides = (palette) => ({
  MuiSvgIcon: MuiSvgIcon(palette),
  MuiBackdrop: MuiBackdrop(palette),
  MuiDialogTitle: MuiDialogTitle(palette),
  MuiDialogContent: MuiDialogContent(palette),
  MuiDialogActions: MuiDialogActions(palette),
  MuiButton: MuiButton(palette),
  MuiToggleButton: MuiToggleButton(palette),
  MuiSelect: MuiSelect(palette),
  MuiTextField: MuiTextField(palette),
  MuiInput: MuiInput(palette),
  MuiInputBase: MuiInputBase(palette),
  MuiPaginationItem: MuiPaginationItem(palette),
  MuiPaper: MuiPaper(palette),
  MuiFormHelperText: MuiFormHelperText(palette),
  MuiTableCell: MuiTableCell(palette),
  MuiCssBaseline: MuiCssBaseline(palette)
});

export default Overrides;
