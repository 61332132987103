import React, { forwardRef, memo } from 'react';

import MuiButton from '@material-ui/core/Button';
import {
  ThemeProvider,
  createTheme,
  makeStyles
} from '@material-ui/core/styles';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

const variantUseStyles = makeStyles({
  base: {
    height: '40px',
    padding: '10px 20px',
    borderRadius: '5px',
    color: colors.white
  },
  medium: {
    height: '36px',
    padding: '10px 20px',
    borderRadius: '5px',
    color: colors.white
  },
  textAndIcon: {
    height: '40px',
    padding: '10px 20px 10px 22px',
    borderRadius: '5px',
    color: colors.white
  },
  small: {
    height: '24px',
    padding: '4px 10px',
    borderRadius: '5px',
    color: colors.white
  },
  link: {
    height: '20px',
    padding: 0,
    borderRadius: '5px',
    color: colors.blue3,
    textTransform: 'none',
    backgroundColor: 'transparent !important'
  }
});

const colorUseStyles = makeStyles({
  pink: {
    backgroundColor: colors.pink2,
    '&:hover': {
      backgroundColor: colors.pink1
    },
    '&:active': {
      backgroundColor: colors.pink4
    }
  },
  lightGreen: {
    backgroundColor: colors.green4,
    '&:hover': {
      backgroundColor: colors.green3
    },
    '&:active': {
      backgroundColor: colors.green2
    }
  },
  green: {
    backgroundColor: colors.green2,
    '&:hover': {
      backgroundColor: colors.green1
    },
    '&:active': {
      backgroundColor: colors.green4
    }
  },
  blue: {
    backgroundColor: colors.blue3,
    '&:hover': {
      backgroundColor: colors.blue2
    },
    '&:active': {
      backgroundColor: colors.blue5
    }
  },
  darkBlue: {
    backgroundColor: colors.blue2,
    '&:hover': {
      backgroundColor: colors.blue3
    },
    '&:active': {
      backgroundColor: colors.blue5
    }
  },
  lightGrey: {
    backgroundColor: colors.grey5,
    color: colors.grey1,
    '&:hover': {
      backgroundColor: colors.grey4
    },
    '&:active': {
      backgroundColor: colors.grey6
    }
  },
  darkGrey: {
    backgroundColor: colors.grey2,
    '&:hover': {
      backgroundColor: colors.grey6
    },
    '&:active': {
      backgroundColor: colors.grey4
    }
  },
  transparent: {
    backgroundColor: 'transparent'
  },
  link: {}
});

const buttonToTypographyVariants = {
  primary: 'H-TEXT-3',
  secondary: 'H-TEXT-3',
  medium: 'S-TEXT-1',
  small: 'S-TEXT-2',
  link: 'B-Text-1'
};
const getTypographyVariant = (buttonVariant) => {
  return _.get(buttonToTypographyVariants, buttonVariant, 'H-TEXT-3');
};

// We use the theme provider here, to disable the
// global theme.overrides of the old design
const theme = createTheme({});

const Button = (
  {
    variant = 'base',
    color,
    children,
    className,
    noTypography = false,
    ...props
  },
  ref
) => {
  const variantClasses = variantUseStyles();
  const colorClasses = colorUseStyles();

  let muiButtonVariant = 'contained';
  if (variant === 'transparent' || variant === 'link') {
    muiButtonVariant = 'text';
  }

  const rootClasses = _.get(props, 'classes.root');

  return (
    <ThemeProvider theme={theme}>
      <MuiButton
        ref={ref}
        disableElevation
        variant={muiButtonVariant}
        classes={{
          ...props.classes,
          root: classnames(
            variantClasses[variant],
            colorClasses[color],
            className,
            rootClasses
          )
        }}
        {..._.omit(props, 'classes')}
      >
        {!noTypography && (
          <Typography variant={getTypographyVariant(variant)} color="inherit">
            {children}
          </Typography>
        )}
        {noTypography && children}
      </MuiButton>
    </ThemeProvider>
  );
};

export default memo(forwardRef(Button));
