import React, { useContext, useState } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

import StudentSummaryDialogInsightsV2 from 'pages/Teachers/InsightsV2/components/StudentSummaryDialog';
import AIFeedbackDialog from 'pages/Teachers/StrategiesOverview/components/AIFeedbackDialog';
import StudentSummaryDialog from 'pages/Teachers/StrategiesOverview/components/StudentSummaryDialog';
import { remappedRubricsToChecks } from 'pages/Teachers/StrategiesOverview/utils';
import { StudentWorkCard as SharedStudentWorkCard } from 'pages/Teachers/shared';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as InfoIcon } from './assets/info.svg';
import styles from './styles.module.scss';

const StudentWorkCard = ({
  workTagImageId,
  studentName,
  studentWorkUrl,
  rubrics,
  setRubrics,
  setDialogIsOpen,
  refetchStrategiesData
}) => {
  const { user } = useContext(UserContext);
  const [summaryDialogIsOpen, setSummaryDialogIsOpen] = useState(false);
  const [aiFeedbackDialogIsOpen, setAIFeedbackDialogIsOpen] = useState(false);

  return (
    <>
      {aiFeedbackDialogIsOpen && (
        <AIFeedbackDialog
          setIsOpen={setAIFeedbackDialogIsOpen}
          workTagImageId={workTagImageId}
          onClose={() => {
            setAIFeedbackDialogIsOpen(false);
          }}
        />
      )}
      {summaryDialogIsOpen &&
        process.env.REACT_APP_SHOW_INSIGHTS_V2 === 'false' && (
          <StudentSummaryDialog
            workTagImageId={workTagImageId}
            studentName={studentName}
            studentWorkUrl={studentWorkUrl}
            isSummaryDialogIsOpen={summaryDialogIsOpen}
            setSummaryDialogIsOpen={setSummaryDialogIsOpen}
            openAIFeedbackDialog={() => setAIFeedbackDialogIsOpen(true)}
            onClose={(e) => {
              setSummaryDialogIsOpen(false);
              trackMixpanelEvent(
                user.id,
                '[StrategiesOverview] Teacher closed student work info card.'
              );
              e.stopPropagation();
            }}
          />
        )}
      {process.env.REACT_APP_SHOW_INSIGHTS_V2 === 'true' && (
        <StudentSummaryDialogInsightsV2
          studentName={studentName}
          workTagImageId={workTagImageId}
          refetchOverview={refetchStrategiesData}
          isSummaryDialogIsOpen={summaryDialogIsOpen}
          onClose={(e) => {
            setSummaryDialogIsOpen(false);
            trackMixpanelEvent(
              user.id,
              '[StrategiesOverview] Teacher closed student work info card.'
            );
            e.stopPropagation();
          }}
        />
      )}
      <SharedStudentWorkCard
        imageUrl={studentWorkUrl}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setSummaryDialogIsOpen(true);

          trackMixpanelEvent(
            user.id,
            '[StrategiesOverview] Teacher opened student work info card.'
          );
        }}
      >
        <div className={styles.workCardDiv}>
          <div className={styles.studentNameText}>{studentName}</div>
          {!_.isEmpty(rubrics) && (
            <Tooltip
              classes={{
                tooltip: styles.tooltipInfo,
                arrow: styles.tooltipArrow
              }}
              title={
                <Typography
                  variant="B-Text-3"
                  component="h5"
                  classes={{ root: styles.tooltipText }}
                >
                  {rubrics.map(({ rubric_name, value }, index) => (
                    <span key={index} className={styles.dialogText}>
                      {remappedRubricsToChecks({
                        masteryLevel: value,
                        color: '#fff'
                      })}{' '}
                      {rubric_name}
                    </span>
                  ))}
                </Typography>
              }
              placement="right"
              arrow={true}
            >
              <SvgIcon
                component={InfoIcon}
                className={styles.infoIcon}
                onMouseEnter={() => {
                  trackMixpanelEvent(
                    user.id,
                    '[StrategiesOverview] Teacher hovered tooltip info.'
                  );
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setRubrics(rubrics);
                  setDialogIsOpen(true);

                  trackMixpanelEvent(
                    user.id,
                    '[StrategiesOverview] Teacher opened tooltip info.'
                  );
                }}
              />
            </Tooltip>
          )}
        </div>
      </SharedStudentWorkCard>
    </>
  );
};

export default StudentWorkCard;
