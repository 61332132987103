import React, { useContext, useEffect, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import {
  EDLIGHT_MAIN_DOMAIN_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL,
  WALKTHROUGH_CREATE_PROFILE_URL
} from 'config/urls';
import logo from 'images/logo-dark.svg';
import _ from 'lodash';

import { useWalkthroughUser } from 'pages/Walkthrough/components';
import TeacherDataForm from 'pages/Walkthrough/components/RolloutForm/components/TeacherDataForm';
import { colors } from 'theme/palette';
import { buildFilters } from 'utils/filters';
import { notifyError, notifyErrors } from 'utils/notifications';
import { useBreakpoints } from 'utils/resizing';

import Image from 'components/Image';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import {
  cleverTeacherLibraryRollout,
  completeWalkthroughProfile,
  createSchool,
  fetchMe,
  getOrCreateWalkthroughOrganization,
  getSchoolsList,
  getStatesList,
  updateUserPreferredName
} from './sdk';
import styles from './styles.module.scss';

const RolloutForm = ({ history, location }) => {
  useWalkthroughUser(location.pathname);

  const isMobile = useBreakpoints({
    tablet: 992,
    mobile: 670
  })[2];

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      fetchMe().then(({ data, success }) => {
        if (success) {
          setUser(data);
        } else {
          notifyError('You need to create account first');
          history.push(WALKTHROUGH_CREATE_PROFILE_URL);
        }
      });
    }
  }, [user, setUser, history]);

  const [schools, setSchools] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedWalkthroughSchool, setSelectedWalkthroughSchool] =
    useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const [isCompletingProfile, setIsCompletingProfile] = useState(false);

  useEffect(() => {
    if (selectedState) {
      getSchoolsList(buildFilters({ state: selectedState }))
        .then(({ data, success, errors }) => {
          if (success) {
            setSchools(data);
          } else {
            notifyErrors(errors);
          }
        })
        .catch(notifyErrors);
    }
  }, [selectedState]);

  useEffect(() => {
    getStatesList()
      .then(({ data, success, errors }) => {
        if (success) {
          setStates(data);
        } else {
          notifyErrors(errors);
        }
      })
      .catch(notifyErrors);
  }, []);

  const rolloutWithCleverAndGoToOverview = async (schoolId) => {
    const { success, errors } = await cleverTeacherLibraryRollout({
      school: schoolId
    });

    if (success) {
      history.push(TEACHERS_ASSIGNMENTS_LIST_URL);
    } else {
      notifyErrors(errors);
    }
  };

  const ensureWalkthroughOrganization = async (values, orgData) => {
    const { data, success, errors } = await getOrCreateWalkthroughOrganization(
      orgData
    );

    if (success) {
      await createNewSchool(values, data.id);
    } else {
      notifyErrors(errors);
    }
  };

  const createNewSchool = async (values, walkthroughOrganizationId) => {
    const newSchoolData = {
      name: selectedWalkthroughSchool.name,
      state: values.stateAbbreviation,
      walkthrough_organization: walkthroughOrganizationId,
      city: values.city
    };

    const { data, success, errors } = await createSchool(newSchoolData);

    if (success) {
      await completeProfile(data.id);
    } else {
      notifyErrors(errors);
    }
  };

  const completeProfile = async (walkthroughSchoolId) => {
    const schoolData = {
      walkthrough_school: walkthroughSchoolId
    };

    const { data, success, errors } = await completeWalkthroughProfile(
      schoolData
    );

    if (success) {
      await rolloutWithCleverAndGoToOverview(data.school_id);
    } else {
      notifyErrors(errors);
    }
  };

  const onNextClick = async (values) => {
    setIsCompletingProfile(true);

    await updateUserPreferredName(user.id, {
      preferred_name: values.preferredUserName
    });

    if (_.isNull(selectedWalkthroughSchool.id)) {
      await ensureWalkthroughOrganization(values, {
        organization: selectedOrganization.id,
        name: selectedOrganization.name
      });
    } else {
      await completeProfile(selectedWalkthroughSchool.id);
    }

    setIsCompletingProfile(false);
  };

  return (
    <div className={styles.page}>
      {!isMobile && (
        <a href={EDLIGHT_MAIN_DOMAIN_URL}>
          <Image src={logo} className={styles.logoSmall} alt="Logo link" />
        </a>
      )}
      <div className={styles.container}>
        <Paper elevation={0} className={styles.paper}>
          <Image src={logo} className={styles.logoBig} alt="Logo placeholder" />

          <Typography variant="H-TEXT-2" color={colors.grey3} align="center">
            Welcome to EdLight!
          </Typography>
          <Typography variant="H-TEXT-2" color={colors.grey3} align="center">
            We just need a little info to get you started.
          </Typography>

          <TeacherDataForm
            schools={schools}
            states={states}
            selectedWalkthroughSchool={selectedWalkthroughSchool}
            setSelectedWalkthroughSchool={setSelectedWalkthroughSchool}
            setSelectedState={setSelectedState}
            selectedOrganization={selectedOrganization}
            setSelectedOrganization={setSelectedOrganization}
            isCompletingProfile={isCompletingProfile}
            onNextClick={onNextClick}
          />
        </Paper>
      </div>
    </div>
  );
};

export default RolloutForm;
