import React, { useCallback, useEffect, useRef, useState } from 'react';

const InfiniteScroll = ({ action, isLoading }) => {
  const elementRef = useRef(null);
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );

      observer.observe(elementRef.current);

      // Remove the observer as soon as the component is unmounted
      return () => observer.disconnect();
    }
  }, []);

  const handleIntersection = useCallback(async () => {
    if (isLoading) {
      return;
    }

    const initialScrollY = window.scrollY;

    await action();

    // Preserve the old scroll position.
    window.scrollTo(0, initialScrollY);
  }, [action, isLoading]);

  useEffect(() => {
    if (isIntersecting) {
      handleIntersection();
    }
  }, [action, isIntersecting, handleIntersection]);

  return <div ref={elementRef} />;
};

export default InfiniteScroll;
