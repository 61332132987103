import React, { useContext, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { isEmpty, isNull } from 'lodash';
import { workTagImageUpdateMisconceptions } from 'sdk';

import AddMisconceptionsDialog from 'pages/Teachers/InsightsV2/components/AddMisconceptionsDialog';
import ConfirmDialog from 'pages/Teachers/InsightsV2/components/ConfirmDialog';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { notifyError, notifySuccess } from 'utils/notifications';

import AddButton from 'components/AddButton';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const MisconceptionMasteryLevel = ({
  workTagImageId,
  masteryLevel,
  refetchOverview,
  refetchMasteryLevel
}) => {
  const user = useContext(UserContext);

  const { misconceptions, is_preannotation } = masteryLevel;

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const [misconceptionToDelete, setMisconceptionToDelete] = useState(null);
  const [misconceptionsToAdd, setMisconceptionsToAdd] = useState([]);

  const [preAnnotationFeedback, setPreAnnotationFeedback] = useState('');

  const updateMisconceptions = async ({
    newMisconceptions,
    misconceptionModified,
    operation
  }) => {
    const data = is_preannotation
      ? { misconceptions: newMisconceptions, feedback: preAnnotationFeedback }
      : { misconceptions: newMisconceptions };

    const { success } = await workTagImageUpdateMisconceptions({
      workTagImageId,
      data
    });

    if (success) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.FORM} ${MixpanelAction.SUBMIT}`,
        {
          feature: 'insights',
          view: 'student work',
          form: operation,
          misconception: misconceptionModified,
          reason: preAnnotationFeedback
        }
      );

      notifySuccess('Successfully updated misconceptions.');
      refetchOverview();
      refetchMasteryLevel();
    } else {
      notifyError('Something went wrong. Please try again.');
    }
  };

  const handleDeleteClick = (misconceptionLabel) => {
    setMisconceptionToDelete(misconceptionLabel);
    setIsDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    const misconceptionLabels = misconceptions.map((item) => item.name);

    const updatedMisconceptions = misconceptionLabels.filter(
      (msc) => msc !== misconceptionToDelete
    );
    updateMisconceptions({
      newMisconceptions: updatedMisconceptions,
      misconceptionModified: misconceptionToDelete,
      operation: 'remove'
    });

    setMisconceptionToDelete(null);
    setIsDeleteConfirmOpen(false);
    setPreAnnotationFeedback('');
  };

  const openAddDialog = () => setIsAddDialogOpen(true);

  const confirmAdd = async () => {
    const misconceptionLabels = misconceptions.map((item) => item.name);

    const updatedMisconceptions = Array.from(
      new Set([...misconceptionLabels, ...misconceptionsToAdd])
    );

    updateMisconceptions({
      newMisconceptions: updatedMisconceptions,
      misconceptionModified: misconceptionsToAdd,
      operation: 'add'
    });

    setMisconceptionsToAdd([]);
    setIsAddDialogOpen(false);
    setPreAnnotationFeedback('');
  };

  const closeDialogs = () => {
    setMisconceptionToDelete(null);
    setIsDeleteConfirmOpen(false);
    setIsAddDialogOpen(false);
  };

  return (
    <>
      <div className={styles.misconceptionsContainer}>
        <Typography
          variant="H-TEXT-3"
          component="h5"
          className={styles.summaryData}
        >
          Misconceptions
        </Typography>
        <div className={styles.innerData}>
          {isEmpty(misconceptions) && 'Bright work! No misconceptions.'}
          {misconceptions.map((misconception, index) => (
            <div key={index} className={styles.misconception}>
              <Typography variant="S-TEXT-1">
                <span className={styles.misconceptionText}>
                  {index + 1}. {misconception.name}
                </span>
                {!isNull(misconception.analysis) && (
                  <span className={styles.analysisText}>
                    {' - '}
                    {misconception.analysis}
                  </span>
                )}
              </Typography>
              <CloseIcon
                className={styles.closeIcon}
                onClick={() => handleDeleteClick(misconception.name)}
              />
            </div>
          ))}
        </div>
        <div className={styles.buttonContainer}>
          <AddButton
            color="blue"
            className={styles.nextStepButton}
            onClick={openAddDialog}
          >
            <Typography
              variant="B-Text-4"
              component="span"
              className={styles.nextStepText}
            >
              Add Misconceptions
            </Typography>
          </AddButton>
        </div>
      </div>
      {isDeleteConfirmOpen && (
        <ConfirmDialog
          onClose={closeDialogs}
          onConfirm={confirmDelete}
          text={'Are you sure you want to delete this misconception?'}
          onPreannotationFeedbackChange={({ newFeedback }) =>
            setPreAnnotationFeedback(newFeedback)
          }
          disableFeedback={!is_preannotation}
        />
      )}
      {isAddDialogOpen && (
        <AddMisconceptionsDialog
          isOpen={isAddDialogOpen}
          onConfirm={confirmAdd}
          onClose={closeDialogs}
          setMisconceptionsToAdd={setMisconceptionsToAdd}
          setPreAnnotationFeedback={({ newFeedback }) =>
            setPreAnnotationFeedback(newFeedback)
          }
          disableFeedback={!is_preannotation}
        />
      )}
    </>
  );
};

export default MisconceptionMasteryLevel;
