import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import classnames from 'classnames';
import { GOOGLE_LOGIN_REDIRECT_URI } from 'config/constants';
import _ from 'lodash';
import { googleManualSync, useCleverLastSync, useGoogleAuthToken } from 'sdk';

import HelpDialog from 'pages/Teachers/shared/HelpDialog';
import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';
import { CleverSyncOrigins } from 'utils/integrations/clever';
import { openGoogleLoginPage } from 'utils/integrations/google';
import { GoogleClassroomSyncStatuses } from 'utils/integrations/googleClassroom';
import { calendarDate } from 'utils/moment';
import { notifyErrors } from 'utils/notifications';

import Button from 'components/Button';
import IntegrationsSyncContext from 'components/IntegrationsSyncContext';
import Typography from 'components/Typography';
import UpdateGoogleClassroomRostersDialog from 'components/UpdateGoogleClassroomRostersDialog';

import { ReactComponent as RightArrowIcon } from './assets/arrow_right.svg';
import styles from './styles.module.scss';
import { useGoogleClassroomLastSync } from './utils';

const DefaultStudentSourceCard = ({ openHelpDialog }) => (
  <Card variant="outlined" className={styles.card}>
    <CardContent className={styles.cardContent}>
      <Typography color={colors.grey1} variant="S-TEXT-1" component="span">
        Student source:{' '}
      </Typography>
      <Typography color={colors.blue3} variant="H-TEXT-3" component="span">
        District
      </Typography>

      <div className={classnames(styles.widgetTitle, styles.extraOffset)}>
        <Button
          noTypography
          variant="link"
          onClick={openHelpDialog}
          classes={{
            root: styles.syncButton,
            label: styles.syncButtonLabel
          }}
          endIcon={<RightArrowIcon />}
        >
          <Typography variant="H-TEXT-1" color={colors.grey3} align="left">
            Get Roster Help
          </Typography>
        </Button>
      </div>
    </CardContent>
  </Card>
);

const GoogleClassroomCard = ({
  lastSync,
  setLastSync,
  googleAuthToken,
  goToGoogleAuthScreen,
  openHelpDialog
}) => {
  const {
    googleClassroom: {
      isInitiated: currentSyncIsInitiated,
      isFailed: currentSyncIsFailed,
      setSync: setCurrentSync
    }
  } = useContext(IntegrationsSyncContext);

  const lastSyncIsFailed = useMemo(
    () => lastSync.status === GoogleClassroomSyncStatuses.FAILED.value,
    [lastSync]
  );

  const syncManually = useCallback(async () => {
    try {
      const data = await googleManualSync();

      if (!_.isEmpty(data)) {
        setLastSync(data);
        setCurrentSync(data);
      }
    } catch (errors) {
      notifyErrors(errors);
    }
  }, [setLastSync, setCurrentSync]);

  const syncButtonEndIcon = useMemo(
    () => !currentSyncIsInitiated && <RightArrowIcon />,
    [currentSyncIsInitiated]
  );

  const syncIsFailed = currentSyncIsFailed || lastSyncIsFailed;
  const canSyncGoogleClassroom = _.get(
    googleAuthToken,
    'can_sync_google_classroom',
    false
  );

  const buttonText = useMemo(() => {
    if (currentSyncIsInitiated) {
      return 'Syncing classes...';
    } else if (syncIsFailed && canSyncGoogleClassroom) {
      return 'Get Roster Help';
    }

    return 'Sync classes';
  }, [currentSyncIsInitiated, syncIsFailed, canSyncGoogleClassroom]);

  const buttonClickHandler = useMemo(() => {
    if (!canSyncGoogleClassroom) {
      return goToGoogleAuthScreen;
    }

    if (syncIsFailed) {
      return openHelpDialog;
    }

    return syncManually;
  }, [
    syncIsFailed,
    openHelpDialog,
    canSyncGoogleClassroom,
    goToGoogleAuthScreen,
    syncManually
  ]);

  if (!lastSync) {
    return <DefaultStudentSourceCard openHelpDialog={openHelpDialog} />;
  }

  return (
    <Card variant="outlined" className={styles.card}>
      <CardContent className={styles.cardContent}>
        <Typography color={colors.grey1} variant="S-TEXT-1" component="span">
          Student source:{' '}
        </Typography>
        <Typography color={colors.blue3} variant="H-TEXT-3" component="span">
          Google
        </Typography>

        {syncIsFailed ? (
          <Typography color={colors.pink} variant="B-Text-2">
            {!_.isEmpty(googleAuthToken) && !canSyncGoogleClassroom
              ? 'Provide all the required access.'
              : 'Sync failed.'}
          </Typography>
        ) : (
          <Typography color={colors.grey3} variant="B-Text-2">
            Last sync: {calendarDate(lastSync.created_at)}
          </Typography>
        )}

        <div className={styles.widgetTitle}>
          <Button
            noTypography
            variant="link"
            onClick={buttonClickHandler}
            disabled={currentSyncIsInitiated}
            classes={{
              root: styles.syncButton,
              label: styles.syncButtonLabel
            }}
            endIcon={syncButtonEndIcon}
          >
            <Typography variant="H-TEXT-1" color={colors.grey3}>
              {buttonText}
            </Typography>
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const CleverCard = ({ lastSync, openHelpDialog }) => {
  const lastSyncOrigin = useMemo(() => _.get(lastSync, 'origin'), [lastSync]);
  const lastSyncData = useMemo(() => _.get(lastSync, 'data'), [lastSync]);

  const syncOriginData = useMemo(
    () => _.find(CleverSyncOrigins, ({ value }) => value === lastSyncOrigin),
    [lastSyncOrigin]
  );

  const isLibrarySync = useMemo(
    () => _.get(syncOriginData, 'value') === CleverSyncOrigins.LIBRARY.value,
    [syncOriginData]
  );

  // No Clever origin - this means we have no existing Clever sync(s) for the user.
  if (_.isNil(syncOriginData)) {
    return <DefaultStudentSourceCard openHelpDialog={openHelpDialog} />;
  }

  if (!lastSyncData && isLibrarySync) {
    return null;
  }

  return (
    <Card variant="outlined" className={styles.card}>
      <CardContent className={styles.cardContent}>
        <Typography color={colors.grey1} variant="S-TEXT-1" component="span">
          Student source:{' '}
        </Typography>
        <Typography color={colors.blue3} variant="H-TEXT-3" component="span">
          {_.get(syncOriginData, 'label')}
        </Typography>

        {isLibrarySync && lastSync && (
          <Typography color={colors.grey3} variant="B-Text-2">
            Last sync: {calendarDate(lastSync.created_at)}
          </Typography>
        )}

        <div
          className={classnames(styles.widgetTitle, {
            [styles.extraOffset]: !isLibrarySync || !lastSync
          })}
        >
          <Button
            noTypography
            variant="link"
            onClick={openHelpDialog}
            classes={{
              root: styles.syncButton,
              label: styles.syncButtonLabel
            }}
            endIcon={<RightArrowIcon />}
          >
            <Typography variant="H-TEXT-1" color={colors.grey3}>
              Get Roster Help
            </Typography>
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const ClassesSyncCards = () => {
  const { data: googleAuthToken } = useGoogleAuthToken();
  const {
    lastSync: lastGoogleSync,
    setLastSync: setLastGoogleSync,
    loadingLastSync: loadingLastGoogleSync
  } = useGoogleClassroomLastSync();

  const {
    isOpened: isUpdateGoogleClassroomRostersDialogOpened,
    openDialog: openUpdateGoogleClassroomRostersDialog,
    closeDialog: closeUpdateGoogleClassroomRostersDialog
  } = useDialog();

  const shouldOpenUpdateGoogleClassroomRostersDialog = useMemo(
    () => !_.isEmpty(lastGoogleSync) && googleAuthToken === '',
    [lastGoogleSync, googleAuthToken]
  );

  const goToGoogleAuthScreen = useCallback(() => {
    const redirectUri = GOOGLE_LOGIN_REDIRECT_URI;

    const scope = _.join(
      [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/classroom.profile.emails',
        'https://www.googleapis.com/auth/classroom.courses.readonly',
        'https://www.googleapis.com/auth/classroom.rosters.readonly'
      ],
      ' '
    );

    const state = { override_auth_token: true };
    const prompt = 'consent';

    openGoogleLoginPage(redirectUri, scope, state, prompt);
  }, []);

  useEffect(() => {
    if (shouldOpenUpdateGoogleClassroomRostersDialog) {
      openUpdateGoogleClassroomRostersDialog();
    }
  }, [
    shouldOpenUpdateGoogleClassroomRostersDialog,
    openUpdateGoogleClassroomRostersDialog
  ]);

  const { data: lastCleverSync, isLoading: loadingLastCleverSync } =
    useCleverLastSync();

  const isLoadingSyncData = useMemo(
    () => loadingLastGoogleSync || loadingLastCleverSync,
    [loadingLastGoogleSync, loadingLastCleverSync]
  );

  const {
    isOpened: isHelpDialogOpened,
    openDialog: openHelpDialog,
    closeDialog: closeHelpDialog
  } = useDialog();

  return (
    <div className={styles.grid}>
      {isLoadingSyncData && (
        <Skeleton
          className={styles.card}
          variant="rect"
          width={300}
          height={118}
        />
      )}
      {!isLoadingSyncData && !_.isEmpty(lastGoogleSync) && (
        <GoogleClassroomCard
          lastSync={lastGoogleSync}
          setLastSync={setLastGoogleSync}
          googleAuthToken={googleAuthToken}
          goToGoogleAuthScreen={goToGoogleAuthScreen}
          openHelpDialog={openHelpDialog}
        />
      )}
      {!isLoadingSyncData && _.isEmpty(lastGoogleSync) && (
        <CleverCard lastSync={lastCleverSync} openHelpDialog={openHelpDialog} />
      )}
      {isHelpDialogOpened && <HelpDialog onClose={closeHelpDialog} />}
      {isUpdateGoogleClassroomRostersDialogOpened && (
        <UpdateGoogleClassroomRostersDialog
          onSync={goToGoogleAuthScreen}
          onClose={closeUpdateGoogleClassroomRostersDialog}
        />
      )}
    </div>
  );
};

export default ClassesSyncCards;
