import AssignedIcon from './assets/assigned.svg';
import CompleteIcon from './assets/complete.svg';
import MissingIcon from './assets/missing.svg';
import ReviseIcon from './assets/revise.svg';
import SubmittedIcon from './assets/submitted.svg';

export const formatDateToString = (dateString, includeTime = false) => {
  const date = new Date(dateString);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  if (!includeTime) {
    return `${month} ${day}${getDaySuffix(day)}, ${year}`;
  }

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${month} ${day}${getDaySuffix(
    day
  )}, ${year} at ${hours}:${minutes}:${seconds} ${ampm}`;
};

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export const getStatusIcon = (status) => {
  switch (status.toLowerCase()) {
    case 'complete':
      return CompleteIcon;
    case 'revised':
      return ReviseIcon;
    case 'submitted':
      return SubmittedIcon;
    case 'assigned':
      return AssignedIcon;
    case 'missing':
    default:
      return MissingIcon;
  }
};
