import React from 'react';

const PieChartDivider = ({ color }) => {
  const dividerContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const dividerStyle = {
    width: '360px',
    height: '8px',
    borderRadius: '8px',
    backgroundColor: color
  };

  return (
    <div style={dividerContainerStyle}>
      <div style={dividerStyle}></div>
    </div>
  );
};

export default PieChartDivider;
