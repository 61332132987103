import React from 'react';

import MuiDialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import _ from 'lodash';

import styles from './styles.module.scss';

const useStyles = makeStyles({
  paperWidthSm: { maxWidth: 500, borderRadius: 5 },
  paperWidthMd: { maxWidth: 820, borderRadius: 5 },
  paperWidthLg: { maxWidth: 940, borderRadius: 5 }
});

const Dialog = ({
  children,
  onClose,
  showClose = true,
  CloseIconTooltipProps = null,
  alignTop = false,
  ...props
}) => {
  const closeIcon = (
    <div onClick={onClose} className={styles.closeButton}>
      <CloseIcon fontSize="large" />
    </div>
  );

  const classes = useStyles();

  return (
    <MuiDialog
      {...props}
      scroll="body"
      onClose={onClose}
      classes={{
        ...props.classes,
        paper: classnames(styles.dialog, _.get(props, 'classes.paper')),
        paperScrollBody: classnames(
          { [styles.scrollBody]: alignTop },
          _.get(props, 'classes.paperScrollBody')
        ),
        ...classes
      }}
    >
      {showClose &&
        onClose &&
        (CloseIconTooltipProps ? (
          <Tooltip placement="right" {...CloseIconTooltipProps}>
            {closeIcon}
          </Tooltip>
        ) : (
          closeIcon
        ))}
      {children}
    </MuiDialog>
  );
};

export default Dialog;
