export const formatExemplarStudentWorks = ({ exemplarUrls }) => {
  let exemplarStudentWorks = [];
  exemplarUrls.forEach((url) => {
    const exemplarStudentWork = {};
    exemplarStudentWork.imageUrl = url;
    exemplarStudentWork.category = 'Exemplar Student Work';
    exemplarStudentWorks.push(exemplarStudentWork);
  });
  return exemplarStudentWorks;
};
