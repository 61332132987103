import React, { useMemo } from 'react';
import { default as ReactDropzone } from 'react-dropzone';

import SvgIcon from '@material-ui/core/SvgIcon';
import classnames from 'classnames';
import _ from 'lodash';

import Button from 'components/Button';

import { ReactComponent as UploadIcon } from './assets/upload.svg';
import styles from './styles.module.scss';

const Dropzone = ({
  buttonText = 'Upload Files',
  className,
  ButtonProps,
  ...props
}) => {
  const buttonColor = useMemo(
    () => _.get(ButtonProps, 'color', 'lightGrey'),
    [ButtonProps]
  );

  const buttonClasses = useMemo(
    () => ({
      ..._.get(ButtonProps, 'classes', {}),
      root: classnames(
        styles.dropzoneButton,
        _.get(ButtonProps, 'classes.root'),
        className
      )
    }),
    [ButtonProps, className]
  );

  return (
    <ReactDropzone {...props}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button
            color={buttonColor}
            classes={buttonClasses}
            startIcon={<SvgIcon component={UploadIcon} />}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </ReactDropzone>
  );
};

export default React.memo(Dropzone);
