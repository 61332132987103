import React from 'react';

import {
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ResponsiveContainer
} from 'recharts';

import styles from './styles.module.scss';

// This is a custom tick label implementation that produces SVG elements
const MultilineTick = (props) => {
  const { x, y, payload } = props;
  const maxLineLength = 10;

  const wrapText = (text, maxLineLength) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = [];

    words.forEach((word) => {
      const currentLineLength = currentLine.join(' ').length;
      if (currentLineLength + word.length + 1 <= maxLineLength) {
        currentLine.push(word);
      } else {
        lines.push(currentLine.join(' '));
        currentLine = [word];
      }
    });

    lines.push(currentLine.join(' '));
    return lines;
  };

  const lines = wrapText(payload.value, maxLineLength);

  return (
    <text
      x={x}
      y={y}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fill: '#585981',
        fontFamily: 'Nunito',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 'bold'
      }}
    >
      {lines.map((line, index) => (
        <tspan x={x} dy={index === 0 ? 0 : 12} key={index}>
          {line}
        </tspan>
      ))}
    </text>
  );
};

const ResultsChart = ({ results }) => (
  <div className={styles.resultsChart}>
    {results && (
      <ResponsiveContainer>
        {/* Please avoid fiddling with the dimensions and sizes as it's very hard to get the right values
        to make the chart responsive on all devices */}
        <RadarChart outerRadius={'65%'} data={results}>
          <PolarGrid />
          <PolarAngleAxis
            dataKey="subject"
            tick={MultilineTick}
            tickSize={40}
          />
          <Radar
            dataKey="percentage"
            stroke="#82ca9d"
            fill="#82ca9d"
            fillOpacity={0.6}
          />
        </RadarChart>
      </ResponsiveContainer>
    )}
  </div>
);

export default ResultsChart;
