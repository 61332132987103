import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { STUDENTS_NOTIFICATIONS_LIST_URL } from 'config/urls';

import List from './List';

const StudentNotifications = () => {
  return (
    <Switch>
      <Route exact path={STUDENTS_NOTIFICATIONS_LIST_URL} component={List} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default StudentNotifications;
