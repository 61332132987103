import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import HelpDialog from 'pages/Teachers/shared/HelpDialog';
import { ReactComponent as HelpIcon } from 'pages/Teachers/shared/HelpDialog/assets/help.svg';
import { useDialog } from 'utils/hooks';

import styles from './styles.module.scss';

const HelpButton = () => {
  const { isOpened, openDialog, closeDialog } = useDialog();

  return (
    <>
      <IconButton onClick={openDialog} classes={{ root: styles.iconButton }}>
        <HelpIcon />
      </IconButton>
      {isOpened && <HelpDialog onClose={closeDialog} />}
    </>
  );
};

export default React.memo(HelpButton);
