import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {
  TEACHERS_ASSIGNMENTS_FEEDBACK_URL,
  TEACHERS_STUDENT_PORTFOLIO_ASSIGNMENT_DETAILS_URL
} from 'config/urls';
import { ReactComponent as CopyIcon } from 'images/copy.svg';
import _ from 'lodash';
import { copyToClipboard, useCaching } from 'utils';

import { TaskCard } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { AssignmentStatusOptions } from 'utils/constants';
import { uploadImage } from 'utils/files';
import { reverse, stringifyParams } from 'utils/urls';

import AssignmentStatusIcon from 'components/AssignmentStatusIcon';
import Button from 'components/Button';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { LoadingListViewRowSkeleton } from '../LoadingListViewSkeleton';
import {
  postAssignmentComment,
  updateTaskStatus,
  uploadAssignmentFeedback
} from './sdk';
import styles from './styles.module.scss';
import { formatDate } from './utils';

const StudentTaskCardTooltip = ({
  task,
  index,
  openedTooltip,
  setOpenedTooltip,
  assignment,
  studentId,
  children
}) => {
  const [activeTask, setActiveTask] = useCaching(task);

  return (
    <Tooltip
      open={openedTooltip === task.id}
      classes={{
        popper: styles.tooltipPopper,
        tooltip: styles.tooltipBox
      }}
      leaveDelay={200}
      title={
        <StudentTaskCard
          onMouseEnter={() => setOpenedTooltip(task.id)}
          onMouseLeave={() => setOpenedTooltip()}
          task={activeTask}
          setTask={setActiveTask}
          index={index}
          assignment={assignment}
          studentId={studentId}
        />
      }
    >
      {children}
    </Tooltip>
  );
};

const changeTaskStatus = (trackerScoreResponseId, status) => {
  return updateTaskStatus({
    trackerScoreResponseId,
    data: { status }
  }).then(() => status);
};
const uploadImageBlob = async (blob, activeWork) => {
  const feedbackFileId = await uploadImage({
    blob,
    name: 'feedback'
  });

  const data = await uploadAssignmentFeedback({
    feedback_file_id: feedbackFileId,
    original_work: activeWork.original_id || activeWork.id
  });

  return data;
};

const StudentTaskCard = ({
  task,
  setTask,
  index,
  assignment,
  studentId,
  onMouseEnter,
  onMouseLeave
}) => {
  const handleTaskStatusChange = useCallback(
    (trackerScoreResponseId, status) => {
      changeTaskStatus(trackerScoreResponseId, status).then(() => {
        setTask((task) => ({ ...task, status }));
      });
    },
    [setTask]
  );

  const userContext = useContext(UserContext);
  const user = userContext.user;
  const handleAddingComment = useCallback(
    ({ trackerScoreResponseId, data }) => {
      postAssignmentComment({ trackerScoreResponseId, data });
      setTask((task) => {
        const commentObject = {
          ...data,
          id: `_${task.comments.length}`,
          user: { is_student: false, name: user.name }
        };

        return {
          ...task,
          comments: [...task.comments, commentObject]
        };
      });
    },
    [setTask, user.name]
  );
  const handleAddingSticker = useCallback(
    (blob, activeWork) => {
      uploadImageBlob(blob, activeWork);
      const url = window.URL.createObjectURL(blob);
      setTask((task) => ({
        ...task,
        work: [
          {
            id: `_${task.work.length}`,
            is_feedback: true,
            work_url: url
          },
          ...task.work
        ]
      }));
    },
    [setTask]
  );

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <TaskCard
        task={task}
        taskIndex={index + 1}
        trackerId={assignment.tracker_id}
        assignmentId={assignment.id}
        studentId={studentId}
        onTaskStatusChange={handleTaskStatusChange}
        onStickerAdd={handleAddingSticker}
        onCommentAdd={handleAddingComment}
      />
    </div>
  );
};

const HeaderCell = ({ children }) => (
  <TableCell className={styles.headerCell}>
    <Typography variant="H-TEXT-3" color={colors.blue1}>
      {children}
    </Typography>
  </TableCell>
);

const Cell = ({ children }) => (
  <TableCell className={styles.cell}>{children}</TableCell>
);

const StatusIcon = ({ status }) => {
  const { icon: Icon, color } = _.get(AssignmentStatusIcon, status);
  return <Icon fill={color} />;
};

const AssignmentRow = ({ assignment, studentId }) => {
  const [openedTooltip, setOpenedTooltip] = useState();
  const tasksCount = assignment.tasks.length;
  const linkToDetails = reverse(
    TEACHERS_STUDENT_PORTFOLIO_ASSIGNMENT_DETAILS_URL,
    {
      studentId,
      assignmentId: assignment.id
    }
  );
  const linkToFeedback = (taskId) => ({
    pathname: reverse(TEACHERS_ASSIGNMENTS_FEEDBACK_URL, {
      trackerId: assignment.tracker_id
    }),
    search: stringifyParams({
      student: studentId,
      section: assignment.id,
      task: taskId
    })
  });

  const assignedTasksCount = assignment.tasks.filter(
    (task) => task.status === AssignmentStatusOptions.ASSIGNED
  ).length;

  return (
    <TableRow hover>
      <Cell>
        <Link to={linkToDetails} className={styles.noUnderline}>
          <Typography variant="B-Text-2" color={colors.grey1}>
            {assignment.name}
          </Typography>
        </Link>
      </Cell>
      <Cell>
        <Typography variant="B-Text-2" color={colors.grey2}>
          {formatDate(assignment.due_at)}
        </Typography>
      </Cell>
      <Cell>
        <Typography variant="B-Text-2" color={colors.grey1}>
          <StatusIcon status={assignment.overall_status} />
        </Typography>
      </Cell>
      <Cell>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {tasksCount - assignedTasksCount} / {tasksCount}
        </Typography>
      </Cell>
      <Cell>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {assignment.complete_tasks_count} / {tasksCount}
        </Typography>
      </Cell>
      <Cell>
        {assignment.tasks.map((task, index) => (
          <span key={index}>
            {task.work.length > 0 && (
              <>
                <StudentTaskCardTooltip
                  task={task}
                  index={index}
                  assignment={assignment}
                  studentId={studentId}
                  openedTooltip={openedTooltip}
                  setOpenedTooltip={setOpenedTooltip}
                >
                  <span
                    onMouseEnter={() => setOpenedTooltip(task.id)}
                    onMouseLeave={() => setOpenedTooltip()}
                  >
                    <Link to={linkToFeedback(task.id)}>{index + 1}</Link>
                  </span>
                </StudentTaskCardTooltip>{' '}
              </>
            )}
            {task.work.length === 0 && <>{index + 1} </>}
          </span>
        ))}
      </Cell>
      <Cell>
        <Button
          noTypography
          variant="textAndIcon"
          color="transparent"
          onClick={() => copyToClipboard(assignment.student_deeplink)}
        >
          <CopyIcon />
          <Typography
            display="inline"
            variant="S-TEXT-1"
            color={colors.blue4}
            className={styles.copyLinkText}
          >
            Copy Link
          </Typography>
        </Button>
      </Cell>
    </TableRow>
  );
};

// `loading` is used for the "Load more" feature
const ListView = ({ assignments, studentId, loading }) => (
  <TableContainer variant="outlined" classes={{ root: styles.tableContainer }}>
    <Table className={styles.table}>
      <TableHead>
        <TableRow>
          <HeaderCell>Name</HeaderCell>
          <HeaderCell>Due</HeaderCell>
          <HeaderCell>Overall Status</HeaderCell>
          <HeaderCell>Tasks Submitted</HeaderCell>
          <HeaderCell>Tasks Complete</HeaderCell>
          <HeaderCell>Tasks </HeaderCell>
          <HeaderCell>Student Link</HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {assignments.map((assignment) => (
          <AssignmentRow
            key={assignment.id}
            assignment={assignment}
            studentId={studentId}
          />
        ))}
        {loading &&
          _.range(3).map((x) => <LoadingListViewRowSkeleton key={x} />)}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ListView;
