import { BASE_URL } from 'config/urls';

import { post, requestSdk } from 'utils/sdk';

export const removeMeetingStandard = async (meetingId, standardId) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/meetings/${meetingId}/standards/remove/`, {
      standard: standardId
    })
  );
