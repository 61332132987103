import React from 'react';

import classnames from 'classnames';
import _ from 'lodash';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StudentWorkTasksButtons = ({
  elements,
  classes = {},
  onChange = () => {}
}) => (
  <div className={classnames(styles.container, classes.root)}>
    {_.map(elements, (element) => (
      <Typography
        key={element.key || element.label}
        onClick={() => onChange(element)}
        variant="S-TEXT-1"
        display="inline"
        className={classnames(
          {
            [styles.selected]: element.isActive,
            [classes.elementActive]: element.isActive
          },
          styles.element,
          classes.element
        )}
      >
        {element.label}
      </Typography>
    ))}
  </div>
);

export default React.memo(StudentWorkTasksButtons);
