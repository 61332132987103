import React, { useEffect, useMemo, useState } from 'react';

import { Dialog } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { includes, isEmpty, isNil, isNull, size } from 'lodash';
import {
  bulkUploadImageUpdate,
  excludeBulkUploadImage,
  useBulkUploadStudents
} from 'sdk';

import ExcludeImageConfirmationDialog from 'pages/Teachers/BulkUpload/MatchStudents/components/ExcludeImageConfirmationDialog';
import SearchField from 'pages/Teachers/shared/SearchField';
import { useDialog } from 'utils/hooks';
import { notifyErrors } from 'utils/notifications';

import Button from 'components/Button';
import Image from 'components/Image';
import SelectFilter from 'components/SelectFilter';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const BulkUploadImageUpdate = ({
  bulkUploadId,
  bulkUploadGroup,
  tasks,
  unmatchedGroupsCount,
  onMatchSuccess,
  onDialogClose,
  trackMatchedStudentMatched,
  trackUnmatchedStudentMatched,
  trackImageExclude,
  trackImageSave
}) => {
  const allGroupsMatched = unmatchedGroupsCount === 0;
  const matchedStudent = bulkUploadGroup.student;
  const isAlreadyMatched = !isNil(matchedStudent);

  const [bulkUploadIndex, setBulkUploadIndex] = useState(0);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [bulkUploadImage, setBulkUploadImage] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  const editingFirstImageOfAGroup = bulkUploadIndex === 0;

  useEffect(() => {
    setBulkUploadImage(bulkUploadGroup.bulk_upload_images[bulkUploadIndex]);
    setSelectedTaskId(
      bulkUploadGroup.bulk_upload_images[bulkUploadIndex].task.id
    );
  }, [bulkUploadGroup, bulkUploadIndex, setBulkUploadImage]);

  const {
    isOpened: isExcludeConfirmDialogOpened,
    openDialog: openExcludeConfirmDialog,
    closeDialog: closeExcludeConfirmDialog
  } = useDialog();

  const { data: students } = useBulkUploadStudents({ bulkUploadId });

  const { data: unmatchedStudents, refetch: refetchUnmatchedStudents } =
    useBulkUploadStudents({
      unmatchedOnly: true,
      bulkUploadId
    });

  const onMatchSuccessActions = () => {
    if (isAlreadyMatched || !editingFirstImageOfAGroup) {
      onDialogClose();
    } else {
      setSelectedStudent(null);
      setSelectedTaskId(tasks[0].id);

      onMatchSuccess();
      refetchUnmatchedStudents();
    }
  };

  const handleImageUpdate = async ({ selectedStudent }) => {
    if (isNil(selectedStudent) && selectedTaskId === bulkUploadImage.task.id) {
      onDialogClose();
      return;
    }

    let data = {
      apply_student_to_all_other_images_in_the_same_group:
        editingFirstImageOfAGroup
    };

    if (!isNil(selectedStudent)) {
      data = { ...data, student: selectedStudent.id };
    }

    if (selectedTaskId !== bulkUploadImage.task.id) {
      data = { ...data, task: selectedTaskId };
    }

    const { success, errors } = await bulkUploadImageUpdate({
      bulkUploadId,
      bulkUploadImageId: bulkUploadImage.id,
      data
    });
    if (success) {
      if (isAlreadyMatched && !isNil(selectedStudent)) {
        trackMatchedStudentMatched({
          previousName: matchedStudent.name,
          newName: selectedStudent.name
        });
      }
      if (!isAlreadyMatched && !isNil(selectedStudent)) {
        trackUnmatchedStudentMatched();
      }

      onMatchSuccessActions();
    } else {
      notifyErrors(errors);
    }
  };

  const handleImageExclude = async () => {
    closeExcludeConfirmDialog();
    const { success, errors } = await excludeBulkUploadImage({
      bulkUploadId,
      bulkUploadImageId: bulkUploadImage.id
    });
    if (success) {
      trackImageExclude();
      onDialogClose();
    } else {
      notifyErrors(errors);
    }
  };

  const headerTile = useMemo(() => {
    if (isAlreadyMatched) {
      return matchedStudent.name;
    }

    return (
      <div className={styles.notMatchedCount}>
        {unmatchedGroupsCount} Students not matched
      </div>
    );
  }, [matchedStudent, isAlreadyMatched, unmatchedGroupsCount]);

  const handleNextIconClick = () => {
    const nextIndex =
      (bulkUploadIndex + 1) % bulkUploadGroup.bulk_upload_images.length;
    setBulkUploadIndex(nextIndex);
  };

  const handlePreviousIconClick = () => {
    const previousIndex =
      (bulkUploadIndex - 1 + bulkUploadGroup.bulk_upload_images.length) %
      bulkUploadGroup.bulk_upload_images.length;
    setBulkUploadIndex(previousIndex);
  };

  if (!bulkUploadImage) return <></>;

  return (
    <Dialog
      open
      className={styles.dialog}
      onClose={onDialogClose}
      classes={{ paper: styles.paperRoot }}
    >
      <div className={styles.header}>
        <Typography variant="H-TEXT-2">{headerTile}</Typography>
      </div>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          {size(bulkUploadGroup.bulk_upload_images) !== 1 && (
            <IconButton
              onClick={handlePreviousIconClick}
              className={styles.leftImageArrow}
            >
              <SvgIcon component={ChevronLeftIcon} />
            </IconButton>
          )}
          <Image
            className={styles.image}
            src={bulkUploadImage.file_url}
            alt="bulk upload image"
          />
          {isExcludeConfirmDialogOpened && (
            <ExcludeImageConfirmationDialog
              onCancel={closeExcludeConfirmDialog}
              onConfirm={handleImageExclude}
            />
          )}
          {size(bulkUploadGroup.bulk_upload_images) !== 1 && (
            <IconButton
              onClick={handleNextIconClick}
              className={styles.rightImageArrow}
            >
              <SvgIcon component={ChevronRightIcon} />
            </IconButton>
          )}
        </div>
        <div className={styles.studentSearch}>
          <div className={styles.pageSelectContainer}>
            <div className={styles.pageSelect}>
              <Typography variant="S-TEXT-1">Page</Typography>
              <SelectFilter
                disabled={allGroupsMatched && !isAlreadyMatched}
                greyDarkOnFocus
                options={tasks?.map(({ name, id }) => ({
                  label: name,
                  value: id,
                  key: id
                }))}
                value={selectedTaskId}
                onChange={(e) => {
                  setSelectedTaskId(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.findStudentContainer}>
            <div className={styles.findStudent}>
              <Typography variant="S-TEXT-1">Find student to match</Typography>
              <SearchField
                disabled={allGroupsMatched && !isAlreadyMatched}
                value={selectedStudent}
                options={students || []}
                getOptionLabel={(student) => student.name}
                onChange={(event, value) => {
                  if (includes(students, value) || isNull(value)) {
                    setSelectedStudent(value);
                  }
                }}
                placeholder="Search Students"
              />
            </div>
          </div>
          {!isEmpty(unmatchedStudents) && (
            <div className={styles.selectStudent}>
              <Typography variant="S-TEXT-1" className={styles.unmatchedText}>
                Select unmatched student
              </Typography>
              <div className={styles.studentsList}>
                {unmatchedStudents.map((student) => (
                  <div
                    key={student.id}
                    className={styles.studentItem}
                    onClick={() =>
                      handleImageUpdate({
                        selectedStudent: student
                      })
                    }
                  >
                    <Typography
                      variant="B-Text-2"
                      className={styles.studentName}
                    >
                      {student.name}
                    </Typography>
                    <ChevronRightRoundedIcon color="disabled" />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className={styles.buttonContainer}>
            <Button
              color="pink"
              fullWidth
              onClick={() => {
                handleImageUpdate({ selectedStudent });
                trackImageSave();
              }}
              disabled={allGroupsMatched && !isAlreadyMatched}
            >
              Save
            </Button>
            <Button
              className={styles.excludeButton}
              color="lightGrey"
              onClick={openExcludeConfirmDialog}
              disabled={allGroupsMatched && !isAlreadyMatched}
            >
              Exclude image
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default BulkUploadImageUpdate;
