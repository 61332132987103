import React from 'react';

import _ from 'lodash';

import { colors } from 'theme/palette';

import TeacherWorkCard from 'components/TeacherWorkCard';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const GridView = ({ monthsAssignments, student }) => (
  <>
    {Object.keys(monthsAssignments).map((key) => (
      <div key={key}>
        <div className={styles.monthName}>
          <Typography color={colors.grey3} variant="S-TEXT-1">
            {key}
          </Typography>
        </div>
        <div className={styles.monthCards}>
          {monthsAssignments[key].map((assignment) => {
            // This is legacy logic - we count on the BE order of the tasks
            const assignmentWithIndexedTasks = {
              ...assignment,
              tasks: _.map(assignment.tasks, (task, index) => ({
                ...task,
                index: index
              }))
            };

            return (
              <TeacherWorkCard
                key={assignment.id}
                student={student}
                assignment={assignmentWithIndexedTasks}
              />
            );
          })}
        </div>
      </div>
    ))}
  </>
);

export default GridView;
