import React from 'react';

import { bytesToMegaBytes } from 'utils';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const FileLine = ({ file }) => (
  <div className={styles.container}>
    <div className={styles.spinner} />
    <Typography variant="S-TEXT-2" className={styles.name}>
      {file.name}
    </Typography>
    <Typography variant="S-TEXT-2" className={styles.size}>
      {bytesToMegaBytes(file.size).toFixed(2)} MB
    </Typography>
  </div>
);

export default FileLine;
