import React, { useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DescriptionIcon from '@material-ui/icons/Description';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { trackerInstanceSectionToggleCompleteNextStep } from 'sdk';

import { notifyError, notifySuccess } from 'utils/notifications';

import Typography from 'components/Typography';

import styles from './styles.module.scss';
import { formatExemplarStudentWorks } from './utils';

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: '#f5f5f9'
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: '15px'
  }
}))(Tooltip);

const StudentsToSupportData = ({
  students,
  studentDisplayText,
  studentPercentage,
  nextStepId,
  nextStepIsComplete,
  nextStepDisplayText,
  trackNextStepCompleteEvent,
  nextStepIx,
  mutateNextStepsRows
}) => {
  const numberOfStudents = students.length;
  const percentOfStudents = Math.round(studentPercentage * 100);
  const [checkboxIsChecked, setCheckboxIsChecked] =
    useState(nextStepIsComplete);
  const [checkboxIsDisabled, setCheckboxIsDisabled] = useState(false);

  const getTooltipText = (students) => {
    return (
      <React.Fragment>
        <List dense={true}>
          {students.map((student, index) => {
            return (
              <ListItem
                key={`student-list-${nextStepId}-${nextStepIx}-${index}`}
              >
                {student?.name}
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>
    );
  };

  const onClick = async () => {
    setCheckboxIsDisabled(true);
    const { success } = await trackerInstanceSectionToggleCompleteNextStep({
      trackerInstanceSectionToNextStepId: nextStepId
    });

    setCheckboxIsDisabled(false);
    let prevValue = checkboxIsChecked;
    setCheckboxIsChecked((prevValue = !prevValue));

    if (success) {
      trackNextStepCompleteEvent({ completedNextStep: nextStepDisplayText });
      // Triggering a revalidation and refresh for the Next Steps table
      mutateNextStepsRows();
      notifySuccess('Successfully updated Next step.');
    } else {
      notifyError('Something went wrong. Please try again.');
    }
  };

  return (
    <Grid container spacing={1} alignItems="baseline">
      <Grid item xs={2} lg={1}>
        <Checkbox
          className={styles.checkbox}
          disabled={checkboxIsDisabled}
          onClick={onClick}
          checked={checkboxIsChecked}
        />
      </Grid>
      <Grid item xs={8} lg={11}>
        <HtmlTooltip
          title={getTooltipText(students)}
          placement="bottom-end"
          arrow={false}
        >
          <Link className={styles.hoverLink}>
            <b>{`${numberOfStudents} student(s) (${percentOfStudents}%) `}</b>
          </Link>
        </HtmlTooltip>
        <span>{studentDisplayText}</span>
      </Grid>
    </Grid>
  );
};

const NextStepsData = ({
  nextStepType,
  nextStepsDisplayText,
  nextStepShortText,
  nextStepId,
  nextStepIx
}) => {
  const getTooltipText = (nextStepsDisplayText) => {
    const nextStepsArray = nextStepsDisplayText.split('. ');
    return (
      <React.Fragment>
        <List dense={true}>
          {nextStepsArray.map((next_step, index) => {
            return (
              <ListItem key={`next-steps-${nextStepId}-${nextStepIx}-${index}`}>
                {next_step}
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>
    );
  };

  const getIcon = (nextStepType) => {
    let icon = null;
    switch (nextStepType) {
      case 'Celebrate':
        icon = <FavoriteIcon />;
        break;
      case 'Intervene':
        icon = <ErrorOutlineIcon />;
        break;
      case 'Support':
        icon = <EmojiObjectsIcon />;
        break;
      case 'Computation Error':
        icon = <AssessmentIcon />;
        break;
      case 'Conceptual Misunderstanding':
        icon = <DescriptionIcon />;
        break;
      case 'Does Not Answer Specific Question':
        icon = <QuestionAnswerIcon />;
        break;
      default:
        icon = <AssignmentTurnedInIcon />;
        break;
    }
    return icon;
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3} lg={1}>
        {getIcon(nextStepType)}
      </Grid>
      <Grid item xs={8} lg={11}>
        <HtmlTooltip
          title={getTooltipText(nextStepsDisplayText)}
          placement="bottom-start"
          arrow={false}
        >
          <Link className={styles.hoverLink}>
            <b>{nextStepShortText}</b>
          </Link>
        </HtmlTooltip>
      </Grid>
    </Grid>
  );
};

export const NextStepsTableRow = ({
  row,
  setSelectedWork,
  setStudentWorks,
  setIsWorkDialogOpened,
  trackNextStepCompleteEvent,
  mutateNextStepsRows
}) => {
  const { assignment_title, next_steps, section_name, exemplar_urls } = row;
  const studentWorks = formatExemplarStudentWorks({
    exemplarUrls: exemplar_urls
  });
  let totalRows = 0;
  for (let i = 0; i < next_steps.length; i++) {
    if (next_steps[i].students.length > 0) {
      totalRows += 1;
    }
  }

  return (
    <>
      {next_steps.map((next_step, index) => {
        const isFirstRow = index === 0;
        const isLastRow = index === totalRows - 1;
        if (next_step.students.length > 0) {
          return (
            <TableRow key={`${next_step.id}-${index}`}>
              {isFirstRow && (
                <TableCell
                  className={styles.tableCell}
                  component="th"
                  scope="row"
                  rowSpan={totalRows}
                >
                  <Typography
                    variant="H-TEXT-4"
                    className={styles.assignmentTitleText}
                  >
                    <b>{assignment_title}</b>
                  </Typography>
                  <Typography className={styles.assignmentTitleText}>
                    {section_name}
                  </Typography>
                </TableCell>
              )}
              <TableCell
                className={
                  isLastRow ? styles.tableCell : styles.noBorderTableCell
                }
              >
                <StudentsToSupportData
                  students={next_step.students}
                  studentDisplayText={next_step.student_display_text}
                  studentPercentage={next_step.student_percentage}
                  nextStepId={next_step.id}
                  nextStepIsComplete={next_step.completed}
                  nextStepDisplayText={next_step.next_step_display_text}
                  trackNextStepCompleteEvent={trackNextStepCompleteEvent}
                  nextStepIx={index}
                  mutateNextStepsRows={mutateNextStepsRows}
                />
              </TableCell>

              <TableCell
                className={
                  isLastRow ? styles.tableCell : styles.noBorderTableCell
                }
              >
                <NextStepsData
                  nextStepType={next_step.next_step_type}
                  nextStepsDisplayText={next_step.next_step_display_text}
                  nextStepShortText={next_step.next_step_short_text}
                  nextStepId={next_step.id}
                  nextStepIx={index}
                />
              </TableCell>
              <TableCell
                className={
                  isLastRow ? styles.tableCell : styles.noBorderTableCell
                }
              >
                {isFirstRow && exemplar_urls.length > 0 && (
                  <Link
                    className={styles.hoverLink}
                    onClick={() => {
                      setStudentWorks(studentWorks);
                      setSelectedWork(studentWorks[0]);
                      setIsWorkDialogOpened(true);
                    }}
                  >
                    <b>Exemplar Work</b>
                  </Link>
                )}
              </TableCell>
            </TableRow>
          );
        } else {
          return '';
        }
      })}
    </>
  );
};
