import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { isEmpty } from 'lodash';
import Markdown from 'markdown-to-jsx';

import Typography from 'components/Typography';

import { ReactComponent as QueryIcon } from './assets/replyIcon.svg';
import styles from './styles.module.scss';

const PathwaysChatBotReply = ({
  message,
  showActions,
  handleLike,
  handleDislike
}) => {
  let rootStyles = `${styles.actionsIcon} ${styles.messageNeutral}`;
  let likeStyles = rootStyles;
  let dislikeStyles = rootStyles;

  if (message?.reaction === 'like') {
    likeStyles = `${styles.actionsIcon} ${styles.messageLiked}`;
  }
  if (message?.reaction === 'dislike') {
    dislikeStyles = `${styles.actionsIcon} ${styles.messageDisliked}`;
  }

  return (
    <div className={styles.messageContainer}>
      <div className={styles.replyContainer}>
        <div className={styles.icon}>
          <QueryIcon />
        </div>
        {isEmpty(message.text) && <CircularProgress size={18} />}
        {!isEmpty(message.text) && (
          <Typography
            variant="B-Text-3"
            className={styles.replyHelperText}
            component="div"
          >
            <Markdown>{message.text}</Markdown>
          </Typography>
        )}
        {showActions && (
          <div className={styles.replyActionsContainer}>
            <div className={styles.likeActionsContainer}>
              <IconButton
                aria-label="yes"
                classes={{ root: likeStyles }}
                onClick={() => handleLike(message.id)}
              >
                <ThumbUpIcon />
              </IconButton>
              <IconButton
                aria-label="no"
                classes={{ root: dislikeStyles }}
                onClick={() => handleDislike(message.id)}
              >
                <ThumbDownIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PathwaysChatBotReply;
