import hundredIcon from './100.svg';
import addMoreHereArrowBlueIcon from './add_more_here_arrow_blue.svg';
import addMoreHereArrowPinkIcon from './add_more_here_arrow_pink.svg';
import addMoreHereBubbleIcon from './add_more_here_bubble.svg';
import excellentIcon from './excellent.svg';
import explainMoreIcon from './explain_more.svg';
import faceConfusedYellowIcon from './face_confused_yellow.svg';
import faceHeartYellowIcon from './face_heart_yellow.svg';
import faceShadesYellowIcon from './face_shades_yellow.svg';
import faceSmileYellowIcon from './face_smile_yellow.svg';
import greatJobIcon from './great_job.svg';
import greatJobHighFiveIcon from './great_job_high_five.svg';
import hardToReadIcon from './hard_to_read.svg';
import highFiveIcon from './high_five.svg';
import keepGoingIcon from './keep_going.svg';
import labelIcon from './label.svg';
import loveThisIcon from './love_this.svg';
import loveThisBubbleIcon from './love_this_bubble.svg';
import needToSeeYourWorkIcon from './need_to_see_your_work.svg';
import niceIcon from './nice.svg';
import punctuationIcon from './punctuation.svg';
import questionMarkIcon from './question_mark.svg';
import reReadTaskIcon from './reread_task.svg';
import showMoreWorkIcon from './show_more_work.svg';
import takePictureOfWork from './take_picture_of_work.svg';
import thisIsSoMuchBetterIcon from './this_is_so_much_better.svg';
import tryAgainIcon from './try_again.svg';
import wowIcon from './wow.svg';
import youCanDoItIcon from './you_can_do_it.svg';
import youMissedAStepIcon from './you_missed_a_step.svg';

export const Emojis = [
  loveThisIcon,
  wowIcon,
  excellentIcon,
  greatJobHighFiveIcon,
  greatJobIcon,
  highFiveIcon,
  loveThisBubbleIcon,
  niceIcon,
  hundredIcon,
  faceSmileYellowIcon,
  faceShadesYellowIcon,
  faceHeartYellowIcon,
  faceConfusedYellowIcon,
  hardToReadIcon,
  takePictureOfWork,
  needToSeeYourWorkIcon,
  youCanDoItIcon,
  addMoreHereArrowBlueIcon,
  addMoreHereArrowPinkIcon,
  addMoreHereBubbleIcon,
  explainMoreIcon,
  keepGoingIcon,
  labelIcon,
  punctuationIcon,
  questionMarkIcon,
  reReadTaskIcon,
  showMoreWorkIcon,
  thisIsSoMuchBetterIcon,
  tryAgainIcon,
  youMissedAStepIcon
];
