import React from 'react';

import { colors } from 'theme/palette';

import ConfirmDialog from 'components/ConfirmDialog';
import Typography from 'components/Typography';

const AddTeacherConfirmDialog = ({ onClose, onConfirm }) => (
  <ConfirmDialog onClose={onClose} onConfirm={onConfirm}>
    <div>
      <Typography variant="B-Text-1" color={colors.grey1} align="center">
        You are currently an administrator, not a teacher, on these classes.
        Would you like to add yourself as a teacher?
      </Typography>
    </div>
  </ConfirmDialog>
);

export default AddTeacherConfirmDialog;
