export const MixpanelObject = {
  BUTTON: 'Button',
  ACCORDION: 'Accordion',
  PAGE: 'Page',
  FORM: 'Form'
};

export const MixpanelAction = {
  CLICK: 'Click',
  HOVER: 'Hover',
  SUBMIT: 'Submit',
  VIEW: 'View',
  ON_CHANGE: 'On Change'
};
