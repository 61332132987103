import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';

import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import { TEACHERS_INSIGHTS_V2_OVERVIEW_URL } from 'config/urls';
import _ from 'lodash';
import {
  useAssignmentsNamesList,
  useTrackerInstanceSections,
  useUserSections,
  useUsersInUserSections,
  useYears
} from 'sdk';

import Filters from 'pages/Teachers/Assignments/List/components/OverviewTable/components/Filters/';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { parseParams, stringifyParams } from 'utils/urls';
import { reverse } from 'utils/urls';

import Assignment from './components/Assignment';
import { NextStepsPanel } from './components/NextStepsPanel';
import AssignmentDialogContext from 'components/AssignmentDialogContext';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const DEFAULT_LIMIT = 10;

const SearchableAssignments = () => {
  const { user } = useContext(UserContext);
  const assignmentDialogContext = useContext(AssignmentDialogContext);

  const history = useHistory();

  const [filtersHaveBeenInitialized, setFiltersHaveBeenInitialized] =
    useState(false);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    numPages: 1,
    currentPage: 1,
    limit: DEFAULT_LIMIT
  });

  const author = _.get(filters, 'author');

  const trackersOverviewFilters = useMemo(() => {
    let offset = undefined;

    if (!_.isNil(pagination.limit)) {
      offset = pagination.limit * (pagination.currentPage - 1);
    }

    return _.omit({ ...filters, offset }, _.isNil);
  }, [filters, pagination]);

  const { data: sectionUsers, isLoading: isLoadingSectionUsers } =
    useUsersInUserSections();
  const { data: years, isLoading: isLoadingYears } = useYears();
  const { data: userSections, isLoading: isLoadingUserSections } =
    useUserSections({
      params: author ? { co_user: author } : {}
    });
  const { data: userAssignments, isLoading: isLoadingUserAssignments } =
    useAssignmentsNamesList({
      data: author ? { co_user: author } : {}
    });

  const {
    data: trackersOverviewData,
    isLoading: isLoadingTrackersOverview,
    refetch: refetchTrackersOverview
  } = useTrackerInstanceSections({
    enabled: filtersHaveBeenInitialized,
    params: trackersOverviewFilters
  });

  const refetchTrackerOverviewCallback = useCallback(
    () => refetchTrackersOverview(),
    [refetchTrackersOverview]
  );

  const trackNextStepCompleteEvent = ({ completedNextStep }) => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'insights',
        view: 'next step detail panel',
        button: 'complete',
        next_step: completedNextStep
      }
    );
  };

  useEffect(() => {
    if (assignmentDialogContext.isAssignmentDialogOpened) {
      assignmentDialogContext.setAssignAssignmentSuccessAction(
        () => refetchTrackerOverviewCallback
      );
    }
  }, [assignmentDialogContext, refetchTrackerOverviewCallback]);

  const loading = _.some([
    isLoadingSectionUsers,
    isLoadingYears,
    isLoadingUserSections,
    isLoadingUserAssignments,
    isLoadingTrackersOverview
  ]);

  const count = _.get(trackersOverviewData, 'count');
  const limit = _.get(trackersOverviewData, 'limit', DEFAULT_LIMIT);
  const trackerInstanceSectionData = _.get(trackersOverviewData, 'results');

  useEffect(() => {
    let numPages = 1;

    if (!_.isNil(count)) {
      numPages = _.ceil(count / limit);
    }

    setPagination((prevPagination) => ({
      ...prevPagination,
      numPages,
      limit
    }));
  }, [count, limit]);

  useEffect(() => {
    if (isLoadingYears || filtersHaveBeenInitialized) {
      return;
    }

    const filters = parseParams(history.location.search, {
      parseNumbers: true
    });
    let author = _.get(filters, 'author');

    // In case when author is completely missing or is not fully provided (e.g. `?author=`)
    if (!_.isInteger(author)) {
      const hasNonAuthorFilter = _.chain(filters)
        .omit('author')
        .values()
        .some((filterValue) => !_.isEmpty(filterValue))
        .value();

      // If any of assignments or sections filters are not empty => set author to null
      if (hasNonAuthorFilter) {
        author = null;
      }
    }

    const name = _.get(filters, 'name');
    const section = _.get(filters, 'section');

    const currentYear = _.last(_.sortBy(years, 'is_current'));
    const year = _.get(filters, 'year', _.get(currentYear, 'id'));

    setFiltersHaveBeenInitialized(true);
    setFilters((prevFilters) => ({
      ...prevFilters,
      author,
      name,
      section,
      year
    }));
  }, [
    user.id,
    years,
    isLoadingYears,
    filtersHaveBeenInitialized,
    history.location.search
  ]);

  const sectionUsersForSearch = useMemo(() => {
    // Explicitly put `this.props.user` on top of the `sectionUsers` filter list
    const currentSectionUser = _.find(sectionUsers, { id: user.id });

    const sectionUsersWithoutCurrentUser = _.filter(
      sectionUsers,
      (u) => u.id !== user.id
    );

    return [currentSectionUser, ...sectionUsersWithoutCurrentUser];
  }, [sectionUsers, user.id]);

  const filterByAuthor = (e, user) =>
    setFilters((prevFilters) => ({
      ...prevFilters,
      author: _.get(user, 'id')
    }));

  const filterByYear = (year) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      year
    }));
  };
  const filterBySectionName = (e, section) =>
    setFilters((prevFilters) => ({
      ...prevFilters,
      section
    }));

  const filterByAssignmentName = (e, name) =>
    setFilters((prevFilters) => ({
      ...prevFilters,
      name
    }));

  const changePageAndRefetchListData = (page) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      currentPage: page
    }));
  };

  useEffect(() => {
    // Reset the pagination every time the filters change.
    changePageAndRefetchListData(1);
  }, [filters]);

  useEffect(() => {
    if (!loading) {
      history.replace({
        search: stringifyParams(filters)
      });
    }
  }, [filters, loading, history]);

  const selectedUser = _.find(sectionUsers, { id: filters.author }) || null;
  const { numPages, currentPage } = pagination;

  const SHOW_NEXT_STEPS_PANEL =
    process.env.REACT_APP_SHOW_NEXT_STEPS_PANEL === 'true';

  return (
    <>
      <div className={styles.nextStepsPanel}>
        {SHOW_NEXT_STEPS_PANEL &&
          filtersHaveBeenInitialized &&
          userSections && (
            <NextStepsPanel
              userSections={userSections}
              filters={filters}
              trackNextStepCompleteEvent={trackNextStepCompleteEvent}
            />
          )}
      </div>
      <div className={styles.filters}>
        <Filters
          user={user}
          filters={filters}
          selectedUser={selectedUser}
          authorOptions={sectionUsersForSearch}
          filterByAuthor={filterByAuthor}
          assignmentsOptions={userAssignments}
          filterByAssignmentName={filterByAssignmentName}
          sectionsOptions={userSections}
          filterBySectionName={filterBySectionName}
          yearsOptions={years}
          filterByYear={filterByYear}
        />
      </div>
      <div className={styles.header}>
        <div>Assignment name</div>
        <div>Section</div>
        <div>Due date</div>
        <div>Submissions</div>
        <div></div>
      </div>
      <div>
        {!loading &&
          trackerInstanceSectionData?.map((trackerInstanceSection) => (
            <Assignment
              key={trackerInstanceSection.id}
              name={trackerInstanceSection.tracker_name}
              sectionName={trackerInstanceSection.section_name}
              submittedWorks={trackerInstanceSection.submitted}
              dueAt={trackerInstanceSection.due_at}
              studentsCount={trackerInstanceSection.students_count}
              standards={trackerInstanceSection.standards}
              onClick={() => {
                history.push({
                  pathname: reverse(TEACHERS_INSIGHTS_V2_OVERVIEW_URL, {
                    trackerInstanceSectionId: trackerInstanceSection.id
                  })
                });
              }}
            />
          ))}
        {loading && (
          <div className={styles.loadingState}>
            <Skeleton variant="text" width={'100%'} height={200} />
            <Skeleton variant="text" width={'100%'} height={200} />
            <Skeleton variant="text" width={'100%'} height={200} />
          </div>
        )}
      </div>
      <div className={styles.pagination}>
        <Pagination
          count={numPages}
          page={currentPage}
          color="secondary"
          onChange={(event, page) => changePageAndRefetchListData(page)}
        />
      </div>
    </>
  );
};

export default SearchableAssignments;
