import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as RightArrow } from 'images/arrow-right.svg';

import { StudentsProgressBar } from 'pages/Teachers/Assignments/List/components/OverviewTable/components/AssignmentRow';
import Standard from 'pages/Teachers/SearchableAssignments/components/Standard';
import { colors } from 'theme/palette';
import { formatDate } from 'utils/moment';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Assignment = ({
  name,
  sectionName,
  submittedWorks,
  dueAt,
  studentsCount,
  standards,
  onClick
}) => {
  const dateFormat = 'ddd MM/DD hh:mm a';
  return (
    <div className={styles.container} onClick={onClick}>
      <div>
        <Typography variant="H-TEXT-3" color={colors.blueDark}>
          {name}
        </Typography>
        <div className={styles.standardsList}>
          {standards.map((standard, index) => (
            <div key={index}>
              <Standard label={standard} />
            </div>
          ))}
        </div>
      </div>

      <Typography variant="S-TEXT-1" color={colors.blue2}>
        {sectionName}
      </Typography>

      <Typography variant="B-Text-3" color={colors.grey2}>
        {formatDate(dueAt, dateFormat)}
      </Typography>

      <div className={styles.progress}>
        <StudentsProgressBar
          lgScreen={true}
          colors={colors}
          numStudents={submittedWorks}
          totalStudents={studentsCount}
          barColor={colors.blueLight}
        />
      </div>

      <SvgIcon component={RightArrow} />
    </div>
  );
};

export default Assignment;
