import React, { useState } from 'react';

import cx from 'classnames';

import { colors } from 'theme/palette';

import styles from './styles.module.scss';

const StripeField = ({ component, options = {} }) => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  const StripeComponent = component;

  // Reference: https://stripe.com/docs/js/appendix/style?type=card
  const defaultOptions = {
    style: {
      base: {
        fontSize: '14px',
        fontWeight: '400',
        letterSpacing: '0.5px',
        fontFamily: 'Nunito',
        '::placeholder': {
          color: colors.grey4
        }
      }
    }
  };

  return (
    <div
      className={cx(styles.cardElement, {
        [styles.active]: isFocused
      })}
    >
      <StripeComponent
        options={{ ...defaultOptions, ...options }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

export default StripeField;
