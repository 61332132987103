import React from 'react';

import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import FeatureToggle from 'components/FeatureToggle';

import styles from './styles.module.scss';

const TeamCardSkeleton = () => {
  return (
    <Paper variant="outlined" className={styles.teamCardSkeleton}>
      <div className={styles.header}>
        <Skeleton variant="text" animation="wave" width={250} />
        <FeatureToggle dev>
          <Skeleton variant="circle" animation="wave" height={20} width={20} />
        </FeatureToggle>
      </div>
      <Divider />
      <div className={styles.content}>
        <div>
          <Skeleton variant="text" animation="wave" width={150} />
          <Skeleton variant="text" animation="wave" width={200} />
        </div>
        <Skeleton variant="text" animation="wave" width={180} />
      </div>
    </Paper>
  );
};

const TeamsListSkeleton = ({ cardsCount = 5 }) => (
  <div className={styles.cardsContainer}>
    {_.times(cardsCount, (cardIdx) => (
      <TeamCardSkeleton key={cardIdx} />
    ))}
  </div>
);

export default TeamsListSkeleton;
