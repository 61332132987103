import React, { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { filter, get, isEmpty, map } from 'lodash';
import { useOrganizationsSchools, useYears } from 'sdk';

import GradeSelectDropdown from 'pages/Teachers/StrategiesOverview/components/GradeSelectDropdown';
import InfoFeaturesDialog from 'pages/Teachers/StrategiesOverview/components/InfoFeaturesDialog';
import LessonSelectDropdown from 'pages/Teachers/StrategiesOverview/components/LessonSelectDropdown';
import MultiPieChartContainer from 'pages/Teachers/StrategiesOverview/components/ListPieChart';
import OrganizationSelectDialog from 'pages/Teachers/StrategiesOverview/components/OrganizationSelectDialog';
import SectionSelectDropdown from 'pages/Teachers/StrategiesOverview/components/SectionSelectDropdown';
import UnitSelectDropdown from 'pages/Teachers/StrategiesOverview/components/UnitSelectDropdown';
import { useNavbar } from 'pages/Teachers/shared';

import SelectFilter from 'components/SelectFilter';

import styles from './styles.module.scss';

const StrategiesOverview = ({
  selectedSchool,
  setSelectedSchool,
  selectedOrganization,
  setSelectedOrganization
}) => {
  const { data: years, isLoading: isLoadingYears } = useYears();

  const [selectedYear, setSelectedYear] = useState(null);
  const { navigation } = useNavbar({ title: '' });

  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [infoDialogOpen, setInfoDialogOpen] = useState(
    !localStorage.getItem('infoFeaturesDialogShown')
  );

  const { data: organizations } = useOrganizationsSchools();

  const yearsOptions = useMemo(() => {
    const yearsWithInsightsData = filter(years, (year) => year.id > 2023);

    return map(yearsWithInsightsData, (year) => {
      const startYear = new Date(year.naive_start_date).getFullYear();
      const endYear = new Date(year.naive_end_date).getFullYear();
      const label = `${startYear} - ${endYear}`;

      return {
        label,
        value: year.id
      };
    });
  }, [years]);

  useEffect(() => {
    if (!isEmpty(yearsOptions) && !selectedYear) {
      setSelectedYear(yearsOptions[0]);
    }
  }, [yearsOptions, selectedYear, setSelectedYear]);

  useEffect(() => {
    navigation.setLeftSubheader(
      <>
        {selectedSchool && selectedYear && (
          <div className={cx(styles.dropdown, styles.separatior)}>
            <SectionSelectDropdown
              selectedSection={selectedSection}
              selectedSchool={selectedSchool}
              setSelectedSection={setSelectedSection}
              setSelectedGrade={setSelectedGrade}
              setSelectedUnit={setSelectedUnit}
              setSelectedLesson={setSelectedLesson}
              selectedYear={selectedYear}
            />
          </div>
        )}
        {selectedSection && (
          <div className={styles.dropdown}>
            <GradeSelectDropdown
              selectedGrade={selectedGrade}
              selectedSection={selectedSection}
              setSelectedGrade={setSelectedGrade}
              setSelectedUnit={setSelectedUnit}
              setSelectedLesson={setSelectedLesson}
            />
          </div>
        )}
      </>
    );
  }, [
    navigation,
    selectedSection,
    selectedGrade,
    selectedSchool,
    selectedYear
  ]);

  useEffect(() => {
    if (selectedSection && selectedGrade) {
      navigation.setRightSubheader(
        <>
          <div className={cx(styles.dropdown, styles.separatior)}>
            <UnitSelectDropdown
              selectedUnit={selectedUnit}
              selectedSection={selectedSection}
              selectedGrade={selectedGrade}
              setSelectedUnit={setSelectedUnit}
              setSelectedLesson={setSelectedLesson}
            />
          </div>
          {selectedUnit && (
            <div className={styles.dropdown}>
              <LessonSelectDropdown
                selectedLesson={selectedLesson}
                selectedSection={selectedSection}
                selectedGrade={selectedGrade}
                selectedUnit={selectedUnit}
                setSelectedLesson={setSelectedLesson}
              />
            </div>
          )}
        </>
      );
    } else {
      navigation.setRightSubheader(<></>);
    }
  }, [
    navigation,
    selectedSection,
    selectedUnit,
    selectedLesson,
    selectedGrade
  ]);

  const allFiltersSelected = useMemo(
    () => selectedSection && selectedGrade && selectedUnit && selectedLesson,
    [selectedSection, selectedGrade, selectedUnit, selectedLesson]
  );

  const infoDialogOnClose = () => {
    localStorage.setItem('infoFeaturesDialogShown', 'true');

    setInfoDialogOpen(false);
  };

  const handleYearChange = (event) => {
    const newYearValue = event.target.value;

    setSelectedYear(yearsOptions.find((item) => item.value === newYearValue));
    setSelectedSection(null);
    setSelectedLesson(null);
    setSelectedUnit(null);
    setSelectedGrade(null);
  };

  return (
    <>
      {!isEmpty(organizations) &&
        !infoDialogOpen &&
        (!selectedSchool || !selectedOrganization) && (
          <OrganizationSelectDialog
            organizations={organizations}
            setSelectedSchool={(school) => {
              setSelectedSchool(school);
              setSelectedSection(null);
              setSelectedLesson(null);
              setSelectedUnit(null);
              setSelectedGrade(null);
            }}
            selectedOrganization={selectedOrganization}
            setSelectedOrganization={setSelectedOrganization}
          />
        )}
      {!isLoadingYears && selectedSchool && (
        <div className={styles.yearSelect}>
          <SelectFilter
            value={get(selectedYear, 'value', '')}
            options={yearsOptions}
            onChange={handleYearChange}
            placeholder="Years"
          />
        </div>
      )}
      <div className={styles.root}>
        {allFiltersSelected && (
          <MultiPieChartContainer
            selectedSection={selectedSection}
            selectedGrade={selectedGrade}
            selectedUnit={selectedUnit}
            selectedLesson={selectedLesson}
          />
        )}
      </div>

      {infoDialogOpen && <InfoFeaturesDialog onClose={infoDialogOnClose} />}
    </>
  );
};

export default StrategiesOverview;
