import { BASE_URL } from 'config/urls';

import { callUrl, get, post } from 'utils/sdk';

export const getTeamDetail = (teamId) =>
  callUrl(get, `${BASE_URL}/v1/meetings/teams/${teamId}/`);

export const getMeetingSharesList = ({ meetingId, ...requestData }) =>
  callUrl(get, `${BASE_URL}/v1/meetings/${meetingId}/shares/`, requestData);

export const getMeetingInfo = (meetingId) =>
  callUrl(get, `${BASE_URL}/v1/meetings/${meetingId}/`);

export const postMeetingShareComment = ({ meetingShareId, parent, text }) =>
  callUrl(
    post,
    `${BASE_URL}/v1/meetings/share/${meetingShareId}/add-comment/`,
    { parent, text }
  );

export const postMeetingComment = ({ meetingId, parent, text }) =>
  callUrl(post, `${BASE_URL}/v1/meetings/${meetingId}/add-comment/`, {
    text,
    parent
  });

export const updateMeetingTopic = ({ meetingId, newTopic }) =>
  callUrl(post, `${BASE_URL}/v1/meetings/${meetingId}/update-topic/`, {
    topic: newTopic
  });
