import { useEffect } from 'react';
import { isAndroid, isIOS, isMacOs, isSafari } from 'react-device-detect';

import {
  TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL,
  TEACHER_STRATEGIES_OVERVIEW_URL
} from 'config/urls';

const MobileApp = ({ history }) => {
  useEffect(() => {
    if (isAndroid) {
      window.location.replace(
        'https://play.google.com/store/apps/details?id=app.edlight'
      );
    } else if (isIOS || isSafari || isMacOs) {
      window.location.replace(
        'https://apps.apple.com/bg/app/edlight/id1570328651'
      );
    } else {
      window.location.replace(
        process.env.REACT_APP_SHOW_INSIGHTS_V2 === 'true'
          ? TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL
          : TEACHER_STRATEGIES_OVERVIEW_URL
      );
    }
  }, []);

  return null;
};

export default MobileApp;
