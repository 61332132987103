import { BASE_URL } from 'config/urls';

import { callUrl, get, post, requestSdk } from 'utils/sdk';

export const fetchStudentPortfolio = ({ studentId, filters, offset, limit }) =>
  callUrl(get, `${BASE_URL}/v1/trackers/student-portfolio/${studentId}/`, {
    ...filters,
    offset,
    limit
  });

export const updateStudentPreferredName = (studentId, data) =>
  requestSdk(() =>
    post(`${BASE_URL}/v1/students/${studentId}/update-preferred-name/`, data)
  );
