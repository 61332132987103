import React from 'react';

import Carousel from 'components/Carousel';
import Dialog from 'components/Dialog';
import Image from 'components/Image';

import FirstPageImage from './assets/firstpage.svg';
import FourthPageImage from './assets/fourthpage.svg';
import SecondPageImage from './assets/secondpage.svg';
import ThirdPageImage from './assets/thirdpage.svg';
import styles from './styles.module.scss';

const InfoFeaturesDialog = ({ onClose }) => {
  return (
    <Dialog open classes={{ paper: styles.paperContainer }} onClose={onClose}>
      <div className={styles.dialogContent}>
        <Carousel
          slides={[
            <Image
              src={FirstPageImage}
              alt="page-1"
              key="page-1"
              className={styles.dialogImage}
            />,
            <Image
              src={SecondPageImage}
              alt="page-2"
              key="page-2"
              className={styles.dialogImage}
            />,
            <Image
              src={ThirdPageImage}
              alt="page-3"
              key="page-3"
              className={styles.dialogImage}
            />,
            <Image
              src={FourthPageImage}
              alt="page-4"
              key="page-4"
              className={styles.dialogImage}
            />
          ]}
        ></Carousel>
      </div>
    </Dialog>
  );
};

export default InfoFeaturesDialog;
