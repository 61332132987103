import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import { colors } from 'theme/palette';

import StudentWorkCard from 'components/StudentWorkCard';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Placeholder = () => (
  <div>
    <div className={styles.monthName}>
      <Typography color={colors.grey3} variant="S-TEXT-1">
        <Skeleton width={100} />
      </Typography>
    </div>
    <div className={styles.monthCards}>
      <StudentWorkCard.Placeholder count={10} />
    </div>
  </div>
);

const GridView = ({ monthsAssignments }) =>
  Object.keys(monthsAssignments).map((key) => (
    <div key={key}>
      <div className={styles.monthName}>
        <Typography color={colors.grey3} variant="S-TEXT-1">
          {key}
        </Typography>
      </div>
      <div className={styles.monthCards}>
        {monthsAssignments[key].map((assignment) => {
          // This is legacy logic - we count on the BE order of the tasks
          const assignmentWithIndexedTasks = {
            ...assignment,
            tasks: _.map(assignment.tasks, (task, index) => ({
              ...task,
              index: index
            }))
          };

          return (
            <div key={assignment.id}>
              <StudentWorkCard assignment={assignmentWithIndexedTasks} />
            </div>
          );
        })}
      </div>
    </div>
  ));

GridView.Placeholder = Placeholder;

export default GridView;
