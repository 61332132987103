import { BASE_URL } from 'config/urls';

import { callUrl, get, post, requestSdk } from 'utils/sdk';

export const getUserOrganizations = () =>
  callUrl(get, `${BASE_URL}/v1/organizations/for-user/`);

export const getSchoolsForOrganization = ({ name, organizationsIds }) =>
  callUrl(get, `${BASE_URL}/v1/schools/`, {
    name,
    organizations_ids: organizationsIds
  });

export const getUsersForSchools = ({ name, selectedSchoolsIds }) =>
  callUrl(get, `${BASE_URL}/v1/schools/schools-school-users/`, {
    name,
    schools_ids: selectedSchoolsIds
  });

export const createTeacherTeam = (data) =>
  requestSdk(() => post(`${BASE_URL}/v1/meetings/teams/create/`, data));
