import { createTheme } from '@material-ui/core';

import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  palette, // here we can define our theming colors
  typography,
  overrides: overrides(palette), // here we can override the default styles of the Material components
  shape: { borderRadius: 5 }
});

export default theme;
