import React, { useCallback, useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';

import { colors } from 'theme/palette';

import AddButton from 'components/AddButton';
import Button from 'components/Button';
import InputField from 'components/InputField';

import styles from './styles.module.scss';

const initialValues = { attribution: '', url: '' };

const validationSchema = yup.object().shape({
  attribution: yup.string().required('Name of Source Required'),
  url: yup.string().required('Link to Source Required')
});

const AddExample = ({ onSubmit }) => {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => setOpen((prev) => !prev), []);

  const onFormSubmit = useCallback(
    (values, { resetForm }) => {
      onSubmit(values);
      resetForm();
      toggle();
    },
    [onSubmit, toggle]
  );

  if (!open) {
    return (
      <AddButton variant="small" color="blue" onClick={toggle}>
        Add Example
      </AddButton>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ isSubmitting, handleSubmit, submitForm, dirty, errors }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <Field
            component={InputField}
            variant="underlined"
            name="attribution"
            placeholder="Name of Source (e.g., “PARCC”)"
            className={styles.input}
            error={!_.isNil(errors.attribution)}
            helperText={errors.attribution}
          />
          <Field
            component={InputField}
            variant="underlined"
            name="url"
            placeholder="Link to Example"
            className={styles.input}
            error={!_.isNil(errors.url)}
            helperText={errors.url}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LinkRoundedIcon fontSize="small" htmlColor={colors.grey4} />
                </InputAdornment>
              )
            }}
          />
          <Button
            variant="small"
            color="blue"
            disabled={isSubmitting || !dirty || !_.isEmpty(errors)}
            onClick={submitForm}
          >
            Add
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default React.memo(AddExample);
