import { BASE_URL } from 'config/urls';

import { callUrl, get, post, requestSdk } from 'utils/sdk';

export const assignmentSectionsList = async (trackerId) =>
  await callUrl(() =>
    get(`${BASE_URL}/v1/trackers/assignments/${trackerId}/sections/`)
  );

export const addStudentComment = async (taskId, data) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/trackers/assignments/${taskId}/add-comment/`, data)
  );

export const updateTaskStatus = async ({ trackerScoreResponseId, data }) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/assignments/task/${trackerScoreResponseId}/update-status/`,
      data
    )
  );

export const bulkAddStudentComment = async (data) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/trackers/assignments/add-multiple-comments/`, data)
  );

export const bulkUpdateTasksStatuses = async (assignmentId, data) =>
  await requestSdk(() =>
    post(
      `${BASE_URL}/v1/trackers/assignments/${assignmentId}/tasks/status-bulk-update/`,
      data
    )
  );

export const bulkUpdateTasksScores = async (data) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/trackers/assignments/score-bulk-update/`, data)
  );

export const uploadAssignmentFeedback = (data) =>
  callUrl(
    post,
    `${BASE_URL}/v2/trackers/assignments/task/upload-feedback/`,
    data
  );

export const getStudentWorkList = async (data) =>
  await requestSdk(() => get(`${BASE_URL}/v1/trackers/student-work/`, data));
