import { BASE_URL } from 'config/urls';

import { callUrl, get, post, requestSdk } from 'utils/sdk';

export const fetchAssignmentSections = async (trackerId) =>
  requestSdk(() =>
    get(`${BASE_URL}/v1/trackers/assignments/${trackerId}/sections/`)
  );

export const fetchStudentAssignmentDetail = ({ studentId, assignmentId }) =>
  callUrl(
    get,
    `${BASE_URL}/v1/trackers/assignments/${assignmentId}/${studentId}/`
  );

export const postAssignmentComment = ({ taskId, data }) =>
  callUrl(
    post,
    `${BASE_URL}/v1/trackers/assignments/${taskId}/add-comment/`,
    data
  );

export const addAssignmentAudio = (assignmentId, data) =>
  callUrl(
    post,
    `${BASE_URL}/v1/trackers/assignments/task/${assignmentId}/upload-audio/`,
    data
  );

export const uploadAssignmentFeedback = (data) =>
  callUrl(
    post,
    `${BASE_URL}/v2/trackers/assignments/task/upload-feedback/`,
    data
  );

export const updateAssignmentScore = (taskId, data) =>
  callUrl(
    post,
    `${BASE_URL}/v1/trackers/assignments/task/${taskId}/update-score/`,
    data
  );

export const updateAssignmentStatus = ({ taskId, data }) =>
  callUrl(
    post,
    `${BASE_URL}/v1/trackers/assignments/task/${taskId}/update-status/`,
    data
  );

export const fetchClosestAssignment = () =>
  callUrl(get, `${BASE_URL}/v1/trackers/assignments/get-closest/`);

export const fetchNextSubmittedWork = (taskId, excludedTaskIds) =>
  callUrl(
    post,
    `${BASE_URL}/v1/trackers/assignments/task/${taskId}/get-next-work/`,
    { excluded_task_ids: excludedTaskIds }
  );
