import React, { useCallback, useContext, useEffect, useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import _ from 'lodash';
import { useFiltersData } from 'sdk';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import PopoverMenu from 'components/PopoverMenu';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const SortFilterDialog = ({
  selectedSection,
  label,
  icon,
  value,
  onChange,
  contentClass
}) => {
  const { user } = useContext(UserContext);
  const { data: filterData, isLoading } = useFiltersData({
    sectionId: selectedSection.id
  });
  const [sortBy, setSortBy] = useState(value.sort_by);
  const [sortOrder, setSortOrder] = useState(
    value.ascending ? 'ascending' : 'descending'
  );
  const [filterBy, setFilterBy] = useState({
    IEP: value.is_on_individualized_education_plan,
    MLL: value.is_multi_lingual_learner
  });
  const handleSortByChange = useCallback(
    (sortByValue) => {
      setSortBy(sortByValue);

      trackMixpanelEvent(
        user.id,
        '[StrategiesOverview] Teacher sorted student work.',
        {
          sortBy: sortByValue
        }
      );
    },
    [setSortBy, user]
  );

  const handleFilterByChange = useCallback(
    (event) => {
      const { name, checked } = event.target;
      setFilterBy({ ...filterBy, [name]: checked });

      trackMixpanelEvent(
        user.id,
        '[StrategiesOverview] Teacher filtered student work.',
        {
          IEP: filterBy.IEP,
          MLL: filterBy.MLL
        }
      );
    },
    [filterBy, setFilterBy, user]
  );

  useEffect(() => {
    const sortFilterParams = {
      ...value,
      sort_by: sortBy,
      ascending: sortOrder === 'ascending'
    };

    if (filterData?.is_mll_filter_enabled) {
      sortFilterParams.is_multi_lingual_learner = filterBy.MLL;
    }

    if (filterData?.is_iep_filter_enabled) {
      sortFilterParams.is_on_individualized_education_plan = filterBy.IEP;
    }

    const valueHasChanged = !_.isEqual(sortFilterParams, value);

    if (valueHasChanged) {
      localStorage.setItem(
        `strategiesFilterSearch`,
        JSON.stringify(sortFilterParams)
      );
      onChange(sortFilterParams);
    }
  }, [sortBy, sortOrder, filterBy, filterData, value, onChange]);

  return (
    <PopoverMenu label={label} icon={icon}>
      <div className={contentClass}>
        <Typography className={styles.contentText}>Sort by</Typography>
        <div className={styles.line} />
        <div>
          <RadioGroup
            value={sortBy}
            onChange={(e) => handleSortByChange(e.target.value)}
            className={styles.radioGroup}
          >
            <FormControlLabel
              value="name_first"
              control={<Radio className={styles.radio} color="primary" />}
              label="First Name"
              className={styles.option}
            />
            <FormControlLabel
              value="name_preferred"
              control={<Radio color="primary" />}
              label="Nickname"
              className={styles.option}
            />
            <FormControlLabel
              value="name_last"
              control={<Radio color="primary" />}
              label="Last Name"
              className={styles.option}
            />
          </RadioGroup>
          <RadioGroup
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            className={styles.radioGroup}
          >
            <FormControlLabel
              value="ascending"
              control={<Radio className={styles.radio} color="primary" />}
              label="Ascending"
              className={styles.option}
            />
            <FormControlLabel
              value="descending"
              control={<Radio color="primary" />}
              label="Descending"
              className={styles.option}
            />
          </RadioGroup>
        </div>
      </div>
      {!isLoading &&
        (filterData.is_iep_filter_enabled ||
          filterData.is_mll_filter_enabled) && (
          <div>
            <Typography className={styles.contentText}>Filter by</Typography>
            <div className={styles.line} />
            <FormGroup className={styles.formGroup}>
              {filterData.is_iep_filter_enabled && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterBy.IEP}
                      onChange={handleFilterByChange}
                      name="IEP"
                      color="primary"
                    />
                  }
                  label="IEP"
                  className={styles.option}
                />
              )}
              {filterData.is_mll_filter_enabled && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterBy.MLL}
                      onChange={handleFilterByChange}
                      name="MLL"
                      color="primary"
                    />
                  }
                  label="MLL"
                  className={styles.option}
                />
              )}
            </FormGroup>
          </div>
        )}
    </PopoverMenu>
  );
};

export default SortFilterDialog;
