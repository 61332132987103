import { createFilterOptions } from '@material-ui/lab/Autocomplete';

export const getOrganizationFilterOptions = ({ options, params }) => {
  const filter = createFilterOptions();

  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      id: null,
      inputValue: params.inputValue,
      name: `Add "${params.inputValue}"`,
      newOrganization: true
    });
  }

  return filtered.slice(0, 20);
};

export const getOrganizationOptionLabel = ({ organization }) => {
  // Organization selected with enter, right from the input
  if (typeof organization === 'string') {
    return organization;
  }

  // Add "xxx" option created dynamically
  if (organization.inputValue) {
    return organization.inputValue;
  }

  return organization.name;
};
