import _ from 'lodash';
import { studentPortfolioGetAssignmentsExportUrl } from 'sdk';

import { default as pusherManager } from 'utils/integrations/pusher';
import { notifyErrors, notifyInfo } from 'utils/notifications';
import { openInNewTab } from 'utils/urls';

const getExportUrlIfReady = ({ receivedUUIDs, expectedUUID }) => {
  if (_.includes(receivedUUIDs, expectedUUID)) {
    pusherManager.stopListeningForStudentPortfolioAssignmentsExport();

    openInNewTab(studentPortfolioGetAssignmentsExportUrl(expectedUUID));
  }
};

export const triggerAssignmentsExport = async ({
  studentId,
  filters,
  fileFormat,
  exportAction
}) => {
  notifyInfo('Your export is being prepared. Please do not close this window');
  let receivedUUIDs = [];

  // When this UUID comes to the channel from pusher then export is ready
  let expectedUUID = null;

  // Listen for export ready
  await pusherManager.listenForStudentPortfolioAssignmentsExport(({ uuid }) => {
    receivedUUIDs.push(uuid);

    // Check if export is ready. This is the base case
    // 1. Start listening to channel
    // 2. Trigger export
    // 3. BE returns UUID for FE to listen for while preparing the export
    // 4. Task for preparing export is ready and pushes the UUID in the channel
    // 5. FE receives the UUID for ready export and calls BE for download URL
    getExportUrlIfReady({ receivedUUIDs, expectedUUID });
  });

  const {
    success,
    errors,
    data: { uuid }
  } = await exportAction(studentId, {
    file_format: fileFormat,
    ...filters
  });

  if (success) {
    expectedUUID = uuid;

    // Check if export is ready.
    // This is the corner case when BE response with expected UUID is received after
    // celery pushed the uuid marking export as ready
    // 1. Start listening to channel
    // 2. Trigger export
    // 3. Task for preparing export is ready and pushes the uuid in the channel
    // 4. FE receives the uuid for ready export but still does not know it should check for it
    // 5. BE returns uuid to listen for
    // 6. FE finds the UUID in already received
    // 7. Call BE for download URL
    getExportUrlIfReady({ receivedUUIDs, expectedUUID });
  } else {
    notifyErrors(errors);
  }
};
