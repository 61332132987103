import React, { useContext, useEffect, useState } from 'react';

import { STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL } from 'config/urls';
import _ from 'lodash';
import { usePagination, useStudentPortfolio } from 'sdk';

import FiltersDrawer from 'pages/Students/FiltersDrawer';
import ViewSwitcher from 'pages/Students/ViewSwitcher';
import { withNavbar } from 'pages/Students/shared';
import { useMyPortfolioInitialFilters } from 'pages/Students/utils';
import { colors } from 'theme/palette';
import { useBreakpoints } from 'utils/resizing';

import ListView from './components/ListView';
import Button from 'components/Button';
import UserContext from 'components/UserContext';

import { INITIAL_FILTERS } from './constants';
import styles from './styles.module.scss';

const MyPortfolioIncompleteAssignmentsView = ({
  history,
  navigation: {
    filterIconClicked,
    setFilterIconClicked,
    setFilterIconColor,
    setBackButtonAction,
    setTitle
  }
}) => {
  const [isDesktop, isTablet, isMobile] = useBreakpoints({
    tablet: 992,
    mobile: 767
  });

  const { user } = useContext(UserContext);
  const studentId = user.student_id;

  const initialFilters = useMyPortfolioInitialFilters({
    defaultValues: INITIAL_FILTERS
  });

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  useEffect(() => {
    if (isMobile) {
      setBackButtonAction(() =>
        history.push(STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL)
      );
    } else {
      setBackButtonAction(null);
    }
  }, [history, setBackButtonAction, isMobile]);

  useEffect(() => {
    if (isTablet || isDesktop) {
      setTitle('My portfolio');
    } else {
      setTitle('incomplete assignments');
    }
  }, [setTitle, isTablet, isDesktop]);

  useEffect(() => {
    if (
      _.isEmpty(filters) ||
      _.isEmpty(initialFilters) ||
      filters === initialFilters
    ) {
      setFilterIconColor(colors.white);
    } else {
      setFilterIconColor(colors.pink);
    }
  }, [filters, initialFilters, setFilterIconColor]);

  const { data: initialData, isLoading: isDoingAnInitialFetch } =
    useStudentPortfolio({ studentId, filters });
  const { hasMore, results, fetchMore, isLoading } = usePagination({
    initialData: initialData,
    isLoading: isDoingAnInitialFetch
  });

  return (
    <>
      <FiltersDrawer
        filters={filters}
        setFilters={setFilters}
        open={filterIconClicked}
        showStatusFilter={false}
        showStandardsFilter={false}
        resetFilters={() => setFilters(initialFilters)}
        onClose={() => setFilterIconClicked(false)}
      />

      <div className={styles.incompleteAssignmentsContainer}>
        <ListView
          assignments={results}
          studentId={studentId}
          loading={isLoading}
        />

        {hasMore && (
          <div className={styles.loadMoreButtonContainer}>
            <Button onClick={fetchMore}>Load more</Button>
          </div>
        )}
      </div>
    </>
  );
};

export default withNavbar(MyPortfolioIncompleteAssignmentsView, {
  title: 'my portfolio',
  filterIconVisible: true,
  viewSwitcher: ViewSwitcher
});
