import React from 'react';

import _ from 'lodash';

import CreateMeetingSortCard from 'pages/Teachers/MeetingFeed/components/CreateMeetingSortCard';
import MeetingSortCard from 'pages/Teachers/MeetingFeed/components/MeetingSortCard';
import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const MeetingSorts = ({
  sorts,
  meetingShares,
  invitationIdentifier,
  onCreateMeetingSort,
  onDeleteSortSuccess
}) => {
  return (
    <div className={styles.container}>
      <Typography variant="S-TEXT-1" color={colors.grey3}>
        Sorts
      </Typography>
      <div className={styles.sortsContainer}>
        <CreateMeetingSortCard onClick={onCreateMeetingSort} />
        {_.map(sorts, (sort) => (
          <MeetingSortCard
            key={sort.id}
            sort={sort}
            invitationIdentifier={invitationIdentifier}
            meetingShares={meetingShares}
            onDeleteSortSuccess={onDeleteSortSuccess}
          />
        ))}
      </div>
    </div>
  );
};

export default MeetingSorts;
