import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import _ from 'lodash';

import { colors } from 'theme/palette';

import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as CheckMinusIcon } from './assets/check_minus.svg';
import { ReactComponent as CheckPlusIcon } from './assets/check_plus.svg';

export const flattenObjectives = (grouped) =>
  _.reduce(
    grouped,
    (res, objective) => {
      if (!objective.children) {
        return [...res, objective];
      }

      return [
        ...res,
        { ...objective, isHeader: true },
        ..._.map(objective.children, (obj) => ({ ...obj, isChild: true }))
      ];
    },
    []
  );

export const getObjectiveValueTypeName = (objective) => {
  const mapper = {
    checkbox: 'Checkbox',
    check_grade: 'Check Grade',
    rubric: 'Rubric',
    rubric_zero_two: 'Rubric 0-2',
    rubric_zero_three: 'Rubric 0-3',
    rubric_zero_four: 'Rubric 0-4',
    number: 'Number',
    text: 'Text'
  };

  return mapper[objective.value_type];
};

export const getObjectiveValueLabel = (
  objective,
  value,
  color = colors.blueDark
) => {
  if (objective.value_type === 'checkbox') {
    if (parseInt(value, 10) === 0) {
      return 'No';
    }

    if (parseInt(value, 10) === 1) {
      return 'Yes';
    }

    if (value === 'false') {
      return 'No';
    }

    if (value === 'true') {
      return 'Yes';
    }
  }

  if (objective.value_type === 'check_grade') {
    const mapper = {
      '-1': <SvgIcon component={CheckMinusIcon} htmlColor={color} />,
      0: <SvgIcon component={CheckIcon} htmlColor={color} />,
      1: <SvgIcon component={CheckPlusIcon} htmlColor={color} />
    };
    return mapper[value];
  }

  if (_.isNull(value)) {
    return 'N/A';
  }

  return `${value} -`;
};

export const sortByValueType = (objective, histogram) => {
  return _.sortBy(Object.entries(histogram), (item) => {
    const key = item[0];
    if (objective.value_type === 'text') {
      return key;
    }
    if (objective.value_type === 'checkbox') {
      return key === 'true';
    }
    return Number(key);
  });
};

export const getObjectiveHistogram = ({
  objective,
  scoreResponsesHistogram
}) => {
  const histogram = sortByValueType(objective, scoreResponsesHistogram);

  // If histogram is [["true", someNumber]], we want to add ["false", 0]
  // If histogram is [["false", someNumber]], we want to add ["true", 0]

  if (histogram.length === 1) {
    if (histogram[0][0] === 'false') {
      return [histogram[0], ['true', 0]];
    } else if (histogram[0][0] === 'true') {
      return [['false', 0], histogram[0]]; // We want false always to be first
    }
  }

  return histogram;
};
