import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  infoPanel: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: theme.headerHeight / 2
  }),
  infoPanelHeader: (theme) => ({
    position: 'absolute',
    display: 'flex',
    zIndex: 100,
    height: theme.headerHeight,
    borderRadius: 16,
    top: 0,
    left: 16,
    right: 16,
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor
  }),
  infoPanelContent: (theme) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 5,
    padding: '32px 16px',
    backgroundColor: theme.accentColor,
    borderColor: theme.primaryColor,
    color: theme.secondaryColor
  }),
  infoPanelFooter: (theme) => ({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    zIndex: 100,
    height: theme.headerHeight,
    borderRadius: 16,
    bottom: 0,
    left: 0,
    right: 0
  }),
  infoPanelFooterButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    width: 'auto'
  }
});

const InfoPanel = ({ header, content, footer, theme }) => {
  if (!theme) {
    theme = {
      primaryColor: '#F8E6CC',
      secondaryColor: '#B27909',
      accentColor: '#FFF1E1',
      headerHeight: 48
    };
  }
  const styles = useStyles({ ...theme });

  return (
    <div
      className={`${styles.infoPanel} `}
      style={{ paddingBottom: footer ? theme.headerHeight / 2 : 0 }}
    >
      <div className={styles.infoPanelHeader}>{header}</div>
      <div
        className={styles.infoPanelContent}
        style={{ paddingBottom: footer ? 32 : 10 }}
      >
        {content}
      </div>
      {footer && (
        <div className={styles.infoPanelFooter}>
          <div className={styles.infoPanelFooterButtons}>{footer}</div>
        </div>
      )}
    </div>
  );
};

export default InfoPanel;
