import _ from 'lodash';

export const buildColumnId = (id) => `column-${id}`;
export const parseColumnId = (columnId) =>
  _.toNumber(_.replace(columnId, 'column-', ''));

export const buildItemId = (id) => `item-${id}`;
export const parseItemId = (itemId) =>
  _.toNumber(_.replace(itemId, 'item-', ''));

export const getSortLabel = (sort) => _.get(sort, 'name', '');

export const buildSortsOptions = (sorts) =>
  _.map(sorts, (sort) => ({
    value: sort.id,
    label: getSortLabel(sort)
  }));

export const getNonExemplarItemsCount = (columns) =>
  _(columns)
    .filter(({ name }) => _.toLower(name) !== 'exemplar')
    .flatMap('items_order')
    .size();
