import React from 'react';

import Box from '@material-ui/core/Box';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

const EmptyAssignment = () => (
  <Box width="100%" padding="var(--offset-xxx-lg)">
    <Typography variant="H-TEXT-1" color={colors.blue1} align="center">
      There is no active student work yet.
    </Typography>
  </Box>
);

export default EmptyAssignment;
