import React, { useContext, useState } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import classnames from 'classnames';
import {
  STUDENTS_ASSIGNMENTS_LIST_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL,
  WALKTHROUGH_CREATE_PROFILE_URL,
  WALKTHROUGH_ROLLOUT_URL
} from 'config/urls';
import { Field, Formik } from 'formik';
import logo from 'images/logo-dark.svg';
import _ from 'lodash';
import { createProfile } from 'sdk';

import { Typography, useWalkthroughUser } from 'pages/Walkthrough/components';
import { colors } from 'theme/palette';
import { setFormErrors } from 'utils/forms';
import { notifyErrors } from 'utils/notifications';
import { reverse } from 'utils/urls';

import Button from 'components/Button';
import Image from 'components/Image';
import InputField from 'components/InputField';
import UserContext from 'components/UserContext';

import { initialValues, validate, validationSchema } from './constants.js';
import styles from './styles.module.scss';

const SignUpWithPassword = ({ history }) => {
  useWalkthroughUser();

  const [passwordType, setPasswordType] = useState('password');
  const { setUser } = useContext(UserContext);
  const passwordShowed = passwordType === 'text';

  const handleSubmit = async ({ values, setFieldError }) => {
    const { success, data, errors } = await createProfile({
      data: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password
      }
    });

    if (success) {
      setUser(data);

      if (data.created || (!data.is_teacher && _.isNull(data.student_id))) {
        // Go to next rollout step
        history.push({
          pathname: reverse(WALKTHROUGH_ROLLOUT_URL),
          search: history.location.search
        });
      } else {
        // If user already has profile, redirect him to homepage
        history.push(
          data.is_teacher
            ? TEACHERS_ASSIGNMENTS_LIST_URL
            : STUDENTS_ASSIGNMENTS_LIST_URL
        );
      }
    } else {
      if (_.find(errors, ['code', 'invalid'])) {
        setFormErrors(errors, setFieldError);
      } else {
        notifyErrors(errors);
      }
    }
  };

  return (
    <div className={styles.signUpWrapper}>
      <div className={styles.signUpBox}>
        <Image src={logo} className={styles.logo} alt="Logo" />
        <Box maxWidth={300} margin="auto">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validate={validate}
          >
            {({
              values,
              errors,
              setFieldValue,
              setFieldError,
              isSubmitting
            }) => {
              const submitDisabled =
                isSubmitting ||
                _.isEmpty(values.password) ||
                _.isEmpty(values.email) ||
                !_.isEmpty(errors);

              return (
                <form
                  method="post"
                  className={styles.formContainer}
                  onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit({ values, setFieldError });
                  }}
                >
                  <Typography
                    variant="H-TEXT-3"
                    color={colors.blue1}
                    className={styles.fieldLabel}
                  >
                    First name
                  </Typography>
                  <Field
                    type="text"
                    name="firstName"
                    variant="underlined"
                    component={InputField}
                    placeholder="First name"
                    error={_.has(errors, 'firstName')}
                    className={classnames(styles.inputField, {
                      [styles.hasValue]: _.get(values, 'firstName')
                    })}
                  />

                  <Typography
                    variant="H-TEXT-3"
                    color={colors.blue1}
                    className={styles.fieldLabel}
                  >
                    Last name
                  </Typography>
                  <Field
                    type="text"
                    name="lastName"
                    variant="underlined"
                    component={InputField}
                    placeholder="Last name"
                    error={_.has(errors, 'lastName')}
                    className={classnames(styles.inputField, {
                      [styles.hasValue]: _.get(values, 'lastName')
                    })}
                  />

                  <Typography
                    variant="B-Text-4"
                    color={colors.pink1}
                    className={styles.error}
                  >
                    {errors.lastName}
                  </Typography>

                  <Typography
                    variant="H-TEXT-3"
                    color={colors.blue1}
                    className={styles.fieldLabel}
                  >
                    Email
                  </Typography>
                  <Field
                    type="email"
                    name="email"
                    variant="underlined"
                    component={InputField}
                    placeholder="Email"
                    error={_.has(errors, 'email')}
                    className={classnames(styles.inputField, {
                      [styles.hasValue]: _.get(values, 'email')
                    })}
                  />

                  <Typography
                    variant="B-Text-4"
                    color={colors.pink1}
                    className={styles.error}
                  >
                    {errors.email}
                  </Typography>

                  <Typography
                    variant="H-TEXT-3"
                    color={colors.blue1}
                    className={styles.fieldLabel}
                  >
                    Password
                  </Typography>
                  <Field
                    type={passwordType}
                    name="password"
                    variant="underlined"
                    component={InputField}
                    placeholder="Password"
                    error={_.has(errors, 'password')}
                    className={classnames(styles.inputField, {
                      [styles.hasValue]: _.get(values, 'password')
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            className={styles.icon}
                            onClick={() =>
                              setPasswordType(
                                passwordShowed ? 'password' : 'text'
                              )
                            }
                          >
                            {!passwordShowed && <VisibilityRoundedIcon />}
                            {passwordShowed && <VisibilityOffRoundedIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Typography
                    variant="B-Text-4"
                    color={colors.pink1}
                    className={styles.error}
                  >
                    {errors.password}
                  </Typography>
                  <Typography
                    variant="B-Text-4"
                    color={colors.grey3}
                    className={styles.helperText}
                  >
                    - At least 8 characters
                    <br />
                    - At least one letter
                    <br />
                    - Not too similar to your email address
                    <br />- Not a commonly used password
                  </Typography>

                  <Button
                    fullWidth
                    color={submitDisabled ? 'lightGrey' : 'pink'}
                    type="submit"
                    disabled={submitDisabled}
                    className={styles.signUpButton}
                  >
                    Sign up
                  </Button>
                </form>
              );
            }}
          </Formik>
          <Typography
            variant="B-Text-2"
            color={colors.blue1}
            onClick={() =>
              history.push({
                pathname: WALKTHROUGH_CREATE_PROFILE_URL,
                search: history.location.search
              })
            }
            className={classnames(styles.link, 'pointer')}
          >
            Back
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default SignUpWithPassword;
