import _ from 'lodash';

export const getDefaultMeetingShares = ({
  meetingShares,
  meetingBoardMeetingShareIds
}) =>
  _.reduce(
    _.map(meetingShares, 'meeting_share_id'),
    (result, value) => {
      result[value] = _.includes(meetingBoardMeetingShareIds, value);

      return result;
    },
    {}
  );

export const getNewSelectedMeetingShares = ({
  meetingShares,
  meetingBoardMeetingShareIds
}) =>
  _.keys(
    _.pickBy(
      meetingShares,
      (value, key) =>
        !_.includes(meetingBoardMeetingShareIds, parseInt(key)) &&
        value === true
    )
  );
