import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { useSectionLessons } from 'sdk';

import { TopBarSelect } from 'pages/Teachers/shared';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { parseParams, stringifyParams } from 'utils/urls';

import UserContext from 'components/UserContext';

const LessonSelectDropdown = ({
  selectedLesson,
  selectedSection,
  selectedGrade,
  selectedUnit,
  setSelectedLesson,
  className
}) => {
  const { user } = useContext(UserContext);

  const { data: lessons, isLoading } = useSectionLessons({
    sectionId: selectedSection.id,
    params: {
      grade: selectedGrade.name,
      unit: selectedUnit.name
    }
  });

  const history = useHistory();
  const urlParams = parseParams(history.location.search);
  const lessonNumber = _.get(urlParams, 'lesson');

  useEffect(() => {
    if (!isLoading) {
      if (!_.isEmpty(lessons)) {
        const lesson = lessons.find((lesson) => lesson.name === lessonNumber);
        if (lesson) {
          setSelectedLesson(lesson);
        } else {
          const defaultLesson = lessons.find((lesson) => lesson.is_default);
          setSelectedLesson(defaultLesson);

          urlParams.lesson = defaultLesson.name;
          history.replace({ search: stringifyParams(urlParams) });
        }
      } else {
        setSelectedLesson(null);

        delete urlParams.lesson;
        history.replace({ search: stringifyParams(urlParams) });
      }
    }
  }, [lessons, setSelectedLesson, lessonNumber, history, urlParams, isLoading]);

  const selectLesson = (newLesson) => {
    setSelectedLesson(newLesson);

    urlParams.lesson = newLesson.name;
    history.replace({ search: stringifyParams(urlParams) });

    trackMixpanelEvent(
      user.id,
      '[StrategiesOverview] Teacher changed lesson.',
      {
        lesson: newLesson.name
      }
    );
  };

  if (_.isEmpty(lessons)) return <></>;
  return (
    <TopBarSelect
      value={selectedLesson}
      renderValue={(lesson) => `Lesson ${_.get(lesson, 'name')}`}
      onChange={selectLesson}
      options={lessons.map((lesson) => ({
        value: lesson,
        label: lesson.name
      }))}
      className={className}
    />
  );
};

export default LessonSelectDropdown;
