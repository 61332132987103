import React, { useContext } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import { useImageStrategies } from 'sdk';

import RecommendedNextSteps from 'pages/Teachers/StrategiesOverview/components/RecommendedNextSteps';
import StrategyRubrics from 'pages/Teachers/StrategiesOverview/components/StrategyRubrics';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Image from 'components/Image';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const LoadingSkeleton = () => (
  <>
    <div className={styles.strategyLoadingState}>
      <Skeleton variant="rect" height={40} />
    </div>
    <div className={styles.rubricLoadingState}>
      <Skeleton variant="rect" height={15} />
    </div>
    <div className={styles.rubricLoadingState}>
      <Skeleton variant="rect" height={15} />
    </div>
    <div className={styles.rubricLoadingState}>
      <Skeleton variant="rect" height={15} />
    </div>
    <div className={styles.strategyLoadingState}>
      <Skeleton variant="rect" height={40} />
    </div>
    <div className={styles.rubricLoadingState}>
      <Skeleton variant="rect" height={15} />
    </div>
  </>
);

const StudentSummaryDialog = ({
  workTagImageId,
  studentName,
  studentWorkUrl,
  isSummaryDialogIsOpen,
  setSummaryDialogIsOpen,
  openAIFeedbackDialog,
  onClose
}) => {
  const { user } = useContext(UserContext);
  const { data: strategiesData, isLoading: isStrategiesLoading } =
    useImageStrategies({
      workTagImageId: workTagImageId
    });
  const isAIFeedbackButtonDisabled =
    isStrategiesLoading || strategiesData.ai_feedback;

  return (
    <div className={styles.dialogContainer}>
      <Dialog
        open={isSummaryDialogIsOpen}
        onClose={onClose}
        className={styles.dialog}
        classes={{ paper: styles.paperRoot }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.dialogContent}>
          <div className={styles.leftCard}>
            <div className={styles.imageSpacer}></div>
            <div className={styles.imageContainer}>
              <Image
                src={studentWorkUrl}
                className={styles.image}
                onClick={() => {
                  trackMixpanelEvent(
                    user.id,
                    '[StrategiesOverview] Teacher attempted to click on student work image.'
                  );
                }}
              />
            </div>
            <div className={styles.imageSpacer}></div>
            <div className={styles.studentNameContainer}>
              <Typography
                variant="H-TEXT-3"
                component="h5"
                className={styles.studentName}
              >
                {studentName}
              </Typography>
            </div>
          </div>
          <div className={styles.rightCard}>
            <Typography
              variant="B-Text-3"
              component="h5"
              className={styles.studentSummaryText}
            >
              STUDENT SUMMARY VIEW
            </Typography>
            {isStrategiesLoading && <LoadingSkeleton />}
            {!isStrategiesLoading && (
              <>
                <StrategyRubrics
                  strategiesBreakdown={strategiesData.strategies_breakdown}
                  user={user}
                />
                {!_.isEmpty(strategiesData.next_steps) && (
                  <RecommendedNextSteps nextSteps={strategiesData.next_steps} />
                )}
              </>
            )}
            <div className={styles.feedbackButtonContainer}>
              <Tooltip
                classes={{
                  tooltip: styles.tooltipInfo,
                  arrow: styles.tooltipArrow
                }}
                arrow={true}
                title="Feedback already submitted"
                disableHoverListener={!isAIFeedbackButtonDisabled}
              >
                <span>
                  <Button
                    color="darkBlue"
                    disabled={isAIFeedbackButtonDisabled}
                    className={styles.feedbackButton}
                    classes={{ label: styles.feedbackButtonLabel }}
                    onClick={(e) => {
                      openAIFeedbackDialog();
                      setSummaryDialogIsOpen(false);
                    }}
                  >
                    <Typography
                      variant="B-Text-5"
                      component="span"
                      className={styles.feedbackText}
                    >
                      doesn't look quite right?
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default StudentSummaryDialog;
