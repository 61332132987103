export const DASHBOARD_STRATEGIES = 'strategies';
export const DASHBOARD_MISCONCEPTIONS = 'misconceptions';

export const MasteryLevelToColorMapper = {
  'Work is complete and correct': 'rgb(155, 155, 155)',
  'General conceptual understanding but work may be insufficient or contain some errors':
    'rgb(240,91,148)',
  'Significant errors in work': 'rgb(85,88,147)',
  'N/A - No evidence of strategy': 'rgb(51,57,102)'
};

export const MISCONCEPTIONS_DASHBOARD_VIEW_OPTIONS = {
  OVERVIEW: 'overview',
  COMPARISON: 'comparison'
};

export const OVERALL_RESPONSE_LABEL = 'Overall response';
