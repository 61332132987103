import React, { useMemo } from 'react';

import _ from 'lodash';

import {
  GridView,
  InfiniteScrollContainer,
  LoadingGridViewSkeleton
} from 'pages/Teachers/StudentPortfolio/components';
import { splitAssignmentsToMonths } from 'utils/assignments';

import NoResultsPlaceholder from 'components/NoResultsPlaceholder';

import styles from './styles.module.scss';

const DateView = ({ studentAssignments, student, loadNextPage }) => {
  const monthsAssignments = useMemo(
    () =>
      splitAssignmentsToMonths({
        assignments: studentAssignments.data.results
      }),
    [studentAssignments.data.results]
  );

  return (
    <div>
      {studentAssignments.loading &&
        _.isEmpty(studentAssignments.data.results) && (
          <div className={styles.gridViewContainer}>
            <LoadingGridViewSkeleton />
          </div>
        )}

      {!studentAssignments.loading &&
        _.isEmpty(studentAssignments.data.results) && <NoResultsPlaceholder />}
      <InfiniteScrollContainer
        length={studentAssignments.data.results.length}
        fetchNext={loadNextPage}
        hasNext={studentAssignments.data.next}
      >
        <div className={styles.gridViewContainer}>
          <GridView monthsAssignments={monthsAssignments} student={student} />
        </div>
      </InfiniteScrollContainer>
    </div>
  );
};

export default DateView;
