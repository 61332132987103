import React from 'react';
import { withRouter } from 'react-router';

import { LOGIN_URL } from 'config/urls';
import _ from 'lodash';

import { stringifyParams } from 'utils/urls';

import UserContext from 'components/UserContext';

import { me } from './sdk';

const StudentRequired = (WrappedComponent) => {
  class HOC extends React.Component {
    fetchMe = async () => {
      const { data: user } = await me();
      return user;
    };

    redirectToLogin = () => {
      const { pathname, search } = this.props.location;

      this.props.history.push({
        pathname: LOGIN_URL,
        search: stringifyParams({ next: pathname + search })
      });
    };

    ensureUser = (user, setUser) => {
      if (!user) {
        this.fetchMe().then((me) => {
          !_.isEmpty(me) ? setUser(me) : this.redirectToLogin();
        });

        return null;
      }

      return user;
    };

    render() {
      return (
        <UserContext.Consumer>
          {({ user, setUser }) => {
            const ensuredUser = this.ensureUser(user, setUser);

            if (ensuredUser) {
              return <WrappedComponent user={ensuredUser} {...this.props} />;
            }
          }}
        </UserContext.Consumer>
      );
    }
  }

  return withRouter(HOC);
};

export default StudentRequired;
