import React, { useContext } from 'react';

import { PATHWAYS_CHATBOT_URL } from 'config/urls';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import UserContext from 'components/UserContext';

import { ReactComponent as AskEmberImage } from './assets/ask-ember.svg';

const AskEmberButton = ({ style = {} }) => {
  const user = useContext(UserContext);

  const defaultStyle = {
    width: '160px',
    position: 'fixed',
    zIndex: 2000,
    bottom: '85px',
    right: '16px',
    cursor: 'pointer'
  };

  const handleClick = () => {
    trackMixpanelEvent(user.id, 'Button Click', {
      feature: 'pathways',
      view: 'ember',
      button: 'open'
    });
    window.location.href = PATHWAYS_CHATBOT_URL;
  };

  return (
    <AskEmberImage
      style={{
        ...defaultStyle,
        ...style
      }}
      onClick={handleClick}
    />
  );
};

export default AskEmberButton;
