import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import styles from './styles.module.scss';

export const AssignmentSkeleton = () => (
  <Box display="flex" width="100%">
    <Paper className={styles.paper} variant="outlined">
      <Skeleton
        variant="rect"
        height={500}
        width="85%"
        className={styles.imgSkeleton}
      />

      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Skeleton width={100} height={40} />
          </Grid>
          <Grid item>
            <Skeleton width={100} height={40} />
          </Grid>
          <Grid item>
            <Skeleton width={100} height={40} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>

    <div className={styles.dummyRightMenu} />
  </Box>
);
