import { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';

import { uploadFile } from 'utils/files';
import { notifyError } from 'utils/notifications';

import {
  MAX_AGENDA_FILE_SIZE,
  MAX_AGENDA_FILE_SIZE_AS_STRING
} from './constants';

export const useAgendaFile = () => {
  const [agendaFile, setAgendaFile] = useState(null);

  const onAgendaFileDrop = useCallback(async (files) => {
    if (_.isEmpty(files)) {
      return;
    }
    const file = files[0];

    const preparedFile = {
      file,
      id: null,
      uploaded: false
    };
    setAgendaFile(preparedFile);

    const uploadedFileId = await uploadFile({
      blob: file,
      name: file.name,
      maxSize: {
        sizeInBytes: MAX_AGENDA_FILE_SIZE,
        sizeAsString: MAX_AGENDA_FILE_SIZE_AS_STRING
      }
    });

    const uploadedFile = {
      file,
      id: uploadedFileId,
      uploaded: true
    };
    setAgendaFile(uploadedFile);
  }, []);

  const onAgendaFileRemove = useCallback(() => setAgendaFile(null), []);

  const onAgendaFileDropRejected = useCallback(
    (file) =>
      notifyError(
        `${file.name} exceeds the size limit of ${MAX_AGENDA_FILE_SIZE_AS_STRING}.`
      ),
    []
  );

  const agendaFileIsUploading = useMemo(
    () => !_.isNull(agendaFile) && !agendaFile.uploaded,
    [agendaFile]
  );

  return {
    agendaFile,
    agendaFileIsUploading,
    onAgendaFileDrop,
    onAgendaFileDropRejected,
    onAgendaFileRemove
  };
};
