import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import MuiAudioPlayer from 'material-ui-audio-player';
import { deleteAssignmentAudio } from 'sdk';

import FileDeleteConfirmDialog from 'pages/Teachers/Assignments/Feedback/components/FileDeleteConfirmDialog';
import { useDialog } from 'utils/hooks';
import { notifyError, notifySuccess } from 'utils/notifications';

import styles from './styles.module.scss';

const useAudioPlayerStyles = makeStyles(() => ({
  root: {
    margin: 0,
    // Aligns the time of the player with the main slider
    '& div:last-child': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  volumeIcon: {
    '& + div': {
      // Selects the first div after the icon component which is the container of the slider
      // and fixes a z-index problem. NOTE: We cannot use the `volumeSlider` class to fix this.
      zIndex: 1
    }
  },
  mainSlider: {
    // Fixes a bug with the time alignment
    paddingBottom: 10
  }
}));

const AudioPlayer = ({ audioFiles, setAudioFiles }) => {
  const {
    isOpened: isOpenedConfirmDialog,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog
  } = useDialog();

  const [fileForDeletion, setFileForDeletion] = useState();

  const handleFileDelete = async () => {
    setFileForDeletion(null);

    try {
      await deleteAssignmentAudio({ audioFileId: fileForDeletion.id });
      // Remove the deleted file from the current list of files
      setAudioFiles(
        audioFiles.filter((file) => file.id !== fileForDeletion.id)
      );
      notifySuccess('File deleted successfully');
    } catch (e) {
      notifyError('An error has occured while deleting the file');
    }
    closeConfirmDialog();
  };

  return (
    <div className={styles.container}>
      {isOpenedConfirmDialog && (
        <FileDeleteConfirmDialog
          onClose={() => {
            setFileForDeletion(null);
            closeConfirmDialog();
          }}
          onConfirm={handleFileDelete}
        />
      )}
      {audioFiles.map((file, index) => {
        const audioSource = _.get(file, 'file.file_url');
        return (
          audioSource && (
            <div className={styles.playerContainer}>
              <MuiAudioPlayer
                key={`player-${index}`}
                src={audioSource}
                elevation={0}
                spacing={2}
                variation="primary"
                useStyles={useAudioPlayerStyles}
                width="95%"
                download={true}
                loop={false}
                debug={false}
              />
              <DeleteIcon
                fontSize="large"
                classes={{ root: styles.deleteButtonRoot }}
                onClick={() => {
                  setFileForDeletion(file);
                  openConfirmDialog();
                }}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

export default AudioPlayer;
