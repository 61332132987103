import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  TEACHERS_SUBSCRIPTIONS_CHANGE_PLAN_URL,
  TEACHERS_SUBSCRIPTIONS_DASHBOARD_URL
} from 'config/urls';

import ChangePlan from './ChangePlan';
import Dashboard from './Dashboard';

const TeacherSubscriptions = () => {
  return (
    <Switch>
      <Route
        exact
        path={TEACHERS_SUBSCRIPTIONS_DASHBOARD_URL}
        component={Dashboard}
      />
      <Route
        exact
        path={TEACHERS_SUBSCRIPTIONS_CHANGE_PLAN_URL}
        component={ChangePlan}
      />

      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default TeacherSubscriptions;
