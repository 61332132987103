import React from 'react';

import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { ReactComponent as CameraIcon } from 'pages/Students/shared/assets/camera.svg';
import { getWorkName } from 'pages/Teachers/shared/utils';
import { colors } from 'theme/palette';
import { ImgixDimensions } from 'utils/constants';
import { setImgixFallbackUrl } from 'utils/files/imgix';

import ImgixImage from 'components/ImgixImage';

import styles from './styles.module.scss';

const TaskWork = ({
  showImageUploadButtons,
  work,
  active,
  changeImage,
  openCameraView
}) => (
  <div className={styles.workContainer}>
    <div>
      {_.size(work) === 0 && showImageUploadButtons && (
        <div className={styles.workPlaceholder} onClick={openCameraView}>
          <CameraIcon fill={colors.grey3} />
        </div>
      )}
      {_.size(work) > 0 && (
        <div className={styles.imageContainer}>
          <ImgixImage
            src={active.work_url}
            dimensions={ImgixDimensions.LARGE}
            onError={setImgixFallbackUrl(active.s3_work_url)}
          />
        </div>
      )}
    </div>
    {active && (
      <div className={styles.workBar}>
        <div className={styles.workDate}>
          {moment(active.created_at).format('M/DD [at] h:mm a')}
        </div>
        <div className={styles.workType}>{getWorkName(active)}</div>
        {_.size(work) > 1 && (
          <div className={styles.workPaginator}>
            {_.map(work, (img, index) => (
              <div
                key={img.id}
                className={classnames({
                  [styles.selected]: img.id === active.id
                })}
                onClick={() => changeImage(img)}
              >
                {index + 1}
              </div>
            ))}
          </div>
        )}
      </div>
    )}
  </div>
);

export default TaskWork;
