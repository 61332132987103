import React from 'react';

import _ from 'lodash';

import { TopBarSelect } from 'pages/Teachers/shared';

const renderValue = (student) => _.get(student, 'name');
const buildOptions = (students) =>
  _.map(students, (student) => ({
    value: student,
    label: student.name
  }));

const StudentSelect = ({ students, selectedStudent, setSelectedStudent }) => {
  const student = _.find(students, { id: _.get(selectedStudent, 'id') });

  if (!student) {
    return null;
  }

  return (
    <TopBarSelect
      value={student}
      renderValue={renderValue}
      onChange={setSelectedStudent}
      options={buildOptions(students)}
    />
  );
};

export default React.memo(StudentSelect);
