import { base64UrlSafeEncodeString, stringifyParams } from 'utils/urls';

const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';

export const openGoogleLoginPage = (
  redirectUri,
  scope,
  state = {},
  prompt = 'select_account'
) => {
  // Reference: https://developers.google.com/identity/protocols/oauth2/web-server#creatingclient

  const params = {
    response_type: 'code',
    access_type: 'offline',
    client_id: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
    redirect_uri: `${process.env.REACT_APP_BASE_BACKEND_URL}/${redirectUri}`,
    scope,
    prompt,
    state: base64UrlSafeEncodeString(JSON.stringify(state))
  };

  window.location = `${GOOGLE_AUTH_URL}?${stringifyParams(params)}`;
};
