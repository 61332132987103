import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const selectUseStyles = (hasValue) =>
  makeStyles({
    root: {
      height: 40,
      borderRadius: '5px 5px 1px 1px',
      boxSizing: 'border-box',
      border: 0,
      paddingLeft: 0,
      paddingRight: 0,
      // =======================================================

      // Borders
      '&:before': {
        borderBottomColor: hasValue ? colors.greyDark : colors.grey4,
        borderBottomWidth: 2
      },
      '&:after': {
        borderBottomColor: hasValue ? colors.greyDark : colors.grey4,
        borderBottomWidth: 2
      },
      '&:hover:before': {
        borderBottomColor: colors.greyDark
      },
      '&.Mui-focused:after': {
        borderBottomColor: colors.greenDarker
      },
      '&.Mui-error.:after': {
        borderBottomColor: colors.pink
      },
      '&.Mui-error.Mui-focused:after': {
        borderBottomColor: colors.pink
      },
      // Backgound
      backgroundColor: hasValue ? colors.greyLight : colors.white,
      '&:hover': {
        backgroundColor: colors.greyLight
      },
      '&.Mui-focused': {
        backgroundColor: colors.greyLight
      },
      // Error text
      '&.MuiFormHelperText-root': {
        color: colors.pink,
        backgroundColor: colors.white,
        paddingLeft: 10
      }
    }
  });

const ObjectiveSelectField = ({
  field,
  form: { handleChange },
  options,
  disabled
}) => {
  const hasValue = !_.isEmpty(_.get(field, 'value') || _.get(options, 'value'));
  const useStyles = selectUseStyles(hasValue)();

  return (
    <FormControl fullWidth required>
      <Select
        classes={{
          select: styles.option,
          icon: styles.endIcon
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          getContentAnchorEl: null,
          classes: { paper: styles.menuPaper, list: styles.menuList }
        }}
        disabled={disabled}
        value={field.value}
        onChange={handleChange(field.name)}
        className={useStyles.root}
        IconComponent={KeyboardArrowDownIcon}
      >
        {options.map((option) => {
          const StartIcon = option.Icon;

          return (
            <MenuItem
              key={option.key}
              value={option.key}
              className={styles.option}
            >
              <StartIcon
                className={styles.startIcon}
                htmlColor={colors.grey1}
              />
              <Typography variant="B-Text-2" color={colors.grey1}>
                {option.text}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ObjectiveSelectField;
