import React from 'react';

import { Typography } from 'pages/Walkthrough/components';
import { colors } from 'theme/palette';

import Image from 'components/Image';

import PhotoBenjamin from './assets/BenjaminPhoto.jpg';
import PhotoCarrie from './assets/CarriePhoto.jpg';
import PhotoJean from './assets/JeanPhoto.png';
import PhotoOlivia from './assets/OliviaPhoto.jpg';
import PhotoStephanie from './assets/StephaniePhoto.jpg';
import { ReactComponent as AccountPlaceholder } from './assets/account_placeholder.svg';
import styles from './styles.module.scss';

const testimonies = [
  {
    text: `“EdLight has been a lifesaver during virtual teaching! As a math teacher, I was worried about checking student work and their calculations. EdLight allows me to look at each students' work, offer meaningful feedback, and it's a quick way to grade all my assignments.”`,
    name: 'Jean Basappa',
    occupation: '6th Math Teacher',
    school: 'Moving Everest Charter School',
    photo: PhotoJean
  },
  {
    text: `“EdLight is a very useful tool, particularly in math. It allows teachers to see their scholars' work instead of just their answer. I also think they have a great team who responds quickly and is always improving the product!”`,
    name: 'Benjamin Taylor',
    school: 'Regeneration Schools',
    photo: PhotoBenjamin
  },
  {
    text: `“I’m going to be honest, when I first heard about EdLight, I was like, this is not going to work in kindergarten. I didn’t see it working… but it is! I would definitely recommend it.”`,
    name: 'Carrie Godwin',
    occupation: 'Kindergarten Teacher',
    school: 'Persistence Preparatory Academy Charter School',
    photo: PhotoCarrie
  },
  {
    text: `“It’s been really pretty simple for my kids to use and that’s been really great too. There are so many online platforms for remote learning, the simplicity of EdLight has been super awesome.”`,
    name: 'Olivia McIntyre',
    occupation: '7th Grade Teacher',
    school: 'Veritas Prep Charter School',
    photo: PhotoOlivia
  },
  {
    text: `“We use photos of work on EdLight for show-calling, I pull up a picture and share my screen and have a discussion about what’s going on in the work and how they’re doing.I also look at their work submissions in real time so I can see trends. I can stop students and do a whole group redirect or stop and pull some into a breakout room and help them there.”`,
    name: 'Olivia McIntyre',
    occupation: '7th Grade Teacher',
    school: 'Veritas Prep Charter School',
    photo: PhotoOlivia
  },
  {
    text: `“I would definitely recommend EdLight to any educators -- it’s just so easy and helpful to see all their work.”`,
    name: 'Olivia McIntyre',
    occupation: '7th Grade Teacher',
    school: 'Veritas Prep Charter School',
    photo: PhotoOlivia
  },
  {
    text: `“We love EdLight because we use a curriculum with handouts, and how am I supposed to see their work? EdLight has made it super easy for kids.”`,
    name: 'Stephanie Layson',
    occupation: '5th Grade Math',
    school: 'Catalyst Public Schools',
    photo: PhotoStephanie
  },
  {
    text: `“I would love to never have to take home papers again. Thinking back to before EdLight, I had a crate I brought back and forth to school -- that’s unnecessary now. There’s no reason I need to collect or keep a huge stack of papers again.”`,
    name: 'Stephanie Layson',
    occupation: '5th Grade Math',
    school: 'Catalyst Public Schools',
    photo: PhotoStephanie
  }
];

const TestimonialFooter = () => {
  const testimony =
    testimonies[Math.round(Math.random() * (testimonies.length - 1))];

  return (
    <div className={styles.testimonialsContainer}>
      <div className={styles.testimony}>
        <Typography
          variant="B-Text-2"
          color={colors.white}
          className={styles.testimonialText}
        >
          {testimony.text}
        </Typography>
        <div className={styles.testimonialClient}>
          <div className={styles.testimonialIcon}>
            {testimony.photo ? (
              <Image
                src={testimony.photo}
                alt="Teacher"
                className={styles.avatar}
              />
            ) : (
              <AccountPlaceholder />
            )}
          </div>
          <Typography variant="B-Text-3" color={colors.blue5}>
            {testimony.name}
            {testimony.occupation && (
              <>
                <br />
                {testimony.occupation}
              </>
            )}
            <br />
            {testimony.school}
          </Typography>
        </div>
      </div>
    </div>
  );
};
export default React.memo(TestimonialFooter);
