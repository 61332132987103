import { BASE_URL } from 'config/urls';

import { notifyError } from 'utils/notifications';
import { post } from 'utils/sdk';

export const trackMixpanelEventSdk = (data) =>
  new Promise((resolve) =>
    post(`${BASE_URL}/v1/integrations/mixpanel/track/`, data)
      .then(resolve)
      .catch(notifyError)
  );
