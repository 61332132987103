import React, { useState } from 'react';

import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles.module.scss';

const MultipleSelect = ({ options, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
    onChange(event.target.value);
  };

  const deselectOption = (deselectedOptionValue) => {
    const newSelectedOptions = selectedOptions.filter(
      (optionValue) => optionValue !== deselectedOptionValue
    );
    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  return (
    <FormControl className={styles.formControlContainer}>
      <Select
        multiple
        displayEmpty
        value={selectedOptions}
        onChange={handleChange}
        className={styles.selectStyle}
        input={<Input />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Select</em>;
          } else {
            return (
              <div className={styles.chips}>
                {options
                  .filter((item) => selected.includes(item.value))
                  .map((item) => (
                    <Chip
                      key={item.value}
                      label={item.label}
                      title={item.label}
                      className={styles.chip}
                      onDelete={() => deselectOption(item.value)}
                      deleteIcon={
                        <CloseIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  ))}
              </div>
            );
          }
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelect;
