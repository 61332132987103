import React from 'react';

import styles from './styles.module.scss';

const DailyMeme = ({ dailyMeme }) => {
  return (
    <img src={dailyMeme?.meme_url} alt="meme" className={styles.dailyMeme} />
  );
};

export default DailyMeme;
