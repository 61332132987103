import { Environments } from 'utils/constants';

const FeatureToggle = ({ dev, staging, prod, review, children }) => {
  const currentEnv = process.env.REACT_APP_ENV_NAME;

  if (dev && currentEnv === Environments.DEV) {
    return children;
  }
  if (staging && currentEnv === Environments.STAGING) {
    return children;
  }
  if (prod && currentEnv === Environments.PROD) {
    return children;
  }
  if (review && currentEnv === Environments.REVIEW) {
    return children;
  }

  return null;
};

export default FeatureToggle;
