import React, { useCallback } from 'react';

import classnames from 'classnames';

import { colors } from 'theme/palette';
import { openInNewTab } from 'utils/urls';

import Typography from 'components/Typography';

import { ReactComponent as FileIcon } from '../../assets/file.svg';
import styles from './styles.module.scss';

const AgendaFileChip = ({ file }) => {
  const downloadFile = useCallback(
    () => openInNewTab(file.file_url),
    [file.file_url]
  );
  return (
    <div
      className={classnames(styles.chip, styles.agendaChip)}
      onClick={downloadFile}
    >
      <FileIcon width={16} height={20} />
      <Typography
        variant="B-Text-3"
        color={colors.blue3}
        className={styles.agendaChipText}
      >
        {file.file_name}
      </Typography>
    </div>
  );
};

export default AgendaFileChip;
