import _ from 'lodash';
import * as yup from 'yup';

import { FIRST_PAGE } from 'pages/Teachers/shared/AssignmentDialog/constants';

export const dialogValidationSchema = yup.object().shape({
  assignmentName: yup.string().required('Assignment name is required.'),
  tasks: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .max(512, 'Task name cannot be longer than 512 symbols.')
          .required('Task name is required.'),
        children: yup.array().of(
          yup.object().shape({
            name: yup.string()
          })
        )
      })
    )
    .min(1, 'At least 1 task is required.')
});

export const buildInitialValues = ({ isCopyMode, assignment }) => {
  let assignmentName = _.get(assignment, 'name', '');
  assignmentName = isCopyMode ? 'Copy of ' + assignmentName : assignmentName;

  return {
    assignmentName,
    description: _.get(assignment, 'description', ''),
    tasks: _.get(assignment, 'tasks', [FIRST_PAGE]),
    attachments: _.get(assignment, 'attachments', [])
  };
};
