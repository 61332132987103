import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  STUDENTS_ASSIGNMENTS_DETAIL_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL,
  STUDENTS_ASSIGNMENTS_OVERVIEW_URL,
  STUDENTS_ASSIGNMENTS_REDIRECT_URL
} from 'config/urls';

import Detail from './Detail';
import DetailRedirect from './DetailRedirect';
import List from './List';
import Overview from './Overview';

const StudentsAssignments = () => (
  <Switch>
    <Route exact path={STUDENTS_ASSIGNMENTS_DETAIL_URL} component={Detail} />

    <Route
      exact
      path={STUDENTS_ASSIGNMENTS_REDIRECT_URL}
      component={DetailRedirect}
    />

    <Route exact path={STUDENTS_ASSIGNMENTS_LIST_URL} component={List} />

    <Route
      exact
      path={STUDENTS_ASSIGNMENTS_OVERVIEW_URL}
      component={Overview}
    />

    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default StudentsAssignments;
