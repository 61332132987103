import React, { useEffect } from 'react';

import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { isEmpty } from 'lodash';

import StudentAvatar from './components/StudentAvatar';

// importing the custom styles
import styles from './styles.module.scss';
import {
  getAvatarColor,
  getIndexOfStudent,
  getStudentFromId,
  getStudentInitials
} from './utils';

const StudentSelectDropdown = ({
  students,
  selectedStudent,
  setSelectedStudent
}) => {
  useEffect(() => {
    if (!isEmpty(students)) {
      setSelectedStudent(students[0]);
    }
  }, [students, setSelectedStudent]);

  const handleChange = (event) => {
    const studentId = event.target.value;
    setSelectedStudent(getStudentFromId({ studentId, students }));
  };

  return (
    <div>
      <Select
        id="student-select-dropdown"
        value={selectedStudent ? selectedStudent.id : students[0].id}
        disableUnderline
        className={styles.studentMenu}
        onChange={handleChange}
        input={<Input />}
        renderValue={(studentId) => {
          const student = getStudentFromId({ studentId, students });
          const bgColor = getAvatarColor({
            index: getIndexOfStudent({ studentId, students })
          });
          const studentInitials = getStudentInitials({ student });
          return (
            <StudentAvatar
              bgColor={bgColor}
              studentInitials={studentInitials}
            ></StudentAvatar>
          );
        }}
      >
        {students.map((student, i) => {
          const bgColor = getAvatarColor({ index: i });
          const studentInitials = getStudentInitials({ student });
          return (
            <MenuItem key={i} value={student.id}>
              <StudentAvatar
                bgColor={bgColor}
                studentInitials={studentInitials}
              ></StudentAvatar>
              <span className={styles.studentMenuName}>
                {student.first_name}
              </span>
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default StudentSelectDropdown;
