import React, { useCallback, useEffect, useMemo } from 'react';

import _ from 'lodash';

import { Typography } from 'pages/Students/shared';
import { colors } from 'theme/palette';

import MultiSelectFilter from 'components/MultiSelectFilter';
import SelectFilter from 'components/SelectFilter';

import { buildStudentsNamesList } from '../../utils';
import { ORDERING_OPTIONS, SHOW_FILTER_OPTIONS } from './constants';
import {
  useDefaultFilters,
  useDefaultOrdering,
  useHistorySearch,
  useTasksOptions
} from './hooks';
import styles from './styles.module.scss';

const getTaskOptionLabel = (opt) => opt.label;
const getStudentOptionLabel = (opt) => opt.name;

const Filters = ({
  filters,
  ordering,
  setFilters,
  setOrdering,
  studentWork,
  selectedSectionId
}) => {
  const [tasksOptions, loadingTasksOptions] =
    useTasksOptions(selectedSectionId);
  const students = useMemo(
    () => buildStudentsNamesList(studentWork),
    [studentWork]
  );

  const filtersInitialized = useDefaultFilters(
    filters,
    setFilters,
    students,
    tasksOptions,
    loadingTasksOptions
  );
  const orderingInitialized = useDefaultOrdering(ordering, setOrdering);

  useHistorySearch(
    selectedSectionId,
    filters,
    filtersInitialized,
    ordering,
    orderingInitialized
  );

  const onTaskValueChange = useCallback(
    (event, val) => {
      setFilters((prev) => ({ ...prev, tasks: val }));
    },
    [setFilters]
  );

  const onStudentValueChange = useCallback(
    (event, val) => {
      setFilters((prev) => ({ ...prev, students: val }));
    },
    [setFilters]
  );

  const onSortValueChange = useCallback(
    (event) => {
      setOrdering(_.find(ORDERING_OPTIONS, { value: event.target.value }));
    },
    [setOrdering]
  );

  const onShowValueChange = useCallback(
    (event) => {
      setFilters((prev) => ({
        ...prev,
        show: _.find(SHOW_FILTER_OPTIONS, { value: event.target.value })
      }));
    },
    [setFilters]
  );

  useEffect(() => {
    if (!selectedSectionId && !_.isEmpty(filters.students)) {
      onStudentValueChange(null, []);
    }
  }, [selectedSectionId, filters.students, onStudentValueChange]);

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.filterBox}>
        <Typography variant="S-TEXT-1" color={colors.grey4}>
          Filter Pages
        </Typography>
        <MultiSelectFilter
          className={styles.filterInput}
          value={filters.tasks}
          getOptionLabel={getTaskOptionLabel}
          options={tasksOptions}
          limitTags={2}
          placeholder="All"
          disabled={loadingTasksOptions}
          onChange={onTaskValueChange}
        />
      </div>
      <div className={styles.filterBox}>
        <Typography variant="S-TEXT-1" color={colors.grey4}>
          Filter Students
        </Typography>
        <MultiSelectFilter
          className={styles.filterInput}
          value={filters.students}
          getOptionLabel={getStudentOptionLabel}
          options={students}
          disabled={_.isEmpty(students)}
          limitTags={2}
          placeholder="All"
          onChange={onStudentValueChange}
        />
      </div>
      <div className={styles.filterBox}>
        <Typography variant="S-TEXT-1" color={colors.grey4}>
          Sort by
        </Typography>
        <SelectFilter
          className={styles.filterInput}
          value={ordering.value}
          options={ORDERING_OPTIONS}
          onChange={onSortValueChange}
        />
      </div>
      <div className={styles.filterBox}>
        <Typography variant="S-TEXT-1" color={colors.grey4}>
          Show
        </Typography>
        <SelectFilter
          className={styles.filterInput}
          value={filters.show.value}
          options={SHOW_FILTER_OPTIONS}
          onChange={onShowValueChange}
        />
      </div>
    </div>
  );
};

export default Filters;
