import React from 'react';

import logoSmall from 'images/logo-small.svg';

import styles from './styles.module.scss';

const StatusIndicator = ({ statusText }) => {
  return (
    <div className={styles.loadingWrapper}>
      <img src={logoSmall} alt="Logo" />
      <p>{statusText}</p>
    </div>
  );
};

export default StatusIndicator;
