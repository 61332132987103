import React from 'react';

import { colors } from 'theme/palette';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const PrivacyDialog = ({ onClose }) => {
  return (
    <Dialog
      open
      alignTop
      className={styles.dialogContainer}
      classes={{ paper: styles.dialog }}
    >
      <Typography
        variant="H-TEXT-2"
        color={colors.blueLight}
        className={styles.dialogTitle}
      >
        Data Privacy
      </Typography>
      <Typography
        variant="B-Text-2"
        color={colors.pink1}
        className={styles.dialogSubtitle}
      >
        Your privacy is our priority.
      </Typography>
      <Typography variant="B-Text-2" className={styles.dialogContent}>
        Your privacy is our priority. We value the trust you place in Pathways
        and will never sell your information.
      </Typography>
      <Typography variant="B-Text-2" className={styles.dialogContent}>
        Your responses are used solely to tailor your experience and recommend
        the best tools and resources for your needs.
      </Typography>
      <Button
        variant="text"
        color="blue"
        fullWidth
        className={styles.dialogButton}
        onClick={onClose}
      >
        OK
      </Button>
    </Dialog>
  );
};

export default PrivacyDialog;
