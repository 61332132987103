import * as Sentry from '@sentry/react';
import React, { useEffect, useRef, useState } from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import _ from 'lodash';
import moment from 'moment';

import {
  AudioPlayer,
  Comments,
  EditingCanvas,
  RightMenu
} from 'pages/Teachers/Assignments/Feedback/components/';
import { getInitialImage } from 'pages/Teachers/Assignments/Feedback/components/EditingCanvas/utils';
import {
  addAssignmentAudio,
  fetchClosestAssignment,
  fetchNextSubmittedWork,
  fetchStudentAssignmentDetail,
  postAssignmentComment,
  updateAssignmentStatus
} from 'pages/Teachers/Assignments/Feedback/sdk';
import styles from 'pages/Teachers/Assignments/Feedback/styles.module.scss';
import {
  getObjectFromList,
  handleTaskStatusChange,
  loadImageFromSrc
} from 'pages/Teachers/Assignments/Feedback/utils';
import {
  useEditingContext,
  useFastContext
} from 'pages/Teachers/Assignments/Feedback/utilsEditingContext';
import { useAudioRecorder } from 'pages/Teachers/Assignments/Feedback/utilsRecorder';
import { getWorkName } from 'pages/Teachers/shared/utils';
import { colors } from 'theme/palette';
import { uploadFile } from 'utils/files';
import { formatDate } from 'utils/moment';

import Button from 'components/Button';
import ControllsOverImage from 'components/ControllsOverImage';
import Typography from 'components/Typography';

import styles1 from './styles.module.scss';

const MAX_ZOOM_SCALE = 11;

const uploadAudioFile = (audioBlob) => {
  return uploadFile({
    blob: audioBlob,
    name: 'audio-feedback.mp3'
  });
};

// TODO: refactor
// TODO: add optimistic re-rendering of the added comment.
const addComment = (values, resetForm, activeTask /*, setActiveTask*/) => {
  return postAssignmentComment({
    taskId: activeTask.id,
    data: values
  }).then(() => {
    // TODO: find a better way to refresh the info
    // setActiveTask({ ...activeTask, comments: [...activeTask.comments, data] });
    resetForm();
  });
};

const fetchAssignmentAndTaskToAdvanceTo = (
  currentTaskId,
  currentlySubmittedTaskIds
) => {
  return fetchNextSubmittedWork(currentTaskId, currentlySubmittedTaskIds).then(
    (data) => {
      const { section_instance_id, student_id, task_id } = data;

      if (currentTaskId === task_id) {
        Sentry.captureException(
          new Error(
            'Backend returned the same task when asked for the next task to score'
          ),
          { extra: { currentTaskId, responseData: data } }
        );
      }

      if (!task_id) {
        return Promise.resolve({});
      }

      return fetchStudentAssignmentDetail({
        assignmentId: section_instance_id,
        studentId: student_id
      }).then((assignment) => {
        const task = getObjectFromList(assignment.tasks, task_id);
        const nextImageSrc = _.get(_.first(task.work), 's3_work_url');
        return loadImageFromSrc(nextImageSrc).then((image) => {
          return Promise.resolve({
            assignment,
            task,
            image,
            sectionId: section_instance_id,
            studentId: student_id
          });
        });
      });
    }
  );
};

const FeedbackTool = ({
  activeTask,
  activeWork,
  setActiveTask,
  setActiveWork,

  previousTask,
  nextTask,
  refetchAssignment,
  switchAssignment,

  setShowCelebratoryDialog,
  setClosestAssignment
}) => {
  const lastRefreshStartedTime = useRef();
  if (!lastRefreshStartedTime.current) {
    lastRefreshStartedTime.current = moment();
  }

  const [assignmentAndTaskToAdvanceTo, setAssignmentAndTaskToAdvanceTo] =
    useState(Promise.resolve({}));

  const activeTaskId = _.get(activeTask, 'id');
  const [activeTaskAudioFiles, setActiveTaskAudioFiles] = useState([]);
  useEffect(() => {
    setActiveTaskAudioFiles(_.get(activeTask, 'audio_files', []));
  }, [activeTask]);

  const [showStickers, setShowStickers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const editingContext = useEditingContext(activeWork.s3_work_url);

  const fastContext = useFastContext();

  const recorder = useAudioRecorder({
    onAudioRecorded: (audioBlob) =>
      uploadAudioFile(audioBlob)
        .then((feedbackFileId) =>
          addAssignmentAudio(activeTaskId, { file: feedbackFileId })
        )
        .then(refetchAssignment)
        .then((assignment) => {
          const refreshedActiveTask = _.head(
            assignment.tasks.filter(
              (task) => task.id === _.get(activeTask, 'id')
            )
          );
          if (refreshedActiveTask) {
            setActiveTask((previousTaskData) => ({
              ...previousTaskData,
              audio_files: refreshedActiveTask.audio_files
            }));
          }
        })
  });

  const handleTaskStatusChange_ = (status, autoAdvance) => {
    const addTaskWork = ({ activeTask, work }) => {
      setActiveTask({ ...activeTask, work: [work, ...activeTask.work] });
    };

    const setTaskWork = (task, work) => {
      setActiveTask({ ...task, work });
    };

    setIsLoading(true);

    if (editingContext.cropping.active) {
      editingContext.cropping.cropImage();
    }
    fastContext.discardSelection();

    const blobPromise = fastContext.imageBlobFromCanvas();
    const initialImage = getInitialImage(fastContext.canvas);
    let imageWasCropped = false;
    let imageWasRotated = false;

    if (initialImage) {
      imageWasCropped = initialImage.scaleX !== 1;
      imageWasRotated = _.get(initialImage, 'angle', 0) % 360 !== 0;
    }

    const canvasHasNewObjects =
      fastContext.hasNewObjects() || imageWasCropped || imageWasRotated;

    // activeTask could change before the promise requests its value, so we cache it in the Promise itself.
    Promise.all([
      Promise.resolve(activeTask),
      assignmentAndTaskToAdvanceTo
    ]).then(([cachedActiveTask, data]) => {
      const { image, task } = data;
      const drawImageAndFetchNext = ({ image, task }) => {
        if (canvasHasNewObjects) {
          blobPromise.then(() => {
            fastContext.drawInitialImageObject(image);
          });
        } else {
          fastContext.drawInitialImageObject(image);
        }
        setAssignmentAndTaskToAdvanceTo(
          fetchAssignmentAndTaskToAdvanceTo(task.id, [cachedActiveTask.id])
        );
      };

      // We want the canvas to be scraped before we get to change the image on it
      if (autoAdvance && image && task) {
        drawImageAndFetchNext({ image, task });
      }

      if (autoAdvance) {
        if (!_.isEmpty(data)) {
          switchAssignment(data);
        } else {
          fetchAssignmentAndTaskToAdvanceTo(cachedActiveTask.id, []).then(
            (data) => {
              if (_.isEmpty(data)) {
                // We are sure there is no next prefetched task
                return fetchClosestAssignment()
                  .then(setClosestAssignment)
                  .then(() => setShowCelebratoryDialog(true));
              } else {
                drawImageAndFetchNext(data);
                switchAssignment(data);
              }
            }
          );
        }
      }
    });

    updateAssignmentStatus({
      taskId: activeTask.id,
      data: { status, advanced_switch_enabled: false } // Hardcoding this to false because we handle it elsewhere
    });

    blobPromise
      .then((blob) =>
        handleTaskStatusChange({
          autoAdvance,
          activeWork,
          activeTask,
          fastContext,
          setActiveWork,
          addTaskWork,
          setTaskWork,
          canvasHasNewObjects,
          blob
        })
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const imageSrc = activeWork.s3_work_url;

  useEffect(() => {
    if (imageSrc && fastContext.isReady) {
      const eventStartedTime = moment();
      if (lastRefreshStartedTime.current < eventStartedTime) {
        lastRefreshStartedTime.current = eventStartedTime;
      }

      //https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
      const url = `${imageSrc}?dont-cache=true`;
      loadImageFromSrc(url).then((image) => {
        if (lastRefreshStartedTime.current === eventStartedTime) {
          fastContext.drawInitialImageObject(image);
        } else {
          // Another image request came in after this image started loading, so it should not be shown
        }
      });
    }
  }, [imageSrc, fastContext]);

  return (
    <div className={styles1.container}>
      <Paper variant="outlined" className={styles.feedbackPaper}>
        <div className={styles.taskSwitch}>
          {previousTask && previousTask.id !== activeTaskId && (
            <IconButton
              onClick={() => {
                // lastRefreshStartedTime.current = moment();
                setActiveTask(previousTask);
                assignmentAndTaskToAdvanceTo.then(({ task }) => {
                  if (task && previousTask.id === task.id) {
                    setAssignmentAndTaskToAdvanceTo(
                      fetchAssignmentAndTaskToAdvanceTo(previousTask.id, [])
                    );
                  }
                });
                refetchAssignment().then((assignment) => {
                  const fetchedTask = getObjectFromList(
                    assignment.tasks,
                    previousTask.id
                  );

                  if (
                    _.size(_.get(fetchedTask, 'work')) !==
                    _.size(_.get(activeTask, 'work'))
                  ) {
                    setActiveTask(fetchedTask);
                  }
                });
              }}
            >
              <ArrowBackIosRoundedIcon />
            </IconButton>
          )}
          <Typography variant="H-TEXT-2" color={colors.blue1} align="center">
            {_.get(activeTask, 'name')}
          </Typography>
          {nextTask && nextTask.id !== activeTaskId && (
            <IconButton
              onClick={() => {
                // lastRefreshStartedTime.current = moment();
                setActiveTask(nextTask);
                assignmentAndTaskToAdvanceTo.then(({ task }) => {
                  if (task && nextTask.id === task.id) {
                    setAssignmentAndTaskToAdvanceTo(
                      fetchAssignmentAndTaskToAdvanceTo(nextTask.id, [])
                    );
                  }
                });
                refetchAssignment().then((assignment) => {
                  // This fixes refreshing the entire assignment on every task move
                  const fetchedTask = getObjectFromList(
                    assignment.tasks,
                    nextTask.id
                  );

                  if (
                    _.size(_.get(fetchedTask, 'work')) !==
                    _.size(_.get(activeTask, 'work'))
                  ) {
                    setActiveTask(fetchedTask);
                  }
                });
              }}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          )}
        </div>
        {activeWork && (
          <>
            <div className={styles.feedbackImage} id="image-container">
              <TransformWrapper
                options={{
                  maxScale: MAX_ZOOM_SCALE,
                  disabled: false
                }}
                zoomIn={{ step: 15 }} // That's just the speed of the zoom. The number doesn't mean that much
                zoomOut={{ step: 15 }} // That's just the speed of the zoom. The number doesn't mean that much
                wheel={{ disabled: true }}
                pan={{
                  paddingSize: 0,
                  disabled:
                    editingContext.objectDeletion.hasSelectedObject ||
                    editingContext.draw.isActive ||
                    editingContext.cropping.active
                }}
                doubleClick={{ disabled: true }}
              >
                {({ scale, zoomOut, zoomIn, resetTransform }) => {
                  return (
                    <>
                      <EditingCanvas
                        editingContext={editingContext}
                        resetTransform={resetTransform}
                        fastContext={fastContext}
                      />
                      <ControllsOverImage
                        recorder={recorder}
                        showStickers={showStickers}
                        closeStickers={() => setShowStickers(false)}
                        editingContext={editingContext}
                        fastContext={fastContext}
                        zoom={{ zoomIn, zoomOut, scale }}
                      />
                    </>
                  );
                }}
              </TransformWrapper>
            </div>
            <Grid container alignItems="center" className={styles.workGrid}>
              <Grid item>
                <Typography variant="B-Text-2" color={colors.grey1}>
                  {formatDate(activeWork.created_at, 'ddd M/DD h:mm A')}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs
                justifyContent="flex-end"
                alignItems="center"
              >
                {activeTask &&
                  _.map(activeTask.work, (workItem, index) => (
                    <Button
                      key={index}
                      noTypography
                      variant="small"
                      color="transparent"
                      onClick={() => {
                        setActiveWork(workItem);
                      }}
                    >
                      <Typography
                        variant="H-TEXT-3"
                        color={
                          activeWork.id === workItem.id
                            ? colors.pink1
                            : colors.grey2
                        }
                      >
                        {getWorkName(workItem)}
                      </Typography>
                    </Button>
                  ))}
              </Grid>
            </Grid>
            {activeTask && !_.isEmpty(activeTaskAudioFiles) && (
              <AudioPlayer
                audioFiles={activeTaskAudioFiles}
                setAudioFiles={setActiveTaskAudioFiles}
              />
            )}
            <Comments
              key={activeTaskId} // re-renders / resets the form when the task is changed
              comments={_.get(activeTask, 'comments', [])}
              addComment={(values, { resetForm }) =>
                addComment(values, resetForm, activeTask)
                  .then(refetchAssignment)
                  .then((assignment) => {
                    const refreshedActiveTask = _.head(
                      assignment.tasks.filter(
                        (task) => task.id === _.get(activeTask, 'id')
                      )
                    );
                    if (refreshedActiveTask) {
                      setActiveTask((previousTaskData) => ({
                        ...previousTaskData,
                        comments: refreshedActiveTask.comments
                      }));
                    }
                  })
              }
            />
          </>
        )}
      </Paper>

      {activeTask && (
        <RightMenu
          onClickAssignmentStatus={handleTaskStatusChange_}
          onTaskUpdate={setActiveTask}
          activeTask={activeTask}
          activeWork={activeWork}
          rotateImage={editingContext.rotation.rotateCanvas}
          cropButton={{
            active: editingContext.cropping.active,
            toggle: editingContext.cropping.activate,
            turnOff: editingContext.cropping.turnOff,
            cropImage: editingContext.cropping.cropImage
          }}
          filterButton={{
            active: editingContext.filter.isActive,
            toggle: editingContext.filter.toggle,
            turnOff: editingContext.filter.turnOff,
            apply: editingContext.filter.apply,
            remove: editingContext.filter.remove
          }}
          drawButton={{
            active: editingContext.draw.isActive,
            toggle: editingContext.draw.toggle,
            turnOff: editingContext.draw.turnOff
          }}
          textButton={{
            apply: editingContext.textBox.addTextBox
          }}
          stickersButton={{
            active: showStickers,
            toggle: () => setShowStickers(!showStickers),
            turnOff: () => setShowStickers(false)
          }}
          audioButton={{
            active: recorder.isVisible, // For the right side menu - this means active button
            toggle: recorder.toggleVisible,
            isRecording: recorder.isActive,
            turnOff: () => recorder.setVisible(false)
          }}
          undo={{
            isEmpty: editingContext.undo.isEmpty,
            pop: editingContext.undo.pop,
            clear: editingContext.undo.clear
          }}
          zoom={{ zoomToNormal: editingContext.zoom.zoomToNormal }}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default FeedbackTool;
