import React, { useCallback, useContext, useMemo, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import _ from 'lodash';

import { buildColumnId, buildItemId } from 'pages/Teachers/MeetingSort/utils';
import { colors } from 'theme/palette';

import MeetingSortContext from 'components/MeetingSortContext';
import Typography from 'components/Typography';

const MoveMenu = ({ studentWork }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = !_.isNil(anchorEl);

  const { columns, handleItemMovement } = useContext(MeetingSortContext);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const sourceColumn = useMemo(
    () =>
      Object.values(columns).find((column) =>
        column.items_order.includes(studentWork.item_id)
      ),
    [columns, studentWork.item_id]
  );

  const handleColumnMenuItemClick = useCallback(
    ({ columnId }) => {
      const sourceColumnId = sourceColumn.id;
      const sourceColumnItemIndex = sourceColumn.items_order.indexOf(
        studentWork.item_id
      );

      return handleItemMovement({
        draggableId: buildItemId(studentWork.item_id),
        source: {
          index: sourceColumnItemIndex,
          droppableId: buildColumnId(sourceColumnId)
        },
        destination: { droppableId: buildColumnId(columnId), index: 0 } // We are moving the item to the top of the new column.
      });
    },
    [handleItemMovement, sourceColumn, studentWork.item_id]
  );

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <SvgIcon component={MoreVertIcon} />
      </IconButton>

      <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={handleMenuClose}>
        <MenuItem disabled>
          <Typography variant="B-Text-4">Move To</Typography>
          <ArrowForwardIcon
            fontSize="small"
            style={{
              color: colors.grey2,
              marginLeft: '10px'
            }}
          />
        </MenuItem>
        {_.map(columns, ({ id: columnId, name: columnName }) => {
          const isAlreadyInThisColumn = sourceColumn.id === columnId;

          return (
            <MenuItem
              key={columnId}
              disabled={isAlreadyInThisColumn}
              onClick={() => handleColumnMenuItemClick({ columnId })}
            >
              <Typography variant="B-Text-4">
                {columnName}
                {isAlreadyInThisColumn && '(current)'}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default MoveMenu;
