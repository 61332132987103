import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useGoogleLastSync } from 'sdk';

import { GoogleClassroomSyncStatuses } from 'utils/integrations/googleClassroom';
import { parseParams, stringifyParams } from 'utils/urls';

import IntegrationsSyncContext from 'components/IntegrationsSyncContext';

export const useGoogleClassroomLastSync = () => {
  const [lastSync, setLastSync] = useState(null);

  const { data: lastSyncData, isLoading: loadingLastSync } =
    useGoogleLastSync();

  const {
    googleClassroom: { isInitiated: currentSyncIsInitiated }
  } = useContext(IntegrationsSyncContext);

  const history = useHistory();

  const appendSyncIdToQueryParams = useCallback(
    (googleSyncId) => {
      // Append the provided sync ID to the query params
      // so that the respective integration observer starts watching the sync status
      const currentParams = parseParams(history.location.search);
      const newParams = stringifyParams({ ...currentParams, googleSyncId });

      history.push({ search: newParams });
    },
    [history]
  );

  useEffect(() => {
    setLastSync(lastSyncData);
  }, [lastSyncData]);

  useEffect(() => {
    if (lastSync) {
      const lastSyncIsInitiated =
        lastSync.status === GoogleClassroomSyncStatuses.INITIATED.value;

      if (lastSyncIsInitiated && !currentSyncIsInitiated) {
        appendSyncIdToQueryParams(lastSync.id);
      }
    }
  }, [lastSync, currentSyncIsInitiated, appendSyncIdToQueryParams]);

  return {
    lastSync,
    setLastSync,
    loadingLastSync
  };
};
