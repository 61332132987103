import React from 'react';

import classnames from 'classnames';

import styles from './styles.module.scss';

const StepperIcon = ({ src, active, completed }) => (
  <div
    className={classnames(styles.stepperIcon, {
      [styles.activeStepperIcon]: active,
      [styles.completedStepperIcon]: completed
    })}
  >
    <img src={src} alt="Step" />
  </div>
);

export default StepperIcon;
