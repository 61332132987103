import React, { useCallback, useState } from 'react';

import { updateStudentPreferredName } from 'pages/Teachers/StudentPortfolio/sdk';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const UpdatePreferredNameDialog = ({
  student,
  isOpen,
  setIsOpen,
  refetchStudentDetails
}) => {
  const [preferredName, setPreferredName] = useState('');

  const onPreferredNameChange = useCallback(
    (event) => setPreferredName(event.target.value),
    []
  );

  const onSubmit = async () => {
    const { success, errors } = await updateStudentPreferredName(student.id, {
      preferred_name: preferredName
    });

    if (success) {
      setIsOpen(false);
      notifySuccess('Preferred name successfully updated.');
      refetchStudentDetails();
    } else {
      setIsOpen(false);
      notifyErrors(errors);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      classes={{ paper: styles.dialog }}
    >
      <Typography variant="H-TEXT-2" className={styles.label}>
        enter a nickname for this student
      </Typography>

      <InputField
        fullWidth
        variant="underlined"
        placeholder="Preferred name"
        value={preferredName}
        onChange={onPreferredNameChange}
      />

      <Button color="pink" className={styles.submitButton} onClick={onSubmit}>
        Submit
      </Button>
    </Dialog>
  );
};

export default UpdatePreferredNameDialog;
