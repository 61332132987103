import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  }
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <Typography align="center" variant="H-TEXT-1" color={colors.blue1}>
        Loading
      </Typography>
    </Backdrop>
  );
};

export default Loader;
