import { colors } from 'theme/palette';
import { AssignmentStatusOptions } from 'utils/constants';

import { ReactComponent as AssignedIcon } from './assets/assigned.svg';
import { ReactComponent as CompleteIcon } from './assets/complete.svg';
import { ReactComponent as ReviseIcon } from './assets/revise.svg';
import { ReactComponent as SubmittedIcon } from './assets/submitted.svg';

const AssignmentStatusIcon = {
  [AssignmentStatusOptions.ASSIGNED]: {
    icon: AssignedIcon,
    color: colors.yellow2
  },
  [AssignmentStatusOptions.SUBMITTED]: {
    icon: SubmittedIcon,
    color: colors.blue4
  },
  [AssignmentStatusOptions.REVISE]: {
    icon: ReviseIcon,
    color: colors.pink2
  },
  [AssignmentStatusOptions.COMPLETE]: {
    icon: CompleteIcon,
    color: colors.green3
  }
};

export default AssignmentStatusIcon;
