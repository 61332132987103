import { BASE_URL } from 'config/urls';

import { get, post, requestSdk } from 'utils/sdk';

export const assignmentDetail = async (trackerId) =>
  await requestSdk(() =>
    get(`${BASE_URL}/v2/trackers/assignments/${trackerId}/detail/`)
  );

export const trackerCreate = async (data) =>
  await requestSdk(() => post(`${BASE_URL}/v1/trackers/create/`, data));

export const trackerUpdate = async ({ trackerId, data }) =>
  await requestSdk(() =>
    post(`${BASE_URL}/v1/trackers/${trackerId}/update/`, data)
  );
