import React from 'react';

import CardResources from 'pages/Pathways/components/CardResources';

import Typography from 'components/Typography';

import contentRigorAndRelevaneImage from './assets/content-rigor-and-relevance.png';
import { ReactComponent as MedalIcon } from './assets/medal.svg';
import studentEngagementImage from './assets/student-engagement.png';
import teacherKnowledgeAndSkillImage from './assets/teacher-knowledge-and-skill.png';
import styles from './styles.module.scss';

const RecommendedResources = ({
  articles,
  refetchArticles,
  refetchWeeklyProgress,
  selectedDate
}) => {
  const articleGetImage = (article) => {
    if (article.user_category === 'Content Rigor & Relevance') {
      return contentRigorAndRelevaneImage;
    }
    if (article.user_category === 'Teacher Knowledge & Skill') {
      return teacherKnowledgeAndSkillImage;
    }
    if (article.user_category === 'Student Engagement') {
      return studentEngagementImage;
    }
  };

  const resources = articles
    ? articles.map((article) => ({
        ...article,
        imageUrl: articleGetImage(article)
      }))
    : [];

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <MedalIcon />
        <div className={styles.textContainer}>
          <Typography variant="H-TEXT-3" className={styles.titleText}>
            Explore the Recommended
          </Typography>
          <Typography variant="H-TEXT-3" className={styles.titleText}>
            Top Resources from your peers
          </Typography>
        </div>
      </div>
      <div className={styles.topResourcesList}>
        {resources.map(
          ({
            title,
            description,
            imageUrl,
            content_url,
            id,
            likes_count,
            is_liked,
            is_disliked
          }) => (
            <CardResources
              title={title}
              text={description}
              imageUrl={imageUrl}
              resourceUrl={content_url}
              likesCount={likes_count}
              id={id}
              key={`resource-card-${id}`}
              refetchArticles={refetchArticles}
              isLiked={is_liked}
              isDisLiked={is_disliked}
              refetchWeeklyProgress={refetchWeeklyProgress}
              selectedDate={selectedDate}
            />
          )
        )}
      </div>
    </div>
  );
};

export default RecommendedResources;
