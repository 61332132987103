import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp
} from 'lucide-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useFamilyStudentAssignmentDatesList } from 'sdk';

import styles from './styles.module.scss';
import {
  formatDate,
  formatMonthYear,
  getDaysInMonth,
  getDaysInWeek
} from './utils';

const Calendar = ({ student, selectedDate, setSelectedDate }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewType, setViewType] = useState('week');
  const [assignments, setAssignments] = useState([]);
  const prevStudentRef = useRef();

  const { data, refetch } = useFamilyStudentAssignmentDatesList({
    student_id: student?.id
  });

  useEffect(() => {
    const studentChanged = student?.id !== prevStudentRef.current;
    if (student && studentChanged) {
      refetch();
      prevStudentRef.current = student?.id;
    }
  }, [student, refetch]);

  useEffect(() => {
    if (data) {
      setAssignments(data);
    }
  }, [data]);

  const days = useMemo(() => {
    if (viewType === 'week') {
      return getDaysInWeek({ date: currentDate }).map((date) => ({
        date,
        isPadding: false
      }));
    } else {
      return getDaysInMonth({ date: currentDate });
    }
  }, [viewType, currentDate]);

  const handleNavigateCalendar = (direction) => {
    const newDate = new Date(currentDate);
    if (viewType === 'week') {
      newDate.setDate(newDate.getDate() + direction * 7);
    } else {
      newDate.setMonth(newDate.getMonth() + direction);
    }
    setCurrentDate(newDate);
  };

  const hasAssignment = (date) => {
    const localDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const dateString = formatDate({ date: localDate });
    return assignments.includes(dateString);
  };

  const isSelected = (date) => {
    return (
      date.getFullYear() === selectedDate.getFullYear() &&
      date.getMonth() === selectedDate.getMonth() &&
      date.getDate() === selectedDate.getDate()
    );
  };

  const toggleView = () => {
    const newViewType = viewType === 'week' ? 'month' : 'week';
    setViewType(newViewType);

    if (newViewType === 'week') {
      const weekDays = getDaysInWeek({ date: selectedDate });
      setCurrentDate(weekDays[0]);
    } else {
      setCurrentDate(new Date(selectedDate));
    }
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.calendarSide}>
        <ChevronLeft
          size={20}
          onClick={() => handleNavigateCalendar(-1)}
          className={styles.navArrow}
        />
      </div>
      <div className={styles.calendarMain}>
        <div className={styles.calendarHeader}>
          <div className={styles.studentName}>
            {`${student ? student.first_name : 'Student'}'s Deadlines`}
          </div>
          <div className={styles.monthSelector} onClick={toggleView}>
            <span>{formatMonthYear({ date: currentDate })}</span>
            {viewType === 'week' ? (
              <ChevronDown size={20} />
            ) : (
              <ChevronUp size={20} />
            )}
          </div>
        </div>
        <div className={styles.calendarDays}>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(
            (day, index) => (
              <div key={index} className={styles.dayLabel}>
                {day}
              </div>
            )
          )}
          {days.map(({ date, isPadding }, index) => {
            const isCurrentMonth = date.getMonth() === currentDate.getMonth();
            return (
              <div
                key={index}
                className={`${styles.dayCell} 
                  ${isSelected(date) ? styles.selected : ''} 
                  ${isPadding ? styles.padding : ''} 
                  ${isCurrentMonth ? styles.currentMonth : styles.otherMonth}`}
                onClick={() => setSelectedDate(date)}
              >
                <span>{date.getDate()}</span>
                {hasAssignment(date) && (
                  <span className={styles.assignmentDot}></span>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.calendarSide}>
        <ChevronRight
          size={20}
          onClick={() => handleNavigateCalendar(1)}
          className={styles.navArrow}
        />
      </div>
    </div>
  );
};

export default Calendar;
