import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { TransformComponent } from 'react-zoom-pan-pinch';

import classnames from 'classnames';
import { fabric } from 'fabric';

import styles from './styles.module.scss';

const EditingCanvas = ({ editingContext, resetTransform, fastContext }) => {
  const canvasRef = useRef(null);
  const cropping = editingContext.cropping;
  const [crop, setCrop] = useState({});

  const setZoomResetAction = editingContext.zoom.setAction;

  const setCanvas = editingContext.setCanvas;

  useEffect(() => {
    if (setZoomResetAction) {
      setZoomResetAction(() => () => resetTransform());
    }
  }, [resetTransform, setZoomResetAction]);

  useEffect(() => {
    if (canvasRef.current && !fastContext.isReady && fastContext.setCanvas) {
      const canvas = new fabric.Canvas(canvasRef.current, {
        selection: false,
        selectable: false,
        hoverCursor: 'default'
      });

      setCanvas(canvas);
      fastContext.setCanvas(canvas);
    }
  }, [canvasRef, fastContext, setCanvas]);

  useEffect(() => {
    setCrop(cropping.cropArea);
  }, [cropping.active, cropping.cropArea]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {cropping.active && (
          <ReactCrop
            src={cropping.imageForCropping}
            width={editingContext.canvas.width}
            height={editingContext.canvas.height}
            crop={crop}
            ruleOfThirds
            onChange={setCrop}
            onDragEnd={() => {
              cropping.setCropArea(crop);
            }}
            keepSelection
          />
        )}
        <span
          className={classnames({
            [styles.hidden]: cropping.active && cropping.imageForCropping // Todo fix to not use such flags
          })}
        >
          <TransformComponent>
            <canvas ref={canvasRef} />
          </TransformComponent>
        </span>
      </div>
    </>
  );
};

export default React.memo(EditingCanvas);
