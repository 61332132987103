import React, { useState } from 'react';

const AssignmentDialogContext = React.createContext({
  isAssignmentDialogOpened: false,
  setIsAssignmentDialogOpened: () => {},
  assignAssignmentSuccessAction: () => {},
  setAssignAssignmentSuccessAction: () => {}
});

export const AssignmentDialogContextProvider = ({ children }) => {
  const [isAssignmentDialogOpened, setIsAssignmentDialogOpened] =
    useState(false);

  const [assignAssignmentSuccessAction, setAssignAssignmentSuccessAction] =
    useState(() => () => {});

  return (
    <AssignmentDialogContext.Provider
      value={{
        isAssignmentDialogOpened,
        setIsAssignmentDialogOpened,
        assignAssignmentSuccessAction,
        setAssignAssignmentSuccessAction
      }}
    >
      {children}
    </AssignmentDialogContext.Provider>
  );
};

export default AssignmentDialogContext;
