import { useCallback, useEffect, useState } from 'react';

export const useInput = (defaultValue, onSaveCallback, onOpen, onClose) => {
  const [inputOpen, setInputOpen] = useState(false);
  const [value, setValue] = useState('');

  const toggleInput = useCallback(() => setInputOpen((prev) => !prev), []);

  useEffect(() => {
    if (inputOpen) {
      onOpen && onOpen();
    } else {
      onClose && onClose();
    }
  }, [inputOpen, onOpen, onClose]);

  useEffect(() => {
    // The initial value may change to an update (e.g. via a webhook).
    setValue(defaultValue);
  }, [defaultValue]);

  const onSave = useCallback(() => {
    onSaveCallback(value);
    toggleInput();
  }, [value, toggleInput, onSaveCallback]);

  const onCancel = useCallback(() => {
    setValue(defaultValue);
    toggleInput();
  }, [defaultValue, toggleInput]);

  const onKeyDown = useCallback(
    (event) => {
      /* 13 == Enter */
      if (event.which === 13 && !event.shiftKey) {
        onSave();
      }
      /* 27 == Escape */
      if (event.which === 27) {
        onCancel();
      }
    },
    [onSave, onCancel]
  );

  return {
    inputOpen,
    toggleInput,
    value,
    onSave,
    onCancel,
    onKeyDown,
    setValue
  };
};
