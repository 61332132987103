import MuiSwitch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

import { colors } from 'theme/palette';

const Switch = withStyles({
  switchBase: {
    color: colors.grey3,
    '&$checked': {
      color: colors.blue
    },
    '&$checked + $track': {
      backgroundColor: colors.blue
    }
  },
  checked: {},
  track: {
    backgroundColor: colors.grey5,
    opacity: 1
  }
})(MuiSwitch);

export default Switch;
