import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import _ from 'lodash';
import { useStandardsForStudentPortfolioWithFilters } from 'sdk';

import { buildFilters } from 'utils/filters';

import StandardBox from './components/StandardBox';
import NoResultsPlaceholder from 'components/NoResultsPlaceholder';
import StudentWorkCard from 'components/StudentWorkCard';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const StandardAndAssignmentsListView = ({
  filters,
  setTitle,
  setBackButtonAction,
  unsetActiveStandardTab
}) => {
  const [selectedParentId, setSelectedParentId] = useState(null);

  const { user } = useContext(UserContext);
  const studentId = user.student_id;

  const {
    data: standards,
    isLoading,
    refetch
  } = useStandardsForStudentPortfolioWithFilters({
    studentId,
    filters: buildFilters(filters)
  });

  useEffect(() => {
    // Refetch each time the parent standard changes so that we're never showing outdated info.
    if (selectedParentId) {
      refetch();
    }
  }, [selectedParentId, refetch]);

  const selectedParent = useMemo(
    () => _.find(standards, { id: selectedParentId }),
    [standards, selectedParentId]
  );

  const selectedParentName = useMemo(() => {
    if (!_.isNil(selectedParent)) {
      return (
        <>
          <Typography variant="H-TEXT-3">{selectedParent.code}</Typography>
          <div className={styles.dash}>-</div>
          <Typography variant="B-Text-2">
            {selectedParent.short_description}
          </Typography>
        </>
      );
    }

    return <Typography variant="H-TEXT-3">{filters.standard}</Typography>;
  }, [filters.standard, selectedParent]);

  const goToParentStandard = useCallback(() => {
    const grandfatherId = _.get(selectedParent, 'parent_id', 0);

    const grandfatherStandard = _.find(standards, {
      id: grandfatherId
    });

    if (grandfatherStandard) {
      setSelectedParentId(grandfatherStandard.id);
    } else if (selectedParent) {
      setSelectedParentId(null);
    } else {
      unsetActiveStandardTab();
    }
  }, [selectedParent, standards, unsetActiveStandardTab]);

  useEffect(() => {
    if (selectedParent) {
      const standardCode = selectedParent.code;

      const newTitle = `${filters.standard} > ${standardCode
        .split('.')
        .join(' > ')}`;

      setTitle(newTitle);
    } else {
      setTitle(filters.standard);
    }
  }, [selectedParent, filters.standard, setTitle]);

  useEffect(() => {
    setBackButtonAction(goToParentStandard);
  }, [goToParentStandard, setBackButtonAction]);

  const standardsToShow = useMemo(() => {
    if (_.isNil(selectedParentId)) {
      // If no parent has been selected we select all the standards with a level
      // equal to the lowest level amongst them.
      const lowestStandardLevel = _.min(_.map(standards, 'level'));

      return _.filter(standards, { level: lowestStandardLevel });
    } else {
      return _.filter(standards, { parent_id: selectedParentId });
    }
  }, [selectedParentId, standards]);

  return (
    <>
      {selectedParentName && (
        <div className={styles.divider}>{selectedParentName}</div>
      )}

      {isLoading && <StandardBox.Placeholder count={3} />}

      {!isLoading && _.isEmpty(standards) && <NoResultsPlaceholder />}

      {!isLoading && (
        <>
          {standardsToShow.map((standard) => (
            <StandardBox
              key={standard.id}
              standard={standard}
              onClick={() => setSelectedParentId(standard.id)}
            />
          ))}

          {!_.isEmpty(selectedParent?.assignments) && (
            <div className={styles.divider}>
              <Typography variant="H-TEXT-3">
                {selectedParent.nested_assignments_count} assignments
              </Typography>
            </div>
          )}

          {selectedParent?.assignments.map((assignment) => {
            // This is legacy logic - we count on the BE order of the tasks
            const assignmentWithIndexedTasks = {
              ...assignment,
              tasks: _.map(assignment.tasks, (task, index) => ({
                ...task,
                index: index
              }))
            };

            return (
              <StudentWorkCard
                key={assignment.id}
                assignment={assignmentWithIndexedTasks}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default StandardAndAssignmentsListView;
