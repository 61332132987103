import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const CreateMeetingSortCard = ({ onClick }) => (
  <Card variant="outlined" classes={{ root: styles.createSortCard }}>
    <CardActionArea
      onClick={onClick}
      component="div"
      classes={{ root: styles.createSortCardContent }}
    >
      <Typography variant="H-TEXT-3" color={colors.blue3} align="center">
        + Create Sort
      </Typography>
    </CardActionArea>
  </Card>
);

export default CreateMeetingSortCard;
