import React, { useContext, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import StudentWorkCard from 'pages/Teachers/StrategiesOverview/components/StudentWorkCard';
import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const RubricAccordion = ({
  strategy_answer,
  student_work_list,
  setIsOpen,
  setDialogRubrics,
  selectedRubric,
  setSelectedRubric,
  index,
  studentCount,
  refetchStrategiesData
}) => {
  const { user } = useContext(UserContext);

  const accordionText = {
    color: colors.blue1,
    fontFamily: 'Nunito',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '17.5px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase'
  };

  const [isAccordionExpanded, setAccordionExpanded] = useState(false);

  const toggleAccordion = (isCurrentRubric, strategy_answer) => {
    if (isCurrentRubric) {
      setAccordionExpanded(false);
      setSelectedRubric('');
    } else {
      setAccordionExpanded(!isAccordionExpanded);
      setSelectedRubric(strategy_answer);

      trackMixpanelEvent(
        user.id,
        '[StrategiesOverview] Teacher expanded rubrics accordion.',
        {
          accordion: strategy_answer
        }
      );
    }
  };

  return (
    <Accordion
      expanded={
        isAccordionExpanded ||
        selectedRubric.toLowerCase() === strategy_answer.toLowerCase()
      }
      onClick={() =>
        toggleAccordion(
          selectedRubric.toLowerCase() === strategy_answer.toLowerCase(),
          strategy_answer
        )
      }
      className={styles.accordionComponent}
      key={strategy_answer}
    >
      <AccordionSummary
        classes={{
          root: styles.accordionComponentSummary,
          expanded: styles.expanded,
          content: styles.accordionSummaryContent
        }}
        aria-controls={`panel1d-content-${index}`}
        id={`panel1d-header-${index}`}
      >
        <Typography style={accordionText}>{strategy_answer}</Typography>
        <Typography>{studentCount}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: styles.accordionDetails }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px'
          }}
        >
          {student_work_list.map(
            (
              {
                work_tag_image_id,
                assignment_tag_template_id,
                student_name,
                work_url,
                rubrics
              },
              studentIndex
            ) => (
              <div
                key={`student-name-${studentIndex}`}
                style={{
                  height: '270px',
                  width: '330px',
                  borderRadius: '5px 0'
                }}
              >
                <StudentWorkCard
                  workTagImageId={work_tag_image_id}
                  assignmentTagTemplateId={assignment_tag_template_id}
                  studentName={student_name}
                  studentWorkUrl={work_url}
                  rubrics={rubrics}
                  setRubrics={setDialogRubrics}
                  setDialogIsOpen={setIsOpen}
                  refetchStrategiesData={refetchStrategiesData}
                />
              </div>
            )
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default RubricAccordion;
