import { ReactComponent as AssignmentCompleteIcon } from './assets/assignment_complete.svg';
import { ReactComponent as NewAssignmentIcon } from './assets/new_assignment.svg';
import { ReactComponent as NewAudioFeedbackIcon } from './assets/new_audio_feedback.svg';
import { ReactComponent as NewCommentIcon } from './assets/new_comment.svg';
import { ReactComponent as NewPictureFeedbackIcon } from './assets/new_picture_feedback.svg';
import { ReactComponent as RevisionRequestedIcon } from './assets/revision_requested.svg';

export const NotificationTypes = {
  // Per assignment:
  NEW_ASSIGNMENT: 'new_assignment',
  REVISION_REQUESTED: 'revision_requested',
  COMPLETE_ASSIGNMENT: 'complete_assignment',
  // Per task:
  NEW_AUDIO_FEEDBACK: 'new_audio_fedback',
  NEW_PICTURE_FEEDBACK: 'new_picture_feedback',
  NEW_COMMENT: 'new_comment'
};

export const NotificationTypeIconAndText = {
  [NotificationTypes.NEW_ASSIGNMENT]: {
    text: 'New Assignment',
    IconComponent: NewAssignmentIcon
  },
  [NotificationTypes.REVISION_REQUESTED]: {
    text: 'Revision Requested',
    IconComponent: RevisionRequestedIcon
  },
  [NotificationTypes.COMPLETE_ASSIGNMENT]: {
    text: 'Assignment Complete',
    IconComponent: AssignmentCompleteIcon
  },
  [NotificationTypes.NEW_AUDIO_FEEDBACK]: {
    text: 'New Audio Feedback',
    IconComponent: NewAudioFeedbackIcon
  },
  [NotificationTypes.NEW_PICTURE_FEEDBACK]: {
    text: 'New Picture Feedback',
    IconComponent: NewPictureFeedbackIcon
  },
  [NotificationTypes.NEW_COMMENT]: {
    text: 'New Comment',
    IconComponent: NewCommentIcon
  }
};
