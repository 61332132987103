import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';

import styles from './styles.module.scss';

export const NextStepsTableSkeletonRow = () => {
  return (
    <TableRow>
      <TableCell className={styles.assignmentCell}>
        <Skeleton variant="text" width={'100%'} />
      </TableCell>
      <TableCell className={styles.studentCell}>
        <Skeleton variant="text" width={'100%'} />
      </TableCell>
      <TableCell className={styles.nextStepsCell}>
        <Skeleton variant="text" width={'100%'} />
      </TableCell>
      <TableCell className={styles.resourcesCell}>
        <Skeleton variant="text" width={'100%'} />
      </TableCell>
    </TableRow>
  );
};
