import React, { useState } from 'react';

import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

import { colors } from 'theme/palette';

import styles from './styles.module.scss';

const palette = [colors.pink5, colors.pink6, colors.pink7, colors.pink8];

const renderActiveShape = (props, title, isSelected, isDisabled) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={outerRadius}
        fill={isSelected ? colors.pink4 : colors.blue4}
        opacity={isDisabled ? 0.5 : 1}
      />
      <text x={cx} y={cy} dy={4} textAnchor="middle" fill={colors.white}>
        {title}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const WeeklyProgressBar = ({ day }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  let data = [
    {
      value: day.progress,
      fill: day.isSelected ? palette[0] : colors.blue3
    },
    {
      value: 4 - day.progress,
      fill: day.isSelected ? palette[2] : colors.blue9
    }
  ];

  if (day.progress === 0) {
    data = [
      {
        value: 4,
        fill: day.isSelected ? palette[2] : colors.blue9
      }
    ];
  }

  return (
    <ResponsiveContainer width="100%">
      <PieChart style={{ cursor: day.isAvailable && 'pointer' }}>
        <Pie
          className={styles.pieChart}
          activeIndex={activeIndex}
          activeShape={(props) =>
            renderActiveShape(
              props,
              day.title,
              day.isSelected,
              !day.isAvailable
            )
          }
          data={data}
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={-270}
          innerRadius={15}
          outerRadius={25}
          fill={colors.blue2}
          dataKey="value"
          onMouseEnter={onPieEnter}
          title={day.title}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default WeeklyProgressBar;
