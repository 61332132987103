import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import RestorePageRoundedIcon from '@material-ui/icons/RestorePageRounded';
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';

import { ReactComponent as aboveIcon } from './assets/above.svg';
import { ReactComponent as belowIcon } from './assets/below.svg';
import { ReactComponent as brushIcon } from './assets/brush.svg';
import { ReactComponent as checkIcon } from './assets/check.svg';
import { ReactComponent as checkMinusIcon } from './assets/check_minus.svg';
import { ReactComponent as checkPlusIcon } from './assets/check_plus.svg';
import { ReactComponent as correctIcon } from './assets/correct.svg';
import { ReactComponent as cropIcon } from './assets/crop.svg';
import { ReactComponent as exposureIcon } from './assets/exposure.svg';
import { ReactComponent as incorrectIcon } from './assets/incorrect.svg';
import { ReactComponent as meetsIcon } from './assets/meets.svg';
import { ReactComponent as partialIcon } from './assets/partial.svg';
import { ReactComponent as reviseIcon } from './assets/revise.svg';
import { ReactComponent as rotateIcon } from './assets/rotate.svg';
import { ReactComponent as smileIcon } from './assets/smile.svg';
import { ReactComponent as textIcon } from './assets/text.svg';
import { ReactComponent as zeroIcon } from './assets/zero_rubric.svg';

const Icons = {
  Brush: (props) => <SvgIcon component={brushIcon} {...props} />,
  Crop: (props) => <SvgIcon component={cropIcon} {...props} />,
  Exposure: (props) => <SvgIcon component={exposureIcon} {...props} />,
  Rotate: (props) => <SvgIcon component={rotateIcon} {...props} />,
  Text: (props) => <SvgIcon component={textIcon} {...props} />,
  CheckMinus: (props) => <SvgIcon component={checkMinusIcon} {...props} />,
  Check: (props) => <SvgIcon component={checkIcon} {...props} />,
  CheckPlus: (props) => <SvgIcon component={checkPlusIcon} {...props} />,
  Smile: (props) => <SvgIcon component={smileIcon} {...props} />,
  Audio: (props) => <SettingsVoiceIcon {...props} />,
  Correct: (props) => <SvgIcon component={correctIcon} {...props} />,
  Incorrect: (props) => <SvgIcon component={incorrectIcon} {...props} />,
  Above: (props) => <SvgIcon component={aboveIcon} {...props} />,
  Below: (props) => <SvgIcon component={belowIcon} {...props} />,
  Meets: (props) => <SvgIcon component={meetsIcon} {...props} />,
  Partial: (props) => <SvgIcon component={partialIcon} {...props} />,
  Zero: (props) => <SvgIcon component={zeroIcon} {...props} />,
  Revise: (props) => <SvgIcon component={reviseIcon} {...props} />,
  Undo: (props) => <RestorePageRoundedIcon {...props} />
};

export default Icons;
