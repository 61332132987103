import _ from 'lodash';
import * as yup from 'yup';

export const initialValues = {
  firstName: '',
  lastName: undefined,
  email: undefined,
  password: ''
};

export const validationSchema = yup.object().shape({
  firstName: yup.string().nullable(),
  lastName: yup.string(),
  email: yup.string().nullable().email(),
  password: yup
    .string()
    .min(8, 'Password should contain at least 8 characters')
    .matches('[A-Za-z]+', 'Password should contain at least one letter')
});

export const validate = (values) => {
  const errors = {};

  if (values.email !== undefined && values.password === '') {
    // Business requirement: We want to show this error only if
    // there has been anything written in the email input
    errors.password = 'Password is required';
  }

  if (values.email === '') {
    // Business requirement: There should be no error in the initial render of the email field
    errors.email = 'Email is required';
  }

  if (
    values.email !== undefined &&
    values.password !== undefined &&
    (_.isNil(values.lastName) || values.lastName === '')
  ) {
    errors.lastName = 'Last name is required';
  }

  return errors;
};
