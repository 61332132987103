import React, { useCallback, useMemo } from 'react';
import Linkify from 'react-linkify';

import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { TEACHERS_STUDENT_PORTFOLIO_URL } from 'config/urls';
import _ from 'lodash';
import moment from 'moment';
import { useStudent } from 'sdk';

import { StatusOptions } from 'pages/Students/shared';
import { TaskCard } from 'pages/Teachers/shared';
import { useNavbar } from 'pages/Teachers/shared/TeacherPageLayout';
import { colors } from 'theme/palette';
import { uploadImage } from 'utils/files';
import { openInNewTab, reverse } from 'utils/urls';

import Typography from 'components/Typography';

import { ReactComponent as FileIcon } from './assets/file.svg';
import {
  postAssignmentComment,
  updateTaskStatus,
  uploadAssignmentFeedback
} from './sdk';
import styles from './styles.module.scss';
import { useAssignment } from './utils';

const changeTaskStatus = (trackerScoreResponseId, status) => {
  return updateTaskStatus({
    trackerScoreResponseId,
    data: { status }
  }).then(() => status);
};

const uploadImageBlob = async (blob, activeWork) => {
  const feedbackFileId = await uploadImage({
    blob,
    name: 'feedback'
  });

  const data = await uploadAssignmentFeedback({
    feedback_file_id: feedbackFileId,
    original_work: activeWork.original_id || activeWork.id
  });

  return data;
};

const StyledLinearProgress = withStyles({
  root: {
    background: colors.grey5,
    borderRadius: 4,
    height: 6,
    width: 90
  },
  bar: {
    background: colors.green2
  }
})(LinearProgress);

const StudentPortfolioAssignmentDetails = ({ match, history }) => {
  const studentId = match.params.studentId;
  const assignmentId = match.params.assignmentId;

  const { data: student } = useStudent({ studentId });
  const assignmentResponse = useAssignment({ studentId, assignmentId });
  const assignment = _.get(assignmentResponse, 'data');

  useNavbar({
    title: _.get(student, 'name', 'Student'),
    onTitleClick: useCallback(() => {
      history.push(reverse(TEACHERS_STUDENT_PORTFOLIO_URL, { id: studentId }));
    }, [history, studentId])
  });

  const completeTasks = useMemo(
    () =>
      _.size(
        _.filter(
          _.get(assignment, 'tasks', []),
          ({ status }) =>
            status === StatusOptions.COMPLETE ||
            status === StatusOptions.SUBMITTED
        )
      ),
    [assignment]
  );

  return (
    <>
      <Paper className={styles.paper}>
        <div className={styles.assignmentInfo}>
          <Typography variant="H-TEXT-2" color={colors.blue2}>
            {_.get(assignment, 'name')}
          </Typography>
          <div className={styles.flexRowContainer}>
            <Typography variant="S-TEXT-1" color={colors.grey2}>
              {_.get(assignment, 'tasks', []).length} Tasks
            </Typography>
            <Typography variant="B-Text-3" color={colors.grey2}>
              Due:{' '}
              {moment(_.get(assignment, 'due_at')).format(
                'ddd MM/D [at] h:mm a'
              )}
            </Typography>
            <div className={styles.progressBarContainer}>
              <Typography variant="S-TEXT-2" color={colors.grey2}>
                {completeTasks} / {_.get(assignment, 'tasks', []).length} done
              </Typography>
              <StyledLinearProgress
                variant="determinate"
                value={
                  (completeTasks / _.size(_.get(assignment, 'tasks', []))) * 100
                }
              />
            </div>
          </div>
          <Typography variant="B-Text-2" color={colors.blue1}>
            <Linkify properties={{ target: '_blank' }}>
              {_.get(assignment, 'description')}
            </Linkify>
          </Typography>
          {!_.isEmpty(_.get(assignment, 'attachments', [])) && (
            <>
              {_.map(assignment.attachments, (attachment) => (
                <div
                  className={classnames(styles.attachment, 'pointer')}
                  key={attachment.id}
                  onClick={() => openInNewTab(attachment.file_url)}
                >
                  <FileIcon fill={colors.blue3} />
                  <Typography
                    variant="B-Text-3"
                    color={colors.blue3}
                    className={styles.attachmentName}
                  >
                    {attachment.original_filename}
                  </Typography>
                </div>
              ))}
            </>
          )}
        </div>
      </Paper>
      <div className={styles.tasksContainer}>
        {_.get(assignment, 'tasks', []).map((task, index) => (
          <TaskCard
            key={task.id}
            task={task}
            taskIndex={index + 1}
            trackerId={assignment.tracker_id}
            assignmentId={assignmentId}
            studentId={studentId}
            onTaskStatusChange={changeTaskStatus}
            onStickerAdd={uploadImageBlob}
            onCommentAdd={postAssignmentComment}
          />
        ))}
      </div>
    </>
  );
};

export default StudentPortfolioAssignmentDetails;
