import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import InputField from 'components/InputField';

const useStyles = makeStyles({
  heightFix: {
    height: '100%',
    minHeight: 40,
    '& .MuiInputBase-root': {
      minHeight: 40
    }
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottomColor: `${colors.grey4} !important`
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${colors.grey4} !important`
    },
    '& .Mui-focused.MuiInput-underline:after': {
      borderBottomColor: `${colors.grey2} !important`
    },
    '& input': {
      padding: '0 10px !important' // Mui Autocomplete sets it to '6px 0' by deafult
    }
  },
  inputHasValue: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.grey6
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: `${colors.blue4} !important`
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${colors.blue4} !important`
    }
  },
  disabled: {
    backgroundColor: `${colors.grey6} !important`
  },
  chipRoot: {
    textOverflow: 'ellipsis',
    borderRadius: 5
  },
  chipLabel: {
    maxWidth: 150
  }
});

const MultiSelectFilter = ({ placeholder, value, ...rest }) => {
  const hasValue = !_.isEmpty(value);
  const styles = useStyles();

  // The Autocomplete shows the placeholder next to the input Tags by default.
  // This fixes this strange behavior.
  placeholder = hasValue ? '' : placeholder;

  const renderInput = (params) => {
    const enhancedParams = {
      ...params,
      variant: 'underlined',
      placeholder: placeholder,
      value: value,
      className: classnames(styles.input, styles.heightFix, {
        [styles.inputHasValue]: hasValue,
        [styles.disabled]: rest.disabled
      })
    };

    return <InputField {...enhancedParams} />;
  };

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      forcePopupIcon={false}
      disableClearable={!hasValue}
      renderInput={renderInput}
      value={value}
      ChipProps={{
        classes: {
          root: styles.chipRoot,
          label: styles.chipLabel
        },
        size: 'small',
        ...rest.ChipProps
      }}
      {...rest}
    />
  );
};

export default MultiSelectFilter;
