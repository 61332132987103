import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';

import AssignmentRow from 'pages/Students/MyPortfolioIncompleteAssignmentsView/components/AssignmentRow';
import { colors } from 'theme/palette';

import NoResultsPlaceholder from 'components/NoResultsPlaceholder';
import Typography from 'components/Typography';

import { TABLE_HEADER_ROWS } from './constants';
import styles from './styles.module.scss';

const ListView = ({ assignments, studentId, loading }) => (
  <>
    {!_.isEmpty(assignments) || loading ? (
      <TableContainer
        variant="outlined"
        classes={{ root: styles.tableContainer }}
      >
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              {TABLE_HEADER_ROWS.map((name) => (
                <TableCell className={styles.headerCell} key={name}>
                  <Typography variant="H-TEXT-3" color={colors.blue1}>
                    {name}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {assignments.map((assignment) => (
              <AssignmentRow
                key={assignment.id}
                assignment={assignment}
                studentId={studentId}
              />
            ))}
            {loading && <AssignmentRow.Placeholder count={20} />}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <div className={styles.noResultsPlaceholderContainer}>
        <NoResultsPlaceholder />
      </div>
    )}
  </>
);

export default ListView;
