import _ from 'lodash';

import { formatDate } from 'utils/moment';

export const getMeetingLabel = (meeting) => {
  const meetingTopic = _.get(meeting, 'topic');

  const formattedMeetingSchedule = formatDate(
    _.get(meeting, 'scheduled_for'),
    'MM/DD/YYYY'
  );
  if (_.isEmpty(meetingTopic)) {
    return formattedMeetingSchedule;
  }
  return `${formattedMeetingSchedule} - ${meetingTopic}`;
};

export const buildMeetingsOptions = (meetings) =>
  _.map(meetings, (meeting) => {
    return {
      value: meeting.invitation_identifier,
      label: getMeetingLabel(meeting)
    };
  });

export const getMeetingTitle = (meeting) => {
  const topic = _.get(meeting, 'topic', '');
  const scheduledFor = _.get(meeting, 'scheduled_for');

  const scheduleDateFormatted = formatDate(scheduledFor);

  return _.isEmpty(topic)
    ? `Meeting date: ${scheduleDateFormatted}`
    : `${topic} - ${scheduleDateFormatted}`;
};
