import React from 'react';

import { colors } from 'theme/palette';

import ConfirmDialog from 'components/ConfirmDialog';
import InputField from 'components/InputField';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const InsightsConfirmDialog = ({
  text,
  onPreannotationFeedbackChange,
  onClose,
  onConfirm,
  disableFeedback
}) => {
  return (
    <ConfirmDialog onClose={onClose} onConfirm={onConfirm}>
      <>
        <div className={styles.confirmationHeader}>
          <Typography variant="B-Text-1" color={colors.grey1} align="center">
            {text}
          </Typography>
        </div>
        {!disableFeedback && (
          <>
            <Typography variant="H-TEXT-3" color={colors.grey1}>
              provide details (optional):
            </Typography>
            <InputField
              multiline={true}
              classes={{ root: styles.textAreaRoot }}
              minRows={3}
              onChange={(e) =>
                onPreannotationFeedbackChange({ newFeedback: e.target.value })
              }
              inputProps={{ maxLength: 200 }}
            />
          </>
        )}
      </>
    </ConfirmDialog>
  );
};

export default InsightsConfirmDialog;
