import React, { createRef, useCallback, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import classnames from 'classnames';
import { ReactComponent as MinusIcon } from 'images/minusicon.svg';
import { ReactComponent as PlusIcon } from 'images/plusicon.svg';
import _ from 'lodash';

import { STANDARD_MSA } from 'utils/constants';
import { MSA_LABEL_MAPPING } from 'utils/constants';
import { useDialog } from 'utils/hooks';
import { useBreakpoints } from 'utils/resizing';
import { getStandardSiblings } from 'utils/standards';

import ScrollableAssignmentsList from './components/ScrollableAssignmentsList';
import StandardDetailDialog from 'components/StandardDetailDialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Placeholder = ({ count = 1 }) =>
  _.range(count).map((index) => (
    <div className={styles.standardNodeContainer} key={index}>
      <div
        className={classnames(styles.iconContainer, {
          [styles.rootWithChildrenAndNoSiblings]: true,
          [styles.lastChild]: true
        })}
        style={{
          height: '38px',
          '--before-width': '33px',
          '--before-height': '40px'
        }}
      >
        <MinusIcon />
      </div>
      <div
        className={styles.standardNode}
        style={{ width: `calc(100% -  31}px)` }}
      >
        <div
          className={classnames(styles.standardBox, {
            [styles.lastChildAndHasChildren]: true
          })}
        >
          <Box display="flex" alignItems="center" flex={1}>
            <Skeleton width="10%" /> - <Skeleton width="20%" />
          </Box>

          <Box display="flex" alignItems="center">
            <Skeleton width="50px" />
          </Box>
        </div>
      </div>
    </div>
  ));

const StandardNode = ({
  standard,
  standards,
  opened,
  openStandard,
  closeStandard,
  openStandardAndAllChildren,
  parentOnLevelIsOnlyChildOrLastChild,
  student,
  forStudents = false
}) => {
  const {
    isOpened: isStandardDetailDialogOpened,
    openDialog: openStandardDetailDialog,
    closeDialog: closeStandardDetailDialog
  } = useDialog();

  const standardBoxRef = createRef();
  const standardNodeRef = createRef();

  const [doubleClickTimeout, setDoubleClickTimeout] = useState(0);
  const [standardNodeHeight, setStandardNodeHeight] = useState(0);

  const [standardBoxHeight, setStandardBoxHeight] = useState(0);

  const updateStandardNodeHeight = useCallback(() => {
    setStandardNodeHeight(_.get(standardNodeRef, 'current.clientHeight', 0));
  }, [standardNodeRef]);

  const updateStandardBoxHeight = useCallback(() => {
    setStandardBoxHeight(_.get(standardBoxRef, 'current.clientHeight', 0));
  }, [standardBoxRef]);

  useEffect(() => {
    updateStandardNodeHeight();
  }, [updateStandardNodeHeight]);

  useEffect(() => {
    updateStandardBoxHeight();
  }, [updateStandardBoxHeight]);

  useEffect(() => {
    window.addEventListener('resize', updateStandardNodeHeight);

    return () => window.removeEventListener('resize', updateStandardNodeHeight);
  }, [updateStandardNodeHeight]);

  useEffect(() => {
    window.addEventListener('resize', updateStandardBoxHeight);

    return () => window.removeEventListener('resize', updateStandardBoxHeight);
  }, [updateStandardBoxHeight]);

  const postHeightChangeAction = useCallback(() => {
    // Used so we can recalculte heights when an assignment comments has its comments expanded.

    updateStandardNodeHeight();
    updateStandardBoxHeight();
  }, [updateStandardBoxHeight, updateStandardNodeHeight]);

  const isTablet = useBreakpoints({
    tablet: 992,
    mobile: 767
  })[1];

  const isFullDesktop = useBreakpoints({
    tablet: 1279,
    mobile: 993
  })[0];

  const shortDescription = _.get(standard, 'short_description', '');

  const parent = _.find(standards, {
    id: standard.parent_id
  });

  const siblings = getStandardSiblings({ standard, standards });

  const siblingsCount = siblings.length;

  const isLastChild =
    siblingsCount === 0 ||
    (siblingsCount !== 0 &&
      _.get(_.last(parent?.direct_children), 'id') === standard.id);

  const hideIcon =
    !_.isEmpty(standard.assignments) && _.isEmpty(standard.direct_children);

  const lastChildAndHasChildren =
    isLastChild && !_.isEmpty(standard.direct_children);

  const isRoot = _.first(standards).id === standard.id;
  const isRootWithNoChildrenAndHasSiblings =
    isRoot && _.isEmpty(standard.direct_children) && !isLastChild;

  const standardsCount = standards.length;

  const isRootWithNoChildrenAndNoSiblings = standardsCount === 1;

  const isRootWithChildren = isRoot && !_.isEmpty(standard.direct_children);

  // The leaf (very last) child in a tree branch
  const isLeafChild =
    !isRootWithChildren && isLastChild && _.isEmpty(standard.direct_children);

  const isRootWithChildrenAndNoSiblings =
    isRoot && !_.isEmpty(standard.direct_children) && siblingsCount === 0;

  const rootWithChildrenAndHasSiblings =
    isRoot && !_.isEmpty(standard.direct_children) && siblingsCount !== 0;

  const hasChildren = !_.isEmpty(standard.direct_children);

  const standardsMinLevel = _.min(_.map(standards, 'level'));

  const lastRootStandard = _.last(
    _.filter(standards, { level: standardsMinLevel })
  );

  const isLastRootStandard = !isRoot && lastRootStandard.id === standard.id;

  const hasNoChildren = _.isEmpty(standard.direct_children);

  const toggleStandard = () => {
    if (opened) {
      closeStandard({ standard });
    } else {
      openStandard({ standard });
    }
  };

  const handleStandardClick = () => {
    if (doubleClickTimeout) {
      if (!opened) {
        openStandardAndAllChildren({ standard });
        clearTimeout(doubleClickTimeout);
        setDoubleClickTimeout(0);
      } else {
        toggleStandard();
      }
    } else {
      const timeout = setTimeout(() => {
        toggleStandard();
        setDoubleClickTimeout(0);
      }, 200);

      setDoubleClickTimeout(timeout);
    }
  };

  useEffect(() => {
    // Clear the timeout on unmount
    return () => clearTimeout(doubleClickTimeout);
  }, [doubleClickTimeout]);

  const sidebarWidth = forStudents ? 0 : 90;

  return (
    <>
      <div className={styles.standardNodeContainer}>
        <div className={styles.leftBorderContainer}>
          {_.range(1, standard.level).map((border, index) => (
            <div
              key={index}
              className={classnames(styles.leftBorder, {
                [styles.transparent]: parentOnLevelIsOnlyChildOrLastChild({
                  level: border,
                  standard: standard
                })
              })}
            ></div>
          ))}
        </div>
        <div
          className={classnames(styles.iconContainer, {
            [styles.rootWithNoChildrenAndHasSiblings]:
              isRootWithNoChildrenAndHasSiblings,
            [styles.rootWithNoChildrenAndNoSiblings]:
              isRootWithNoChildrenAndNoSiblings,
            [styles.rootWithChildrenAndHasSiblings]:
              rootWithChildrenAndHasSiblings,
            [styles.rootWithChildrenAndNoSiblings]:
              isRootWithChildrenAndNoSiblings,
            [styles.lastRootStandard]: isLastRootStandard,
            [styles.lastChild]: isLastChild,
            [styles.leafChild]: isLeafChild
          })}
          style={{
            height: `${standardBoxHeight}px`,
            '--before-width': `${standardBoxHeight / 2 + 12 + 2}px`,
            '--before-height': `${standardNodeHeight}px`
          }}
          onClick={handleStandardClick}
        >
          {!hideIcon && opened ? (
            <MinusIcon />
          ) : !hideIcon && !opened ? (
            <PlusIcon />
          ) : null}
        </div>
        <div
          className={styles.standardNode}
          style={{ width: `calc(100% - ${(standard.level - 1) * 21 + 31}px)` }}
          ref={standardNodeRef}
        >
          <div
            className={classnames(styles.standardBox, {
              [styles.lastChildAndHasChildren]: lastChildAndHasChildren,
              [styles.rootWithNoChildrenAndNoSiblings]:
                isRootWithNoChildrenAndNoSiblings,
              [styles.hasNoChildren]: hasNoChildren
            })}
            onClick={handleStandardClick}
            ref={standardBoxRef}
          >
            <Box display="flex" alignItems="center" flex={1}>
              <Typography
                variant={isTablet ? 'S-TEXT-2' : 'S-TEXT-1'}
                className={styles.standardCode}
                onClick={(event) => {
                  event.stopPropagation();
                  openStandardDetailDialog();
                }}
              >
                {standard.code}
              </Typography>

              {!_.isEmpty(shortDescription) && (
                <>
                  <Typography className={styles.standardDescription}>
                    -
                  </Typography>
                  <Box flex={1}>
                    <Typography
                      variant={isTablet ? 'B-TEXT-2' : 'B-TEXT-1'}
                      className={styles.standardDescription}
                    >
                      {shortDescription}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>

            <Box display="flex" alignItems="center">
              {standard.msa && (
                <div
                  className={classnames(styles.standardMsaWrapper, {
                    [styles.major]: standard.msa === STANDARD_MSA.MAJOR,
                    [styles.supporting]:
                      standard.msa === STANDARD_MSA.SUPPORTING,
                    [styles.additional]:
                      standard.msa === STANDARD_MSA.ADDITIONAL
                  })}
                >
                  <Typography variant="S-TEXT-1">
                    {MSA_LABEL_MAPPING[standard.msa]}
                  </Typography>
                </div>
              )}

              <Typography
                variant={isTablet ? 'B-TEXT-2' : 'H-TEXT-2'}
                className={styles.standardChildrenCount}
              >
                {standard.nested_assignments_count}
              </Typography>
            </Box>
          </div>

          {!_.isEmpty(standard.assignments) && opened && (
            <div
              className={classnames(styles.assignmentsContainer, {
                [styles.lastChildAndHasChildren]: lastChildAndHasChildren,
                [styles.hasChildren]: hasChildren
              })}
              style={{
                width: isTablet
                  ? `calc(100vw - ${(standard.level - 1) * 21 + 31 + 10}px)`
                  : isFullDesktop
                  ? `calc(100vw - ${
                      (standard.level - 1) * 21 + 31 + 20 + sidebarWidth
                    }px)`
                  : `calc(100vw - ${
                      (standard.level - 1) * 21 + 31 + 20 + 20
                    }px)`
              }}
            >
              <ScrollableAssignmentsList
                forStudents={forStudents}
                assignments={standard.assignments}
                student={student}
                standardId={standard.id}
                postHeightChangeAction={postHeightChangeAction}
              />
            </div>
          )}
        </div>
      </div>
      {isStandardDetailDialogOpened && (
        <StandardDetailDialog
          forStudents={forStudents}
          standardId={standard.id}
          onClose={closeStandardDetailDialog}
        />
      )}
    </>
  );
};

StandardNode.Placeholder = Placeholder;

export default StandardNode;
