import React from 'react';

import classnames from 'classnames';
import _ from 'lodash';

import Typography from 'components/Typography';

import styles from './styles.module.scss';
import { MIN_BAR_WIDTH, getStackedBarPercents, toPercent } from './utils';

// size = 'small' | 'medium' | 'big'
const ProgressBar = ({
  size = 'medium',
  width,
  value,
  total,
  color,
  className
}) => (
  <div
    style={{ width }}
    className={classnames(styles.wrapper, styles[size], className)}
  >
    <div
      style={{
        width: `${toPercent(value, total)}%`,
        minWidth: `${MIN_BAR_WIDTH}%`, // Make sure there is something visible when there is zero value for the bar
        background: color
      }}
      className={styles.progress}
    />
  </div>
);

export const StackedProgressBar = ({
  width,
  values,
  colors,
  className,
  showValues = true
}) => {
  const data = _.zip(values, colors);
  const filteredData = _.filter(data, ([val]) => !_.isNil(val) && val !== 0);

  const percents = getStackedBarPercents(_.map(filteredData, ([val]) => val));
  const minPercent = _.min(percents);

  return (
    <div
      style={{ width }}
      className={classnames(styles.wrapper, styles.big, className)}
    >
      {_.map(filteredData, ([value, color], index) => {
        let width = `${percents[index]}%`;

        if (index > 0) {
          // The negative margin of the non-first bars is 20px. We add it to the width in order to preserve the right proportions
          width = `calc(${width} + 20px)`;
        }

        return (
          <div
            key={index}
            className={styles.progress}
            style={{
              width,
              minWidth: minPercent,
              background: color,
              zIndex: _.size(filteredData) - index // The progress bars should be over their right sibling
            }}
          >
            {showValues && (
              <Typography variant="S-TEXT-1" color={colors.grey1}>
                {value}
              </Typography>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressBar;
