import React, { useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { updateFamilyMember, useFamilyMemberDetails } from 'sdk';

import familyLogo from 'pages/Family/assets/family-logo.svg';
import StatusIndicator from 'pages/Family/components/StatusIndicator';
import { notifyError, notifySuccess } from 'utils/notifications';

import styles from './styles.module.scss';

const UpdateProfile = ({ students = {}, firstTimeLogin = false, onSubmit }) => {
  const { data: familyMember, isLoading: isLoadingData } =
    useFamilyMemberDetails();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [relationships, setRelationships] = useState({});

  useEffect(() => {
    if (familyMember) {
      setFirstName(familyMember.first_name || '');
      setLastName(familyMember.last_name || '');
    }
  }, [familyMember]);

  useEffect(() => {
    const initialRelationships = Object.values(students).reduce(
      (acc, student) => ({
        ...acc,
        [student.id]: student.relationship || ''
      }),
      {}
    );
    setRelationships(initialRelationships);
  }, [students]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { success } = await updateFamilyMember({
      data: {
        first_name: firstName,
        last_name: lastName,
        relationships: relationships
      }
    });

    if (success) {
      notifySuccess('Profile updated successfully');
      onSubmit();
    } else {
      notifyError('Failed to update profile');
    }
  };

  const handleRelationshipChange = (studentId, value) => {
    setRelationships((prev) => ({ ...prev, [studentId]: value }));
  };

  if (isLoadingData) {
    return <StatusIndicator statusText={'Loading your profile...'} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img
          className={styles.familyLogo}
          src={familyLogo}
          alt="EdLight Logo"
        />
      </div>

      <div className={styles.iconContainer}>
        <IconButton aria-label="Account" size="medium" className="profile">
          <AccountCircleIcon color="primary" fontSize="large" />
        </IconButton>
      </div>

      <p className={styles.instruction}>
        {firstTimeLogin
          ? 'Before we get started, tell us a little bit about yourself!'
          : 'Update Profile'}
      </p>

      <div className={styles.formBox}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.inputGroup}>
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>

          <div className={styles.inputGroup}>
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>

          {Object.values(students).map(
            (student) =>
              student && (
                <div key={student.id} className={styles.inputGroup}>
                  <label htmlFor={`relationship-${student.id}`}>
                    Relationship to {student.first_name} {student.last_name}
                  </label>
                  <select
                    id={`relationship-${student.id}`}
                    value={relationships[student.id] || ''}
                    onChange={(e) =>
                      handleRelationshipChange(student.id, e.target.value)
                    }
                    required
                  >
                    <option value="">Select Relationship</option>
                    <option value="father">Father</option>
                    <option value="mother">Mother</option>
                    <option value="guardian">Guardian</option>
                    <option value="grandparent">Grandparent</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              )
          )}

          <button type="submit" className={styles.submitButton}>
            {firstTimeLogin ? 'Create Profile' : 'Update Profile'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateProfile;
