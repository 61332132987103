import React from 'react';

import styles from './styles.module.scss';

const ConfirmStudents = ({ selectedStudentNames, onConfirm, onCancel }) => {
  if (!selectedStudentNames || selectedStudentNames.length === 0) {
    return (
      <div className={styles.boxOverlay}>
        <div className={styles.boxContent}>
          <h3>Please select your student(s) to continue</h3>
          <div className={styles.boxActions}>
            <button className={styles.cancelButton} onClick={onCancel}>
              Select Students(s)
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.boxOverlay}>
      <div className={styles.boxContent}>
        <h3>Confirm Student(s)</h3>
        <p>
          Are <b>{selectedStudentNames.join(' and ')}</b> your student(s)?
        </p>
        <div className={styles.boxActions}>
          <button className={styles.cancelButton} onClick={onCancel}>
            No
          </button>
          <button className={styles.confirmButton} onClick={onConfirm}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStudents;
