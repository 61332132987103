import React from 'react';
import { useHistory } from 'react-router-dom';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import { STUDENTS_ASSIGNMENTS_OVERVIEW_URL } from 'config/urls';
import _ from 'lodash';
import moment from 'moment';

import { colors } from 'theme/palette';
import { AssignmentStatusOptions } from 'utils/constants';
import { reverse, stringifyParams } from 'utils/urls';

import AssignmentStatusIcon from 'components/AssignmentStatusIcon';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Placeholder = ({ count = 1 }) =>
  _.range(count).map((index) => (
    <TableRow key={index}>
      <TableCell width="23%">
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton
          variant="circle"
          width={24}
          height={24}
          style={{ margin: 'auto' }}
        />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
    </TableRow>
  ));

const AssignmentRow = ({ assignment }) => {
  const history = useHistory();

  const tasksCount = assignment.tasks.length;

  const assignedTasksCount = _.filter(assignment.tasks, {
    status: AssignmentStatusOptions.ASSIGNED
  }).length;

  const { icon: Icon, color } = _.get(
    AssignmentStatusIcon,
    assignment.overall_status
  );

  const redirectToAssignmentOverview = () => {
    const link = {
      pathname: reverse(STUDENTS_ASSIGNMENTS_OVERVIEW_URL, {
        assignmentId: assignment.id
      }),
      search: stringifyParams({
        backUrl: history.location.pathname
      })
    };

    history.push(link);
  };

  return (
    <TableRow
      hover
      onClick={redirectToAssignmentOverview}
      className={styles.row}
    >
      <TableCell className={styles.cell}>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {assignment.name}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography variant="B-Text-2" color={colors.grey2}>
          {moment(assignment.due_at).format('ddd MM/DD')}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography variant="B-Text-2" color={color}>
          <Icon fill={color} />
        </Typography>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {tasksCount - assignedTasksCount} / {tasksCount}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography variant="B-Text-2" color={colors.grey1}>
          {assignment.complete_tasks_count} / {tasksCount}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

AssignmentRow.Placeholder = Placeholder;

export default AssignmentRow;
