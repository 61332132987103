import React, { useContext, useEffect } from 'react';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as LoaderImage } from './assets/loader.svg';
import styles from './styles.module.scss';

const EmptyDataState = () => {
  const user = useContext(UserContext);

  useEffect(() => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.PAGE} ${MixpanelAction.VIEW}`,
      {
        feature: 'insights',
        view: 'breather'
      }
    );
  }, [user]);

  return (
    <div className={`${styles.loaderContainer} ${styles.bounceAnimation}`}>
      <div className={styles.imageContainer}>
        <LoaderImage />
        <div
          className={`${styles.dotsContainer} ${styles.loading} ${styles.bounceAnimation}`}
        >
          <div className={`${styles.dot} forward-animation`}></div>
          <div className={`${styles.dot} forward-animation`}></div>
          <div className={`${styles.dot} forward-animation`}></div>
        </div>
      </div>
      <Typography variant="H-TEXT-1" className={styles.text}>
        COME BACK SOON...
      </Typography>
    </div>
  );
};

export default EmptyDataState;
