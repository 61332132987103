export const MuiTableCell = (palette) => ({
  sizeSmall: {
    height: 40,
    paddingLeft: 16,
    paddingRight: 16
  },
  head: {
    backgroundColor: palette.common.colors.background
  }
});
