import React from 'react';

import classnames from 'classnames';

import { colors } from 'theme/palette';
import { STUDENT_PORTFOLIO_STANDARD_TABS } from 'utils/constants';

import { ReactComponent as ELAIcon } from './assets/ela.svg';
import { ReactComponent as MATHIcon } from './assets/math.svg';
import styles from './styles.module.scss';

const StandardTabs = ({ activeTab, setActiveTab }) => (
  <div className={styles.tabsWrapper}>
    <div
      onClick={() => setActiveTab(STUDENT_PORTFOLIO_STANDARD_TABS.MATH)}
      className={classnames(styles.tab, {
        [styles.activeTab]: activeTab === STUDENT_PORTFOLIO_STANDARD_TABS.MATH
      })}
    >
      <MATHIcon
        fill={
          activeTab === STUDENT_PORTFOLIO_STANDARD_TABS.MATH
            ? colors.pink1
            : colors.grey3
        }
      />
      MATH
    </div>
    <div
      onClick={() => setActiveTab(STUDENT_PORTFOLIO_STANDARD_TABS.ELA)}
      className={classnames(styles.tab, {
        [styles.activeTab]: activeTab === STUDENT_PORTFOLIO_STANDARD_TABS.ELA
      })}
    >
      <ELAIcon
        fill={
          activeTab === STUDENT_PORTFOLIO_STANDARD_TABS.ELA
            ? colors.pink1
            : colors.grey3
        }
      />
      ELA
    </div>
    <div
      onClick={() => setActiveTab(STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS)}
      className={classnames(styles.tab, {
        [styles.activeTab]:
          activeTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS
      })}
    >
      NO STANDARDS
    </div>
  </div>
);

export default StandardTabs;
