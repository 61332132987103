import { isEmpty, isEqual, isNull, uniqWith } from 'lodash';

import { OVERALL_RESPONSE_LABEL } from './constants';

// Splits a single string label into multiple lines based on a specified maximum line length.
export const splitLabelIntoMultiline = (label, maxLineLength) => {
  const chunks = [];

  const labelParts = label.split(/[, ]+/);
  labelParts.forEach((labelPart) => {
    if (isEmpty(chunks)) chunks.push(labelPart);
    else {
      const lastElement = chunks.pop();
      const newElement = `${lastElement} ${labelPart}`;

      if (newElement.length <= maxLineLength) {
        chunks.push(newElement);
      } else {
        chunks.push(lastElement);
        chunks.push(labelPart);
      }
    }
  });

  return chunks;
};

// This plugin fixes bugs related to the tooltip being shown in certain conditions
// For example when scrolling and when the cursor is not on bar element
export const hideTooltipWhenNotHoveringOnBarElementPlugin = {
  id: 'mouseMoveTooltipHideEventCatcher',
  beforeInit(chart, args) {
    // Save the original external tooltip handler to ensure that the tooltip behavior remains consistent
    this.originalTooltipHandler =
      chart.config.options.plugins?.tooltip?.external;
  },
  beforeEvent(chart, args) {
    // https://www.chartjs.org/docs/latest/configuration/interactions.html
    const event = args.event;
    if (event.type === 'mousemove') {
      const selectedElements = chart.getElementsAtEventForMode(
        event,
        'nearest',
        { intersect: true },
        false
      );
      const tooltipElement = document.getElementById('chartjs-tooltip');
      if (selectedElements.length === 0 && tooltipElement) {
        // hide the tooltip
        document.getElementById('chartjs-tooltip').style.opacity = 0;
      }
    } else if (event.type === 'click') {
      // hide the tooltip
      document.getElementById('chartjs-tooltip')?.remove();

      // disable the external tooltip handler for 1 second
      chart.config.options.plugins.tooltip.external = null;
      setTimeout(() => {
        // Restore the original external tooltip handler 1 second after the click
        chart.config.options.plugins.tooltip.external =
          this.originalTooltipHandler;
        chart.update();
      }, 1000);
    }
  }
};

export const loadDashboardFilterData = (
  sectionsData,
  schoolFilter,
  sectionFilter,
  userFilter
) => {
  const schools = [];
  const sections = [];
  const users = [];

  sectionsData.forEach((section) => {
    const schoolForSection = section.school;
    const usersForSection = section.users;

    const passingSectionFilter =
      isNull(sectionFilter) ||
      (!isNull(sectionFilter) && section.id === sectionFilter.id);
    const passingSchoolFilter =
      isNull(schoolFilter) ||
      (!isNull(schoolFilter) && schoolForSection.id === schoolFilter.id);

    const passingUserFilter =
      isNull(userFilter) ||
      (!isNull(userFilter) &&
        usersForSection.some(
          (user) =>
            user.id === userFilter.id &&
            user.first_name === userFilter.first_name &&
            user.last_name === userFilter.last_name
        ));

    if (passingUserFilter && passingSectionFilter) {
      schools.push({ id: schoolForSection.id, name: schoolForSection.name });
    }

    if (passingUserFilter && passingSchoolFilter) {
      sections.push({ id: section.id, name: section.name });
    }

    if (passingSchoolFilter && passingSectionFilter) {
      usersForSection.forEach((user) => {
        users.push({
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name
        });
      });
    }
  });

  const uniqueSchools = uniqWith(schools, isEqual);
  const uniqueSections = uniqWith(sections, isEqual);
  const uniqueUsers = uniqWith(users, isEqual);

  return [uniqueSchools, uniqueSections, uniqueUsers];
};

/**
 * Retrieves the top three strategy labels with the highest total counts.
 * Filters out strategies with "N/A - No evidence of strategy" data counts.
 */
export function getTopThreeStrategyLabels(data) {
  if (data?.strategies?.length === 0) {
    return 'No results';
  }

  const filteredStrategies = data?.strategies
    ?.filter((strategy) => strategy.label !== OVERALL_RESPONSE_LABEL)
    ?.map((strategy) => ({
      label: strategy.label,
      data: strategy.data.filter(
        (item) => item.mastery_level !== 'N/A - No evidence of strategy'
      )
    }));

  // Check if any strategy has data counts greater than 0, excluding "N/A - No evidence of strategy"
  const hasNonEmptyData = filteredStrategies?.some((strategy) =>
    strategy.data.some((item) => item.count > 0)
  );

  if (!hasNonEmptyData) {
    return 'No results';
  }

  const totalCounts = {};
  filteredStrategies.forEach((strategy) => {
    strategy.data.forEach((item) => {
      totalCounts[strategy.label] =
        (totalCounts[strategy.label] || 0) + item.count;
    });
  });

  const sortedStrategyLabels = Object.keys(totalCounts).sort(
    (a, b) => totalCounts[b] - totalCounts[a]
  );

  const topStrategyLabels = sortedStrategyLabels.slice(
    0,
    Math.min(sortedStrategyLabels.length, 3)
  );

  return topStrategyLabels;
}

/**
 * Retrieves the top three misconception labels with the highest counts.
 * If there are no misconceptions with counts greater than 0, it returns "No results".
 */
export function getTopThreeMisconceptionLabels(data) {
  const misconceptions = data?.misconceptions;

  // Filter out misconceptions with "N/A - No misconception present"
  const filteredMisconceptions = misconceptions?.filter(
    (item) =>
      item.category !== 'N/A - No misconception present' && item.count > 0
  );

  // If there are no misconceptions with counts greater than 0, return "No results"
  if (filteredMisconceptions?.length === 0) {
    return 'No results';
  }

  const sortedMisconceptions = filteredMisconceptions?.sort(
    (a, b) => b.count - a.count
  );

  const topMisconceptionLabels = sortedMisconceptions
    ?.slice(0, 3)
    .map((item) => item.category);

  return topMisconceptionLabels;
}

export const SHORT_MASTERY_LEVEL_NAMES = [
  'N/A',
  'Got it',
  'Almost There',
  'Great'
];

//This utility function shortens long mastery level labels into more concise representations.
export const shortenMasteryLevel = (longName) => {
  return {
    'N/A - No evidence of strategy': 'N/A',
    'Significant errors in work': 'Got it',
    'General conceptual understanding but work may be insufficient or contain some errors':
      'Almost There',
    'Work is complete and correct': 'Great'
  }[longName];
};
