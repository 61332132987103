import React, { useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';

import { SearchField } from 'pages/Teachers/shared';
import { useStandards } from 'pages/Teachers/shared/hooks';
import { getStandardOptionLabel } from 'pages/Teachers/shared/utils';
import { colors } from 'theme/palette';
import { useBreakpoints } from 'utils/resizing';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const StandardDialog = ({
  onClose,
  onSave,
  selectedItems = [],
  saveButtonLabel = 'Save'
}) => {
  const [selectedStandards, setSelectedStandards] = useState(selectedItems);

  const selectedStandardIds = useMemo(
    () => _.map(selectedStandards, 'id'),
    [selectedStandards]
  );

  const { standards, setStandardSearchValue, standardsLoading } =
    useStandards(selectedStandardIds);

  const [, , isMobile] = useBreakpoints({
    tablet: 992,
    mobile: 767
  });

  const debouncedSetStandardSearchValue = _.debounce(
    (event) => setStandardSearchValue(event.target.value),
    300
  );

  const onStandardInputChange = (event) => {
    event.persist();

    debouncedSetStandardSearchValue(event);
  };

  const onOptionChange = (event, values) => {
    setSelectedStandards(values);
  };

  const minStandardLevel = _.min(_.map(standards, 'level'));

  let searchPlaceholder = '';

  if (_.isEmpty(selectedStandards)) {
    searchPlaceholder = isMobile
      ? 'Search Standards'
      : 'Search Standards (e.g., RL.7.2)';
  }

  return (
    <Dialog
      open
      alignTop
      maxWidth="md"
      fullWidth
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <Box display="flex" justifyContent="flex-end" marginBottom="30px">
        <Button
          color="pink"
          className={styles.actionButtons}
          onClick={() => {
            onSave({ selectedStandards });
            onClose();
          }}
        >
          {saveButtonLabel}
        </Button>
      </Box>

      <Box>
        <SearchField
          multiple
          autoFocus
          autoHighlight
          options={standards}
          defaultValue={selectedItems}
          getOptionLabel={(option) =>
            getStandardOptionLabel({ standard: option })
          }
          className={styles.searchField}
          disableClearable
          onInputChange={onStandardInputChange}
          onChange={onOptionChange}
          getOptionSelected={(option, value) => option.id === value.id} // The options we're passing are list of objects. We need to define the equiality function here.
          loading={standardsLoading}
          noOptionsText="No standards"
          placeholder={searchPlaceholder}
          PopperComponent={({ children }) => <div>{children}</div>} // Setting the PopperComponent to be a div instead of the mui Popper, so that we can render the options in the modal body
          PaperComponent={({ children }) => <div>{children}</div>} // Setting the PaperComponent to be a div instead of the mui Paper, so that we do not have an outline in the options menu
          renderTags={(selectedTags, getTagProps) =>
            selectedTags.map((tag, index) => {
              const { onDelete } = getTagProps({ index });

              return (
                <Chip
                  size="small"
                  onDelete={onDelete}
                  key={index}
                  classes={{ root: styles.selectedStandard }}
                  deleteIcon={<CloseIcon className={styles.blueIcon} />}
                  label={
                    <Typography variant="B-Text-3" color={colors.blue3}>
                      <strong>{_.get(tag, 'code', 'short-description')}</strong>
                    </Typography>
                  }
                ></Chip>
              );
            })
          }
          renderOption={(option) => {
            const standardPaddingLeft =
              (option.level - minStandardLevel + 1) * 10;

            return (
              <Typography
                variant="B-Text-2"
                color={colors.grey2}
                style={{
                  paddingLeft: `${standardPaddingLeft}px`
                }}
              >
                {getStandardOptionLabel({ standard: option })}
              </Typography>
            );
          }}
          classes={{ option: styles.option }}
        />
      </Box>
    </Dialog>
  );
};

export default StandardDialog;
