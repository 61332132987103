import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import styles from './styles.module.scss';

// This is a custom button that opens an export in a new tab
const ExportButton = ({ handleClick }) => {
  return (
    <IconButton className={styles.exportButton} onClick={handleClick}>
      <GetAppRoundedIcon fontSize="large" />
    </IconButton>
  );
};

export default ExportButton;
