import React from 'react';

import Grid from '@material-ui/core/Grid';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import _ from 'lodash';

import { Typography } from 'pages/Students/shared';
import { colors } from 'theme/palette';

import styles from './styles.module.scss';

const Chip = ({ label, onDelete }) => (
  <div className={styles.chip}>
    <Typography variant="S-TEXT-4" color={colors.grey1}>
      {label}
    </Typography>
    <CloseRoundedIcon onClick={onDelete} classes={{ root: styles.chipIcon }} />
  </div>
);

const Filters = ({
  filters,
  removeClassFilter,
  removeAssignmentNameFilter
}) => (
  <div className={styles.filtersContainer}>
    <Grid
      container
      alignItems="center"
      spacing={2}
      className={styles.filterGrid}
    >
      <Grid item>
        <Typography variant="S-TEXT-1" color={colors.blue4}>
          Filters
        </Typography>
      </Grid>
      {!_.isEmpty(filters.sections) && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="S-TEXT-3" color={colors.grey1}>
                Class
              </Typography>
            </Grid>
            {_.map(filters.sections, (section) => (
              <Grid item key={section.id}>
                <Chip
                  label={section.name}
                  onDelete={() => {
                    removeClassFilter(section);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {!_.isEmpty(filters.assignmentName) && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="S-TEXT-3" color={colors.grey1}>
                Name
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                label={filters.assignmentName}
                onDelete={removeAssignmentNameFilter}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  </div>
);

export default Filters;
