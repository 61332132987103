import { useEffect, useMemo, useReducer } from 'react';

import _ from 'lodash';
import { identity } from 'utils';

import { fetchStudentAssignmentDetail, fetchStudentDetails } from './sdk';

const fetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, data: action.data, loading: false };
    case 'FETCH_FAILURE':
      return { ...state, loading: false };
    default:
      return state;
  }
};

const useFetch = (sdk, initialState, id, mapResponse = identity) => {
  const [data, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    let didCancel = false;

    dispatch({ type: 'FETCH' });
    sdk(id)
      .then((response) => {
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', data: mapResponse(response) });
        }
      })
      .catch(() => {
        if (!didCancel) {
          // this won't fire because of a bad implementation of the sdk sometimes
          dispatch({ type: 'FETCH_FAILURE' });
        }
      });

    return () => {
      didCancel = true;
    };
  }, [id, sdk, mapResponse]);

  return data;
};

export const useAssignment = ({ assignmentId, studentId }) => {
  const params = useMemo(
    () => ({ assignmentId, studentId }),
    [assignmentId, studentId]
  );

  const assignment = useFetch(fetchStudentAssignmentDetail, undefined, params);

  return assignment;
};

export const useStudent = (id) => {
  const student = useFetch(fetchStudentDetails, undefined, id);

  return _.get(student, 'data');
};
