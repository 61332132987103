import _ from 'lodash';

export const MIN_BAR_WIDTH = 6;

export const toPercent = (value, total) => (100 * value) / total;

export const getStackedBarPercents = (values) => {
  // We transform the provided values to percents from the whole sum.
  // If there are percents that are lower than the min bar width, we replace them with the min bar width.
  // After that we recalculate the rest of the percents in order to preserve the 100% ratio.

  let percents = _.map(values, (val) => toPercent(val, _.sum(values)));
  let minPercent = _.min(percents);

  if (minPercent < MIN_BAR_WIDTH) {
    minPercent = MIN_BAR_WIDTH;

    let percentsToBeChanged = 0;

    const replacedSmallPercents = _.map(percents, (p) => {
      if (p < minPercent) {
        return minPercent;
      }
      percentsToBeChanged += 1;
      return p;
    });

    if (percentsToBeChanged === 0) {
      // This means that all given values are too small.
      // We cannot recalculate the proportons in this case
      return percents;
    }

    const offset = (_.sum(replacedSmallPercents) - 100) / percentsToBeChanged;

    percents = _.map(replacedSmallPercents, (p) => {
      if (p === minPercent) {
        return p;
      }
      return p - offset;
    });
  }

  return percents;
};
