export const formatDate = ({ date } = {}) => {
  return date.toISOString().split('T')[0];
};

export const getDaysInWeek = ({ date } = {}) => {
  const start = new Date(date);
  start.setDate(start.getDate() - start.getDay());
  return Array.from({ length: 7 }, (_, i) => {
    const day = new Date(start);
    day.setDate(start.getDate() + i);
    return day;
  });
};

export const getDaysInMonth = ({ date } = {}) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);

  const result = [];
  const dayOfWeek = firstDay.getDay();

  for (let i = 0; i < dayOfWeek; i++) {
    const paddingDay = new Date(firstDay);
    paddingDay.setDate(paddingDay.getDate() - dayOfWeek + i);
    result.push({ date: paddingDay, isPadding: true });
  }

  for (let d = new Date(firstDay); d <= lastDay; d.setDate(d.getDate() + 1)) {
    result.push({ date: new Date(d), isPadding: false });
  }

  const remainingDays = 7 - (result.length % 7);
  if (remainingDays < 7) {
    for (let i = 1; i <= remainingDays; i++) {
      const paddingDay = new Date(lastDay);
      paddingDay.setDate(paddingDay.getDate() + i);
      result.push({ date: paddingDay, isPadding: true });
    }
  }

  return result;
};

export const formatMonthYear = ({ date } = {}) => {
  return date.toLocaleString('default', { month: 'long', year: 'numeric' });
};
