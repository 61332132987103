export const MuiToggleButton = () => ({
  root: {
    border: 0,
    borderRadius: '50px',

    // cannot use `disabled` directly. Material UI bug
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)'
    }
  }
});
