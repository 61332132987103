import React from 'react';

import withWidth from '@material-ui/core/withWidth';
import _ from 'lodash';

import {
  bootIntercomWidget,
  shutdownIntercomWidget
} from 'utils/integrations/intercom';
import { trackMixpanelEventSdk } from 'utils/integrations/mixpanel/sdk';
import { notifyErrors } from 'utils/notifications';
import { parseParams } from 'utils/urls';

import StatisticsCard from './components/StatisticsCard';
import UserContext from 'components/UserContext';

import {
  ClassesSyncCards,
  CleverSyncObserver,
  GoogleClassroomSyncObserver,
  OverviewTable
} from './components';
import { closestTask } from './sdk';
import styles from './styles.module.scss';

const CLEVER_SYNC_ID_KEY = 'cleverSyncId';

class TeacherAssignmentsList extends React.Component {
  static contextType = UserContext;
  state = {
    closestTask: null,
    loading: true
  };

  componentDidMount() {
    const { user } = this.context;

    this.init();

    if (user) {
      bootIntercomWidget({
        email: user.email,
        name: user.name
      });
    }

    trackMixpanelEventSdk({
      user_id: user.id,
      event_name: 'Teacher opened Assignments Page'
    });
  }

  componentWillUnmount() {
    shutdownIntercomWidget();
  }

  async init() {
    await this.fetchClosestTask();
  }

  getQueryParams = () => {
    const { history } = this.props;

    const queryParams = parseParams(history.location.search, {
      parseNumbers: true
    });

    return queryParams;
  };

  fetchClosestTask = async () => {
    const { data, errors, success } = await closestTask();

    if (success) {
      this.setState({
        closestTask: data
      });
    } else {
      this.setState(
        {
          closestTask: null
        },
        () => notifyErrors(errors)
      );
    }
  };

  getCleverSyncId = (queryParams) => {
    const cleverSyncIdFromQueryParams = _.get(
      queryParams,
      CLEVER_SYNC_ID_KEY,
      null
    );

    const cleverSyncIdFromLocalStorage =
      localStorage.getItem(CLEVER_SYNC_ID_KEY);
    localStorage.removeItem(CLEVER_SYNC_ID_KEY);

    const cleverSyncId =
      cleverSyncIdFromQueryParams || cleverSyncIdFromLocalStorage;

    return cleverSyncId;
  };

  render() {
    const { closestTask } = this.state;
    const { width } = this.props;

    const queryParams = this.getQueryParams();
    const cleverSyncId = this.getCleverSyncId(queryParams);

    return (
      <>
        <div className={styles.grid}>
          <StatisticsCard.Submitted closestTask={closestTask} />
          {/* <StatisticsCard.SessionsAssigned /> */}
          <StatisticsCard.ClassesCount />
          <StatisticsCard.Meetings />
          <ClassesSyncCards />
        </div>

        <OverviewTable width={width} />

        {cleverSyncId && <CleverSyncObserver cleverSyncId={cleverSyncId} />}
        {queryParams.googleSyncId && (
          <GoogleClassroomSyncObserver
            googleSyncId={queryParams.googleSyncId}
          />
        )}
      </>
    );
  }
}

export default withWidth()(TeacherAssignmentsList);
