import React, { useCallback } from 'react';

import AttachmentIcon from '@material-ui/icons/Attachment';
import { TEACHERS_MEETING_FEED_URL } from 'config/urls';
import _ from 'lodash';
import { updateMeetingSharingEnabled } from 'sdk';
import { copyToClipboard } from 'utils';

import { colors } from 'theme/palette';
import { formatDate } from 'utils/moment';
import { notifyErrors } from 'utils/notifications';
import { reverse } from 'utils/urls';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import SelectFilter from 'components/SelectFilter';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const ShareMeetingDialog = ({ meeting, onClose, onUpdateSuccess }) => {
  const meetingName = meeting.topic;

  const scheduledFor = meeting.scheduled_for;
  const scheduleDateFormatted = formatDate(scheduledFor);

  const teamName = meeting.team.name;

  const options = [
    { label: `Members of ${teamName}`, value: false },
    { label: 'Anyone with the link', value: true }
  ];

  const handleSharingEnabledChange = useCallback(
    async ({ is_sharing_enabled }) => {
      const { success, errors } = await updateMeetingSharingEnabled({
        meetingId: meeting.id,
        data: { is_sharing_enabled }
      });

      if (success) {
        onUpdateSuccess();
      } else {
        notifyErrors(errors);
      }
    },
    [meeting.id, onUpdateSuccess]
  );

  const meetinFeedUrl =
    process.env.REACT_APP_BASE_FRONTEND_URL +
    reverse(TEACHERS_MEETING_FEED_URL, {
      invitationIdentifier: meeting.invitation_identifier
    });

  return (
    <Dialog
      open
      alignTop
      maxWidth="md"
      onClose={onClose}
      classes={{ paper: styles.dialog }}
    >
      <Typography
        variant="H-TEXT-2"
        color={colors.blue1}
        className={styles.label}
      >
        Share {!_.isEmpty(meetingName) ? meetingName : scheduleDateFormatted}
      </Typography>

      <Typography variant="B-Text-2" color={colors.grey1}>
        Who has access?
      </Typography>

      <SelectFilter
        disabled={meeting.is_user_invited}
        value={meeting.is_sharing_enabled}
        options={options}
        onChange={(e) =>
          handleSharingEnabledChange({ is_sharing_enabled: e.target.value })
        }
      />

      {meeting.is_user_invited && (
        <Typography
          variant="S-TEXT-3"
          color={colors.pinkDarker}
          className={styles.permissionsInfo}
        >
          Only team members can change sharing
        </Typography>
      )}

      <Button
        color="blue"
        className={styles.button}
        onClick={() => copyToClipboard(meetinFeedUrl)}
        startIcon={<AttachmentIcon />}
        disabled={!meeting.is_sharing_enabled}
      >
        Copy link and share with care
      </Button>

      {meeting.is_sharing_enabled && (
        <div className={styles.caution}>
          <Typography variant="H-TEXT-3" color={colors.pink2}>
            Caution!
          </Typography>
          <Typography variant="B-Text-2" color={colors.grey1}>
            Anyone with the link can see student work for:{' '}
            {!_.isEmpty(meetingName) && `${meetingName} - `}{' '}
            {scheduleDateFormatted}
          </Typography>
        </div>
      )}
    </Dialog>
  );
};

export default ShareMeetingDialog;
