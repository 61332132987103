import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { useSectionUnits } from 'sdk';

import { TopBarSelect } from 'pages/Teachers/shared';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { parseParams, stringifyParams } from 'utils/urls';

import UserContext from 'components/UserContext';

const UnitSelectDropdown = ({
  selectedUnit,
  selectedSection,
  selectedGrade,
  setSelectedUnit,
  setSelectedLesson,
  className
}) => {
  const { user } = useContext(UserContext);

  const { data: units, isLoading } = useSectionUnits({
    sectionId: selectedSection.id,
    params: {
      grade: selectedGrade.name
    }
  });

  const history = useHistory();
  const urlParams = parseParams(history.location.search);
  const unitNumber = _.get(urlParams, 'unit');

  useEffect(() => {
    if (!isLoading) {
      if (!_.isEmpty(units)) {
        const unit = units.find((unit) => unit.name === unitNumber);
        if (unit) {
          setSelectedUnit(unit);
        } else {
          const defaultUnit = units.find((unit) => unit.is_default);
          setSelectedUnit(defaultUnit);

          urlParams.unit = defaultUnit.name;
          history.replace({ search: stringifyParams(urlParams) });
        }
      } else {
        setSelectedUnit(null);

        delete urlParams.unit;
        delete urlParams.lesson;
        history.replace({ search: stringifyParams(urlParams) });
      }
    }
  }, [units, setSelectedUnit, unitNumber, history, urlParams, isLoading]);

  const selectUnit = (newUnit) => {
    setSelectedLesson(null);
    setSelectedUnit(newUnit);

    urlParams.unit = newUnit.name;
    history.replace({ search: stringifyParams(urlParams) });

    trackMixpanelEvent(user.id, '[StrategiesOverview] Teacher changed unit.', {
      unit: newUnit.name
    });
  };

  if (_.isEmpty(units)) return <></>;
  return (
    <TopBarSelect
      value={selectedUnit}
      renderValue={(unit) => `Unit ${_.get(unit, 'name')}`}
      onChange={selectUnit}
      options={units.map((unit) => ({
        value: unit,
        label: unit.name
      }))}
      className={className}
    />
  );
};

export default UnitSelectDropdown;
