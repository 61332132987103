import React, { createContext, useContext, useState } from 'react';

const Index = createContext();

export const useStudent = () => useContext(Index);

export const FamilyContext = ({ children }) => {
  const [selectedStudent, setSelectedStudent] = useState(null);

  return (
    <Index.Provider value={{ selectedStudent, setSelectedStudent }}>
      {children}
    </Index.Provider>
  );
};
