import * as yup from 'yup';

export const assignValidationSchema = yup.object().shape({
  sections: yup
    .array()
    .of(
      yup.object().shape({
        sectionId: yup.mixed().nullable().required('Section is required.'),
        due: yup.string().required('Due date is required.')
      })
    )
    .min(1, 'At least 1 section is required.')
});
