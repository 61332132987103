import _ from 'lodash';

import { STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTIONS } from 'utils/constants';

export const INITIAL_FILTERS = {
  no_standards: false,
  status: _.find(
    STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTIONS,
    (status) => status.key === 'all_submitted'
  ).value
};
