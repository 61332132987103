import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import cx from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { openInNewTab } from 'utils/urls';

import Typography from 'components/Typography';

import { ReactComponent as FileIcon } from './assets/file.svg';
import styles from './styles.module.scss';

const AttachmentList = ({
  attachments,
  onRemove,
  className,
  enableFileDownload = false
}) => {
  const handleFileDownload = ({ attachment }) => {
    if (!_.isNil(attachment.file.url) && enableFileDownload) {
      return openInNewTab(attachment.file.url);
    }
  };
  return _.isEmpty(attachments) ? null : (
    <div className={cx(styles.container, className)}>
      {attachments.map((attachment, index) => (
        <Grid
          key={`${attachment}-${index}`}
          container
          spacing={2}
          alignItems="center"
        >
          <Grid
            container
            item
            xs={4}
            spacing={1}
            alignItems="center"
            wrap="nowrap"
            onClick={() => handleFileDownload({ attachment })}
            className={cx({
              [styles.downloadable]:
                enableFileDownload && !_.isNil(attachment.file.url)
            })}
          >
            <Grid item>
              {attachment.uploaded ? (
                <SvgIcon component={FileIcon} />
              ) : (
                <CircularProgress size={20} />
              )}
            </Grid>
            <Grid item xs={11}>
              <Typography noWrap variant="B-Text-3" color={colors.grey2}>
                {attachment.file.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              size="small"
              onClick={() => onRemove(index)}
              disabled={!attachment.uploaded}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};
export default AttachmentList;
