import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { colors } from 'theme/palette';

import InputField from 'components/InputField';

const useStyles = makeStyles(() => ({
  field: {
    height: '24px',
    width: '100px',

    backgroundColor: colors.white,

    '&:hover': {
      backgroundColor: colors.grey6
    },

    '& .MuiInputBase-root': {
      height: '24px'
    },

    '& .MuiInputBase-root > input': {
      paddingRight: 0,
      color: colors.grey1,

      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '13px',
      paddingLeft: '4px',

      '&:hover': {
        color: colors.blue1
      }
    },

    '& .MuiInput-underline:before': {
      borderBottomWidth: '1px'
    },

    '& .Mui-focused.MuiInput-underline:after': {
      borderBottomColor: `${colors.grey3}`,
      borderBottomWidth: '1px'
    },

    '& .MuiInput-underline:hover:before': {
      borderBottomColor: `${colors.blue4}`,
      borderBottomWidth: '1px'
    },

    '& .MuiInput-underline:hover:after': {
      borderBottomColor: `${colors.blue4}`,
      borderBottomWidth: '1px'
    },

    '& .MuiInputBase-root > .MuiInputAdornment-root': {
      margin: 0
    },

    '& .MuiInputBase-root > .MuiInputAdornment-root button': {
      padding: 4
    },

    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px'
    }
  }
}));

const defaultProps = {
  variant: 'inline',
  disablePast: true,
  format: 'MM/DD/YYYY',
  inputVariant: 'underlined'
};

const DateField = (props) => {
  const styles = useStyles();

  return (
    <KeyboardDatePicker
      {...defaultProps}
      {...props}
      autoOk
      className={styles.field}
      TextFieldComponent={InputField}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <TodayRoundedIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default DateField;
