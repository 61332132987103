import React from 'react';

import { ReactComponent as AssignedSmall } from 'images/assigned_small.svg';

import { colors } from 'theme/palette';

import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as CheckMinusIcon } from './assets/check_minus.svg';
import { ReactComponent as CheckPlusIcon } from './assets/check_plus.svg';
import { ReactComponent as CompleteSmall } from './assets/complete_small.svg';
import { ReactComponent as ReviseSmall } from './assets/revise_small.svg';
import { ReactComponent as SubmittedSmall } from './assets/submitted_small.svg';

export const STATUS_ICONS = {
  assigned: AssignedSmall,
  submitted: SubmittedSmall,
  revise: ReviseSmall,
  complete: CompleteSmall
};

export const CHECK_GRADE_VALUES = {
  '-1': <CheckMinusIcon />,
  0: <CheckIcon />,
  1: <CheckPlusIcon />
};

export const STATUS_COLORS = {
  assigned: colors.yellow3,
  submitted: colors.blue3,
  revise: colors.pink1,
  complete: colors.green1
};
