import React, { useState } from 'react';

import { Legend, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

import styles from './styles.module.scss';

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 5}
        outerRadius={outerRadius + 5}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const DoughnutChart = ({ data, onChange }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <ResponsiveContainer
      width="100%"
      height={300}
      className={styles.chartResponsiveContainer}
    >
      <PieChart>
        <Legend
          verticalAlign="bottom"
          height={100}
          formatter={(value, entry, index) => {
            const display = `${value} - ${(entry.payload.value * 100).toFixed(
              0
            )}%`;

            return (
              <span className={styles.legendText}>
                {activeIndex === index ? <strong>{display}</strong> : display}
              </span>
            );
          }}
        />
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          startAngle={90} // Start angle to rotate the chart
          endAngle={-270} // End angle to complete the circle
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={42}
          outerRadius={85}
          fill="#8884d8"
          dataKey="value"
          onClick={(_, index) => {
            setActiveIndex(index);
            onChange(index);
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DoughnutChart;
