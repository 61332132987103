import _ from 'lodash';

const getFilterValue = (value) => {
  if (_.isArray(value)) {
    return value.join(',');
  }

  return value;
};

const isEmptyFilterValue = (value) => {
  if (_.isArray(value)) {
    return _.isEmpty(value);
  }

  return _.isNil(value);
};

export const buildFilters = (filters) => {
  if (_.isEmpty(filters)) {
    return {};
  }

  return _.reduce(
    filters,
    (params, value, key) => {
      if (!isEmptyFilterValue(value)) {
        params[key] = getFilterValue(value);
      }

      return params;
    },
    {}
  );
};
