import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const FiltersSkeleton = ({ student, renderStandardsFilter = false }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.studentInfo}>
          {_.isNil(student) ? (
            <>
              <Skeleton width={100} height={30} animation="wave" />
              <Skeleton width={100} height={30} animation="wave" />
            </>
          ) : (
            <Typography variant="H-TEXT-3">
              {_.get(student, 'grade_level.name', 'Unknown')}
            </Typography>
          )}
        </div>

        <div className={styles.filtersContainer}>
          <div className={styles.filters}>
            <div className={styles.filter}>
              <Skeleton width={10} height={24} animation="wave" />
            </div>
            <Typography
              variant="B-Text-2"
              textTransform="none"
              className={styles.text}
            >
              Assignments
            </Typography>
            <div className={styles.filter}>
              <Skeleton width={70} height={24} animation="wave" />
            </div>
            <Typography
              variant="B-Text-2"
              textTransform="none"
              className={styles.text}
            >
              between
            </Typography>
            <div className={styles.filter}>
              <Skeleton width={100} height={24} animation="wave" />
            </div>
            <Typography
              variant="B-Text-2"
              textTransform="none"
              className={styles.text}
            >
              and
            </Typography>
            <div className={styles.filter}>
              <Skeleton width={100} height={24} animation="wave" />
            </div>
          </div>
          <div className={styles.filters}>
            {renderStandardsFilter && (
              <>
                <Typography
                  variant="B-Text-2"
                  textTransform="none"
                  className={styles.text}
                >
                  on
                </Typography>
                <div className={styles.filter}>
                  <Skeleton width={90} height={24} animation="wave" />
                </div>
              </>
            )}
            <Typography
              variant="B-Text-2"
              textTransform="none"
              className={styles.text}
            >
              and
            </Typography>
            <div className={styles.filter}>
              <Skeleton width={150} height={24} animation="wave" />
            </div>

            <Typography
              variant="B-Text-2"
              textTransform="none"
              className={styles.text}
            >
              in
            </Typography>

            <div className={styles.filter}>
              <Skeleton width={150} height={24} animation="wave" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersSkeleton;
