import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import classnames from 'classnames';
import _ from 'lodash';

import InputField from 'components/InputField';

export const Autocomplete = withStyles({
  popupIndicatorOpen: { transform: 'none' }
})(MuiAutocomplete);

const useStyles = makeStyles({
  input: {
    '& input': {
      padding: '0 10px !important' // Mui Autocomplete sets it to '6px 0' by deafult
    },
    '& .MuiAutocomplete-endAdornment': {
      marginRight: '10px'
    }
  }
});

export const SearchField = ({
  getOptionLabel,
  getOptionSelected,
  className,
  ...props
}) => {
  const styles = useStyles();

  const localComponentProps = ['required', 'hideSearchIcon', 'renderInput'];

  return (
    <Autocomplete
      openOnFocus
      popupIcon={
        props.hideSearchIcon ? (
          <ArrowDropDownRoundedIcon />
        ) : (
          <SearchRoundedIcon />
        )
      }
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => {
        return (
          <InputField
            variant="underlined"
            className={classnames(styles.input, className)}
            {...params}
            {...props}
          />
        );
      }}
      {..._.omit(props, ...localComponentProps)}
    />
  );
};

export default SearchField;
