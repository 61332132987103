import React from 'react';

import _ from 'lodash';

import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const ItemsPercentage = ({ columnItemsCount, nonExemplarItemsCount }) => {
  const percentageFromTotal =
    nonExemplarItemsCount > 0
      ? _.round((columnItemsCount / nonExemplarItemsCount) * 100)
      : 0;

  return (
    <Typography
      variant="S-TEXT-1"
      color={colors.white}
      className={styles.container}
    >
      {columnItemsCount} <span>{percentageFromTotal}%</span>
    </Typography>
  );
};

export default React.memo(ItemsPercentage);
