import { BASE_URL } from 'config/urls';

import { callUrl, get, post, requestSdk } from 'utils/sdk';

export const getUserTeams = (params) =>
  callUrl(get, `${BASE_URL}/v1/meetings/teams/`, params);

export const createMeeting = (data) =>
  requestSdk(() => post(`${BASE_URL}/v1/meetings/create/`, data));

export const getCurrentSchoolYear = () =>
  callUrl(get, `${BASE_URL}/v1/core/current-school-year/`);
