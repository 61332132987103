import React, { useEffect, useMemo, useState } from 'react';

import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import {
  bulkUploadImageUpdate,
  excludeBulkUploadImage,
  useBulkUploadStudents
} from 'sdk';

import AllStudentsMatched from 'pages/Teachers/BulkUpload/StudentsConfirmation/components/AllStudentsMatched';
import ExcludeImageConfirmationDialog from 'pages/Teachers/BulkUpload/StudentsConfirmation/components/ExcludeImageConfirmationDialog';
import SearchField from 'pages/Teachers/shared/SearchField';
import { useDialog } from 'utils/hooks';
import { notifyErrors } from 'utils/notifications';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Image from 'components/Image';
import SelectFilter from 'components/SelectFilter';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const BulkUploadImageUpdate = ({
  onDialogClose,
  onMatchSuccess,
  loadingUnmatchedGroups,
  onNextButtonClick,
  bulkUploadId,
  bulkUploadImage,
  tasks,
  selectedImageIndex,
  unmatchedGroupsCount,
  allGroupsMatched,
  student = null
}) => {
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(bulkUploadImage.task.id);

  useEffect(
    () => setSelectedTaskId(bulkUploadImage.task.id),
    [bulkUploadImage.task.id]
  );

  const matchedBulkUploadImage = !_.isNil(student);

  const editingFirstImageOfAGroup = selectedImageIndex === 0;

  const {
    isOpened: isExcludeConfirmDialogOpened,
    openDialog: openExcludeConfirmDialog,
    closeDialog: closeExcludeConfirmDialog
  } = useDialog();

  const { data: students } = useBulkUploadStudents({ bulkUploadId });

  const { data: unmatchedStudents, refetch: refetchUnmatchedStudents } =
    useBulkUploadStudents({
      unmatchedOnly: true,
      bulkUploadId
    });

  const onMatchSuccessActions = () => {
    if (matchedBulkUploadImage || !editingFirstImageOfAGroup) {
      onDialogClose();
    } else {
      setSelectedStudent(null);
      setSelectedTaskId(tasks[0].id);

      onMatchSuccess();
      refetchUnmatchedStudents();
    }
  };

  const handleImageUpdate = async ({ selectedStudent }) => {
    if (
      _.isNil(selectedStudent) &&
      selectedTaskId === bulkUploadImage.task.id
    ) {
      onDialogClose();
      return;
    }

    let data = {
      apply_student_to_all_other_images_in_the_same_group:
        editingFirstImageOfAGroup
    };

    if (!_.isNil(selectedStudent)) {
      data = { ...data, student: selectedStudent.id };
    }

    if (selectedTaskId !== bulkUploadImage.task.id) {
      data = { ...data, task: selectedTaskId };
    }

    const { success, errors } = await bulkUploadImageUpdate({
      bulkUploadId,
      bulkUploadImageId: bulkUploadImage.id,
      data
    });
    if (success) {
      onMatchSuccessActions();
    } else {
      notifyErrors(errors);
    }
  };

  const handleImageExclude = async () => {
    closeExcludeConfirmDialog();
    const { success, errors } = await excludeBulkUploadImage({
      bulkUploadId,
      bulkUploadImageId: bulkUploadImage.id
    });
    if (success) {
      onDialogClose();
    } else {
      notifyErrors(errors);
    }
  };

  const headerTile = useMemo(() => {
    if (matchedBulkUploadImage) {
      return student.name;
    }

    if (allGroupsMatched) {
      return 'All students matched';
    }

    return (
      <div className={styles.notMatchedCount}>
        {loadingUnmatchedGroups ? (
          <Skeleton width={25} variant="text" />
        ) : (
          unmatchedGroupsCount
        )}{' '}
        Students not matched
      </div>
    );
  }, [
    student,
    matchedBulkUploadImage,
    allGroupsMatched,
    loadingUnmatchedGroups,
    unmatchedGroupsCount
  ]);

  return (
    <Dialog open>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Button
            className={styles.backButton}
            color="lightGrey"
            onClick={onDialogClose}
            startIcon={<ArrowBackIosRoundedIcon color="action" />}
          >
            Back
          </Button>
          <Typography variant="H-TEXT-2">{headerTile}</Typography>
        </div>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <Image
              className={styles.image}
              src={bulkUploadImage.file_url}
              alt="bulk upload image"
            />
            {allGroupsMatched && !matchedBulkUploadImage && (
              <AllStudentsMatched
                onReviewClick={onDialogClose}
                onNextClick={onNextButtonClick}
              />
            )}
            {isExcludeConfirmDialogOpened && (
              <ExcludeImageConfirmationDialog
                onCancel={closeExcludeConfirmDialog}
                onConfirm={handleImageExclude}
              />
            )}
          </div>
          <div className={styles.studentSearch}>
            <div className={styles.pageSelectContainer}>
              <div className={styles.pageSelect}>
                <Typography variant="S-TEXT-1">Page</Typography>
                <SelectFilter
                  disabled={allGroupsMatched && !matchedBulkUploadImage}
                  greyDarkOnFocus
                  options={tasks?.map(({ name, id }) => ({
                    label: name,
                    value: id,
                    key: id
                  }))}
                  value={selectedTaskId}
                  onChange={(e) => {
                    setSelectedTaskId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={styles.findStudentContainer}>
              <div className={styles.findStudent}>
                <Typography variant="S-TEXT-1">
                  Find student to match
                </Typography>
                <SearchField
                  disabled={allGroupsMatched && !matchedBulkUploadImage}
                  value={selectedStudent}
                  classes={{ input: styles.search }}
                  options={students || []}
                  getOptionLabel={(student) => student.name}
                  onChange={(event, value) => {
                    if (_.includes(students, value) || _.isNull(value)) {
                      setSelectedStudent(value);
                    }
                  }}
                  placeholder="Search Students"
                />
              </div>
            </div>
            {!_.isEmpty(unmatchedStudents) && (
              <div className={styles.selectStudent}>
                <Typography variant="S-TEXT-1" className={styles.unmatchedText}>
                  Select unmatched student
                </Typography>
                <div className={styles.studentsList}>
                  {unmatchedStudents.map((student) => (
                    <div
                      key={student.id}
                      className={styles.studentItem}
                      onClick={() =>
                        handleImageUpdate({
                          selectedStudent: student
                        })
                      }
                    >
                      <Typography
                        variant="B-Text-2"
                        className={styles.studentName}
                      >
                        {student.name}
                      </Typography>
                      <ChevronRightRoundedIcon color="disabled" />
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className={styles.buttonContainer}>
              <Button
                color="pink"
                fullWidth
                onClick={() => handleImageUpdate({ selectedStudent })}
                disabled={allGroupsMatched && !matchedBulkUploadImage}
              >
                Save
              </Button>
              <Button
                className={styles.excludeButton}
                color="lightGrey"
                onClick={openExcludeConfirmDialog}
                disabled={allGroupsMatched && !matchedBulkUploadImage}
              >
                Exclude image
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default BulkUploadImageUpdate;
