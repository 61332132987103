import React, { useState } from 'react';

import { isEmpty } from 'lodash';

import InputField from 'components/InputField';

import { ReactComponent as QueryIcon } from './../PathwaysChatBotReply/assets/replyIcon.svg';
import styles from './styles.module.scss';

const InputChatBot = ({ onSubmit, isDisabled }) => {
  const [text, setText] = useState('');

  const handleSubmit = () => {
    if (isDisabled || isEmpty(text)) return;
    if (isEmpty(text)) return;

    onSubmit({ message: text });
    setText('');
  };

  return (
    <div className={styles.outerContainer}>
      <div className={styles.inputContainer}>
        <InputField
          fullWidth
          placeholder="Ask Ember"
          classes={{ root: styles.input }}
          onChange={(e) => setText(e.target.value)}
          value={text}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              handleSubmit();
            }
          }}
          maxRows={5}
          multiline
        />
        <QueryIcon
          className={`${styles.icon} ${isDisabled ? styles.disabledIcon : ''}`}
          disabled={true}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default InputChatBot;
