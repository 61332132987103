import React from 'react';

import { Typography } from 'pages/Students/shared';
import { colors } from 'theme/palette';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import ForwardButton from 'components/ForwardButton';

import YayImg from './assets/yay.png';
import styles from './styles.module.scss';

export const AllTasksSubmittedDialog = ({
  toggleDoneDialog,
  goToAssignmentsList
}) => (
  <Dialog
    open
    showClose={false}
    onClose={toggleDoneDialog}
    PaperProps={{
      className: styles.dialogPaper
    }}
  >
    <img className={styles.yayImg} src={YayImg} alt="Yay" />
    <Typography
      variant="H-TEXT-3"
      color={colors.blue4}
      align="center"
      className={styles.submittedHeader}
    >
      All tasks are
      <br />
      submitted
    </Typography>
    <ForwardButton
      fullWidth
      noTypography
      color="pink"
      variant="textAndIcon"
      onClick={goToAssignmentsList}
      className={styles.doneButton}
    >
      <Typography variant="S-TEXT-1" align="center" color={colors.white}>
        Done
      </Typography>
    </ForwardButton>
    <Button fullWidth variant="medium" color="blue" onClick={toggleDoneDialog}>
      Add more
    </Button>
  </Dialog>
);
