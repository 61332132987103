import { useMemo, useState } from 'react';

import { buildFilters } from 'utils/filters';
import { useFetch } from 'utils/sdk';

import { getStandards } from './sdk';

export const useStandards = (selectedStandardsIds) => {
  const [standardSearchValue, setStandardSearchValue] = useState('');

  const params = useMemo(
    () =>
      buildFilters({
        search: standardSearchValue,
        'exclude_standards[]': selectedStandardsIds
      }),
    [standardSearchValue, selectedStandardsIds]
  );

  const {
    data: { results: standards }
  } = useFetch(getStandards, { data: { results: [] } }, params);

  return {
    standards,
    setStandardSearchValue,
    standardSearchValue
  };
};
