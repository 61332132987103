import React from 'react';

import _ from 'lodash';

import {
  ListView,
  LoadingListViewSkeleton
} from 'pages/Teachers/StudentPortfolio/components';

import Button from 'components/Button';
import NoResultsPlaceholder from 'components/NoResultsPlaceholder';

import styles from './styles.module.scss';

const IncompleteAssignmentsView = ({
  isLoading,
  assignments,
  hasMore,
  fetchMore,
  studentId
}) => (
  <div>
    {isLoading && _.isEmpty(assignments) && <LoadingListViewSkeleton />}

    {!isLoading && _.isEmpty(assignments) && <NoResultsPlaceholder />}

    {!_.isEmpty(assignments) && (
      <ListView
        assignments={assignments}
        studentId={studentId}
        loading={isLoading}
      />
    )}

    {hasMore && (
      <div className={styles.loadMoreButtonContainer}>
        <Button onClick={fetchMore}>Load more</Button>
      </div>
    )}
  </div>
);

export default IncompleteAssignmentsView;
