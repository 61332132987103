import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import _ from 'lodash';

import { colors } from 'theme/palette';
import { uploadFile } from 'utils/files';
import { notifyError } from 'utils/notifications';

import Dropzone from 'components/Dropzone';
import Typography from 'components/Typography';

import { ReactComponent as FileIcon } from './assets/file.svg';
import styles from './styles.module.scss';

const MAX_ATTACHMENT_FILE_SIZE_IN_BYTES = 20 * 1000 * 1000; // 20MB
const MAX_ATTACHMENT_FILE_SIZE_IN_MEGABYTES_STRING = '20MB';

const AttachmentsUpload = ({ attachments, setAttachments }) => {
  const onDrop = async (files) => {
    const attachmentsBeforeDrop = _.cloneDeep(attachments);

    const preparedFiles = _.map(files, (file) => ({
      file,
      id: null,
      uploaded: false
    }));

    const preparedAttachments = [...attachmentsBeforeDrop, ...preparedFiles];
    setAttachments(preparedAttachments);

    const uploadedFileIds = await Promise.all(
      _.map(preparedFiles, ({ file }) =>
        uploadFile({
          blob: file,
          name: file.name,
          maxSize: {
            sizeInBytes: MAX_ATTACHMENT_FILE_SIZE_IN_BYTES,
            sizeAsString: MAX_ATTACHMENT_FILE_SIZE_IN_MEGABYTES_STRING
          }
        })
      )
    );

    const uploadedFiles = _.map(files, (file, index) => ({
      file,
      id: uploadedFileIds[index],
      uploaded: true
    }));

    const uploadedAttachments = [...attachmentsBeforeDrop, ...uploadedFiles];
    setAttachments(uploadedAttachments);
  };

  const onRemove = (indexToRemove) => {
    const newAttachments = _.filter(
      attachments,
      (value, index) => index !== indexToRemove
    );
    setAttachments(newAttachments);
  };

  const onDropRejected = (files) =>
    _.forEach(files, ({ name }) =>
      notifyError(
        `${name} exceeds the size limit of ${MAX_ATTACHMENT_FILE_SIZE_IN_MEGABYTES_STRING}.`
      )
    );

  return (
    <div>
      <Dropzone
        onDrop={onDrop}
        maxSize={MAX_ATTACHMENT_FILE_SIZE_IN_BYTES}
        onDropRejected={onDropRejected}
        className={styles.dropzone}
      />
      <div>
        {_.map(attachments, (attachment, index) => (
          <Grid
            key={`${attachment}-${index}`}
            container
            spacing={2}
            alignItems="center"
          >
            <Grid
              container
              item
              xs={4}
              spacing={1}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                {attachment.uploaded ? (
                  <SvgIcon component={FileIcon} />
                ) : (
                  <CircularProgress size={20} />
                )}
              </Grid>
              <Grid item xs={11}>
                <Typography noWrap variant="B-Text-3" color={colors.grey2}>
                  {attachment.file.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                size="small"
                onClick={() => onRemove(index)}
                disabled={!attachment.uploaded}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </div>
    </div>
  );
};

export default React.memo(AttachmentsUpload);
