import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import { ReactComponent as FileUploadIcon } from 'images/file-upload-icon.svg';
import { bytesToMegaBytes } from 'utils';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const FileLine = ({ file, user }) => {
  const getLastModifiedDateString = (lastModified) => {
    const date = new Date(lastModified);
    return date.toDateString();
  };

  return (
    <div className={styles.container}>
      <div className={styles.fileInfoContainer}>
        <FileUploadIcon className={styles.uploadIcon} />
        <div className={styles.fileInfo}>
          <Typography variant="B-Text-3" className={styles.name}>
            {file.name}
          </Typography>
          <div className={styles.fileData}>
            <Typography variant="B-Text-4" className={styles.fileDataText}>
              Uploaded by {user.name ? user.name : user.email}
            </Typography>
            <Typography variant="B-Text-4" className={styles.fileDataText}>
              &#9679;
            </Typography>
            <Typography variant="B-Text-4" className={styles.fileDataText}>
              {getLastModifiedDateString(file.lastModified)}
            </Typography>
            <Typography variant="B-Text-4" className={styles.fileDataText}>
              &#9679;
            </Typography>
            <Typography variant="B-Text-4" className={styles.fileDataText}>
              {bytesToMegaBytes(file.size).toFixed(2)} MB
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.progressBar}>
        <LinearProgress />
      </div>
    </div>
  );
};

export default FileLine;
