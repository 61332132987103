import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import cx from 'classnames';
import _ from 'lodash';

import Typography from 'components/Typography';

import { ReactComponent as CSVIcon } from './assets/CSV.svg';
import { ReactComponent as PrintIcon } from './assets/Print.svg';
import { ReactComponent as ExcelIcon } from './assets/XLS.svg';
import styles from './styles.module.scss';
import { triggerAssignmentsExport } from './utils';

const options = [
  {
    label: 'Print',
    onClick: () => window.print(),
    icon: PrintIcon
  },
  {
    label: 'Download CSV',
    onClick: (studentId, filters, exportAction) =>
      triggerAssignmentsExport({
        studentId,
        filters,
        fileFormat: 'csv',
        exportAction
      }),
    icon: CSVIcon
  },
  {
    label: 'Download Excel',
    onClick: (studentId, filters, exportAction) =>
      triggerAssignmentsExport({
        studentId,
        filters,
        fileFormat: 'xlsx',
        exportAction
      }),

    icon: ExcelIcon
  }
];

const Option = ({ option }) => (
  <div className={styles.optionContainer}>
    <option.icon />
    <Typography variant="B-Text-4" noWrap>
      {option.label}
    </Typography>
  </div>
);

const ExportAndPrintMenu = ({ studentId, exportAction, filters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.container}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        classes={{
          list: styles.menuList,
          paper: cx(styles.menuPaper, 'noPrint')
        }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {_.map(options, (option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            onClick={() => {
              handleClose();
              option.onClick(studentId, filters, exportAction);
            }}
            classes={{ root: styles.item, selected: styles.itemSelected }}
          >
            <Option option={option} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ExportAndPrintMenu;
