import { withProfiler } from '@sentry/react';
import React, { useState } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import Routes from 'config/routes';
import theme from 'theme';

import UserContext from 'components/UserContext';

const App = () => {
  const [user, setUser] = useState(null);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <UserContext.Provider value={{ user, setUser }}>
            <Routes />
          </UserContext.Provider>
        </CssBaseline>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default withProfiler(App);
