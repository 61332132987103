import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { colors } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import cx from 'classnames';
import _, { isEmpty, isNull } from 'lodash';
import { useAssignmenTasks } from 'sdk';

import BulkUploadGroup from 'pages/Teachers/BulkUpload/MatchStudents/components/BulkUploadGroup';
import BulkUploadImageUpdate from 'pages/Teachers/BulkUpload/MatchStudents/components/BulkUploadImageUpdate';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as ArrowUp } from './assets/arrow-up.svg';
import styles from './styles.module.scss';

const GroupWrapper = ({
  text,
  groups,
  taskIds,
  shouldPoll,
  imagesExpanded,
  setImagesExpanded,
  setSelectedGroup
}) => {
  return (
    <div className={styles.content}>
      <div
        className={styles.header}
        onClick={() => setImagesExpanded((expanded) => !expanded)}
      >
        <Typography
          variant="H-TEXT-2"
          className={styles.title}
          color={colors.green}
        >
          {groups.length} {groups.length === 1 ? 'student' : 'students'} {text}
        </Typography>

        <IconButton className={cx({ [styles.arrowDown]: imagesExpanded })}>
          <ArrowUp />
        </IconButton>
      </div>

      <div className={styles.groups}>
        {imagesExpanded &&
          groups.map((item, index) => (
            <BulkUploadGroup
              key={index}
              disabled={shouldPoll}
              bulkUploadGroup={item}
              onClick={() => setSelectedGroup(item)}
              showSkeleton={shouldPoll}
              tasksIds={taskIds}
            />
          ))}
      </div>
    </div>
  );
};

const Placeholder = ({ count = 1, boxCount = 10 }) =>
  _.range(count).map((index) => (
    <div key={index}>
      <Typography variant="H-TEXT-2" className={styles.title}>
        <Skeleton width={250} />
      </Typography>

      {_.range(boxCount).map((boxIndex) => (
        <Skeleton
          height={243}
          width={250}
          key={boxIndex}
          variant="rect"
          className={styles.placeholderBox}
        />
      ))}
    </div>
  ));

const GroupsList = ({
  bulkUploadId,
  assignmentId,
  shouldPoll,
  unmatchedBulkUploadImageGroups,
  refetchUnmatchedImageGroups,
  matchedBulkUploadImageGroups,
  refetchMatchedImageGroups
}) => {
  const user = useContext(UserContext);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [unmatchedImagesAreExpanded, setUnmatchedImagesAreExpanded] =
    useState(true);
  const [matchedImagesAreExpanded, setMatchedImagesAreExpanded] =
    useState(false);

  const { data: assignmentTasks } = useAssignmenTasks({ assignmentId });

  const tasksIds = useMemo(
    () => assignmentTasks?.map((task) => task.id),
    [assignmentTasks]
  );

  useEffect(() => {
    if (shouldPoll) {
      const interval = setInterval(() => {
        refetchMatchedImageGroups();
        refetchUnmatchedImageGroups();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [shouldPoll, refetchMatchedImageGroups, refetchUnmatchedImageGroups]);

  useEffect(() => {
    if (!isNull(selectedGroup)) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
        {
          feature: 'capture',
          view: 'confirm',
          button: 'work'
        }
      );
    }
  }, [user, selectedGroup]);

  const handleBulkUploadImageModifyDialogClose = useCallback(() => {
    setSelectedGroup(null);

    refetchMatchedImageGroups();
    refetchUnmatchedImageGroups();
  }, [refetchMatchedImageGroups, refetchUnmatchedImageGroups]);

  const handleMatchSuccess = useCallback(() => {
    const restOfTheUnmatchedGroups = unmatchedBulkUploadImageGroups.filter(
      (group) => group.id !== selectedGroup?.id
    );

    if (!isEmpty(restOfTheUnmatchedGroups)) {
      setSelectedGroup(restOfTheUnmatchedGroups[0]);
    } else {
      setSelectedGroup(null);
    }

    refetchMatchedImageGroups();
    refetchUnmatchedImageGroups();
  }, [
    refetchUnmatchedImageGroups,
    refetchMatchedImageGroups,
    selectedGroup?.id,
    unmatchedBulkUploadImageGroups
  ]);

  const trackMatchedStudentMatched = ({ previousName, newName }) => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.FORM} ${MixpanelAction.SUBMIT}`,
      {
        feature: 'capture',
        view: 'confirm',
        form: 'name_match',
        previous: previousName,
        new: newName
      }
    );
  };

  const trackUnmatchedStudentMatched = () => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'capture',
        view: 'confirm',
        button: 'unmatched'
      }
    );
  };

  const trackImageExclude = () => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'capture',
        view: 'confirm',
        button: 'exclude'
      }
    );
  };

  const trackImageSave = () => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'capture',
        view: 'confirm',
        button: 'save'
      }
    );
  };

  return (
    <div className={styles.container}>
      {isEmpty(unmatchedBulkUploadImageGroups) &&
        isEmpty(matchedBulkUploadImageGroups) && <Placeholder count={2} />}

      {!isEmpty(unmatchedBulkUploadImageGroups) && (
        <GroupWrapper
          text={'not matched'}
          groups={unmatchedBulkUploadImageGroups}
          taskIds={tasksIds}
          shouldPoll={shouldPoll}
          imagesExpanded={unmatchedImagesAreExpanded}
          setImagesExpanded={setUnmatchedImagesAreExpanded}
          setSelectedGroup={setSelectedGroup}
        />
      )}

      {!isEmpty(matchedBulkUploadImageGroups) && (
        <GroupWrapper
          text={'matched'}
          groups={matchedBulkUploadImageGroups}
          taskIds={tasksIds}
          shouldPoll={shouldPoll}
          imagesExpanded={matchedImagesAreExpanded}
          setImagesExpanded={setMatchedImagesAreExpanded}
          setSelectedGroup={setSelectedGroup}
        />
      )}

      {selectedGroup && (
        <BulkUploadImageUpdate
          bulkUploadId={bulkUploadId}
          bulkUploadGroup={selectedGroup}
          tasks={assignmentTasks}
          unmatchedGroupsCount={unmatchedBulkUploadImageGroups.length}
          onMatchSuccess={handleMatchSuccess}
          onDialogClose={handleBulkUploadImageModifyDialogClose}
          trackMatchedStudentMatched={trackMatchedStudentMatched}
          trackUnmatchedStudentMatched={trackUnmatchedStudentMatched}
          trackImageExclude={trackImageExclude}
          trackImageSave={trackImageSave}
        />
      )}
    </div>
  );
};

export default GroupsList;
