import _ from 'lodash';

export const getStandardSiblings = ({ standard, standards }) =>
  _.filter(
    standards,
    (standardX) =>
      standardX.parent_id === standard.parent_id &&
      standardX.level === standard.level &&
      standardX.id !== standard.id
  );
