import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import _ from 'lodash';
import { useUserSectionsCount } from 'sdk';

import { CleverSyncStatuses } from 'utils/integrations/clever';
import { default as pusherManager } from 'utils/integrations/pusher';
import {
  dismissNotification,
  notifyError,
  notifyErrors,
  notifyInfo,
  notifySuccess,
  notifyWarning
} from 'utils/notifications';

import IntegrationsSyncContext from 'components/IntegrationsSyncContext';

import { cleverSyncDetails } from './sdk';

const CleverSyncObserver = ({ cleverSyncId }) => {
  const { refetch: refetchStudentsCount } = useUserSectionsCount();

  const infoToastRef = useRef(null);
  const {
    clever: { sync, isInitiated, setSync }
  } = useContext(IntegrationsSyncContext);

  const dismissLoadingNotification = useCallback(
    () => dismissNotification(infoToastRef.current),
    []
  );

  const notificationOptions = useMemo(
    () => ({
      autoClose: 10000,
      onOpen: dismissLoadingNotification
    }),
    [dismissLoadingNotification]
  );

  const notifyLoading = useCallback(() => {
    infoToastRef.current = notifyInfo(CleverSyncStatuses.INITIATED.message, {
      autoClose: false,
      closeOnClick: false,
      draggable: false,
      closeButton: false
    });
  }, []);

  const notifyFailed = useCallback(
    () => notifyError(CleverSyncStatuses.FAILED.message, notificationOptions),
    [notificationOptions]
  );

  const notifyReady = useCallback(
    (nonSyncedStudentsCount) => {
      if (nonSyncedStudentsCount > 0) {
        const warningMessage = CleverSyncStatuses.READY.getWarningMessage(
          nonSyncedStudentsCount
        );

        notifyWarning(warningMessage, notificationOptions);
      } else {
        notifySuccess(
          CleverSyncStatuses.READY.successMessage(),
          notificationOptions
        );
      }

      refetchStudentsCount();
    },
    [notificationOptions, refetchStudentsCount]
  );

  const fetchCleverSyncDetails = useCallback(
    async (callback) => {
      const { success, data, errors } = await cleverSyncDetails(cleverSyncId);

      if (success) {
        callback(data);
      } else {
        notifyErrors(errors, notificationOptions);
      }
    },
    [cleverSyncId, notificationOptions]
  );

  const notifyForStatusUpdate = useCallback(
    (data) => {
      const { status, non_synced_students_count } = data;

      if (status === CleverSyncStatuses.READY.value) {
        notifyReady(non_synced_students_count);
      } else if (status === CleverSyncStatuses.FAILED.value) {
        notifyFailed();
      }

      setSync(data);
      pusherManager.stopListeningForCleverSyncStatusUpdates(data.id);
    },
    [notifyReady, notifyFailed, setSync]
  );

  const onStatusUpdate = useCallback(() => {
    fetchCleverSyncDetails(notifyForStatusUpdate);
  }, [fetchCleverSyncDetails, notifyForStatusUpdate]);

  useEffect(() => {
    if (_.isNull(sync)) {
      fetchCleverSyncDetails(setSync);
    }
  }, [sync, fetchCleverSyncDetails, setSync]);

  useEffect(() => {
    if (!isInitiated) {
      return;
    }

    notifyLoading();
    pusherManager.listenForCleverSyncStatusUpdates(sync.id, onStatusUpdate);
  }, [isInitiated, sync, onStatusUpdate, notifyLoading]);

  return null;
};

export default CleverSyncObserver;
