import React from 'react';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';

import Button from 'components/Button';

const useIconButtonStyles = makeStyles({
  base: {
    width: '40px'
  },
  small: {
    width: '24px',
    padding: 0
  },
  blue: {
    backgroundColor: colors.blue3,
    '&:hover': {
      backgroundColor: colors.blue2
    }
  }
});

const useButtonStyles = makeStyles({
  pink: {
    backgroundColor: colors.pink3,
    '&:hover': {
      backgroundColor: colors.pink2
    }
  },
  blue: {
    backgroundColor: colors.blue4,
    '&:hover': {
      backgroundColor: colors.blue3
    }
  }
});

const useButtonGroupStyles = makeStyles({
  root: {
    '& > button': {
      minWidth: 'auto'
    }
  }
});

const AddButton = ({
  className,
  color,
  onClick,
  variant = 'base',
  ...props
}) => {
  const iconClasses = useIconButtonStyles();
  const buttonColorClasses = useButtonStyles();
  const groupClasses = useButtonGroupStyles();

  return (
    <ButtonGroup
      disableElevation
      onClick={onClick}
      disabled={_.get(props, 'disabled', false)}
      className={classnames(groupClasses.root, className)}
    >
      <Button
        className={classnames(iconClasses[variant], iconClasses[color])}
        color={color}
        variant={variant}
        noTypography
      >
        <AddRoundedIcon style={{ color: colors.white }} />
      </Button>
      <Button
        className={buttonColorClasses[color]}
        variant={variant}
        color={color}
        {...props}
      />
    </ButtonGroup>
  );
};

export default AddButton;
