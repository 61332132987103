import React from 'react';

import styles from './styles.module.scss';

const Legend = ({ items }) => (
  <div className={styles.legendContainer}>
    {items.map((item, index) => (
      <div key={index} className={styles.legendItem}>
        <div
          className={styles.colorBox}
          style={{ backgroundColor: item.color }}
        ></div>
        <span>{item.text}</span>
      </div>
    ))}
  </div>
);

export default Legend;
