import React, { useCallback, useEffect, useMemo, useState } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import LinkIcon from '@material-ui/icons/Link';
import classnames from 'classnames';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';

import { colors } from 'theme/palette';
import { buildFilters } from 'utils/filters';
import { useDialog } from 'utils/hooks';
import { notifyErrors } from 'utils/notifications';
import { formatDateForApi } from 'utils/sdk';

import AgendaFileUpload from 'components/AgendaFileUpload';
import { useAgendaFile } from 'components/AgendaFileUpload/hooks';
import Button from 'components/Button';
import CreateTeacherTeamDialog from 'components/CreateTeacherTeamDialog';
import DateField from 'components/DateField';
import DateTimeField from 'components/DateTimeField';
import Dialog from 'components/Dialog';
import InputField from 'components/InputField';
import SearchFilter from 'components/SearchFilter';
import Switch from 'components/Switch';
import Typography from 'components/Typography';

import { RECURRING_FREQUENCIES } from './constants';
import { useCurrentSchoolYear, useTeams } from './hooks';
import { createMeeting } from './sdk';
import styles from './styles.module.scss';

const DATEPICKER_FORMAT = 'ddd MMM DD [at] hh:mm A';

const validationSchema = yup.object().shape({
  teamId: yup.number().required(),
  scheduleDate: yup.date().nullable().required(),
  meetingIsRecurring: yup.bool(),
  recurringDueDate: yup
    .date()
    .nullable()
    .when(['meetingIsRecurring', 'scheduleDate'], {
      is: (meetingIsRecurring, scheduleDate) =>
        meetingIsRecurring && !_.isNull(scheduleDate),
      then: yup
        .date('Please select valid recurring end date.')
        .nullable()
        .required('Please select recurring end date.')
    }),
  topic: yup.string().max(255, 'Topic name is too long'),
  notes: yup.string(),
  agendaUrl: yup.string().url()
});

const ScheduleMeetingDialog = ({
  onClose,
  onSuccess,
  onTeamCreate,
  initialTeamId = null,
  disableTeamSelect = false
}) => {
  const [visible, setVisible] = useState(true);

  const {
    isOpened: isCreateTeacherTeamDialogOpened,
    openDialog: openCreateTeacherTeamDialog,
    closeDialog: closeCreateTeacherTeamDialog
  } = useDialog();

  useEffect(() => {
    setVisible(!isCreateTeacherTeamDialogOpened);
  }, [isCreateTeacherTeamDialogOpened]);

  const {
    agendaFile,
    agendaFileIsUploading,
    onAgendaFileDrop,
    onAgendaFileDropRejected,
    onAgendaFileRemove
  } = useAgendaFile();

  const onSubmit = useCallback(
    async (values) => {
      const newMeetingData = {
        team: values.teamId,
        scheduled_for: values.scheduleDate.format('YYYY-MM-DDTHH:mm:ss ZZ'),
        // As the docs say: By default Moment Timezone caches the detected timezone.
        // This means that subsequent calls to moment.tz.guess() will always return the same value.
        // That's why we need to pass the `true` argument in order to ignore cache and always send the correct timezone
        // More info here: https://momentjs.com/timezone/docs/#/using-timezones/guessing-user-timezone/
        timezone: moment.tz.guess(true),
        topic: values.topic,
        comment: values.notes,
        is_recurring: values.meetingIsRecurring,
        recurring_frequency: values.meetingIsRecurring
          ? RECURRING_FREQUENCIES.WEEKLY
          : null,
        recurring_due_date:
          values.recurringDueDate && values.meetingIsRecurring
            ? formatDateForApi(values.recurringDueDate)
            : null
      };

      if (!_.isEmpty(values.agendaUrl)) {
        newMeetingData.agenda_url = values.agendaUrl;
      }
      if (!_.isNull(agendaFile)) {
        newMeetingData.agenda_file = agendaFile.id;
      }

      const preparedPostData = buildFilters(newMeetingData);

      const { success, data, errors } = await createMeeting(preparedPostData);

      if (success) {
        onSuccess(data);
        onClose();
      } else {
        notifyErrors(errors);
      }
    },
    [onSuccess, onClose, agendaFile]
  );

  const initialValues = useMemo(
    () => ({
      teamId: initialTeamId,
      scheduleDate: null,
      meetingIsRecurring: false,
      recurringDueDate: null,
      topic: '',
      notes: '',
      agendaUrl: ''
    }),
    [initialTeamId]
  );

  const {
    values: formikValues,
    isSubmitting: formikIsSubmitting,
    dirty: formikDirty,
    errors: formikErrors,
    handleSubmit: formikHandleSubmit,
    handleChange: formikHandleChange,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });
  const currentSchoolYear = useCurrentSchoolYear();
  useEffect(() => {
    const schoolYearEndDate = _.get(currentSchoolYear, 'naive_end_date');
    if (
      !_.isEmpty(schoolYearEndDate) &&
      _.isNull(formikValues.recurringDueDate) &&
      formikValues.meetingIsRecurring
    ) {
      setFieldValue('recurringDueDate', schoolYearEndDate);
    }
  }, [
    currentSchoolYear,
    formikValues.recurringDueDate,
    setFieldValue,
    formikValues.meetingIsRecurring
  ]);

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamSearchValue, setTeamSearchValue] = useState(
    _.get(selectedTeam, 'name', '')
  );
  const { teams, setTeams } = useTeams(teamSearchValue);

  useEffect(() => {
    if (!_.isNil(initialTeamId) && _.isNil(selectedTeam) && !_.isEmpty(teams)) {
      const teamId = parseInt(initialTeamId);
      const team = teams.filter((team) => team.id === teamId)[0];
      setSelectedTeam(team);
    }
  }, [initialTeamId, teams, selectedTeam]);

  const teamsSearchDisabled = useMemo(
    () =>
      (_.isEmpty(teams) && _.isEmpty(teamSearchValue)) ||
      formikIsSubmitting ||
      disableTeamSelect,
    [teams, teamSearchValue, formikIsSubmitting, disableTeamSelect]
  );

  const selectTeam = useCallback(
    (team) => {
      const teamId = _.get(team, 'id', null);
      const teamName = _.get(team, 'name', '');

      setSelectedTeam(team);
      setFieldValue('teamId', teamId);
      setTeamSearchValue(teamName);
    },
    [setFieldValue]
  );

  const scheduleDateHasErrors = _.has(formikErrors, 'scheduleDate');
  const agendaUrlHasErrors = _.has(formikErrors, 'agendaUrl');
  const topicHasErrors = _.has(formikErrors, 'topic');
  const topicError = _.get(formikErrors, 'topic');
  const recurringDueDateHasErrors = _.has(formikErrors, 'recurringDueDate');

  const disableSubmit =
    formikIsSubmitting ||
    !formikDirty ||
    !_.isEmpty(formikErrors) ||
    agendaFileIsUploading;

  const onCreateTeacherTeamSuccess = useCallback(
    (newTeam) => {
      setTeams([newTeam, ...teams]);
      selectTeam(newTeam);
      onTeamCreate && onTeamCreate(newTeam);
    },
    [teams, setTeams, selectTeam, onTeamCreate]
  );

  const onTeamChange = useCallback((e, opt) => selectTeam(opt), [selectTeam]);
  const onTeamInputChange = useCallback(
    (e, val) => setTeamSearchValue(val),
    []
  );

  const onWeeklyRecurringSwitchChange = useCallback(
    (e) => {
      const checkedValue = e.target.checked;
      setFieldValue('meetingIsRecurring', checkedValue);
    },
    [setFieldValue]
  );

  const recurringDueDateDisabled = useMemo(
    () => _.isEmpty(formikValues.scheduleDate),
    [formikValues.scheduleDate]
  );

  return (
    <Dialog
      open
      alignTop
      fullWidth
      maxWidth="md"
      hideBackdrop={!visible}
      onClose={onClose}
      classes={{
        paper: classnames(styles.dialog, { [styles.hidden]: !visible })
      }}
    >
      <div className={styles.header}>
        <Typography variant="H-TEXT-2" color={colors.blue1}>
          Schedule meeting
        </Typography>
        <Button
          color="pink"
          variant="textAndIcon"
          endIcon={<ChevronRightRoundedIcon />}
          onClick={formikHandleSubmit}
          disabled={disableSubmit}
          className={styles.saveButton}
        >
          Save
        </Button>
      </div>
      <div className={styles.container}>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Team
        </Typography>
        <div className={styles.teamContainer}>
          <SearchFilter
            fullWidth
            name="teamId"
            placeholder="Select Team"
            disabled={teamsSearchDisabled}
            options={teams || []}
            value={selectedTeam}
            getOptionLabel={(team) => _.get(team, 'name', '')}
            getOptionSelected={(team, value) => team.id === value.id}
            onChange={onTeamChange}
            onInputChange={onTeamInputChange}
          />
          <Typography variant="B-Text-3" align="center" color={colors.grey3}>
            - or -
          </Typography>
          <Button
            color="lightGrey"
            variant="contained"
            onClick={openCreateTeacherTeamDialog}
            className={styles.createTeamButton}
            disabled={disableTeamSelect}
          >
            Create team
          </Button>
        </div>

        <div
          className={classnames(styles.label, styles.scheduleLabelContainer)}
        >
          <Typography variant="S-TEXT-1" color={colors.grey3}>
            Schedule
          </Typography>
          <FormControlLabel
            disabled={formikIsSubmitting}
            control={
              <Switch
                checked={formikValues.meetingIsRecurring}
                onChange={onWeeklyRecurringSwitchChange}
              />
            }
            labelPlacement="start"
            label={
              <Typography variant="S-TEXT-1" color={colors.grey3}>
                WEEKLY
              </Typography>
            }
          />
        </div>
        <div className={styles.scheduleContainer}>
          <DateTimeField
            name="scheduleDate"
            disablePast={false} // TODO: Do we want to disable past date selection?
            placeholder="Choose date / time"
            format={DATEPICKER_FORMAT}
            onChange={(scheduleDate) =>
              setFieldValue('scheduleDate', scheduleDate)
            }
            value={formikValues.scheduleDate}
            error={scheduleDateHasErrors}
            helperText={
              scheduleDateHasErrors && 'Please set valid schedule date'
            }
          />
          {formikValues.meetingIsRecurring && (
            <div className={styles.recurringDueDateContainer}>
              <Typography variant="S-TEXT-1" color={colors.grey3}>
                Repeat Until
              </Typography>
              <DateField
                name="recurringDueDate"
                disablePast={false} // TODO: Do we want to disable past date selection?
                disabled={recurringDueDateDisabled}
                minDate={formikValues.scheduleDate}
                placeholder="Choose date"
                onChange={(selectedDate) =>
                  setFieldValue('recurringDueDate', selectedDate)
                }
                className={styles.recurringDueDateField}
                value={formikValues.recurringDueDate}
                error={recurringDueDateHasErrors}
                helperText={
                  recurringDueDateHasErrors &&
                  'Please select valid recurring due date'
                }
              />
            </div>
          )}
        </div>

        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Topic
        </Typography>
        <InputField
          fullWidth
          name="topic"
          variant="underlined"
          placeholder="Add a topic for the meeting"
          value={formikValues.topic}
          onChange={formikHandleChange}
          error={topicHasErrors}
          helperText={topicError}
        />

        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Agenda
        </Typography>

        <div className={styles.agendaContainer}>
          <InputField
            fullWidth
            name="agendaUrl"
            placeholder="Link to Agenda"
            variant="underlined"
            value={formikValues.agendaUrl}
            onChange={formikHandleChange}
            error={agendaUrlHasErrors}
            helperText={agendaUrlHasErrors && 'Enter a valid agenda URL'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={styles.agendaUrlIcon}>
                  <LinkIcon color="disabled" fontSize="small" />
                </InputAdornment>
              )
            }}
          />
          <AgendaFileUpload
            agendaFile={agendaFile}
            onAgendaFileDrop={onAgendaFileDrop}
            onAgendaFileDropRejected={onAgendaFileDropRejected}
            onRemove={onAgendaFileRemove}
          />
        </div>

        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.label}
        >
          Notes
        </Typography>
        <InputField
          fullWidth
          name="notes"
          variant="underlined"
          placeholder="Notes for team..."
          value={formikValues.notes}
          onChange={formikHandleChange}
          InputProps={{
            classes: { multiline: styles.textarea }
          }}
        />

        {formikIsSubmitting && (
          <LinearProgress className={styles.linearProgress} />
        )}
      </div>
      {isCreateTeacherTeamDialogOpened && (
        <CreateTeacherTeamDialog
          onClose={closeCreateTeacherTeamDialog}
          onSuccess={onCreateTeacherTeamSuccess}
        />
      )}
    </Dialog>
  );
};

export default React.memo(ScheduleMeetingDialog);
