import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL } from 'config/urls';
import _ from 'lodash';

import FiltersDrawer from 'pages/Students/FiltersDrawer';
import ViewSwitcher from 'pages/Students/ViewSwitcher';
import { withNavbar } from 'pages/Students/shared';
import AssignmentsDateView from 'pages/Students/shared/AssignmentsDateView';
import AssignmentsStandardsView from 'pages/Students/shared/AssignmentsStandardsView';
import StandardAndAssignmentsListView from 'pages/Students/shared/StandardAndAssignmentsListView';
import { useMyPortfolioInitialFilters } from 'pages/Students/utils';
import { colors } from 'theme/palette';
import { STUDENT_PORTFOLIO_STANDARD_TABS } from 'utils/constants';
import { useBreakpoints } from 'utils/resizing';

import StandardTab from './components/StandardTab';
import StandardTabs from './components/StandardTabs';

import { ReactComponent as ELAIcon } from './assets/ela.svg';
import { ReactComponent as MATHIcon } from './assets/math.svg';
import {
  INITIAL_FILTERS,
  STANDARD_TAB_TO_READABLE_VALUE_MAPPING
} from './constants';
import styles from './styles.module.scss';

const MyPortfolioStandardsView = ({
  history,
  navigation: {
    filterIconClicked,
    setFilterIconClicked,
    setFilterIconColor,
    setTitle,
    setBackButtonAction
  }
}) => {
  const [isDesktop, isTablet, isMobile] = useBreakpoints({
    tablet: 992,
    mobile: 767
  });

  const [activeStandardTab, setActiveStandardTab] = useState(
    isMobile ? null : STUDENT_PORTFOLIO_STANDARD_TABS.MATH
  );

  useEffect(() => {
    if (!isMobile) {
      setActiveStandardTab(STUDENT_PORTFOLIO_STANDARD_TABS.MATH);
    } else {
      setActiveStandardTab(null);
    }
  }, [isMobile]);

  const defaultFilterValues = useMemo(() => {
    if (
      [
        STUDENT_PORTFOLIO_STANDARD_TABS.MATH,
        STUDENT_PORTFOLIO_STANDARD_TABS.ELA
      ].includes(activeStandardTab)
    ) {
      return {
        ...INITIAL_FILTERS,
        no_standards: false,
        standard: activeStandardTab
      };
    } else {
      return {
        ...INITIAL_FILTERS,
        no_standards: true,
        standard: null
      };
    }
  }, [activeStandardTab]);

  const initialFilters = useMyPortfolioInitialFilters({
    defaultValues: defaultFilterValues
  });

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  useEffect(() => {
    if (
      _.isEmpty(filters) ||
      _.isEmpty(initialFilters) ||
      filters === initialFilters
    ) {
      setFilterIconColor(colors.white);
    } else {
      setFilterIconColor(colors.pink);
    }
  }, [filters, initialFilters, setFilterIconColor]);

  useEffect(() => {
    // Update the back action to be going back to the grouping choice page if there isn't a
    // standard tab selected.
    if (isMobile && _.isNil(activeStandardTab)) {
      setBackButtonAction(() =>
        history.push(STUDENTS_MY_PORTFOLIO_GROUPING_CHOICE_URL)
      );
    } else if (
      isMobile &&
      activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS
    ) {
      setBackButtonAction(() => setActiveStandardTab(null));
    } else if (isDesktop || isTablet) {
      setBackButtonAction(null);
    }
  }, [
    activeStandardTab,
    history,
    isMobile,
    isDesktop,
    isTablet,
    setBackButtonAction
  ]);

  useEffect(() => {
    if (isTablet || isDesktop) {
      setTitle('My portfolio');
    } else if (_.isNil(activeStandardTab)) {
      setTitle('Standards');
    } else {
      setTitle(STANDARD_TAB_TO_READABLE_VALUE_MAPPING[activeStandardTab]);
    }
  }, [activeStandardTab, setTitle, isTablet, isDesktop]);

  const unsetActiveStandardTab = useCallback(
    () => setActiveStandardTab(null),
    []
  );

  // We check both the active tab & the filters so that we can be sure that they're matching.
  const showDateView = useMemo(
    () =>
      activeStandardTab === STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS &&
      filters.standard === null,
    [activeStandardTab, filters.standard]
  );
  const showStandardsView = useMemo(
    () =>
      [
        STUDENT_PORTFOLIO_STANDARD_TABS.MATH,
        STUDENT_PORTFOLIO_STANDARD_TABS.ELA
      ].includes(activeStandardTab) &&
      [
        STUDENT_PORTFOLIO_STANDARD_TABS.MATH,
        STUDENT_PORTFOLIO_STANDARD_TABS.ELA
      ].includes(filters.standard),
    [activeStandardTab, filters.standard]
  );

  const showRootStandardPicker = _.isNil(activeStandardTab);

  return (
    <>
      <FiltersDrawer
        filters={filters}
        setFilters={setFilters}
        open={filterIconClicked}
        resetFilters={() => setFilters(initialFilters)}
        onClose={() => setFilterIconClicked(false)}
      />

      {!isMobile && (
        <StandardTabs
          activeTab={activeStandardTab}
          setActiveTab={setActiveStandardTab}
        />
      )}

      {showRootStandardPicker && (
        <>
          <StandardTab
            onClick={() =>
              setActiveStandardTab(STUDENT_PORTFOLIO_STANDARD_TABS.MATH)
            }
            icon={MATHIcon}
          >
            {
              STANDARD_TAB_TO_READABLE_VALUE_MAPPING[
                STUDENT_PORTFOLIO_STANDARD_TABS.MATH
              ]
            }
          </StandardTab>
          <StandardTab
            onClick={() =>
              setActiveStandardTab(STUDENT_PORTFOLIO_STANDARD_TABS.ELA)
            }
            icon={ELAIcon}
          >
            {
              STANDARD_TAB_TO_READABLE_VALUE_MAPPING[
                STUDENT_PORTFOLIO_STANDARD_TABS.ELA
              ]
            }
          </StandardTab>
          <StandardTab
            onClick={() =>
              setActiveStandardTab(STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS)
            }
          >
            {
              STANDARD_TAB_TO_READABLE_VALUE_MAPPING[
                STUDENT_PORTFOLIO_STANDARD_TABS.NO_STANDARDS
              ]
            }
          </StandardTab>
        </>
      )}

      {showDateView && <AssignmentsDateView filters={filters} />}

      {showStandardsView && !isMobile && (
        <div className={styles.wrapper}>
          <AssignmentsStandardsView
            filters={filters}
            initialFilters={initialFilters}
          />
        </div>
      )}

      {showStandardsView && isMobile && (
        <StandardAndAssignmentsListView
          filters={filters}
          setTitle={setTitle}
          setBackButtonAction={setBackButtonAction}
          unsetActiveStandardTab={unsetActiveStandardTab}
        />
      )}
    </>
  );
};

export default withNavbar(MyPortfolioStandardsView, {
  title: 'My portfolio',
  filterIconVisible: true,
  viewSwitcher: ViewSwitcher
});
