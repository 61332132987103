import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import _ from 'lodash';
import { useUserSectionsCount } from 'sdk';

import { GoogleClassroomSyncStatuses } from 'utils/integrations/googleClassroom';
import { default as pusherManager } from 'utils/integrations/pusher';
import {
  dismissNotification,
  notifyError,
  notifyErrors,
  notifyInfo,
  notifySuccess,
  notifyWarning
} from 'utils/notifications';

import IntegrationsSyncContext from 'components/IntegrationsSyncContext';

import { googleClassroomSyncDetails } from './sdk';

const GoogleClassroomSyncObserver = ({ googleSyncId }) => {
  const { refetch: refetchStudentsCount } = useUserSectionsCount();

  const infoToastRef = useRef(null);
  const {
    googleClassroom: { sync, isInitiated, setSync }
  } = useContext(IntegrationsSyncContext);

  const dismissLoadingNotification = useCallback(
    () => dismissNotification(infoToastRef.current),
    []
  );

  const notificationOptions = useMemo(
    () => ({
      autoClose: 10000,
      onOpen: dismissLoadingNotification
    }),
    [dismissLoadingNotification]
  );

  const notifyLoading = useCallback(() => {
    infoToastRef.current = notifyInfo(
      GoogleClassroomSyncStatuses.INITIATED.message,
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false
      }
    );
  }, []);

  const notifyFailed = useCallback(
    () =>
      notifyError(
        GoogleClassroomSyncStatuses.FAILED.message,
        notificationOptions
      ),
    [notificationOptions]
  );

  const notifyReady = useCallback(
    (nonSyncedStudentsCount) => {
      if (nonSyncedStudentsCount > 0) {
        const warningMessage =
          GoogleClassroomSyncStatuses.READY.getWarningMessage(
            nonSyncedStudentsCount
          );

        notifyWarning(warningMessage, notificationOptions);
      } else {
        notifySuccess(GoogleClassroomSyncStatuses.READY.successMessage(), {
          ...notificationOptions,
          ...GoogleClassroomSyncStatuses.READY.customNotificationOptions
        });

        refetchStudentsCount();
      }
    },
    [notificationOptions, refetchStudentsCount]
  );

  const fetchGoogleClassroomSyncDetails = useCallback(
    async (callback) => {
      const { success, data, errors } = await googleClassroomSyncDetails(
        googleSyncId
      );

      if (success) {
        callback(data);
      } else {
        notifyErrors(errors, notificationOptions);
      }
    },
    [googleSyncId, notificationOptions]
  );

  const notifyForStatusUpdate = useCallback(
    (data) => {
      const { status, non_synced_students_count } = data;

      if (status === GoogleClassroomSyncStatuses.READY.value) {
        notifyReady(non_synced_students_count);
      } else if (status === GoogleClassroomSyncStatuses.FAILED.value) {
        notifyFailed();
      }

      setSync(data);
      pusherManager.stopListeningForGoogleClassroomSyncStatusUpdates(data.id);
    },
    [notifyReady, notifyFailed, setSync]
  );

  const onStatusUpdate = useCallback(() => {
    fetchGoogleClassroomSyncDetails(notifyForStatusUpdate);
  }, [fetchGoogleClassroomSyncDetails, notifyForStatusUpdate]);

  useEffect(() => {
    if (_.isNull(sync)) {
      fetchGoogleClassroomSyncDetails(setSync);
    }
  }, [sync, fetchGoogleClassroomSyncDetails, setSync]);

  useEffect(() => {
    if (!isInitiated) {
      return;
    }

    notifyLoading();
    pusherManager.listenForGoogleClassroomSyncStatusUpdates(
      sync.id,
      onStatusUpdate
    );
  }, [isInitiated, sync, onStatusUpdate, notifyLoading]);

  return null;
};

export default GoogleClassroomSyncObserver;
