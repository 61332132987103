import React, { useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { colors } from 'theme/palette';

import styles from './styles.module.scss';

function Carousel({ slides }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [slideDirection, setSlideDirection] = useState('left');

  const slidesPerPage = 1;

  const handleNextPage = () => {
    setSlideDirection('left');
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setSlideDirection('right');
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const prevButtonDisabled = currentPage === 0;
  const nextButtonDisabled = useMemo(
    () => currentPage >= Math.ceil((slides.length || 0) / slidesPerPage) - 1,
    [currentPage, slides, slidesPerPage]
  );

  return (
    <Box className={styles.carouselContainer}>
      <Box className={styles.contentContainer}>
        {slides.map((slide, index) => (
          <Box
            key={`slide-${index}`}
            style={{
              width: '100%',
              height: '100%',
              display: currentPage === index ? 'block' : 'none'
            }}
          >
            <Slide direction={slideDirection} in={currentPage === index}>
              <Box className={styles.slidesContainer}>{slide}</Box>
            </Slide>
          </Box>
        ))}
      </Box>
      <Box className={styles.footerContainer}>
        <IconButton
          onClick={handlePrevPage}
          className={styles.IconButton}
          disabled={prevButtonDisabled}
        >
          <NavigateBeforeIcon
            classes={{ root: styles.carouselButton }}
            htmlColor={prevButtonDisabled ? '#D9D9D9' : colors.blue3}
          />
        </IconButton>
        <Box className={styles.dotsContainer}>
          {slides.map((slide, index) => (
            <div
              className={styles.dot}
              key={`slide-dot-${index}`}
              style={
                currentPage === index ? { backgroundColor: '#32393F' } : {}
              }
            ></div>
          ))}
        </Box>

        <IconButton
          onClick={handleNextPage}
          className={styles.IconButton}
          disabled={nextButtonDisabled}
        >
          <NavigateNextIcon
            classes={{ root: styles.carouselButton }}
            htmlColor={nextButtonDisabled ? '#D9D9D9' : colors.blue3}
          />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Carousel;
