import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import cx from 'classnames';

import { colors } from 'theme/palette';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const MaterialsButton = ({ onClick, className }) => (
  <Button
    color="lightGrey"
    tabIndex="-1"
    className={cx(styles.container, className)}
    onClick={onClick}
  >
    <Typography
      variant="B-Text-3"
      color={colors.blue3}
      className={styles.content}
    >
      <SvgIcon
        component={AttachFileRoundedIcon}
        color="primary"
        className={styles.icon}
      />
      Materials
    </Typography>
  </Button>
);
export default MaterialsButton;
