import React, { useState } from 'react';

import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

import styles from './styles.module.scss';

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      <text x={cx} y={cy} dy={-8} textAnchor="middle" fill={'#292E52'}>
        OVERALL
      </text>
      <text x={cx} y={cy} dy={12} textAnchor="middle" fill={'#292E52'}>
        RESPONSE
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const DoughnutChart = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={190}
      className={styles.chartResponsiveContainer}
    >
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={52}
          outerRadius={85}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DoughnutChart;
