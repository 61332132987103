import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import classnames from 'classnames';
import { STUDENTS_ASSIGNMENTS_OVERVIEW_URL } from 'config/urls';
import _ from 'lodash';
import moment from 'moment';

import {
  AssignmentStatus,
  StatusOptions,
  Typography,
  withNavbar
} from 'pages/Students/shared';
import { colors } from 'theme/palette';
import { AssignmentStatusOptions } from 'utils/constants';
import { buildFilters } from 'utils/filters';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { notifyErrors } from 'utils/notifications';
import { parseParams, reverse, stringifyParams } from 'utils/urls';

import Flex from 'components/Flex';
import UserContext from 'components/UserContext';

import { Filters, FiltersDrawer } from './components';
import {
  assignmentsList,
  assignmentsStatistics,
  fetchStudentSections
} from './sdk';
import styles from './styles.module.scss';

const StyledLinearProgress = withStyles({
  root: {
    background: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    height: 6
  },
  bar: {
    background: 'var(--light-blue)'
  }
})(LinearProgress);

const StatusTabs = ({ active, statistics, onClick, loading }) => (
  <Grid container className={styles.tabs}>
    {_.map(AssignmentStatusOptions, (opt) => (
      <Grid
        item
        xs
        container
        alignItems="center"
        justifyContent="center"
        key={opt}
        className={classnames(styles.tab, {
          [styles.active]: opt === active
        })}
        onClick={() => onClick(opt)}
      >
        <AssignmentStatus
          size="small"
          variant="light"
          status={opt}
          className={classnames({ [styles.disabled]: loading })}
        />
        {!_.includes(
          [AssignmentStatusOptions.COMPLETE, AssignmentStatusOptions.SUBMITTED],
          opt
        ) &&
          _.get(statistics, opt, 0) > 0 && (
            <Typography
              variant="S-TEXT-1"
              className={styles.count}
              color={opt === active ? colors.white : colors.blue1}
            >
              {statistics[opt]}
            </Typography>
          )}
      </Grid>
    ))}
  </Grid>
);

const Assignment = ({
  onClick,
  lgScreen,
  data: { status, title, due_at, created_by, tasks, section }
}) => {
  const overdue =
    status === AssignmentStatusOptions.ASSIGNED && moment().isAfter(due_at);

  return (
    <Grid
      container
      className={styles.assignmentContainer}
      onClick={onClick}
      alignItems="center"
    >
      <Grid item xs={11} container alignItems="center">
        <Grid item style={{ width: '94px' }}>
          <AssignmentStatus status={status} light={false} />
        </Grid>
        <Grid item container xs direction="column">
          <Grid item>
            <Typography
              variant="H-TEXT-3"
              color={colors.blue2}
              className={styles.title}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component="span"
              variant="B-Text-3"
              color={colors.grey1}
            >
              {_.get(section, 'name')}
            </Typography>
            <Typography
              component="span"
              variant={lgScreen ? 'S-TEXT-2' : 'S-TEXT-3'}
              style={{ paddingLeft: 6, color: colors.grey3 }}
            >
              {created_by}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            style={{ paddingTop: 10 }}
          >
            <Grid item xs>
              <Typography
                variant={lgScreen ? 'B-Text-3' : 'B-Text-4'}
                color={overdue ? colors.pink1 : colors.grey3}
              >
                Due:{' '}
                {_.isNil(due_at)
                  ? 'N/A'
                  : moment(due_at).format('ddd M/DD h:mm a')}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              sm={8}
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item>
                <Typography variant="S-TEXT-3" color={colors.blue4}>
                  {tasks.complete}/{tasks.all}
                </Typography>
              </Grid>
              <Grid item>
                <StyledLinearProgress
                  variant="determinate"
                  style={{ width: lgScreen ? 300 : 60 }}
                  value={(tasks.complete / tasks.all) * 100}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} container justifyContent="center">
        <Grid item>
          <IconButton size="small">
            <ChevronRightRoundedIcon htmlColor={colors.blue2} />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AssignmentPlaceholder = ({ count }) => {
  return (
    <React.Fragment>
      {_.map(_.range(count), (index) => (
        <Box key={index} padding="1rem 1rem 0 1rem">
          <Flex>
            <Skeleton variant="rect" width={70} height={70} />
            <Box paddingLeft="1rem" width="100%">
              <Skeleton width="50%" />
              <Skeleton width="30%" />
              <Skeleton />
            </Box>
          </Flex>
        </Box>
      ))}
    </React.Fragment>
  );
};

const NoAssignments = ({ activeTab }) => {
  const getText = () => {
    if (activeTab === StatusOptions.ALL) return 'No assignments!';
    if (activeTab === StatusOptions.ASSIGNED) return 'Nothing assigned!';
    if (activeTab === StatusOptions.REVISE) return 'No assignments to revise!';

    return `No ${activeTab} assignments!`;
  };

  return (
    <div className={styles.noAssignments}>
      <h3 className={styles.header}>{getText()}</h3>
    </div>
  );
};

class StudentAssignmentsList extends React.Component {
  static contextType = UserContext;

  DEFAULT_LIMIT = 10;

  state = {
    activeTab: StatusOptions.ASSIGNED,
    assignmentsLoading: true,
    statisticsLoading: true,
    assignments: [],
    allSections: [],
    statistics: null,
    filters: {
      sections: [],
      assignmentName: ''
    },
    pagination: {
      numPages: 1,
      currentPage: 1,
      limit: this.DEFAULT_LIMIT
    }
  };

  componentDidMount() {
    this.init();
  }

  async init() {
    await this.fetchSections();
    await this.initializeFilters();
    await Promise.all([
      this.buildFiltersAndFetchAssignments(),
      this.fetchStatistics()
    ]);
  }

  initializeFilters = async () => {
    let filters = parseParams(this.props.history.location.search, {
      parseNumbers: true
    });

    const activeTab = _.get(
      StatusOptions,
      _.upperCase(filters.status),
      StatusOptions.ASSIGNED
    );
    this.setState({ activeTab });

    filters = {
      assignmentName: filters.assignmentName,
      sections: _.filter(this.state.allSections, (section) => {
        if (_.isArray(filters.sections)) {
          return _.includes(filters.sections, section.id);
        }
        return filters.sections === section.id;
      })
    };

    await this.setFilters(filters);
  };

  fetchAssignments = async (filters = {}, pagination = {}) => {
    this.setState({ assignmentsLoading: true });

    // TODO: passing student_id is legacy and should be removed
    const {
      data: { results, count, limit },
      success,
      errors
    } = await assignmentsList(this.context.user.student_id, {
      ...filters,
      ...pagination
    });

    if (success) {
      const numPages = Math.ceil(count / limit);

      this.setState({
        assignments: results,
        pagination: {
          ...this.state.pagination,
          numPages,
          limit
        },
        assignmentsLoading: false
      });
    }

    if (!success) {
      this.setState({ assignments: [], assignmentsLoading: false }, () =>
        notifyErrors(errors)
      );
    }
  };

  buildFiltersAndFetchAssignments = async () => {
    const { currentPage } = this.state.pagination;
    const paginationParams = {
      offset: this.state.pagination.limit * (currentPage - 1)
    };

    const statusFilters = this.state.activeTab !== StatusOptions.ALL && {
      status: this.state.activeTab
    };

    const filters = buildFilters({
      ...statusFilters,
      assignment_name: this.state.filters.assignmentName,
      sections: _.map(this.state.filters.sections, 'id')
    });

    await this.fetchAssignments(filters, paginationParams);
  };

  fetchStatistics = async () => {
    const { data, success, errors } = await assignmentsStatistics(
      this.context.user.student_id
    );

    if (!success) {
      this.setState({ statistics: null, statisticsLoading: false }, () =>
        notifyErrors(errors)
      );
      return;
    }

    this.setState({
      statistics: data,
      statisticsLoading: false
    });
  };

  fetchSections = async () => {
    const { success, errors, data } = await fetchStudentSections();
    if (!success) {
      notifyErrors(errors);
      return;
    }
    this.setState({ allSections: data });
  };

  changeTab = (tab) => {
    if (this.state.assignmentsLoading) {
      return;
    }
    const newTab = tab === this.state.activeTab ? StatusOptions.ALL : tab;

    trackMixpanelEvent(
      this.context.user.id,
      `Student switched to "${newTab}" in top navigation.`
    );

    this.setState(
      {
        activeTab: newTab,
        pagination: {
          numPages: 1,
          currentPage: 1,
          limit: this.DEFAULT_LIMIT
        }
      },
      async () => {
        await this.buildFiltersAndFetchAssignments();
        this.updateGetQuery();
      }
    );
  };

  goToAssignmentOverview = (assignmentId) =>
    this.props.history.push(
      reverse(STUDENTS_ASSIGNMENTS_OVERVIEW_URL, {
        assignmentId
      })
    );

  changePage = (page) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          currentPage: page
        }
      },
      this.buildFiltersAndFetchAssignments
    );
  };

  setFilters = (filters) => {
    return new Promise((resolve, reject) => {
      this.setState({ filters: { ...this.state.filters, ...filters } }, () => {
        this.updateGetQuery();
        resolve();
      });
    });
  };

  removeClassFilter = (section) => {
    const filters = {
      ...this.state.filters,
      sections: _.xor(this.state.filters.sections, [section])
    };
    this.setFilters(filters).then(() => {
      this.buildFiltersAndFetchAssignments();
    });
  };

  removeAssignmentNameFilter = () => {
    const filters = { ...this.state.filters, assignmentName: '' };
    this.setFilters(filters).then(() => {
      this.buildFiltersAndFetchAssignments();
    });
  };

  resetStatus = () => {
    const { filters } = this.state;
    if (!_.isEmpty(filters.assignmentName) || !_.isEmpty(filters.sections)) {
      // Reset the active tab when filters are applied
      this.setState({ activeTab: StatusOptions.ALL }, this.updateGetQuery);
    }
  };

  updateGetQuery = () => {
    const statusFilters = this.state.activeTab !== 'all' && {
      status: this.state.activeTab
    };

    this.props.history.replace({
      search: stringifyParams({
        ...statusFilters,
        assignmentName: this.state.filters.assignmentName,
        sections: _.map(this.state.filters.sections, 'id')
      })
    });
  };

  render() {
    const {
      activeTab,
      assignmentsLoading,
      statisticsLoading,
      assignments,
      statistics,
      allSections,
      filters,
      pagination: { numPages, currentPage }
    } = this.state;

    const {
      navigation: { filterIconClicked, setFilterIconClicked }
    } = this.props;

    const lgScreen = isWidthUp('sm', this.props.width);

    const showFilters = !_.every(_.map(filters, (filter) => _.isEmpty(filter)));

    return (
      <div className={styles.listContent}>
        <StatusTabs
          loading={assignmentsLoading}
          active={activeTab}
          statistics={statistics}
          onClick={(selectedOption) =>
            !statisticsLoading && this.changeTab(selectedOption)
          }
        />

        {showFilters && (
          <Filters
            filters={filters}
            removeClassFilter={this.removeClassFilter}
            removeAssignmentNameFilter={this.removeAssignmentNameFilter}
          />
        )}

        <div className={styles.assignments}>
          {assignmentsLoading && <AssignmentPlaceholder count={3} />}

          {!assignmentsLoading &&
            _.map(assignments, (assignment) => (
              <Assignment
                key={assignment.id}
                onClick={() => this.goToAssignmentOverview(assignment.id)}
                lgScreen={lgScreen}
                data={assignment}
              />
            ))}

          {!assignmentsLoading && _.isEmpty(assignments) && (
            <NoAssignments activeTab={activeTab} />
          )}

          {!assignmentsLoading && numPages > 1 && (
            <Pagination
              className={styles.pagination}
              count={numPages}
              shape="rounded"
              page={currentPage}
              onChange={(_, page) => this.changePage(page)}
            />
          )}
        </div>
        <FiltersDrawer
          open={filterIconClicked}
          onClose={() => setFilterIconClicked(false)}
          setFilters={this.setFilters}
          onFiltersApply={this.buildFiltersAndFetchAssignments}
          resetStatus={this.resetStatus}
          filters={filters}
          allSections={allSections}
        />
      </div>
    );
  }
}

export default withWidth()(
  withNavbar(StudentAssignmentsList, {
    title: 'My assignments',
    filterIconVisible: true
  })
);
