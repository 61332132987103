import React, { useCallback, useEffect, useMemo, useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as AddCommentIcon } from 'images/add-comment.svg';
import { ReactComponent as DefaultAddCommentIcon } from 'images/default-add-comment.svg';
import { ReactComponent as HideCommentsIcon } from 'images/hide-comments.svg';
import _ from 'lodash';
import moment from 'moment';
import { addCommentToStudent, taskStatusUpdate } from 'sdk';

import StatusBoxIcon from 'pages/Teachers/shared/StatusBoxIcon';
import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';
import { notifyErrors } from 'utils/notifications';

import AssignmentTaskValueTypeIcon from 'components/AssignmentTaskValueTypeIcon';
import PaginatedStudentWorkTasksButtons from 'components/PaginatedStudentWorkTasksButtons';
import StudentWorkCardComments from 'components/StudentWorkCardComments';
import StudentWorkDialog from 'components/StudentWorkDialog';
import StudentWorkTasksButtons from 'components/StudentWorkTasksButtons';
import Typography from 'components/Typography';
import WorkCardImage from 'components/WorkCardImage';

import styles from './styles.module.scss';

const StudentWorkCard = ({
  assignmentId,
  trackerId,
  tasksData,
  studentData,
  initalComments = []
}) => {
  const [comments, setComments] = useState(initalComments);
  const [taskIndex, setTaskIndex] = useState(0);
  const [workIndex, setWorkIndex] = useState(0);

  const [paginatedTasksStart, setPaginatedTasksStart] = useState(0);

  const [showComments, setShowComments] = useState(false);

  const {
    isOpened: isStudentWorkDialogOpened,
    openDialog: openStudentWorkDialog,
    closeDialog: closeStudentWorkDialog
  } = useDialog();

  const isExemplar = studentData.is_teacher_demo_student;

  const tasksWithIndex = useMemo(
    () =>
      _.map(tasksData, (task, index) => ({
        ...task,
        index: index
      })),
    [tasksData]
  );

  const [tasks, setTasks] = useState(tasksWithIndex);

  useEffect(() => {
    setTasks(tasksWithIndex);
  }, [tasksWithIndex]);

  const changeTask = useCallback(
    ({ taskId, newTask }) => {
      setTasks((previousTasks) => {
        const newTasks = _.cloneDeep(previousTasks);
        return newTasks.map((task) => {
          if (task.id === taskId) {
            return newTask;
          }
          return task;
        });
      });
    },
    [setTasks]
  );

  const activeTask = _.get(tasks, taskIndex, {});
  const activeWork = _.get(activeTask.work, workIndex, { emptyWork: true });

  const tasksButtonsElements = tasksData?.map((task, index) => ({
    label: index + 1,
    key: task.id,
    value: index,
    isActive: index === taskIndex
  }));

  const handleStatusChange = useCallback(
    async (status) => {
      changeTask({ taskId: activeTask.id, newTask: { ...activeTask, status } });

      const { errors } = await taskStatusUpdate({
        trackerScoreResponseId: activeTask.id,
        data: { status }
      });

      if (!_.isEmpty(errors)) {
        notifyErrors(errors);
      }
    },
    [activeTask, changeTask]
  );

  const handleRightArrowClick = useCallback(() => {
    if (paginatedTasksStart + 2 === taskIndex) {
      setPaginatedTasksStart((prevTaskIndex) => prevTaskIndex + 1);
    }

    setTaskIndex((taskIndex) => taskIndex + 1);
    setWorkIndex(0);
  }, [paginatedTasksStart, taskIndex]);

  const handleLeftArrowClick = useCallback(() => {
    if (paginatedTasksStart === taskIndex) {
      setPaginatedTasksStart((prevTaskIndex) => prevTaskIndex - 1);
    }

    setTaskIndex((taskIndex) => taskIndex - 1);
    setWorkIndex(0);
  }, [paginatedTasksStart, taskIndex]);

  const handleCommentAdd = useCallback(
    async ({ event, parent }) => {
      if (event.value !== '') {
        const { data, success, errors } = await addCommentToStudent({
          trackerInstanceSectionId: assignmentId,
          studentId: studentData.id,
          data: {
            text: event.value,
            parent
          }
        });

        if (success) {
          setComments((comments) => [...comments, data]);
        } else {
          notifyErrors(errors);
        }
      }
    },
    [assignmentId, studentData, setComments]
  );

  const cardTitle = isExemplar
    ? `Exemplar - ${studentData.preferred_name}`
    : studentData.name;

  const showPaginatedTaskButtons = tasks.length > 5;
  const showAssignmentTasksButtons = tasks.length > 1 && tasks.length <= 5;

  const showActiveWorksDropdown = activeTask.work?.length > 1;

  return (
    <div className={styles.container}>
      <WorkCardImage
        isEmptyWork={_.get(activeWork, 'emptyWork', false)}
        onClick={openStudentWorkDialog}
        activeWork={activeWork}
        activeWorkIndex={workIndex}
        activeTaskIndex={taskIndex}
        assignmentTasks={tasks}
        activeTaskWork={activeTask.work}
        onLeftArrowClick={handleLeftArrowClick}
        onTopArrowClick={() => setWorkIndex((prevIndex) => prevIndex - 1)}
        onBottomArrowClick={() => setWorkIndex((prevIndex) => prevIndex + 1)}
        onRightArrowClick={handleRightArrowClick}
      />

      <div className={styles.actionRow}>
        <Grid container alignItems="center" className={styles.icons}>
          <Grid item>
            <StatusBoxIcon
              iconButtonClasses={{
                root: styles.iconRoot,
                label: styles.iconLabel
              }}
              disabled={_.get(activeWork, 'emptyWork', false)}
              currentStatus={activeTask.status}
              changeStatus={handleStatusChange}
            />
          </Grid>

          {showComments ? (
            <Grid item>
              <SvgIcon
                component={HideCommentsIcon}
                className={styles.messageIcon}
                onClick={() => setShowComments(false)}
              />
            </Grid>
          ) : (
            <Grid item>
              <SvgIcon
                component={
                  !_.isEmpty(comments) ? AddCommentIcon : DefaultAddCommentIcon
                }
                className={styles.messageIcon}
                onClick={() => setShowComments(true)}
              />
            </Grid>
          )}
        </Grid>

        <div className={styles.taskButtons}>
          {showPaginatedTaskButtons && (
            <PaginatedStudentWorkTasksButtons
              elements={tasksButtonsElements}
              onChange={(element) => {
                setTaskIndex(element.value);
                setWorkIndex(0);
              }}
              onLeftArrowClick={handleLeftArrowClick}
              onRightArrowClick={handleRightArrowClick}
              firstElementIndex={paginatedTasksStart}
              elementsCount={tasks.length}
            />
          )}
          {showAssignmentTasksButtons && (
            <StudentWorkTasksButtons
              elements={tasksButtonsElements}
              onChange={(element) => {
                setTaskIndex(element.value);
                setWorkIndex(0);
              }}
            />
          )}
        </div>
        <div className={styles.selectWork}>
          {showActiveWorksDropdown && (
            <FormControl>
              <Select
                className={styles.selectField}
                classes={{ root: styles.selectFieldRoot }}
                value={workIndex}
                onChange={(e, element) => {
                  setWorkIndex(element.props.value);
                }}
              >
                {activeTask.work.map((workItem, index) => {
                  if (workItem.is_feedback) {
                    return (
                      <MenuItem key={index} value={index}>
                        Feedback
                      </MenuItem>
                    );
                  }

                  if (workItem.is_revision) {
                    return (
                      <MenuItem key={index} value={index}>
                        Revision
                      </MenuItem>
                    );
                  }

                  return (
                    <MenuItem key={index} value={index}>
                      Original
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </div>
      </div>

      <Typography
        variant="B-Text-1"
        className={styles.name}
        onClick={openStudentWorkDialog}
      >
        {cardTitle}
      </Typography>

      <div className={styles.taskInfoContainer}>
        <Grid container className={styles.spaceBetween}>
          <Grid item>
            <Typography variant="S-TEXT-1" color={colors.grey1}>
              {activeTask.index + 1}. {activeTask.name}
            </Typography>
          </Grid>

          {!_.isNull(activeTask.value) && (
            <Grid item>
              <div className={styles.taskScore}>
                <AssignmentTaskValueTypeIcon
                  task={activeTask}
                  color={colors.blue4}
                />
              </div>
            </Grid>
          )}
        </Grid>
        {_.get(activeTask, 'criteria', []).map((criteria, index) => (
          <div key={index} className={styles.criteria}>
            <Typography variant="B-Text-3" color={colors.grey2}>
              {criteria.name}
            </Typography>
            <Typography
              variant="S-TEXT-2"
              color={colors.blue4}
              className={styles.criteriaStatus}
            >
              {criteria.value ? 'Yes' : 'No'}
            </Typography>
          </div>
        ))}
      </div>

      {showComments && (
        <StudentWorkCardComments
          comments={_.orderBy(
            [
              ...comments,
              ..._.flatten(comments.map((x) => _.get(x, 'replies', [])))
            ],
            (x) => moment(x.created_at)
          )}
          onSubmit={(event) =>
            handleCommentAdd({ event, parent: _.get(comments, '0.id') })
          }
        />
      )}
      {isStudentWorkDialogOpened && (
        <StudentWorkDialog
          initialTaskIndex={taskIndex}
          initialWorkIndex={workIndex}
          studentWork={{
            tasks,
            student: studentData
          }}
          assignmentId={assignmentId}
          trackerId={trackerId}
          onClose={closeStudentWorkDialog}
        />
      )}
    </div>
  );
};

export default StudentWorkCard;
