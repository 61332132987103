import React, { useMemo, useState } from 'react';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { first, isEmpty, map } from 'lodash';

import StudentWorkViewDialog from 'pages/MisconceptionsDashboard/components/StudentWorkViewDialog';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const Strengths = ({
  studentMasteredCount,
  classMasteredAverageCount,
  studentMasteredWorks
}) => {
  const [selectedWork, setSelectedWork] = useState(null);
  const [isWorkDialogOpened, setIsWorkDialogOpened] = useState(false);

  const studentWorks = useMemo(() => {
    return map(studentMasteredWorks, (url) => {
      return {
        imageUrl: url,
        category: 'Concepts Mastered'
      };
    });
  }, [studentMasteredWorks]);

  const handleViewStrengths = (event) => {
    if (isEmpty(studentWorks)) return;

    event.preventDefault();
    setSelectedWork(first(studentWorks));
    setIsWorkDialogOpened(true);
  };

  return (
    <div className={styles.strengthSectionOuter}>
      {isWorkDialogOpened && (
        <StudentWorkViewDialog
          studentWork={selectedWork}
          setSelectedStudentWork={setSelectedWork}
          studentWorks={studentWorks}
          onClose={() => setIsWorkDialogOpened(false)}
        />
      )}
      <Typography className={styles.sectionTitle} variant="H-TEXT-2">
        strengths
      </Typography>
      <div
        className={styles.strengthSectionInner}
        role="button"
        onClick={handleViewStrengths}
      >
        <div className={styles.strength}>
          <div className={styles.spacer}></div>
          <div className={styles.strengthNumber}>{studentMasteredCount}</div>
          <div>
            <CheckCircleOutlineIcon className={styles.checkIcon} />
          </div>
          <div className={styles.spacer}></div>
        </div>
        <div className={styles.strengthText}>
          <Typography className={styles.innerText}>
            CONCEPTS MASTERED
          </Typography>
        </div>
      </div>
      <Typography className={styles.averageText} variant="H-TEXT-3">
        average: {classMasteredAverageCount}
      </Typography>
    </div>
  );
};

export default Strengths;
