import React from 'react';
import ShowMoreText from 'react-show-more-text';

import { makeStyles } from '@material-ui/core/styles';

import InfoPanel from 'pages/Pathways/components/PathwaysDailyProgress/components/InfoPanel';

import Typography from 'components/Typography';

const HEADER_HEIGHT = 48;

const useStyles = makeStyles({
  infoPanelFooterButton: (theme) => ({
    height: theme.headerHeight,
    borderRadius: theme.headerHeight / 2,
    fontWeight: 600,
    textTransform: 'none',
    backgroundColor: '#fff',
    color: theme.secondaryColor,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.primaryColor,
    '&:hover': {
      backgroundColor: theme.accentColor
    }
  }),
  contentStyle: (theme) => ({
    backgroundColor: theme.primaryColor,
    borderRadius: 5,
    padding: 8
  }),
  headerTitle: (theme) => ({
    lineHeight: `${theme.headerHeight}px`,
    padding: '0px 16px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textTransform: 'none'
  }),
  moreLess: (theme) => ({
    float: 'right',
    color: theme.secondaryColor,
    '&:hover': {
      color: '#374151',
      textDecoration: 'underline'
    },
    fontWeight: 600
  }),
  showMoreText: {
    color: '#438642',
    width: '100%',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.25rem'
  },
  anchorClass: {
    cursor: 'pointer'
  }
});

const DailyReflectionDisplay = ({ reflectionText }) => {
  const panelTheme = {
    primaryColor: '#C1EACB',
    secondaryColor: '#292E52',
    accentColor: '#DBF3E2',
    headerHeight: HEADER_HEIGHT
  };
  const styles = useStyles(panelTheme);

  return (
    <div className={styles.infoPanelContainer}>
      <InfoPanel
        theme={panelTheme}
        header={
          <Typography variant="H-TEXT-2" className={styles.headerTitle}>
            Today's self reflection
          </Typography>
        }
        content={
          <div className={styles.contentStyle}>
            <ShowMoreText
              lines={3}
              more={
                <>
                  <div className={styles.moreLess}>view more</div>
                  <div style={{ clear: 'both' }}></div>
                </>
              }
              less={
                <>
                  <div className={styles.moreLess}>...view less</div>
                  <div style={{ clear: 'both' }}></div>
                </>
              }
              className={styles.showMoreText}
              anchorClass={styles.anchorClass}
              expanded={false}
              truncatedEndingComponent={'...'}
            >
              {reflectionText}
            </ShowMoreText>
          </div>
        }
      />
    </div>
  );
};

export default DailyReflectionDisplay;
