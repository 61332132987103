export const MuiInput = () => ({
  root: {
    height: '100%'
  },
  input: {
    // This is a fix for mobile Safari users. If the input field has a fontSize smaller
    // than 16 - Safari zooms in on that input field and never zooms out.
    fontSize: '16px'
  }
});
