export const ORDERING_OPTIONS = [
  {
    key: 'student.last_submission_date',
    label: 'Most Recent',
    value: 'student.last_submission_date'
  },
  {
    key: 'student.first_name',
    label: 'First Name',
    value: 'student.first_name'
  },
  {
    key: 'student.last_name',
    label: 'Last Name',
    value: 'student.last_name'
  }
];

export const SHOW_FILTER_OPTIONS = [
  {
    key: 'all_submissions',
    value: 'all_submissions',
    label: 'All Submitted'
  },
  { key: 'all_students', value: 'all_students', label: 'All Students' },
  {
    key: 'all_students_all_tasks',
    value: 'all_students_all_tasks',
    label: 'All Students & All Pages'
  },
  {
    key: 'all_submitted_all_tasks',
    value: 'all_submitted_all_tasks',
    label: 'All Submitted & All Pages'
  }
];
