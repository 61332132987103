export function formatAnswerText({ text }) {
  const replacements = {
    'America/Los_Angeles': 'America/Los Angeles',
    'America/New_York': 'America/New York',
    'America/Puerto_Rico': 'America/Puerto Rico'
  };

  if (Object.keys(replacements).includes(text)) {
    return replacements[text];
  }

  return text;
}
