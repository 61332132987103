import React, { useCallback } from 'react';

import classnames from 'classnames';

import { colors } from 'theme/palette';
import { openInNewTab } from 'utils/urls';

import Typography from 'components/Typography';

import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import styles from './styles.module.scss';

const AgendaLinkChip = ({ link }) => {
  const openLink = useCallback(() => openInNewTab(link), [link]);
  return (
    <div
      className={classnames(styles.chip, styles.agendaChip)}
      onClick={openLink}
    >
      <LinkIcon width={20} height={16} />
      <Typography
        variant="B-Text-2"
        color={colors.blue3}
        className={styles.agendaChipText}
      >
        {link}
      </Typography>
    </div>
  );
};

export default AgendaLinkChip;
