import React from 'react';

import { PolarGrid, Radar, RadarChart, ResponsiveContainer } from 'recharts';

import styles from './styles.module.scss';

const ExplanationChart = ({ results, title }) => {
  const renderDot = (props) => {
    const { cx, cy, key } = props;
    if (key === 'dot-0' && title === 'Teacher Knowledge & Skill') {
      return (
        <svg key={key}>
          <circle cx={cx} cy={cy} r={4} fill="#CC2364" />
        </svg>
      );
    }
    if (key === 'dot-1' && title === 'Student Engagement') {
      return (
        <svg key={key}>
          <circle cx={cx} cy={cy} r={4} fill="#CC2364" />
        </svg>
      );
    }

    if (key === 'dot-2' && title === 'Content Rigor & Relevance') {
      return (
        <svg key={key}>
          <circle cx={cx} cy={cy} r={4} fill="#CC2364" />
        </svg>
      );
    }
  };

  return (
    <div className={styles.explanationChart}>
      {results && (
        <ResponsiveContainer>
          <RadarChart outerRadius={'100%'} data={results}>
            <PolarGrid />

            <Radar
              dot={renderDot}
              dataKey="result"
              fill="#82ca9d"
              fillOpacity={0.6}
            />
          </RadarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ExplanationChart;
