import React, { useContext, useEffect, useMemo, useState } from 'react';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as FilterIcon } from 'images/filter.svg';
import _ from 'lodash';
import { useStudentAssignments, useStudentSections } from 'sdk';
import { getStandardButtonLabel } from 'utils';

import { colors } from 'theme/palette';
import { STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTIONS } from 'utils/constants';
import { useDialog } from 'utils/hooks';

import Button from 'components/Button';
import ForwardButton from 'components/ForwardButton';
import InputField from 'components/InputField';
import SearchFilter from 'components/SearchFilter';
import SelectFilter from 'components/SelectFilter';
import StandardDialog from 'components/StandardDialog';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const FiltersDrawer = ({
  open,
  resetFilters,
  filters: initialFilters,
  setFilters: applyFilters,
  onClose,
  showStatusFilter = true,
  showStandardsFilter = true
}) => {
  const [filters, setFilters] = useState(initialFilters);
  const [selectedStandards, setSelectedStandards] = useState([]);

  const { user } = useContext(UserContext);
  const studentId = user.student_id;

  const { data: studentSections } = useStudentSections({ studentId });
  const { data: studentAssignments } = useStudentAssignments({ studentId });

  useEffect(() => {
    if (!open) {
      setFilters(initialFilters);
    }
  }, [open, initialFilters]);

  const classFilterOptions =
    useMemo(
      () =>
        _.filter(
          studentSections,
          (section) => !_.some(_.get(filters, 'sections', []), section)
        ),
      [studentSections, filters]
    ) || [];

  const {
    isOpened: isStandardDialogOpened,
    closeDialog: closeStandardDialog,
    openDialog: openStandardDialog
  } = useDialog();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{
        root: styles.filtersDrawer,
        paper: styles.filtersDrawerPaper
      }}
    >
      <div className={styles.filterTitle}>
        <IconButton onClick={onClose} className={styles.backIcon} size="small">
          <ArrowForwardIosRoundedIcon style={{ color: colors.blue3 }} />
        </IconButton>
        <FilterIcon fill={colors.blue1} className={styles.filterIcon} />
        <Typography variant="H-TEXT-3">Filter</Typography>
      </div>

      {showStatusFilter && (
        <div className={styles.menuSection}>
          <div className={styles.filterName}>show</div>
          <SelectFilter
            greyDarkOnFocus
            placeholder="All"
            valueEqualToTheInitial={_.isEqual(
              filters.status,
              initialFilters.status
            )}
            options={STUDENT_PORTFOLIO_ASSIGNMENT_STATUS_FILTER_OPTIONS}
            value={_.get(filters, 'status', '')}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
          />
        </div>
      )}

      <div className={styles.menuSection}>
        <div className={styles.filterName}>assignments</div>
        <SearchFilter
          freeSolo
          autoComplete
          withWhiteBackground
          placeholder="All"
          options={studentAssignments || []}
          value={
            _.find(
              studentAssignments,
              (option) => _.get(filters, 'assignment', '') === option.name
            ) || ''
          }
          onChange={(e, value) => {
            setFilters({ ...filters, assignment: value?.name || '' });
          }}
          getOptionLabel={(option) => _.get(option, 'name')}
        />
      </div>

      <div className={styles.menuSection}>
        <div className={styles.filterName}>class</div>
        <SearchFilter
          freeSolo
          autoComplete
          withWhiteBackground
          placeholder="All"
          options={classFilterOptions}
          value={_.find(
            classFilterOptions,
            (item) => item.id === filters.sections
          )}
          onChange={(e, value) => {
            setFilters({ ...filters, sections: value?.id });
          }}
          getOptionLabel={(option) => _.get(option, 'name')}
        />
      </div>

      {showStandardsFilter && (
        <div className={styles.menuSection}>
          <div className={styles.filterName}>standard</div>
          <InputField
            readOnly
            fullWidth
            withBlueUnderline
            valueEqualToTheInitial={_.isEmpty(selectedStandards)}
            className={styles.transparentInput}
            onClick={openStandardDialog}
            variant="underlined"
            value={
              _.isEmpty(selectedStandards)
                ? 'All'
                : getStandardButtonLabel({
                    standards: selectedStandards
                  })
            }
          />
        </div>
      )}

      <div className={styles.menuSection}>
        <div className={styles.filterName}>start</div>
        <KeyboardDatePicker
          fullWidth
          disablePast={false}
          withBlueUnderline
          className={styles.transparentInput}
          value={_.get(filters, 'startDate')}
          valueEqualToTheInitial={_.isEqual(
            filters.startDate,
            initialFilters.startDate
          )}
          onChange={(e, value) => setFilters({ ...filters, startDate: value })}
          format="MM/DD/YYYY"
          variant="inline"
          inputVariant="underlined"
          autoOk
          TextFieldComponent={InputField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <TodayRoundedIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>

      <div className={styles.menuSection}>
        <div className={styles.filterName}>end</div>
        <KeyboardDatePicker
          fullWidth
          withBlueUnderline
          disablePast={false}
          className={styles.transparentInput}
          value={_.get(filters, 'endDate')}
          valueEqualToTheInitial={_.isEqual(
            filters.endDate,
            initialFilters.endDate
          )}
          onChange={(e, value) => setFilters({ ...filters, endDate: value })}
          format="MM/DD/YYYY"
          variant="inline"
          inputVariant="underlined"
          autoOk
          TextFieldComponent={InputField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <TodayRoundedIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>

      <div className={styles.applyButtonContainer}>
        <ForwardButton
          fullWidth
          color="blue"
          onClick={() => {
            applyFilters((prevValue) => ({
              ...prevValue,
              ...filters
            }));
            onClose();
          }}
        >
          Apply
        </ForwardButton>
      </div>

      <div className={styles.resetButtonContainer}>
        <Button
          fullWidth
          color="pink"
          onClick={() => {
            resetFilters();
            setSelectedStandards([]);
            onClose();
          }}
        >
          Reset
        </Button>
      </div>

      {isStandardDialogOpened && (
        <StandardDialog
          disableClearable
          selectedItems={selectedStandards}
          saveButtonLabel="Filter"
          onClose={closeStandardDialog}
          value={selectedStandards}
          onSave={({ selectedStandards }) => {
            setFilters({
              ...filters,
              standards: _.map(selectedStandards, 'id')
            });
            setSelectedStandards(selectedStandards);
          }}
        />
      )}
    </Drawer>
  );
};
export default FiltersDrawer;
